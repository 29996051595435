import React, { Dispatch, FC, SetStateAction, useState } from "react";
import PrimaryButton from "@components/buttons/primary.button";
import downArrow from "@assets/images/icons/chevronDown.svg";
import { NFT_RENT_TYPE } from "@constants/nft";
import { CROWN, ICX } from "@constants/images";

interface IRentNFT {
  price: number;
  period: number;
  days?: number;
  currency: keyof typeof NFT_RENT_TYPE;
  setPeriod: Dispatch<SetStateAction<number>>;
  setRentalModal: Dispatch<
    SetStateAction<"rent" | "update" | "remove" | "lend" | "claim" | null>
  >;
  isWalletConnected: boolean;
}
const RentNFT: FC<IRentNFT> = ({
  currency,
  period,
  days = 30,
  setPeriod,
  price,
  setRentalModal,
  isWalletConnected
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const selectOption = (day: number) => {
    setPeriod(day);
    setIsOpen(false);
  };

  const getOption = (day: number) => {
    return (
      <div className="rent-price-dropdown flex gap-1.5">
        {day} {day > 1 ? "days" : "day"} ({" "}
        <img
          src={
            currency === NFT_RENT_TYPE.CROWN_CURRENCY ? CROWN : ICX
          }
          alt="currency"
          className="w-5 h-5"
        />
        {price * day} Total)
      </div>
    )
  }

  return (
    <div className="relative inline-block text-left mt-8 border-b pb-8 border-[#485C65]">
      <div className="flex py-0 px-6 items-center gap-4">
        <div
          className="px-4 py-[0.625rem] gap-4 w-72  flex text-sm font-medium text-white items-center border border-[#485C65] cursor-pointer"
          onClick={toggleDropdown}
        >
          <div className="flex-grow rent-price-dropdown">
            {period
              ? getOption(period)
              : "Select an Option"}
          </div>
          <div>
            <img
              src={downArrow}
              alt="downarrow"
              className="items-center"
            />
          </div>
        </div>

        <div>
          <PrimaryButton
            disabled={!period || !isWalletConnected}
            onClick={() => setRentalModal("rent")}
            buttonName="Rent NFT"
            width="8rem"
          />
        </div>
      </div>

      {isOpen && (
        <div className="origin-top-left absolute left-6 w-72 rounded-md shadow-l border border-[#485C65] bg-black z-50 bg-opacity-100">
          <div className="py-1 max-h-32 overflow-y-scroll">
            {Array.from({ length: days }, (_, i) => i + 1).map((day) => (
              <button
                key={day}
                onClick={() => selectOption(day)}
                className="block w-full px-4 py-2 text-sm text-gray-500  hover:text-white"
              >
                {getOption(day)}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default RentNFT;
