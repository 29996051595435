"use strict";
/*
 * Copyright 2021 ICON Foundation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
const Call_1 = require("./call/Call");
const IcxTransaction_1 = require("./transaction/IcxTransaction");
const CallTransaction_1 = require("./transaction/CallTransaction");
const DeployTransaction_1 = require("./transaction/DeployTransaction");
const DepositTransaction_1 = require("./transaction/DepositTransaction");
const MessageTransaction_1 = require("./transaction/MessageTransaction");
const Builder = {
    CallBuilder: Call_1.CallBuilder,
    IcxTransactionBuilder: IcxTransaction_1.IcxTransactionBuilder,
    CallTransactionBuilder: CallTransaction_1.CallTransactionBuilder,
    DeployTransactionBuilder: DeployTransaction_1.default,
    MessageTransactionBuilder: MessageTransaction_1.default,
    DepositTransactionBuilder: DepositTransaction_1.default,
};
exports.default = Builder;
