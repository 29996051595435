/*eslint-disable*/

import { AiOutlineClose } from "react-icons/ai";
import { BiInfoCircle } from "react-icons/bi";

const NewUserAlert = ({ setIsFirstTime }: any) => {
  return (
    <div
      style={{ background: "rgb(255 198 0 / 18%)" }}
      className="flex mx-2 items-center text-white text-sm  px-4 py-3"
      role="alert"
    >
      <div className="flex justify-between w-full">
        <div className="flex gap-2">
          <BiInfoCircle size={20} />
          <p>
            New to GangstaVerse? Explore the official site {" "}
            <u className="cursor-pointer">
              <span
                onClick={() => {
                  window.open("https://gangstaverse.co/"), "_blank";
                }}
              >
                here
              </span>
            </u>.
          </p>
        </div>
        <AiOutlineClose
          onClick={() => {
            window.localStorage.setItem("openForFirstTime", "false");
            setIsFirstTime(true);
          }}
          className="cursor-pointer"
          size={20}
        />
      </div>
    </div>
  );
};

export default NewUserAlert;
