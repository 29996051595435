export const config = {
  icon: {
    WALLET_URL:
      process.env.REACT_APP_WALLET_URL ||
      "https://lisbon.net.solidwallet.io/api/v3",
    NETWORK_ID: process.env.REACT_APP_NETWORK_ID || "2",
    STEP_LIMIT_API:
      process.env.REACT_APP_STEP_LIMIT_API ||
      "https://lisbon.net.solidwallet.io/api/v3d",
  },
  contract: {
    GANGWAR_CONTRACT:
      process.env.REACT_APP_GANGWAR_SCORE ||
      "cx4b52ca0777e78d3f0f9ec49ee79ed16b4fcff790",
    GANGSTA_SCORE:
      process.env.REACT_APP_GANGSTA_SCORE ||
      "cx137e29bdeac9025cefb2d9ab5f7d7a31ea7ee4ef",
    LOOTBOX_SCORE:
      process.env.REACT_APP_LOOTBOX_SCORE ||
      "cxd8bfa3e21d3202c157401c328c100ef33d65dda7",
    ITEMS_SCORE:
      process.env.REACT_APP_ITEM_SCORE ||
      "cx32252f896ec567c0ca90230573d105fb575ebc3d",
    EQUIPMENT_SCORE:
      process.env.REACT_APP_EQUIPMENT_SCORE ||
      "cx33cf47da39dba251750431c3a1d320b87948f795",
    CROWN_SCORE:
      process.env.REACT_APP_CROWN_SCORE ||
      "cx1bdcca4ccf06a86e62e1cba91e9400329a835595",
    TOKEN_DISTRIBUTION: process.env.REACT_APP_TOKEN_DISTRIBUTION,
    RENTAL_SCORE:
      process.env.REACT_APP_RENTAL_SCORE ||
      "cx27647c46eedb30910c7fe2a930c420c95f714c2c",
  },
};

// contract: {
//   GANGWAR_CONTRACT:
//     process.env.GANGWAR || "cxed6fa7e4d64ca066fc907bde90711dd8d58183b6",
//   GANGSTA_SCORE:
//     process.env.GANGSTA_SCORE || "cx137e29bdeac9025cefb2d9ab5f7d7a31ea7ee4ef",
//   LOOTBOX_SCORE:
//     process.env.LOOTBOX_SCORE || "cx5cd404f0d1c4f030c588394664f5c2b924f4ec95",
//   ITEMS_SCORE:
//     process.env.ITEM_SCORE || "cx8333c56feced0332ab579528a93cc9ffae3d2dbe",
//   EQUIPMENT_SCORE:
//     process.env.EQUIPMENT_SCORE ||
//     "cxa8412e3ca8f6eef1bb225206050ba0856c9bb440",
// },
