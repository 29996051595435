import PrimaryButton from "@components/buttons/primary.button";
import NFTCard from "@components/card/nftCard.component";
import CommonModal from "@components/modal/commonModal.component";
import { CROWN, TEST_NFTS, GB_HAT, WALLET_ICON, EMPTY_HAT } from "@constants/images";
import { useNFTDetailGlobalContext } from "@context/nftInformation";
import { useCardAnimation } from "@hooks/useCardAnimation";
import { NFTsDetails } from "@interfaces/nft.interface";
import { useEffect, useRef, useLayoutEffect, useState, useCallback, useMemo } from "react";
import { AiOutlineDown } from "react-icons/ai";
import { FaSearch } from "react-icons/fa";
import { useSelector } from "react-redux";
import { LOADING } from "@constants/images";
import { BsHeartFill } from "react-icons/bs";
import { MdTimer } from "react-icons/md";
import { PLAY_NORMAL_CLICK } from "@constants/audio";
import { useDetailGlobalContext } from "@context/detailProvider";
import { MessageText } from "@misc/message/messageText";
import { useTransactionValidate } from "@hooks/transactionResult";
import { claimRentFees, getCoolDownFee, outOfHospitalization } from "@services/icon.service";
import Countdown from "react-countdown-simple";
import OutOfHospitalizationWarning from "@components/modal/crownLimitToJoin.modal";
import Typewriter from "typewriter-effect";
import PutForRent from "@components/gangstabet/putForRent.component";
import LendModal from "@components/modal/rental/rental.lend.modal";
import ClaimModal from "@components/modal/rental/rental.claim.modal";
import RentDetail from "./rentDetail.component";
import UpdateModal from "@components/modal/rental/rental.update.modal";
import RemoveModal from "@components/modal/rental/rental.remove.modal";
import { NFTSkillsWRentalHistory, NFTDetail } from "./nfts.component";
import { NFT_RENT_STATUS } from "@constants/nft";
import { useNFTInformation } from "@context/nftInfoProvider";
import { useRentalMarketplace } from "@hooks/renatlMarketPlace";
import { RootState } from "@redux/store";
import { usePaginatedRentalMarket } from "@hooks/usePaginatedRentalData";
import { ZERO_ADDRESS } from "@type/rental.type";
import GangstaBetRentalLoading from "./gangstaBetRentalLoading";
import { useNavigate } from "react-router-dom";
import { DefaultEmptyGangstaBetPage, DefaultEmptyTexts } from "@pages/gangstabet/Gangstabet";

interface SelectedNFTsDetails extends NFTsDetails {
    fullAmount?: string;
    halfAmount?: string;
}

interface RecoverState {
    state: boolean;
    fullRecover: boolean;
}

const RentedGangstaBet = ({ totalPageCount }: { totalPageCount: number }) => {
    const defaultSort = useRef<any>(null);
    const { walletAddress, isLedger, ledgerPath, ledgerTransport } = useSelector(
        (state: RootState) => state.wallet
    );
    const URL = `${process.env.REACT_APP_BASE_URL}/gangwar/rental/getAddressActiveRentals/${walletAddress ?? ZERO_ADDRESS}`
    const { data, loading, loaderRef, fetchRentalMarketplaceInfo } = usePaginatedRentalMarket(URL, totalPageCount, "TENANT", walletAddress ?? ZERO_ADDRESS);
    const [defaultSortOptions, setDafaultSortOptions] = useState<boolean>(false);
    const [selectedOption, setSelectedOption] = useState<string>("Default sort");

    const [rentalModal, setRentalModal] = useState<
        "rent" | "update" | "remove" | "lend" | "claim" | null
    >(null);
    console.log({ hereData: data })
    const sortOptions = [
        // { label: "Default (Alphabetically)", value: "default" },
        // { label: "Recently Used", value: "recent" },
        // { label: "NEW SORTING FEATURE", value: "new" },
        { label: "Level (High to Low)", value: "levelHigh" },
        { label: "Level (Low to High)", value: "levelLow" },
    ];

    const {
        //     nftsDetails,
        //     gangstabetLoading,
        //     currentBatchIndex,
        //     setNftData,
        //     setCurrentBatchIndex,
        //     setRentalIndex,
        rentalInformation,
        //     setRentalInformation
    } = useNFTDetailGlobalContext();

    const {
        reFetchDetails,
        setTransactionLoading,
        userCrown,
    } = useDetailGlobalContext();
    const navigate = useNavigate()
    const [animationToggle, setAnimationToggle] = useState<boolean>(false);
    const [recover, setRecover] = useState<RecoverState>({
        state: false,
        fullRecover: false,
    });
    const [notEnoughCrown, setNotEnoughCrown] = useState(false);
    // const [selectedChar, setSelectedChar] = useState<SelectedNFTsDetails | null>(
    //     null
    // );
    const [selectedChar, setSelectedChar] = useState<any>(
        null
    );
    const [timestamp, setTimestamp] = useState<number>(0);




    const { getTxResultWithTimer } = useTransactionValidate({
        onHandle: () => { },
    });


    // comment out for removing the search box as its difficult to maintain search in infinity loading
    // const [searchTerm, setSearchTerm] = useState('');
    // const [searchResult, setSearchResult] = useState<any>([]);
    // const memoizeNFTInformation = useMemo(() => {
    //     if (!data) return []
    //     return data.map((item: any) => ({
    //         gangstaBetDetails: {
    //             ...item.gangstaBetDetails,
    //             nftId: +item.gangstaBetDetails.nftId
    //         },
    //         ...item,
    //     }))

    // }, [data]);

    // useEffect(() => {
    //     setSearchResult(memoizeNFTInformation)
    // }, [memoizeNFTInformation])

    // const searchNFTInformation = useCallback((searchKey: string) => {
    //     const normalizeSearchTerm = searchKey.trim().toLowerCase();
    //     console.log({ searchResult: memoizeNFTInformation })
    //     // Check if rentalData is null
    //     if (data === null) {
    //         return;
    //     }
    //     const searchResult = normalizeSearchTerm === '' ? memoizeNFTInformation : memoizeNFTInformation.filter((item: any) => item.gangstaBetDetails.nftId.toString().includes(normalizeSearchTerm) || item.gangstaBetDetails.nftName.toLowerCase().includes(normalizeSearchTerm))
    //     setSearchResult(searchResult)
    // }, [memoizeNFTInformation]);

    // const handleNFTInformationSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     const value = e.target.value;
    //     setSearchTerm(value);
    //     searchNFTInformation(value);
    // }

    useCardAnimation("rightSection", animationToggle);
    const confirmHandle = async () => {
        setRecover({
            state: false,
            fullRecover: false,
        });
        setTransactionLoading((prev) => ({
            ...prev,
            state: "loading",
            status: true,
            message: recover.fullRecover
                ? MessageText.OUT_OF_HOSPITALIZATION_FULL
                : MessageText.OUT_OF_HOSPITALIZATION_HALF,
            title: "Recovering...",
        }));
        const fullRecovery = recover.fullRecover ?? false;
        // const value = await getCoolDownFee(selectedChar?.nftId, "0x1");
        // console.log("value", value);
        const value = recover.fullRecover
            ? selectedChar?.fullAmount
            : selectedChar?.halfAmount;
        const res: any = await outOfHospitalization(
            timestamp,
            walletAddress,
            selectedChar?.gangstaBetDetails?.nftId,
            fullRecovery,
            value,
            isLedger,
            ledgerPath,
            ledgerTransport
        );
        setSelectedChar(null);
        fetchRentalMarketplaceInfo();
        if (isLedger) {
            if (res?.name === "TransportStatusError") {
                console.log("Ledger cancelled!!!");
            } else if (res) {
                getTxResultWithTimer(res as string);
            } else {
                console.log("Error on ledger");
            }
        }
    };
    const warStartTime = ({ days, hours, minutes, seconds }: any) => {
        if (days === 0 && hours === 0 && minutes === 0 && seconds === 0) {

        }
        return (
            <div>
                {hours < 10 ? "0" + hours : hours} hr{" "}
                {minutes < 10 ? "0" + minutes : minutes} min{" "}
                {seconds < 10 ? "0" + seconds : seconds} sec
            </div>
        );
    };

    return (
        <>
            {/* <>
                {searchResult ? searchResult.length > 0 ? searchResult.map((item: any) => {
                    return <div className="text-green">{item.gangstaBetDetails.token_name}</div>
                }) : <div className="text-red">No data found</div> : <div className="text-white">Loading...</div>}
            </> */}
            {/* {nftsDetails.length > 0 && !gangstabetLoading && !isLoading ? ( */}
            {data && data.length > 0 ? (
                <div className="flex justify-start  mx-auto">
                    <div className=" gangstabetNftList border-r border-gray-600">
                        <div className="flex">
                            <div className="body">
                                {data?.length > 0 ? (
                                    <div className="flex flex-col gap-7">
                                        <div className="flex justify-center flex-wrap gap-5 m-5 cardsSection">
                                            {data?.map((item: any) => (
                                                <div
                                                    className="flex flex-col gap-1 items-center justify-start h-content"
                                                    onClick={async () => {
                                                        PLAY_NORMAL_CLICK();
                                                        setAnimationToggle(
                                                            item === selectedChar
                                                                ? animationToggle
                                                                : !animationToggle
                                                        );
                                                        if (Number(item.hospitalization.warCooldown) / 1000 > Date.now()
                                                        ) {
                                                            const timer = new Date().getTime() * 1000;
                                                            setTimestamp(timer);
                                                            const fullAmount: string = await getCoolDownFee(
                                                                item.gangstaBetDetails.nftId,
                                                                "0x1",
                                                                timer.toString()
                                                            );
                                                            const halfAmount = await getCoolDownFee(
                                                                item.gangstaBetDetails.nftId,
                                                                "0x0",
                                                                timer.toString()
                                                            );
                                                            console.log({ fullAmount })
                                                            setSelectedChar({
                                                                ...item,
                                                                fullAmount: fullAmount,
                                                                halfAmount: halfAmount,
                                                            });
                                                        } else {
                                                            setSelectedChar(item);
                                                        }
                                                    }}
                                                >
                                                    <NFTCard
                                                        cardType="nftCardImageCollection"
                                                        nftImage={item.gangstaBetDetails.nftImage ?? TEST_NFTS}
                                                        width="140px"
                                                        height="180px"
                                                        showCard={false}
                                                        showTimer={false}
                                                        rental={
                                                            {
                                                                cardSize: "NFTOnRentMd",
                                                                rentalType: item.rental.status,
                                                                rentalTimer: item.rental.expirationTimestamp,
                                                                rentalName: item.rental.rentalName,
                                                            }
                                                        }
                                                        hospitalized={{
                                                            cardSize: "NFTOnIllMd",
                                                            hospitalizedTimer: item.hospitalization.warCooldown,

                                                        }}
                                                        locked={
                                                            item.lockStatus && {
                                                                cardSize: "NFTOnIllMd",
                                                            }
                                                        }
                                                    />
                                                    <div className="text-gray text-center truncate w-[140px]">
                                                        {item.gangstaBetDetails.nftName}
                                                    </div>
                                                </div>
                                            ))}
                                            {loading &&
                                                <GangstaBetRentalLoading />
                                            }
                                            <div key={"Rented gangstaBet"} ref={loaderRef} style={{ height: '10px' }} />
                                        </div>
                                        {/* {currentBatchIndex < Math.ceil(ownedNft.length / 5) && (
                                            <p className="text-white self-center text-center batchLoading  flex text-lg">
                                                Loading{" "}
                                                <Typewriter
                                                    options={{
                                                        strings: ["..."],
                                                        autoStart: true,
                                                        loop: true,
                                                        cursor: ".",
                                                    }}
                                                />{" "}
                                            </p>
                                        )} */}
                                    </div>
                                ) : (
                                    // <div className="flex flex-col gap-2  items-center justify-center">
                                    //     <NFTCard
                                    //         cardType="nftCardImageCollection"
                                    //         nftImage={GB_HAT}
                                    //         height={"180px"}
                                    //         width={"142px"}
                                    //         className="inactive-image2"
                                    //     />
                                    //     <p className="text-white text-3xl big-shoulders uppercase">
                                    //         No NFT found
                                    //     </p>
                                    //     <p className="text-gray w-2/3 text-center">
                                    //         It seems that your search didn't return any results.
                                    //         Please check if you entered the correct NFT name or ID.
                                    //     </p>
                                    // </div>
                                    <></>
                                )}
                            </div>
                        </div>

                    </div>
                    {selectedChar ? (
                        <div className="overflow-hidden right-section-width">
                            <div className="right-section animation" id="rightSection">
                                <div className="flex-center gap-6 border-b border-b-gray-600">
                                    <div className="flex-center gap-6 p-5">
                                        <NFTCard
                                            cardType="nftCardImageCollection"
                                            nftImage={selectedChar.gangstaBetDetails.nftImage ?? TEST_NFTS}
                                            width={"140px"}
                                            height={"180px"}
                                            isSelected
                                            rental={
                                                {
                                                    cardSize: "NFTOnRentMd",
                                                    rentalType: selectedChar.rental.status,
                                                    rentalTimer: selectedChar.rental.expirationTimestamp,
                                                    rentalName: selectedChar.rental.rentalName,
                                                }
                                            }
                                            locked={
                                                selectedChar.lockStatus && {
                                                    cardSize: "NFTOnIllMd",
                                                }
                                            }
                                            hospitalized={{
                                                cardSize: "NFTOnIllMd",
                                                hospitalizedTimer: selectedChar.hospitalization.warCooldown,

                                            }}

                                        />
                                        <NFTDetail selectedChar={selectedChar} />
                                    </div>
                                </div>
                                {Number(selectedChar.hospitalization.warCooldown) / 1000 > Date.now() && (
                                    <div
                                        className={` ${Math.ceil(
                                            Number(selectedChar?.fullAmount) / 10 ** 18
                                        ) <= 0 &&
                                            "disable"} border-b flex justify-between outOfHospitalization px-5  border-b-gray-600`}
                                    >
                                        <div className="flex items-center justify-center flex-col gap-3">
                                            <button
                                                onClick={() => {
                                                    if (
                                                        userCrown >=
                                                        Number(selectedChar?.fullAmount) / 10 ** 18
                                                    ) {
                                                        setRecover({
                                                            state: true,
                                                            fullRecover: true,
                                                        });
                                                    } else {
                                                        setNotEnoughCrown(true);
                                                    }
                                                }}
                                                className="fullRecoverBtn flex items-center justify-center"
                                            >
                                                <BsHeartFill color="red" /> Recovery{" "}
                                                <img src={CROWN} alt="icon" className="h-4" />{" "}
                                                {Math.ceil(Number(selectedChar?.fullAmount) / 10 ** 18)}
                                            </button>
                                            {/* <button
                            onClick={() => {
                              if (
                                userCrown >=
                                Number(selectedChar.fullAmount) / 10 ** 18
                              ) {
                                setRecover({
                                  state: true,
                                  fullRecover: false,
                                });
                              } else {
                                setNotEnoughCrown(true);
                              }
                            }}
                            className="halfRecoverBtn flex items-center justify-center "
                          >
                            <BsHeartHalf color="red" /> Half Recovery{" "}
                            <img src={CROWN} alt="icon" className="h-4" />{" "}
                            {Math.ceil(
                              Number(selectedChar?.halfAmount) / 10 ** 18
                            )}
                          </button> */}
                                        </div>
                                        <div className="recoveryBtnSeparator"></div>
                                        <div className="recoveeryTimerBox flex gap-2 items-center">
                                            <MdTimer className="bg-grey" color="#70878f" />
                                            <div className="recoveryTimer">
                                                <p className="recoveryTimerText">Auto recover in:</p>
                                                <Countdown
                                                    targetDate={new Date(
                                                        new Date(
                                                            (selectedChar.hospitalization.warCooldown as number) / 1000
                                                        ).setHours(
                                                            new Date(
                                                                (selectedChar.hospitalization.warCooldown as number) / 1000
                                                            ).getHours()
                                                        )
                                                    ).toISOString()}
                                                    disableTypes={true}
                                                    renderer={warStartTime}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {/* {selectedChar?.cooldownActivated && (
                      <div className="border-b border-b-gray-600 ">
                        <div className="py-4 text-center">
                          <PrimaryButton
                            width="300px"
                            buttonName="FULL RECOVEREY"
                            icon={<FaHeart className="text-red" />}
                            extraIcon={
                              <img src={CROWN} alt="icon" className="h-4" />
                            }
                            extraText={selectedChar.fullAmount?.toString()}
                          />
                          <PrimaryButton
                            width="300px"
                            buttonName="HALF RECOVEREY"
                            icon={<FaHeart className="text-red" />}
                            extraIcon={
                              <img src={CROWN} alt="icon" className="h-4" />
                            }
                            extraText={selectedChar.halfAmount?.toString()}
                            onClick={() => setRecover(true)}
                          />
                        </div>
                      </div>
                    )} */}
                                {!selectedChar.lockStatus && <>
                                    {selectedChar.rental?.status === NFT_RENT_STATUS.NOT_AVAILABLE_FOR_RENT ?
                                        <PutForRent setRentalModal={setRentalModal} />
                                        : <RentDetail setRentalModal={setRentalModal} selectedChar={selectedChar} />
                                    }
                                </>}
                                {/* {!Boolean(Number(selectedChar.rental.status)) ?
                                    <PutForRent setRentalModal={setRentalModal} />
                                    : <RentDetail setRentalModal={setRentalModal} selectedChar={selectedChar} />
                                } */}
                                <NFTSkillsWRentalHistory selectedChar={selectedChar} />
                            </div>
                        </div>
                    ) : (
                        <div className="flex-center self-center nftSelectedCard justify-center  h-full px-12">
                            <div className="text-3xl uppercase text-gray-700 text-center big-shoulders my-auto font-bold">
                                Select an NFT to see Details
                            </div>
                        </div>
                    )}
                </div>
                // gangstabetLoading || isLoading
            ) : loading && data.length === 0 ? (
                // Loading
                <div className="flex min-w-[1400px] flex-col gap-2 w-full h-full items-center justify-center">
                    <img width={220} src={LOADING} alt="loading circle" />
                </div>
            ) : !loading && data.length === 0 && (
                // Empty Inventory
                <div className="min-w-[1400px] flex justify-center items-center min-h-[500px]">

                    <DefaultEmptyGangstaBetPage buttonName="Rent GangstaBet" title={DefaultEmptyTexts.RENTED_PAGE.title} description={DefaultEmptyTexts.RENTED_PAGE.description} linkTo={() => { navigate(DefaultEmptyTexts.RENTED_PAGE.link) }} />
                </div>
            )}

            {/* modals */}
            {recover.state && !notEnoughCrown && (
                <CommonModal
                    isVisible={recover.state}
                    setIsVisible={() => {
                        setRecover({
                            state: false,
                            fullRecover: false,
                        });
                    }}
                    approveAction={"Recover"}
                    confirmHandle={confirmHandle}
                    icon={<img src={CROWN} alt="icon" className="h-5" />}
                    extraText={
                        <span className="text-yellow">
                            {recover.fullRecover
                                ? Math.ceil(Number(selectedChar?.fullAmount) / 10 ** 18)
                                : Math.ceil(Number(selectedChar?.halfAmount) / 10 ** 18)}
                        </span>
                    }
                    textInformation={
                        recover.fullRecover
                            ? "Use CROWN to recover your GangstaBet instantly."
                            : "Use CROWN to reduce your GangstaBet's recovery time to half."
                    }
                />
            )}
            {notEnoughCrown && (
                <OutOfHospitalizationWarning
                    isVisible={notEnoughCrown}
                    setIsVisible={setNotEnoughCrown}
                    fee={
                        recover.fullRecover
                            ? Number(selectedChar?.fullAmount)
                            : Number(selectedChar?.halfAmount)
                    }
                />
            )}
            {/* Rental */}
            {rentalModal === "lend" && (
                <LendModal
                    selectedChar={selectedChar}
                    isVisible={rentalModal === "lend"}
                    setRentalModal={setRentalModal}
                    maxDaysForRent={rentalInformation.maxRentDay}
                />
            )}
            {rentalModal === "update" && (
                <UpdateModal
                    selectedChar={selectedChar}
                    isVisible={rentalModal === "update"}
                    setRentalModal={setRentalModal}
                    maxDaysForRent={rentalInformation.maxRentDay}
                />
            )}
            {rentalModal === "remove" && (
                <RemoveModal
                    selectedChar={selectedChar}
                    isVisible={rentalModal === "remove"}
                    setRentalModal={setRentalModal}
                />
            )}
            {rentalModal === "claim" && (
                <ClaimModal
                    isVisible={rentalModal === "claim"}
                    setRentalModal={setRentalModal}
                />
            )}

        </>
    );
};

export default RentedGangstaBet;
