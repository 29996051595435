import Modal from "@components/modal/modal.component";
import { useState, useEffect } from "react";
import { availableCrown, getICXBalance, handleLogin } from "@services/icon.service";
import { ICONEX, LEDGER, HANA_LOGO } from "@constants/images";
import PrimaryButton from "@components/buttons/primary.button";
import Transport from "@ledgerhq/hw-transport-webhid";
import AppIcx from "@ledgerhq/hw-app-icx";
import LedgerAddressModal from "./ledgerAddress.modal";
import { loadAddresses, connectToLedger, onNext, onPrevious, firstPage } from "@utils/ledger";

import {
    setLedgerPath,
    setLedgerTransport,
    setWalletAddress,
    setIsLedger,
} from "@redux/features/walletSlice";
import { setAvailableCrown, setAvailableICX, setOwnedNFTs } from "@redux/features/inventorySlice";
import { useDispatch } from "react-redux";
import { PLAY_CLOSE_SOUND, PLAY_NORMAL_CLICK, PLAY_INVENTORY_CLICK } from "@constants/audio";
import { getOwnedNft } from "@services/icon.service";
import { HYPERLINKS } from "@misc/links";

const ConnectModal = ({ isVisible, setIsVisible }) => {
    const [ledgerConnection, setLedgerConnection] = useState(false);
    // Ledger
    const [transport, setTransport] = useState(null);
    const [icx, setIcx] = useState(null);
    const [walletPaths, setWalletPaths] = useState([]);
    const [hasLedgerSupport, setHasLedgerSupport] = useState(false);
    const [isAddressLoading, setIsAddressLoading] = useState(false);
    const [isLedgerConnecting, setLedgerConnecting] = useState(false);
    const [isLedgerConnected, setIsLedgerConnected] = useState(false);

    // Pagination
    const [currPage, setCurrPage] = useState(1);
    const [wallets, setWallets] = useState([]);
    const addressPerPage = 8;

    const dispatch = useDispatch();

    const loadLedgerAddress = (suppressError, icx) => {
        loadAddresses(
            suppressError,
            icx,
            setIsAddressLoading,
            addressPerPage,
            currPage,
            setIsLedger,
            setWallets,
            setWalletPaths
        );
    };

    const connectLedger = () => {
        connectToLedger(
            setLedgerConnecting,
            Transport,
            setTransport,
            AppIcx,
            setIcx,
            setIsLedgerConnected,
            setHasLedgerSupport,
            setIsAddressLoading,
            addressPerPage,
            currPage,
            setIsLedger,
            setWallets,
            setWalletPaths
        );
    };

    useEffect(() => {
        if (isLedgerConnected && hasLedgerSupport) {
            loadLedgerAddress(false, icx);
        }
    }, [currPage, isLedgerConnected, hasLedgerSupport]);

    useEffect(() => {
        if (wallets.length) {
            setLedgerConnection(true);
        }
    }, [wallets]);

    const handleConfirmation = async (selectedWallet, path) => {
        dispatch(setWalletAddress(selectedWallet));
        dispatch(setLedgerPath(path));
        dispatch(setLedgerTransport(transport));
        const nftListPromises = [];
        const crown = await availableCrown(selectedWallet);
        dispatch(setAvailableCrown(crown));
        const icx = await getICXBalance(selectedWallet);
        dispatch(setAvailableICX(icx));
        //todo
        // 1.change total_page to totalPage and nft_list to nftList
        //also dont forget to change in icon service
        const ownedNFTs = await getOwnedNft(selectedWallet);
        for (let i = 1; i <= Number(ownedNFTs.totalPage); i++) {
            const ownedNFTs = getOwnedNft(selectedWallet, `0x${i.toString(16)}`);
            nftListPromises.push(ownedNFTs);
        }
        const nftList = [];
        await Promise.all(nftListPromises).then((values) => {
            for (let i = 0; i < values.length; i++) {
                nftList.push(...values[i].nftList);
            }
        });
        dispatch(setOwnedNFTs(nftList));
        setCurrPage(1);
    };

    const handleClose = () => {
        setLedgerConnection(false);
        setCurrPage(1);
    };

    // const onNext = () => {
    //   setCurrPage((prev) => prev + 1);
    // };

    // const onPrevious = () => {
    //   setCurrPage((prev) => prev - 1);
    // };

    // const firstPage = () => {
    //   setCurrPage(1);
    // };

    return (
        <>
            <Modal isVisible={isVisible} setIsVisible={setIsVisible} modalTitle="Connect With">
                {isLedgerConnecting ? (
                    <p className="ledger-connecting-text" style={{ textAlign: "center" }}>
                        Ledger is connecting...
                    </p>
                ) : (
                    <div className="wallet-connect-options-wrapper">
                        <div className="wallet-connect-options">
                            <div
                                onClick={() => {
                                    PLAY_NORMAL_CLICK();
                                    handleLogin();
                                    setIsVisible(false);
                                }}
                                className="connect-icon-div"
                            >
                                <img className="connect-icon" src={HANA_LOGO} alt="icon-logo" />
                                <p>Hana</p>
                            </div>
                            <div className="connect-or-line">
                                <hr className="or-seperator" />
                                <p>or</p>
                                <hr className="or-seperator" />
                            </div>
                            <div
                                onClick={() => {
                                    PLAY_NORMAL_CLICK();
                                    connectLedger();
                                }}
                                className="connect-ledger-div"
                            >
                                <img className="connect-ledger" src={LEDGER} alt="ledger-logo" />
                                <p>Ledger</p>
                            </div>
                        </div>
                        <div className="connect-revert-btn">
                            <PrimaryButton
                                onClick={() => {
                                    PLAY_CLOSE_SOUND();
                                    setIsVisible(false);
                                }}
                                buttonName="Cancel"
                            />
                        </div>
                        <p className="text-md text-white  whitespace-nowrap ml-[-118px] opacity-70 mt-3">
                            If you do not have Hana installed, please download from{" "}
                            <span
                                className="text-blue cursor-pointer"
                                onClick={() => {
                                    window.open(HYPERLINKS.HANA_EXTENSION_LINK, "_blank");
                                }}
                            >
                                here
                            </span>{" "}
                            and try again.
                        </p>
                    </div>
                )}
            </Modal>
            {ledgerConnection && (
                <LedgerAddressModal
                    isVisible={ledgerConnection}
                    setIsVisible={setLedgerConnection}
                    onClose={handleClose}
                    onConfirm={handleConfirmation}
                    onNext={() => onNext(setCurrPage)}
                    onPrevious={() => onPrevious(setCurrPage)}
                    firstPage={() => firstPage(setCurrPage)}
                    currentPage={currPage}
                    addressList={wallets}
                    walletPaths={walletPaths}
                    isAddressLoading={isAddressLoading}
                    setConnectWalletPopup={setIsVisible}
                />
            )}
        </>
    );
};

export default ConnectModal;
