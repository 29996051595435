import Modal from "@components/modal/modal.component";
import PrimaryButton from "@components/buttons/primary.button";
import { TRANSACTION_LOADING } from "@constants/images";
import { useDetailGlobalContext } from "@context/detailProvider";
import toasterService from "@components/toast/ToasterService.component";
import Typewriter from "typewriter-effect";
import { useNFTDetailGlobalContext } from "@context/nftInformation";
import { ResponseMessage } from "@misc/message/messageText";
import { useNFTInformation } from "@context/nftInfoProvider";

const LoadingModal = ({
  isVisible,
  setIsVisible,
  message,
  state,
  title,
  navigate,
  closeScreen,
}: any) => {
  const {
    reFetchDetails,
    setRefetchData,
    reFetchChangeData,
  } = useDetailGlobalContext();
  const { reRenderCollectionToggler, setRerenderCollectionToggler } = useNFTInformation();
  const { setNftData, setRentNftData, setRentalIndex, setCurrentRentalBatchIndex, setCurrentBatchIndex } = useNFTDetailGlobalContext();
  return (
    <Modal
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      modalTitle={
        title === "loading" || title === "Unlocking" ? (
          <div className={`flex ${title === "Unlocking" && "justify-center"}`}>
            <p>{title}</p>
            <Typewriter
              options={{
                strings: ["..."],
                autoStart: true,
                loop: true,
                cursor: ".",
              }}
            />
          </div>
        ) : (
          title
        )
      }
    >
      <div className="connect-success">
        <p className="text-center wallet-connected-success flex flex-col items-center justify-center gap-5">
          {state === "loading" ? (
            <>
              <img
                height={40}
                width={100}
                src={TRANSACTION_LOADING}
                alt="loading"
                className="custom-spinner"
              />
              {message}
            </>
          ) : state === "success" ? (
            <>
              {ResponseMessage(message)?.message}
              <PrimaryButton
                onClick={() => {
                  setRerenderCollectionToggler(!reRenderCollectionToggler);
                  setRentalIndex(null);
                  setNftData([]);
                  setCurrentBatchIndex(0);
                  // setChangeDataToggle(!changeDataToggle);
                  setIsVisible();
                  setRefetchData(!reFetchChangeData);
                  reFetchDetails();
                  closeScreen();
                  // navigate(navigate);
                  // toasterService.set({
                  //   title: "Check Lobby",
                  //   message: `Please Check lobby`,
                  //   type: "warn",
                  //   onClick: () => {
                  //     console.log("test");
                  //   },
                  //   toastSize: "toastLarge",
                  // });
                }}
                buttonName={`${ResponseMessage(message)?.buttonName ??
                  "Continue"}`}
              />
            </>
          ) : (
            state === "failed" && (
              <>
                {message}
                <PrimaryButton
                  onClick={() => setIsVisible()}
                  buttonName="Cancel"
                />
              </>
            )
          )}
        </p>
      </div>
    </Modal>
  );
};

export default LoadingModal;
