import { Routes, Route, Navigate } from "react-router-dom";
import { Leaderboard as LeaderboardComponent } from "./components/Leaderboard";
import { LeaderboardProvider } from "./context/LeaderboardContext";
import LEADERBOARD_ROUTES from "./routes.leaderboard";
import Seo from "@components/seo";

export default function Leaderboard() {
    return (
        <>
            <Seo title="Leaderboard | Gangwars" description="Weekly/Monthly winners of Gangwars" />
            <LeaderboardProvider>
                <Routes>
                    <Route path={""} element={<LeaderboardComponent defaultInterval="weekly" />} />
                    <Route
                        path={LEADERBOARD_ROUTES.WEEKLY}
                        element={<LeaderboardComponent defaultInterval="weekly" />}
                    />
                    <Route
                        path={LEADERBOARD_ROUTES.MONTHLY}
                        element={<LeaderboardComponent defaultInterval="monthly" />}
                    />
                    <Route
                        path={LEADERBOARD_ROUTES.ALL}
                        element={<LeaderboardComponent defaultInterval="all" />}
                    />
                    <Route
                        path={LEADERBOARD_ROUTES.SEASONAL}
                        element={<LeaderboardComponent defaultInterval="seasonal" />}
                    />
                    <Route
                        path="*"
                        element={
                            <Navigate to={LEADERBOARD_ROUTES.BASE + LEADERBOARD_ROUTES.WEEKLY} />
                        }
                    />
                </Routes>
            </LeaderboardProvider>
        </>
    );
}
