import moment from "moment";
import { IInterval, IStats } from "./interfaces/leaderboard.interface";

export const LEADERBOARD_COLS_MAP: Record<IStats, string> = {
    wins: "Wins",
    kills: "Kills",
    gamesPlayed: "Games Played",
    heal: "Heal",
    damageDealt: "Damage Dealt",
};

export const STATS_PER_GAME_MAPPING: PartialRecord<IStats, string> = {
    wins: "Win %",
    kills: "Kills / Game",
    heal: "Heal / Game",
    damageDealt: "Dmg / Game",
};

export const flagAndLimitLeaderboard = (
    res: any,
    userNfts: Array<string>,
    maxRows: number
) => {
    return res
        .map((row: any, index: number) => {
            row.isUserNft = userNfts
                .map((id) => parseInt(id))
                .includes(parseInt(row.nftId));
            row.rank = index + 1;
            return row;
        })
        .filter((row: any, index: number) => {
            return index < maxRows || row.isUserNft;
        });
};

export const extractMonthDay = (timestamp: number) => {
    return moment(timestamp)
        .utc()
        .toString()
        .split(" ")
        .slice(1, 3)
        .join(" ");
};

export const getExtremeIntervalDates = (
    timestamp: number,
    interval: IInterval
) => {
    const period = interval.slice(0, -2) as "week" | "month";
    let intervalStart = moment(timestamp)
        .utc()
        .startOf(period)
        .startOf("day");
    let intervalEnd = moment(timestamp)
        .utc()
        .endOf(period)
        .startOf("day");

    if (interval === "weekly") {
        intervalStart = intervalStart.add(1, "day");
        intervalEnd = intervalEnd.add(1, "day");
    }

    return [intervalStart.valueOf(), intervalEnd.valueOf()];
};

export const getLastIntervalTs = (intervalFilter: IInterval) => {
    const [start, _] = getExtremeIntervalDates(Date.now(), intervalFilter);
    return moment(start)
        .utc()
        .startOf("day")
        .subtract(2, "days")
        .valueOf();
};

export const isLastPeriod = (timestamp: number, interval: IInterval) => {
    if (interval === "weekly") {
        const lastMonday = moment
            .utc()
            .startOf("week")
            .add(1, "day")
            .startOf("day");
        const lastLastMonday = lastMonday
            .clone()
            .subtract(3, "day")
            .startOf("week")
            .startOf("day")
            .add(1, "day");
        if (
            timestamp > lastLastMonday.valueOf() &&
            timestamp < lastMonday.valueOf()
        ) {
            return true;
        }
        return false;
    } else {
        const thisMonthStart = moment
            .utc()
            .startOf("month")
            .startOf("day");
        const lastMonthStart = thisMonthStart
            .clone()
            .subtract(3, "day")
            .startOf("month")
            .startOf("day");
        if (
            timestamp < thisMonthStart.valueOf() &&
            timestamp > lastMonthStart.valueOf()
        ) {
            return true;
        }
        return false;
    }
};

export const getGbLevel = (stat: string): number => {
    if (Number.isNaN(stat)) return 0;
    const computedLevel = Math.floor(parseInt(stat) / 50) + 1;
    return computedLevel <= 10 ? computedLevel : 10;
};

export const formatLeaderboardNum = (n: number, precision: number = 0) => {
    return (n < 100 && !Number.isInteger(n)) ? n.toFixed(1) : n.toFixed(precision);
};
