import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface ILobbyState {
  isTeamFormed: boolean;
}

const initialState: ILobbyState = {
    isTeamFormed: false,
};

export const lobbySlice = createSlice({
  name: "lobby",
  initialState,
  reducers: {
    setTeamFormation: (state, action: PayloadAction<boolean>) => {
      state.isTeamFormed = action.payload;
    },
  },
});

export const {
    setTeamFormation,
} = lobbySlice.actions;

export default lobbySlice.reducer;
