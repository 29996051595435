import { TABS } from "@constants/images";
import ImageLoader from "@misc/ImageLoader";
import { Tooltip } from "react-tooltip";

const warTabs = ({ setInLog, inLogType, userStatsDetails }: any) => {
  const tooltip = (id: string) => {
    return (
      <Tooltip
        delayShow={500}
        style={{ zIndex: 999, maxWidth: "300px", opacity: 1 }}
        anchorSelect={`#${id}`}
      />
    );
  };

  return (
    <div className="logsTabInWar flex justify-around items-center">
      <div
        onClick={() => {
          setInLog(0);
        }}
        className={`warTabs ${inLogType === 0 ? "" : "inActiveLogTab"}`}
        id="eventListTip"
        data-tooltip-content="Event Log"
      >
        <ImageLoader
          height="30px"
          width="30px"
          src={TABS.LOGS_ICON}
          alt="logs"
        />
        {tooltip("eventListTip")}
      </div>
      <div
        onClick={() => {
          if (userStatsDetails) setInLog(1);
        }}
        id="gbStatsTip"
        data-tooltip-content="GangstaBet Stats"
        className={`warTabs ${
          inLogType === 1 && userStatsDetails
            ? ""
            : !userStatsDetails
            ? "inActiveLogTab no-drop"
            : "inActiveLogTab "
        } `}
      >
        <ImageLoader
          height="30px"
          width="30px"
          src={TABS.USER_ICON}
          alt="logs"
        />
        {tooltip("gbStatsTip")}
      </div>
      <div
        onClick={() => {
          setInLog(3);
        }}
        className={`warTabs ${inLogType === 3 ? "" : "inActiveLogTab "} `}
        id="spdListTip"
        data-tooltip-content="Speed List"
      >
        <ImageLoader
          height="30px"
          width="30px"
          src={TABS.SPEED_ICON}
          alt="logs"
        />
        {tooltip("spdListTip")}
      </div>
      <div
        onClick={() => {
          userStatsDetails && setInLog(4);
        }}
        className={`warTabs ${
          inLogType === 4 && userStatsDetails
            ? ""
            : !userStatsDetails
            ? "inActiveLogTab no-drop"
            : "inActiveLogTab "
        } `}
        id="sneakmoveTip"
        data-tooltip-content="Add/Fill Sneak Moves"
      >
        <ImageLoader
          height="30px"
          width="30px"
          src={TABS.SNEAK_ICON}
          alt="logs"
        />
        {tooltip("sneakmoveTip")}
      </div>
      <div
        id="chatTip"
        data-tooltip-content="Chat coming soon"
        className={`warTabs ${inLogType === 5 ? "" : "inActiveLogTab"}`}
        // onClick={() => setInLog(5)}
      >
        <ImageLoader
          height="30px"
          width="30px"
          src={TABS.CHAT_ICON}
          alt="logs"
        />
        {tooltip("chatTip")}
      </div>
    </div>
  );
};

export default warTabs;
