import ImageLoader from "@misc/ImageLoader";
import ItemTooltip from "@components/tooltips/item.tooltip";
import WeaponTooltip from "@components/tooltips/weapon.tooltip";
import SneakMoves from "@components/tooltips/sneakmove.tooltip";
import { ABILITY, ITEM, WEAPON } from "@constants/images";
const GameLoadoutLobby = ({ participantsDetails }: any) => {
  return (
    <div className="flex gap-2">
      <div className="flex gap-3">
        <div className="flex gap-1">
          {participantsDetails?.equipments.map(
            (equipments: any, index: number) => {
              const equipmentsName =
                equipments?.name?.split(" ").length > 1
                  ? equipments?.name?.replace(/\s/g, "_")
                  : equipments?.name;
              const tooltipId = `${equipmentsName}_${index}_${participantsDetails?.nftDetails?.nftId}`;
              return (
                <>
                  <div
                    id={tooltipId}
                    className="takenLoadouts userloadout cursor-pointer"
                  >
                    <ImageLoader
                      style={{ padding: "3px" }}
                      src={equipments?.image}
                      alt="loadout-img"
                    />
                  </div>
                  <WeaponTooltip tooltipId={tooltipId} details={equipments} />
                </>
              );
            }
          )}
          {Array.from(
            { length: 1 - participantsDetails?.equipments?.length },
            (_, i) => {
              return (
                <div className="takenLoadouts userloadout r">
                  <ImageLoader
                    style={{ padding: "3px" }}
                    src={WEAPON}
                    alt="loadout-img"
                  />
                </div>
              );
            }
          )}
        </div>
        <div className="flex gap-1">
          {participantsDetails?.items.map((items: any, index: number) => {
            const itemsName =
              items?.name?.split(" ").length > 1
                ? items?.name?.replace(/\s/g, "_")
                : items?.name;
            const tooltipId = `${itemsName}_${index}_${participantsDetails?.nftDetails?.nftId}`;
            return (
              <>
                <div
                  id={`${tooltipId}`}
                  className="takenLoadouts userloadout cursor-pointer"
                >
                  <ImageLoader
                    style={{ padding: "3px" }}
                    src={items?.image}
                    alt="loadout-img"
                  />
                </div>
                <ItemTooltip
                  key={`itemtooltip_${Math.random() * 100000}`}
                  details={items}
                  tooltipId={tooltipId}
                />
              </>
            );
          })}
          {Array.from(
            { length: 3 - participantsDetails?.items?.length },
            (_, i) => {
              return (
                <div className="takenLoadouts userloadout r">
                  <ImageLoader
                    style={{ padding: "3px" }}
                    src={ITEM}
                    alt="loadout-img"
                  />
                </div>
              );
            }
          )}
        </div>
        <div className="flex flex-1 gap-1">
          {participantsDetails?.abilities.map(
            (abilities: any, index: number) => {
              const abilitiesName =
                abilities?.name?.split(" ").length > 1
                  ? abilities?.name?.replace(/\s/g, "_")
                  : abilities?.name;
              const tooltipId = `${abilitiesName}_${index}_${participantsDetails?.nftDetails?.nftId}`;
              return (
                <>
                  <div
                    id={`${tooltipId}`}
                    className="takenLoadouts userloadout cursor-pointer"
                  >
                    <ImageLoader
                      style={{ padding: "3px" }}
                      src={abilities?.image}
                      alt="loadout-img"
                    />
                  </div>
                  <SneakMoves
                    key={`sneakmoves_${Math.random() * 100000}`}
                    details={abilities}
                    tooltipId={tooltipId}
                  />
                </>
              );
            }
          )}
          {Array.from(
            { length: 3 - participantsDetails?.abilities?.length },
            (_, i) => {
              return (
                <div className="takenLoadouts userloadout r">
                  <ImageLoader
                    style={{ padding: "3px" }}
                    src={ABILITY}
                    alt="loadout-img"
                  />
                </div>
              );
            }
          )}
        </div>
      </div>
    </div>
  );
};

export default GameLoadoutLobby;
