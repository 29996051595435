export type SpecificGameTags =
    | "GangstaBetType"
    | "GenderType"
    | "AccessoryType"
    | "WeaponsType"
    | "GeneralType";
type warSpecific = { [key: string]: string };
export const GangstaBetType: warSpecific = {
    "0": "Human",
    "1": "Cyborg",
    "2": "Zombie",
    "3": "Alien",
    "4": "Dinosaur",
    "5": "Ghost",
};
export const GenderType: warSpecific = {
    "0": "Male",
    "1": "Female",
};

export const AccessoryType: warSpecific = {
    "0": "Beer",
    "1": "Pistol",
    "2": "Revolver",
    "3": "BrownAndGoldCane",
    "4": "SawedoffShotgun",
    "5": "None",
    "6": "MachineGun",
    "7": "MolotovCocktail",
    "8": "Shotgun",
    "9": "BrownAndSilverCane",
    "10": "LeatherHandbag",
    "11": "BlackAndSilverCane",
    "12": "BlackAndGoldCane",
    "13": "Cocktail",
    "14": "M1911",
};

export const WeaponType: warSpecific = {
    "1": "Dagger",
    "2": "Baton",
    "3": "Frying Pan",
    "4": "Axe",
    "5": "Hammer",
    "6": "Machete",
    "7": "Katana",
    "8": "Hand Gun",
    "9": "Rifle",
    "10": "Carbine",
    "11": "Tommy Gun",
    "12": "Remmington 870",
    "13": "Grenade",
    "14": "P&R Flame Bottles",
    "15": "Flamethrower",
    "16": "Dynamite",
    "18": "Crossbow",
    "19": "Nunchucks",
    "20": "Whip",
    "21": "Musket",
    "22": "Pipe Bomb",
    "23": "Canesword",
    "24": "Crowbar",
    "25": "Knuckles",
};

export const GangstaBetTypeCount = () => {
    return Object.keys(GangstaBetType).length;
};
export const GenderTypeCount = () => {
    return Object.keys(GenderType).length;
};
export const WeaponTypeCount = () => {
    return Object.keys(WeaponType).length;
};
export const AccessoryTypeCount = () => {
    return Object.keys(AccessoryType).length;
};
