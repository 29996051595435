import StylizedButton from "@components/buttons/stylized.button";
import TeamFormation from "@components/lobbyScreen/teamFormation.modal";
import Modal from "@components/modal/modal.component";
import { LOADING } from "@constants/images";
import { useWarListGlobalContext } from "@context/war";
import { getSimulationDetail } from "@services/icon.service";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import Typewriter from "typewriter-effect";

const Loading = ({ warId }: any) => {
  const navigate = useNavigate();
  const { setTeamSeparation, teamSeparation } = useWarListGlobalContext();
  async function getTeamSeperation() {
    const result = await getSimulationDetail(warId);
    if (
      result.teamA.length > 0 &&
      result.teamB.length > 0 &&
      teamSeparation.teamA.length === 0 &&
      teamSeparation.teamB.length === 0
    ) {
      setTeamSeparation({ teamA: result.teamA, teamB: result.teamB });
    }
  }
  useEffect(() => {
    getTeamSeperation();
  }, []);

  return (
    <div className="loading">
      {/* <img src={LOADING} style={{ maxWidth: "220px" }} /> */}
      {/* <Modal
        isVisible={true}
        modalTitle={
          <div className="flex items-center justify-center">
            <p className="text-uppercase">Starting War</p>
          </div>
        }
      >
        <div className="flex gap-3 flex-col items-center">
          <div className="flex flex-col justify-center items-center">
            <img src={LOADING} style={{ width: "100px" }} />

            <p className="text-white preparingWarText">
              <Typewriter
                options={{
                  strings: [
                    "MAKE SURE YOU ARE LOADED",
                    "KILL THEM ENEMIES",
                    "STEAL THE LOOTS",
                  ],
                  autoStart: true,
                  loop: true,
                  cursor: "",
                }}
              />
            </p>
          </div>
          <div className="">
            <StylizedButton
              onClick={() => {
                navigate("/war");
              }}
              buttonName="Go Back"
            />
          </div>
        </div>
      </Modal> */}
      <TeamFormation
        closeHandler={() => {
          navigate("/war");
        }}
        isVisible={true}
        teamSeparation={teamSeparation}
      />
    </div>
  );
};

export default Loading;
