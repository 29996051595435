import { useState } from "react";
import L from "leaflet";
import { Polygon, Marker } from "react-leaflet";
import { MARKER, MAP_WAR_MARKER_UPCOMING } from "@constants/images";
import WarDetailsSidebar from "@pages/warDetails.sidebar";
import UpcomingSidebar from "./upcomigSidebar";

const UpcomingPolygon = (props: any) => {
  const { war, pathOptions, eventHandlers, positions, id } = props;
  console.log("war------------------", war);
  const [open, setOpen] = useState(false);
  const [warDetail, setWarDetail] = useState<any>();
  const center = L.polygon(positions)
    .getBounds()
    .getCenter();
  const [changeIcon, setChangeIcon] = useState(false);
  const customMarker = new L.Icon({
    iconUrl: open ? MARKER : MAP_WAR_MARKER_UPCOMING,
    iconSize: [40, 80],
  });

  const handleMarkerClick = (item: any) => {
    setWarDetail(item);
    setChangeIcon(true);
    setOpen(true);
  };

  return (
    <>
      <Polygon
        pathOptions={pathOptions}
        eventHandlers={eventHandlers}
        positions={positions}
      >
        <Marker
          key={`marker-`}
          position={{
            lat:
              war.coordinates &&
              typeof war.coordinates === "string" &&
              war.coordinates.split(",")
                ? Number(war.coordinates.split(",")[0].replace(" ", ""))
                : 0,
            lng:
              war.coordinates &&
              typeof war.coordinates === "string" &&
              war.coordinates.split(",")
                ? Number(war.coordinates.split(",")[1].replace(" ", ""))
                : 0,
          }}
          icon={customMarker}
          eventHandlers={{
            click: () => {
              handleMarkerClick(war);
            },
          }}
        ></Marker>
        {open && (
          <UpcomingSidebar
            warDetail={warDetail}
            open={open}
            setOpen={setOpen}
            id={id}
          />
        )}
      </Polygon>
    </>
  );
};

export default UpcomingPolygon;
