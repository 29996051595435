import { sendMessage } from '@services/firebase.service';
import React, { useState, useRef } from 'react'
import { MdEmojiEmotions, MdSend } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import EmojiPicker from './EmojiPicker';

const ChatInput = ({ chatRoomId, userStatsDetails, playersInfo }: any) => {
  const [inputMessage, setInputMessage] = useState('');
  const textInputRef = useRef<HTMLInputElement>(null)
  const [isIconPickerOpened, setIsIconPickerOpened] = useState(false);
  const { walletAddress } = useSelector(
    (state: any) => state.wallet
  );
  const location = useLocation()
  const emojiWrapperRef = useRef<HTMLDivElement>(null)

  const handleChange = (e: any) => {
    const val = e.target.value
    const sanitizedVal = val.replace(/\s+/g, ' ');
    setInputMessage(sanitizedVal);
  }

  const getUser = () => {
    let currUser = null
    for(const teamKey in playersInfo) {
        if(Object.prototype.hasOwnProperty.call(playersInfo, teamKey)) {
            const team = playersInfo[teamKey];
            for(const member of team) {
                const playerTokenId = parseInt(member.token_id, 16)
                if(playerTokenId == userStatsDetails?.nftId) {
                    currUser = member;
                    break;
                }
            }
            if(currUser) break;
        }
    }
    return currUser
  }

  const handleSend = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if(!inputMessage || inputMessage === ' ') return;
    setIsIconPickerOpened(false);
    const userDetails = getUser();
    const user = {
        uid: parseInt(userDetails?.token_id, 16),
        displayName: `Gangstabet #${parseInt(userDetails?.token_id, 16)}`
    }
    sendMessage(`gangwars#${chatRoomId}`, user, inputMessage)
    setInputMessage('');
  }

  const disableInput = () => {
    const userDetail = getUser();
    if(location.pathname.includes('rewatch')){
        return true
    } else if (userStatsDetails?.nftId !== parseInt(userDetail?.token_id, 16)) {
        return true
    } else {
        return false
    }
  }

  const addIconToInput = (value: string) => {
    const start = textInputRef.current?.selectionStart as number;
    const end = textInputRef.current?.selectionEnd as number;
    const splitted = inputMessage.split("");
    splitted.splice(start, end - start, value);
    setInputMessage(splitted.join(""));
  };

  return (
    <div className="input-container">
        <form onSubmit={handleSend}>
        <div className={`emoji-button ${isIconPickerOpened && 'emoji-select'}`}>
            <span onClick={() => {
                if(!disableInput()) {
                    setIsIconPickerOpened(prev => !prev)
                }
            }}>
                <MdEmojiEmotions
                    size={20}
                    color='inherit'
                />
            </span>
        </div>
        {isIconPickerOpened &&(
              <div ref={emojiWrapperRef} className="emoji-wrapper">
                  <EmojiPicker onEmojiSelect={(emoji) => addIconToInput(emoji.native)} />
              </div>
        )}
        <div className="chat-inputField">
            <input 
                type="text" 
                className='chat-input' 
                value={inputMessage} 
                onChange={handleChange} 
                disabled={disableInput()}
                ref={textInputRef}
            />
        </div>
            
        <button type='submit' className='message-sendBtn'>
            <MdSend
                size={20}
                color={"#70878F"}
            />
        </button>
        </form>
    </div>
  )
}

export default ChatInput