import { useDispatch, useSelector } from "react-redux";
import { setLoadingState } from "@redux/features/stateSlice";
import {
  setWalletConnection,
  setWalletAddress,
  setTempWalletAddress,
} from "@redux/features/walletSlice";
import {
  setOwnedNFTs,
  setAvailableICX,
  setAvailableCrown,
} from "@redux/features/inventorySlice";
import { useEffect } from "react";
import {
  availableCrown,
  getICXBalance,
  getOwnedNft,
} from "@services/icon.service";
import { RootState } from "@redux/store";
import { verifyToken } from "@api/api";
import { useNFTDetailGlobalContext } from "@context/nftInformation";
import { useDetailGlobalContext } from "@context/detailProvider";
import { signatureSubject } from "./useSignMessage";

export const useWalletConnect = (setConnectedWalletModal: any) => {
  const dispatch = useDispatch();

  const eventHandler = async (event: any) => {
    if (event.detail.type === "RESPONSE_ADDRESS") {
      if (event.detail.payload) {
        // setOwnedNFTs([]);
        // dispatch(setOwnedNFTsDetails([]));
        // dispatch(setWalletConnection(true));
        setConnectedWalletModal(true);
        // dispatch(setWalletAddress(event.detail.payload));
        localStorage.setItem("tempWalletAddress", event.detail.payload);
        dispatch(setTempWalletAddress(event.detail.payload));
        const address = event.detail.payload;
        //
        dispatch(setWalletConnection(true));
        dispatch(setWalletAddress(address));
        const crown = await availableCrown(address);
        dispatch(setAvailableCrown(crown));
        const icx = await getICXBalance(address);
        dispatch(setAvailableICX(icx));
        const nftListPromises = [];
        //todo
        // 1.change total_page to totalPage and nft_list to nftList
        //also dont forget to change in icon service
        const ownedNFTs = await getOwnedNft(address);
        for (let i = 1; i <= Number(ownedNFTs.totalPage); i++) {
          const ownedNFTs = getOwnedNft(address, `0x${i.toString(16)}`);
          nftListPromises.push(ownedNFTs);
        }
        const nftList: Array<string> = [];
        await Promise.all(nftListPromises).then((values) => {
          for (let i = 0; i < values.length; i++) {
            nftList.push(...values[i].nftList);
          }
        });

        dispatch(setOwnedNFTs(nftList));
      }
    } else if (event.detail.type === "RESPONSE_SIGNING") {
      const address = localStorage.getItem("tempWalletAddress");
      // const result = validateSignature(
      //   event.detail.payload,
      //   address as string,
      //   localStorage.getItem("dynamicHash") as string
      // );

      signatureSubject.next(event.detail.payload);
      // const payload = {
      //   address: address,
      //   signature: event.detail.payload,
      //   payload: localStorage.getItem("dynamicHash") as string,
      // };
      // const res = await verifyToken(payload);
      // console.log("jwt res", res);
      // if (res.data.jwt) {
      // localStorage.setItem("token", res.data.jwt);
      // dispatch(setWalletConnection(true));
      // dispatch(setWalletAddress(address));
      // const crown = await availableCrown(address);
      // dispatch(setAvailableCrown(crown));
      // const icx = await getICXBalance(address);
      // dispatch(setAvailableICX(icx));
      // const nftListPromises = [];
      // //todo
      // // 1.change total_page to totalPage and nft_list to nftList
      // //also dont forget to change in icon service
      // const ownedNFTs = await getOwnedNft(address);
      // for (let i = 1; i <= Number(ownedNFTs.total_page); i++) {
      //   const ownedNFTs = getOwnedNft(address, `0x${i.toString(16)}`);
      //   nftListPromises.push(ownedNFTs);
      // }
      // const nftList: Array<string> = [];
      // await Promise.all(nftListPromises).then((values) => {
      //   for (let i = 0; i < values.length; i++) {
      //     nftList.push(...values[i].nft_list);
      //   }
      // });

      // dispatch(setOwnedNFTs(nftList));
      setTimeout(() => {
        dispatch(setLoadingState(false));
      }, 4000);
      // }
      // localStorage.setItem("modalShown", "false");
    } else {
      // console.log(event);
      
      console.log("Error on wallet connection!");
      dispatch(setLoadingState(false));
    }
  };

  useEffect(() => {
    window.addEventListener("ICONEX_RELAY_RESPONSE", eventHandler, false);
    return () => {
      window.removeEventListener("ICONEX_RELAY_RESPONSE", eventHandler);
    };
  }, []);
};
