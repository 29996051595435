import StylizedButton from "@components/buttons/stylized.button";
import Modal from "@components/modal/modal.component";
interface INotEnoughCrown {
  isVisible: boolean;
  setIsVisible: any;
  info: any;
}
const NotEnoughCrownModal = ({
  isVisible,
  setIsVisible,
  info,
}: INotEnoughCrown) => {
  return (
    <Modal
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      modalTitle={`Not Enough ${info.type === "CROWN" ? "CROWN" : "ICX"}`}
    >
      <div className="crownLimitWrapper">
        <div className="playerNumber">
          Join war for
          <span className=" font-extrabold">
            {" "}
            {info.type === "CROWN" ? `$CROWN ${info.fee}` : `ICX ${info.fee}`} .
          </span>
        </div>
        <hr className="horizontal-seperator" />
        <div className="crownLimitText">
          <p className="crownLimitTopText">
            Unfortunately, you do not have enough{" "}
            {info.type === "CROWN" ? "CROWN" : "ICX"} to join the war.
          </p>
          <p className="crownLimitInfo">
            Please try joining a different war or buy more{" "}
            {info.type === "CROWN" ? "CROWN" : "ICX"} to join this war.
          </p>
        </div>
        <hr className="horizontal-seperator" />
        <div className="crownLimitButton">
          <StylizedButton
            onClick={() => {
              setIsVisible(false);
            }}
            buttonName="Okay"
          />
        </div>
      </div>
    </Modal>
  );
};

export default NotEnoughCrownModal;
