import React, { useState, useEffect } from "react";
import { MdClose } from "react-icons/md";
import { CRATE2, FIST, HEALING, PISTOL, TEST_NFTS } from "@constants/images";
import PrimaryButton from "@components/buttons/primary.button";
import { RiSwordFill, RiSwordLine } from "react-icons/ri";
import ColoredCrateCard from "@components/card/coloredCrateCard";
import SidebarBody from "@pages/sidebarBody";
import { FiChevronLeft } from "react-icons/fi";
import EquipmentPlainCard from "@components/card/equipmentPlainCard.component";
import Countdown from "react-countdown-simple";
import { CROWN } from "@constants/images";

import {
  getLobbyLockdownPeriod,
  getSimulationDetail,
  hasWalletParticipated,
} from "@services/icon.service";
import { useSelector } from "react-redux";
import { getLootBoxDetails } from "@api/api";
import { useNavigate } from "react-router-dom";
import { useDetailGlobalContext } from "@context/detailProvider";
const tsConvertOptions: Intl.DateTimeFormatOptions = {
  day: "2-digit",
  month: "short",
  hour: "2-digit",
  minute: "2-digit",
  hour12: true,
};

const UpcomingSidebar = ({ open, warDetail, setOpen, title, id }: any) => {
  const [openDropChances, setOpenDropChances] = useState<boolean>(false);

  const navigate = useNavigate();

  const [lootboxDetails, setLootboxDetails] = useState<any>();

  useEffect(() => {
    async function fetchLootDetails() {
      try {
        const result = await getLootBoxDetails(Number(warDetail.lootboxId));
        setLootboxDetails(result.data);
      } catch (e) {
        console.log(e);
      }
    }
    fetchLootDetails();
  }, []);

  const { setWarIdSelectedFromMap } = useDetailGlobalContext();

  return (
    <>
      <SidebarBody setOpen={setOpen} title={warDetail?.title}>
        <div className="flex items-start justify-start w-full">
          <div className="w-1/2 border-r border-gray-800  pr-4">
            <p className="text-gray">START TIME </p>
            <p className="text-white font-semibold text-base">
              {" "}
              {new Date(Number(warDetail?.startTime) / 1000).toLocaleString(
                "en-US",
                tsConvertOptions
              )}
            </p>
            <div className="text-gray flex ">
              <p>{"Starts in"}</p>
              <b>
                <p className="modal-countdown">
                  <Countdown
                    targetDate={new Date(
                      new Date(Number(warDetail?.startTime) / 1000).setHours(
                        new Date(Number(warDetail?.startTime) / 1000).getHours()
                      )
                    ).toISOString()}
                    disableTypes={true}
                  />
                </p>
              </b>
            </div>
          </div>
          <div className="px-4 w-1/2 ">
            <p className="text-gray">AVAILABLE AT </p>
            <p className="text-white font-semibold text-base">
              <p className="text-white font-semibold text-base">
                {" "}
                {new Date(
                  Number(warDetail?.scheduleTime) / 1000
                ).toLocaleString("en-US", tsConvertOptions)}
              </p>
              <p className="modal-countdown mt-[-5px]">
                <Countdown
                  targetDate={new Date(
                    new Date(Number(warDetail?.scheduleTime) / 1000).setHours(
                      new Date(
                        Number(warDetail?.scheduleTime) / 1000
                      ).getHours()
                    )
                  ).toISOString()}
                  disableTypes={true}
                />
              </p>
            </p>
          </div>
        </div>
        <div className="mt-2 mb-4 w-full flex justify-center ">
          <PrimaryButton
            buttonName="WAR INFORMATION"
            icon={<RiSwordLine className="text-4xl text-white" />}
            className="w-full text-3xl "
            // disabled
            width="300px"
            onClick={() => {
              setWarIdSelectedFromMap(Number(id));
              navigate("/war");
            }}
          />
        </div>
        <div className="flex items-start justify-start w-full border-y border-gray-800  py-4">
          <div className="w-1/2  border-r border-gray-800  pr-4">
            <p className="text-gray">DESCRIPTION </p>
            {/* <p className="text-white font-semibold text-base">
              {" "}
              Street Brawling
            </p> */}
            <p className="text-gray">{warDetail.description}</p>
          </div>
          <div className="w-1/2  px-4">
            <p className="text-gray">MAX DURATION </p>
            <p className="text-white font-semibold text-base">
              {" "}
              {Number(warDetail.period)} MINS
            </p>
          </div>
        </div>
        <div className="flex items-start justify-start w-full border-y border-gray-800 py-4">
          <div className="w-1/2  border-r border-gray-800  pr-4">
            <p className="text-gray">PAYMENT TYPE </p>

            <p className="text-green">
              {/* {isWalletParticipated ? (
                <span className="text-yellow">joined</span>
              ) : gameStatus ? (
                "-"
              ) : warDetail.entryFee > 0 ? (
                <>
                  {warDetail.entryFee}
                  <img src={CROWN} alt="crown" className="crown-logo" />
                </>
              ) : (
                )} */}
              <span className="text-green">{warDetail?.feeType}</span>
            </p>
          </div>
          <div className="w-1/2 px-4">
            <p className="text-gray">ZONE TYPE </p>
            <p className="text-white font-semibold text-base">
              {" "}
              {warDetail?.zone}
            </p>
          </div>
        </div>
        <div className="flex w-full gap-4">
          {/* <ColoredCrateCard
            image={CRATE2}
            bgColor="purple"
            className="custom-height"
          /> */}

          <img
            className="p-2 w-[105px] mt-[1px]"
            src={lootboxDetails?.imageUrl}
            alt="lootbox"
          />

          <div>
            <div className="text-white text-base mt-10 font-bold">
              {lootboxDetails?.name}
            </div>
            {/* <p
              className="underline text-white cursor-pointer"
              onClick={() => {
                // setOpen(false);
                setOpenDropChances(true);
              }}
            >
              View Drop Chances
            </p> */}
          </div>
        </div>
      </SidebarBody>

      {openDropChances && (
        <SidebarBody
          title="Drop Chances"
          icon={
            <FiChevronLeft
              onClick={() => {
                setOpen(true);
                setOpenDropChances(!openDropChances);
              }}
              className="text-white text-4xl cursor-pointer"
            />
          }
          setOpen={setOpenDropChances}
        >
          <div className="flex w-full mt-4 border-b border-gray-800 pb-4 sm:gap-2 gap-0">
            <div className="w-1/3">
              <ColoredCrateCard
                image={CRATE2}
                bgColor="purple"
                className="custom-height"
              />
            </div>
            <div className="w-2/3">
              <div className="text-white text-xl font-bold">Epic Crate</div>
              <p className="text-gray text-sm cursor-pointer">
                The Epic Loot Crate is packed with all sorts of Epic goodies.
                Expect to find awesome weapons, cool items, and sweet abilities
                to wage .
              </p>
            </div>
          </div>
        </SidebarBody>
      )}
    </>
  );
};

export default UpcomingSidebar;
