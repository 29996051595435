import { useEffect } from "react";
import axios from "axios";
import {
  setWalletAddress,
  setWalletConnection,
} from "@redux/features/walletSlice";

export const useTokenInterceptor = (
  baseURL: any,
  setMessageSigningModal: any
) => {
  useEffect(() => {
    const interceptor = baseURL.interceptors.request.use(
      async (config: any) => {
        const token = localStorage.getItem("token");
        if (token) {
          try {
            const response = await axios.post(
              process.env.REACT_APP_BASE_URL + "/auth/verifyToken",
              { token }
            );
            if (response.data.publicKey) {
              config.headers = config.headers || {};
              config.headers.Authorization = `Bearer ${token}`;
              return config;
            }
          } catch (error) {
            console.log("Error validating token:", error);
            throw error;
          }
        }
        // const modalShown = localStorage.getItem("modalShown");
        // if (!modalShown || modalShown === "false") {
        // navigate("/war");
        // dispatch(setWalletConnection(false));
        // dispatch(setWalletAddress(null));
        setMessageSigningModal(true);
        // localStorage.setItem("modalShown", "true");
        // }
      }
    );

    return () => {
      axios.interceptors.request.eject(interceptor);
    };
  }, [baseURL]);
};
