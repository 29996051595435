import { CRS, Renderer } from "leaflet";
import { useEffect, useState } from "react";
import { MapContainer, TileLayer, Popup } from "react-leaflet";
import PolygonWithText from "./PolygonWithText";
import poly_processed_file from "./poly_processed.json";
import L from "leaflet";
import { getActiveGangwars } from "@services/icon.service";
import { getUpComingWars } from "@api/api";
import UpcomingPolygon from "./upcomingPolygon";

const poly_processed: any = poly_processed_file;
const data = {
    district_1: [[-111.2336305, 160.65123393500002]],
    // district_2: [[-138.9881377435, 78.55300952600001]],
    // district_3: [[-107.891437435, 134.06917641500002]],
    // district_4: [[-138.6810509185, 162.90812774]],
    // district_5: [[-146.15756557, 113.3927483855]],
    // district_6: [[-109.0133538835, 135.678466865]],
    // district_7: [[-116.0419882315, 94.348156421]],
    // district_8: [[-122.66215823050001, 111.75088340900001]],
    // district_9: [[-143.1976372945, 182.26003976]],
};

// const coordinates = [
//   [-138.9881377435, 78.55300952600001],
//   [-111.2336305, 160.65123393500002],
//   [-107.891437435, 134.06917641500002],
//   [-138.6810509185, 162.90812774],
//   [-146.15756557, 113.3927483855],
//   [-109.0133538835, 135.678466865],
//   [-116.0419882315, 94.348156421],
//   [-122.66215823050001, 111.75088340900001],
//   [-143.1976372945, 182.26003976],
// ];

const COLOR: any = {
    district_1: "#FFFFFF",
    district_2: "#FFFFFF",
    district_3: "#FFFFFF",
    district_4: "#FFFFFF",
    district_5: "#FFFFFF",
    district_6: "#FFFFFF",
    district_7: "#FFFFFF",
    district_8: "#FFFFFF",
    district_9: "#FFFFFF",
};
const NAME: any = {
    district_1: "Greenfield",
    district_2: "Vienna",
    district_3: "Oyster Bay",
    district_4: "Riverside",
    district_5: "South port",
    district_6: "Manhattan",
    district_7: "Newshire",
    district_8: "Kingston",
    district_9: "Ibiza",
};
function EmeraldCityMap() {
    const [isInteractable, setIsInteractable] = useState(false);
    const [upComingWars, setUpComingWar] = useState<any[]>([]);

    const interactable = () => {
        setIsInteractable(!isInteractable);
    };
    const [activeGangwar, setActiveGangwar] = useState<any>([]);
    const gangwars = async () => {
        try {
            const comingWar = await getUpComingWars();
            console.log("comingWar", comingWar.data.scheduledWars);
            setUpComingWar(comingWar.data.scheduledWars);
        } catch (e) {
            setUpComingWar([]);
        }
        try {
            const data = await getActiveGangwars();
            setActiveGangwar(data);
        } catch (error) {
            console.log("Error fetching active gangwars:", error);
        }
    };

    useEffect(() => {
        gangwars();
        const interval = setInterval(gangwars, 8000);
        return () => clearInterval(interval);
    }, []);

    const [screenSize, setScreenSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useEffect(() => {
        const handleResize = () => {
            setScreenSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        window.addEventListener("resize", handleResize);
        console.log("screenSize", screenSize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    // useEffect(() => {
    //   handleScreenSizeChange();
    // });

    // const handleScreenSizeChange = () => {
    //   console.log("Screen size changed:", screenSize);
    // };
    const [cordIndex, increaseCordIndex] = useState(0);

    const coordinates: any = [
        [-130, 123],
        [-130, 123],
        [-130, 123],
    ];
    const MAP_WAR_MAPPER_NUMBER = 9803955;

    const minLatLng = L.latLngBounds(coordinates).getSouthWest();
    const maxLatLng = L.latLngBounds(coordinates).getNorthEast();
    const maxBounds = L.latLngBounds(minLatLng, maxLatLng);

    return (
        <div
            className=" mx-auto"
            style={{
                width: "100%",
                height: "100%",
                position: "relative",
                zIndex: "1",
                // margin: "4rem 0px",
            }}
        >
            {isInteractable ? null : (
                <div
                    className="emerald_city_map_overlay  mx-auto "
                    style={{
                        // width: "100%",
                        height: "100vh",
                        cursor: "pointer",
                        position: "absolute",
                        background: "rgba(0,0,0,0.2)",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        top: "0",
                        bottom: "0",
                        zIndex: "1",
                        justifyContent: "space-around",
                    }}
                    onClick={interactable}
                >
                    {/* Click and zoom on the map to interact */}
                </div>
            )}
            <div
                // onMouseLeave={interactable}
                className="emerald_city_map_container container min-w-[100%] mx-auto"
                style={{
                    // width: "100%",
                    height: "100%",
                    position: "relative",
                    overflow: "hidden",
                    zIndex: "1",
                    margin: "15px 0px",
                }}
            >
                <MapContainer
                    center={[-130, 123]}
                    zoom={3}
                    dragging={screenSize.width <= 500 ? false : true}
                    // tap={false}
                    maxZoom={3}
                    crs={CRS.Simple}
                    zoomAnimation={false}
                    fadeAnimation={true}
                    preferCanvas={true}
                    style={{
                        // width: "100%",
                        height: "650px",
                        background: "#163340",
                    }}
                    minZoom={3}
                    scrollWheelZoom={true}
                    maxBounds={maxBounds}
                >
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://emeraldcity-lands.s3.us-west-2.amazonaws.com/mapTiles/default/{z}/{x}/{y}.png
            "
                    />
                    {activeGangwar?.activeSimulations?.length > 0 &&
                        activeGangwar.activeSimulations.map((warInfo: any, index: number) => {
                            if (!(new Date(Number(warInfo.endTime) / 1000) < new Date())) {
                                const simList = {
                                    ...warInfo.simulation,
                                    rewards: warInfo.reward,
                                };
                                return (
                                    <PolygonWithText
                                        key={index}
                                        war={simList}
                                        pathOptions={{
                                            weight: 0.1,
                                            fill: true,
                                            fillOpacity: 0.01,
                                        }}
                                        eventHandlers={{
                                            mouseover: (e: any) => {
                                                console.log(e.target);
                                                e.target.setStyle({
                                                    fillOpacity: 0.07,
                                                });
                                            },
                                            mouseout: (e: any) => {
                                                e.target.setStyle({
                                                    fillOpacity: 0.01,
                                                });
                                            },
                                        }}
                                        positions={poly_processed["district_1"]}
                                    />
                                );
                            } else {
                                return null;
                            }
                        })}

                    {upComingWars?.length > 0 &&
                        upComingWars.map((warInfo: any, index: number) => {
                            return (
                                <UpcomingPolygon
                                    key={index}
                                    war={warInfo?.data}
                                    id={MAP_WAR_MAPPER_NUMBER + index}
                                    pathOptions={{
                                        weight: 0.1,
                                        fill: true,
                                        fillOpacity: 0.01,
                                    }}
                                    eventHandlers={{
                                        mouseover: (e: any) => {
                                            console.log(e.target);
                                            e.target.setStyle({
                                                fillOpacity: 0.07,
                                            });
                                        },
                                        mouseout: (e: any) => {
                                            e.target.setStyle({
                                                fillOpacity: 0.01,
                                            });
                                        },
                                    }}
                                    positions={poly_processed["district_1"]}
                                />
                            );
                        })}
                </MapContainer>
            </div>
        </div>
    );
}

export default EmeraldCityMap;
