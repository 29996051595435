import React, { useState, useEffect } from "react";
import Seo from "@components/seo";
import GangWars from "@components/warPage/gangWars/gangWars.component";
import WarsHisory from "./warsHistory/warsHistory.component";
import {
    getActiveGangwars,
    getSimulationCount,
    getSimulationHistory,
} from "@services/icon.service";
import { AiOutlineClose } from "react-icons/ai";
import NewUserAlert from "./newUser";
import { useDetailGlobalContext } from "@context/detailProvider";

const WarPage = () => {
    const { setActiveTab, activeTab } = useDetailGlobalContext();
    // const [activeTab, setActiveTab] = useState<number>(0);
    // const [totalSimulationCount, setTotalSimulationCount] = useState<number>(0);

    // useEffect(() => {
    //   async function getHistory() {
    //     const count = await getSimulationCount();
    //     setTotalSimulationCount(count);
    //   }
    //   getHistory();
    // }, []);

    const [firstTime, setIsFirstTime] = useState(false);

    const openedForFirstTime = window.localStorage.getItem("openForFirstTime");

    return (
        <div className="war-page-wrapper minWidthHistoryContainer container mx-auto w-full">
            <Seo title="War | Gangwars" description="Join and spectate ongoing/upcoming Gangwars" />
            <div className="war-tabs-wrapper">
                {!Boolean(openedForFirstTime) && !firstTime && (
                    <NewUserAlert setIsFirstTime={setIsFirstTime} />
                )}

                <div className="war-nav-tabs">
                    <div
                        className={`war-nav-tab ${activeTab === 0 ? "active-tab" : ""}`}
                        onClick={() => setActiveTab(0)}
                    >
                        gangwars
                    </div>
                    <div
                        className={`war-nav-tab ${activeTab === 1 ? "active-tab" : ""}`}
                        onClick={() => setActiveTab(1)}
                    >
                        history
                    </div>
                </div>
                <div className="tab-content-wrapper">
                    {activeTab === 0 ? <GangWars /> : <WarsHisory />}
                </div>
            </div>
        </div>
    );
};

export default React.memo(WarPage);
