import EquipmentPlainCard from "@components/card/equipmentPlainCard.component";
import { RANGE } from "@constants/images";
import { HYPERLINKS } from "@misc/links";
import { excludeStatsKeys } from "@misc/removeStatsKeys";
import { titleStatNameMapping, statNameMapping } from "@misc/statNameMap";
import { getRentalHistory } from "@services/icon.service";
import { FC, useEffect, useState } from "react";
import AddressWrapper from "./addressWrapper.component";
import Countdown from "react-countdown-simple";

interface INFT {
  selectedChar: any;
  showWeapon?: boolean;
  showId?: boolean;
  showLevel?: boolean;
  isNameClickable?: boolean;
}

export const NFTSkillsWRentalHistory: FC<INFT> = ({ selectedChar }) => {
  const [selectedTopic, setSelectedTopic] = useState("stats");
  const [historyData, setHistoryData] = useState<any[]>([]);

  useEffect(() => {
    const getRentalData = async () => {
      const rentalHistory = await getRentalHistory(selectedChar?.gangstaBetDetails.nftId);
      console.log({ rentalHistory })
      setHistoryData(rentalHistory.filter((data: any) => data.borrower !== 'hx0000000000000000000000000000000000000000'));
    }

    getRentalData();
  }, [selectedChar?.gangstaBetDetails.nftId]);

  return (
    <div className="nftSkills" style={{ "marginTop": "-10px" }}>
      <div className="stats-selector-np">
        <div className="stats-tabs">
          <div>
            <span
              className={selectedTopic === "stats" ? "selected-filter" : ""}
              onClick={() => setSelectedTopic("stats")}
            >
              GangWar Stats
            </span>
            <span
              className={selectedTopic === "history" ? "selected-filter" : ""}
              onClick={() => setSelectedTopic("history")}
            >
              Rent History
            </span>
          </div></div></div>

      {selectedTopic === "stats" ? <div className="nftActualSkills cursor-pointer">
        <div className="skillsLeft">
          {selectedChar?.skills &&
            Object.keys(selectedChar?.skills?.primary)?.map((keys) => {
              return (
                <div className="skillInfo">
                  <p className="skillName capitalize">{keys}</p>
                  <p className="skillNumber">
                    {Math.floor(selectedChar?.skills?.primary[keys]).toFixed(0)}
                  </p>
                </div>
              );
            })}
        </div>
        <div className="skillsRight">
          {Object.keys(selectedChar?.skills?.secondary).map((keys: any) => {
            if (excludeStatsKeys.includes(keys)) {
              return null;
            } else {
              return (
                <div className="skillInfo">
                  <p
                    className="skillName cursor-help"
                    title={`${titleStatNameMapping[keys]}`}
                  >
                    {statNameMapping?.[keys]}
                  </p>

                  <p className="skillNumber">
                    {statNameMapping?.[keys]?.split(" ").length > 1
                      ? `${keys.toLowerCase() !== "dodgechance" ? "+" : ""
                      }${Number(
                        selectedChar?.skills.secondary[keys] * 100
                      ).toFixed(0)}%`
                      : selectedChar?.skills.secondary[keys].toFixed(0)}
                  </p>
                </div>
              );
            }
          })}
        </div>
      </div> : <div>{historyData.length === 0 ? <div className="text-white">No Rental History</div> :
        <>
          <div className="relative overflow-x-auto shadow-md sm:lg">
            <table className="w-full text-sm text-left rtl:text-right text-gray-400 ">
              <thead className="text-xs text-gray-400 uppercase  bg-opacity-50 ">
                <tr>
                  <th scope="col" className="px-6 py-3 border-b border-gray-600">
                    Rentee
                  </th>
                  <th scope="col" className="px-6 py-3 border-b border-gray-600">
                    Renter
                  </th>
                  <th scope="col" className="px-6 py-3 border-b border-gray-600">
                    Listed On
                  </th>
                  <th scope="col" className="px-6 py-3 border-b border-gray-600">
                    Period
                  </th>
                </tr>
              </thead>
              <tbody>

                {historyData.map((data: any) =>
                  <tr className="odd:bg-black-100 even:bg-gray-900 border-b border-gray-900">
                    <th scope="row" className="px-6 py-4 font-medium text-white whitespace-nowrap">
                      {data.borrower === 'hx0000000000000000000000000000000000000000' ? '-' : <AddressWrapper address={data.borrower} />}
                    </th>
                    <td className="px-6 py-4">
                      <AddressWrapper address={data.owner} />
                    </td>
                    <td className="px-6 py-4">
                      {new Date(Number(data.listedTimestamp) / 1000).toLocaleString(undefined, {
                        day: 'numeric',
                        month: 'short',
                      }
                      )}
                    </td>
                    <td className="px-6 py-4">
                      {+data.period > 0 ? <> {+data.period} {+data.period === 1 ? 'day' : 'days'}</> : <>-</>}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </>
      }</div>}


    </div>
  );
};

export const NFTDetail: FC<INFT> = ({ selectedChar, isNameClickable = true, showLevel = true, showWeapon = true, showId = true }) => {
  return (
    <div className="flex flex-col text-white">
      <div
        onClick={() => {
          if (isNameClickable) {
            window.open(
              `${HYPERLINKS.CRAFT_PROFILE}${selectedChar?.gangstaBetDetails?.nftId}`
            );
          }
        }}
        className="font-semibold hover:text-[#eab308] text-[1.112rem] cursor-pointer"
      >
        {selectedChar?.gangstaBetDetails?.nftName}
      </div>
      <div className="text-gray flex gap-1.5 items-center text-base font-medium">
        {selectedChar?.gangstaBetDetails?.classType}{" "}
        {/* <div className="flex items-center gap-1"> */}
        <div className="w-1 h-1 mt-1 bg-[#70878F] rounded-full"></div>
        <p> {selectedChar?.gangstaBetDetails?.characterType}</p>
        {/* </div> */}
      </div>
      {/* {Number(selectedChar?.hospitalization?.warCooldown) / 1000 > Date.now() && <div className="text-white flex">

        <p className="text-sm">
          Hospitalized:
        </p> <p className="hospitalizationTimerInRentMarket">
          <Countdown
            targetDate={new Date(
              new Date(Number(selectedChar?.hospitalization?.warCooldown) / 1000).setHours(
                new Date(
                  Number(selectedChar?.hospitalization?.warCooldown) / 1000
                ).getHours()
              )
            ).toISOString()}
          />
        </p>
      </div>} */}
      {showLevel &&
        <div className=" flex items-center gap-1.5 text-sm mt-2">
          <p> {selectedChar?.gangstaBetDetails?.levelType}</p>
          {/* <div className="flex item-center gap-1"> */}
          <div className="w-1 h-1 mt-1 bg-gray-200 rounded-full"></div>
          <p className="whitespace-nowrap">Level {selectedChar?.gangstaBetDetails?.levelNumber}</p>
          {/* </div> */}
          {showId && <>
            <div className="w-1 h-1 mt-1 bg-gray-200 rounded-full"></div>
            <p>Id {selectedChar?.gangstaBetDetails?.nftId}</p>
          </>}
        </div>
      }

      {showWeapon && (
        <div className="nftWeapons">
          <div className="weapons">
            <EquipmentPlainCard
              height="54px"
              width="58px"
              equipmentImages={selectedChar?.defaultWeapons?.image}
            />
            <div className="weaponsInfo w-content">
              <p className="text-white text-base">
                {selectedChar?.defaultWeapons?.weaponName}
              </p>

              <div className="flex-center text-sm text-gray gap-3">
                {selectedChar?.defaultWeapons?.weaponType}
                <img src={RANGE} alt="ranged" className="target" />
                <p className="rangedText">{selectedChar?.defaultWeapons?.Ranged}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
