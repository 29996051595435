import React, { useState } from "react";
import { BADGE, CRAFT, CROPPEDGRENADE, ITEM, LOADING, SHIELD } from "@constants/images";
import EquipmentStylizedCard from "@components/card/equipmentStylizedCard.component";
import InventoryBody from "@pages/inventory/InventoryBody";
import PrimaryButton from "@components/buttons/primary.button";
import { useCardAnimation } from "@hooks/useCardAnimation";
import { useDetailGlobalContext } from "@context/detailProvider";
import { IItems } from "@interfaces/items.interface";
import NFTCard from "@components/card/nftCard.component";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { Tooltip } from "react-tooltip";
import { HYPERLINKS } from "@misc/links";
import { PLAY_CLOSE_SOUND, PLAY_NORMAL_CLICK, PLAY_INVENTORY_CLICK } from "@constants/audio";
import { useSelector } from "react-redux";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import { GiDropWeapon } from "react-icons/gi";
import ImageLoader from "@misc/ImageLoader";

const Items = () => {
    const [animationToggle, setAnimationToggle] = useState<boolean>(false);
    useCardAnimation("animateThis", animationToggle);

    const {
        userItemsDetails,
        userItemsDetailsWithSeperation,
        loadingFetchUserDetails,
    } = useDetailGlobalContext();

    const [itemsDetails, setItemsDetails] = useState<IItems | null>(null);
    const { isWalletConnected } = useSelector((state: any) => state.wallet);
    const [collpase, setCollapse] = useState({
        stat: true,
        nonStat: true,
        unReleased: true,
    });
    console.log("userItemsDetailsWithSeperation", userItemsDetailsWithSeperation);
    return (
        <>
            <InventoryBody>
                {(userItemsDetails?.length > 0 && !loadingFetchUserDetails) ||
                    userItemsDetailsWithSeperation?.unReleased.length > 0 ? (
                    <>
                        <div className="col-span-7 itemsOptions">
                            {userItemsDetailsWithSeperation?.stats?.length > 0 && (
                                <div>
                                    <div className="flex gap-1 items-center mb-3">
                                        <div className="flex gap-1 items-center ">
                                            <p className="w-[90px]">Stats Items</p>
                                            <IoIosInformationCircleOutline
                                                className="cursor-pointer mt-1"
                                                color="#485C65"
                                                size={20}
                                                id="statsItems"
                                                data-tooltip-content="A type of item that can be consumed to boost the in-game stat of GangstaBets - also known as stat boosters."
                                            />
                                            <Tooltip
                                                style={{
                                                    zIndex: 999,
                                                    maxWidth: "300px",
                                                    opacity: 1,
                                                }}
                                                anchorSelect="#statsItems"
                                            />
                                        </div>
                                        <hr className="w-[100%] speInventoryTop " />
                                        {collpase.stat ? (
                                            <RiArrowDropUpLine
                                                size={30}
                                                className="text-gray min-w-[30px] mr-3 cursor-pointer"
                                                onClick={() => {
                                                    setCollapse((prev) => ({
                                                        ...prev,
                                                        stat: !collpase.stat,
                                                    }));
                                                }}
                                            />
                                        ) : (
                                            <RiArrowDropDownLine
                                                size={30}
                                                className="text-gray min-w-[30px] mr-3 cursor-pointer"
                                                onClick={() => {
                                                    setCollapse((prev) => ({
                                                        ...prev,
                                                        stat: !collpase.stat,
                                                    }));
                                                }}
                                            />
                                        )}
                                    </div>
                                    <div className="flex items-start flex-wrap gap-5">
                                        {/* <div className="col-span-1 h-full relative">
              <div className="newLabel">NEW</div>
              <div
                className={
                  "border  pt-10 px-4 cursor-pointer h-full " +
                  "border-gray-500"
                }
              >
                <img src={SHIELD} alt="" className="h-[100px] " />
                <div className="flex items-center justify-between itemsNumber px-4">
                  <div className="flex items-center gap-2">
                    <img src={""} alt="" className="badgeImage" />
                    <span className="badgeNumber"></span>
                  </div>
                  <div className="">*12</div>
                </div>
                <p className="mt-5 pb-3 font-semibold px-4">Alcohol</p>
              </div>
            </div> */}

                                        {userItemsDetailsWithSeperation?.stats?.map(
                                            (item: any, index: number) => {
                                                if (
                                                    index <
                                                    (collpase.stat
                                                        ? userItemsDetailsWithSeperation?.stats
                                                            ?.length
                                                        : 0)
                                                )
                                                    return (
                                                        <div className="">
                                                            <div
                                                                className={
                                                                    "max-w-[175px] inventoryBoxHover min-w-[175px] min-h-[201px]  border flex flex-col justify-center items-center gap-7  px-4 cursor-pointer relative " +
                                                                    (itemsDetails?.id === item.id
                                                                        ? "activeOption"
                                                                        : "apply-border")
                                                                }
                                                                onClick={() => {
                                                                    PLAY_INVENTORY_CLICK();
                                                                    setAnimationToggle(
                                                                        !animationToggle
                                                                    );
                                                                    setItemsDetails(item);
                                                                }}
                                                            >
                                                                <div className="image-background-inventory">
                                                                    <img
                                                                        src={item.image}
                                                                        alt="items"
                                                                        className=" item-images"
                                                                    />
                                                                    <p className="mt-5 pb-3 overflow-hiddden itemCount font-semibold px-4">
                                                                        <span className="text-sm font-normal">
                                                                            x
                                                                        </span>
                                                                        {item.count}
                                                                    </p>
                                                                </div>
                                                                <p>
                                                                    <span className="badgeNumber text-center">
                                                                        {item.name}
                                                                    </span>
                                                                </p>
                                                                {/* <div className="flex items-center justify-between itemsNumber px-4">
                      <div className="flex items-center gap-2">
                        <img src={BADGE} alt="" className="badgeImage" />
                        <span className="badgeNumber text-center">
                          {item.name}
                        </span>
                      </div>
                      <div className="">*{item["stat-up"]}</div>
                    </div> */}
                                                            </div>
                                                        </div>
                                                    );
                                            }
                                        )}
                                    </div>
                                </div>
                            )}

                            {userItemsDetailsWithSeperation?.nonStats?.length > 0 && (
                                <div>
                                    <div className="flex gap-1 items-center my-4">
                                        <div className="flex gap-1 items-center ">
                                            <p className="w-[95px] whitespace-nowrap">
                                                Special Items
                                            </p>
                                            {/* className="w-[125px]" */}
                                            <IoIosInformationCircleOutline
                                                className="cursor-pointer mt-1"
                                                color="#485C65"
                                                size={20}
                                                id="specialItems"
                                                data-tooltip-content="Item which have special utility in GangWars"
                                            />
                                            <Tooltip
                                                style={{
                                                    zIndex: 999,
                                                    maxWidth: "300px",
                                                    opacity: 1,
                                                }}
                                                anchorSelect="#specialItems"
                                            />
                                            {/* <hr className="w-full change-inventory-seperator" />{" "} */}
                                        </div>
                                        <hr className="w-[100%] speInventoryTop " />
                                        {collpase.nonStat ? (
                                            <RiArrowDropUpLine
                                                size={30}
                                                className="text-gray min-w-[30px] mr-3 cursor-pointer"
                                                onClick={() => {
                                                    setCollapse((prev) => ({
                                                        ...prev,
                                                        nonStat: !collpase.nonStat,
                                                    }));
                                                }}
                                            />
                                        ) : (
                                            <RiArrowDropDownLine
                                                size={30}
                                                className="text-gray min-w-[30px] mr-3 cursor-pointer"
                                                onClick={() => {
                                                    setCollapse((prev) => ({
                                                        ...prev,
                                                        nonStat: !collpase.nonStat,
                                                    }));
                                                }}
                                            />
                                        )}
                                    </div>
                                    <div className="flex items-start flex-wrap gap-5">
                                        {/* <div className="col-span-1 h-full relative">
              <div className="newLabel">NEW</div>
              <div
                className={
                  "border  pt-10 px-4 cursor-pointer h-full " +
                  "border-gray-500"
                }
              >
                <img src={SHIELD} alt="" className="h-[100px] " />
                <div className="flex items-center justify-between itemsNumber px-4">
                  <div className="flex items-center gap-2">
                    <img src={""} alt="" className="badgeImage" />
                    <span className="badgeNumber"></span>
                  </div>
                  <div className="">*12</div>
                </div>
                <p className="mt-5 pb-3 font-semibold px-4">Alcohol</p>
              </div>
            </div> */}

                                        {userItemsDetailsWithSeperation?.nonStats?.map(
                                            (item: any, index: number) => {
                                                if (
                                                    index <
                                                    (collpase.nonStat
                                                        ? userItemsDetailsWithSeperation?.nonStats
                                                            ?.length
                                                        : 0)
                                                )
                                                    return (
                                                        <div className="">
                                                            <div
                                                                className={
                                                                    "max-w-[175px] inventoryBoxHover min-w-[175px] min-h-[201px]  border flex flex-col justify-center items-center gap-7  px-4 cursor-pointer relative " +
                                                                    (itemsDetails?.id === item.id
                                                                        ? "activeOption"
                                                                        : "apply-border")
                                                                }
                                                                onClick={() => {
                                                                    setAnimationToggle(
                                                                        !animationToggle
                                                                    );
                                                                    setItemsDetails(item);
                                                                    PLAY_INVENTORY_CLICK();
                                                                }}
                                                            >
                                                                <div className="image-background-inventory">
                                                                    <img
                                                                        src={item.image}
                                                                        alt="items"
                                                                        className=" p-6"
                                                                    />
                                                                    <p className="mt-5 pb-3 itemCount font-semibold overflow-hidden px-4">
                                                                        <span className="text-sm font-normal">
                                                                            x
                                                                        </span>
                                                                        {item.count}
                                                                    </p>
                                                                </div>

                                                                <p>
                                                                    <span className="badgeNumber text-center">
                                                                        {item.name}
                                                                    </span>
                                                                </p>
                                                                {/* <div className="flex items-center justify-between itemsNumber px-4">
                      <div className="flex items-center gap-2">
                        <img src={BADGE} alt="" className="badgeImage" />
                        <span className="badgeNumber text-center">
                          {item.name}
                        </span>
                      </div>
                      <div className="">*{item["stat-up"]}</div>
                    </div> */}
                                                            </div>
                                                        </div>
                                                    );
                                            }
                                        )}
                                    </div>
                                </div>
                            )}
                            {userItemsDetailsWithSeperation?.unReleased?.length > 0 && (
                                <div className="mb-4">
                                    <div className="flex gap-1 items-center my-4">
                                        <div className="flex gap-1 items-center ">
                                            <p className="whitespace-nowrap">Unreleased Items</p>
                                            <IoIosInformationCircleOutline
                                                className="cursor-pointer mt-1"
                                                color="#485C65"
                                                size={20}
                                                id="unreleased"
                                                data-tooltip-content="These items are not usable in GangWar currently."
                                            />
                                            <Tooltip
                                                style={{
                                                    zIndex: 999,
                                                    maxWidth: "300px",
                                                    opacity: 1,
                                                }}
                                                anchorSelect="#unreleased"
                                            />
                                        </div>
                                        <hr className="w-[100%] speInventoryTop " />
                                        {collpase.unReleased ? (
                                            <RiArrowDropUpLine
                                                size={30}
                                                className="text-gray min-w-[30px] mr-3 cursor-pointer"
                                                onClick={() => {
                                                    setCollapse((prev) => ({
                                                        ...prev,
                                                        unReleased: !collpase.unReleased,
                                                    }));
                                                }}
                                            />
                                        ) : (
                                            <RiArrowDropDownLine
                                                size={30}
                                                className="text-gray min-w-[30px] mr-3 cursor-pointer"
                                                onClick={() => {
                                                    setCollapse((prev) => ({
                                                        ...prev,
                                                        unReleased: !collpase.unReleased,
                                                    }));
                                                }}
                                            />
                                        )}
                                    </div>
                                    <div className="flex items-start flex-wrap gap-5">
                                        {userItemsDetailsWithSeperation?.unReleased?.map(
                                            (item: any, index: number) => {
                                                if (
                                                    index <
                                                    (collpase.unReleased
                                                        ? userItemsDetailsWithSeperation?.unReleased
                                                            ?.length
                                                        : 0)
                                                )
                                                    return (
                                                        <div className="">
                                                            <div
                                                                className={
                                                                    "border inventoryBoxHover max-h-[180px] flex flex-col relative items-center justify-center  pt-5 px-4 cursor-pointer " +
                                                                    (itemsDetails?.id === item.id
                                                                        ? "activeOption"
                                                                        : "apply-border")
                                                                }
                                                                onClick={() => {
                                                                    PLAY_INVENTORY_CLICK();
                                                                    setItemsDetails(item);
                                                                    setAnimationToggle(
                                                                        !animationToggle
                                                                    );
                                                                }}
                                                            >
                                                                <div className="image-background-inventory-inuse relative">
                                                                    <img
                                                                        src={item.image}
                                                                        alt=""
                                                                        className="h-[100px] p-2 "
                                                                    />
                                                                    {item.isUnreleased && (
                                                                        <GiDropWeapon
                                                                            size={20}
                                                                            color="#70878f"
                                                                            className="absolute top-[15px] left-[10px]"
                                                                        />
                                                                    )}
                                                                </div>
                                                                <p className="mt-2 pb-3 text-xs font-semibold">
                                                                    {item.name}
                                                                </p>
                                                                {item.isUnreleased && (
                                                                    <p className="mt-5 pb-3  equipmentCount-inuse font-semibold px-4">
                                                                        <span className="text-xs font-normal lowercase">
                                                                            x
                                                                        </span>
                                                                        {Number(item.count)}
                                                                    </p>
                                                                )}
                                                            </div>
                                                        </div>
                                                    );
                                            }
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                        {itemsDetails ? (
                            <div className="col-span-3 flex justify-between flex-col details overflow-hidden">
                                <div className="flex flex-col animation" id="animateThis">
                                    <div className="flex items-center gap-4 ">
                                        <EquipmentStylizedCard
                                            equipmentImages={itemsDetails.image}
                                            className="setColor"
                                        />

                                        <div className="flex flex-col items-start justify-center">
                                            <h1 className="text-xl font-proxima">
                                                {itemsDetails.name}
                                            </h1>
                                            <div className="flex items-center justify-between w-full">
                                                <div className="flex flex-col items-center gap-4">
                                                    <span>
                                                        <div className="text-primary text-sm">
                                                            {itemsDetails.type}
                                                        </div>
                                                        <div className="text-white text-sm">
                                                            {itemsDetails.serving}
                                                        </div>
                                                    </span>
                                                    {itemsDetails?.rank && (
                                                        <button className=" flex px-3 py-2 bg-[#161d21] gap-2 text-sm mt-2">
                                                            <img src={BADGE} alt="" /> Rank{" "}
                                                            {itemsDetails?.rank}
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {itemsDetails?.statBoost && !itemsDetails?.isUnreleased ? (
                                        <button className="text-center px-4 py-2 bg-[#161d21] text-lg font-bold w-full my-4">
                                            <span className="dark capitalize ">
                                                {Object.keys(itemsDetails?.statBoost)[0]}
                                                <span className="text-green-400">
                                                    {" "}
                                                    {Object.values(itemsDetails?.statBoost)[0]}
                                                </span>
                                            </span>
                                        </button>
                                    ) : (
                                        <div className="py-2"></div>
                                    )}
                                    {itemsDetails?.isUnreleased && (
                                        <div className="inUseState mt-4 flex items-start gap-2">
                                            <p className="inUseTexts">
                                                <span>Unreleased Item:</span> This Item will be
                                                usable after the next update of GangWars.
                                            </p>
                                            <GiDropWeapon size={30} color="#70878f" className="" />
                                        </div>
                                    )}
                                    <div className="grid text-left py-4 gap-6">
                                        <div className="title text-base text-white">
                                            {itemsDetails.description}
                                        </div>
                                    </div>

                                    {!itemsDetails?.isUnreleased && (
                                        <div
                                            className="viewInCraft"
                                            onClick={() => {
                                                PLAY_INVENTORY_CLICK();
                                                window.open(
                                                    "https://craft.network/collection/gangstaverse-items",
                                                    "_blank"
                                                );
                                            }}
                                        >
                                            <img src={CRAFT} alt="craft" />
                                            <button className="">View in Craft</button>
                                        </div>
                                    )}
                                </div>
                                <div className="moreInfoText">
                                    <hr className="seperatorInventory" />
                                    <p className=" mt-5">For More details on Items</p>
                                    <a href={HYPERLINKS.ITEMS} target="_blank" rel="noopener">
                                        Click here to read the documentation.
                                    </a>
                                </div>
                            </div>
                        ) : (
                            <div className="col-span-3 flex items-center justify-center details overflow-hidden">
                                <p className="text-4xl text-center unSelectedTextState opacity-20">
                                    SELECT AN ITEM TO SEE DETAILS
                                </p>
                            </div>
                        )}
                    </>
                ) : loadingFetchUserDetails ? (
                    <div className="col-span-10 ">
                        <div className="flex flex-col gap-2 w-full h-full items-center justify-center">
                            <img width={220} src={LOADING} alt="loading circle" />
                        </div>
                    </div>
                ) : (
                    <div className="col-span-10 ">
                        <div className="flex flex-col gap-2 w-full h-full items-center justify-between">
                            <div className="flex flex-col gap-2 w-full h-full items-center justify-center">
                                <NFTCard
                                    cardType="nftCardImageCollection"
                                    nftImage={ITEM}
                                    height={"180px"}
                                    width={"145px"}
                                    className="inactive-image2"
                                />
                                {isWalletConnected ? (
                                    <>
                                        <p className="text-3xl big-shoulders">NO ITEMS FOUND</p>
                                        <p className="text-gray max-w-[500px] text-center">
                                            It looks like your Items Inventory is currently empty.
                                            Explore Craft marketplace to purchase new Items.
                                        </p>
                                        <PrimaryButton
                                            onClick={() => {
                                                PLAY_INVENTORY_CLICK();
                                                window.open(
                                                    "https://craft.network/collection/gangstaverse-items",
                                                    "_blank"
                                                );
                                            }}
                                            buttonName="buy items"
                                        />
                                    </>
                                ) : (
                                    <>
                                        <p className="text-3xl big-shoulders">
                                            WALLET NOT CONNECTED
                                        </p>
                                        <p className="text-gray max-w-[500px] text-center">
                                            Please connect your wallet to view items in your
                                            inventory.
                                        </p>
                                        <PrimaryButton buttonName="Connect wallet" />
                                    </>
                                )}
                            </div>

                            <div className="moreInfoTextEmpty w-full overflow-hidden">
                                <hr className="seperatorInventory" />
                                <div className="flex flex-col gap-2">
                                    <p className=" mt-5 text-center">For More details on Items</p>
                                    <a
                                        href={HYPERLINKS.ITEMS}
                                        target="_blank"
                                        className="text-center"
                                    >
                                        Click here to read the documentation.
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </InventoryBody>
        </>
    );
};

export default Items;
