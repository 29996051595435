import { useEffect, useState } from "react";
import { IInterval, IStats } from "../interfaces/leaderboard.interface";
import { getLeaderboardData } from "@api/api";

const API_MAP: Record<IInterval, string> = {
    weekly: "weekly",
    monthly: "monthly",
    all: "eternal",
    seasonal: "seasonal",
};

function useLeaderboardApi(stats: IStats, interval: IInterval, timestamp: number, count: number) {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<any>(null);
    const [error, setError] = useState("");

    useEffect(() => {
        if (!interval || !timestamp || timestamp < 0) {
            return;
        }
        setLoading(true);
        getLeaderboardData(stats, API_MAP[interval], timestamp, count)
            .then((data) => {
                setData(data);
                setError("");
            })
            .catch((err: any) => {
                console.error(err);
                typeof err === "object"
                    ? err.response?.data
                        ? setError(JSON.stringify(err.response.data))
                        : setError(JSON.stringify(err))
                    : setError(err);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [stats, interval, count, timestamp]);

    return { data, loading, error };
}

export { useLeaderboardApi };
