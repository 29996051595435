import { useLeaderboardContext } from "../context/LeaderboardContext";
import LeaderboardTable from "./LeaderboardTable";
import IntervalSelector from "./IntervalSelector";
import StatsSelector from "./StatsSelector";
import LastWinners from "./Modal/LastWinnersModal";
import { useEffect, useState } from "react";
import { useLeaderboardApi } from "../hooks/useLeaderboardApi";
import { getNFTDetails } from "@api/api";
import { IInterval, ILeaderboardData, IStats } from "../interfaces/leaderboard.interface";
import LoadingAnimation from "@assets/images/loading/loading.gif";
import RewardsModal from "./Modal/RewardsModal";
import EmptyLeaderboard from "./EmptyLeaderboard";
import { useSelector } from "react-redux";
import {
    LEADERBOARD_COLS_MAP,
    STATS_PER_GAME_MAPPING,
    flagAndLimitLeaderboard,
    formatLeaderboardNum,
} from "../utils.leaderboard";

const MAX_DATA_COUNT = 50;
const SHOW_DATA_COUNT = 10;

const Leaderboard = ({ defaultInterval }: { defaultInterval: IInterval }) => {
    const {
        intervalFilter,
        setSelectedInterval,
        statsFilter,
        showLastWinners,
        showRewardsModal,
        setShowRewardsModal,
    } = useLeaderboardContext();
    const [loading, setLoading] = useState(true);
    const [timestamp, setTimestamp] = useState(Date.now());
    const [leaderboardData, setLeaderboardData] = useState<ILeaderboardData[] | null>(null);
    const [leaderboardColumns, setLeaderboardColumns] = useState([
        "rank",
        "gangstabet",
        "wins",
        "Games Played",
        "Win %",
    ]);

    const { data: leaderboardApiData, error: leaderboardApiError } = useLeaderboardApi(
        statsFilter,
        intervalFilter,
        timestamp,
        MAX_DATA_COUNT
    );

    const userNfts: Array<string> = useSelector((store: any) => store.inventory.ownedNft);

    useEffect(() => {
        setSelectedInterval(defaultInterval);
    }, [defaultInterval]);

    useEffect(() => {
        setLeaderboardColumns((prevData) => {
            prevData[2] = LEADERBOARD_COLS_MAP[statsFilter];
            if (statsFilter === "gamesPlayed") {
                return prevData.slice(0, 3);
            }
            prevData[3] = "Games Played";
            prevData[4] = STATS_PER_GAME_MAPPING[statsFilter] ?? "";
            return prevData;
        });
    }, [statsFilter]);

    useEffect(() => {
        if (leaderboardApiData?.leaderboard) {
            const { leaderboard: leaderboardRaw } = leaderboardApiData;
            setLoading(true);
            const formattedData: unknown = flagAndLimitLeaderboard(
                leaderboardRaw,
                userNfts,
                SHOW_DATA_COUNT
            );
            const tempData = getLeaderboardTableData(formattedData, statsFilter);
            Promise.all(tempData).then((res: unknown) => {
                setLoading(false);
                setLeaderboardData(res as ILeaderboardData[]);
            });
        }
    }, [leaderboardApiData]);

    useEffect(() => {
        setLoading(true);
    }, [statsFilter, intervalFilter]);

    return (
        <div className="mx-auto leaderboard-container">
            <IntervalSelector />
            <div className="leaderboard-inner-container">
                <StatsSelector />
                {leaderboardApiError != "" ? (
                    leaderboardApiError.toLowerCase().includes("invalid date") ? (
                        <EmptyLeaderboard />
                    ) : (
                        <h3>Error loading leaderboard data</h3>
                    )
                ) : loading ? (
                    <img
                        style={{ margin: "auto" }}
                        src={LoadingAnimation}
                        alt="Leaderboard data loading..."
                        height="auto"
                        width={200}
                    />
                ) : (
                    leaderboardData && (
                        <div className="leaderboard-table-container">
                            {leaderboardData.length > 0 ? (
                                <LeaderboardTable
                                    selectedStats={statsFilter}
                                    data={leaderboardData}
                                    columns={leaderboardColumns}
                                />
                            ) : (
                                <EmptyLeaderboard />
                            )}
                        </div>
                    )
                )}
            </div>

            {showLastWinners && (
                <LastWinners
                    seasonTimestamp={
                        leaderboardApiData?.seasonalInfo?.timestamps?.lastSeasonTimeStamp
                    }
                    intervalFilter={intervalFilter}
                />
            )}
            {showRewardsModal && <RewardsModal onClose={() => setShowRewardsModal(false)} />}
        </div>
    );
};

function getLeaderboardTableData(leaderboardApiData: any, statsFilter: IStats) {
    const tempDataPromise = leaderboardApiData.map(async (details: any, index: number) => {
        const {
            data: {
                token_name: gbetName,
                token_uri: gbetImg,
                class_type: gbetClass,
                statistics: { current_stats: currentLevel },
            },
        } = await getNFTDetails(details.nftId);
        return {
            rank: details.rank,
            id: details.nftId,
            gbetImg,
            gbetName,
            gbetClass,
            currentLevel,
            isUserNft: details.isUserNft,
            gamesPlayed: details.gamesPlayed,
            [statsFilter]: details[statsFilter],
            [`${statsFilter}Percent`]:
                details.gamesPlayed > 0
                    ? statsFilter === "wins"
                        ? formatLeaderboardNum((details[statsFilter] / details.gamesPlayed) * 100)
                        : formatLeaderboardNum(details[statsFilter] / details.gamesPlayed)
                    : "",
            rewards: "",
        };
    });

    return tempDataPromise as Array<Promise<unknown>>;
}

export { Leaderboard };
