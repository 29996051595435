import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { NFTsDetails } from "@interfaces/nft.interface";


interface IInventoryState {
  availableCrown: number;
  ownedNft:Array<string>;
  nftsDetails:Array<NFTsDetails>;
  availableICX:number;
}

const initialState: IInventoryState = {
  availableCrown: 0,
  ownedNft:[],
  availableICX:0,
  nftsDetails:[],
};

export const inventorySlice = createSlice({
  name: "inventory",
  initialState,
  reducers: {
    setAvailableCrown: (state, action: PayloadAction<number>) => {
      state.availableCrown = action.payload;
    },
    setAvailableICX: (state, action: PayloadAction<number>) => {
      state.availableICX = action.payload;
    },

    setOwnedNFTs: (state, action: PayloadAction<Array<string>>) => {
      state.ownedNft = action.payload;
    },
    setOwnedNFTsDetails:(state,action:PayloadAction<Array<NFTsDetails>>)=>{
      state.nftsDetails = action.payload;
    }
  },
});

export const {
  setAvailableCrown,
  setOwnedNFTs,
  setOwnedNFTsDetails,
  setAvailableICX,
} = inventorySlice.actions;

export default inventorySlice.reducer;
