import { memo } from "react";
import Popup from "./Modal";
import RewardCrate from "@assets/images/leaderboard/rewardCrate.svg";

interface IRewardsPopup {
    onClose: () => void;
}

export default memo(function RewardsPopup({ onClose }: IRewardsPopup) {
    return(
        <Popup show={true} onClose={onClose} variant="small">
            <div>
                <h5>Top Winner</h5>
                <h3>Rank 1 Reward</h3>
            </div>
            <div className="reward-crate">
                <img src={RewardCrate} alt="winner crate image" />
            </div>
            <div>
                <p>Get to the top of the leaderboard to win this crate every week.</p>
            </div>
        </Popup>
    );
});