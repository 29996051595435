import { useEffect, useState } from "react";
import { getLootBoxDetails } from "@api/api";
import UpComingWarLists from "./upComingWarLists.component";

const UpComingWar = ({ war, id }: any) => {
  const [lootboxDetails, setLootboxDetails] = useState<any>();
  useEffect(() => {
    async function fetchLootDetails() {
      try {
        const result = await getLootBoxDetails(Number(war.data.lootboxId));
        setLootboxDetails(result.data);
      } catch (e) {
        console.log(e);
      }
    }
    fetchLootDetails();
  }, []);

  return (
    <>
      <UpComingWarLists
        id={id}
        war={war.data}
        lootboxDetails={lootboxDetails}
      />
    </>
  );
};

export default UpComingWar;
