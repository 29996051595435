import { Tooltip } from "react-tooltip";
import ImageLoader from "@misc/ImageLoader";

const SneakMoves = ({ details, place = "right", tooltipId }: any) => {
  const loadoutName =
    details?.name?.split(" ").length > 1
      ? details?.name?.replace(/\s/g, "_")
      : details?.name;
  return (
    <>
      <Tooltip
        place={place}
        delayShow={800}
        className={`tooltipSneakMoveLoadout tooltipPlace_${place}`}
        anchorSelect={`#${tooltipId}`}
      >
        <div className="flex flex-col gap-5">
          <div className="weaponsDetails flex gap-6 items-center ">
            <div className="imageHodler userloadout tooltipLoadout">
              <ImageLoader
                style={{ padding: "5px" }}
                src={details?.image}
                alt="weapons"
              />
            </div>
            <div className="flex flex-col gap-2">
              <h2 className="tooltipLoadoutName">{details?.name}</h2>
              <div className="flex isActive  gap-2">{details?.type} move</div>
            </div>
          </div>
          <hr className="horizontalSeperator" />
          <p className="loadoutDescription">{details?.description}</p>
        </div>
      </Tooltip>
    </>
  );
};
export default SneakMoves;
