export const HYPERLINKS = {
    HANA_EXTENSION_LINK:
        "https://chromewebstore.google.com/detail/hana-wallet/jfdlamikmbghhapbgfoogdffldioobgl",
    CRAFT_MARKETPLACE: "https://craft.network/collection/gangstabet",
    CRAFT_PROFILE: "https://craft.network/nft/gangstabet:",
    GANGSTABET_PROFILE: "https://gangstabet.io/profile",
    GANGSTABET_WALLET: "https://gangstabet.io/wallet",
    DISCORD: "https://discord.com/invite/9jkyefW9kU",
    WEAPONS:
        "https://gangstaverse.medium.com/gangwars-getting-familiar-with-the-game-interface-and-its-assets-c0e9955f59",
    ITEMS:
        "https://gangstaverse.medium.com/gangwars-getting-familiar-with-the-game-interface-and-its-assets-c0e9955f59",
    ABILITIES:
        "https://gangstaverse.medium.com/gangwars-getting-familiar-with-the-game-interface-and-its-assets-c0e9955f59",
    LOOTCTATE:
        "https://gangstaverse.medium.com/gangwars-getting-familiar-with-the-game-interface-and-its-assets-c0e9955f59",
};
