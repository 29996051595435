import { useState, useCallback } from "react";

interface PaginationState {
  offset: number;
  limit: number;
  total: number;
}

export const usePagination = (
  total: number,
  limit = 5
): [PaginationState, (newOffset: number) => void] => {
  const [paginationState, setPaginationState] = useState<PaginationState>({
    offset: total - limit,
    limit,
    total,
  });

  const changeOffset = useCallback(
    (newOffset: number) => {
      setPaginationState({
        ...paginationState,
        offset: total - newOffset - limit,
      });
    },
    [paginationState]
  );

  return [paginationState, changeOffset];
};
