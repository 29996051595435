import { NFTsDetails } from "@interfaces/nft.interface";
import { getDefaultEquipment, getNFTDetails, getSkillsStats } from "@api/api";
import { nfgetNFTsStats } from "./getStats";
import { store } from "@redux/store";
import {
    getCharacterInfo,
    // getCollectedAmount,
    getLockedNft,
    getNftStatus,
    getRentInfo,
    getRentNftStatus,
    getRentType,
    getRentedBy,
    getSetRentDetail,
} from "@services/icon.service";
import { NFT_RENT_STATUS } from "@constants/nft";

interface IAttribute {
    key: string;
    value: string;
}

function extractGenderAndType(attributes: IAttribute[]): { gender: string; type: string } {
    let gender = "";
    let type = "";
    attributes.forEach((attribute) => {
        if (attribute.key === "Gender") {
            gender = attribute.value;
        } else if (attribute.key === "Type") {
            type = attribute.value;
        }
    });

    return { gender, type };
}

export async function getNFTInformation(nftId: string): Promise<NFTsDetails> {
    const { ownedNft } = store.getState().inventory;
    try {
        const result = await getNFTDetails(nftId);
        const defaultEquipmentInfo = await getDefaultEquipment(nftId);
        const characterInfo = await getCharacterInfo(nftId);
        const rentStatus: keyof typeof NFT_RENT_STATUS = await getRentNftStatus(nftId);

        const rental = {
            type: await getRentType(nftId),
            rentedBy: rentStatus === "IN_RENT" ? await getRentedBy(nftId) : null,
            // claimable: ownedNft.includes(nftId) ? await getCollectedAmount(nftId): {
            //   ICX_CURRENCY: 0,
            //   CROWN_CURRENCY: 0,
            //  },
            ...objectMap((await getRentInfo(nftId)) ?? {}, (v: any, k: any) => {
                if (k === "borrower" || k === "owner") return v;
                if (k === "rentFee") return Number(v / 10 ** 18);
                if (k === "claimableAmount") return Number(v / 10 ** 18);
                return Number(v);
            }),
            ...objectMap((await getSetRentDetail(nftId)) ?? {}, (v: any, k: any) => {
                return Number(v);
            }),
            status: rentStatus,
        };
        const {
            token_id,
            token_name,
            statistics,
            owned_by,
            token_uri,
            class_type,
            skills,
            attributes,
        } = result.data;
        const { gender, type } = extractGenderAndType(attributes);
        console.log({ gender, type });
        // const { getLockedNFTs } = useDetailGlobalContext();
        // { primary: primaryStat, secondary: secondaryStat }
        // const nftSkill = await nfgetNFTsStats(skills);
        const nftSkill = await getSkillsStats(Number(nftId));
        const getLockedNFTs = await getLockedNft();
        const siklls = {
            primary: nftSkill?.data?.primaryStats,
            secondary: nftSkill?.data?.secondaryStats,
        };
        const statusInfo = await getNftStatus(Number(nftId).toString());
        const isNFTLocked = getLockedNFTs.includes(`0x${Number(nftId).toString(16)}`) as boolean;
        const testInfo: any = {
            gangstaBetDetails: {
                nftId: Number(token_id),
                nftImage: token_uri,
                nftName: token_name,
                ownedBy: owned_by,
                gender: gender,
                race: type,
                classType: class_type,
                levelNumber: Number(characterInfo.characterLevel), //character_level
                characterType: Number(characterInfo.isGangster) //is_gangster
                    ? "Gangster"
                    : "Detective",
                levelType: statistics?.current_level?.name,
            },
            defaultWeapons: {
                weaponName: defaultEquipmentInfo.data?.defaultEquipment?.name as string,
                weaponType: "Default Weapon",
                Ranged: defaultEquipmentInfo.data?.defaultEquipment?.type,
                image: defaultEquipmentInfo.data?.defaultEquipment?.imageUrl,
            },
            stats: {
                siklls,
            },
            rental: {
                isInRent: false,
            },
            hospitalization: {
                isHospitalized: false,
                outOfHospitalizationTimer: 3476546,
            },
            lockStatus: false,
        };
        const info: NFTsDetails = {
            nftId: Number(token_id),
            nftImage: token_uri,
            nftName: token_name,
            ownedBy: owned_by,
            skills: siklls,
            gender: gender,
            race: type,
            classType: class_type,
            levelNumber: Number(characterInfo.characterLevel), //character_level
            characterType: Number(characterInfo.isGangster) //is_gangster
                ? "Gangster"
                : "Detective",
            levelType: statistics?.current_level?.name,
            cooldownActivated: !!Number(statusInfo.cooldownActivated),
            cooldownTimer: Number(statusInfo.warCooldown),
            isNFTLocked: isNFTLocked,
            weapons: {
                weaponName: defaultEquipmentInfo.data?.defaultEquipment?.name as string,
                weaponType: "Default Weapon",
                Ranged: defaultEquipmentInfo.data?.defaultEquipment?.type,
                image: defaultEquipmentInfo.data?.defaultEquipment?.imageUrl,
            },
            rental,
        };
        console.log({ testInformation: testInfo });
        return info;
    } catch (e) {
        console.log(e);
        return {
            nftId: -1,
            nftImage: "",
            nftName: "",
            ownedBy: "",
            skills: {
                Strength: 0,
                Accuracy: 0,
                Mastery: 0,
                Agility: 0,
                Luck: 0,
            },
            gender: "",
            race: "",
            isNFTLocked: false,
            classType: "",
            characterType: "Gangster",
            cooldownActivated: false,
            cooldownTimer: 0,
            levelNumber: 0,
            levelType: "",
            weapons: {
                weaponName: "",
                weaponType: "",
                Ranged: "",
                image: "",
            },
            rental: {
                status: "NOT_AVAILABLE_FOR_RENT",
                type: "INVALID",
                rentedBy: null,
            },
        };
    }
}

export const objectMap = (obj: any, fn: any) =>
    Object.fromEntries(Object.entries(obj).map(([k, v], i) => [k, fn(v, k, i)]));
