import StylizedButton from "@components/buttons/stylized.button";
import ColoredCrateCard from "@components/card/coloredCrateCard";
import Modal from "@components/modal/modal.component";
import ImageLoader from "@misc/ImageLoader";
import { useNavigate } from "react-router";
import { STARTER } from "@constants/images";
import { PLAY_CLOSE_SOUND, PLAY_NORMAL_CLICK, PLAY_INVENTORY_CLICK } from "@constants/audio";
interface INotEnoughCrown {
  isVisible: boolean;
  setIsVisible: any;
}
const StarterKit = ({ isVisible, setIsVisible }: INotEnoughCrown) => {
  const navigate = useNavigate();
  return (
    <Modal
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      modalTitle="Open your starter kit now!"
    >
      <div className="crownLimitWrapper">
        <div className="playerNumber">
          <div>
            {/* <ImageLoader
              src={`${process.env.REACT_APP_IMAGE_BASE_URL}/lootbox_images/2.png`}
              alt="lootbox"
              style={{ width: "120px" }}
            /> */}
            <img className="w-[350px]" src={STARTER} alt="starter" />
            {/* <img src={image} alt="" className="max-h-[200px] object-contain" /> */}
          </div>
        </div>
        <hr className="horizontal-seperator" />
        <div className="crownLimitText">
          {/* <p className="crownLimitTopText">
            Unfortunately, you do not have enough crown to join the war.
          </p> */}
          <p className="crownLimitInfo" style={{ fontSize: " 16px" }}>
            Congratulations, you have received some LootCrates! <br /> Head over
            to your inventory to start claiming some assets for GangWars.{" "}
          </p>
        </div>
        <hr className="horizontal-seperator" />
        <div className="crownLimitButton flex flex-col">
          <StylizedButton
            onClick={() => {
              PLAY_NORMAL_CLICK();
              setIsVisible(false);
              navigate("/inventory/loot-crates");
            }}
            buttonName="OPEN NOW"
          />
          <button
            onClick={() => {
              PLAY_CLOSE_SOUND();
              setIsVisible(false);
            }}
            className="notNowBtn"
          >
            Not Now
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default StarterKit;
