import { IGetUserAbility } from "@interfaces/ability.interface";
import { IItems } from "@interfaces/items.interface";
import { ABILITY, ITEM, WEAPON } from "@constants/images";
import { IUserEquipment } from "@interfaces/equipment.interface";
export const emptyStructureForAbilities: IGetUserAbility = {
  id: Math.floor(Math.random() * (-100000 - -1 + 1)) + -1,
  image: ABILITY,
  count: -1,
  name: "Sneak Moves Slot",
  description: "",
  type: "Empty",
};

export const emptyStructureForItems: IItems = {
  id: Math.floor(Math.random() * (-100000 - -1 + 1)) + -1,
  image: ITEM,
  name: "Item Slot ",
  rank: -1,
  serving: "",
  "stat-up": 3,
  type: "Empty",
  count: -1,
  description: "",
  statBoost: null,
};

export const emptyStructureForEquipments: IUserEquipment = {
  count: -1,
  name: "Empty",
  type: "Equipment Slot",
  equipmentId: Math.floor(Math.random() * (-100000 - -1 + 1)) + -1,
  stats: {
    consumptionRate: -1,
    criticalChance: 0,
    damage: 0,
    duraAmmo: 0,
    hitChance: 0,
    numberofHits: 0,
  },
  properties: { masteryRequired: 0 },
  image: WEAPON,
};
