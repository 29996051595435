import SelectionScreen from "@components/screen/selectionScreen.component";
import Countdown from "react-countdown-simple";
import { LOADING, LOADING_WAR_LIST } from "@constants/images";
import ParticipantLoddyLoadout from "@components/lobbyScreen/participantLoddyLoadout.component";
import EmptyLobbyLoadout from "@components/lobbyScreen/emptyStateLobby.component";
import ParticipatedLoadoutGame from "@components/lobbyScreen/participatedLoadout.component";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import LobbyScreenComponent from "./LobbyScreen.component";
import {
    getLobbyLockdownPeriod,
    getLockedNFTInSimulation,
    getSimulationDetail,
    getUserParticipantDetail,
    hasWalletParticipated,
} from "@services/icon.service";
import { getAbilitiesInformation } from "@utils/getAbilitiesInformation";
import { getEquipmentInformation } from "@utils/getEquipmentInformation";
import { getItemsInformation } from "@utils/getItemsInformation";
import { getNFTInformation } from "@utils/getNFTInformation";
import { NFTsDetails } from "@interfaces/nft.interface";
import { useDetailGlobalContext } from "@context/detailProvider";
import NumberOfParticipants from "./getNumberOfParticipants.component";
import TeamFormation from "./teamFormation.modal";
import { useWarListGlobalContext } from "@context/war";
import { useDispatch, useSelector } from "react-redux";

interface IEmptyLobbyDetails {
    [nftId: string]: {
        nftDetails: { nftId: number };
    };
}

interface ILobbyDetails {
    [nftId: string]: {
        items: string[];
        equipments: string[];
        abilities: string[];
        nftDetails: NFTsDetails;
    };
}

const GameLobby = ({ isScreenClose, setScreenClose, warId, walletAddress }: any) => {
    const navigate = useNavigate();
    const { reFetchChangeData } = useDetailGlobalContext();
    const { setTeamSeparation, teamSeparation } = useWarListGlobalContext();
    const { isWalletConnected } = useSelector((state: any) => state.wallet);
    // setTeamFormation
    const [lobbyLoading, setLobbyLoading] = useState(false);

    // wallet connected
    const [isWalletParticipated, setWalletParticipated] = useState<boolean>(false);

    //check if its ran for first time

    const [lobbyParticipantsDetails, setLobbyParticipantsDetails] = useState<ILobbyDetails>({});
    const [lobbyParticipatedDetails, setLobbyParticipatedDetails] = useState<any>({});
    // const [participatedNFTId, setParticipatedNFTId] = useState(-1);
    const setParticipatedLoadouts = (
        key: string,
        amount: string,
        availableInfo: any,
        nftId: string,
        tempDetails: any
    ) => {
        for (let i = 0; i < Number(amount); i++) {
            tempDetails[nftId][key].push(availableInfo);
        }
    };

    const [warDetails, setWarDetails] = useState<any>(null);
    const [lobbyLockPeriod, setLobbyLockPeriod] = useState("0");
    async function lobbyLockDownPeriod() {
        const result = await getLobbyLockdownPeriod();
        setLobbyLockPeriod(result);
    }
    async function getDetails() {
        const result = await getSimulationDetail(warId);
        setWarDetails({ ...result?.simulation, ...result?.allowedMetadata });
    }
    async function participants() {
        if (isWalletConnected) {
            const checkStatus = await hasWalletParticipated(warId, walletAddress);
            setWalletParticipated(!!Number(checkStatus));
        } else {
            setWalletParticipated(false);
        }
    }
    useEffect(() => {
        participants();
        getDetails();
        lobbyLockDownPeriod();
    }, []);

    //view loadouts
    const [isViewLoadout, setViewLoadout] = useState(false);

    //team separation

    const [teamFormationModal, setTeamFormationModal] = useState(false);

    //getsimulation details -> participants
    async function getSimDetails(preventLoading?: boolean, teamFormed?: boolean) {
        if (!preventLoading) setLobbyLoading(true);
        const userParticipatedNFTId = await getLockedNFTInSimulation(walletAddress, warId);
        const result = await getSimulationDetail(warId);
        if (
            result.teamA.length > 0 &&
            result.teamB.length > 0 &&
            teamSeparation.teamA.length === 0 &&
            teamSeparation.teamB.length === 0 &&
            !teamFormed
        ) {
            setTeamSeparation({ teamA: result.teamA, teamB: result.teamB });
            navigate(`/battle/${Number(warId)}`);
        }
        const detailsHead = ["abilities", "equipments", "items"];
        const simTempDetails: ILobbyDetails | IEmptyLobbyDetails = {};

        for await (let nft of result?.participants) {
            const nftId = Number(nft).toString();
            const details = await getUserParticipantDetail(Number(warId).toString(), nftId);
            const getNftInfo: NFTsDetails = await getNFTInformation(nftId);
            const tempDetails: ILobbyDetails = {
                [nftId.toString()]: {
                    abilities: [],
                    equipments: [],
                    items: [],
                    nftDetails: getNftInfo,
                },
            };
            for await (let key of detailsHead) {
                for await (let item of details[key]) {
                    if (key === detailsHead[0]) {
                        const availableInfo = await getAbilitiesInformation(item.id);
                        setParticipatedLoadouts(
                            key,
                            item.amount,
                            availableInfo,
                            nftId,
                            tempDetails
                        );
                    } else if (key === detailsHead[1]) {
                        const availableInfo = await getEquipmentInformation(item.id);
                        setParticipatedLoadouts(
                            key,
                            item.amount,
                            availableInfo,
                            nftId,
                            tempDetails
                        );
                    } else if (key === detailsHead[2]) {
                        const availableInfo = await getItemsInformation(item.id);
                        setParticipatedLoadouts(
                            key,
                            item.amount,
                            availableInfo,
                            nftId,
                            tempDetails
                        );
                    }
                }
            }
            if (simTempDetails && tempDetails && nftId in tempDetails) {
                simTempDetails[nftId] = tempDetails[nftId];
            }
        }

        // Check the current length of the object
        const currentLength = Object.keys(simTempDetails).length;
        if (currentLength < 10) {
            const numToAdd = 10 - currentLength;
            for (let i = 0; i < numToAdd; i++) {
                const randomNegativeInteger = Math.floor(Math.random() * -10000000000);
                simTempDetails[randomNegativeInteger] = {
                    nftDetails: { nftId: randomNegativeInteger },
                };
            }
        }
        const extractedValue = simTempDetails[Number(userParticipatedNFTId).toString()];
        delete simTempDetails[Number(userParticipatedNFTId)];
        setLobbyParticipatedDetails(extractedValue);
        setLobbyParticipantsDetails(simTempDetails as ILobbyDetails);
        setLobbyLoading(false);
    }

    useEffect(() => {
        getSimDetails();
    }, [reFetchChangeData]);

    useEffect(() => {
        const interval = setInterval(() => {
            const teamFormed = teamSeparation.teamA.length > 0 && teamSeparation.teamB.length > 0;
            getSimDetails(true, teamFormed);
        }, 8000);
        return () => clearInterval(interval);
    }, []);

    const [isLobbyLocked, setLobbyLocked] = useState(false);
    const startsGameIn = ({ days, hours, minutes, seconds }: any) => {
        if (lobbyLockPeriod && warDetails) {
            const lockTime = Number(warDetails?.startTime) / 1000 - Number(lobbyLockPeriod) / 1000;
            if (new Date() > new Date(lockTime)) {
                if (!isLobbyLocked) {
                    setLobbyLocked(true);
                }
            }
        }
        if (days === 0 && hours === 0 && minutes === 0 && seconds === 0) {
            return <span className="text-dark mr-2 uppercase normal-case">Lobby</span>;
        } else {
            return (
                <>
                    <span className="text-dark mr-2 uppercase normal-case">War Starts In </span>
                    <span>
                        {days > 0 && `${days} :`}
                        {hours < 10 ? "0" + hours : hours}:{minutes < 10 ? "0" + minutes : minutes}:
                        {seconds < 10 ? "0" + seconds : seconds}
                    </span>
                </>
            );
        }
    };

    return (
        <>
            <SelectionScreen
                isScreenClose={isScreenClose}
                setScreenClose={setScreenClose}
                className="changeSized"
                titleText={
                    <>
                        {/* <span className="text-dark uppercase normal-case">
              War Starts In{" "}
            </span> */}
                        {warDetails && Number(lobbyLockPeriod) > 0 && (
                            <Countdown
                                targetDate={new Date(
                                    new Date(
                                        Number(warDetails?.startTime) / 1000 -
                                            Number(lobbyLockPeriod) / 1000
                                    ).setHours(
                                        new Date(
                                            Number(warDetails?.startTime) / 1000 -
                                                Number(lobbyLockPeriod) / 1000
                                        ).getHours()
                                    )
                                ).toISOString()}
                                renderer={startsGameIn}
                            />
                        )}
                    </>
                }
                closeContent={
                    warDetails && (
                        <NumberOfParticipants
                            setTeamFormationModal={setTeamFormationModal}
                            warId={warDetails?.id}
                            teamSeparation={teamSeparation}
                            isLobbyLocked={isLobbyLocked}
                        />
                    )
                }
            >
                {lobbyLoading ? (
                    <div className="flex justify-center items-center w-full h-[600px]">
                        <img width={"180px"} src={LOADING} alt="loading-image" />
                    </div>
                ) : (
                    <div className="gameLobbyWrapper">
                        <div className="userLoadoutForGame">
                            {/* <div className="lobbyFirstCol"> */}
                            {isWalletParticipated && (
                                <ParticipatedLoadoutGame
                                    setViewLoadout={setViewLoadout}
                                    participatedDetails={lobbyParticipatedDetails}
                                    isLobbyLocked={isLobbyLocked}
                                />
                            )}
                            {Object.values(lobbyParticipantsDetails)
                                .slice(0, 1)
                                .map((details: any, index: number) => {
                                    if (details?.nftDetails?.nftId > 0) {
                                        return (
                                            <ParticipantLoddyLoadout
                                                participantsDetails={details}
                                            />
                                        );
                                    } else {
                                        return <EmptyLobbyLoadout />;
                                    }
                                })}
                            {/* </div> */}
                            {/* <div className="lobbySecondCol"> */}
                            {Object.values(lobbyParticipantsDetails)
                                .slice(1, 5)
                                .map((details: any, index: number) => {
                                    if (details?.nftDetails?.nftId > 0) {
                                        return (
                                            <ParticipantLoddyLoadout
                                                participantsDetails={details}
                                            />
                                        );
                                    } else {
                                        return <EmptyLobbyLoadout />;
                                    }
                                })}
                            {/* </div> */}
                            {/* <div className="lobbyThirdCol"> */}
                            {Object.values(lobbyParticipantsDetails)
                                .slice(5, 9)
                                .map((details: any, index: number) => {
                                    if (details?.nftDetails?.nftId > 0) {
                                        return (
                                            <ParticipantLoddyLoadout
                                                participantsDetails={details}
                                            />
                                        );
                                    } else {
                                        return <EmptyLobbyLoadout />;
                                    }
                                })}
                            {/* </div> */}
                            {!isWalletParticipated &&
                                Object.values(lobbyParticipantsDetails)
                                    .slice(9, 10)
                                    .map((details: any, index: number) => {
                                        if (details?.nftDetails?.nftId > 0) {
                                            return (
                                                <ParticipantLoddyLoadout
                                                    participantsDetails={details}
                                                />
                                            );
                                        } else {
                                            return <EmptyLobbyLoadout />;
                                        }
                                    })}
                        </div>
                    </div>
                )}
            </SelectionScreen>
            {isViewLoadout && (
                <LobbyScreenComponent
                    participatedNFTsDetails={lobbyParticipatedDetails.nftDetails}
                    participantsDetails={{
                        items: lobbyParticipatedDetails.items,
                        abilities: lobbyParticipatedDetails.abilities,
                        equipments: lobbyParticipatedDetails.equipments,
                    }}
                    isScreenClose={isViewLoadout}
                    setScreenClose={setViewLoadout}
                    simulationId={warId}
                    walletAddress={walletAddress}
                    war={warDetails}
                />
            )}
            {/* {teamFormationModal && (
        <TeamFormation
          isVisible={teamFormationModal}
          setIsVisible={setTeamFormationModal}
          startsGameIn={startsGameIn}
          warDetails={warDetails}
          teamSeparation={teamSeparation}
        />
      )} */}
        </>
    );
};

export default GameLobby;
