import { LOADING } from "@constants/images"
import Typewriter from "typewriter-effect";
import Modal from "../modal.component"
import { FC } from "react";

interface IRentalLoading {
    title: string,
    description: string
}

const RentalLoading: FC<IRentalLoading> = ({title, description}) => {
    return (
        <Modal
        isVisible={true}
        loading
        modalTitle={title}
        className=" rental-modal-outer-wrapper flex flex-col justify-center items-center"
      >
        <div className="flex items-center flex-col">
          <img width={220} src={LOADING} alt="loading circle" />
          <div className="description-text text-center py-4">
            <Typewriter
              options={{
                strings: description,
                autoStart: true,
                loop: true,
                cursor: ".",
              }}
            />
          </div>
        </div>
      </Modal>
    )
}
export default RentalLoading;