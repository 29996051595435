import Tag from "@components/warPage/tag/Tag.component";
import { GangstaBetTypeCount, SpecificGameTags, WeaponTypeCount } from "./warSpecific";

export const AddSpecificGameTag = ({
    specificType,
    tag,
    customId,
    isHistory = false,
}: {
    specificType: string[];
    tag: SpecificGameTags;
    customId: string | number;
    isHistory?: boolean;
}) => {
    return (
        <div className="flex gap-1 flex-wrap">
            {specificType.map((id: string) => {
                return <Tag isHistory={isHistory} customId={customId} tag={tag} tagId={+id} />;
            })}
        </div>
    );
};

export const checkForGeneralTag = (war: any) => {
    if (
        (war.allowedTypes.length === 0 && war.allowedWeapons.length === 0) ||
        (war.allowedTypes.length === GangstaBetTypeCount() &&
            war.allowedWeapons.length === WeaponTypeCount())
    )
        return true;
    return false;
};

export function getKeyByValue(object: Record<number | string, string>, values: string[]) {
    const keys = [];
    for (const key in object) {
        if (values.includes(object[key])) {
            keys.push(key);
        }
    }
    return keys;
}
