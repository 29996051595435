/* eslint-disable */
import { DEAD, DEADICON } from "@constants/images";
import { INFTCard } from "src/core/interfaces/card.interface";
import Image from "@misc/Image";
import { AiFillLock } from "react-icons/ai";
import Countdown from "react-countdown-simple";
import { useState } from "react";
import { NFT_RENT_STATUS } from "@constants/nft";
import RenderTimeCounter from "@utils/renderTimeCounter";

const NFTCard = ({
    nftImage,
    width,
    height,
    damage,
    className,
    isSelected,
    onSelect,
    nftId,
    onClick,
    dead,
    id,
    imageId,
    flip,
    locked,
    cooldownTimer,
    cardType = "nftCardImageCollection",
    borderType = "nftDefaultCardBorder",
    rental = null,
    hospitalized = null,
    showTimer = true,
    showCard = true,
}: INFTCard) => {
    const [isHovered, setIsHovered] = useState(false);
    const checkHospitalization = hospitalized && new Date(hospitalized.hospitalizedTimer / 1000) > new Date();
    return (
        <div
            id={id}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={() => {
                {
                    onSelect && onSelect(nftId);
                }
                {
                    onClick && onClick();
                }
            }}
            className={`cursor-pointer ${className}`}
            style={{ width, height, maxHeight: `${height} !important` }}
        >
            <div
            // className={`nftOuterWrapper ${isSelected ? "selectedNFTOuter" : "unSelectedNFTOuter"
            //   }`}
            >
                <div
                    // className={`nftInnerWrapper ${flip && "flip"} ${isSelected ? "selectedNFTInner" : "unSelectedNFTInner"
                    //   }`}
                    className={`${borderType && isSelected ? "nftActiveCardBorder" : borderType
                        }  relative overflow-hidden`}
                >
                    {imageId ? (
                        <Image
                            style={{ maxHeight: height }}
                            id={imageId}
                            className={`nftCardImage ${cardType} ${locked || (checkHospitalization) &&
                                "unSelected "} `}
                        />
                    ) : (
                        <img
                            className={`nftCardImage ${cardType} ${locked || (checkHospitalization) &&
                                "unSelected"} `}
                            src={nftImage}
                            alt="nft image"
                            loading="lazy"
                            style={{ maxHeight: height }}
                        />
                    )}

                    {dead && (
                        <img className={`nftCardDeadImage`} src={DEADICON} alt="nft dead image" />
                    )}
                    {locked && (
                        <div className={`${locked.cardSize} NFTIsolation`}>
                            <div className="hospitalizedTextColor flex justify-center gap-2 items-center">
                                <AiFillLock />
                                <p>Locked</p>
                            </div>
                        </div>
                    )}
                    {/* && new Date(rental.rentalTimer / 1000) > new Date() */}
                    {rental && showCard && rental.rentalType !== "NOT_AVAILABLE_FOR_RENT" && <div
                        className={`${rental.cardSize} NFTIsolation`}
                    >
                        <div className="rentalTextColor">{rental.rentalName}</div>
                        {(rental.cardSize === "NFTOnRentLg" || rental.cardSize === "NFTOnRentMd") && Number(rental.rentalTimer) > 0 && Number(rental.rentalTimer) / 1000 > Date.now() && showTimer && <div className={`rentalRemainingTime`}>
                            <p>
                                <Countdown
                                    targetDate={new Date(
                                        new Date(Number(rental.rentalTimer) / 1000).setHours(
                                            new Date(
                                                Number(rental.rentalTimer) / 1000
                                            ).getHours()
                                        )
                                    ).toISOString()}
                                    renderer={RenderTimeCounter}
                                />
                            </p>
                        </div>}
                    </div>}
                    {checkHospitalization && <div
                        className={`${hospitalized.cardSize} NFTIsolation`}
                    >
                        <div className="hospitalizedTextColor">{hospitalized.hospitalizedName ?? "Hospitalized"}</div>
                        {(hospitalized.cardSize === "NFTOnIllLg" || hospitalized.cardSize === "NFTOnIllMd") && <div className={`hospitalizedRemainingTime`}>
                            <p>
                                {Number(hospitalized.hospitalizedTimer) > 0 && (
                                    <>
                                        <Countdown
                                            targetDate={new Date(
                                                new Date(Number(hospitalized.hospitalizedTimer) / 1000).setHours(
                                                    new Date(
                                                        Number(hospitalized.hospitalizedTimer) / 1000
                                                    ).getHours()
                                                )
                                            ).toISOString()
                                            }
                                            renderer={RenderTimeCounter}
                                        />
                                    </>
                                )}
                            </p>
                        </div>}
                    </div>}
                    {/* {locked} */}

                    {/* {(damage?.isDamaged || damage?.isRental) && (
                        <div
                            className={`${damage.cardSize} mb-20 ${damage.nameName === "RENTED" && isHovered
                                ? "mt-10"
                                : damage.nameName === "RENTED" && !isHovered
                                    ? "mt-20"
                                    : null
                                }  transition-all duration-300 ease-in-out`}
                        >
                            <div className="damageName">{damage.nameName}</div>
                            <div className={`damageRemainingTime`}>
                                <p>
                                    {Number(cooldownTimer) > 0 && (
                                        <>
                                            <Countdown
                                                targetDate={new Date(
                                                    new Date(Number(cooldownTimer) / 1000).setHours(
                                                        new Date(
                                                            Number(cooldownTimer) / 1000
                                                        ).getHours()
                                                    )
                                                ).toISOString()}
                                            // disableTypes={true}
                                            // formatType="d_h_m_s"
                                            // renderer={warStartTimeElapsed}
                                            />
                                        </>
                                    )}
                                </p>
                            </div>
                        </div>
                    )} */}
                    {/* {isLocked && (
            <div className={`${damage.cardSize}`}>
              <div className="damageName">{damage.nameName}</div>
              <div className="damageRemainingTime">
                <p>{damage.damageRemainingTime}</p>
                <p>remaining</p>
              </div>
            </div>
          )} */}
                </div>
            </div>
            {/* <div
                className={` ${isSelected ? "selectedTriangleOne" : "unSelectedTriangleOne"}`}
            ></div>
            <div
                className={` ${isSelected ? "selectedTriangleTwo" : "unSelectedTriangleTwo"}`}
            ></div> */}
        </div>
    );
};

export default NFTCard;
