import { IUserEquipment } from "@interfaces/equipment.interface";
import { IEquipBtnActive, IItemCount } from "@interfaces/items.interface";
import { IEquipedLoadout } from "@interfaces/items.interface";
import { IItems } from "@interfaces/items.interface";

import { IHandleSwitchConfirm } from "@interfaces/items.interface";
import { IGetUserAbility } from "@interfaces/ability.interface";

export const handleSwitchConfirm = ({
  slot,
  setEquipedLoadout,
  loadoutEquipmentDetails,
  setEquipeBtnActive,
  equipedLoadout,
  loadoutItemDetails,
  itemIndex,
  setItemNumberCount,
  getItemNumberCount,
  getAbilityNumberCount,
  setAbilityNumberCount,
  abilityIndex,
  loadoutAbilityDetails,
}: any) => {
  if (slot === "weapon") {
    setEquipedLoadout((prev: IEquipedLoadout) => ({
      ...prev,
      equipment: loadoutEquipmentDetails,
    }));

    setEquipeBtnActive((prev: IEquipBtnActive) => ({
      ...prev,
      equipment: {
        status: true,
        id: loadoutEquipmentDetails!.equipmentId,
      },
    }));
  }
  if (slot === "item") {
    const removeStat = document.getElementById(
      equipedLoadout.items[itemIndex]?.id.toString()
    );
    if (removeStat) {
      removeStat.remove();
    }
    if (loadoutItemDetails.statBoost) {
      const skillsStat = document.getElementById(
        `${Object.keys(loadoutItemDetails.statBoost)[0]}`
      );
      if (skillsStat) {
        const span = document.createElement("span");
        const statText = document.createTextNode(
          `${Object.values(loadoutItemDetails.statBoost)[0]}`
        );
        span.setAttribute("id", loadoutItemDetails.id.toString());
        span.appendChild(statText);
        skillsStat.appendChild(span);
      }
    }
    // const switchStat = document.getElementById(
    //   equipedLoadout.items[itemIndex]?.id.toString() as string
    // );
    // if (switchStat) {
    //   switchStat.textContent = ` + ${loadoutItemDetails?.["stat-up"]}`;
    //   switchStat?.setAttribute("id", loadoutItemDetails!.id.toString());
    // }

    setItemNumberCount((prev: any) => ({
      ...prev,
      [(equipedLoadout.items[itemIndex] as IItems).id]:
        getItemNumberCount[(equipedLoadout.items[itemIndex] as IItems).id] + 1,
      [(loadoutItemDetails as IItems).id]:
        getItemNumberCount[(loadoutItemDetails as IItems).id] - 1,
    }));

    setEquipedLoadout((prev: IEquipedLoadout) => ({
      ...prev,
      items: {
        ...prev.items,
        [itemIndex]: loadoutItemDetails,
      },
    }));
    setEquipeBtnActive((prev: IEquipBtnActive) => ({
      ...prev,
      item: {
        ...prev.item,
        [itemIndex]: {
          status: true,
          itemIndex: itemIndex,
          id: (loadoutItemDetails as IItems).id,
        },
      },
    }));
  }

  if (slot === "ability") {
    setAbilityNumberCount((prev: any) => ({
      ...prev,
      [(equipedLoadout.ability[abilityIndex] as IGetUserAbility).id]:
        getAbilityNumberCount[
          (equipedLoadout.ability[abilityIndex] as IGetUserAbility).id
        ] + 1,
      [(loadoutAbilityDetails as IGetUserAbility).id]:
        getAbilityNumberCount[(loadoutAbilityDetails as IGetUserAbility).id] -
        1,
    }));

    setEquipedLoadout((prev: IEquipedLoadout) => ({
      ...prev,
      ability: {
        ...prev.ability,
        [abilityIndex]: loadoutAbilityDetails,
      },
    }));
    setEquipeBtnActive((prev: IEquipBtnActive) => ({
      ...prev,
      ability: {
        ...prev.ability,
        [abilityIndex]: {
          status: true,
          abilityIndex: abilityIndex,
          id: (loadoutAbilityDetails as IGetUserAbility).id,
        },
      },
    }));
  }
};
