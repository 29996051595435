import { Routes, Route, Navigate } from "react-router-dom";
import { Gangstabet as GangstabetComponent } from "./Gangstabet";
import GANGSTABET_ROUTES from "./routes.gangstabet";
import Seo from "@components/seo";

export default function Gangstabet() {
    return (
        <>
            <Seo
                title="GangstaBet | Gangwars"
                description="View your Gangsta NFT stats past war records."
            />
            <Routes>
                <Route
                    path={""}
                    element={<GangstabetComponent defaultNFT="Collection" />}
                />
                <Route
                    path={GANGSTABET_ROUTES.COLLECTION}
                    element={<GangstabetComponent defaultNFT='Collection' />}
                />
                <Route
                    path={GANGSTABET_ROUTES.MARKETPLACE}
                    element={<GangstabetComponent defaultNFT="Marketplace" />}
                />
                <Route
                    path={GANGSTABET_ROUTES.RENTAL}
                    element={<GangstabetComponent defaultNFT="Rent" />}
                />
                <Route
                    path="*"
                    element={<Navigate to={GANGSTABET_ROUTES.BASE + GANGSTABET_ROUTES.COLLECTION} />}
                />
            </Routes>
        </>
    );
}
