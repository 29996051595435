import {
    GangstaBetType,
    WeaponType,
    AccessoryType,
    GenderType,
    SpecificGameTags,
} from "@misc/warSpecific";
import TagTooltip from "./tagTip.component";

const Tag = ({
    tag,
    tagId,
    customId,
    isHistory = false,
}: {
    tag: SpecificGameTags;
    tagId: number | string;
    customId: number | string;
    isHistory?: boolean;
}) => {
    const tagCustomId = `tipId_${Number(customId)}_${tag}_${tagId}`;
    const content = () => {
        if (tag === "GangstaBetType") {
            if (isHistory) {
                return `${GangstaBetType[tagId]} GangstaBets were allowed in this GangWar.`;
            }
            return `You can enter this GangWar with a ${GangstaBetType[tagId]} GangstaBet.`;
        }
        if (tag === "WeaponsType") {
            if (isHistory) {
                return `${WeaponType[tagId]} was allowed in this GangWar.`;
            }
            return `You can enter this GangWar by equipping a ${WeaponType[tagId]}.`;
        }
        if (tag === "GeneralType") {
            if (isHistory) {
                return `There were no specific restrictions in this GangWar.`;
            }
            return `This GangWar does not have any weapon or GangstaBet type restrictions.`;
        }
        return "General Game -1";
    };
    return (
        <>
            <div id={tagCustomId} className={`${tag} SpecificType cursor-pointer`}>
                {tag === "GenderType" && GenderType[tagId]}
                {tag === "AccessoryType" && AccessoryType[tagId]}
                {tag === "GangstaBetType" && GangstaBetType[tagId]}
                {tag === "WeaponsType" && WeaponType[tagId]}
                {tag === "GeneralType" && "Any"}
            </div>
            <TagTooltip details={content()} tooltipId={tagCustomId} />
        </>
    );
};
export default Tag;
