export const durationOptions = [
  {
    name: "All",
    value: "all",
  },
  {
    name: "8 mins",
    value: "8",
  },
  {
    name: "12 mins",
    value: "12",
  },
  {
    name: "10 mins",
    value: "10",
  },
  {
    name: "16 mins",
    value: "16",
  },
];

export const sortNFTList = [
  // {
  //   name: "Default (Alphabetically)",
  //   value: "alphabetically",
  // },
  // {
  //   name: "Recently Used",
  //   value: "recently",
  // },
  {
    name: "Level (High to Low)",
    value: "highToLow",
  },
  {
    name: "Level (Low to Hight)",
    value: "lowToHigh",
  },
];

export const entryFeeOptions = [
  {
    name: "All",
    value: "all",
  },
  {
    name: "Free to play",
    value: "freeToPlay",
  },
  {
    name: "Paid",
    value: "paid",
  },
];

export const filterWarsOptions = [
  {
    name: "All",
    value: "all",
  },
  // {
  //   name: "Show only wars I can join",
  //   value: "eligibleWars",
  // },
  {
    name: "Joined Wars",
    value: "joinedWars",
  },
];

export const sortByOptions = [
  {
    name: "Recent",
    value: "recent",
  },
  {
    name: "Rewards (High to low)",
    value: "rewardHighLow",
  },
  {
    name: "Rewards (Low to high)",
    value: "rewardLowHigh",
  },
  {
    name: "Entry Fees (Low to high)",
    value: "entryFeeLowHigh",
  },
  {
    name: "Entry Fees (High to low)",
    value: "entryFeeHighLow",
  },
];

export const timeOptions = [
  {
    name: "All Time",
    value: "all",
  },
  {
    name: "Today",
    value: "today",
  },
  {
    name: "This Week",
    value: "week",
  },
  {
    name: "This Month",
    value: "month",
  },
];

export const warsHistoryViewOptions = [
  {
    name: "All",
    value: "all",
  },
  {
    name: "My wars only",
    value: "joinedWars",
  },
];

export const showWinners = [
  {
    name: "Show Winners",
    value: "showWinners",
  },
  {
    name: "Hide Winners",
    value: "hideWinners",
  },
];
