import ICONEX from "@assets/images/extra/hana.png";
import ICX from "@assets/images/extra/iconex.svg";
import BLUE_ICX from "@assets/images/logos/icx-blue.svg";
import NAV_GOLDEN_BG from "@assets/images/backgrounds/goldenBg.svg";
import WAR_SCREEN_BG from "@assets/images/backgrounds/warscreenBg.svg";
import TEST_NFTS from "@assets/images/nfts/testNft.svg";
import SHIELD from "@assets/images/extra/shield.svg";
import CROWN from "@assets/images/logos/crown.svg";
import PISTOL from "@assets/images/weapons/pistol.svg";
import RANGE from "@assets/images/weapons/range.svg";
import GRENADE from "@assets/images/weapons/grenade.svg";
import ABILITY from "@assets/images/weapons/ability.svg";
import ABILITY1 from "@assets/images/ability/ability1.svg";
import ITEM from "@assets/images/weapons/weapon.svg";
import WEAPON from "@assets/images/weapons/item.svg";
import CROPPEDGRENADE from "@assets/images/weapons/croppedGrenade.png";
import GB_HAT from "@assets/images/logos/gbHat.svg";
import EMPTY_HAT from "@assets/images/extra/emptyNFT.svg";
import warningIcon from "@assets/images/toast/warning.svg";
import dangerIcon from "@assets/images/toast/danger.svg";
import successIcon from "@assets/images/toast/success.svg";
import ALCOHOL from "@assets/images/weapons/Alcohol.svg";
import KNIFE from "@assets/images/weapons/knife.svg";
import LEDGER from "@assets/images/logos/ledger.svg";
import BADGE from "@assets/images/extra/badge.svg";
import CRATE from "@assets/images/crates/crate.svg";
import CRATE2 from "@assets/images/crates/Crate2.svg";
import postGame from "@assets/images/postGame/post.png";
import FEATHER from "@assets/images/postGame/bg_feather.svg";
import NEXT from "@assets/images/postGame/NEXT.svg";
import SHIELD2 from "@assets/images/postGame/SHIELD2.svg";
import BGCROWN from "@assets/images/postGame/bg_crown.svg";
import DEAD from "@assets/images/postGame/Dead.svg";
import DEADICON from "@assets/images/postGame/DEADICON.svg";
import DEFEAT from "@assets/images/postGame/defeat.png";
import NOTE from "@assets/images/postGame/notes.svg";
import FIST from "@assets/images/postGame/FIST.svg";
import HEALING from "@assets/images/postGame/HEALING.svg";
import MARKER from "@assets/images/extra/Marker.png";
import LOADING from "@assets/images/loading/loading.gif";
import TRANSACTION_LOADING from "@assets/images/loading/transaction.gif";
import LOADING_CIRCLE from "@assets/images/extra/loading.gif";
import INVENTORY_BACKGROUND from "@assets/images/backgrounds/inventory.svg";
import DISCONNECT from "@assets/images/extra/disconnect.svg";
import PRICE_CHANGE from "@assets/images/icons/priceChange.svg";
import LINEVECTOR from "@assets/images/components/lineVector.svg";
import DELETE_ICON from "@assets/images/icons/Delete.svg";
import REDIRECT_ICON from "@assets/images/icons/redirect.svg";
import ARROW_DOWN_ICON from "@assets/images/icons/arrow-down.svg";
import DOWNLOAD_ICON from "@assets/images/icons/downlod.svg";
import WALLET_ICON from "@assets/images/icons/wallet-icon.svg";
//marker
import MAP_WAR_MARKER_PRESENT from "@assets/images/extra/present_war_marker.svg";
import MAP_WAR_MARKER_UPCOMING from "@assets/images/extra/coming_war_marker.svg";

//iconex different icon
import YELLOW_ICONEX from "@assets/images/icons/Icon.png";
import HANA_LOGO from "@assets/images/icons/hana.png";

//effects
import BELITTLED from "@assets/images/effects/belittled.png";
import BLEED from "@assets/images/effects/bleed.png";
import BLIND from "@assets/images/effects/blind.png";
import BURNING from "@assets/images/effects/Burning.png";
import DAMAGE_IMMUNITY from "@assets/images/effects/damageimmunity.png";
import DIARRHEA from "@assets/images/effects/Diarrhea.png";
import EFFICIENT from "@assets/images/effects/efficient.png";
import KEENEYE from "@assets/images/effects/keen_eye.png";
import POISON from "@assets/images/effects/poison.png";
import PRECISION_STRIKE from "@assets/images/effects/Precision_Strike.png";
import QUICKEN from "@assets/images/effects/quicken.png";
import RAGE from "@assets/images/effects/Rage.png";
import REFLECT from "@assets/images/effects/reflect.png";
import REGEN from "@assets/images/effects/regen.png";
import SHIELD_UP from "@assets/images/effects/shield_up.png";
import SILENCE from "@assets/images/effects/silence.png";
import SKIPTURN from "@assets/images/effects/skipturn.png";
import STUN from "@assets/images/effects/stun.png";
import COVER from "@assets/images/effects/cover.png";
import HIDE from "@assets/images/effects/Hide.png";
import SLOW from "@assets/images/effects/slow.png";

//craft
import CRAFT from "@assets/images/logos/craft.png";

//opeing crate
import OPENING_CRATE from "@assets/images/crates/openingLoot.gif";
import STARTER_OPENING from "@assets/images/crates/starter.gif";
import PREMIUM_OPENING from "@assets/images/crates/premium.gif";

import STARTER from "@assets/images/crates/starterkit.svg";
import LOADING_WAR_LIST from "@assets/images/crates/loading.gif";

//header connect icons

import HEADER_WALLET from "@assets/images/extra/walletIcon.svg";
import ICONEX_HEADER from "@assets/images/extra/iconex.svg";

// log tabs icons
import CHAT_ICON from "@assets/images/tabIcons/chat.png";
import LOGS_ICON from "@assets/images/tabIcons/logs.png";
import SNEAK_ICON from "@assets/images/tabIcons/sneak.png";
import SPEED_ICON from "@assets/images/tabIcons/speed.png";
import USER_ICON from "@assets/images/tabIcons/user.png";

import discord from "@assets/images/logos/discord.svg";

// inventory repair and inuse state icons
import WAR_HAPPENING from "@assets/images/icons/war.png";
import REPAIR from "@assets/images/icons/repair.png";

const TABS = {
    CHAT_ICON: CHAT_ICON,
    LOGS_ICON: LOGS_ICON,
    SNEAK_ICON: SNEAK_ICON,
    SPEED_ICON: SPEED_ICON,
    USER_ICON: USER_ICON,
};

const EFFECTS: any = {
    BELITTLED: BELITTLED,
    BLEEDING: BLEED,
    BLIND: BLIND,
    BURNING: BURNING,
    LIGHT_BURN: BURNING,
    DAMAGE_IMMUNITY: DAMAGE_IMMUNITY,
    DIARRHEA: DIARRHEA,
    EFFICIENT: EFFICIENT,
    KEENEYE: KEENEYE,
    POISON: POISON,
    PRECISION_STRIKE: PRECISION_STRIKE,
    QUICKEN: QUICKEN,
    RAGE: RAGE,
    REFLECT: REFLECT,
    REGEN: REGEN,
    SHIELD_UP: SHIELD_UP,
    SILENCE: SILENCE,
    SKIPTURN: SKIPTURN,
    STUN: STUN,
    COVER: COVER,
    HIDE: HIDE,
    SLOW: SLOW,
};

const TOAST = {
    WARNING: warningIcon,
    DANGER: dangerIcon,
    SUCCESS: successIcon,
};
const DISCORD = discord;
export {
    YELLOW_ICONEX,
    HEADER_WALLET,
    ICONEX_HEADER,
    DISCONNECT,
    ABILITY,
    ABILITY1,
    ITEM,
    WEAPON,
    PISTOL,
    RANGE,
    CROPPEDGRENADE,
    ICONEX,
    NAV_GOLDEN_BG,
    TEST_NFTS,
    SHIELD,
    LEDGER,
    CROWN,
    GB_HAT,
    EMPTY_HAT,
    GRENADE,
    ALCOHOL,
    KNIFE,
    TOAST,
    BADGE,
    CRATE,
    CRATE2,
    postGame,
    FEATHER,
    BGCROWN,
    DEFEAT,
    NOTE,
    BLUE_ICX,
    FIST,
    HEALING,
    MARKER,
    WAR_SCREEN_BG,
    NEXT,
    SHIELD2,
    LOADING,
    TRANSACTION_LOADING,
    DEAD,
    DEADICON,
    LOADING_CIRCLE,
    EFFECTS,
    CRAFT,
    OPENING_CRATE,
    STARTER,
    STARTER_OPENING,
    PREMIUM_OPENING,
    LOADING_WAR_LIST,
    TABS,
    ICX,
    DISCORD,
    MAP_WAR_MARKER_UPCOMING,
    MAP_WAR_MARKER_PRESENT,
    WAR_HAPPENING,
    REPAIR,
    PRICE_CHANGE,
    LINEVECTOR,
    DELETE_ICON,
    REDIRECT_ICON,
    ARROW_DOWN_ICON,
    DOWNLOAD_ICON,
    WALLET_ICON,
    HANA_LOGO,
};
