import React, { useEffect, useRef } from "react";
import { IoCloseSharp } from "react-icons/io5";

interface IPopup {
    children: React.ReactNode;
    show: boolean;
    onClose: () => void;
    variant: "small" | "medium" | "large";
}

export default function Popup({ show, onClose, variant, children }: IPopup) {

    const detectEscKey = (ev: KeyboardEvent) => {
        if(ev.key === "Escape") {
            onClose();
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', detectEscKey);

        return () => document.removeEventListener("keydown", detectEscKey);
    }, []);

    const getVariantClass = () => {
        switch (variant) {
            case "small":
                return "popup-small";
            case "medium":
                return "popup-medium";
            case "large":
                return "popup-large";
        }
    };

    if (!show) return <></>;

    return (
        <div className="popup-overlay">
            <div style={{position: "relative"}}>
                <span className="close-icon" onClick={onClose}>
                    <IoCloseSharp color="#fff" size={"19px"} />
                </span>
                <div
                    className={["popup-container", getVariantClass()].join(" ")}
                >
                    {children}
                </div>
            </div>
        </div>
    );
}
