import axios from "axios";
import { useState, useEffect } from "react";
import ImageLoader from "./ImageLoader";
import { idWithMetaData } from "@utils/metadata/mapping";

type Props = {
  id: string;
  className?: string;
  width?: string;
  height?: string;
  style?: any;
};
const Image: React.FC<Props> = ({ id, className, width, height, style }) => {
  // const [imageURL, setImageURL] = useState("");

  // useEffect(() => {
  //   axios
  //     .get(
  //       `${process.env.REACT_APP_GANGSTABET_URL}/0x${Number(id).toString(16)}`
  //     )
  //     .then((response) => {
  //       setImageURL(response.data.token_uri);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, [id]);

  return (
    <>
      <ImageLoader
        skeletonClassName="skeletionCustomClassLogs"
        src={`https://d2l8dezwbqkb9.cloudfront.net/ipfs/img/${idWithMetaData[Number(id).toString()]
          }.gif`}
        alt="image"
        className={className}
        height={height}
        width={width}
        style={style}
      />
      {/* <img src={imageURL} alt="image" className={className} /> */}
    </>
  );
};

export default Image;
