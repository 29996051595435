import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { NFTsDetails } from "@interfaces/nft.interface";


interface IWalletState {
  isWalletConnected: boolean;
  walletAddress: string | null;
  tempWalletAddress:string | null;
  isLedger: boolean;
  ledgerPath: string | null;
  ledgerTransport: any;
  storeSignHash:null|string
}

const initialState: IWalletState = {
  isWalletConnected: false,
  walletAddress: null,
  isLedger: false,
  ledgerPath: "",
  ledgerTransport: null,
  tempWalletAddress:null,
  storeSignHash:null
};

export const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    setWalletAddress: (
      state: IWalletState,
      action: PayloadAction<string | null>
    ) => {
      state.walletAddress = action.payload;
      state.isWalletConnected = Boolean(action.payload);
    },
    setTempWalletAddress: (
      state: IWalletState,
      action: PayloadAction<string | null>
    ) => {
      state.tempWalletAddress = action.payload;
    },
    setSignStoreHash :(state,action:PayloadAction<string|null>) =>{
      state.storeSignHash = action.payload
    },
    setWalletConnection: (state, action: PayloadAction<boolean>) => {
      state.isWalletConnected = action.payload;
    },
  
   
    setIsLedger: (state, action: PayloadAction<boolean>) => {
      state.isLedger = action.payload;
    },
    setLedgerPath: (
      state: IWalletState,
      action: PayloadAction<string | null>
    ) => {
      state.ledgerPath = action.payload;
    },
    setLedgerTransport: (state: IWalletState, action: PayloadAction<any>) => {
      state.ledgerTransport = action.payload;
    },
  
 
  },
});

export const {
  setWalletAddress,
  setWalletConnection,
  setIsLedger,
  setLedgerPath,
  setLedgerTransport,
  setTempWalletAddress,
  setSignStoreHash
} = walletSlice.actions;

export default walletSlice.reducer;
