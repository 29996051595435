export const objectToHexWithPrefix = (params: any) => {
  const enc = new TextEncoder();
  const arrayBuffer = enc.encode(JSON.stringify(params));

  if (
    typeof arrayBuffer !== "object" ||
    arrayBuffer === null ||
    typeof arrayBuffer.byteLength !== "number"
  ) {
    throw new TypeError("Expected input to be an ArrayBuffer");
  }

  const view = new Uint8Array(arrayBuffer);
  let result = "";
  let value;

  for (let i = 0; i < view.length; i += 1) {
    value = view[i].toString(16);
    result += value.length === 1 ? `0${value}` : value;
  }

  return `0x${result}`;
};
