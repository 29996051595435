import { useState } from "react";
import { CROWN, LOADING, WEAPON } from "@constants/images";
import { RxStack } from "react-icons/rx";
import { GiGrenade } from "react-icons/gi";
import EquipmentStylizedCard from "@components/card/equipmentStylizedCard.component";
import InventoryBody from "@pages/inventory/InventoryBody";
import { useCardAnimation } from "@hooks/useCardAnimation";
import { IUserEquipment } from "@interfaces/equipment.interface";
import { useSelector } from "react-redux";
import { useDetailGlobalContext } from "@context/detailProvider";
import NFTCard from "@components/card/nftCard.component";
import PrimaryButton from "@components/buttons/primary.button";
import { CRAFT } from "@constants/images";
import { useNavigate } from "react-router-dom";
import { HYPERLINKS } from "@misc/links";
import { PLAY_INVENTORY_CLICK } from "@constants/audio";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { Tooltip } from "react-tooltip";
import { WAR_HAPPENING, REPAIR } from "@constants/images";
import ImageLoader from "@misc/ImageLoader";
import Countdown from "react-countdown-simple";
import CommonModal from "@components/modal/commonModal.component";
import { RiArrowDropUpLine, RiArrowDropDownLine } from "react-icons/ri";
import { GiDropWeapon } from "react-icons/gi";

const Weapons = () => {
    const [animationToggle, setAnimationToggle] = useState<boolean>(false);
    const [recover, setRecover] = useState<boolean>(false);

    const { walletAddress, isWalletConnected } = useSelector((state: any) => state.wallet);
    useCardAnimation("animateWeapon", animationToggle);

    const {
        userEquipmentDetails,
        loadingFetchUserDetails,
        userLockedEquipmentDetails,
        userCooldownEquipmentDetails,
        userUnreleasedEquipmentDetails,
        reFetchDetails,
    } = useDetailGlobalContext();

    interface IUserEquipmentExtension extends IUserEquipment {
        isLocked?: boolean;
        isInCooldown?: boolean;
    }
    const [equipmentDetails, setEquipmentDetails] = useState<IUserEquipmentExtension | null>(null);
    const navigate = useNavigate();
    const warStartTime = ({ days, hours, minutes, seconds }: any) => {
        if (days === 0 && hours === 0 && minutes === 0 && seconds === 0) {
            reFetchDetails();
        }
        return (
            <div>
                {days > 0 && `${days} d `}
                {hours < 10 ? "0" + hours : hours} hr {minutes < 10 ? "0" + minutes : minutes} min{" "}
                {seconds < 10 ? "0" + seconds : seconds} sec
            </div>
        );
    };
    const [collpase, setCollapse] = useState({
        available: true,
        inUse: true,
    });
    console.log("userUnreleasedEquipmentDetails", userUnreleasedEquipmentDetails);
    return (
        <>
            <InventoryBody>
                {(userEquipmentDetails?.length > 0 && !loadingFetchUserDetails) ||
                    userCooldownEquipmentDetails?.length > 0 ||
                    userLockedEquipmentDetails?.length > 0 ||
                    userUnreleasedEquipmentDetails.length > 0 ? (
                    <>
                        <div className="col-span-7 flex flex-col justify-between  options weaponOption">
                            <div>
                                {userEquipmentDetails?.length > 0 && (
                                    <>
                                        <div className="ml-6 flex items-center gap-1 justify-between ">
                                            <div className="flex gap-1 items-center">
                                                <p className="w-[152px]">Available Weapons</p>
                                                <IoIosInformationCircleOutline
                                                    className="cursor-pointer  mt-1"
                                                    color="#485C65"
                                                    size={20}
                                                    data-tooltip-content="These weapons are available to equip in upcoming GangWars."
                                                    id="availableWeapons"
                                                />
                                                <Tooltip
                                                    style={{
                                                        zIndex: 999,
                                                        maxWidth: "300px",
                                                        opacity: 1,
                                                    }}
                                                    anchorSelect="#availableWeapons"
                                                />
                                            </div>
                                            <hr className="w-[100%] speInventoryTop " />

                                            {collpase.available ? (
                                                <RiArrowDropUpLine
                                                    size={30}
                                                    className="text-gray min-w-[30px] mr-3 cursor-pointer"
                                                    onClick={() => {
                                                        setCollapse((prev) => ({
                                                            ...prev,
                                                            available: !collpase.available,
                                                        }));
                                                    }}
                                                />
                                            ) : (
                                                <RiArrowDropDownLine
                                                    size={30}
                                                    className="text-gray min-w-[30px] mr-3 cursor-pointer"
                                                    onClick={() => {
                                                        setCollapse((prev) => ({
                                                            ...prev,
                                                            available: !collpase.available,
                                                        }));
                                                    }}
                                                />
                                            )}
                                        </div>
                                        <div className="flex gap-6 flex-wrap p-[22px]">
                                            {userEquipmentDetails?.map(
                                                (item: IUserEquipment, index: number) => {
                                                    if (
                                                        index <
                                                        (collpase.available
                                                            ? userEquipmentDetails.length
                                                            : 0)
                                                    )
                                                        return (
                                                            <div className="col-span-1  max-w-[175px] min-w-[175px] min-h-[201px] ">
                                                                <div
                                                                    className={
                                                                        "border inventoryBoxHover flex flex-col relative items-center justify-center  pt-8 px-4 cursor-pointer " +
                                                                        (equipmentDetails?.equipmentId ===
                                                                            item.equipmentId &&
                                                                            !item.hasOwnProperty(
                                                                                "isLocked"
                                                                            ) &&
                                                                            !item.hasOwnProperty(
                                                                                "isInCooldown"
                                                                            )
                                                                            ? "activeOption"
                                                                            : "apply-border")
                                                                    }
                                                                    onClick={() => {
                                                                        PLAY_INVENTORY_CLICK();
                                                                        setEquipmentDetails(item);
                                                                        setAnimationToggle(
                                                                            !animationToggle
                                                                        );
                                                                    }}
                                                                >
                                                                    <div className="image-background-inventory">
                                                                        <img
                                                                            src={item.image}
                                                                            alt=""
                                                                            className="h-[120px] "
                                                                        />
                                                                    </div>
                                                                    <p className="mt-5 pb-3 font-semibold">
                                                                        {item.name}
                                                                    </p>
                                                                    <p className="mt-5 pb-3  equipmentCount font-semibold px-4">
                                                                        <span className="text-sm font-normal lowercase">
                                                                            x
                                                                        </span>
                                                                        {Number(item.count)}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        );
                                                }
                                            )}
                                        </div>
                                    </>
                                )}
                                {(userLockedEquipmentDetails.length > 0 ||
                                    userCooldownEquipmentDetails.length > 0 ||
                                    userUnreleasedEquipmentDetails.length > 0) && (
                                        <>
                                            <div className="ml-6 flex items-center gap-1 justify-between ">
                                                <div className="flex gap-1 items-center">
                                                    <p className="w-[152px] whitespace-nowrap">
                                                        Unavailable Weapons
                                                    </p>
                                                    <IoIosInformationCircleOutline
                                                        className="cursor-pointer mt-1"
                                                        color="#485C65"
                                                        size={20}
                                                        data-tooltip-content="These weapons are either being used in a war, being repaired or not released currently. "
                                                        id="unavailableWeapons"
                                                    />
                                                    <Tooltip
                                                        style={{
                                                            zIndex: 999,
                                                            maxWidth: "300px",
                                                            opacity: 1,
                                                        }}
                                                        anchorSelect="#unavailableWeapons"
                                                    />
                                                </div>
                                                <hr className="w-[100%] speInventoryTop " />
                                                {collpase.inUse ? (
                                                    <RiArrowDropUpLine
                                                        size={30}
                                                        className="text-gray min-w-[30px] mr-3 cursor-pointer"
                                                        onClick={() => {
                                                            setCollapse((prev) => ({
                                                                ...prev,
                                                                inUse: !collpase.inUse,
                                                            }));
                                                        }}
                                                    />
                                                ) : (
                                                    <RiArrowDropDownLine
                                                        size={30}
                                                        className="text-gray  min-w-[30px] mr-3 cursor-pointer"
                                                        onClick={() => {
                                                            setCollapse((prev) => ({
                                                                ...prev,
                                                                inUse: !collpase.inUse,
                                                            }));
                                                        }}
                                                    />
                                                )}
                                            </div>
                                            {(userLockedEquipmentDetails.length > 0 ||
                                                userCooldownEquipmentDetails.length > 0 ||
                                                userUnreleasedEquipmentDetails.length > 0) && (
                                                    <div className="flex gap-6 flex-wrap p-[22px]">
                                                        {[
                                                            ...userLockedEquipmentDetails,
                                                            ...userCooldownEquipmentDetails,
                                                            ...userUnreleasedEquipmentDetails,
                                                        ]?.map((item: IUserEquipment, index: number) => {
                                                            if (
                                                                index <
                                                                (collpase.inUse
                                                                    ? userLockedEquipmentDetails.length +
                                                                    userCooldownEquipmentDetails.length +
                                                                    userUnreleasedEquipmentDetails.length
                                                                    : 0)
                                                            )
                                                                return (
                                                                    <div className="col-span-1 relative  max-w-[130px] min-w-[130px] min-h-[180px] ">
                                                                        <div
                                                                            className={
                                                                                "border inventoryBoxHover max-h-[180px] flex flex-col relative items-center justify-center  pt-8 px-4 cursor-pointer " +
                                                                                (equipmentDetails?.uniqueKey ===
                                                                                    item.uniqueKey
                                                                                    ? "activeOption"
                                                                                    : "apply-border")
                                                                            }
                                                                            onClick={() => {
                                                                                PLAY_INVENTORY_CLICK();
                                                                                setEquipmentDetails(item);
                                                                                setAnimationToggle(
                                                                                    !animationToggle
                                                                                );
                                                                            }}
                                                                        >
                                                                            <div className="image-background-inventory-inuse relative">
                                                                                <img
                                                                                    src={item.image}
                                                                                    alt=""
                                                                                    className="h-[100px] p-2 "
                                                                                />
                                                                                {item.isInCooldown ||
                                                                                    item.isLocked ? (
                                                                                    <ImageLoader
                                                                                        width="18px"
                                                                                        height="18px"
                                                                                        className="absolute top-[15px] left-[10px]"
                                                                                        src={
                                                                                            item.isInCooldown
                                                                                                ? REPAIR
                                                                                                : WAR_HAPPENING
                                                                                        }
                                                                                        // src={WAR_HAPPENING}
                                                                                        alt="in use in war"
                                                                                    />
                                                                                ) : (
                                                                                    <GiDropWeapon
                                                                                        size={20}
                                                                                        color="#70878f"
                                                                                        className="absolute top-[15px] left-[10px]"
                                                                                    />
                                                                                )}
                                                                            </div>
                                                                            <p className="mt-2 pb-3 text-xs font-semibold">
                                                                                {item.name}
                                                                            </p>
                                                                            {item.isLocked ||
                                                                                (item.isUnreleased && (
                                                                                    <p className="mt-5 pb-3  equipmentCount-inuse font-semibold px-4">
                                                                                        <span className="text-xs font-normal lowercase">
                                                                                            x
                                                                                        </span>
                                                                                        {Number(item.count)}
                                                                                    </p>
                                                                                ))}
                                                                        </div>
                                                                    </div>
                                                                );
                                                        })}
                                                    </div>
                                                )}
                                        </>
                                    )}

                                {/* {userCooldownEquipmentDetails.length > 0 && (
                  <>
                    <div className="ml-6 flex gap-1 items-center">
                      <p>In Cooldown</p>
                      <IoIosInformationCircleOutline
                        className="cursor-pointer mt-1"
                        color="#485C65"
                        size={20}
                        data-tooltip-content="These weapons are currently being repaired and cannot be used until they are fully repaired.
                        "
                        id="availableWeapons"
                      />
                      <Tooltip
                        style={{ zIndex: 999, maxWidth: "300px", opacity: 1 }}
                        anchorSelect="#availableWeapons"
                      />
                    </div>
                    {userCooldownEquipmentDetails.length > 0 && (
                      <div className="flex gap-6 flex-wrap p-[22px]">
                        {userCooldownEquipmentDetails?.map(
                          (item: IUserEquipment, index: number) => (
                            <div className="col-span-1 relative  max-w-[175px] min-w-[175px] min-h-[201px] ">
                              <div
                                className={
                                  "border inventoryBoxHover flex flex-col relative items-center justify-center  pt-8 px-4 cursor-pointer " +
                                  (equipmentDetails?.uniqueKey ===
                                  item.uniqueKey
                                    ? "activeOption"
                                    : "apply-border")
                                }
                                onClick={() => {
                                  PLAY_INVENTORY_CLICK();
                                  setEquipmentDetails(item);
                                  setAnimationToggle(!animationToggle);
                                }}
                              >
                                <div className="image-background-inventory relative">
                                  <img
                                    src={item.image}
                                    alt=""
                                    className="h-[120px] "
                                  />
                                  <ImageLoader
                                    width="20px"
                                    height="20px"
                                    className="absolute top-[15px] left-[15px]"
                                    // src={true ? REPAIR : WAR_HAPPENING}
                                    src={REPAIR}
                                    alt="in use in war"
                                  />
                                </div>
                                <p className="mt-5 pb-3 font-semibold">
                                  {item.name}
                                </p>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    )}
                  </>
                )} */}

                                {/* <div className="moreInfoWeapons w-full overflow-hidden">
                  <hr className="moreInfoSeperator" />
                  <div className="flex flex-col mx-4 my-2 gap-1">
                    <p className=" mt-1 text-left">Default Equipment Note:</p>
                    <p className="text-left  cursor-pointer">
                      <b>Default Weapons</b> are not shown in GangWar inventory
                      since they are tied to the GangstaBets. You can view the
                      default weapons your GangstaBets have in your{" "}
                      <u
                        onClick={() => {
                          navigate("/gangstabet");
                          PLAY_INVENTORY_CLICK();
                        }}
                      >
                        GangstaBet collection
                      </u>
                      .
                    </p>
                  </div>
                </div> */}
                            </div>
                            <div className="moreInfoWeapons w-full ">
                                <hr className="moreInfoSeperator" />
                                <div className="flex flex-col mx-4 my-2 gap-1">
                                    <p className=" mt-1 text-left">Default Equipment Note:</p>
                                    <p className="text-left  cursor-pointer">
                                        <b>Default Weapons</b> are not shown in GangWar inventory
                                        since they are tied to the GangstaBets. You can view the
                                        default weapons your GangstaBets have in your{" "}
                                        <u
                                            onClick={() => {
                                                navigate("/gangstabet");
                                                PLAY_INVENTORY_CLICK();
                                            }}
                                        >
                                            GangstaBet collection
                                        </u>
                                        .
                                    </p>
                                </div>
                            </div>
                        </div>
                        {equipmentDetails ? (
                            <div className="col-span-3 flex flex-col justify-between details overflow-hidden">
                                <div className="flex flex-col animation" id="animateWeapon">
                                    <div className="flex items-center gap-4 ">
                                        <EquipmentStylizedCard
                                            equipmentImages={equipmentDetails.image}
                                            className="setColor"
                                        />

                                        <div className="flex flex-col items-start justify-center text-white">
                                            <h1 className="text-lg font-proxima">
                                                {equipmentDetails.name}
                                            </h1>
                                            <div className="flex items-center text-sm">
                                                <GiGrenade className="h-8 mr-2" />
                                                {equipmentDetails.type}
                                            </div>
                                            <div className="flex items-center text-sm">
                                                <RxStack className=" mr-2" />
                                                Basic
                                            </div>
                                        </div>
                                    </div>

                                    {/* <button className="my-4 text-center gap-4 px-4 py-2 bg-[#141a1e] text-lg font-bold">
                    <span className="text-white ">
                      {equipmentDetails.properties.masteryRequired > 0
                        ? equipmentDetails.properties.masteryRequired
                        : "No "}{" "}
                      MAS REQUIRED
                    </span>{" "}
                  </button> */}
                                    {equipmentDetails?.isLocked && (
                                        <div className="inUseState mt-4 flex items-start gap-2">
                                            <p className="inUseTexts">
                                                <span>Weapon in use:</span> This weapon is actively
                                                being used in a war.
                                            </p>
                                            <ImageLoader
                                                width="20px"
                                                height="20px"
                                                className=""
                                                src={WAR_HAPPENING}
                                                alt="in use in war"
                                            />
                                        </div>
                                    )}
                                    {equipmentDetails?.isUnreleased && (
                                        <div className="inUseState mt-4 flex items-start gap-2">
                                            <p className="inUseTexts">
                                                <span>Unreleased Weapon:</span> This Weapon will be
                                                usable after the next update of GangWars.
                                            </p>
                                            <GiDropWeapon size={30} color="#70878f" className="" />
                                        </div>
                                    )}

                                    {equipmentDetails?.isInCooldown && (
                                        <div className="inCooldownState mt-4 flex flex-col gap-2">
                                            <div className="autoRepairTimerBlock">
                                                <div className="autoTimer flex items-start justify-between">
                                                    <div>
                                                        <p className="repairText">
                                                            Auto repair in:
                                                        </p>
                                                        <p className="repairTimer">
                                                            <Countdown
                                                                targetDate={new Date(
                                                                    new Date(
                                                                        (equipmentDetails.cooldownTime as number) /
                                                                        1000
                                                                    ).setHours(
                                                                        new Date(
                                                                            (equipmentDetails.cooldownTime as number) /
                                                                            1000
                                                                        ).getHours()
                                                                    )
                                                                ).toISOString()}
                                                                disableTypes={true}
                                                                renderer={warStartTime}
                                                            />
                                                        </p>
                                                    </div>
                                                    <ImageLoader
                                                        width="30px"
                                                        height="30px"
                                                        className=""
                                                        src={REPAIR}
                                                        alt="in use in war"
                                                    />
                                                </div>
                                            </div>

                                            {/* <PrimaryButton
                        width="250px"
                        buttonName="RAPID RECOVEREY"
                        icon={<FaHeart className="text-red" />}
                        extraIcon={
                          <img src={CROWN} alt="icon" className="h-4" />
                        }
                        extraText={"200"}
                        className="ml-[-4px]"
                        onClick={() => setRecover(true)}
                      /> */}
                                            <p className="repairText">
                                                <span>Notice:</span> This weapon is currently being
                                                repaired and cannot be used in a war.
                                            </p>
                                        </div>
                                    )}

                                    <div className="py-3 border-y-[0.5px] border-slate-500 border-gray text-white mt-4">
                                        <span>{equipmentDetails.description}</span>
                                    </div>

                                    {equipmentDetails?.isUnreleased ? (
                                        <div className="unSelectedTextState flex items-center justify-center h-[150px] opacity-60 text-4xl">
                                            Coming soon
                                        </div>
                                    ) : (
                                        <div className="grid grid-cols-2 text-left py-4 gap-4 gap-x-0">
                                            <span>
                                                <div className="title text-md text-gray-400">
                                                    Damage
                                                </div>
                                                <div className="title text-lg text-white">
                                                    {equipmentDetails.stats.damage}{" "}
                                                    <span className="text-sm">
                                                        ({equipmentDetails.stats.numberofHits} hits)
                                                    </span>
                                                </div>
                                            </span>
                                            <span>
                                                <div
                                                    // title="Scales with Gangstabet's DuraAmmo stat."
                                                    className="help-text-weapons cursor-help title text-md text-gray-400"
                                                >
                                                    {equipmentDetails?.type === "Melee"
                                                        ? "Base Durability"
                                                        : "Base Ammo"}
                                                </div>
                                                <div className="title text-lg text-white">
                                                    {equipmentDetails.stats.duraAmmo}
                                                    <span className="text-sm">
                                                        {" "}
                                                        (uses{" "}
                                                        {equipmentDetails.stats.consumptionRate} per
                                                        hit)
                                                    </span>
                                                </div>
                                            </span>
                                            <span>
                                                <div className="title text-md text-gray-400">
                                                    Hit Chance
                                                </div>
                                                <div className="title text-lg text-white">
                                                    {Number(
                                                        equipmentDetails.stats.hitChance * 100
                                                    )?.toFixed(0)}
                                                    %
                                                </div>
                                            </span>
                                            <span>
                                                <div className="title text-md text-gray-400">
                                                    Critical Hit Chance
                                                </div>
                                                <div className="title text-lg text-white">
                                                    {Number(
                                                        equipmentDetails.stats.criticalChance * 100
                                                    )?.toFixed(0)}
                                                    %
                                                </div>
                                            </span>
                                        </div>
                                    )}

                                    {!equipmentDetails?.isUnreleased && (
                                        <div
                                            className="viewInCraft"
                                            onClick={() => {
                                                PLAY_INVENTORY_CLICK();
                                                window.open(
                                                    "https://craft.network/collection/gangstaverse-weapons",
                                                    "_blank"
                                                );
                                            }}
                                        >
                                            <img src={CRAFT} alt="craft" />
                                            <button className="">View in Craft</button>
                                        </div>
                                    )}
                                </div>
                                <div className="moreInfoText">
                                    <hr className="seperatorInventory" />
                                    <p className=" mt-5">For More details on Weapons</p>
                                    <a
                                        href={HYPERLINKS.WEAPONS}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Click here to read the documentation.
                                    </a>
                                </div>
                            </div>
                        ) : (
                            <div className="col-span-3 flex items-center justify-center details overflow-hidden">
                                <p className="text-4xl text-center unSelectedTextState opacity-20">
                                    SELECT A WEAPON TO SEE DETAILS
                                </p>
                            </div>
                        )}
                    </>
                ) : loadingFetchUserDetails ? (
                    <div className="col-span-10 ">
                        <div className="flex flex-col gap-2 w-full h-full items-center justify-center">
                            <img width={220} src={LOADING} alt="loading circle" />
                        </div>
                    </div>
                ) : (
                    <div className="col-span-10">
                        <div className="flex flex-col gap-2 w-full h-full items-center justify-center">
                            <div className="flex flex-col gap-2 w-full h-full items-center justify-center">
                                <NFTCard
                                    nftImage={WEAPON}
                                    height={"180px"}
                                    width={"145px"}
                                    className="inactive-image2"
                                />
                                <p className="text-3xl big-shoulders">NO WEAPONS FOUND</p>
                                <p className="text-gray w-2/3 max-w-[500px] text-center">
                                    It looks like your Weapons Inventory is currently empty. Explore
                                    Craft marketplace to purchase new Weapons.
                                </p>
                                <PrimaryButton
                                    onClick={() => {
                                        PLAY_INVENTORY_CLICK();
                                        window.open(
                                            "https://craft.network/collection/gangstaverse-weapons",
                                            "_blank"
                                        );
                                    }}
                                    className=""
                                    buttonName="buy weapons"
                                />
                            </div>
                            <div className="moreInfoTextEmpty w-full overflow-hidden">
                                <hr className="seperatorInventory" />
                                <div className="flex flex-col gap-2">
                                    <p className=" mt-5 text-center">For More details on Weapons</p>
                                    <a
                                        href={HYPERLINKS.WEAPONS}
                                        target="_blank"
                                        className="text-center"
                                        rel="noreferrer"
                                    >
                                        Click here to read the documentation.
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {recover && (
                    <CommonModal
                        isVisible={recover}
                        width="400px"
                        modalTitle="Confirm quick repair"
                        setIsVisible={setRecover}
                        textInformation="Are you sure you want to use crown to repair this weapon? You cannot undo this action after you click repair."
                        approveAction={"Recover For"}
                        icon={<img src={CROWN} alt="icon" className="h-5" />}
                        extraText={<span className="text-yellow">200</span>}
                    />
                )}
            </InventoryBody>
        </>
    );
};

export default Weapons;
