import { IItems } from "@interfaces/items.interface";
import { usersItemsBalance } from "@services/icon.service";
import { getItemDetails } from "@api/api";
import { IItemCount } from "@interfaces/items.interface";
import { getItemsInformation } from "./getItemsInformation";

interface IGetItems {
    walletAddress: string | null;
    setItemNumberCount: React.Dispatch<React.SetStateAction<IItemCount>>;
    setUserItemsDetails: React.Dispatch<React.SetStateAction<Array<IItems>>>;
    setUserItemsDetailsWithSeperation: React.Dispatch<
        React.SetStateAction<{
            stats: Array<IItems>;
            nonStats: Array<IItems>;
            unReleased: Array<IItems>;
        }>
    >;
}

const UNRELEASED_ITEM_TYPE = "War-prep Items";
const UNRELEASED_ITEMS_ID = [2, 35, 8];
export async function getItems({
    walletAddress,
    setItemNumberCount,
    setUserItemsDetails,
    setUserItemsDetailsWithSeperation,
}: IGetItems) {
    const userItems: Array<IItems> = [];
    const diffStatNonStat: {
        stats: Array<IItems>;
        nonStats: Array<IItems>;
        unReleased: Array<IItems>;
    } = {
        stats: [],
        nonStats: [],
        unReleased: [],
    };
    const items = await usersItemsBalance(walletAddress);
    for await (let item of items) {
        if (Number(item.count) > 0) {
            setItemNumberCount((prev) => ({
                ...prev,
                [Number(item.id)]: Number(item.count),
            }));
            const info = await getItemsInformation(item.id);
            info["count"] = Number(item.count);
            if (
                info?.statBoost &&
                info?.type !== UNRELEASED_ITEM_TYPE &&
                !UNRELEASED_ITEMS_ID.includes(Number(item.id))
            ) {
                diffStatNonStat.stats.push(info as IItems);
            } else if (
                info?.type === UNRELEASED_ITEM_TYPE ||
                UNRELEASED_ITEMS_ID.includes(Number(item.id))
            ) {
                const userItem = { ...info };
                userItem["id"] = (userItem.id as number) + 10011;
                userItem["uniqueKey"] = `released_${Number(userItem.id)}`;
                userItem["isUnreleased"] = true;
                diffStatNonStat.unReleased.push(userItem as IItems);
            } else if (!UNRELEASED_ITEMS_ID.includes(Number(item.id))) {
                diffStatNonStat.nonStats.push(info as IItems);
            }
            if (!UNRELEASED_ITEMS_ID.includes(Number(item.id))) userItems.push(info as IItems);
        }
    }
    setUserItemsDetails(userItems);
    setUserItemsDetailsWithSeperation(diffStatNonStat);
}
