import React, { useState, useEffect } from 'react';

interface MessageProp {
  isOwnMessage: boolean;
  message: any;
  userStatsDetails: any;
  playersInfo: any;
}

const ChatMessage = ({
  isOwnMessage,
  message,
  userStatsDetails,
  playersInfo,
}: MessageProp) => {
  const [minAgo, setMinAgo] = useState('');
  const getMessagetime = () => {
    const messageSeconds = message?.timestamp?.seconds;
    const currentTimeInSeconds = Math.floor(Date.now() / 1000);
    const timeDiff = currentTimeInSeconds - messageSeconds;
    if (timeDiff < 0 || !timeDiff) {
      setMinAgo('0 mins ago');
    } else if (Math.floor(timeDiff / 60) < 60) {
      setMinAgo(`${Math.floor(timeDiff / 60)} mins ago`);
    } else if (Math.floor(timeDiff / 60) < 1440) {
      const hours = Math.floor(timeDiff / 3600);
      const minutes = Math.floor((timeDiff % 3600) / 60);
      const lastSeenText = `${hours} hour${
        hours > 1 ? 's' : ''
      } and ${minutes} min${minutes > 1 ? 's' : ''} ago`;
      setMinAgo(lastSeenText);
    } else {
      const days = Math.floor(timeDiff / 86400);
      // const hours = Math.floor(timeDiff / 3600);
      const lastSeenText = `${days} day${
        days > 1 ? 's' : ''
      } ago`;
      setMinAgo(lastSeenText);
    }
  };

  const getImage = () => {
    let currUser = null;
    for (const teamKey in playersInfo) {
      if (Object.prototype.hasOwnProperty.call(playersInfo, teamKey)) {
        const team = playersInfo[teamKey];
        for (const member of team) {
          const playerTokenId = parseInt(member.token_id, 16);
          if (playerTokenId == message?.uid) {
            currUser = member;
            break;
          }
        }
        if (currUser) break;
      }
    }
    return currUser?.token_uri;
  };

  useEffect(() => {
    getMessagetime();

    const interval = setInterval(getMessagetime, 60000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div
      className={`message ${isOwnMessage &&
        'own-message'} animate__animated animate__fadeInUp`}
    >
      <div className='user-avatar'>
        <img src={getImage()} alt={userStatsDetails?.nftName} />
      </div>
      <div className='message-content'>
        <h4 className='user-name'>{message?.displayName}</h4>
        <p className='message-text'>{message?.text}</p>
        <span className='message-time'>{minAgo}</span>
      </div>
    </div>
  );
};

export default ChatMessage;
