import { BiGift } from "react-icons/bi";
import { IRankRewards, ILeaderboardData, IStats } from "../interfaces/leaderboard.interface";
import CrownImg from "@assets/images/leaderboard/crown.svg";
import Indicator from "@components/indicator";
import { memo } from "react";
import { getGbLevel } from "../utils.leaderboard";
import { HYPERLINKS } from "@misc/links";

const RankRewards = ({ rank }: IRankRewards) => {
    return (
        <div className="lb-rank-rewards">
            <button>
                <BiGift />
                <span>See rank {rank} rewards</span>
            </button>
        </div>
    );
};

function renderRank(rank: number, goingUp?: boolean) {
    const componentToShow =
        typeof goingUp !== "undefined" ? (
            goingUp ? (
                <Indicator variant="up" />
            ) : (
                <Indicator variant="down" />
            )
        ) : (
            undefined
        );
    return (
        <div className={["lb-rank", `rank-${rank}`].join(" ")}>
            <div className="rank-number">
                {rank}
                {componentToShow}
            </div>
            {rank > 0 && rank <= 3 ? (
                <span>
                    <img src={CrownImg} alt="winner icon" />
                </span>
            ) : (
                <span style={{ width: "48px" }} />
            )}
        </div>
    );
}

const LeaderboardTable = ({
    data,
    columns,
    selectedStats,
}: {
    data: Array<ILeaderboardData>;
    columns: Array<string>;
    selectedStats: IStats;
}) => {
    const colsSet = Array.from(new Set(columns));
    console.log("data", data);
    return (
        <table id="leaderboard-table">
            {colsSet.length <= 4 ? (
                <colgroup>
                    <col span={1} style={{ width: "12%" }} />
                    <col span={1} style={{ width: "50%" }} />
                    <col span={1} style={{ width: "18%" }} />
                    <col span={1} style={{ width: "23%" }} />
                </colgroup>
            ) : (
                <colgroup>
                    <col span={1} style={{ width: "10%" }} />
                    <col span={1} style={{ width: "40%" }} />
                    <col span={1} style={{ width: "15%" }} />
                    <col span={1} style={{ width: "15%" }} />
                    <col span={1} style={{ width: "15%" }} />
                </colgroup>
            )}
            <thead>
                <tr>
                    {colsSet.map((th) => (
                        <th key={th}>{th != "rewards" ? th : ""}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                <tr style={{ height: "10px" }}></tr>
                {data.map((nftData) => (
                    <tr className={nftData.isUserNft ? "user-row" : ""} key={nftData.gbetName}>
                        <td>{renderRank(nftData.rank)}</td>
                        <td style={{ position: "relative" }}>
                            <div className="lb-gbet-img-container">
                                <a
                                    href={`${HYPERLINKS.GANGSTABET_PROFILE}/${nftData.id}`}
                                    target="_blank"
                                    rel="noopener"
                                >
                                    <img
                                        className="lb-gbet-img"
                                        src={nftData.gbetImg}
                                        alt="gbet cropped img"
                                    />
                                    <span className="lb-gbet-name">
                                        {nftData.gbetName} <br />
                                        <span
                                            style={{
                                                fontWeight: "400",
                                                fontSize: "14px",
                                                lineHeight: "20px",
                                                color: "#9AA8AC",
                                                display: "table-cell",
                                                verticalAlign: "middle",
                                            }}
                                        >
                                            {nftData.gbetClass}
                                            &nbsp;
                                            <span
                                                style={{
                                                    fontSize: "8px",
                                                    transform: "translateY(-1px)",
                                                    position: "absolute",
                                                }}
                                            >
                                                &#9679;
                                            </span>
                                            &nbsp;&nbsp;&nbsp; Level{" "}
                                            {getGbLevel(nftData.currentLevel)}
                                        </span>
                                    </span>
                                </a>
                            </div>
                        </td>
                        {["wins", "damageDealt", "heal", "kills"].includes(selectedStats) && (
                            <td className="lb-wins">{Math.floor(nftData[selectedStats] ?? 0)}</td>
                        )}
                        <td className="lb-wars-joined">{Math.floor(nftData.gamesPlayed)}</td>
                        {colsSet.length === 5 && (
                            <td>
                                {selectedStats !== "gamesPlayed"
                                    ? nftData[`${selectedStats}Percent`]
                                    : ""}
                            </td>
                        )}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default memo(LeaderboardTable);
