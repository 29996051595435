"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/*
 * Original Code
 * https://github.com/ethereumjs/ethereumjs-util/blob/master/index.js
 */
const js_sha3_1 = require("js-sha3");
const uuid_1 = require("uuid");
const node_1 = require("./module/node");
const scryptsy_1 = require("./module/scryptsy");
const Hexadecimal_1 = require("./data/Hexadecimal");
const Util_1 = require("./data/Util");
const Validator_1 = require("./data/Validator");
const Exception_1 = require("./Exception");
const Type_1 = require("./data/Type");
const secp256k1 = require("secp256k1");
/**
 * Class which provides EOA functions.
 */
class Wallet {
    /**
     * Creates an instance of Wallet.
     * @param {string} privKey - The private key.
     */
    constructor(privKey) {
        if (!privKey) {
            const error = new Exception_1.WalletError("A private key must be supplied to the constructor.");
            throw error.toString();
        }
        if (privKey && !(0, Validator_1.isPrivateKey)(privKey)) {
            const error = new Exception_1.WalletError(`[${privKey}] is not a valid private key.`);
            throw error.toString();
        }
        this._privKey = privKey;
    }
    /**
     * Creates an instance of Wallet.
     * @static
     * @return {Wallet} The wallet instance.
     */
    static create() {
        let privKey;
        do {
            privKey = node_1.crypto.randomBytes(32);
        } while (!secp256k1.privateKeyVerify(privKey));
        return new Wallet(privKey);
    }
    /**
     * Import existing wallet instance using private key.
     * @static
     * @param {string} privKey - The private key.
     * @return {Wallet} The wallet instance.
     */
    static loadPrivateKey(privKey) {
        if (!(0, Validator_1.isPrivateKey)(privKey)) {
            const error = new Exception_1.WalletError(`[${privKey}] is not a valid private key.`);
            throw error.toString();
        }
        const pkBuffer = Buffer.from(privKey, "hex");
        return new Wallet(pkBuffer);
    }
    /**
     * Import existing wallet instance using keystore object.
     * @static
     * @param {object|string} keystore - The keystore object or stringified object.
     * @param {string} password - The password of keystore object.
     * @param {boolean=} nonStrict - Set whether checking keystore file case-insensitive or not. (affects when 'keystore' param is string.)
     * @return {Wallet} The wallet instance.
     */
    static loadKeystore(keystore, password, nonStrict) {
        if (!(0, Type_1.isString)(password)) {
            const error = new Exception_1.WalletError("Password is invalid.");
            throw error.toString();
        }
        const json = typeof keystore === "object"
            ? keystore
            : JSON.parse(nonStrict
                ? keystore.toLowerCase()
                : keystore);
        if (json.version !== 3) {
            const error = new Exception_1.WalletError("This is not a V3 wallet.");
            throw error.toString();
        }
        let derivedKey;
        let kdfparams;
        if (json.crypto.kdf === "scrypt") {
            kdfparams = json.crypto.kdfparams;
            derivedKey = scryptsy_1.default(Buffer.from(password), Buffer.from(kdfparams.salt, "hex"), kdfparams.n, kdfparams.r, kdfparams.p, kdfparams.dklen);
        }
        else if (json.crypto.kdf === "pbkdf2") {
            kdfparams = json.crypto.kdfparams;
            if (kdfparams.prf !== "hmac-sha256") {
                const error = new Exception_1.WalletError("It's an unsupported parameters to PBKDF2.");
                throw error.toString();
            }
            derivedKey = node_1.crypto.pbkdf2Sync(Buffer.from(password), Buffer.from(kdfparams.salt, "hex"), kdfparams.c, kdfparams.dklen, "sha256");
        }
        else {
            const error = new Exception_1.WalletError("It's an unsupported key derivation scheme.");
            throw error.toString();
        }
        const ciphertext = Buffer.from(json.crypto.ciphertext, "hex");
        const mac = (0, js_sha3_1.keccak256)(Buffer.concat([derivedKey.slice(16, 32), ciphertext]));
        if (mac.toString("hex") !== json.crypto.mac) {
            const error = new Exception_1.WalletError("Key derivation is failed (possibly wrong passphrase).");
            throw error.toString();
        }
        const decipher = node_1.crypto.createDecipheriv(json.crypto.cipher, derivedKey.slice(0, 16), Buffer.from(json.crypto.cipherparams.iv, "hex"));
        const seed = Buffer.concat([decipher.update(ciphertext), decipher.final()]);
        return new Wallet(seed);
    }
    /**
     * Get keystore object of an instance of a `Wallet` class.
     * @param {string} password - The new password for encryption.
     * @param {object=} opts - The custom options for encryption.
     * @return {object} A keystore object.
     */
    store(password, opts = {}) {
        const salt = opts.salt || node_1.crypto.randomBytes(32);
        const iv = opts.iv || node_1.crypto.randomBytes(16);
        let derivedKey;
        const kdf = opts.kdf || "scrypt";
        const kdfparams = {
            dklen: opts.dklen || 32,
            salt: salt.toString("hex"),
        };
        if (kdf === "scrypt") {
            kdfparams.n = opts.n || 16384;
            kdfparams.r = opts.r || 8;
            kdfparams.p = opts.p || 1;
            derivedKey = scryptsy_1.default(Buffer.from(password), salt, kdfparams.n, kdfparams.r, kdfparams.p, kdfparams.dklen);
        }
        else if (kdf === "pbkdf2") {
            kdfparams.c = opts.c || 16384;
            kdfparams.prf = "hmac-sha256";
            derivedKey = node_1.crypto.pbkdf2Sync(Buffer.from(password), salt, kdfparams.c, kdfparams.dklen, "sha256");
        }
        else {
            const error = new Exception_1.WalletError("It's an unsupported kdf.");
            throw error.toString();
        }
        const cipher = node_1.crypto.createCipheriv(opts.cipher || "aes-128-ctr", derivedKey.slice(0, 16), iv);
        if (!cipher) {
            const error = new Exception_1.WalletError("It's an unsupported cipher.");
            throw error.toString();
        }
        const ciphertext = Buffer.concat([
            cipher.update(this.privKey),
            cipher.final(),
        ]);
        const mac = (0, js_sha3_1.keccak256)(Buffer.concat([
            derivedKey.slice(16, 32),
            Buffer.from(ciphertext, "hex"),
        ]));
        return {
            version: 3,
            id: (0, uuid_1.v4)({ random: opts.uuid || node_1.crypto.randomBytes(16) }),
            address: this.getAddress(),
            crypto: {
                ciphertext: ciphertext.toString("hex"),
                cipherparams: {
                    iv: iv.toString("hex"),
                },
                cipher: opts.cipher || "aes-128-ctr",
                kdf,
                kdfparams,
                mac: mac.toString("hex"),
            },
            coinType: "icx",
        };
    }
    /**
     * Generate signature string by signing transaction object.
     * @param {string} data - The serialized transaction object.
     * @return {string} The signature string.
     */
    sign(data) {
        const signature = (0, Util_1.sign)(data, this.privKey);
        return Buffer.from(signature).toString("base64");
    }
    /**
     * Get private key of wallet instance.
     * @return {string} The private key.
     */
    getPrivateKey() {
        return this.privKey.toString("hex");
    }
    /**
     * Get public key of wallet instance.
     * @return {string} The public key.
     */
    getPublicKey() {
        return Buffer.from(this.pubKey).toString("hex");
    }
    /**
     * Get EOA address of wallet instance.
     * @return {string} The EOA address.
     */
    getAddress() {
        return this.address;
    }
}
exports.default = Wallet;
Object.defineProperty(Wallet.prototype, "privKey", {
    get: function get() {
        if (!this._privKey) {
            const error = new Exception_1.WalletError("This is a public key only wallet.");
            throw error.toString();
        }
        return this._privKey;
    },
});
Object.defineProperty(Wallet.prototype, "pubKey", {
    get: function get() {
        return secp256k1.publicKeyCreate(this.privKey, false).slice(1);
    },
});
Object.defineProperty(Wallet.prototype, "address", {
    get: function get() {
        return (0, Hexadecimal_1.addHxPrefix)((0, js_sha3_1.sha3_256)(this.pubKey).slice(-40));
    },
});
