import AppIcx from "@ledgerhq/hw-app-icx";

export const BASE_PATH = `44'/4801368'/0'/0'`;
export const loadAddresses = async (
  suppressError,
  icx,
  setIsAddressLoading,
  addressPerPage,
  currPage,
  setIsLedger,
  setWallets,
  setWalletPaths
) => {
  let i = 0;
  let counter;
  const addresses = [];
  const paths = [];
  setIsAddressLoading(true);
  while (i < addressPerPage) {
    try {
      suppressError = true;
      counter = (currPage - 1) * addressPerPage + i;
      const path = `${BASE_PATH}/${counter}'`;

      let { address } = await icx.getAddress(path, false, true);

      address = address.toString();
      addresses.push(address);
      paths.push(path);
      console.log("Ledger is connected!!!");
      setIsLedger(true);
    } catch (error) {
      if (error?.statusCode === 28428) console.log("Failed to load addresses.");
      console.log(error);
    }
    i++;
  }

  if (addresses.length === 0) {
    // alert("Your ledger is busy, please re-connect it.");
  }

  setIsAddressLoading(false);
  setWallets(addresses);
  setWalletPaths(paths);
};

export const connectToLedger = (
  setLedgerConnecting,
  Transport,
  setTransport,
  AppIcx,
  setIcx,
  setIsLedgerConnected,
  setHasLedgerSupport,
  setIsAddressLoading,
  addressPerPage,
  currPage,
  setIsLedger,
  setWallets,
  setWalletPaths
) => {
  let suppressError = false;
  setLedgerConnecting(true);

  Transport.create()
    .then(async (transport) => {
      transport.setDebugMode(true);
      setTransport(transport);
      const icx = new AppIcx(transport);
      setIcx(icx);
      try {
        console.log("Connection successful! ");
        setIsLedgerConnected(true);
        setHasLedgerSupport(true);
        await icx.getAddress(`${BASE_PATH}/0'`, false, true);
        await loadAddresses(
          suppressError,
          icx,
          setIsAddressLoading,
          addressPerPage,
          currPage,
          setIsLedger,
          setWallets,
          setWalletPaths
        );
        setLedgerConnecting(false);
      } catch (error) {
        alert("Please re-connect your ledger to icon network.");
        console.log("Failed connecting to ledger!");
        setIsLedgerConnected(false);
        setLedgerConnecting(false);
        setHasLedgerSupport(false);
      }
    })
    .catch((error) => {
      if (error.name === "TransportOpenUserCancelled") {
        console.log("Cancelled!");
      } else {
        console.log(error);
        alert("Please re-connect your ledger to icon network!");
      }
      setLedgerConnecting(false);
    });
};

export const onNext = (setCurrPage) => {
  setCurrPage((prev) => prev + 1);
};

export const onPrevious = (setCurrPage) => {
  setCurrPage((prev) => prev - 1);
};

export const firstPage = (setCurrPage) => {
  setCurrPage(1);
};

// ---------------------------------___SIGNING SECTION___------------------------------
export const signWithLedger = async (transport, ledgerPath, txStrRep) => {
  try {
    const icxObj = new AppIcx(transport);
    const base64Signature = await signTransaction(txStrRep, ledgerPath, icxObj);
    return { signature: base64Signature, rawMsg: txStrRep };
  } catch (e) {
    console.log("ledger signing error", e);
    return {};
  }
};

const signTransaction = async (txStrRep, walletPath, icxObj) => {
  const { signedRawTxBase64 } = await icxObj.signTransaction(
    walletPath,
    txStrRep
  );
  return signedRawTxBase64;
};
