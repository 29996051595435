import { ABILITY } from "@constants/images";
import Image from "@misc/Image";
import ImageLoader from "@misc/ImageLoader";
import { Tooltip } from "react-tooltip";

const Logs = ({ formattedLogs }: any) => {
  const validateDangerousHTML = (
    tags: any,
    className?: string,
    id?: string
  ) => {
    const hasScriptOrLinkTags = /<(script|link)\b/i.test(tags);
    if (!hasScriptOrLinkTags) {
      return (
        <p
          className={`text-sm my-3 px-4 ${className}`}
          dangerouslySetInnerHTML={{
            __html: tags,
          }}
          id={id}
        />
      );
    }
  };

  return (
    <div className="relative">
      <div>
        {formattedLogs.map((log: any, index: number) => {
          return (
            <div className="fightScreen warLogsOnly animate__animated animate__slideInUp">
              <p className="px-3 text-xl my-3">{log?.eventName}</p>
              {/* <p>{log?.narrationType}</p> */}
              {/* {currentTurn && (
                <p className="text-xs text-white ">
                  Current Turn:{" "}
                  <span className="text-green text-bold">{currentTurn}</span>
                </p>
              )} */}

              {validateDangerousHTML(log?.contextLog)}

              <div className="flex px-3 mt-3  gap-2">
                <div className="flex gap-1 flex-wrap items-center justify-center">
                  {" "}
                  {log?.mains && Object.keys(log?.mains)?.length > 0 && (
                    <div className="image-container flex gap-1">
                      {log?.mains?.ids.map((id: number) => {
                        return (
                          <Image id={id.toString()} className="blue-image" />
                        );
                      })}
                    </div>
                  )}
                </div>
                {log?.targets?.ids &&
                  Object.keys(log?.targets?.ids)?.length > 0 && (
                    <p className="self-center versus-text">VS</p>
                  )}

                <div className="flex gap-1 flex-wrap items-center justify-center">
                  {log?.targets && Object.keys(log?.targets)?.length > 0 && (
                    <div className="image-container flex gap-1">
                      {log?.targets && (
                        <div className="image-container flex gap-1">
                          {log?.targets?.ids.map((id: number) => {
                            return (
                              <Image
                                id={id.toString()}
                                className="blue-image"
                              />
                            );
                          })}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              {log?.logCollection.map((logCollection: any, i: number) => (
                <>
                  {/* <div
                    className={`flex-center px-4 gap-4 my-2 animate__animated animate__slideInUp animate__delay-${i +
                      2}s`}
                  ></div> */}
                  {logCollection?.narrationType === "ABILITY_LOG" && (
                    <div className="px-4 mb-3 flex justify-center items-center sneakMoveActionType">
                      <div className="flex   gap-2">
                        <div className="flex gap-1 flex-wrap items-center justify-center">
                          {" "}
                          {logCollection?.main &&
                            Object.keys(logCollection?.main)?.length > 0 && (
                              <div className="image-container flex gap-1">
                                {logCollection?.main.id && (
                                  <Image
                                    id={logCollection?.main.id.toString()}
                                    className="blue-image"
                                  />
                                )}
                              </div>
                            )}
                        </div>

                        <p className="self-center versus-text">
                          <ImageLoader
                            width="50px"
                            alt="ability"
                            src={ABILITY}
                          />
                        </p>

                        <div className="flex gap-1 flex-wrap items-center justify-center">
                          {logCollection?.target &&
                            Object.keys(logCollection?.target)?.length > 0 && (
                              <div className="image-container flex gap-1">
                                {logCollection?.target && (
                                  <div className="image-container flex gap-1">
                                    {logCollection?.target?.id && (
                                      <Image
                                        id={logCollection?.target?.id.toString()}
                                        className="blue-image"
                                      />
                                    )}
                                  </div>
                                )}
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  )}
                  {logCollection?.extraDamage?.some(
                    (entry: any) => entry.factor === "CRITICAL_HIT"
                  ) && (
                      <div className="px-4 mb-3 flex justify-center items-center criticalHitType">
                        <div className="flex cursor-pointer   gap-2">
                          <>
                            {validateDangerousHTML(
                              logCollection?.narration,
                              "_",
                              `${logCollection?.narrationType}_CRITICAL_HIT_${logCollection?.actionType}`
                            )}

                            <Tooltip
                              style={{
                                zIndex: 999,
                                maxWidth: "300px",
                                opacity: 1,
                              }}
                              anchorSelect={`#${logCollection?.narrationType}_CRITICAL_HIT_${logCollection?.actionType}`}
                            >
                              {logCollection?.extraDamage?.map(
                                (info: any, index: number) => {
                                  return (
                                    <div key={`keys${index}`}>
                                      <div
                                        className={`${info.type === "RAISE"
                                          ? "text-green"
                                          : "text-red"
                                          } font-bold flex gap-2`}
                                      >
                                        <p>
                                          {info.type === "RAISE" ? "+" : ""}
                                          {info.amount}
                                        </p>
                                        <p>{info.factor.replace("_", " ")}</p>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </Tooltip>
                          </>
                        </div>
                      </div>
                    )}
                  {(logCollection?.narrationType === "EFFECT_TAKEN" ||
                    logCollection?.narrationType === "EFFECT_LOG") && (
                      <div className="px-4 mb-3 flex justify-center items-center effectTakenType">
                        <div className="flex">
                          <div className="flex  gap-1 items-center justify-center">
                            {/* {logCollection?.main?.id && (
                            <div className="image-container min-w-[58px] flex">
                              <Image
                                id={logCollection?.main?.id}
                                className="blue-image"
                              />
                            </div>
                          )} */}
                            {logCollection?.target?.id && (
                              <div className="image-container min-w-[58px] flex">
                                <Image
                                  id={logCollection?.target?.id}
                                  className="blue-image"
                                />
                              </div>
                            )}
                          </div>

                          {/* <p className="self-center versus-text">
                          <ImageLoader
                            width="50px"
                            alt="ability"
                            src={ABILITY}
                          />
                        </p> */}

                          <div className="flex gap-1 flex-wrap items-center justify-center">
                            {validateDangerousHTML(
                              logCollection?.narration,
                              "text-left"
                            )}
                          </div>
                        </div>
                      </div>
                    )}

                  {logCollection?.narration &&
                    logCollection?.narrationType !== "EFFECT_TAKEN" &&
                    logCollection?.narrationType !== "EFFECT_LOG" &&
                    !logCollection?.extraDamage?.some(
                      (entry: any) => entry.factor === "CRITICAL_HIT"
                    ) &&
                    validateDangerousHTML(logCollection?.narration)}
                </>
              ))}
            </div>
          );
        })}
      </div>
      {/* <div
      key={`key_${Math.random()}`}
      className="abilityTriggered px-4 py-2 justify-center flex flex-col items-start animate__animated  animate__bounceIn"
    >
      <h1>Sneak Move:</h1>
      It will be triggered soon!
    </div> */}
    </div>
  );
};
export default Logs;
