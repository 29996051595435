import { NOTE } from "@constants/images";
import { useState } from "react";
import { MdOutlineArrowBackIos } from "react-icons/md";
import SideDrawer from "@components/sideDrawer/sideDrawer.drawer";
import { useNavigate } from "react-router-dom";

const Header = ({ logs }: any) => {
  const [open, setOpen] = useState({ status: "", state: false });
  const navigate = useNavigate();
  return (
    <div className="headingSection">
      <div className="headingSelectCharacter flex w-full px-3 justify-between mt-2">
        <div
          className="flex-center gap-5"
          onClick={() => {
            navigate(-1);
          }}
        >
          <MdOutlineArrowBackIos className="cursor-pointer text-white text-3xl" />
          <div className="opHeadingText">War Overview</div>
        </div>
        <img
          src={NOTE}
          alt="Note"
          className="text-white text-4xl cursor-pointer"
          onClick={() => {
            setOpen({ status: "clicked", state: true });
          }}
        />
      </div>
      {open.status !== "" && (
        <SideDrawer logs={logs} open={open} setOpen={setOpen} />
      )}
    </div>
  );
};

export default Header;
