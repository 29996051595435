import { useLeaderboardContext } from "../context/LeaderboardContext";
import { MdRestartAlt } from "react-icons/md";
import CrownImg from "@assets/images/leaderboard/crown.svg";
import { BsFillHexagonFill } from "react-icons/bs";
import moment from "moment";
import { useEffect, useState } from "react";
import ResetTimer from "./ResetTimer";
import { getCurrentLeaderboardSeason } from "@utils/getCurrentSeason";
import { useLeaderboardApi } from "../hooks/useLeaderboardApi";

export default function StatsSelector() {
    const {
        statsFilter,
        setSelectedStats,
        intervalFilter,
        setShowLastWinners,
    } = useLeaderboardContext();

    const [resetTimestamp, setResetTimestamp] = useState(0);
    const [seasonTimestamp, setSeasonTimestamp] = useState(Date.now());
    const { data: leaderboardApiData, error: leaderboardApiError } = useLeaderboardApi(
        "wins",
        intervalFilter,
        seasonTimestamp,
        5
    );

    useEffect(() => {
        if (intervalFilter === "all" || intervalFilter === "seasonal") return;
        const interval = intervalFilter.slice(0, -2) as "week" | "month";
        const now = moment.utc();
        now.startOf(interval);
        now.add(1, interval);
        // now points to sunday next week OR 1st day of next month
        interval === "week" && now.add(1, "day");
        now.startOf("day");
        // now points to monday 00:00:00 OR next month's first day 00:00:00
        setResetTimestamp(now.valueOf());
    }, [intervalFilter]);

    return (
        <div className="stats-selector">
            <div className="stats-tabs flex justify-between">
                <div>
                    <span
                        className={statsFilter === "wins" ? "selected-filter" : ""}
                        onClick={() => setSelectedStats("wins")}
                    >
                        Top Wins
                    </span>
                    <span
                        className={statsFilter === "gamesPlayed" ? "selected-filter" : ""}
                        onClick={() => setSelectedStats("gamesPlayed")}
                    >
                        Top Participants
                    </span>
                    <span
                        className={statsFilter === "kills" ? "selected-filter" : ""}
                        onClick={() => setSelectedStats("kills")}
                    >
                        Top Kills
                    </span>
                    <span
                        className={statsFilter === "damageDealt" ? "selected-filter" : ""}
                        onClick={() => setSelectedStats("damageDealt")}
                    >
                        Top Damage
                    </span>
                    <span
                        className={statsFilter === "heal" ? "selected-filter" : ""}
                        onClick={() => setSelectedStats("heal")}
                    >
                        Top Healer
                    </span>
                </div>
            </div>

            <div className="stats-sec-row">
                {intervalFilter === "all" ? (
                    <div style={{ minWidth: "100%" }}></div>
                ) : (
                    <>
                        <div>
                            <span className="left-section">
                                <span>
                                    <MdRestartAlt
                                        fontSize={25}
                                        fontWeight={"bolder"}
                                        style={{
                                            color: "rgb(112, 135, 143)",
                                            margin: "0",
                                            top: "50%",
                                            left: "50%",
                                            position: "absolute",
                                            transform: "translate(-50%, -50%)",
                                        }}
                                    />
                                    <BsFillHexagonFill
                                        fontSize={40}
                                        style={{
                                            color: "rgba(38, 52, 61, 0.4)",
                                            left: "0",
                                            top: "0",
                                            position: "absolute",
                                        }}
                                    />
                                </span>
                                <span style={{ fontSize: "14px" }}>
                                    <span style={{ color: "#485C65" }}>
                                        {intervalFilter === "seasonal"
                                            ? "Season Ends"
                                            : "Resets in :"}{" "}
                                    </span>
                                    <span style={{ color: "#70878F" }}>
                                        {intervalFilter === "seasonal" ? (
                                            <>
                                                {
                                                    leaderboardApiData?.seasonalInfo
                                                        ?.currentSeasonEndsAfterMonth
                                                }{" "}
                                                months later
                                                {/* This month */}
                                                {/* {leaderboardApiData &&
                                                leaderboardApiData?.seasonalInfo
                                                    ?.currentSeasonEndsAfterMonth
                                                    ? leaderboardApiData?.seasonalInfo
                                                          ?.currentSeasonEndsAfterMonth === 1 &&
                                                      "This month"
                                                    : `${leaderboardApiData?.seasonalInfo?.currentSeasonEndsAfterMonth} months later`} */}
                                            </>
                                        ) : (
                                            <ResetTimer timestamp={resetTimestamp} />
                                        )}
                                    </span>
                                </span>
                            </span>
                        </div>
                        {/* <div className="right-section">
              <button onClick={() => setShowLastWinners(true)}>
                <img
                  height="15px"
                  width="15px"
                  style={{ margin: "0px 5px" }}
                  src={CrownImg}
                  alt="Winner"
                />
                <span>
                  See last {intervalFilter == "weekly" ? "week" : "month"}
                  's winners
                </span>
              </button>
            </div> */}
                    </>
                )}

                <div
                    hidden={
                        intervalFilter === "all" ||
                        leaderboardApiData?.seasonalInfo?.seasonComplete === 0
                    }
                    className="right-section"
                >
                    {leaderboardApiData && (
                        <button className="min-w-[200px]" onClick={() => setShowLastWinners(true)}>
                            <img
                                height="15px"
                                width="15px"
                                style={{ margin: "0px 5px" }}
                                src={CrownImg}
                                alt="Winner"
                            />
                            <span>
                                See last{" "}
                                {intervalFilter == "weekly"
                                    ? "week"
                                    : intervalFilter === "monthly"
                                        ? "months"
                                        : intervalFilter === "seasonal" && "season"}
                                's top 3
                            </span>
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
}
