export const RentalEmptyStructure = {
    borrower: "hx0000000000000000000000000000000000000000",
    claimableAmount: "0",
    deListed: "0",
    expirationTimestamp: "0",
    id: "0x1",
    listedTimestamp: "0",
    nftId: "0",
    owner: "hx0000000000000000000000000000000000000000",
    period: "0",
    rentFee: "0",
    rentType: "0",
    startTimestamp: "0",
    status: "2",
    listedRentFee: "0",
};
