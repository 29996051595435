import { useEffect, useState, useMemo } from "react";

import Countdown from "react-countdown-simple";
import { CROWN, ICX } from "@constants/images";

import { Tooltip } from "react-tooltip";

import ImageLoader from "@misc/ImageLoader";
import { useDetailGlobalContext } from "@context/detailProvider";
import { AddSpecificGameTag, checkForGeneralTag } from "@misc/specificTagGames";
import { GangstaBetTypeCount, WeaponTypeCount } from "@misc/warSpecific";

const tsConvertOptions: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "short",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
};
const UpComingWarLists = ({ war, lootboxDetails, id }: any) => {
    const [level, setLevel] = useState({
        min: 0,
        max: 0,
    });
    useEffect(() => {
        const levels = war.allowedLevels
            .map((hexString: string) => Number(hexString))
            .sort((a: number, b: number) => b - a);
        setLevel({
            max: levels[0],
            min: levels[levels?.length ? levels.length - 1 : 0],
        });
    }, []);
    const { warIdSelectedFromMap } = useDetailGlobalContext();

    const randomNumber = useMemo(() => Math.floor(Math.random() * 10000), []);
    const warStartTime = ({ days, hours, minutes, seconds }: any) => {
        return (
            <div>
                {days > 0 && `${days} :`}
                {hours < 10 ? "0" + hours : hours} :{minutes < 10 ? "0" + minutes : minutes} :
                {seconds < 10 ? "0" + seconds : seconds}
            </div>
        );
    };

    const warStartTimeElapsed = ({ days, hours, minutes, seconds }: any) => {
        return (
            <div>
                {days > 0 && `${days} :`}
                {hours < 10 ? "0" + hours : hours} :{minutes < 10 ? "0" + minutes : minutes} :
                {seconds < 10 ? "0" + seconds : seconds}
            </div>
        );
    };

    return (
        <tr
            className={`war-item flex items-center gap-3 justify-between ${warIdSelectedFromMap ===
                Number(id) && "map-clicked-bg"}`}
        >
            <td className="start-time-block-list flex flex-col gap-1">
                <div className="game-start-time">
                    <div className="text-xl font-bold text-white">
                        {new Date(Number(war?.startTime) / 1000).toLocaleString(
                            "en-US",
                            tsConvertOptions
                        )}
                    </div>
                </div>
                <div className="flex items-center ">
                    <div className="game-start-time-counter flex gap-2 items-center">
                        <p className="slef-center text-sm text-white">Starts In </p>
                        {war.scheduleTime && (
                            <Countdown
                                targetDate={new Date(
                                    new Date(war.startTime / 1000).setHours(
                                        new Date(war.startTime / 1000).getHours()
                                    )
                                ).toISOString()}
                                disableTypes={true}
                                renderer={warStartTimeElapsed}
                            />
                        )}
                    </div>
                </div>
            </td>
            <td className="entry-fee-block-list flex flex-col items-center gap-2">
                {Number(war.fee) > 0 ? (
                    <div className="flex gap-1 items-center">
                        <p className="font-extrabold	"> {Number(war.fee)}</p>

                        {war?.feeType === "CROWN" ? (
                            <img width={"20px"} src={CROWN} alt="crown" className="crown-logo" />
                        ) : (
                            war?.feeType === "ICX" && (
                                <img width={"20px"} src={ICX} alt="icx" className="crown-logo" />
                            )
                        )}
                    </div>
                ) : (
                    <span className="text-green free-entry ">Free Entry</span>
                )}
                <div className="text-white text-lg text-center">
                    LVL{" "}
                    <span className="font-bold">
                        {war.allowedLevels.length > 0 ? `${level.min}-${level.max}` : "1-10"}
                    </span>
                </div>
            </td>
            <td className="rewards-block-list items-center gap-2 flex flex-col flex-wrap">
                <div className="reward cursor-pointer" id={`upcomingWar__${randomNumber}`}>
                    <ImageLoader width="80px" height="80px" src={lootboxDetails?.imageUrl} alt="" />
                </div>
                <Tooltip
                    style={{ zIndex: 999, maxWidth: "250px" }}
                    anchorSelect={`#upcomingWar__${randomNumber}`}
                    delayShow={500}
                >
                    <h4>{lootboxDetails?.name}:</h4>
                    <small> {lootboxDetails?.description}</small>
                </Tooltip>
                <h2 className="text-white">{lootboxDetails?.name}</h2>
            </td>
            <td className="duration-block-list">{war.period} mins</td>
            <td className="objective-block-list">
                <div className="obj-name">{war.title}</div>
                <div className="obj-description ">
                    <p id={`${war.zone}_description`}> {war.description}</p>
                    {/* <div className="flex gap-1 flex-wrap mt-1">
                        {war.allowedWeapons.length !== WeaponTypeCount() && (
                            <AddSpecificGameTag
                                specificType={war.allowedWeapons}
                                tag="WeaponsType"
                                customId={war.scheduleTime}
                            />
                        )}
                        {war.allowedTypes.length !== GangstaBetTypeCount() && (
                            <AddSpecificGameTag
                                specificType={war.allowedTypes}
                                tag="GangstaBetType"
                                customId={war.scheduleTime}
                            />
                        )}
                        {checkForGeneralTag(war) && (
                            <AddSpecificGameTag
                                customId={war.scheduleTime}
                                specificType={["1"]}
                                tag="GeneralType"
                            />
                        )}
                    </div> */}
                </div>
            </td>
            <td className="w-[100px] text-sm">{war.zone}</td>

            <td className="zone-type-block-list">
                {/* {war.zone} */}
                <div className="flex flex-col gap-1 flex-wrap mt-1">
                    <div className="flex flex-col gap-1">
                        <p className="text-[14px]"> Weapon</p>
                        {war.allowedWeapons && war.allowedWeapons.length !== 0 && war.allowedWeapons !== WeaponTypeCount() ? (
                            <AddSpecificGameTag
                                specificType={war.allowedWeapons}
                                tag="WeaponsType"
                                customId={war.scheduleTime}
                            />
                        ) : (
                            <AddSpecificGameTag
                                customId={war.scheduleTime}
                                specificType={["1"]}
                                tag="GeneralType"
                            />
                        )}
                    </div>
                    <div className="flex flex-col gap-1">
                        <p className="text-[14px]">GangstaBet Type</p>
                        {war.allowedTypes && war.allowedTypes.length !== GangstaBetTypeCount() ? (
                            <AddSpecificGameTag
                                specificType={war.allowedTypes}
                                tag="GangstaBetType"
                                customId={war.scheduleTime}
                            />
                        ) : (
                            <AddSpecificGameTag
                                customId={war.scheduleTime}
                                specificType={["1"]}
                                tag="GeneralType"
                            />
                        )}
                    </div>
                    {/* {checkForGeneralTag(war) && (
                        <AddSpecificGameTag
                            customId={war.id}
                            specificType={["1"]}
                            tag="GeneralType"
                        />
                    )} */}
                </div>
            </td>
            <td className="war-join-btn-list">
                <div className="flex flex-col items-center justify-center">
                    <div className="text-white availableToJoin flex flex-col items-center">
                        <p className="text-gray text-sm ">Available to join in</p>
                        {war.startTime && (
                            <Countdown
                                targetDate={new Date(
                                    new Date(war.scheduleTime / 1000).setHours(
                                        new Date(war.scheduleTime / 1000).getHours()
                                    )
                                ).toISOString()}
                                disableTypes={true}
                                renderer={warStartTime}
                            />
                        )}
                    </div>
                </div>
            </td>
        </tr>
    );
};

export default UpComingWarLists;
