import { useNavigate } from "react-router-dom";
import GANGSTABET_ROUTES from "./routes.gangstabet";
import { INFT } from "./interfaces/gangstabet.interface";

export default function IntervalSelector({ currentNFT }: { currentNFT: INFT }) {
    const navigate = useNavigate();

    return (
        <div className="interval-selector">
            <span
                className={`${currentNFT === "Collection" && "selected-interval"} !w-auto px-6 pt-4 pb-2`}
                onClick={() => navigate(GANGSTABET_ROUTES.BASE + GANGSTABET_ROUTES.COLLECTION)}
            >
                Your NFTs
            </span>
            <span
                className={`${currentNFT === "Rent" && "selected-interval"} !w-auto px-6 pt-4 pb-2`}
                onClick={() => navigate(GANGSTABET_ROUTES.BASE + GANGSTABET_ROUTES.RENTAL)}
            >
                Rented NFTs
            </span>
            {/* <span
                className={`${currentNFT === "Marketplace" && "selected-interval"} !w-auto px-6 pt-4 pb-2`}
                onClick={() => navigate(GANGSTABET_ROUTES.BASE + GANGSTABET_ROUTES.MARKETPLACE)}
            >
                Rental Marketplace
            </span> */}
        </div>
    );
}
