import { useEffect, useState } from "react";

interface imageLoader {
    src: string;
    className?: string;
    alt: string;
    style?: any;
    onClick?: any;
    skeletonStyle?: any;
    width?: string;
    height?: string;
    skeletonClassName?: string;
    id?: string;
}

const ImageLoader = ({
    src,
    className,
    alt,
    style,
    onClick,
    skeletonStyle,
    width = "100%",
    height,
    skeletonClassName,
    id,
}: imageLoader) => {
    const [isImageLoaded, setImageLoader] = useState(true);
    useEffect(() => {
        if (src) {
            const image = new Image();
            image.src = src;
            image.onload = () => {
                setImageLoader(false);
            };
        }
    }, [src]);
    if (isImageLoaded)
        return (
            <div
                style={skeletonStyle}
                className={`skeleton skeleton-default-width ${skeletonClassName} `}
            />
        );
    return (
        <img
            onClick={onClick}
            width={width}
            height={height}
            src={src}
            style={style}
            className={className}
            alt={alt}
            id={id}
            loading="lazy"
        />
    );
};

export default ImageLoader;
