import { Tooltip } from "react-tooltip";
const TagTooltip = ({ details, place = "top", tooltipId }: any) => {
    return (
        <>
            <Tooltip
                place={place}
                delayShow={300}
                className={`tooltipTag tooltipPlace_${place}`}
                anchorSelect={`#${tooltipId}`}
            >
                {details}
            </Tooltip>
        </>
    );
};
export default TagTooltip;
