/*eslint-disable*/
import { useState } from "react";
import Modal from "../modal.component";
import PrimaryButton from "@components/buttons/primary.button";
import { useDispatch } from "react-redux";
import { setIsLedger } from "@redux/features/walletSlice";

const LedgerAddressModal = ({
  isVisible,
  onClose,
  isAddressLoading,
  onNext,
  onPrevious,
  firstPage,
  onConfirm,
  currentPage,
  addressList,
  setIsVisible,
  walletPaths,
  setConnectWalletPopup,
}) => {
  const [active, setActive] = useState("");
  const [activePath, setActivePath] = useState("");

  const dispatch = useDispatch();

  const handleSelect = (item, index) => {
    setActive(item);
    setActivePath(walletPaths[index]);
  };

  const onConfirmation = () => {
    onClose();
    onConfirm(active, activePath);
    setActive("");
    setActivePath("");
    dispatch(setIsLedger(true));
    setConnectWalletPopup(false);
  };

  const onCloseHandler = () => {
    onClose();
    setActive("");
    setActivePath("");
  };

  return (
    <Modal
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      modalTitle="Select Address"
    >
      <div className="ledgerWrapper">
        <div className="ledger-heading  px-4 mb-1">
          <p>Choose a wallet from your Ledger:</p>
        </div>
        <div className="body ledger-address-body">
          <ul className="ledger-address-list">
            {!isAddressLoading ? (
              addressList.map((item, index) => (
                <li
                  className={`address-container ${
                    active === item ? "active" : ""
                  }`}
                  key={index}
                  tabIndex={0}
                  onClick={() => handleSelect(item, index)}
                >
                  <p className="col-10  text-truncate mb-0">
                    <span className="address-text">{item}</span>
                  </p>
                </li>
              ))
            ) : (
              <p className="address-text">Fetching addresses...</p>
            )}
          </ul>
        </div>
        <div>
          <div className="include-flex ledger-button-group d-flex">
            <PrimaryButton onClick={onConfirmation} buttonName="Confirm" />

            <PrimaryButton onClick={onCloseHandler} buttonName="Cancel" />
          </div>
          <nav
            aria-label="ledger-page-navigation"
            className="ledger-page-navigation"
          >
            <ul className="pagination">
              <li className="page-item">
                <button
                  className="page-link"
                  aria-label="Previous"
                  onClick={onPrevious}
                  disabled={currentPage == 1}
                  style={{ width: "40px", height: "30px" }}
                >
                  <span aria-hidden="true">&laquo;</span>
                </button>
              </li>
              <li className="page-item">
                <button
                  className="page-link"
                  onClick={firstPage}
                  style={{ width: "40px", height: "30px" }}
                >
                  {currentPage}
                </button>
              </li>

              <li className="page-item">
                <button
                  className="page-link"
                  aria-label="Next"
                  onClick={onNext}
                  style={{ width: "40px", height: "30px" }}
                >
                  <span aria-hidden="true">&raquo;</span>
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </Modal>
  );
};

export default LedgerAddressModal;
