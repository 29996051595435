import { REDIRECT_ICON } from "@constants/images";
import { HYPERLINKS } from "@misc/links";

interface IAddress {
    address: string;
}

const AddressWrapper = ({ address }: IAddress) => {
    return (
        <a
            href={`${HYPERLINKS.GANGSTABET_WALLET}/${address}`}
            target="_blank"
            className="cursor_pointer"
            rel="noreferrer"
        >
            <span className="inline-flex gap-3 rental-text !font-semibold !text-[rgba(32,148,225,1)]">
                {address.slice(0, 4)}...{address.slice(-4)}
                <img src={REDIRECT_ICON} alt="redirect" />
            </span>
        </a>
    )
}

export default AddressWrapper;