import { IGetUserAbility } from "@interfaces/ability.interface";
import { usersAvailableAbilityBalances } from "@services/icon.service";
import { IAbilityCount } from "@interfaces/ability.interface";
import { getAbilitiesInformation } from "./getAbilitiesInformation";

interface IGetUsersAvailableAbilityBalances {
  setAbilityNumberCount: React.Dispatch<React.SetStateAction<IAbilityCount>>;
  setUserAbilityDetails: React.Dispatch<
    React.SetStateAction<Array<IGetUserAbility>>
  >;
  walletAddress: string;
  setUserAbilityDetailsWithSeperation: React.Dispatch<
    React.SetStateAction<{
      active: Array<IGetUserAbility>;
      passive: Array<IGetUserAbility>;
    }>
  >;
}

export async function getUsersAvailableAbilityBalances({
  setAbilityNumberCount,
  setUserAbilityDetails,
  walletAddress,
  setUserAbilityDetailsWithSeperation,
}: IGetUsersAvailableAbilityBalances) {
  const userAvailableAbility: Array<IGetUserAbility> = [];
  const userAbilitySep: {
    active: Array<IGetUserAbility>;
    passive: Array<IGetUserAbility>;
  } = {
    active: [],
    passive: [],
  };
  const userAbility = await usersAvailableAbilityBalances(walletAddress);

  for await (let ability of userAbility.availableAbility) {
    const info = await getAbilitiesInformation(ability.id);
    const id = Number(ability.id);
    if (Object.keys(info).length > 0) {
      setAbilityNumberCount((prev) => ({
        ...prev,
        [Number(id).toString()]: Number(ability.count),
      }));
      info["count"] = Number(ability.count);
      if (info.type === "Active") {
        userAbilitySep.active.push(info as IGetUserAbility);
      } else if (info.type === "Passive") {
        userAbilitySep.passive.push(info as IGetUserAbility);
      }
      // console.log("abilities", info);
      userAvailableAbility.push(info as IGetUserAbility);
    }
  }
  setUserAbilityDetailsWithSeperation(userAbilitySep);
  setUserAbilityDetails(userAvailableAbility);
}
