import { formatMilliseconds } from "@misc/formatTimeDuration";
const TimeAndRound = ({ rounds, duration }: any) => {
  return (
    <div className="pgDurationRound animate__animated animate__fadeInUpBig flex items-center justify-evenly	">
      <div className="pgDuration">
        <h1>War duration</h1>
        <p className="text-center">
          {duration && formatMilliseconds(duration)}
        </p>
      </div>
      <hr className="pgDuraRoundSep" />
      <div className="pgRounds">
        <h1>Total Rounds</h1>
        <p className="text-center">{rounds ?? ""}</p>
      </div>
      <div className="pgDuraRoundLeftBox" />
      <div className="pgDuraRoundRightBox" />
    </div>
  );
};
export default TimeAndRound;
