import {
    getLobbyLockdownPeriod,
    getSimulationDetail,
    hasWalletParticipated,
} from "@services/icon.service";
import { useState, useEffect } from "react";
import Spectator from "./spectator";
import Countdown from "react-countdown-simple";
import { CROWN, ICX, WAR_HAPPENING } from "@constants/images";
import { AiFillEye } from "react-icons/ai";
import { FaUserAlt } from "react-icons/fa";
import PrimaryButton from "@components/buttons/primary.button";
import { useSelector } from "react-redux";
import { useDetailGlobalContext } from "@context/detailProvider";
import { Tooltip } from "react-tooltip";
import Typewriter from "typewriter-effect";
import ImageLoader from "@misc/ImageLoader";
import { GangstaBetTypeCount, WeaponTypeCount } from "@misc/warSpecific";
import Tag from "../tag/Tag.component";
import { AddSpecificGameTag, checkForGeneralTag } from "@misc/specificTagGames";

const tsConvertOptions: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "short",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
};
const GangwarList = ({ war, lootboxDetails, warActionBtnHandler, viewLobbyHandler }: any) => {
    const [level, setLevel] = useState({
        min: 0,
        max: 0,
    });
    useEffect(() => {
        const levels = war.allowedLevels
            .map((hexString: string) => Number(hexString))
            .sort((a: number, b: number) => b - a);
        setLevel({
            max: levels[0],
            min: levels[levels?.length ? levels.length - 1 : 0],
        });
    }, []);
    const { warIdSelectedFromMap, reFetchChangeData } = useDetailGlobalContext();
    const { isWalletConnected, walletAddress } = useSelector((state: any) => state.wallet);
    const WAR_MAX_PLAYERS = Number(war?.maxPlayer);

    const warId = `game_${war.id}_war`;
    const [gameStatus, setGameStatus] = useState(
        new Date() >= new Date(war.startTime / 1000) && new Date() <= new Date(war.endTime / 1000)
    );

    const [lobbyLockPeriod, setLobbyLockPeriod] = useState("0");
    async function lobbyLockDownPeriod() {
        const result = await getLobbyLockdownPeriod();
        setLobbyLockPeriod(result);
    }

    useEffect(() => {
        lobbyLockDownPeriod();
    }, []);

    const [numberOfParticipants, setNumberOfParticipants] = useState<number>(0);
    const checkGameStatus = async () => {
        const result = await getSimulationDetail(war.id);
        setGameStatus(result.teamA.length > 0 && result.teamB.length > 0);
        setNumberOfParticipants(result.participants.length);
    };

    const [isWalletParticipated, setWalletParticipated] = useState<boolean>(false);

    async function participants() {
        if (isWalletConnected) {
            const checkStatus = await hasWalletParticipated(war.id, walletAddress);
            setWalletParticipated(!!Number(checkStatus));
        } else {
            setWalletParticipated(false);
        }
    }
    useEffect(() => {
        participants();
        const interval = setInterval(participants, 5000);
        return () => clearInterval(interval);
    }, [isWalletConnected, reFetchChangeData]);

    useEffect(() => {
        const interval = setInterval(checkGameStatus, 1000);

        return () => clearInterval(interval);
    }, []);

    const [lockJoiningGame, setLockJoiningGame] = useState<boolean>(false);
    const warStartsIn = ({ minutes, seconds }: any) => {
        return (
            <div>
                {minutes < 10 ? "0" + minutes : minutes} :{seconds < 10 ? "0" + seconds : seconds}
            </div>
        );
    };
    const warStartTime = ({ days, hours, minutes, seconds }: any) => {
        if (lobbyLockPeriod) {
            const lockTime = Number(war?.startTime) / 1000 - Number(lobbyLockPeriod) / 1000;
            if (new Date() > new Date(lockTime) && !lockJoiningGame) setLockJoiningGame(true);
        }
        if (days === 0 && hours === 0 && minutes === 0 && seconds === 0) {
            return <span className="text-red whitespace-nowrap">Forming team</span>;
        }
        // if (hours === 0 && seconds === 0 && minutes === 4) {
        //   toasterService.set({
        //     title: "WARNING",
        //     message: `Lobby will be locked after 1 minutes`,
        //     type: "warn",
        //     toastSize: "toastLarge",
        //   });
        // }
        return (
            <div>
                {days > 0 && `${days} :`}
                {hours < 10 ? "0" + hours : hours} :{minutes < 10 ? "0" + minutes : minutes} :
                {seconds < 10 ? "0" + seconds : seconds}
            </div>
        );
    };

    const warStartTimeElapsed = ({ days, hours, minutes, seconds }: any) => {
        return (
            <div>
                {hours < 10 ? "0" + hours : hours} :{minutes < 10 ? "0" + minutes : minutes} :
                {seconds < 10 ? "0" + seconds : seconds}
            </div>
        );
    };

    return (
        <tr
            className={`war-item flex items-center gap-3 justify-between
        ${gameStatus && isWalletParticipated
                    ? "now-playing-bg"
                    : gameStatus
                        ? "watch-live-bg"
                        : warIdSelectedFromMap === Number(war.id) && "map-clicked-bg"
                }`}
        >
            <td className="start-time-block-list flex flex-col gap-1">
                <div className="game-start-time">
                    {gameStatus && isWalletParticipated ? (
                        <div className="flex gap-2 items-center">
                            <div className="circle"></div>
                            <p className="text-xl font-bold text-white">Now Playing</p>
                        </div>
                    ) : gameStatus && !isWalletParticipated ? (
                        <div className="flex gap-2 items-center">
                            <div className="circle"></div>
                            <p className="text-xl font-bold text-white">Watch Live</p>
                        </div>
                    ) : (
                        <div className="text-xl font-bold text-white">
                            {new Date(
                                Number(war?.startTime) / 1000 - Number(lobbyLockPeriod) / 1000
                            ).toLocaleString("en-US", tsConvertOptions)}
                        </div>
                    )}
                </div>
                <div className="flex items-center whitespace-nowrap ">
                    {!gameStatus ? (
                        <div className="flex playerCount gap-0.5 ">
                            <FaUserAlt
                                color={numberOfParticipants === WAR_MAX_PLAYERS ? "red" : ""}
                            />
                            <span
                                className={`${numberOfParticipants === WAR_MAX_PLAYERS ? "text-red" : ""
                                    }`}
                            >
                                {numberOfParticipants ?? "-"}/{WAR_MAX_PLAYERS}
                            </span>
                        </div>
                    ) : gameStatus ? (
                        <div className="flex items-center gap-15 playerCount gap-2 ">
                            {/* <FaUserAlt /> */}
                            <ImageLoader
                                width="10px"
                                src={WAR_HAPPENING}
                                alt="war happeing"
                                className="mt-[-2px]"
                            />
                            <span className="text-xs whitespace-nowrap">
                                {numberOfParticipants / 2} VS {numberOfParticipants / 2}
                            </span>
                        </div>
                    ) : (
                        "Forming team"
                    )}
                    {!gameStatus && <div className="separator"></div>}

                    <div className="game-start-time-counter flex gap-2 items-center">
                        <p className="slef-center text-sm text-white">
                            {gameStatus ? "" : !lockJoiningGame ? "Starts In" : ""}
                        </p>
                        {gameStatus ? (
                            // <Countdown
                            //   targetDate={new Date(
                            //     new Date(Number(war.endTime) / 1000).setHours(
                            //       new Date(Number(war.endTime) / 1000).getHours()
                            //     )
                            //   ).toISOString()}
                            //   disableTypes={true}
                            //   renderer={warStartTimeElapsed}
                            // />
                            ""
                        ) : Number(lobbyLockPeriod) > 0 ? (
                            <Countdown
                                targetDate={new Date(
                                    new Date(
                                        Number(war?.startTime) / 1000 -
                                        Number(lobbyLockPeriod) / 1000
                                    ).setHours(
                                        new Date(
                                            Number(war?.startTime) / 1000 -
                                            Number(lobbyLockPeriod) / 1000
                                        ).getHours()
                                    )
                                ).toISOString()}
                                disableTypes={true}
                                renderer={warStartTime}
                            />
                        ) : (
                            <small className="text-sm min-w-[30px]">
                                <Typewriter
                                    options={{
                                        strings: ["........"],
                                        autoStart: true,
                                        loop: true,
                                        cursor: ".",
                                    }}
                                />
                            </small>
                        )}
                    </div>
                    {gameStatus && <div className="separator"></div>}
                    {gameStatus && (
                        <div className="flex playerCount items-center gap-1 ">
                            <AiFillEye />
                            <span>
                                <b>
                                    <Spectator id={Number(war.id)} />{" "}
                                </b>
                            </span>
                        </div>
                    )}
                </div>
            </td>
            <td className="entry-fee-block-list flex flex-col justify-center items-center gap-2">
                <>
                    {isWalletParticipated ? (
                        <div className="flex flex-col items-center">
                            <span className="joined-text">Joined</span>
                            <span className="free-entry">
                                {war?.paymentMethod === "CROWN"
                                    ? "CROWN "
                                    : war?.paymentMethod === "ICX"
                                        ? "ICX "
                                        : war?.paymentMethod === "FREE" && "Free "}
                                entry
                            </span>
                        </div>
                    ) : gameStatus ? (
                        "-"
                    ) : Number(war.fee) > 0 ? (
                        <div className="flex gap-1 items-center">
                            <p className="font-extrabold	"> {Number(war.fee) / 10 ** 18}</p>

                            {war?.paymentMethod === "CROWN" ? (
                                <img
                                    width={"20px"}
                                    src={CROWN}
                                    alt="crown"
                                    className="crown-logo"
                                />
                            ) : (
                                war?.paymentMethod === "ICX" && (
                                    <img
                                        width={"20px"}
                                        src={ICX}
                                        alt="icx"
                                        className="crown-logo"
                                    />
                                )
                            )}
                        </div>
                    ) : (
                        <span className="text-green free-entry ">Free Entry</span>
                    )}
                </>
                <div className="text-white  text-lg text-center">
                    LVL{" "}
                    <span className="font-bold">
                        {war.allowedLevels.length > 0 ? `${level.min}-${level.max}` : "1-10"}
                    </span>
                </div>
            </td>
            <td className="rewards-block-list flex flex-col  gap-2 flex items-center  flex-wrap">
                <div className="reward cursor-pointer" id={warId}>
                    <ImageLoader width="80px" height="80px" src={lootboxDetails?.imageUrl} alt="" />
                    {/* <ColoredCrateCard
            image={lootboxDetails?.imageUrl}
            width="80px"
            height="80px"
            maxWidth="80px"
            crateWidth="100px"
            crateHeight="100px"
            bgColor={
              colorDetails[lootboxDetails?.id?.toString()] as ColorDetailsTypes
            }
          /> */}
                </div>
                <Tooltip
                    style={{ zIndex: 999, maxWidth: "250px" }}
                    anchorSelect={`#${warId}`}
                    delayShow={500}
                >
                    <h4>{lootboxDetails?.name}:</h4>
                    <small> {lootboxDetails?.description}</small>
                </Tooltip>
                <h2 className="text-white ">{lootboxDetails?.name}</h2>
            </td>
            <td className="duration-block-list">{war.period / 60000000} mins</td>
            <td className="objective-block-list">
                <div className="obj-name">{war.title}</div>
                <div className="obj-description ">
                    <p id={`${warId}_description`}> {war.description}</p>
                    {/* <p className="text-bold text-green-600">Eligibility Criteria</p> */}
                    {/* <div className="flex flex-col gap-1 flex-wrap mt-1">
                        {war.allowedWeapons.length !== WeaponTypeCount() && (
                            <div className="flex gap-1">
                                Weapon:
                                <AddSpecificGameTag
                                    specificType={war.allowedWeapons}
                                    tag="WeaponsType"
                                    customId={war.id}
                                />
                            </div>
                        )}
                        {war.allowedTypes.length !== GangstaBetTypeCount() && (
                            <div className="flex gap-1">
                                GangstaBet Type:
                                <AddSpecificGameTag
                                    specificType={war.allowedTypes}
                                    tag="GangstaBetType"
                                    customId={war.id}
                                />
                            </div>
                        )}
                        {checkForGeneralTag(war) && (
                            <AddSpecificGameTag
                                customId={war.id}
                                specificType={["1"]}
                                tag="GeneralType"
                            />
                        )}
                    </div> */}

                    {/* <Tooltip
            style={{ zIndex: 999, maxWidth: "250px" }}
            anchorSelect={`#${warId}_description`}
            delayShow={500}
          >
            <small> {war.description}</small>
          </Tooltip> */}
                </div>
            </td>
            <td className="w-[100px] text-sm">{war.zone}</td>

            <td className="zone-type-block-list">
                {/* {war.zone} */}
                <div className="flex flex-col gap-1 flex-wrap mt-1">
                    <div className="flex flex-col gap-1">
                        <p className="text-[14px]"> Weapon</p>
                        {war.allowedWeapons && war.allowedWeapons.length !== 0 && war.allowedWeapons !== WeaponTypeCount() ? (
                            <AddSpecificGameTag
                                specificType={war.allowedWeapons}
                                tag="WeaponsType"
                                customId={war.id}
                            />
                        ) : (
                            <AddSpecificGameTag
                                customId={war.id}
                                specificType={["1"]}
                                tag="GeneralType"
                            />
                        )}
                    </div>
                    <div className="flex flex-col gap-1">
                        <p className="text-[14px]">GangstaBet Type</p>
                        {war.allowedTypes && war.allowedTypes.length !== GangstaBetTypeCount() ? (
                            <AddSpecificGameTag
                                specificType={war.allowedTypes}
                                tag="GangstaBetType"
                                customId={war.id}
                            />
                        ) : (
                            <AddSpecificGameTag
                                customId={war.id}
                                specificType={["1"]}
                                tag="GeneralType"
                            />
                        )}
                    </div>
                    {/* {checkForGeneralTag(war) && (
                        <AddSpecificGameTag
                            customId={war.id}
                            specificType={["1"]}
                            tag="GeneralType"
                        />
                    )} */}
                </div>
            </td>
            <td className="war-join-btn-list">
                <div className="flex flex-col items-center justify-center">
                    <div
                        className={`war-action-btn `}
                        id={`simId_${Number(war.id)}`}
                        data-tooltip-content={`Gangwar ID: ${Number(war.id)}`}
                    >
                        <PrimaryButton
                            width="145px"
                            height="35px"
                            buttonName={
                                gameStatus
                                    ? isWalletParticipated
                                        ? "play now"
                                        : "spectate"
                                    : isWalletParticipated
                                        ? "Check Lobby"
                                        : lockJoiningGame
                                            ? "War Locked"
                                            : "join war"
                            }
                            className={`mt-[0px] whitespace-nowrap ${!gameStatus && !isWalletParticipated && lockJoiningGame
                                ? "join-disabled disable"
                                : ""
                                }`}
                            onClick={warActionBtnHandler}
                        />

                        {!isWalletParticipated && numberOfParticipants > 0 && !gameStatus && (
                            <PrimaryButton
                                width="108px"
                                height="30px"
                                buttonName={"Visit Lobby"}
                                onClick={viewLobbyHandler}
                                className="visit-lobby-btn"
                            />
                        )}

                        {process.env.REACT_APP_ENV === "development" && (
                            <Tooltip
                                style={{
                                    zIndex: 999,
                                    maxWidth: "300px",
                                    opacity: 1,
                                }}
                                anchorSelect={`#simId_${Number(war.id)}`}
                            />
                        )}
                    </div>
                    {/* <div className="war-info">
            {!gameStatus && lockJoiningGame ? (
              <span className="text-red text-sm quickWarStart">
                {
                  <Countdown
                    targetDate={new Date(
                      new Date(Number(war.startTime) / 1000).setHours(
                        new Date(Number(war.startTime) / 1000).getHours()
                      )
                    ).toISOString()}
                    renderer={warStartsIn}
                  />
                }{" "}
                secs
              </span>
            ) : (
              ""
            )}
          </div> */}
                </div>
            </td>

            {/* <td className="flex flex-col justify-center items-center war-status-wrapper">
        
      </td> */}
        </tr>
    );
};

export default GangwarList;
