import { ReactNode } from "react";
import { IPrimaryButton } from "src/core/interfaces/button.interface";

const PrimaryIconButton = ({
  buttonName,
  icon,
  extraIcon,
  extraText,
  onClick,
  height,
  className,
}: IPrimaryButton) => {
  return (
    <button
      style={{ height }}
      onClick={onClick}
      className={"primary-icon-button m-2  " + className}
    >
      <div className={`flex items-center`}>{icon}</div>
    </button>
  );
};

export default PrimaryIconButton;
