import PrimaryButton from "@components/buttons/primary.button";
import NFTCard from "@components/card/nftCard.component";
import { TEST_NFTS, REDIRECT_ICON, EMPTY_HAT } from "@constants/images";
import { useCardAnimation } from "@hooks/useCardAnimation";
import { useEffect, useState, useMemo } from "react";
import { LOADING } from "@constants/images";
import { PLAY_NORMAL_CLICK } from "@constants/audio";
import RentModal from "@components/modal/rental/rental.rent.modal";
import UpdateModal from "@components/modal/rental/rental.update.modal";
import RemoveModal from "@components/modal/rental/rental.remove.modal";
import { NFTSkillsWRentalHistory, NFTDetail } from "./nfts.component";
import { CROWN, ICX } from "@constants/images";
import RentNFT from "./rentNFT.component";
import { NFT_RENT_TYPE } from "@constants/nft";
import { HYPERLINKS } from "@misc/links";
import { useSelector } from "react-redux";
import RentDetail from "./rentDetail.component";
import { RootState } from "@redux/store";
import { usePaginatedRentalMarket } from "@hooks/usePaginatedRentalData";
import GangstaBetRentalLoading from "./gangstaBetRentalLoading";
import { ZERO_ADDRESS } from "@type/rental.type";
import { useNavigate } from "react-router-dom";
import { useNFTInformation } from "@context/nftInfoProvider";

const RentGangstabet = ({ totalPageCount }: { totalPageCount: number }) => {
  const [rentalModal, setRentalModal] = useState<
    "rent" | "update" | "remove" | "lend" | "claim" | null
  >(null);
  const [period, setperiod] = useState(0);
  const navigate = useNavigate();
  const { isWalletConnected, walletAddress } = useSelector((state: RootState) => state.wallet);
  const URL = `${process.env.REACT_APP_BASE_URL}/gangwar/rental/getActiveRentals`;
  const { data, loading, loaderRef, fetchRentalMarketplaceInfo } = usePaginatedRentalMarket(URL, totalPageCount, "MARKETPLACE", walletAddress ?? ZERO_ADDRESS);
  const [animationToggle, setAnimationToggle] = useState<boolean>(false);
  const [selectedChar, setSelectedChar] = useState<any | null>(
    null
  );
  const { rentalInformation } = useNFTInformation()
  useEffect(() => {
    setSelectedChar(null);
  }, [isWalletConnected]);

  useCardAnimation("rightSection", animationToggle);
  return (
    <>
      <div className="border-x border-gray-600 min-w-[1450px] mx-auto">
        {data && data.length > 0 ? (
          <div className="flex gap-2 justify-center">
            <div className="gangstabetRentNftList flex">
              <div className={`flex mt-4 w-full h-full ${data?.length > 0 ? 'items-start' : 'items-center'}`}>
                <div className="body">
                  {data?.length > 0 ? (
                    <div className="flex gap-7 ">
                      <div className="flex justify-start m-3 flex-wrap gap-4 cardsSection">
                        {data?.map((item: any) => {
                          if (walletAddress && walletAddress.toLowerCase() === item?.rental?.owner?.toLowerCase()) return <></>;
                          return <div
                            className="flex gap-2 p-3 w-[18.5rem] relative items-center justify-start h-content border border-gray-600 hover:border-[#E3C96E] cursor-pointer"
                            onClick={async () => {
                              PLAY_NORMAL_CLICK();
                              setAnimationToggle(
                                item === selectedChar
                                  ? animationToggle
                                  : !animationToggle
                              );
                              setSelectedChar(item);
                            }}
                          >
                            <img
                              src={item?.gangstaBetDetails?.nftImage ?? TEST_NFTS}
                              alt="nft"
                              className="w-[5rem] h-[6.825rem] "
                            />
                            {Number(item?.hospitalization?.warCooldown) / 1000 > Date.now() && <div className="rentalMarketplaceHospitalization">
                              <p className="rentalMarketplaceHospitalization_title">Hospitalized</p>
                            </div>}
                            <div className="flex flex-col justify-between h-full py-0.5">
                              <div className="whitespace-normal overflow-hidden">
                                <NFTDetail
                                  isNameClickable={false}
                                  showWeapon={false}
                                  selectedChar={item}
                                  showId={false}
                                />
                              </div>
                              <div className="text-white flex gap-1 items-center justify-start ">
                                <img
                                  src={
                                    item.rental.rentType ===
                                      NFT_RENT_TYPE.CROWN_CURRENCY
                                      ? CROWN
                                      : ICX
                                  }
                                  alt="currency"
                                  className="w-6 h-6"
                                />
                                {+item.rental?.listedRentFee!}{" "}
                                <span className="text-xs"> per day</span>
                              </div>
                            </div>
                          </div>
                        })}
                        {loading &&
                          <GangstaBetRentalLoading />
                        }
                        <div ref={loaderRef} style={{ height: '10px' }} />
                      </div>
                    </div>
                  ) : (
                    <div className="flex flex-col gap-2  items-center justify-center">
                      <img src={EMPTY_HAT} alt="empty hat" />

                      <p className="text-white text-3xl big-shoulders uppercase">
                        Rental Market is Empty
                      </p>
                      <p className="text-gray w-2/3 text-center">
                        Looks like the Rent Market is currently empty. Don't
                        worry, you can seize this opportunity to be the first to
                        rent out your powerful Gangstabets and earn rewards in
                        return!
                      </p>
                      <PrimaryButton
                        onClick={() => window.open("/profile/collection", "_self")}
                        buttonName="Put For Rent" />
                    </div>
                  )}
                </div>
              </div>
            </div>
            {selectedChar ? (
              <div className="overflow-hidden gangstabetPageWrapper right-section-rent-width  overflow-auto ">
                <div className="right-section animation" id="rightSection">
                  <div className="flex-center gap-6 border-b border-b-gray-600">
                    <div className="flex-center gap-6 p-5">
                      <NFTCard
                        cardType="nftCardImageCollection"
                        nftImage={selectedChar?.gangstaBetDetails.nftImage ?? TEST_NFTS}
                        hospitalized={{
                          cardSize: "NFTOnIllMd",
                          hospitalizedTimer: selectedChar.hospitalization.warCooldown,

                        }}
                        width={"170px"}
                        height={"220px"}
                        rental={
                          {
                            cardSize: "NFTOnRentMd",
                            rentalType: selectedChar.rental.status,
                            rentalTimer: selectedChar.rental.expirationTimestamp,
                            rentalName: selectedChar.rental.rentalName,
                          }
                        }
                        isSelected
                      />
                      <div className="scale-95">
                        <NFTDetail
                          selectedChar={selectedChar}
                        />
                        <hr className="horizontal-seperator" />
                        <p className="rental-text">Owned by</p>
                        <p className="text-sm font-semibold leading-5 text-[#fff]">
                          <a href={`${HYPERLINKS.GANGSTABET_WALLET}/${selectedChar.gangstaBetDetails.ownedBy}`} target="_blank" className="cursor_pointer" rel="noreferrer">
                            <span className="inline-flex gap-2 rental-text !font-semibold !text-[rgba(32,148,225,1)]">
                              {selectedChar?.gangstaBetDetails?.ownedBy.slice(0, 4)}...{selectedChar?.gangstaBetDetails?.ownedBy.slice(-4)}
                              <img src={REDIRECT_ICON} alt="redirect" />
                            </span>
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                  {selectedChar.gangstaBetDetails.ownedBy.toLowerCase() === walletAddress?.toLowerCase() ? (
                    <RentDetail
                      setRentalModal={setRentalModal}
                      selectedChar={selectedChar}
                    />
                  ) : (
                    <RentNFT
                      period={period}
                      days={rentalInformation.maxRentDay}
                      // days={Math.floor((selectedChar.rental?.expiry! / 1000 - Date.now()) / msPerDay)}
                      setPeriod={setperiod}
                      currency={selectedChar.rental.rentType}
                      price={+selectedChar.rental?.listedRentFee!}
                      setRentalModal={setRentalModal}
                      isWalletConnected={isWalletConnected}
                    />
                  )}
                  <NFTSkillsWRentalHistory selectedChar={selectedChar} />
                </div>
              </div>
            ) : (
              <div className="flex-center w-[500px] min-h-[600px] self-center nftSelectedCard justify-center  h-full px-12">
                <div className="text-3xl uppercase text-gray-700 text-center big-shoulders my-auto font-bold">
                  Select an NFT to see Details
                </div>
              </div>
            )}
          </div>
        ) : loading && data.length === 0 ? (
          // Loading
          <div className="flex flex-col gap-2 w-full min-h-[600px] h-full items-center justify-center">
            <img width={220} src={LOADING} alt="loading circle" />
          </div>
        ) : (
          // Empty Inventory
          <div className="flex flex-col min-h-[600px] gap-2 w-full h-full items-center justify-center">
            <img src={EMPTY_HAT} alt="empty hat" />
            <p className="text-white text-3xl big-shoulders uppercase">
              No Gangstabets for Rent in rental marketplace
            </p>
            <p className="text-gray w-1/3 text-center">
              It looks like there is no NFT for rent.
            </p>
            {/* <PrimaryButton
              onClick={() => {
                navigate("/profile");
                PLAY_NORMAL_CLICK();
              }}
              buttonName="put on rent"
            /> */}
          </div>
        )}

        {/* modals */}
        {rentalModal === "rent" && (
          <RentModal
            period={period}
            selectedChar={selectedChar}
            isVisible={rentalModal === "rent"}
            setRentalModal={setRentalModal}
            fetchRentalMarketplaceInfo={fetchRentalMarketplaceInfo}
            setSelectedChar={setSelectedChar}
          />
        )}
        {rentalModal === "update" && (
          <UpdateModal
            selectedChar={selectedChar}
            isVisible={rentalModal === "update"}
            setRentalModal={setRentalModal}
          />
        )}
        {rentalModal === "remove" && (
          <RemoveModal
            selectedChar={selectedChar}
            isVisible={rentalModal === "remove"}
            setRentalModal={setRentalModal}
          />
        )}
      </div>
    </>
  );
};

export default RentGangstabet;
