import {
  PLAY_CLOSE_SOUND,
  PLAY_NORMAL_CLICK,
  PLAY_INVENTORY_CLICK,
} from "@constants/audio";
import { CROWN } from "@constants/images";
import Seo from "@components/seo";
import { headerItems } from "@type/header.type";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

const InventoryBody = ({ children }: any) => {
  const [item, setItem] = useState<headerItems>("INVENTORY");
  const [notificationsON, setNotificationsON] = useState<boolean>(false);
  const [isUserMenuListVisible, setUserMenuListVisible] = useState<boolean>(
    false
  );
  const [disconnectWalletModal, setDisconnectWalletModal] = useState(false);
  const { isWalletConnected, walletAddress } = useSelector(
    (state: any) => state.wallet
  );
  const [userConnectModal, setuserConnectModal] = useState(false);

  const [activeTab, setActiveTab] = useState(false);
  const location = useLocation();
  useEffect(() => {
    if (
      location.pathname === "/inventory/equipment" ||
      location.pathname === "/inventory"
    ) {
      setActiveTab(true);
    } else {
      setActiveTab(false);
    }
  }, [location.pathname]);

  return (
    <>
      <Seo
        title="Inventory | Gangwars"
        description="Open Loot Crates, and view your Weapons, Sneak Moves and Items"
      />
      <div className=" inventoryContent text-white ">
        <div className="container inventoryMain minWidthContainer mx-auto grid grid-cols-12">
          <div className="col-span-2 sidebar">
            <ul>
              <li>
                <NavLink
                  onClick={() => {
                    PLAY_NORMAL_CLICK();
                  }}
                  to="/inventory/equipment"
                  className={({ isActive }) =>
                    isActive || activeTab
                      ? "activeClassName hoverClass"
                      : "hoverClass"
                  }
                >
                  WEAPONS
                </NavLink>
              </li>
              <li>
                <NavLink
                  onClick={() => {
                    PLAY_NORMAL_CLICK();
                  }}
                  to="/inventory/sneak-moves"
                  className={({ isActive }) =>
                    isActive ? "activeClassName hoverClass" : "hoverClass"
                  }
                >
                  SNEAK MOVES
                </NavLink>
              </li>

              <li>
                <NavLink
                  onClick={() => {
                    PLAY_NORMAL_CLICK();
                  }}
                  to="/inventory/items"
                  className={({ isActive }) =>
                    isActive ? "activeClassName hoverClass" : "hoverClass"
                  }
                >
                  ITEMS
                </NavLink>
              </li>

              <li>
                <NavLink
                  onClick={() => {
                    PLAY_NORMAL_CLICK();
                  }}
                  to="/inventory/loot-crates"
                  className={({ isActive }) =>
                    isActive ? "activeClassName hoverClass" : "hoverClass"
                  }
                >
                  LOOT CRATES
                </NavLink>
              </li>
            </ul>
          </div>
          {children}
        </div>
      </div>
    </>
  );
};

export default InventoryBody;
