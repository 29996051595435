import { initializeApp } from "firebase/app";
import { getAuth, signInAnonymously } from "firebase/auth";

import {
  getFirestore,
  collection,
  onSnapshot,
  doc,
  query,
  getDocs,
  getDoc,
  setDoc,
  addDoc,
  orderBy,
  serverTimestamp,
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MESAUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const auth = getAuth(app);


export const signInAnonymouslyAndSetSignedIn = (setFirebaseSignedIn) => {
  signInAnonymously(auth)
    .then(() => {
      // Set context here
      setFirebaseSignedIn(true);
      console.log("Signed in anonymously");
    })
    .catch((error) => {
      console.log("Error signing in anonymously:", error);
    });
};

export const getDB = (simId) => {
  return doc(firestore, "gangwars", "gangwar#" + simId.toString());
};

export const getTeamA = (simId) => {
  return getDocs(
    collection(firestore, "gangwars", "gangwar#" + simId.toString(), "Team A")
  );
};

export const getTeamB = (simId) => {
  return getDocs(
    collection(firestore, "gangwars", "gangwar#" + simId.toString(), "Team B")
  );
};

export async function getSpectatorStats(simId, callback) {
  try {
    onSnapshot(doc(firestore, "spectator", simId.toString()), (doc) => {
      console.log("Current data: ", doc.data());
      callback(doc.data());
    });
  } catch (error) {
    console.log(error);
  }
}

export async function getStats(simId) {
  try {
    const docRef = doc(firestore, "spectator", simId.toString());
    const docSnap = await getDoc(docRef);
    const data = docSnap.data();
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function updateSpectatorStats(simId, cCount, mCount) {
  try {
    console.log("Updating spectator count!!!", simId, cCount, mCount);
    await setDoc(
      doc(firestore, "spectator", simId.toString()),
      {
        currentCount: cCount,
        maxCount: mCount,
      },
      { merge: true }
    );
    console.log("completed!!!--->");
  } catch (error) {
    console.error("Updating spectator count", error);
  }
}

export async function sendMessage(roomId, user, text) {
  try {
    await addDoc(collection(firestore, "chat", roomId, "messages"), {
      uid: user.uid,
      displayName: user.displayName,
      text: text.trim(),
      timestamp: serverTimestamp(),
    });
  } catch (error) {
    console.error(error);
  }
}

export function getMessages(roomId, callback) {
  return onSnapshot(
    query(
      collection(firestore, "chat", roomId, "messages"),
      orderBy("timestamp", "asc")
    ),
    (querySnapshot) => {
      const messages = querySnapshot.docs.map((x) => ({
        id: x.id,
        ...x.data(),
      }));

      callback(messages);
    }
  );
}
