import { getLootBoxDetails } from "@api/api";
import PrimaryButton from "@components/buttons/primary.button";
import ColoredCrateCard from "@components/card/coloredCrateCard";
import NFTCard from "@components/card/nftCard.component";
import ImageLoader from "@misc/ImageLoader";
import { HYPERLINKS } from "@misc/links";
import { ColorDetailsTypes, colorDetails } from "@type/lootboxColor.type";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { onSnapshot } from "firebase/firestore";
import { getDB } from "@services/firebase.service";
import { formatMilliseconds } from "@misc/formatTimeDuration";
import { AddSpecificGameTag, checkForGeneralTag } from "@misc/specificTagGames";
import { GangstaBetTypeCount, WeaponTypeCount } from "@misc/warSpecific";

const convertTime = (timestamp: number) => {
    const dateObj = new Date(timestamp / 1000);
    const date = dateObj.getDate();
    const month = dateObj.toLocaleString("default", { month: "short" });
    const hours = dateObj.getUTCHours();
    const minutes = dateObj.getUTCMinutes();
    return `${date} ${month}, ${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")} EST`;
};
function microsecondToLocalTime(microsecondTimestamp: number) {
    const timestampInMilliseconds = Math.floor(microsecondTimestamp / 1000);
    const date = new Date(timestampInMilliseconds);

    // Get the month as a short name
    const month = date.toLocaleString("en-US", { month: "short" });

    // Get the hours in 12-hour format with AM/PM
    let hours = date.getHours();
    const amPm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;

    // Get the individual components of the date
    const year = date.getFullYear();
    const day = String(date.getDate()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    // Format the date as "MMM DD, YYYY hh:mm:ss AM/PM"
    const formattedTime = `${month} ${day}, ${year} ${hours}:${minutes} ${amPm}`;
    // const formattedTime = `${month} ${day}, ${hours}:${minutes} ${amPm}`;

    return formattedTime;
}

const WarHistoryList = ({ warList, showWinnersWarList }: any) => {
    const navigate = useNavigate();
    const [lootboxDetails, setLootboxDetails] = useState<any>();
    const [duration, setDuration] = useState<number>(0);

    useEffect(() => {
        async function fetchLootDetails() {
            try {
                const result = await getLootBoxDetails(Number(warList?.reward?.lootBoxId));
                setLootboxDetails(result.data);
            } catch (e) {
                console.log(e);
            }
        }
        const unsubscribe = onSnapshot(
            getDB(Number(warList?.simulation?.id)),
            async (snapshot: any) => {
                if (snapshot.data()?.eventLogs.length > 0) {
                    const sn_data = snapshot.data();
                    console.log("sn_data", sn_data.duration);
                    setDuration(Number(sn_data.duration));
                }
            }
        );
        fetchLootDetails();
        return () => {
            unsubscribe();
        };
    }, []);
    return (
        <>
            {warList.teamA.length > 0 &&
                warList.teamB.length > 0 &&
                Boolean(Number(warList.simulation.isSynced)) && (
                    <tr className="war-item flex items-center">
                        <td className="start-time-block flex  items-center">
                            {microsecondToLocalTime(Number(warList.simulation.startTime))}
                        </td>
                        <td className="duration-block flex items-center">
                            {/* {Number(warList?.simulation?.period) / 60_000_000} */}
                            {formatMilliseconds(duration)}
                        </td>
                        <td
                            className={`team-block team-a ${warList.reward.winnerTeam ===
                                "TEAM_A" &&
                                showWinnersWarList &&
                                "green-gradient-bg"} flex flex-col`}
                        >
                            <div className="team-nfts flex flex-row flex-wrap gap-1 justify-center">
                                {warList.teamA.map((nftDet: any, index: number) => {
                                    return (
                                        <div className="team-nft-wrapper relative">
                                            {/* <div className="user-nft-marker text-yellow flex items-center justify-center">
                <FaUserAlt size={11} />
              </div> */}
                                            <NFTCard
                                                borderType="nftBorderHistoryAndBattle"
                                                cardType="nftCardImageHistoryAndBattle"
                                                imageId={nftDet.participantId}
                                                width="50px"
                                                height="65px"
                                                onClick={() => {
                                                    window.open(
                                                        `${HYPERLINKS.GANGSTABET_PROFILE}/${nftDet.participantId}`,
                                                        "_blank"
                                                    );
                                                }}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                            {warList.reward.winnerTeam === "TEAM_A" && showWinnersWarList && (
                                <div className="war-result-wrapper flex flex-col items-center uppercase mt-4">
                                    {/* <div className="user-team">your team</div> */}
                                    <div className="result green-border">winner</div>
                                </div>
                            )}
                        </td>
                        <td
                            className={`team-block ${warList.reward.winnerTeam === "TEAM_B" &&
                                showWinnersWarList &&
                                "green-gradient-bg"} team-b flex items-center flex-col`}
                        >
                            <div className="team-nfts flex flex-row flex-wrap gap-1 justify-center">
                                {warList.teamB.map((nftDet: any, index: number) => {
                                    return (
                                        <div className="team-nft-wrapper">
                                            <NFTCard
                                                borderType="nftBorderHistoryAndBattle"
                                                cardType="nftCardImageHistoryAndBattle"
                                                imageId={nftDet.participantId}
                                                width="50px"
                                                height="65px"
                                                onClick={() => {
                                                    window.open(
                                                        `${HYPERLINKS.GANGSTABET_PROFILE}/${nftDet.participantId}`,
                                                        "_blank"
                                                    );
                                                }}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                            {warList.reward.winnerTeam === "TEAM_B" && showWinnersWarList && (
                                <div className="war-result-wrapper flex flex-col items-center uppercase mt-4">
                                    {/* <div className="user-team">your team</div> */}
                                    <div className="result green-border">winner</div>
                                </div>
                            )}
                        </td>
                        <td className="rewards-block flex flex-col items-start w-[240px] gap-1">
                            <div
                                className="cursor-pointer"
                                id={`history_${Number(warList?.simulation?.id).toString()}`}
                            >
                                <ImageLoader
                                    width="80px"
                                    height="80px"
                                    src={lootboxDetails?.imageUrl}
                                    alt=""
                                />
                                {/* <ColoredCrateCard
                image={lootboxDetails?.imageUrl}
                width="80px"
                height="80px"
                maxWidth="80px"
                crateWidth="100px"
                crateHeight="100px"
                bgColor={
                  colorDetails[
                    lootboxDetails?.id?.toString()
                  ] as ColorDetailsTypes
                }
              /> */}
                            </div>
                            <Tooltip
                                style={{ zIndex: 999, maxWidth: "250px" }}
                                anchorSelect={`#history_${Number(
                                    warList?.simulation?.id
                                ).toString()}`}
                                delayShow={500}
                            >
                                <h4>{lootboxDetails?.name}:</h4>
                                <small> {lootboxDetails?.description}</small>
                            </Tooltip>
                            <h2 className="text-white text-center">{lootboxDetails?.name}</h2>
                        </td>
                        <td className="spec-block flex flex-col justify-start items-start w-[180px] gap-4">
                            <div className="flex gap-1 flex-wrap mt-1">
                                {warList.allowedMetadata && warList.allowedMetadata.allowedWeapons.length !== 0 &&
                                    warList.allowedMetadata.allowedWeapons.length !==
                                    WeaponTypeCount() && (
                                        <AddSpecificGameTag
                                            specificType={warList.allowedMetadata.allowedWeapons}
                                            tag="WeaponsType"
                                            customId={warList.simulation.id}
                                            isHistory={true}
                                        />
                                    )}
                                {warList.allowedMetadata && warList.allowedMetadata.allowedTypes.length !== 0 &&
                                    warList.allowedMetadata.allowedTypes.length !==
                                    GangstaBetTypeCount() && (
                                        <AddSpecificGameTag
                                            specificType={warList.allowedMetadata.allowedTypes}
                                            tag="GangstaBetType"
                                            customId={warList.simulation.id}
                                            isHistory={true}
                                        />
                                    )}
                                {(!warList.allowedMetadata || (warList.allowedMetadata && checkForGeneralTag({
                                    ...warList.allowedMetadata,
                                }))) && (
                                        <AddSpecificGameTag
                                            customId={warList.simulation.id}
                                            specificType={["1"]}
                                            tag="GeneralType"
                                            isHistory={true}
                                        />
                                    )}
                            </div>
                        </td>
                        <td className="replay-btn-wrapper  flex flex-col gap-1 items-center justify-end text-center">
                            {/* 18097179838 */}
                            <PrimaryButton
                                width="135px"
                                height="35px"
                                onClick={() => {
                                    const url = `/battle/rewatch/${Number(warList.simulation.id)}`;
                                    window.location.href = url;
                                    // window.open();
                                }}
                                buttonName="Replay"
                            />
                            <PrimaryButton
                                width="135px"
                                height="35px"
                                onClick={() => {
                                    navigate(`/overview/${Number(warList.simulation.id)}`);
                                }}
                                buttonName="View Details"
                            />
                        </td>
                    </tr>
                )}
        </>
    );
};
export default WarHistoryList;
