import StylizedButton from "@components/buttons/stylized.button";
import Modal from "@components/modal/modal.component";
interface INotEnoughCrown {
  isVisible: boolean;
  setIsVisible?: any;
  handleConfirm?: any;
  warFee: number;
  showPayCrownConfirm: any;
}
const PayEntryFee = ({
  isVisible,
  setIsVisible,
  handleConfirm,
  warFee,
  showPayCrownConfirm,
}: INotEnoughCrown) => {
  return (
    <Modal
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      modalTitle="Pay the entry fee"
    >
      <div className="crownLimitWrapper">
        <div className="playerNumber">
          Join war for
          <span className=" font-extrabold">
            {" "}
            {showPayCrownConfirm.method === "ICX"
              ? `ICX ${warFee}`
              : `CROWN ${warFee}`}
            .
          </span>
        </div>
        <hr className="horizontal-seperator" />
        <div className="crownLimitText">
          <p className="crownLimitTopText">
            You are paying {warFee} {showPayCrownConfirm.method} to join the
            war, are you sure you want to continue ?.
          </p>
          {/* <p className="crownLimitInfo">
            Please try joining a different war or buy more crown to join this
            war.
          </p> */}
        </div>
        <hr className="horizontal-seperator" />
        <div className="crownLimitButton">
          <StylizedButton
            onClick={() => {
              setIsVisible(false);
              handleConfirm();
            }}
            buttonName="Pay & Join war"
          />
        </div>
      </div>
    </Modal>
  );
};

export default PayEntryFee;
