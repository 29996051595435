import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import SocialMedia from "@components/media/socialMedia";
import { FaBell, FaUserAlt } from "react-icons/fa";
import { handleDropdownClose } from "@utils/closeDropdown";
import { headerItems } from "@type/header.type";
import { useDispatch, useSelector } from "react-redux";
import { useWalletConnect } from "@hooks/connectWallet";
import { BsFillVolumeUpFill } from "react-icons/bs";

import {
  availableCrown,
  getICXBalance,
  getOwnedNft,
  getUsersLootCrateBurntCount,
  usersAvailableLootboxBalances,
} from "@services/icon.service";
import ConnectModal from "@components/modal/wallet/connectWith.modal";
import { useNavigate, useLocation } from "react-router-dom";
import { useDetailGlobalContext } from "@context/detailProvider";
import { MdContentCopy } from "react-icons/md";
import { DISCONNECT } from "@constants/images";
import { CROWN, HEADER_WALLET, ICONEX_HEADER } from "@constants/images";
import { formatNumber } from "@utils/formatNumber";
import { PLAY_CLOSE_SOUND, PLAY_NORMAL_CLICK } from "@constants/audio";
import {
  setAvailableCrown,
  setAvailableICX,
  setOwnedNFTs,
} from "@redux/features/inventorySlice";
import VolumeController from "@components/sound/volume.controller";
import { Tooltip } from "react-tooltip";
import ConnectDisconnectWallet from "./ConnectDisconnectWallet";

const HeaderStylishItemBorder = () => {
  return (
    <>
      <div className="header-item-style-box-1"></div>
      <div className="header-item-style-box-2"></div>
      <div className="header-item-style-box-3"></div>
    </>
  );
};

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const headerLodation = location.pathname
    .trim()
    .split("/")[1]
    .toLocaleUpperCase() as headerItems;

  const [item, setItem] = useState<headerItems>(headerLodation);
  const [isUserMenuListVisible, setUserMenuListVisible] = useState<boolean>(
    false
  );
  const [showVolumeList, setShowVolumeList] = useState(false);
  const [isNotificationListVisible, setNotificationListVisible] = useState<
    boolean
  >(false);
  const [userConnectModal, setuserConnectModal] = useState(false);
  const userMenuRef = useRef<any>(null);
  const volumeRef = useRef<any>(null);
  const notificationRef = useRef<any>(null);
  const { isWalletConnected, walletAddress } = useSelector(
    (state: any) => state.wallet
  );
  const [connectedWalletModal, setConnectedWalletModal] = useState(false);
  const [disconnectWalletModal, setDisconnectWalletModal] = useState(false);
  useWalletConnect(setConnectedWalletModal);
  useEffect(() => {
    handleDropdownClose(userMenuRef, setUserMenuListVisible);
    handleDropdownClose(volumeRef, setShowVolumeList);
  });

  const [openStartKit, setOpenStartKit] = useState(false);

  useEffect(() => {
    setItem(headerLodation);
  }, [headerLodation]);

  const availableUserInformation = async () => {
    const crown = await availableCrown(walletAddress);
    dispatch(setAvailableCrown(crown));
    const icx = await getICXBalance(walletAddress);
    dispatch(setAvailableICX(icx));
    const nftListPromises = [];
    //todo
    // 1.change total_page to totalPage and nft_list to nftList
    //also dont forget to change in icon service
    const ownedNFTs = await getOwnedNft(walletAddress);
    for (let i = 1; i <= Number(ownedNFTs.totalPage); i++) {
      const ownedNFTs = getOwnedNft(walletAddress, `0x${i.toString(16)}`);
      nftListPromises.push(ownedNFTs);
    }
    const nftList: Array<string> = [];
    await Promise.all(nftListPromises).then((values) => {
      for (let i = 0; i < values.length; i++) {
        nftList.push(...values[i].nftList);
      }
    });

    dispatch(setOwnedNFTs(nftList));
  };

  useEffect(() => {
    if (isWalletConnected) {
      availableUserInformation();
    }
  }, []);

  useEffect(() => {
    async function checkIfUserClaimingForFirstTime() {
      const count = await getUsersLootCrateBurntCount(walletAddress);
      const lootCrates = await usersAvailableLootboxBalances(walletAddress);
      const value = Object.values(count).map((num) => Number(num));
      const maxValue = Math.max(...value);
      if (maxValue <= 0 && lootCrates.length > 0) {
        setOpenStartKit(true);
      }
    }
    if (isWalletConnected) {
      checkIfUserClaimingForFirstTime();
    }
  }, [isWalletConnected]);

  const { userCrown, userICX } = useDetailGlobalContext();

  const [color, setColor] = useState("");

  const chanceCopyAddressColor = () => {
    setColor("#068385");
    setTimeout(() => {
      setColor("");
    }, 1000);
  };
  return (
    <div className="header-wrapper ">
      <div className="header-item-wrapper   mx-auto">
        <nav>
          <ul className="header-items ">
            <NavLink
              className={`header-item ${item === "MAP" ? "selected-item-header" : "unSelected-item-header"
                }`}
              onClick={() => {
                // setItem("MAP");
                PLAY_NORMAL_CLICK();
              }}
              to="/map"
            >
              {item === "MAP" ? <><h1>map</h1> <HeaderStylishItemBorder /></> :
                "map"}
            </NavLink>
            <NavLink
              className={`header-item ${item === "WAR" ? "selected-item-header" : "unSelected-item-header"
                }`}
              onClick={() => {
                PLAY_NORMAL_CLICK();
              }}
              to="/war"
            >
              {item === "WAR" ? <><h1>wars</h1> <HeaderStylishItemBorder /></> :
                "wars"}
            </NavLink>
            <NavLink
              className={`header-item ${item === "INVENTORY"
                ? "selected-item-header"
                : "unSelected-item-header"
                }`}
              onClick={() => {
                PLAY_NORMAL_CLICK();
                // if (isWalletConnected) {
                // } else {
                //   setuserConnectModal(true);
                // }
              }}
              to="/inventory"
            >
              {item === "INVENTORY" ? <><h1>inventory</h1> <HeaderStylishItemBorder /></> :
                "inventory"}
            </NavLink>
            <NavLink
              className={`header-item  ${item === "PROFILE"
                ? "selected-item-header"
                : "unSelected-item-header"
                }`}
              onClick={() => {
                PLAY_NORMAL_CLICK();
                // if (isWalletConnected) {
                setItem("PROFILE");
                // } else {
                //   setuserConnectModal(true);
                // }
              }}
              to="/profile"
            >
              {item === "PROFILE" ? <><h1>profile</h1> <HeaderStylishItemBorder /></> :
                "profile"}
            </NavLink>
            {/* <>
              <a className="header-item-alert"NavLink
                <p className="alertText">!</p>
              </NavLink>
            </> */}

            <NavLink
              id="rental"
              className={`header-item ${item === "RENTAL"
                ? "selected-item-header"
                : "unSelected-item-header"
                }`}
              onClick={() => {
                PLAY_NORMAL_CLICK();
                setItem('RENTAL')
              }}
              to={"/rental"}
            >
              {item === "RENTAL" ? <><h1>Rental </h1> <HeaderStylishItemBorder /></> :
                "Rental "}
              <Tooltip
                style={{
                  zIndex: 999,
                  maxWidth: "300px",
                  opacity: 1,
                }}
                anchorSelect={`#rental`}
              />
            </NavLink>

            <NavLink
              id="leaderboard"
              className={`header-item ${item === "LEADERBOARD"
                ? "selected-item-header"
                : "unSelected-item-header"
                }`}
              onClick={() => {
                PLAY_NORMAL_CLICK();
                setItem('LEADERBOARD')
              }}
              to={"/leaderboard"}
            >
              {item === "LEADERBOARD" ? <><h1>leaderboard</h1> <HeaderStylishItemBorder /></> :
                "leaderboard"}
              <Tooltip
                style={{
                  zIndex: 999,
                  maxWidth: "300px",
                  opacity: 1,
                }}
                anchorSelect={`#leaderboard`}
              />
            </NavLink>

          </ul>
        </nav>
        <div className="header-icons">
          {isWalletConnected && <>
            <div
              className="flex items-center ml-3 gap-2 justify-center"
              ref={notificationRef}
            >
              <img src={CROWN} alt="crown" />
              <span className="crown-text-color">
                {" "}
                {formatNumber(Number(userCrown.toFixed(2)))}
              </span>
              {/* <SocialMedia
              className={!isNotificationListVisible ? "unSelected" : ""}
              onClick={() =>
                setNotificationListVisible(!isNotificationListVisible)
              }
              icon={<FaBell size={30} color="white" />}
            /> */}
            </div>
            <div
              className="flex items-center gap-1 ml-2 justify-center"
              ref={notificationRef}
            >
              <img src={ICONEX_HEADER} alt="icon" />
              <span className="crown-text-color">
                {" "}
                {formatNumber(Number((Number(userICX)?.toFixed(2))))}
              </span>
              {/* <SocialMedia
              className={!isNotificationListVisible ? "unSelected" : ""}
              onClick={() =>
                setNotificationListVisible(!isNotificationListVisible)
              }
              icon={<FaBell size={30} color="white" />}
            /> */}
            </div>
          </>}

          <div ref={userMenuRef}>
            <div
              className="drop-down-menu-wallet"
              onClick={() => {
                setUserMenuListVisible(!isUserMenuListVisible);
                setShowVolumeList(false);
                PLAY_NORMAL_CLICK();
              }}
            >
              {/* <FaUserAlt
                className={`${
                  !isUserMenuListVisible ? "unSelected " : "Selected-dropdown"
                } connectWalletDrop cursor-pointer`}
                onClick={() => setUserMenuListVisible(!isUserMenuListVisible)}
                size={25}
                color="white"
              /> */}
              {isWalletConnected ? (
                <p className="text-header-wallet flex gap-3">
                  <img src={ICONEX_HEADER} alt="icon" />
                  <p>
                    {walletAddress.slice(0, 4)}...
                    {walletAddress.slice(-4)}
                  </p>
                </p>
              ) : (
                <p
                  onClick={() => {
                    PLAY_NORMAL_CLICK();
                    setuserConnectModal(true);
                  }}
                  className="text-header-wallet flex- gap-3"
                >
                  <img src={HEADER_WALLET} alt="wallet" />
                  <p>Connect</p>
                </p>
              )}
            </div>
            <div>
              {isUserMenuListVisible && isWalletConnected && (
                <div className="user-menu-list animate__animated  animate__fadeIn">
                  <>
                    <li
                      style={{ color }}
                      onClick={(e) => {
                        PLAY_NORMAL_CLICK();
                        window.navigator.clipboard.writeText(walletAddress);
                        chanceCopyAddressColor();
                      }}
                      className="flex gap-3 items-center wallet-connection-dropdown"
                    >
                      <MdContentCopy
                        color={`${color === "" ? "white" : color}`}
                      />
                      <span>Copy Address</span>
                    </li>
                    <li
                      className="flex gap-3 items-center wallet-connection-dropdown"
                      onClick={() => {
                        setDisconnectWalletModal(true);
                        PLAY_CLOSE_SOUND();
                      }}
                    >
                      <img src={DISCONNECT} alt="disconnect" />
                      Disconnect
                    </li>
                  </>
                </div>
              )}
            </div>
          </div>
          <div ref={volumeRef}>
            <div
              className="drop-down-menu-volume"
              onClick={() => {
                setShowVolumeList(!showVolumeList);
                PLAY_NORMAL_CLICK();
              }}
            >
              <BsFillVolumeUpFill
                className="mr-3"
                size={30}
                color={`${showVolumeList ? "yellow" : "white"}`}
              />
            </div>
            <div>{showVolumeList && <VolumeController />}</div>
          </div>
        </div>
      </div>
      <ConnectDisconnectWallet userConnectModal={userConnectModal} setuserConnectModal={setuserConnectModal} disconnectWalletModal={disconnectWalletModal} setDisconnectWalletModal={setDisconnectWalletModal} />
    </div>
  );
};

export default Header;
