import Modal from "../modal.component";
import { Dispatch, FC, SetStateAction } from "react";
import { HYPERLINKS } from "@misc/links";

interface IRentalLoading {
  title: string;
  description: string;
  setRentalModal: Dispatch<
  SetStateAction<"rent" | "update" | "remove" | "lend" | "claim" | null>
>;
}

const RentalFailed: FC<IRentalLoading> = ({ setRentalModal, title, description }) => {
  return (
    <Modal
      isVisible={true}
      onModalClose={() => setRentalModal(null)}
      glow="fail"
      modalTitle={title}
      className=" rental-modal-outer-wrapper flex flex-col justify-center items-center"
    >
      <p className="description-text text-center">
      {description}
        <br />
        If the problem persists please reachout to us in our{" "}
        <a href={HYPERLINKS.DISCORD} className="text-[#e3c96e]">Discord server</a>.
      </p>
    </Modal>
  );
};
export default RentalFailed;
