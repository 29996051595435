export const NFT_RENT_STATUS = {
    IN_RENT: "IN_RENT",
    AVAILABLE_FOR_RENT: "AVAILABLE_FOR_RENT",
    NOT_AVAILABLE_FOR_RENT: "NOT_AVAILABLE_FOR_RENT",
    DE_LISTED: "DE_LISTED",
    COMPLETED: "COMPLETED",
    RENTED: "RENTED",
    EXPIRING: "EXPIRING",
} as const;

export const CONTRACT_RENT_STATUS_TO_NFT_STATUS = {
    0: NFT_RENT_STATUS.IN_RENT,
    1: NFT_RENT_STATUS.AVAILABLE_FOR_RENT,
    2: NFT_RENT_STATUS.NOT_AVAILABLE_FOR_RENT,
    3: NFT_RENT_STATUS.COMPLETED,
    4: NFT_RENT_STATUS.DE_LISTED,
} as const;

export const NFT_RENT_TYPE = {
    INVALID: "INVALID",
    ICX_CURRENCY: "ICX_CURRENCY", //0
    CROWN_CURRENCY: "CROWN_CURRENCY", //1
} as const;

export const msPerDay = 600000; //86400000
