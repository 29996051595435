import { useState, useRef, useEffect } from "react";
import { AiOutlineDown } from "react-icons/ai";
import { IFilterOptions } from "@interfaces/filter.interface";
import {
    durationOptions,
    showWinners,
    timeOptions,
    warsHistoryViewOptions,
} from "@type/dropDownOption.type";
import { handleDropdownClose } from "@utils/closeDropdown";
import WarReward from "../rewardImage.component";
import NFTCard from "@components/card/nftCard.component";
import PrimaryButton from "@components/buttons/primary.button";
import { FaUserAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { AiOutlineDoubleLeft } from "react-icons/ai";
import { AiOutlineDoubleRight } from "react-icons/ai";
import { LOADING } from "@constants/images";

import {
    getSimulationCount,
    getSimulationHistory,
    hasWalletParticipated,
} from "@services/icon.service";
import { getLootBoxDetails } from "@api/api";
import { useSelector } from "react-redux";
import { usePagination } from "@hooks/usePagination";
import ColoredCrateCard from "@components/card/coloredCrateCard";
import { ColorDetailsTypes, colorDetails } from "@type/lootboxColor.type";
import WarHistoryList from "./historyList.component";
import ImageLoader from "@misc/ImageLoader";
import { useHistoryGlobalContext } from "@context/history";

interface IWarHistoryFilter {
    duration: IFilterOptions[];
    time: IFilterOptions;
    wars: IFilterOptions;
}
interface Props {
    totalSimulationCount: number;
}
const WarsHisory = () => {
    const { totalSimulationCount } = useHistoryGlobalContext();
    const [warHistoryDetails, setWarHistoryDetails] = useState([]);
    const [paginationState, changeOffset] = usePagination(totalSimulationCount);
    const [loadingHistory, setLoadingHistory] = useState(true);

    // **********************************************

    const [historyDetails, setHistoryDetails] = useState<any>([]);
    const BATCH_SIZE = 1;
    const dataFetchStartIdRef = useRef<number>(0);
    const offsetRef = useRef<number>(0);
    const observerTarget = useRef(null);
    const [dataFetchLoading, setDataFetchLoading] = useState(true);

    const fetchWarHistory = async () => {
        let endId;
        if (Number(totalSimulationCount) + offsetRef.current <= 0) return;

        endId = dataFetchStartIdRef.current + BATCH_SIZE;
        if (endId > Number(totalSimulationCount)) {
            endId = Number(totalSimulationCount) + 1;
        }

        const promises = [];
        for (let i = dataFetchStartIdRef.current; i < endId; i++) {
            offsetRef.current -= 10;
            // console.log(
            //     "totalSimulationCount",
            //     offsetRef.current,
            //     Number(totalSimulationCount) + offsetRef.current,
            //     totalSimulationCount
            // );
            promises.push(getSimulationHistory(Number(totalSimulationCount) + offsetRef.current));
        }
        dataFetchStartIdRef.current = endId;
        const allHistoryArray = await Promise.all(promises);
        const tempHistoryData: any[] = [].concat(...allHistoryArray);
        setHistoryDetails((prevData: any) => [...prevData, ...tempHistoryData]);
    };

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting) {
                    setDataFetchLoading(true);
                    fetchWarHistory();
                    setDataFetchLoading(false);
                }
            },
            { rootMargin: "100px" }
        );

        if (observerTarget.current) {
            observer.observe(observerTarget.current);
        }

        return () => {
            if (observerTarget.current) {
                observer.unobserve(observerTarget.current);
            }
        };
    }, [observerTarget, totalSimulationCount]);

    // **********************************************

    // const warHistory = async () => {
    //     setLoadingHistory(true);
    //     const historyDetails = await getSimulationHistory(paginationState.offset);
    //     setWarHistoryDetails(historyDetails);
    //     setLoadingHistory(false);
    // };

    // useEffect(() => {
    //     warHistory();
    // }, [changeOffset]);
    // const { isWalletConnected, walletAddress } = useSelector((state: any) => state.wallet);
    // const [lootboxDetails, setLootboxDetails] = useState<any>();

    const [showDurationDropdown, setShowDurationDropdown] = useState<boolean>(false);
    // const [showTimeDropdown, setShowTimeDropdown] = useState<boolean>(false);

    const [warHistoryListFilter, setWarHistoryListFilter] = useState<IWarHistoryFilter>({
        duration: [durationOptions[1], durationOptions[2]],
        time: timeOptions[0],
        wars: warsHistoryViewOptions[0],
    });

    const [showWinnersWarList, setShowWinnersWarList] = useState(false);

    // const durationDropdownRef = useRef<any>(null);
    // const timeDropdownRef = useRef<any>(null);

    // const navigate = useNavigate();

    // const [filterHistory, setFilterHistory] = useState<any>([]);

    // useEffect(() => {
    //   async function filters() {
    //     const history = warHistoryDetails;
    //     const filtered: any = [];
    //     await Promise.all(
    //       history?.map(async (item: any) => {
    //         if (isWalletConnected) {
    //           const status = await hasWalletParticipated(
    //             item.simulation.id,
    //             walletAddress
    //           );
    //           if (warHistoryListFilter.wars.value === "all") {
    //             filtered.push(item);
    //           } else if (warHistoryListFilter.wars.value === "joinedWars") {
    //             if (!!Number(status)) {
    //               filtered.push(item);
    //             }
    //           }
    //         } else {
    //           filtered.push(item);
    //         }
    //       })
    //     );
    //     setFilterHistory(filtered);
    //   }
    //   filters();
    // }, [warHistoryListFilter]);

    // useEffect(() => {
    //   handleDropdownClose(durationDropdownRef, setShowDurationDropdown);
    //   handleDropdownClose(timeDropdownRef, setShowTimeDropdown);
    // });

    // const checkDurationSelected = (duration: any): boolean =>
    //     warHistoryListFilter.duration.find((item) => item.name === duration.name) ? true : false;

    // const handleDurationCheckbox = (duration: IFilterOptions) => {
    //     const optionSelectedIndex = warHistoryListFilter.duration.findIndex(
    //         (item: any) => item.value === duration.value
    //     );

    //     optionSelectedIndex > -1
    //         ? warHistoryListFilter.duration.splice(optionSelectedIndex, 1)
    //         : warHistoryListFilter.duration.push(duration);

    //     warHistoryListFilter.duration.sort((a, b) => parseInt(a.value) - parseInt(b.value));

    //     setWarHistoryListFilter({ ...warHistoryListFilter });
    //     setShowDurationDropdown(!showDurationDropdown);
    // };

    // handle the change in offset
    // handle the change in offset
    // const handlePageChange = (newOffset: number) => {
    //     const totalPages = Math.ceil(paginationState.total / paginationState.limit);
    //     const maxPage = Math.max(totalPages - 1, 0);
    //     const newOffsetClamped = Math.min(Math.max(newOffset, 0), maxPage * paginationState.limit);
    //     changeOffset(paginationState.total - newOffsetClamped - paginationState.limit);
    // };

    // const renderPagination = () => {
    //     const totalPages = Math.ceil(paginationState.total / paginationState.limit);
    //     const currentPage = Math.ceil(
    //         (paginationState.total - paginationState.offset) / paginationState.limit
    //     );
    //     return (
    //         <div className="flex items-center justify-center gap-2">
    //             <div
    //                 onClick={() => handlePageChange(paginationState.offset + paginationState.limit)}
    //                 className={`${currentPage === 1 && "disable"} cursor-pointer paginationDiv`}
    //             >
    //                 <AiOutlineDoubleLeft />
    //             </div>

    //             <span>
    //                 {currentPage} of {totalPages}
    //             </span>
    //             <div
    //                 onClick={() => handlePageChange(paginationState.offset - paginationState.limit)}
    //                 className={`${currentPage === totalPages &&
    //                     "disable"} cursor-pointer paginationDiv`}
    //             >
    //                 <AiOutlineDoubleRight />
    //             </div>
    //         </div>
    //     );
    // };

    return (
        <>
            <div className="wars-history-tab-content ">
                <div className="filter-section flex justify-between flex-row">
                    <div className="flex flex-row dropdown-wrapper ">
                        {/* <div className="relative time-dropdown" ref={timeDropdownRef}>
              <div
                className="dropdown-title-wrapper flex items-center cursor-pointer"
                onClick={() => {
                  setShowTimeDropdown(!showTimeDropdown);
                }}
              >
                <div className="filter-title flex flex-row">
                  <p className="filter-text">Time:</p>
                  <p className="filter-selected">
                    {warHistoryListFilter.time.name}
                  </p>
                </div>
                <AiOutlineDown />
              </div>
              {showTimeDropdown && (
                <div className="filter-menu">
                  {timeOptions.map((option, index) => {
                    return (
                      <p
                        className={
                          warHistoryListFilter.time.name === option.name
                            ? "filter-selected text-yellow"
                            : ""
                        }
                        onClick={() => {
                          setWarHistoryListFilter({
                            ...warHistoryListFilter,
                            time: option,
                          });
                          setShowTimeDropdown(!showTimeDropdown);
                        }}
                        key={index}
                      >
                        {option.name}
                      </p>
                    );
                  })}
                </div>
              )}
            </div> */}
                        {/* <div
              className="relative duration-dropdown"
              ref={durationDropdownRef}
            >
              <div
                className="dropdown-title-wrapper flex items-center cursor-pointer"
                onClick={() => {
                  setShowDurationDropdown(!showDurationDropdown);
                }}
              >
                <div className="filter-title flex flex-row">
                  <p className="filter-text">Duration:</p>
                  <p className="filter-selected">
                    {warHistoryListFilter.duration.map(
                      (item, index) => (index ? ", " : "") + item.name
                    )}
                  </p>
                </div>
                <AiOutlineDown />
              </div>
              {showDurationDropdown && (
                <div className="filter-menu">
                  {durationOptions.slice(1).map((option, index) => {
                    return (
                      <label
                        htmlFor={"duration_" + option.value}
                        className="flex gap-3 items-center checkbox-label"
                        key={option.value}
                      >
                        <input
                          type="checkbox"
                          value={option.value}
                          id={"duration_" + option.value}
                          className="checkbox-input"
                          checked={checkDurationSelected(option)}
                          onChange={() => handleDurationCheckbox(option)}
                        />
                        <span className="custom-checkbox" />
                        <span
                          className={
                            checkDurationSelected(option)
                              ? "filter-selected text-yellow"
                              : ""
                          }
                        >
                          {option.name}
                        </span>
                      </label>
                    );
                  })}
                </div>
              )}
            </div> */}
                        {/* {isWalletConnected && (
              <div className="war-join-filter flex flex-row gap-3 ml-2">
                {warsHistoryViewOptions.map((option, index) => {
                  return (
                    <label
                      htmlFor={option.value}
                      className="flex gap-2 items-center radio-label"
                      key={option.value}
                    >
                      <input
                        type="radio"
                        value={option.value}
                        id={option.value}
                        className="radio-input"
                        checked={
                          option.value === warHistoryListFilter.wars.value
                        }
                        onChange={() =>
                          setWarHistoryListFilter({
                            ...warHistoryListFilter,
                            wars: option,
                          })
                        }
                      />
                      <span className="custom-radio" />
                      {option.name}
                    </label>
                  );
                })}
              </div>
            )} */}

                        {/* <div className="clear-filter underline underline-offset-2 cursor-pointer flex items-center ml-3">
              Clear all filters
            </div> */}

                        <div>
                            {/* <label className="relative inline-flex items-center mr-5 cursor-pointer">
                <input
                  onChange={() => {
                    setShowWinnersWarList(!showWinnersWarList);
                  }}
                  type="checkbox"
                  value=""
                  className="sr-only peer"
                />
                <div className="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#e3c96e]"></div>
                <span className="ml-3 text-sm font-medium text-white-900 dark:text-gray-300">
                  Show Winners
                </span>
              </label> */}
                            <div className="toggleCommingWar text-sm font-normal  flex items-center gap-2">
                                <label className="switch">
                                    <input
                                        onChange={() => {
                                            setShowWinnersWarList(!showWinnersWarList);
                                        }}
                                        id="check"
                                        checked={showWinnersWarList}
                                        type="checkbox"
                                        className="checkbox"
                                    />
                                    <div className="slider"></div>
                                </label>
                                <p
                                    onClick={() => {
                                        setShowWinnersWarList(!showWinnersWarList);
                                    }}
                                    className="cursor-pointer joinedWars"
                                >
                                    Show Winners
                                </p>
                            </div>
                        </div>

                        {/* <div className="war-join-filter showWinners flex flex-row gap-3 ml-2">
              <input
                onChange={() => {
                  setShowWinnersWarList(!showWinnersWarList);
                }}
                type="checkbox"
                id="myCheckbox"
              />
              <label className="cursor-pointer" htmlFor="myCheckbox">
                Show Winners
              </label>
            </div> */}
                    </div>
                </div>

                <div className="wars-table-wrapper   wars-history-table-wrapper">
                    {historyDetails.length > 0 || loadingHistory ? (
                        <>
                            <table className="wars-table">
                                <thead className="wars-table-header">
                                    <tr className="flex">
                                        <td className="start-time-block">start time</td>
                                        <td className="duration-block">duration</td>
                                        <td className="team-block text-center">team a</td>
                                        <td className="team-block text-center">team b</td>
                                        <td className="rewards-block">rewards</td>
                                        <td className="spec-block text-start">War Spec</td>
                                    </tr>
                                </thead>
                                <tbody className="war-items">
                                    {historyDetails?.map((item: any, index: number) => (
                                        <WarHistoryList
                                            warList={item}
                                            key={`warhistory)${index}`}
                                            showWinnersWarList={showWinnersWarList}
                                        />
                                    ))}
                                </tbody>
                            </table>
                            {/* {totalSimulationCount > 5 && (
                                <div className="mt-5">{renderPagination()}</div>
                            )} */}

                            {loadingHistory && (
                                <div className="flex items-center justify-center h-[300px]">
                                    <img width="180px" src={LOADING} alt="" />
                                </div>
                            )}
                        </>
                    ) : (
                        !dataFetchLoading && (
                            <div className="lex min-h-[330px] w-full flex uppercase justify-center text-4xl opacity-40 items-center no-war-available unSelectedTextState">
                                no gangwar history
                            </div>
                        )
                    )}
                </div>
                {showDurationDropdown && (
                    <div className="filter-menu">
                        {durationOptions.slice(1).map((option, index) => {
                            return (
                                <p
                                    className={
                                        warHistoryListFilter.duration[index].name === option.name
                                            ? "filter-selected text-yellow"
                                            : ""
                                    }
                                    onClick={() => {
                                        setWarHistoryListFilter({
                                            ...warHistoryListFilter,
                                            duration: [option],
                                        });
                                        setShowDurationDropdown(!showDurationDropdown);
                                    }}
                                    key={index}
                                >
                                    {option.name}
                                </p>
                            );
                        })}
                        {dataFetchLoading && <img width="180px" src={LOADING} alt="" />}
                    </div>
                )}
            </div>
            <div ref={observerTarget} />
        </>
    );
};

export default WarsHisory;
