import { getEquipmentsDetails } from "@api/api";
import { IUserEquipment } from "@interfaces/equipment.interface";

export async function getEquipmentInformation(
  id: string
): Promise<Partial<IUserEquipment>> {
  try {
    const result = await getEquipmentsDetails(id);
    const {
      equipmentId,
      name,
      type,
      stats,
      properties,
      imageUrl,
      description,
    } = result.data.primaryEquipment;
    const availableInfo = {
      equipmentId,
      name,
      type,
      stats,
      properties,
      image: imageUrl,
      description,
    };
    return availableInfo;
  } catch (e) {
    console.log(e);
    return {};
  }
}
