import { IEquipment } from "src/core/interfaces/card.interface";
import { AiOutlineCheckCircle } from "react-icons/ai";
import ImageLoader from "@misc/ImageLoader";
const EquipmentPlainCard = ({
  equipmentNumber,
  equipmentImages,
  height,
  width,
  className,
  active,
  handleClick,
  id,
  doubleClick,
  divId,
  maxHeight,
  minHeight,
  minWidth,
}: IEquipment) => {
  return (
    <div
      style={{ height, width }}
      className={`equipmentPlainWrapper cursor-pointer`}
      onClick={handleClick}
      onDoubleClick={doubleClick}
      id={divId}
    >
      <div
        style={{ maxHeight, minHeight, minWidth }}
        className={`equipmentPlainComponent ${className}`}
      >
        <div className="equipment">
          <ImageLoader
            className="equ-image"
            src={equipmentImages}
            alt="(*_*)"
          />
          {/* <img src={equipmentImages} alt="(*_*)" /> */}
          <div id={id} className="equipmentNumber">
            {equipmentNumber}
          </div>
        </div>
      </div>
      {active && (
        <div className="active-tick">
          <AiOutlineCheckCircle className="text-[#e3c96e] text-lg rounded-full bg-black" />
        </div>
      )}
      <div className="backgroundStylizer"></div>
    </div>
  );
};

export default EquipmentPlainCard;
