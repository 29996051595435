import { getMessages } from '@services/firebase.service';
import React from 'react';


function useMessages(roomId: string) {
    const [messages, setMessages] = React.useState([]);

    React.useEffect(() => {
        getMessages(roomId, setMessages);
    }, [roomId]);

    return messages;
}

export { useMessages };