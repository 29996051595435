import React from "react";
import ReactDOM from "react-dom/client";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import "@assets/styles/index.style.scss";
import "animate.css";
import { store } from "@redux/store";
import { Provider } from "react-redux";
import "react-tooltip/dist/react-tooltip.css";
import { DetailsProvider } from "./core/context/detailProvider";
import { ContextIndex } from "@context/index";

const persistor = persistStore(store);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <ContextIndex>
        <App />
      </ContextIndex>
    </PersistGate>
  </Provider>
);
