import {
  setWalletConnection,
  setWalletAddress,
  setLedgerPath,
  setLedgerTransport,
  setIsLedger,
  setSignStoreHash,
  setTempWalletAddress,
} from "@redux/features/walletSlice";
import {
  setAvailableCrown,
  setAvailableICX,
  setOwnedNFTs,
} from "@redux/features/inventorySlice";
export const disconnectWallet = (
  dispatch: any,
  navigate: any,
  setNftData: any,
  setCurrentBatchIndex: any,
  setRentalIndex: any
) => {
  dispatch(setWalletConnection(false));
  dispatch(setWalletAddress(null));
  dispatch(setSignStoreHash(null));
  dispatch(setTempWalletAddress(null));
  dispatch(setLedgerPath(""));
  dispatch(setLedgerTransport(null));
  dispatch(setIsLedger(false));
  dispatch(setOwnedNFTs([]));
  dispatch(setAvailableCrown(0));
  dispatch(setAvailableICX(0));
  setNftData([]);
  setCurrentBatchIndex(0);
  setRentalIndex(null);
  navigate("/map");
};
