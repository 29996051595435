import { IStylizedButton } from "src/core/interfaces/button.interface";

const StylizedButton = ({ buttonName, icon, onClick }: IStylizedButton) => {
  return (
    <div onClick={onClick} className="stylized-wrapper my-3">
      <div className="box-1"></div>
      <button className="stylized-button  mx-7">
        <div className="px-10 stylized-text flex gap-3 items-center">
          {buttonName}
        </div>
      </button>
      <div className="box-2"></div>
    </div>
  );
};

export default StylizedButton;
