import { FC, useEffect, useState } from "react";

import Modal from "@components/modal/modal.component";
import PrimaryButton from "../../buttons/primary.button";
import { BLUE_ICX, CROWN } from "@constants/images";
import { claimRentFees, getUserClaimables, syncClaimableRent } from "@services/icon.service";
import { useDetailGlobalContext } from "@context/detailProvider";
import { AsyncState } from "./rental.interface";
import RentalWrapper from "./RentalWrapper.component";
import { AiOutlineClose } from "react-icons/ai";
import { useSelector } from "react-redux";
import RentalLoading from "./loading.rental";
import Image from "@misc/Image";
import { useNFTInformation } from "@context/nftInfoProvider";
import { RootState } from "@redux/store";
import { useTransactionValidate } from "@hooks/transactionResult";




const ClaimModal: FC<any> = ({
  // selectedChar,
  isVisible,
  setRentalModal,
}) => {
  const { getTxResultWithTimer } = useTransactionValidate({ onHandle: () => { } });
  const { reRenderCollectionToggler, setRerenderCollectionToggler, rentalInformation, setRentalInformation, getRentalInformation, platformFees } = useNFTInformation();
  const [asyncState, setAsyncState] = useState(AsyncState.IDLE);
  const [batchId, setBatchId] = useState(0);
  const [totalClaimableRecords, setTotalClaimableRecords] = useState(0);
  const [history, setHistory] = useState<any[]>([]);
  const { isWalletConnected, walletAddress, isLedger } = useSelector((state: RootState) => state.wallet);

  const {
    transactionLoading,
    setTransactionLoading,
  } = useDetailGlobalContext();

  const onAction = async () => {
    const result: any = setAsyncState(AsyncState.LOADING);
    if (isLedger) {
      if (result?.name === "TransportStatusError") {
        console.log("Ledger cancelled!!!");
      } else if (result) {
        getTxResultWithTimer(result as string);
      } else {
        console.log("Error on ledger");
      }
    }
    await claimRentFees()
  };

  const onContinue = () => {
    setRentalModal(null);
    if (asyncState === AsyncState.SUCCESS) {
      setRerenderCollectionToggler(!reRenderCollectionToggler);
      setRentalInformation({ ...rentalInformation, hasClaimableRentFee: false })
    }
  };
  // fetch claimable history
  useEffect(() => {
    if (isWalletConnected) {
      if (batchId * 10 <= totalClaimableRecords) {
        (async () => {
          const res = await getUserClaimables(walletAddress, (batchId * 10).toString())
          if (!totalClaimableRecords) setTotalClaimableRecords(Number(res.totalRecords))
          // const filteredHistory = res.rents.filter((hisDetails: any) => Number(hisDetails.claimableAmount) !== 0)
          setHistory([...history, ...res.rents])
          setBatchId(batchId + 1)
        })()
      }
    }
  }, [batchId, history, isWalletConnected, totalClaimableRecords, walletAddress])

  if (asyncState === AsyncState.CANCELED) {
    setRentalModal(null);
    setTransactionLoading((prev) => ({
      ...prev,
      state: "idle",
      status: false,
    }));
    return null;
  }
  if (asyncState === 'sync') {
    if (transactionLoading.state === 'loading') {
      setTransactionLoading((prev) => ({
        ...prev,
        state: "loading",
        status: false,
      }));
    } else if (transactionLoading.state === 'success') {
      getRentalInformation();
      setHistory([]);
      setBatchId(0)
      setTotalClaimableRecords(0)
      setTransactionLoading((prev) => ({
        ...prev,
        state: "idle",
        status: false,
      }));
      setAsyncState(AsyncState.IDLE)
    }
    else if (transactionLoading.state === 'canceled') {
      setTransactionLoading((prev) => ({
        ...prev,
        state: "idle",
        status: false,
      }));
      setRentalModal(null)
    }

    return (
      <RentalLoading
        title="Please Sign in the transaction"
        description="Please Sign in the transaction in order to sync your claimables .."
      />
    )
  }

  if (asyncState === AsyncState.IDLE) {
    return (
      <RentalWrapper
        type="CLAIM"
        asyncState={asyncState}
        setAsyncState={setAsyncState}
        setRentalModal={setRentalModal}
      >
        <div
          className="modalOverlay claim-modal-outer-wrapper"
        >
          <div className="childOverlay">
            <div className="modalComponent flex flex-col justify-between">
              <div>
                <div className="titleText flex justify-between !py-2">
                  <p className="">Total claimable rental earning</p>
                  <button
                    onClick={onContinue}
                    className="modalCloseButton outline-none"
                  >
                    <AiOutlineClose
                      size={30}
                      color="white"
                    />
                  </button>
                </div>
                {Boolean(totalClaimableRecords) ? (
                  <div className="relative !m-3 !p-3 mt-6 overflow-x-auto h-[60dvh] overflow-y-scroll  shadow-md sm:lg">
                    <table className="w-full text-left rtl:text-right text-gray-400 ">
                      <thead className="sticky -top-3 z-30 text-sm border-b border-gray-600 bg-[#0a0e10] text-[#70878F] uppercase ">
                        <tr>
                          <th scope="col" className="px-6 py-4 ">
                            GBET
                          </th>
                          <th scope="col" className="whitespace-nowrap px-6 py-4  bg-[rgba(38,52,61,0.20)] ">
                            Rent Id
                          </th>
                          <th scope="col" className="px-6 py-4  ">
                            Rented by
                          </th>
                          <th scope="col" className="px-6 py-4  bg-[rgba(38,52,61,0.20)] ">
                            Listed On
                          </th>
                          <th scope="col" className="px-6 py-4  ">
                            Period
                          </th>
                          <th scope="col" className="px-6 py-4  bg-[rgba(38,52,61,0.20)] ">
                            Rent Fee
                          </th>
                          <th scope="col" className="px-6 py-4 ">
                            Earned
                          </th>
                          {/* <th scope="col" className="px-6 py-4  bg-[rgba(38,52,61,0.20)] ">
                          Status
                        </th> */}
                        </tr>
                      </thead>
                      <tbody>

                        {history.map((data: any) =>
                          <tr>
                            <td className="px-4 py-2 flex gap-3 w-fit">
                              {/* <NFTCard
                                cardType="plainNftCardImage"
                                nftImage={nftsDetails.find((nft: any) => nft.nftId === +data.nftId)?.nftImage}
                                width="40px"
                                className="scale-125"
                              // height="100%"
                              /> */}
                              <Image className="scale-125"
                                width="40px" height="100%" id={(data.nftId)} />
                              {/* <NFTDetail selectedChar={nftsDetails.find((nft: any) => nft.nftId === +data.nftId)} showWeapon={false} showLevel={false} /> */}
                              {/* {+data.nftId} */}
                            </td>
                            <td className="px-6 py-2 font-medium whitespace-nowrap bg-[rgba(38,52,61,0.20)]">
                              {/* {data.id.slice(0, 4) + '...' + data.borrower.slice(-4)} */}
                              {+data.id}
                            </td>
                            <td className="px-6 py-2 font-medium text-white whitespace-nowrap ">
                              {data.borrower === 'hx0000000000000000000000000000000000000000' ? 'No one' : data.borrower.slice(0, 4) + '...' + data.borrower.slice(-4)}
                            </td>
                            <td className="px-4 py-2 bg-[rgba(38,52,61,0.20)]">
                              {new Date(Number(data.listedTimestamp) / 1000).toLocaleString()}
                            </td>
                            <td className="px-4 py-2 text-white">
                              {+data.period} {+data.period === 1 ? 'day' : 'days'}
                            </td>
                            <td className="px-4 py-2 bg-[rgba(38,52,61,0.20)]">
                              <div className="flex gap-2 items-center">
                                {+data.rentType === 0 ?
                                  <img
                                    src={BLUE_ICX}
                                    alt="currency"
                                    className="w-5 h-5"
                                  />
                                  : +data.rentType === 1 ?
                                    <img
                                      src={CROWN}
                                      alt="currency"
                                      className="w-5 h-5"
                                    />
                                    : ''}
                                {+data.rentFee / (10 ** 18)} per day
                              </div>
                            </td>
                            <td className="px-4 py-2 text-white">
                              <div className="flex gap-2 items-center">
                                {+data.rentType === 0 ?
                                  <img
                                    src={BLUE_ICX}
                                    alt="currency"
                                    className="w-5 h-5"
                                  />
                                  : +data.rentType === 1 ?
                                    <img
                                      src={CROWN}
                                      alt="currency"
                                      className="w-5 h-5"
                                    />
                                    : ''}
                                {+data.claimableAmount / (10 ** 18)}
                              </div>
                            </td>
                            {/* <td className="px-4 py-2 bg-[rgba(38,52,61,0.20)] ">
                            {CONTRACT_RENT_STATUS_TO_NFT_STATUS[+data.status as keyof typeof CONTRACT_RENT_STATUS_TO_NFT_STATUS]}
                          </td> */}
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <span className="flex justify-center min-h-[300px] items-center text-4xl text-yellow-300">No claimable data available</span>
                )}
              </div>
              <div className="!m-6">
                <div className="flex flex-col my-2">
                  <div className="flex items-center gap-4">
                    <span className="rental-text-md">Claimable rent fee may not be updated, refresh the list to see latest updates.</span>
                    <button
                      onClick={async () => {
                        setAsyncState('sync');
                        await syncClaimableRent();
                      }}
                      className="rental-text !text-[#E3C96E] underline underline-offset-2"
                    >
                      Refresh Now
                    </button>
                  </div>
                  <span className="text-gray-200">Note: <span>{platformFees}% will be deducted as platform fees.</span></span>

                </div>
                <div className="flex justify-between items-center !px-6 bg-gradient-to-r from-[rgba(255,198,0,0.25)] to-[rgba(10,14,16,0.00)]">
                  <div className="flex flex-col">
                    <span className="rental-text !text-base inline-flex items-center gap-2">
                      Total claimable rent (CROWN):
                      <img
                        src={CROWN}
                        alt="currency"
                        className="w-5 h-5"
                      />
                      <p className="text-xl font-bold">{rentalInformation.fees.crown?.toFixed(2)}</p>
                    </span>
                    <span className="rental-text !text-base inline-flex items-center gap-2">
                      Total claimable rent (ICX):
                      <img
                        src={BLUE_ICX}
                        alt="currency"
                        className="w-5 h-5"
                      />
                      <p className="text-xl font-bold">{rentalInformation.fees.icx?.toFixed(2)}</p>
                    </span>
                  </div>
                  <PrimaryButton
                    onClick={() => setAsyncState(AsyncState.CONFIRM)}
                    disabled={!rentalInformation.hasClaimableRentFee}
                    buttonName="CLAIM ALL THE RENT"
                    height="44px"
                    width="200px"
                  />
                </div>
              </div>
            </div>
          </div>

        </div>
      </RentalWrapper>

    )
  }

  return (
    <RentalWrapper
      type="CLAIM"
      asyncState={asyncState}
      setAsyncState={setAsyncState}
      setRentalModal={setRentalModal}
    >
      <Modal
        isVisible={isVisible}
        onModalClose={onContinue}
        glow={asyncState === AsyncState.SUCCESS ? "success" : ""}
        modalTitle={
          asyncState === AsyncState.SUCCESS ? "Success! Rental Fee Collected" : "Confirm Claim all rent"
        }
        className=" rental-modal-outer-wrapper flex flex-col justify-center items-center"
      >
        <p className="description-text text-center">
          {asyncState === AsyncState.SUCCESS ? (
            <>
              You have claimed the last remaining <br /> rental amount in ICX and Crown
              <div className="success-description-text py-4 mt-6 mb-8">
                <p className="flex gap-1.5 mb-2 justify-center items-end">
                  Crown Claimed:
                  <span className="inline-flex gap-2 items-end">
                    <img
                      src={CROWN}
                      alt="currency"
                      className="w-6 h-6"
                    />
                    {rentalInformation.fees.crown}
                  </span>

                </p>
                <p className="flex gap-1.5 justify-center items-end">
                  ICX Claimed:
                  <span className="inline-flex gap-2 items-end">
                    <img
                      src={BLUE_ICX}
                      alt="currency"
                      className="w-6 h-6"
                    />
                    {rentalInformation.fees.icx}
                  </span>

                </p>
              </div>
            </>
          ) : (
            <>
              Are you sure you want to claim all the <br /> remaining rent?
              <div className="success-description-text py-4 mt-6 mb-8">
                <p className="flex gap-1.5 mb-2 justify-center items-end">
                  Crown Claimed:
                  <span className="inline-flex gap-2 items-end">
                    <img
                      src={CROWN}
                      alt="currency"
                      className="w-6 h-6"
                    />
                    {rentalInformation.fees.crown}
                  </span>

                </p>
                <p className="flex gap-1.5 justify-center items-end">
                  ICX Claimed:
                  <span className="inline-flex gap-2 items-end">
                    <img
                      src={BLUE_ICX}
                      alt="currency"
                      className="w-6 h-6"
                    />
                    {rentalInformation.fees.icx}
                  </span>

                </p>
              </div>
            </>
          )}
        </p>
        <div className="flex flex-col items-center">
          {/* {asyncState === AsyncState.IDLE && (
           
          )} */}
          {asyncState === AsyncState.CONFIRM && (
            <PrimaryButton onClick={onAction} buttonName="Yes, claim all rent"
              className="!w-full"
            />
          )}
          {asyncState === AsyncState.SUCCESS ? (
            <PrimaryButton onClick={onContinue} buttonName="Okay" />
          ) : (
            <button
              onClick={
                asyncState === AsyncState.CONFIRM
                  ? () => setAsyncState(AsyncState.IDLE)
                  : () => setRentalModal(null)
              }
              className="rent-price-dropdown !text-center !py-[0.5em] outline-none"
            >
              {asyncState === AsyncState.CONFIRM ? "Go Back" : "Not Now"}
            </button>
          )}
        </div>
      </Modal>
    </RentalWrapper>
  );
};

export default ClaimModal;


// {
//   "borrower": "hx0000000000000000000000000000000000000000",
//   "claimableAmount": "0",
//   "expirationTimestamp": "0",
//   "id": "21",
//   "listedTimestamp": "1 703 837 992 536 469",
//   "nftId": "400",
//   "owner": "hx0ea5bab84d7447eea1536aadf6dd8b74fdc40595",
//   "period": "0",
//   "rentFee": "12 000 000 000 000 000 000",
//   "rentType": "0",
//   "startTimestamp": "0",
//   "status": "3"
// }