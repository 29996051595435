export function lockUnlockLoadouts(
  previousObject: Record<string, number>,
  presentObject: Record<string, number>
): [string[], string[], string[], string[]] {
  const unlockId: string[] = [];
  const unlockValue: string[] = [];
  const lockId: string[] = [];
  const lockValue: string[] = [];

  for (const i in previousObject) {
    if (presentObject.hasOwnProperty(i)) {
      if (previousObject[i] === presentObject[i]) {
      } else {
        const sub = Math.abs(previousObject[i] - presentObject[i]);
        if (previousObject[i] > presentObject[i]) {
          unlockId.push(i);
          unlockValue.push(sub.toString());
        } else {
          lockId.push(i);
          lockValue.push(sub.toString());
        }
      }
    } else {
      unlockId.push(i);
      unlockValue.push(previousObject[i].toString());
    }
  }

  for (const i in presentObject) {
    if (!previousObject.hasOwnProperty(i)) {
      lockId.push(i);
      lockValue.push(presentObject[i].toString());
    }
  }

  return [unlockId, unlockValue, lockId, lockValue];
}
