import React, { useEffect, useState } from "react";
import { TOAST } from "@constants/images";
import { TosterInterface } from "src/core/interfaces/toaster.interface";
import toasterService from "@components/toast/ToasterService.component";
import { AiOutlineClose } from "react-icons/ai";

const Toaster: React.FC<TosterInterface> = (props) => {
  const { toastList, position, autoDelete } = props;
  const [list, setList] = useState(toastList);
  const deleteToast = (id: any) => {
    const listItemIndex = list.findIndex((e) => e.id === id);
    const toastListItem = toastList.findIndex((e) => e.id === id);
    list.splice(listItemIndex, 1);
    toastList.splice(toastListItem, 1);
    toasterService.update(list);
    setList([...list]);
  };
  useEffect(() => {
    toasterService.get().subscribe((data: any) => {
      setList([...data]);
    });
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (autoDelete && toastList.length && list.length) {
        deleteToast(toastList[0].id);
      }
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [toastList, autoDelete, list]);

  return (
    <div className={`notification-container ${position}`}>
      {list.map((toast) => (
        <div
          key={toast.id}
          className={`notification toast ${position} ${toast.type}`}
        >
          <div className="toaster-body">
            {toast.toastSize === "toastLarge" ? (
              <>
                <div className="toasterTop">
                  <div className="notification-image">
                    {toast.type === "success" ||
                    toast.type === "warn" ||
                    toast.type === "error" ? (
                      <img
                        src={
                          toast.type === "success"
                            ? TOAST.SUCCESS
                            : toast.type === "warn"
                            ? TOAST.WARNING
                            : toast.type === "error"
                            ? TOAST.DANGER
                            : ""
                        }
                        alt=""
                      />
                    ) : null}
                  </div>
                  <div className="toastTitleText">
                    <p className="notification-title r-subtitle-1">
                      {toast.title}
                    </p>
                    <button
                      type="button"
                      className="close-toaster"
                      onClick={() => deleteToast(toast.id)}
                    >
                      <AiOutlineClose color="white" size={22} />
                    </button>
                  </div>
                </div>
                <div className="toastTextBtn">
                  <p className="notification-message r-caption">
                    {toast.message}
                  </p>
                  <button
                    onClick={() => {
                      toast.onClick ?? deleteToast(toast.id);
                    }}
                    className="toastBtn"
                  >
                    Okay
                  </button>
                </div>{" "}
              </>
            ) : (
              <>
                <div className="smallToast">
                  <div className="notification-image">
                    {toast.type === "success" ||
                    toast.type === "warn" ||
                    toast.type === "error" ? (
                      <img
                        src={
                          toast.type === "success"
                            ? TOAST.SUCCESS
                            : toast.type === "warn"
                            ? TOAST.WARNING
                            : toast.type === "error"
                            ? TOAST.DANGER
                            : ""
                        }
                        alt=""
                      />
                    ) : null}
                  </div>
                  <div className="toastTitleText">
                    <p className="notification-title r-subtitle-1">
                      {toast.message}
                    </p>
                    <button
                      type="button"
                      className="close-toaster"
                      onClick={() => deleteToast(toast.id)}
                    >
                      <AiOutlineClose color="white" size={22} />
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Toaster;
