import NFTCard from "@components/card/nftCard.component";
import { excludeStatsKeys } from "@misc/removeStatsKeys";
import { statNameMapping } from "@misc/statNameMap";

const Gangstabet = ({ userStatsDetails }: any) => {
    return (
        <div className={`warLogStatWrapper `}>
            <div className="warYourStats">
                <h2 className="text-white warGBText">Your NFTs</h2>
            </div>
            <div>
                <div className="nftDetailsWar">
                    <div className="flex gap-6 p-8">
                        <NFTCard
                            cardType="nftCardImageCollection"
                            nftImage={userStatsDetails?.nftImage}
                            width={"80px"}
                            height={"104px"}
                            isSelected
                        />
                        <div className="flex  flex-col text-white">
                            <div className="font-semibold text-lg">{userStatsDetails.nftName}</div>
                            <div className="text-gray text-base font-semibold">
                                {userStatsDetails?.classType} . {userStatsDetails?.characterType}
                            </div>
                            <div className="text-sm text-gray text-base mt-1">
                                {userStatsDetails?.levelType} . Level{" "}
                                {userStatsDetails?.levelNumber}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="statsInWar flex flex-col gap-2">
                    <div className="statHeading">
                        <h2 className="statsText">Stats</h2>
                    </div>
                    <div className="mt-3">
                        <div className="primaryStatsWar  mt-4 warPageSkillsStateBorder">
                            <div className=" flex flex-col px-4 py-2 gap-1">
                                {userStatsDetails?.skills &&
                                    Object.entries(userStatsDetails?.skills?.primary).map(
                                        ([key, value]: any) => {
                                            return (
                                                <div className="flex  justify-between " key={key}>
                                                    <p className="text-grey statsSkillKeysWar text-sm capitalize">
                                                        {key}{" "}
                                                    </p>
                                                    <p className="text-sm statsSkillValuesWar ">
                                                        {Math.floor(value).toFixed(0)}
                                                    </p>
                                                </div>
                                            );
                                        }
                                    )}
                            </div>
                        </div>
                    </div>
                    <div className="secondaryStatsWar warPageSkillsStateBorder">
                        <div className="flex px-4 py-2 flex-col gap-1">
                            {userStatsDetails?.skills &&
                                Object.entries(userStatsDetails?.skills?.secondary).map(
                                    ([key, value]: any) => {
                                        if (excludeStatsKeys.includes(key)) return null;
                                        return (
                                            <div
                                                className="flex flex-row justify-between w-full"
                                                key={key}
                                            >
                                                <p className="statsSkillKeysWar text-sm capitalize">
                                                    {statNameMapping?.[key]}
                                                </p>
                                                <p className="statsSkillValuesWar text-sm self-end">
                                                    {statNameMapping?.[key]?.split(" ").length > 1
                                                        ? (value * 100).toFixed(2) + "%"
                                                        : value.toFixed(0)}
                                                </p>
                                            </div>
                                        );
                                    }
                                )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Gangstabet;
