import EquipmentPlainCard from "@components/card/equipmentPlainCard.component";
import SneakMoves from "@components/tooltips/sneakmove.tooltip";
import { ABILITY } from "@constants/images";
import { useDetailGlobalContext } from "@context/detailProvider";
import ImageLoader from "@misc/ImageLoader";
import { AiFillLock } from "react-icons/ai";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { addAbilityTokens } from "@services/icon.service";
import { useTransactionValidate } from "@hooks/transactionResult";
import { getUserSneakmove } from "@api/api";
import PrimaryButton from "@components/buttons/primary.button";
import LoadingMove from "@components/team/loading";
import { RiDeleteBin6Line } from "react-icons/ri";
import { MessageText } from "@misc/message/messageText";
import { ResponseMessage } from "@misc/message/messageText";

const SneakMoveDetails = ({ userStatsDetails }: any) => {
  const { id } = useParams();
  const { walletAddress, isLedger, ledgerPath, ledgerTransport } = useSelector(
    (state: any) => state.wallet
  );
  const REACT_APP_IMAGE_BASE_URL = process.env.REACT_APP_IMAGE_BASE_URL;
  const [userRemainingAbilities, setUserRemainingAbilities] = useState<any>([]);
  const [userNewAbilities, setUserNewAbilities] = useState<any>([]);

  const [disableAbilities, setDisableAbilities] = useState(false);
  // console.log(playersInfo);
  useEffect(() => {
    let timeoutId: any;
    if (disableAbilities) {
      timeoutId = setTimeout(() => {
        setDisableAbilities(false);
      }, 4000);
    }
    return () => clearTimeout(timeoutId);
  }, [disableAbilities]);
  const closeScreen = () => {
    setDisableAbilities(!disableAbilities);
    setUserNewAbilities([]);
  };
  const { getTxResultWithTimer } = useTransactionValidate({
    onHandle: closeScreen,
    method: "addAbilityTokens",
  });

  const fetchUserSneakmoves = () => {
    const payload = {
      userAddress: walletAddress as string,
      simulationId: id?.toString() as string,
    };
    getUserSneakmove(payload).then((res) => {
      const abilityList = res.data.data.abilityList;
      const usableAbility = abilityList.filter(
        (move: any) =>
          move.status === "USABLE" ||
          move.status === "PENDING" ||
          move.type === "PASSIVE"
      );
      setUserRemainingAbilities(
        usableAbility.length > 3 ? usableAbility.slice(0, 3) : usableAbility
      );
    });
  };
  useEffect(() => {
    fetchUserSneakmoves();
    const interval = setInterval(fetchUserSneakmoves, 3000);
    return () => clearInterval(interval);
  }, [disableAbilities]);
  // useEffect(() => {
  // axios
  //   .post(`${process.env.REACT_APP_BASE_URL}${"/gangwar/get_ability/"}`, {
  //     playerAddress: walletAddress,
  //     simulationId: id?.toString(),
  //   })
  //   .then((res) => {
  //     const array = res.data.data.usableAbilityList
  //       .map((move: any, index: number) =>
  //         Array.from({ length: move.count }).fill(move)
  //       )
  //       .flat();
  //     const totalSneakmove = res.data.data.totalAbilityList
  //       .map((move: any, index: number) =>
  //         Array.from({ length: move.count }).fill(move)
  //       )
  //       .flat();
  // setTotalAbilities(totalSneakmove);
  // setUserRemainingAbilities(array);
  // });
  //   return
  // }, [disableAbilities]);
  const {
    userAbilityDetails,
    userAbilityDetailsWithSeperation,
    getAbilityNumberCount,
    setAbilityNumberCount,
    setTransactionLoading,
  } = useDetailGlobalContext();
  const [selectedSlot, setSelectedSlot] = useState<number>(0);

  const swapSneakmove = (ability: any) => {
    if (selectedSlot >= 0) {
      //   userAbilityDetails[selectedSlot] = ability;
      // setAbilityNumberCount((prev: any) => ({
      //   ...prev,
      //   [ability?.id]: getAbilityNumberCount[ability?.id] - 1,
      // }));
      const length = userNewAbilities.length + 1;
      setUserNewAbilities((prev: any) => {
        const array = [...prev];
        if (JSON.stringify(array[selectedSlot]) !== JSON.stringify(ability)) {
          if (array[selectedSlot]?.id) {
            setAbilityNumberCount((prev: any) => ({
              ...prev,
              [array[selectedSlot]?.id]:
                getAbilityNumberCount[array[selectedSlot]?.id] + 1,
            }));
          }
          setAbilityNumberCount((prev: any) => ({
            ...prev,
            [ability?.id]: getAbilityNumberCount[ability?.id] - 1,
          }));
          array[selectedSlot] = ability;
          return array;
        } else {
          return prev;
        }
      });
      setSelectedSlot((prev) => {
        if (userRemainingAbilities.length + length + prev < 3) {
          return prev + 1;
        } else {
          return prev;
        }
      });
    }
  };

  const updateSneakmove = async () => {
    // const presentAbilityGangwar: any = {};
    // const previousAbilityGangwar: any = {};
    // totalAbilities?.map((sneak: any, index: number) => {
    //   if (sneak.abilityId in previousAbilityGangwar) {
    //     previousAbilityGangwar[sneak.abilityId] =
    //       previousAbilityGangwar[sneak.abilityId] + 1;
    //   } else {
    //     previousAbilityGangwar[sneak.abilityId] = 1;
    //   }
    // });

    setTransactionLoading((prev) => ({
      ...prev,
      state: "loading",
      status: true,
      message: MessageText.REFILLING_MOVES,
      title: "Refilling Sneakmoves",
    }));

    const toAddAbilities: any = {};
    userNewAbilities?.map((sneak: any, index: number) => {
      if (sneak.id in toAddAbilities) {
        toAddAbilities[sneak.id] = toAddAbilities[sneak.id] + 1;
      } else {
        toAddAbilities[sneak.id] = 1;
      }
    });

    // new sneakmoves values / ids
    const abilityToAddIds = Object.keys(toAddAbilities).map((item: any) =>
      item.toString()
    );
    const abilityToAddValues = Object.values(toAddAbilities).map((item: any) =>
      item.toString()
    );

    //present values abilities
    // const abilitypresentIds = Object.keys(
    //   presentAbilityGangwar
    // ).map((item: any) => item.toString());
    // const abilityPresentValues = Object.values(
    //   presentAbilityGangwar
    // ).map((item: any) => item.toString());

    //previous values
    //abilities
    // const abilityPreviousIds = Object.keys(
    //   previousAbilityGangwar
    // ).map((item: any) => item.toString());
    // const abilityPreviousValues = Object.values(
    //   previousAbilityGangwar
    // ).map((item: any) => item.toString());

    // console.log(
    //   "present values",
    //   abilitypresentIds,
    //   abilityPresentValues,
    //   abilityPreviousIds,
    //   abilityPreviousValues
    // );

    // const prevAbilities = convertTwoArrayToObject(
    //   abilityPreviousIds,
    //   abilityPreviousValues
    // );
    // const presAbilities = convertTwoArrayToObject(
    //   abilitypresentIds,
    //   abilityPresentValues
    // );

    // const abilitiesLockUnlock = lockUnlockLoadouts(
    //   prevAbilities,
    //   presAbilities
    // );

    // const unLockAbilitiesId = abilitiesLockUnlock[0];
    // const unLockAbilitiesValue = abilitiesLockUnlock[1];
    // const lockAbilitiesId = abilitiesLockUnlock[2];
    // const lockAbilitiesValues = abilitiesLockUnlock[3];

    // const params = {
    //   simulationId: id?.toString(),
    //   nftId: "",
    //   newAbilityIds: abilitypresentIds,
    //   newAbilityValues: abilityPresentValues,
    //   allAbilityRemoved: abilityPresentValues.length === 0 ? true : false,
    // };

    const parameter = {
      simulationId: id?.toString(),
      nftId: userStatsDetails?.nftId.toString(),
      abilityIds: abilityToAddIds,
      abilityValues: abilityToAddValues,
    };

    const res: any = await addAbilityTokens({
      address: walletAddress,
      params: parameter,
      ledger: {
        isLedger,
        ledgerPath,
        ledgerTransport,
      },
    });
    if (isLedger) {
      if (res?.name === "TransportStatusError") {
        console.log("Ledger cancelled!!!");
      } else if (res) {
        getTxResultWithTimer(res as string);
      } else {
        console.log("Error on ledger");
      }
    }
  };

  return (
    <div className="sneakMovesListWrapper ">
      <div className="sneakMoveHeading">
        <h1 className="px-4">Edit Sneak Moves:</h1>
      </div>
      <div>
        <div className="warEditSneakMoves flex gap-6 p-3">
          {userRemainingAbilities.map((move: any, index: number) => {
            return (
              <div
                // onDoubleClick={() => {
                //   if (move?.type?.toLowerCase() === "active")
                //     setUserRemainingAbilities((prev: any) => {
                //       let array = prev.filter(
                //         (_: any, ind: number) => ind !== index
                //       );
                //       return array;
                //     });
                // }}
                // onClick={() => {
                //   console.log("MOVES", move, move?.type?.toLowerCase());
                //   if (move?.type?.toLowerCase() === "active")
                //     setSelectedSlot((prev: number) => {
                //       if (prev === index) {
                //         return -1;
                //       } else {
                //         return index;
                //       }
                //     });
                // }}

                className={`warEditSlotOne flex flex-col gap-1 ${move.status ===
                  "PENDING" && "disable"}`}
                id={`sneak_${index}`}
              >
                <p className="uppercase">slot {index + 1} </p>
                <div
                  className={` relative loadoutBackgroundActiveBorderSneakMove
                   sneakMoveSlotWar cursor-pointer`}
                >
                  {move.type === "PASSIVE" && (
                    <div className="flex items-center justify-center passiveSneakMoveOverlay  uppercase">
                      <span className=" text-center">used</span>
                    </div>
                  )}
                  {move.status === "PENDING" && (
                    <div className="flex items-center justify-center passiveSneakMoveOverlay  uppercase">
                      <span className=" text-center text-xs">Pending</span>
                    </div>
                  )}
                  <ImageLoader
                    src={`${REACT_APP_IMAGE_BASE_URL}/sneak_moves_images/${
                      move?.abilityId ? move?.abilityId : move?.id
                    }.png`}
                    alt="sneak moves"
                    className="p-1"
                  />
                </div>
              </div>
            );
          })}

          {userRemainingAbilities.length < 3 &&
            userNewAbilities.map((move: any, index: number) => {
              return (
                <div
                  onClick={() => {
                    if (move?.type?.toLowerCase() === "active")
                      setSelectedSlot(index);
                  }}
                  className="warEditSlotOne relative flex flex-col gap-1"
                  id={`sneak_${index}`}
                >
                  <p className="uppercase">
                    slot {userRemainingAbilities.length + index + 1}
                  </p>
                  <div
                    className={`${
                      index === selectedSlot
                        ? "sneakMoveBgBorder"
                        : "loadoutBackgroundActiveBorderSneakMove"
                    } sneakMoveSlotWar cursor-pointer`}
                  >
                    <ImageLoader
                      src={`${REACT_APP_IMAGE_BASE_URL}/sneak_moves_images/${
                        move?.abilityId ? move?.abilityId : move?.id
                      }.png`}
                      alt="sneak moves"
                      className="p-1"
                    />
                  </div>
                  <div>
                    <div
                      onClick={() => {
                        if (move?.type?.toLowerCase() === "active") {
                          setUserNewAbilities((prev: any) => {
                            let array = prev.filter(
                              (_: any, ind: number) => ind !== index
                            );
                            return array;
                          });
                          setAbilityNumberCount((prev: any) => ({
                            ...prev,
                            [move?.id]: getAbilityNumberCount[move?.id] + 1,
                          }));
                        }
                      }}
                      className="cursor-pointer animate__animated  animate__fadeIn sneakMoveAddupOverlay flex items-center justify-center  uppercase"
                    >
                      <span className="ml-2">
                        <RiDeleteBin6Line
                          className="removeSneakMove"
                          size={22}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
          {Array.from(
            {
              length:
                3 - userRemainingAbilities.length - userNewAbilities.length,
            },
            (_, i) => {
              return (
                <div
                  onClick={() => {
                    setSelectedSlot(userNewAbilities.length + i);
                  }}
                  className="warEditSlotOne flex flex-col gap-1"
                >
                  <p className="uppercase">
                    Slot{" "}
                    {userRemainingAbilities.length +
                      userNewAbilities.length +
                      i +
                      1}
                  </p>
                  <div
                    className={`${
                      //   userRemainingAbilities.length +
                      userNewAbilities.length + i === selectedSlot
                        ? "sneakMoveBgBorder"
                        : "loadoutBackgroundActiveBorderSneakMove"
                    } sneakMoveSlotWar cursor-pointer`}
                  >
                    <ImageLoader
                      src={ABILITY}
                      alt="sneak moves"
                      className="p-1"
                    />
                  </div>
                </div>
              );
            }
          )}
        </div>
        <div className="swapSneakWar h-[75px] text-left p-3 flex items-center justify-between">
          <p>Active Sneak Moves:</p>
          <div
            className={`animate__animated${
              userNewAbilities.length > 0
                ? " animate__fadeInRight"
                : "animate__fadeOutRight"
            }`}
          >
            {userNewAbilities.length > 0 && (
              <PrimaryButton
                onClick={() => {
                  updateSneakmove();
                }}
                width="160px"
                height="40px"
                buttonName="continue"
              />
            )}
          </div>
        </div>
        {userAbilityDetailsWithSeperation.active.length > 0 ? (
          <div className="sneakMovesWarPageList px-4  flex gap-4 flex-wrap">
            {userAbilityDetailsWithSeperation.active.map((ability, index) => {
              const sneakMoveName =
                ability?.name?.split(" ").length > 1
                  ? ability?.name?.replace(/\s/g, "_")
                  : ability?.name;
              const tooltipId = `${sneakMoveName}_${Math.random() * 200}`;
              if (getAbilityNumberCount[ability?.id] <= 0) return;
              return (
                <>
                  <div id={`${tooltipId}`}>
                    <EquipmentPlainCard
                      height="50px"
                      width="56px"
                      equipmentImages={ability?.image}
                      // className={
                      //   slot === "item" &&
                      //   (loadoutItemDetails as IItems)?.id === item.id
                      //     ? "setColor "
                      //     : "setGrey "
                      // }
                      //   doubleClick={() => {}}
                      id={`itemId_${ability.id}`}
                      equipmentNumber={getAbilityNumberCount[ability.id]}
                      // handleClick={() => {
                      //   if ((loadoutItemDetails as IItems)?.id !== item.id) {
                      //     setLoadoutItemDetails(item);
                      //     setAnimationToggle(!animationToggle);
                      //   }
                      // }}
                      handleClick={() => {
                        if (userRemainingAbilities.length < 3) {
                          swapSneakmove(ability);
                        }
                      }}
                      key={`${index}_${ability.name}`}
                    />
                  </div>
                  <SneakMoves
                    key={`sneakmoves_${Math.random() * 100000}`}
                    place="bottom"
                    details={ability}
                    tooltipId={tooltipId}
                  />
                </>
              );
            })}
          </div>
        ) : (
          //for no available sneakmoves
          <div className="sneakMovesWarPageListEmpty flex-col p-4 min-h-[300px] flex justify-center items-center gap-4 flex-wrap">
            <ImageLoader
              width="120px"
              src={ABILITY}
              alt="sneak moves"
              className="p-1"
            />
            <p> No Sneak Move Available</p>
          </div>
          //for passive abilities
          // <div className="sneakMovesWarPageListPassive flex-col p-4 min-h-[300px] flex justify-center items-center gap-4 flex-wrap">
          //   <AiFillLock className="mb-[-20px]" size={100} />
          //   <p className="">You cannot change passive abilities</p>
          // </div>
        )}
      </div>
    </div>
  );
};

export default SneakMoveDetails;
