import { memo, useEffect, useRef, useState } from "react";
import {
    AiOutlineDoubleRight,
    AiOutlineRight,
    AiOutlineDoubleLeft,
    AiOutlineLeft,
} from "react-icons/ai";
import moment from "moment";
import { IInterval, ILeaderboardData } from "../../interfaces/leaderboard.interface";
import Popup from "./Modal";
import { useLeaderboardApi } from "@pages/leaderboard/hooks/useLeaderboardApi";
import LeaderboardTable from "../LeaderboardTable";
import LoadingAnimation from "@assets/images/loading/loading.gif";
import { getNFTDetails } from "@api/api";
import { useLeaderboardContext } from "@pages/leaderboard/context/LeaderboardContext";
import EmptyLeaderboard from "../EmptyLeaderboard";
import {
    getExtremeIntervalDates,
    isLastPeriod,
    extractMonthDay,
    getLastIntervalTs,
    formatLeaderboardNum,
    LEADERBOARD_COLS_MAP,
    STATS_PER_GAME_MAPPING,
} from "../../utils.leaderboard";

const WINNERS_COUNT = 3;
const WEEK_MS = 7 * 24 * 60 * 60 * 1000;
const MONTH_MS = 30 * 24 * 60 * 60 * 1000;
const SEASONAL = 4 * 30 * 24 * 60 * 60 * 1000;

const intervalTimeMsMap: Record<IInterval, number> = {
    monthly: MONTH_MS,
    weekly: WEEK_MS,
    all: 0,
    seasonal: SEASONAL,
};

export default memo(function LastWinners({
    intervalFilter,
    seasonTimestamp,
}: {
    intervalFilter: IInterval;
    seasonTimestamp: number;
}) {
    const [leaderboardData, setLeaderboardData] = useState<ILeaderboardData[] | null>(null);
    const [timestamp, setTimestamp] = useState(-1);
    const [minTimestamp, setMinTimestamp] = useState(0); // min timestamp supported by api
    const prevTimestamp = useRef(timestamp);

    const { setShowLastWinners, statsFilter } = useLeaderboardContext();
    const [leaderboardColumns, setLeaderboardColumns] = useState([
        "rank",
        "gangstabet",
        "wins",
        "Games Played",
        "Win %",
    ]);
    const [loading, setLoading] = useState(true);
    const [displayDate, setDisplayDate] = useState("");
    const [hasNextData, setHasNextData] = useState(false);
    const [hasPrevData, setHasPrevData] = useState(true);
    const { data: leaderboardApiData, error: leaderboardApiError } = useLeaderboardApi(
        statsFilter,
        intervalFilter,
        intervalFilter === "seasonal" ? seasonTimestamp : timestamp,
        WINNERS_COUNT
    );

    const MAP_STAT_FILTER = {
        wins: "Wins",
        gamesPlayed: "Participants",
        kills: "Kills",
        damageDealt: "Damage",
        heal: "Healer",
    };

    useEffect(() => {
        setLeaderboardColumns((prevData) => {
            prevData[2] = LEADERBOARD_COLS_MAP[statsFilter];
            if (statsFilter === "gamesPlayed") {
                return prevData.slice(0, 3);
            }
            prevData[3] = "Games Played";
            prevData[4] = STATS_PER_GAME_MAPPING[statsFilter] ?? "";
            return prevData;
        });
    }, [statsFilter]);

    useEffect(() => {
        const [start, end] = getExtremeIntervalDates(timestamp, intervalFilter);
        if (intervalFilter === "seasonal") {
            console.log({ leaderboardApiData })
            // todo need to change asap before second season
            setDisplayDate(
                `Season ${leaderboardApiData?.seasonalInfo?.currentSeasonRunning ?? ".."
                } `
            );
        } else {
            if (isLastPeriod(timestamp, intervalFilter)) {
                setDisplayDate(`Last ${intervalFilter.slice(0, -2)} `);
            } else {
                setDisplayDate(`${extractMonthDay(start)} - ${extractMonthDay(end)} `);
            }
        }

        if (intervalFilter === "seasonal") {
            if (leaderboardApiData?.seasonalInfo?.timestamps?.lastSeasonTimeStamp) {
                // setHasPrevData(true);
            } else {
                setHasPrevData(false);
            }
            // todo: next season data is also showing need to change update below for temp false is set
            if (leaderboardApiData?.seasonalInfo?.timestamps?.nextSeasonTimeStamp) {
                // setHasNextData(true);
                setHasNextData(false);
            } else {
                setHasNextData(false);
            }
            prevTimestamp.current = timestamp;
        } else {
            if (prevTimestamp.current > 0) {
                // going towards past
                moment
                    .utc(timestamp)
                    .utc()
                    .subtract(intervalTimeMsMap[intervalFilter])
                    .valueOf() <= minTimestamp
                    ? setHasPrevData(false)
                    : setHasPrevData(true);
                if (prevTimestamp.current > timestamp) {
                    setHasNextData(true);
                }
                // going towards present
                if (prevTimestamp.current < timestamp) {
                    isLastPeriod(timestamp, intervalFilter)
                        ? setHasNextData(false)
                        : setHasNextData(true);
                    setHasPrevData(true);
                }
            }
            prevTimestamp.current = timestamp;
        }
    }, [timestamp, minTimestamp]);

    useEffect(() => {
        if (leaderboardApiError) {
            setHasPrevData(false);
        } else if (timestamp - intervalTimeMsMap[intervalFilter] > minTimestamp) {
            setHasPrevData(true);
        }
    }, [leaderboardApiError]);

    // useEffect(() => {
    //     if (intervalFilter === "seasonal" && leaderboardApiData) {
    //         setTimestamp(leaderboardApiData?.seasonalInfo?.timestamps?.lastSeasonTimeStamp);
    //     }
    // }, [intervalFilter, leaderboardApiData]);

    useEffect(() => {
        setTimestamp(getLastIntervalTs(intervalFilter));
    }, [intervalFilter]);

    useEffect(() => {
        if (leaderboardApiData?.leaderboard) {
            setMinTimestamp(leaderboardApiData.initialDate);
            const { leaderboard } = leaderboardApiData;
            setLoading(true);
            if (Array.isArray(leaderboard) && leaderboard.length === 0) {
                setLoading(false);
                setLeaderboardData([]);
                return;
            }
            const tempData = leaderboard.map(async (details: any, index: number) => {
                const {
                    data: {
                        token_name: gbetName,
                        token_uri: gbetImg,
                        class_type: gbetClass,
                        statistics: { current_stats: currentLevel },
                    },
                } = await getNFTDetails(details.nftId);
                return {
                    rank: index + 1,
                    id: details.nftId,
                    gbetImg,
                    gbetName,
                    gbetClass,
                    currentLevel,
                    isUserNft: details.isUserNft,
                    gamesPlayed: details.gamesPlayed,
                    [statsFilter]: details[statsFilter],
                    [`${statsFilter}Percent`]:
                        details.gamesPlayed > 0
                            ? statsFilter === "wins"
                                ? formatLeaderboardNum(
                                    (details[statsFilter] / details.gamesPlayed) * 100
                                )
                                : formatLeaderboardNum(details[statsFilter] / details.gamesPlayed)
                            : "",
                    rewards: "",
                };
            });

            Promise.all(tempData).then((res: unknown) => {
                setLoading(false);
                setLeaderboardData(res as ILeaderboardData[]);
            });
        }
    }, [leaderboardApiData]);

    const handleModalClose = () => {
        setShowLastWinners(false);
    };

    const updateTimestamp = (steps: number) => {
        if (intervalFilter === "seasonal") {
            if (steps === -2) {
                setTimestamp(leaderboardApiData?.initialDate);
            } else if (steps === -1) {
                console.log(leaderboardApiData?.seasonalInfo?.timestamps);
                setTimestamp((prev) => {
                    console.log({ leaderboardApiData: leaderboardApiData?.seasonalInfo?.timestamps?.lastSeasonTimeStamp })
                    if (leaderboardApiData?.seasonalInfo?.timestamps?.lastSeasonTimeStamp) {
                        return leaderboardApiData?.seasonalInfo?.timestamps?.lastSeasonTimeStamp;
                    } else {
                        return prev;
                    }
                });
            } else if (steps === 1) {
                console.log(leaderboardApiData?.seasonalInfo?.timestamps?.nextSeasonTimeStamp);
                setTimestamp((prev) => {
                    if (leaderboardApiData?.seasonalInfo?.timestamps?.nextSeasonTimeStamp) {
                        return leaderboardApiData?.seasonalInfo?.timestamps?.nextSeasonTimeStamp;
                    } else {
                        return prev;
                    }
                });
            } else if (steps === 2) {
                setTimestamp(Date.now());
            }
        } else {
            if (steps === -1) {
                setTimestamp((prev) => {
                    const newTime = moment(prev)
                        .utc()
                        .subtract(intervalTimeMsMap[intervalFilter]);
                    return isValidTimestamp(newTime) ? newTime.valueOf() : prev;
                });
            } else if (steps === -2) {
                setTimestamp(minTimestamp + 60 * 60 * 1000);
            } else if (steps === 1) {
                setTimestamp((prev) => {
                    const newTime = moment(prev)
                        .utc()
                        .add(intervalTimeMsMap[intervalFilter]);
                    return isValidTimestamp(newTime) ? newTime.valueOf() : prev;
                });
            } else if (steps === 2) {
                setTimestamp(getLastIntervalTs(intervalFilter));
            }
        }
    };

    const isValidTimestamp = (time: moment.Moment) => {
        const timestamp = time.valueOf();
        // time can't be before minTimestamp
        if (timestamp < minTimestamp) {
            return false;
        }
        // time can't be after the start of current interval
        const [intervalStartTs] = getExtremeIntervalDates(Date.now(), intervalFilter);
        if (timestamp > intervalStartTs) {
            return false;
        }
        return true;
    };

    return (
        <Popup show={true} onClose={() => handleModalClose()} variant="medium">
            <div className="last-winners-header">
                <h3>Top {MAP_STAT_FILTER?.[statsFilter]}</h3>
                <div>
                    <div
                        className={["navigators", !hasPrevData ? "navigators-disabled" : ""].join(
                            " "
                        )}
                    >
                        <AiOutlineDoubleLeft size={20} onClick={() => updateTimestamp(-2)} />
                        <AiOutlineLeft size={20} onClick={() => updateTimestamp(-1)} />
                    </div>
                    <h5 style={{ color: "#fff", lineHeight: "32px" }}>{displayDate}</h5>
                    <div
                        className={["navigators", !hasNextData ? "navigators-disabled" : ""].join(
                            " "
                        )}
                    >
                        <AiOutlineRight size={20} onClick={() => updateTimestamp(1)} />
                        <AiOutlineDoubleRight size={20} onClick={() => updateTimestamp(2)} />
                    </div>
                </div>
            </div>
            <div className="past-lb-table-container">
                {leaderboardApiError != "" ? (
                    leaderboardApiError.toLowerCase().includes("invalid date") ? (
                        <EmptyLeaderboard />
                    ) : (
                        <h3>Error loading leaderboard data</h3>
                    )
                ) : loading ? (
                    <img
                        src={LoadingAnimation}
                        alt="Leaderboard data loading..."
                        height="auto"
                        width={270}
                    />
                ) : (
                    leaderboardData &&
                    (leaderboardData.length > 0 ? (
                        <LeaderboardTable
                            selectedStats={statsFilter}
                            data={leaderboardData}
                            columns={leaderboardColumns}
                        />
                    ) : (
                        <EmptyLeaderboard />
                    ))
                )}
            </div>
        </Popup>
    );
});
