"use strict";
/*
 * Copyright 2021 ICON Foundation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
const Hexadecimal_1 = require("../Hexadecimal");
const Converter_1 = require("../Converter");
const assignParams = (_this, params, data, 
// eslint-disable-next-line no-unused-vars
converter = (value) => value) => {
    const convertedParams = {};
    params.map((param) => {
        const value = data[param];
        if (value) {
            convertedParams[param] = converter(value);
        }
        return true;
    });
    Object.assign(_this, convertedParams);
};
class ConfirmedTransaction {
    constructor(data) {
        assignParams(this, ["timestamp"], data, Converter_1.toNumber);
        assignParams(this, ["value", "fee", "nid", "stepLimit", "nonce"], data, Converter_1.toBigNumber);
        assignParams(this, ["from", "to"], data, Hexadecimal_1.addHxPrefix);
        assignParams(this, ["signature", "dataType", "data"], data);
        this.version =
            data.version && (0, Converter_1.toBigNumber)(data.version).gte(3)
                ? (0, Converter_1.toBigNumber)(data.version)
                : (0, Converter_1.toBigNumber)(2);
        this.txHash = data.tx_hash
            ? (0, Hexadecimal_1.add0xPrefix)(data.tx_hash)
            : (0, Hexadecimal_1.add0xPrefix)(data.txHash);
    }
}
exports.default = ConfirmedTransaction;
