import { AiOutlineClose } from "react-icons/ai";
import PrimaryButton from "@components/buttons/primary.button";
import ImageLoader from "@misc/ImageLoader";
import { CRATE2, OPENING_CRATE } from "@constants/images";
import { useEffect, useRef, useState } from "react";
import { useDetailGlobalContext } from "@context/detailProvider";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { PLAY_CLAIM_SOUND } from "@constants/audio";
const keys = ["abilities", "items", "equipment", "crown", "icx"];

const LootClaimCard = ({ keyName, loots, onClick }: any) => {
    return (
        <div onClick={onClick} className="claimCardWrapper cursor-pointer">
            <h2 className="cardTitle capitalize mb-2">
                {keyName === "abilities"
                    ? "Sneak Moves"
                    : keyName === "icx"
                    ? "ICX"
                    : keyName === "crown"
                    ? "CROWN"
                    : keyName}
            </h2>
            <div className="flex gap-4 flex-wrap justify-center">
                {loots.map((loadout: any, index: number) => {
                    return (
                        <>
                            <div id={`${keyName}_${index}`} className="claimCards">
                                <div className="claimCard">
                                    <div className="claimCardImg">
                                        <ImageLoader
                                            className="p-5"
                                            alt="img"
                                            src={loadout.details.image}
                                        />
                                        <p className="countClaim">
                                            {keyName === "Icx"
                                                ? `${loadout.count} ICX`
                                                : keyName === "crown"
                                                ? `${loadout.count} CROWN`
                                                : `x${loadout.count}`}
                                        </p>
                                    </div>

                                    <p className="lootName text-center">
                                        {loadout.details.name === "icx"
                                            ? "ICX"
                                            : loadout.details.name === "Crown"
                                            ? "Bonus CROWN"
                                            : loadout.details.name}
                                    </p>
                                </div>
                            </div>
                            <Tooltip
                                anchorSelect={`#${keyName}_${index}`}
                                place="top"
                                delayShow={500}
                                style={{
                                    backgroundColor: "#192228",
                                    border: "1px solid #485c65",
                                    padding: "10px",
                                    zIndex: 99999,
                                    maxWidth: 350,
                                    opacity: 1,
                                }}
                            >
                                <div className="flex text-xm items-start gap-3">
                                    <p className="text-xm">{loadout.details.description}</p>
                                </div>
                            </Tooltip>
                        </>
                    );
                })}
            </div>
        </div>
    );
};

const LootClaimAnimation = ({ loadoutsReceived, setOpenCrate, setLootboxDetails, imgSrc }: any) => {
    PLAY_CLAIM_SOUND();
    const { reFetchDetails } = useDetailGlobalContext();
    const navigate = useNavigate();
    const [openReward, setOpenReward] = useState<boolean>(false);
    setTimeout(() => {
        setOpenReward(true);
    }, 4000);
    const [shadow, setShadow] = useState<boolean>(false);
    setTimeout(() => {
        setShadow(true);
    }, 1500);

    const randomInt = Math.floor(Math.random() * 1000000) + 1;

    return (
        <>
            <div className="overlayScreen">
                <div className="flex items-center justify-center h-full ">
                    <img
                        key={`${Math.random()}_key${randomInt}`}
                        src={imgSrc}
                        alt="animation animate__animated animate__bounceInDown"
                        className={"min-h-[150px] " + (shadow ? " imageShadow" : " ")}
                    />
                </div>
            </div>
            {openReward && (
                <div className="lootClaimAnimationWrapper">
                    <div className="lootClaimMain">
                        <div className="lootClaimOverlay">
                            <div className="lootClaimContainer ">
                                <div className="claimInfo">
                                    <h1 className="claimHeadingText">Lootcrate Unlocked</h1>
                                    <p className="claimParagraphText">
                                        Your rewards have been stashed in the inventory.
                                    </p>
                                </div>
                                <div className="flex gap-5 flex-wrap items-center justify-center animate__animated  animate__zoomIn animate__fadeIn animate__duration-2s">
                                    {keys.map((keyName: any, index: number) => {
                                        if (loadoutsReceived[keyName].length > 0) {
                                            return (
                                                <LootClaimCard
                                                    onClick={() => {
                                                        if (
                                                            keyName !== "crown" ||
                                                            keyName !== "icx"
                                                        ) {
                                                            reFetchDetails();
                                                            navigate(
                                                                `/inventory/${
                                                                    keyName === "abilities"
                                                                        ? "sneak-moves"
                                                                        : keyName
                                                                }`
                                                            );
                                                        }
                                                    }}
                                                    keyName={keyName}
                                                    loots={loadoutsReceived[keyName]}
                                                />
                                            );
                                        } else {
                                            return null;
                                        }
                                    })}
                                </div>

                                <PrimaryButton
                                    onClick={() => {
                                        setOpenReward(false);
                                        setOpenCrate();
                                        reFetchDetails();
                                        setLootboxDetails((prev: any) => ({
                                            ...prev,
                                            count: prev.count - 1,
                                        }));
                                    }}
                                    buttonName="Okay, got it"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default LootClaimAnimation;
