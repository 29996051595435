import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import EmeraldCityMapLeaflet from "@components/EmeraldCityMapLeaflet.component";
import Home from "@pages/home";
import Toaster from "@components/toast/Toaster.component";
import ToasterService from "@components/toast/ToasterService.component";
import WarPage from "@components/warPage/warPage.component";
import Header from "@components/header/header.component";
import Inventory from "@pages/inventory/weapons";
import Abilities from "@pages/inventory/abilities";
import Items from "@pages/inventory/items";
import LootCrates from "@pages/inventory/lootCrate";
import "animate.css";
import PostGame from "@pages/postGame/Index";
import WarOverview from "@pages/warOverview/WarOverview";
import NewWarScreen from "@pages/warScreen/newWarScreen";
import Gangstabet from "@pages/gangstabet";
import { useSelector } from "react-redux";
import GameLobby from "@components/lobbyScreen/gameLobby.component";
import { useDetailGlobalContext } from "@context/detailProvider";
import { signInAnonymouslyAndSetSignedIn } from "@services/firebase.service";
import PostGames from "@pages/postGame/postGame";
import Overview from "@pages/warOverview/overview";
import GameReward from "@pages/postGame/gameReward";
import Connections from "@components/header/connections";
import { BgSound } from "@components/sound/BgSound";
import Leaderboard from "@pages/leaderboard";
import Test from "@components/gangstabet/test";
import RentalMarket from "@pages/market/rental.page";

function HeaderWithConditionalRendering() {
  const location = useLocation();
  if (
    location.pathname.includes("/war-overview") ||
    location.pathname.includes("/battle") ||
    location.pathname.includes("post-game") ||
    location.pathname.includes("/log") ||
    location.pathname.includes("/postGame") ||
    location.pathname.includes("/overview")
    // ||
    // location.pathname.includes("/reward")
  ) {
    return null; // hide the header in WarPage
  } else {
    return <Header />; // show the header in all other pages
  }
}
function App() {
  if (process.env.REACT_APP_ENV === "production") {
    console.log = () => { };
    console.error = () => { };
    console.debug = () => { };
    console.warn = () => { };
  }
  const toasterList = useRef([]);

  const { setFirebaseSignedIn } = useDetailGlobalContext();

  useLayoutEffect(() => {
    signInAnonymouslyAndSetSignedIn(setFirebaseSignedIn);
  }, []);

  useEffect(() => {
    ToasterService.get().subscribe((data: any) => {
      toasterList.current = data;
    });
  });
  return (
    <>
      <BrowserRouter>
        <HeaderWithConditionalRendering />
        <BgSound />
        <div className="mx-auto">
          <Routes>
            {/* <Route path="/components" element={<Home />} /> */}

            <Route path="/" element={<Navigate to="/map" replace />} />
            <Route path="/war" element={<WarPage />} />
            <Route path="/inventory" element={<Inventory />} />
            <Route path="/map" element={<EmeraldCityMapLeaflet />} />
            <Route path="/leaderboard/*" element={<Leaderboard />} />
            <Route
              path="/inventory"
              element={<Navigate to="/inventory/inventory" replace />}
            />
            <Route path="/inventory/equipment" element={<Inventory />} />
            <Route path="/inventory/sneak-moves" element={<Abilities />} />
            <Route path="/inventory/items" element={<Items />} />
            <Route path="/inventory/loot-crates" element={<LootCrates />} />
            <Route path="*" element={<Navigate to="/map" replace />} />
            {/* <Route path="/battle/:id" element={<NewWarScreen />} /> */}
            {/* <Route path="/post-game" element={<PostGame />} /> */}
            {/* <Route path="/war-overview/:id" element={<WarOverview />} /> */}
            <Route path="/profile/*" element={<Gangstabet />} />
            {/* <Route path="/loot" element={<LootClaimAnimation />} /> */}
            <Route path="/inventory/equipment" element={<Inventory />} />
            <Route path="/inventory/sneak-moves" element={<Abilities />} />
            <Route path="/inventory/items" element={<Items />} />
            <Route path="/inventory/loot-crates" element={<LootCrates />} />
            <Route path="/battle/:id" element={<NewWarScreen />} />
            {/* <Route path="/post-game" element={<PostGame />} /> */}
            {/* <Route path="/war-overview/:id" element={<WarOverview />} /> */}
            <Route path="/profile" element={<Gangstabet />} />
            <Route path="/lobby" element={<GameLobby />} />
            <Route path="/battle/rewatch/:id" element={<NewWarScreen />} />
            {/* <Route path="/postGame" element={<PostGames />} /> */}
            <Route path="/overview/:id" element={<Overview />} />
            <Route path="/reward" element={<GameReward />} />
            <Route path="/test" element={<Test />} />
            <Route path="/rental" element={<RentalMarket />} />
          </Routes>
          <Toaster
            position="top-right"
            autoDelete
            toastList={toasterList.current}
          />
        </div>
        <Connections />
      </BrowserRouter>
    </>
  );
}

export default App;
