import React from "react";

const ColoredCrateCard = ({
  image,
  width = "100%",
  height,
  bgColor,
  number,
  className,
  crateWidth,
  crateHeight,
  maxWidth = "100%",
}: any) => {
  return (
    <div
      style={{ width: crateWidth, height: crateHeight }}
      className={`stylishCard green  ${bgColor}  ${className}`}
    >
      <img
        src={image}
        style={{ width, height, maxWidth }}
        className="max-h-[200px] object-contain"
      />
      {number && (
        <div className="lootCrateNumber">
          <span className="text-sm font-normal lowercase">x</span>
          {number}
        </div>
      )}
    </div>
  );
};

export default ColoredCrateCard;
