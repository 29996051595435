import { DetailsProvider } from "./detailProvider";
import { NFTInformation } from "./nftInformation";
import { WarLists } from "./war";
import { History } from "./history";
import { NFTInformationProvider } from "./nftInfoProvider";
const ContextIndex = ({ children }: any) => {
  return (
    <>
      <DetailsProvider>
        <NFTInformation>
          <NFTInformationProvider>
            <WarLists>
              <History>{children}</History>
            </WarLists>
          </NFTInformationProvider>
        </NFTInformation>
      </DetailsProvider>
    </>
  );
};

export { ContextIndex };
