import { FC, useState } from "react";

import Modal from "@components/modal/modal.component";
import PrimaryButton from "../../buttons/primary.button";
import { CROWN, ICX } from "@constants/images";
import { deListRent, reverseDeList } from "@services/icon.service";
import { useDetailGlobalContext } from "@context/detailProvider";
import { IRentalModal, AsyncState } from "./rental.interface";
import { useNFTDetailGlobalContext } from "@context/nftInformation";
import { NFT_RENT_STATUS, NFT_RENT_TYPE } from "@constants/nft";
import RentalWrapper from "./RentalWrapper.component";
import { useNFTInformation } from "@context/nftInfoProvider";
import { useSelector } from "react-redux";
import { RootState } from "@redux/store";
import { useTransactionValidate } from "@hooks/transactionResult";

const RemoveModal: FC<IRentalModal> = ({
  selectedChar,
  isVisible,
  setRentalModal,
  setSelectedChar
}) => {
  const { isLedger } = useSelector((state: RootState) => state.wallet);
  const { getTxResultWithTimer } = useTransactionValidate({ onHandle: () => { } });
  const [asyncState, setAsyncState] = useState(AsyncState.IDLE);
  const {
    setTransactionLoading,
    reFetchDetails,
    setRefetchData,
    reFetchChangeData,
  } = useDetailGlobalContext();
  const {
    setNftData,
    setRentalIndex,
    setCurrentBatchIndex,
    setChangeDataToggle,
    changeDataToggle

  } = useNFTDetailGlobalContext();

  const { reRenderCollectionToggler, setRerenderCollectionToggler } = useNFTInformation();

  const onAction = async () => {
    setAsyncState(AsyncState.LOADING);
    let result: any;
    if (selectedChar.rental.deListed) {
      result = await reverseDeList(selectedChar?.gangstaBetDetails?.nftId.toString());
    } else {
      result = await deListRent(selectedChar?.gangstaBetDetails?.nftId.toString());
    }

    if (isLedger) {
      if (result?.name === "TransportStatusError") {
        console.log("Ledger cancelled!!!");
      } else if (result) {
        getTxResultWithTimer(result as string);
      } else {
        console.log("Error on ledger");
      }
    }
  };

  const onContinue = () => {
    setRentalModal(null);
    if (asyncState === AsyncState.SUCCESS) {
      setSelectedChar(null);
      //refetch all NFT details (rent + inventory)
      setRerenderCollectionToggler(!reRenderCollectionToggler);
      setRentalIndex(null);
      setNftData([]);
      setCurrentBatchIndex(0);
      setRefetchData(!reFetchChangeData);
      setChangeDataToggle(!changeDataToggle);
      reFetchDetails();
    }
  };

  if (asyncState === AsyncState.CANCELED) {
    setRentalModal(null);
    setTransactionLoading((prev) => ({
      ...prev,
      state: "idle",
      status: false,
    }));
    return null;
  }

  return (
    <RentalWrapper
      type={selectedChar?.rental.status === NFT_RENT_STATUS.EXPIRING ? "RELIST" : "REMOVE"}
      asyncState={asyncState}
      setAsyncState={setAsyncState}
      setRentalModal={setRentalModal}
    >
      <Modal
        isVisible={isVisible}
        onModalClose={onContinue}
        glow={asyncState === AsyncState.SUCCESS ? "success" : ""}
        modalTitle={
          asyncState === AsyncState.SUCCESS
            ? selectedChar?.rental.status === NFT_RENT_STATUS.EXPIRING ? "GangstaBet Relisted" : "GangstaBet Removed"
            : selectedChar?.rental.status === NFT_RENT_STATUS.EXPIRING ? "Relist in market" : "Remove from rental market"
        }
        className=" rental-modal-outer-wrapper flex flex-col justify-center items-center"
      >
        <p className="description-text text-center">
          {asyncState === AsyncState.SUCCESS ? (
            <>
              {selectedChar?.rental.status === NFT_RENT_STATUS.EXPIRING ? "Your GangstaBet has been relisted in the rental market." : "Your GangstaBet has been removed from the rental market."}

              <div className="success-description-text py-4 mt-6 mb-8">
                GangstaBet name: <span>{selectedChar?.gangstaBetDetails?.nftName}</span>
              </div>
            </>
          ) : (
            <>
              Are you sure you want to {selectedChar?.rental.status === NFT_RENT_STATUS.EXPIRING ? <>relist this GangstaBet <br /> in the{" "}</> : <>delist this GangstaBet <br /> from the {" "}</>}
              rental marketplace?
              <div className="success-description-text py-4 mt-6 mb-8">
                GangstaBet name: <span>{selectedChar?.gangstaBetDetails?.nftName}</span>
                <br />
                <p className="flex gap-1.5 justify-center items-end">
                  Rent rate:
                  <span className="inline-flex gap-2 items-end">
                    <img
                      src={
                        selectedChar?.rental.rentType ===
                          NFT_RENT_TYPE.CROWN_CURRENCY
                          ? CROWN
                          : ICX
                      }
                      alt="currency"
                      className="w-6 h-6"
                    />
                    {selectedChar.rental.status === NFT_RENT_STATUS.AVAILABLE_FOR_RENT ? selectedChar?.rental.listedRentFee : selectedChar?.rental.rentFee} per day
                  </span>
                </p>
                {/* Date Put on Rent:{" "}
                <span>
                  {new Date(
                    (selectedChar?.rental.listedTimestamp || 0) / 1000
                  ).toLocaleString("en-US", {
                    month: "short",
                    year: "numeric",
                    day: "numeric",
                  })}
                </span> */}
              </div>
            </>
          )}
        </p>
        <div className="flex flex-col items-center">
          {asyncState === AsyncState.IDLE && (
            <PrimaryButton
              onClick={onAction}
              buttonName={selectedChar?.rental.status === NFT_RENT_STATUS.EXPIRING ? "Relist In Market" : "Remove from market"}
              width="250px"
            />
          )}
          {asyncState === AsyncState.SUCCESS ? (
            <PrimaryButton onClick={onContinue} buttonName="Okay" />
          ) : (
            <button
              onClick={() => setRentalModal(null)}
              className="rent-price-dropdown !text-center !py-[0.5em] outline-none"
            >
              Not Now
            </button>
          )}
        </div>
      </Modal>
    </RentalWrapper>
  );
};

export default RemoveModal;
