import { IEquipment } from "src/core/interfaces/card.interface";
import ImageLoader from "@misc/ImageLoader";
const EquipmentStylizedCard = ({
  equipmentNumber,
  equipmentImages,
  height,
  width,
  className,
}: IEquipment) => {
  return (
    <div
      style={{ height, width }}
      className={`equipmentStylizedWrapper ${className}`}
    >
      <div className="equipmentStylizedComponent">
        <div className="equipment">
          {/* <ImageLoader src={equipmentImages} alt="shield" /> */}
          <img src={equipmentImages} alt="shield" />
          <div className="equipmentNumber">{equipmentNumber}</div>
        </div>
      </div>
      <div className="backgroundStylizer"></div>
    </div>
  );
};

export default EquipmentStylizedCard;
