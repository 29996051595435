import StylizedButton from "@components/buttons/stylized.button";
import Modal from "@components/modal/modal.component";
interface INotEnoughCrown {
  isVisible: boolean;
  setIsVisible: any;
  fee: string | number;
}
const OutOfHospitalizationWarning = ({
  isVisible,
  setIsVisible,
  fee,
}: INotEnoughCrown) => {
  return (
    <Modal
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      modalTitle={`Not Enough CROWN`}
    >
      <div className="crownLimitWrapper">
        <div className="playerNumber">
          Recovery Fees:
          <span className=" font-extrabold">
            {" "}
            {Math.ceil(Number(fee))} CROWN.
          </span>
        </div>
        <hr className="horizontal-seperator" />
        <div className="crownLimitText">
          <p className="crownLimitTopText">
            Unfortunately, you do not have enough crown to recover your
            GangstaBet quickly.
          </p>
          <p className="crownLimitInfo">
            You can try waiting out the hospital time or buy more crown.
          </p>
        </div>
        <hr className="horizontal-seperator" />
        <div className="crownLimitButton">
          <StylizedButton
            onClick={() => {
              setIsVisible(false);
            }}
            buttonName="Okay"
          />
        </div>
      </div>
    </Modal>
  );
};

export default OutOfHospitalizationWarning;
