import EmeraldCityMap from "@components/leafletMap/EmeraldCityMap";
import Seo from "./seo";

function EmeraldCityMapLeaflet() {
  return (
    <div className="emeraldcity-leaflet-container">
      <Seo title="Map | Gangwars" description="Find ongoing Gangwar events on the Emerald City map" />
      <div className="emeraldcity-leaflet-wrapper minWidthContainer">
        <EmeraldCityMap />
      </div>
    </div>
  );
}

export default EmeraldCityMapLeaflet;
