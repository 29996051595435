import { CROWN } from "@constants/images";
import { AiOutlineClose } from "react-icons/ai";
import NFTCard from "@components/card/nftCard.component";
import { AiOutlinePlus } from "react-icons/ai";
import { useState, useEffect } from "react";
import GameTime from "./gameTime.component";
import { useDetailGlobalContext } from "@context/detailProvider";
import { formatNumber } from "@utils/formatNumber";
import { useSelector } from "react-redux";

interface ISkills {
  Strength: number;
  Accuracy: number;
  Mastery: number;
  Agility: number;
  Luck: number;
}

const SelectionScreen = ({
  children,
  isScreenClose,
  setScreenClose,
  titleText,
  closeContent,
  className,
}: any) => {
  // if (!isScreenClose) return null;
  const { userCrown, reFetchDetails } = useDetailGlobalContext();
  const { availableCrown } = useSelector((state: any) => state.wallet);
  return (
    <div className="popUpOverlay">
      <div className={`container selectCharacterWrapper ${className}`}>
        <div className="headingSelectCharacter">
          <h1 className="headingText">
            {titleText || "Select Your GangstaBet"}
          </h1>

          <div className="closerPart flex items-center justify-center">
            {closeContent ? (
              closeContent
            ) : (
              <>
                <div className="crownIconWithValue">
                  <img src={CROWN} alt="crown logo" />
                  <p className="crownVlaue">
                    {formatNumber(Number(userCrown.toFixed(2)))}
                  </p>
                </div>
              </>
            )}
            <div
              onClick={() => {
                setScreenClose(false);
                reFetchDetails();
              }}
            >
              <AiOutlineClose className="cursor-pointer" size={30} />
            </div>
          </div>
        </div>
        <div className="selectCharacterBody">{children}</div>
      </div>
    </div>
  );
};

export default SelectionScreen;
