import React from "react";
import { useTrail, a, } from "@react-spring/web";

interface IAnimatedEventNameProps {
    text: string;
    show: boolean;
}

const DURATION = 600;

const Trail: React.FC<{ show: boolean, children: any }> = ({ show, children }) => {
    const items = React.Children.toArray(children);
    const color = ["#a1a1a1", "#c66565"];
    const trail = useTrail(items.length, {
        config: { mass: 5, tension: 2000, friction: 200, duration: DURATION },
        from: { opacity: 0, x: 200, height: 0 },
        opacity: show ? 1 : 0,
        height: show ? 50 : 0,
        x: show ? 0 : -200,
    });

    return (
        <div className="trailsContainer">
            {trail.map(({ height, ...style }, index) => (
                <a.div key={index} className="trailsText" style={style}>
                    <a.div style={{ height, color: color[index % 2], textAlign: "center" }}>{items[index]}</a.div>
                </a.div>
            ))}
        </div>
    )
}

const AnimatedEventName = ({ text, show }: IAnimatedEventNameProps) => {
    return (
        <Trail show={show} key={text}>
            {text.split(" ").map((word) => (
                <span>{word}</span>
            ))}
        </Trail>
    );
}

export default React.memo(AnimatedEventName);