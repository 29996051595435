import { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import { formatGangstaBetInformation } from "@utils/rental/formatGb";
import { RENTAL_API_CALL } from "@type/rental.type";
import { useSelector } from "react-redux";
import { RootState } from "@redux/store";

interface PaginatedDataHook {
    data: any;
    loading: boolean;
    loaderRef: React.RefObject<HTMLDivElement>;
    fetchRentalMarketplaceInfo: () => void;
}

export const usePaginatedRentalMarket = (
    apiEndpoint: string,
    totalPages: number,
    apiCall: RENTAL_API_CALL,
    address: string
): PaginatedDataHook => {
    const [data, setData] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const loaderRef = useRef<HTMLDivElement>(null);
    const { isWalletConnected } = useSelector((state: RootState) => state.wallet);

    const rentalMarketplaceInfo = async () => {
        setLoading(true);
        try {
            const url = `${apiEndpoint}/${currentPage}`;
            const nftDetailedInfo = await axios.get(url);
            const rentalStructuredInfo = formatGangstaBetInformation(
                {
                    nftList: nftDetailedInfo.data.rentalList,
                },
                apiCall,
                address
            );
            if (totalPages >= currentPage && rentalStructuredInfo.length === 0) {
                setCurrentPage((prevPage) => prevPage + 1);
            }
            setData((prevData: any) => [...prevData, ...rentalStructuredInfo]);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        rentalMarketplaceInfo();
    }, [apiEndpoint, currentPage]);

    const fetchRentalMarketplaceInfo = useCallback(() => {
        setData([]);
        rentalMarketplaceInfo();
    }, [isWalletConnected]);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: "30px",
            threshold: 0.5,
        };

        const handleIntersection = (entries: IntersectionObserverEntry[]) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting && currentPage < totalPages && !loading) {
                    setCurrentPage((prevPage) => prevPage + 1);
                }
            });
        };

        const observer = new IntersectionObserver(handleIntersection, options);

        if (loaderRef.current) {
            observer.observe(loaderRef.current);
        }

        return () => {
            if (loaderRef.current) {
                observer.unobserve(loaderRef.current);
            }
        };
    }, [loaderRef.current, currentPage, totalPages, loading]);

    return { data, loading, loaderRef, fetchRentalMarketplaceInfo };
};
