import PrimaryButton from "@components/buttons/primary.button";
import React, { FC, Dispatch, SetStateAction } from "react";

interface IPutForRent {
  setRentalModal: Dispatch<SetStateAction<'rent' | 'update' | 'remove' | 'lend' | "claim" | null>>
}
const PutForRent: FC<IPutForRent> = ({ setRentalModal}) => {
  return (
      <div className="flex flex-col justify-center items-center gap-2 py-6 border-b border-b-gray-600">
        <p className="rental-text">Place your NFT on the rental market</p>
        <PrimaryButton
          onClick={() => setRentalModal('lend')}
          buttonName="PUT FOR RENT"
          className="!m-0 !h-11"
        />
      </div>
      
  );
};

export default PutForRent;
