export function formatNumber(num: number) {
  if (num >= 1e15) {
    return (num / 1e15).toFixed(1) + "P";
  }
  else if (num >= 1e12) {
    return (num / 1e12).toFixed(1) + "T";
  }
  else if (num >= 1e9) {
    return (num / 1e9).toFixed(1) + "G";
  }
  else if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + "M";
  } else if (num >= 1000) {
    return (num / 1000).toFixed(1) + "K";
  } else {
    return num.toString();
  }
}
