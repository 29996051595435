import React, { FC, Dispatch, SetStateAction, useState, useEffect } from "react";
import {
  CROWN,
  DELETE_ICON,
  REDIRECT_ICON,
  ICX,
  DOWNLOAD_ICON,
} from "@constants/images";
import { NFTsDetails } from "@interfaces/nft.interface";
import { NFT_RENT_STATUS, NFT_RENT_TYPE, msPerDay } from "@constants/nft";
import Countdown from "react-countdown-simple";
import { HYPERLINKS } from "@misc/links";
import { formatNumber } from "@utils/formatNumber";
import { ZERO_ADDRESS } from "@type/rental.type";
import { MdAddShoppingCart } from "react-icons/md";
import RenderTimeCounter from "@utils/renderTimeCounter";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { Tooltip } from "react-tooltip";
import { useNFTInformation } from "@context/nftInfoProvider";


interface IRentDetail {
  selectedChar: any;
  setRentalModal: Dispatch<
    SetStateAction<"rent" | "update" | "remove" | "lend" | "claim" | null>
  >;
}
const RentDetail: FC<IRentDetail> = ({ selectedChar, setRentalModal }) => {
  const { platformFees } = useNFTInformation()
  return (
    <div className="flex flex-col justify-center items-center gap-[1.5rem] border-b border-b-gray-600 py-6">
      <div className="flex flex-col items-center gap-5 ">
        <div className=" flex justify-center items-start gap-[1.5rem]">
          <div className=" flex flex-col items-start gap-4 ">
            <div className="flex flex-col items-start">
              <p className="text-sm font-normal leading-5 text-[#70878F]">
                Rented by
              </p>
              <p className="text-sm font-semibold w-full leading-5 text-[#fff]">
                {selectedChar?.rental.borrower && selectedChar?.rental.borrower !== ZERO_ADDRESS ? (
                  <a
                    href={`${HYPERLINKS.GANGSTABET_WALLET}/${selectedChar.rental.status === NFT_RENT_STATUS.IN_RENT ? selectedChar.rental.owner : selectedChar.rental.borrower}`}
                    target="_blank"
                    className="cursor_pointer"
                    rel="noreferrer"
                  >
                    <span className="inline-flex gap-3 rental-text !font-semibold !text-[rgba(32,148,225,1)]">
                      {selectedChar.rental.status === NFT_RENT_STATUS.IN_RENT ? `${selectedChar.rental.owner.slice(0, 18)}...` : selectedChar?.rental.borrower.slice(0, 18)}...
                      <img src={REDIRECT_ICON} alt="redirect" />
                    </span>
                  </a>
                ) : (
                  "Not rented by anyone yet"
                )}
              </p>
            </div>
            {/* {selectedChar.rental.status !== NFT_RENT_STATUS.RENTED && ( */}
            {selectedChar.rental.status !== NFT_RENT_STATUS.IN_RENT && (
              <button
                disabled={selectedChar.rental.status === NFT_RENT_STATUS.IN_RENT}
                onClick={() => setRentalModal("remove")}
                className="flex py-2 px-4 w-full h-[2.1rem] justify-center items-center gap-2 border border-[#882C2C] bg-[#0A0E10] shadow-md disabled:border-[#485C65] disabled:bg-[#1C242A] disabled:text-[#485C65] disabled:cursor-not-allowed"
              >
                <p className="text-[#fff] text-sm leading-7 flex gap-1">

                  {(selectedChar.rental.status === NFT_RENT_STATUS.RENTED || selectedChar.rental.status === NFT_RENT_STATUS.EXPIRING) && selectedChar.rental.deListed ? <MdAddShoppingCart className="mt-1" color="white" size={20} /> : <img src={DELETE_ICON} alt="deleteIcon" />}

                  {selectedChar.rental.status === NFT_RENT_STATUS.RENTED || selectedChar.rental.status === NFT_RENT_STATUS.EXPIRING ? selectedChar.rental.deListed ? "Relist in market" : "Remove from market" : "Remove from market"}

                </p>
              </button>
            )}
            {/* )} */}
          </div>
          {/* <img src={LINEVECTOR} alt="lineVector" /> */}
          <div className="border-l border-[#485C65] pl-3 flex flex-col justify-end items-start gap-4 ">
            <div className="flex gap-4">
              <div className="flex flex-col">
                <p className="text-sm font-normal leading-5 text-[#70878F]">
                  {selectedChar.rental.status ===
                    NFT_RENT_STATUS.AVAILABLE_FOR_RENT || selectedChar.rental.status ===
                    NFT_RENT_STATUS.RENTED ? (
                    "Rent Rate"
                  ) : <>{selectedChar.rental.status === NFT_RENT_STATUS.IN_RENT ?

                    "Rent paid" : <p className="flex gap-1">Fee Earned <IoMdInformationCircleOutline data-tooltip-content={`${platformFees}% is taken as platform fee.`} id="rentalPlatformFee" className="cursor-pointer mt-1" color="gray" /> <Tooltip
                      style={{
                        zIndex: 999,
                        maxWidth: "300px",
                        opacity: 1,
                      }}
                      anchorSelect={`#rentalPlatformFee`}
                    /></p>}</>}
                </p>
                <p className="flex text-sm font-semibold leading-5 text-[#FFF] gap-1">

                  {selectedChar.rental.status ===
                    NFT_RENT_STATUS.AVAILABLE_FOR_RENT || selectedChar.rental.status ===
                    NFT_RENT_STATUS.RENTED
                    ? <>
                      <img
                        src={
                          selectedChar?.rental.rentType === NFT_RENT_TYPE.CROWN_CURRENCY
                            ? CROWN
                            : ICX
                        }
                        alt="currency"
                        className="w-5 h-5"
                      />
                      {selectedChar.rental.status ===
                        NFT_RENT_STATUS.AVAILABLE_FOR_RENT ? Number(selectedChar.rental.listedRentFee) : Number(selectedChar.rental.rentFee)}/day
                    </>
                    : <>
                      <img
                        src={
                          selectedChar?.rental.rentType === NFT_RENT_TYPE.CROWN_CURRENCY
                            ? CROWN
                            : ICX
                        }
                        alt="currency"
                        className="w-5 h-5"
                      />
                      {(Number(selectedChar.rental.claimableAmount))}
                    </>}
                </p>
              </div>
              {/* {(selectedChar.rental.status === NFT_RENT_STATUS.AVAILABLE_FOR_RENT || selectedChar.rental.status === NFT_RENT_STATUS.IN_RENT) && ( */}
              {(selectedChar.rental.status === NFT_RENT_STATUS.RENTED || selectedChar.rental.status === NFT_RENT_STATUS.EXPIRING) && (selectedChar.rental.rentFee !== selectedChar.rental.listedRentFee) && (

                <div className="flex flex-col">
                  <p className="text-sm font-normal leading-5 text-[#70878F]">
                    Next Cycle Rate
                  </p>
                  <p className="flex text-sm font-semibold leading-5 text-[#FFF] gap-1">
                    {/* {Math.floor((selectedChar.rental.expiry! / 1000 - Date.now()) / msPerDay)} Days */}
                    <img
                      src={
                        selectedChar?.rental.rentType === NFT_RENT_TYPE.CROWN_CURRENCY
                          ? CROWN
                          : ICX
                      }
                      alt="currency"
                      className="w-5 h-5"
                    />
                    {Number(selectedChar.rental.listedRentFee)}/day
                  </p>
                </div>)}
            </div>
            {(selectedChar.rental.status === NFT_RENT_STATUS.RENTED || selectedChar.rental.status === NFT_RENT_STATUS.IN_RENT) && (
              <p className="rent-time-countdown">
                <span className="text-sm font-normal leading-5 text-[#70878F]">
                  Rent time remaining: <br />
                </span>
                {Number(selectedChar.rental.expirationTimestamp) > 0 && (
                  <>
                    <Countdown
                      targetDate={new Date(
                        new Date(
                          Number(selectedChar.rental.expirationTimestamp) / 1000
                        ).setHours(
                          new Date(
                            Number(selectedChar.rental.expirationTimestamp) /
                            1000
                          ).getHours()
                        )
                      ).toISOString()}
                      // disableTypes={true}
                      renderer={RenderTimeCounter}
                    />
                    {/* out of{" "}
                    {((selectedChar.rental.expirationTimestamp! -
                      selectedChar.rental.startTimestamp!) /
                      (1000 * 1000 * 3600 * 24)).toFixed(1)}{" "}
                    {((selectedChar.rental.expirationTimestamp! -
                      selectedChar.rental.startTimestamp!) /
                      (1000 * 1000 * 3600 * 24)) > 1 ? "days" : "day"} */}
                  </>
                )}
              </p>
            )}
            {/* {selectedChar.rental.status !== NFT_RENT_STATUS.RENTED && ( */}
            {selectedChar.rental.status !== NFT_RENT_STATUS.IN_RENT && (
              <button
                disabled={selectedChar.rental.status === NFT_RENT_STATUS.IN_RENT}
                onClick={() => setRentalModal("update")}
                className="flex py-4 px-2 w-full h-[2.1rem] justify-center items-center gap-2 border border-[#AE963E] bg-[#0A0E10] shadow-md disabled:border-[#485C65] disabled:bg-[#1C242A] disabled:text-[#485C65] disabled:cursor-not-allowed"
              >
                <p className="text-[#fff] text-sm leading-7 flex gap-1">
                  <img src={DOWNLOAD_ICON} alt="pricechange" />
                  {selectedChar.rental.status === NFT_RENT_STATUS.RENTED ? "Update rent for next time" : "Update rent rate"}
                </p>
              </button>
            )}
            {/* )} */}
          </div>
        </div>
        {selectedChar.rental.status === NFT_RENT_STATUS.AVAILABLE_FOR_RENT && (
          <div className="py-3 px-8 gap-2 items-start bg-neutral-700 text-sm font-normal leading-5">
            <p className="text-[#9AA8AC] text-left">
              <span className="text-[#FB9D51]">Notice: </span>You cannot use
              this NFT in wars while it is listed in the rental marketplace.
              Remove from the market to use it in wars.
            </p>
          </div>
        )}
        {(selectedChar.rental.status === NFT_RENT_STATUS.RENTED || selectedChar.rental.status === NFT_RENT_STATUS.EXPIRING) && (
          <div className="py-3 px-8 gap-2 items-start bg-neutral-700 text-sm font-normal leading-5">
            <p className="text-[#9AA8AC] text-left">
              <span className="text-[#FB9D51]">Notice: </span>The rent status and the rent amount will be updated when the current renting session is over.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default RentDetail;
