export const IMAGES_S3_URL = "https://d3o1y7koafmnju.cloudfront.net/ui-assets/images/";
export const SOUND_S3_URL = "https://d3o1y7koafmnju.cloudfront.net/ui-assets/sounds/";

export const INFINITE_GIF_SUFFIX = "-infinite";
export const TARGET_GIF_SUFFIX = "-effect";

export enum CHANGE_TYPE {
    DODGE_CHANCE = "DODGE_CHANCE",
    HIT_CHANCE = "HIT_CHANCE",
    CRITICAL_CHANCE = "CRITICAL_CHANCE",
    HEALTH = "HEALTH",
    DURA_AMMO = "DURA_AMMO",
    SPEED = "SPEED",
    MELEE_DAMAGE = "MELEE_DAMAGE",
    RANGE_DAMAGE = "RANGE_DAMAGE",
    SUPPORT_EFFECTIVENESS = "SUPPORT_EFFECTIVENESS",
    SUPPORT_TRIGGER_CHANCE = "SUPPORT_TRIGGER_CHANCE",
    STRENGTH = "STRENGTH",
    ACCURACY = "ACCURACY",
    MASTERY = "MASTERY",
    AGILITY = "AGILITY",
    LUCK = "LUCK",
    DAMAGE = "DAMAGE",
    CYCLE = "CYCLE",
    SHIELD = "SHIELD",
    IDLE = "IDLE",
    CONSUMPTION_RATE = "CONSUMPTION_RATE",
    BURN = "BURN",
    STUN = "STUN",
}

export const NARRATION_TYPES = {
    ITEM_TAKEN: "ITEM_TAKEN",
    ACTION_LOG: "ACTION_LOG",
    EFFECT_LOG: "EFFECT_LOG",
    ABILITY_LOG: "ABILITY_LOG",
    HEAL: "HEAL",
    SELF_HEAL: "SELF_HEAL",
    PLAYER_DEAD: "PLAYER_DEAD", // EVEN AFTER BEING DEAD, IT COULD RESURRECT BY TAKING ITEM
    SHIELD_BOOST: "SHIELD_BOOST",
};

// ONLY MOVE LOGS TO THE BOTTOM OF SIDEBAR LOGS, DON'T ANIMATE
export enum IGNORED_NARRATION_TYPES {
    ABILITY_PASSIVE = "ABILITY_PASSIVE",
    COMPOSITE_ACTION_LOG = "COMPOSITE_ACTION_LOG",
    DAMAGE_IMMUNITY = "DAMAGE_IMMUNITY",
    EQUIPMENT_BROKEN = "EQUIPMENT_BROKEN",
    EFFECT_TAKEN = "EFFECT_TAKEN",
    EFFECT_OVER = "EFFECT_OVER",
    EFFECT_REFRESHED = "EFFECT_REFRESHED",
    EQUIPMENT_SWITCHED = "EQUIPMENT_SWITCHED",
    ITEM_OVER = "ITEM_OVER",
    ITEM_LOG = "ITEM_LOG",
    ITEM_TAKEN = "ITEM_TAKEN",
    GAME_OVER = "GAME_OVER",
}

// Show special text animation covering the whole screen & animate it to the end of sidebar logs
export enum SPECIAL_NARRATION_TYPES {
    ABILITY_LOG = "ABILITY_LOG",
    // PLAYER_DEAD = "PLAYER_DEAD",
}

export enum PASSIVE_NARRATION_TYPES {
    EFFECT_LOG = "EFFECT_LOG",
    SELF_HEAL = "SELF_HEAL",
    SHIELD_BOOST = "SHIELD_BOOST",
    ITEM_TAKEN = "ITEM_TAKEN",
    EFFECT_OVER = "EFFECT_OVER",
}

export const PRELUDE_NARRATION_TYPES = [
    "ITEM_TAKEN",
    "ABILITY_PASSIVE",
];

export enum PASSIVE_ACTION_TYPES {
    SELF_HEAL = "SELF_HEAL",
}

export enum IGNORED_ACTION_TYPES {
    SELF_HIDE = "SELF_HIDE",
}

export enum SPECIAL_ACTION_TYPES {
    ACC_DUEL = "ACC_DUEL",
    MAS_DUEL = "MAS_DUEL",
    LUK_DUEL = "LUK_DUEL",
    AGI_DUEL = "AGI_DUEL",
    STR_DUEL = "STR_DUEL",
    REVIVE = "REVIVE",
    GUN_JAMMED = "GUN_JAMMED",
    DISTRACTION_SUCCESS = "DISTRACTION_SUCCESS",
    DISTRACTION_FAILED = "DISTRACTION_FAILED",
    SHIELD_BOOST = "SHIELD_BOOST",
};

export const SPECIAL_ACTION_GIF_WIDTH = 300; // in px

export enum IGNORED_EQUIPMENTS { // battle equipments / effect types (rage), etc
    RAGE = "Rage",
}

export enum IGNORED_EFFECTS {
    PRECISION_STRIKE = "PRECISION_STRIKE",
    HIDE = "HIDE",
}

export enum ACTION_TYPE {
    SELF_HEAL = "SELF_HEAL", // INCREASES SELF HP
    COVER_HEAL = "COVER_HEAL",
    HEAL = "HEAL",
    NORMAL_ATTACK = "NORMAL_ATTACK",
    RETURN_ATTACK = "RETURN_ATTACK",
    COVERING_ATTACK = "COVERING_ATTACK",
    FRIENDLY_FIRE = "FRIENDLY_FIRE", // attacking the same team
    COLLATERAL_ATTACK = "COLLATERAL_ATTACK", // A attacks B but both B and C get affected
    REVIVE = "REVIVE",
    DISTRACTION_SUCCESS = "DISTRACTION_SUCCESS",
    DISTRACTION_FAILED = "DISTRACTION_FAILED",
    GUN_JAMMED = "GUN_JAMMED",
}

export const BATTLE_STATE = {
    NONE: "none",
    BATTLE_RESET: "reset",
    PRELUDE_ANIMATION: "preludeAnimation",
    ATTACK_IN_PROGRESS: "inProgress",
    ATTACK_COMPLETE: "attackComplete",
    BATTLE_COMPLETE: "battleComplete",
};

export enum BATTLE_ROLES {
    MAIN = "main", // attacker
    TARGET = "target", // defender
    DISTRACT_NFTS = "distractedNFTs",  // distracted nfts in DISTRACTION_SUCCESS / DISTRACTION_FAILED
}

export const BATTLE_TEAMS = {
    BLUE: "Team A",
    ORANGE: "Team B",
};

export const ATTACK_STATE = {
    NONE: "none",
    ATTACKING: "attacking",
    DEFENDING: "defending",
    COMPLETED: "completed",
};

export const ATTACK_EFFECTS = {
    GUN_EFFECT: "GUN_EFFECT",
    GRENADE_EFFECT: "GRENADE_EFFECT",
};

export const NO_RETURN_DEFAULT_POS_ACTIONS = [ACTION_TYPE.COLLATERAL_ATTACK];

export const NO_RETURN_DEFAULT_POS_NARRATIONS = [NARRATION_TYPES.PLAYER_DEAD];

export const ATTACK_EQUIPMENTS = {
    DAGGER: "Dagger",
    REVOLVER: "Revolver",
    BATON: "Baton",
    FRYING_PAN: "Frying Pan",
    KATANA: "Katana",
    HAMMER: "Hammer",
    AXE: "Axe",
    SHOTGUN: "Shotgun",
    MACHETE: "Machete",
    HAND_GUN: "Hand Gun",
    RIFLE: "Rifle",
    CARBINE: "Carbine",
    TOMMY_GUN: "Tommy Gun",
    REMMINGTON: "Remmington 870",
    GRENADE: "Grenade",
    FLAME_BOTTLES: "P&R Flame Bottles",
    NONE: "None",
};

export const ABILITY = {
    IMMUNE: "IMMUNE",
};

export enum COLD_WATER_EFFECTS {
    STUN = "STUN",
    BURN = "BURN",
};

export const EFFECTS = {
    COVER: "COVER",
    BLEEDING: "BLEEDING",
    BURNING: "BURNING",
    POISON: "POISON",
    SLOW: "SLOW",
    BLIND: "BLIND",
    STUN: "STUN",
    QUICKEN: "QUICKEN",
    BELITTLED: "BELITTLED",
    DAMAGE_IMMUNITY: "DAMAGE_IMMUNITY",
    KEEN_EYE: "KEEN_EYE",
    SHIELD: "SHIELD",
    SILENCE: "SILENCE",
    EFFICIENT: "EFFICIENT",
    REGENERATION: "REGENERATION",
    RAGE: "RAGE",
    DIARRHEA: "DIARRHEA",
    PRECISION_STRIKE: "PRECISION_STRIKE",
    LIGHT_BURN: "LIGHT_BURN",
    HIDE: "HIDE",
    CLUMSY: "CLUMSY",
    CURSED: "CURSED",
    DELAY: "DELAY",
    NEGATE: "NEGATE",
    TWIN_CAST: "TWIN_CAST",
    RETALIATE: "RETALIATE",
    REFLECT: "REFLECT",
    DISARM: "DISARM",
    DISTRACTED: "DISTRACTED",
    CONFUSED: "CONFUSED",
    ROOT: "ROOT",
    PROVOKED: "PROVOKED",
    HASTE: "HASTE",
    IMMORTAL: "IMMORTAL",
    GUN_JAMMED: "GUN_JAMMED",
    SKIP_TURN: "SKIP_TURN",
    DEAD_COVER: "DEAD_COVER",
};

export const ITEMS = {
    ANTIDOTE: "ANTIDOTE",
    EXOSKELETON: "EXOSKELETON",
    STEROIDS: "STEROIDS",
    CHEESE_CAKE: "CHEESE_CAKE",
    COLD_WATER: "COLD_WATER",   // ADDED new
    ENERGY_DRINK: "GANGSTER_ENERGY_DRINK",
    SLIGSHOT_DEVICE: "SLINGSHOT",
    COFFEE: "COFFEE",
    ANKH_OF_REBIRTH: "ANKH_OF_REBIRTH",
    MORSEL_OF_SUSHI: "MORSEL_OF_SUSHI",
    LUNCHBOX_OF_SUSHI: "LUNCHBOX_OF_SUSHI",
    ENTREE_OF_SUSHI: "ENTREE_OF_SUSHI",
    PLATTER_OF_SUSHI: "PLATTER_OF_SUSHI",
    FEAST_OF_SUSHI: "FEAST_OF_SUSHI",
    MORSEL_OF_PIZZA: "MORSEL_OF_PIZZA",
    LUNCHBOX_OF_PIZZA: "LUNCHBOX_OF_PIZZA",
    ENTREE_OF_PIZZA: "ENTREE_OF_PIZZA",
    PLATTER_OF_PIZZA: "PLATTER_OF_PIZZA",
    FEAST_OF_PIZZA: "FEAST_OF_PIZZA",
    MORSEL_OF_BACON: "MORSEL_OF_BACON",
    LUNCHBOX_OF_BACON: "LUNCHBOX_OF_BACON",
    ENTREE_OF_BACON: "ENTREE_OF_BACON",
    PLATTER_OF_BACON: "PLATTER_OF_BACON",
    FEAST_OF_BACON: "FEAST_OF_BACON",
    MORSEL_OF_FISH_STICKS: "MORSEL_OF_FISH_STICKS",
    LUNCHBOX_OF_FISH_STICKS: "LUNCHBOX_OF_FISH_STICKS",
    ENTREE_OF_FISH_STICKS: "ENTREE_OF_FISH_STICKS",
    PLATTER_OF_FISH_STICKS: "PLATTER_OF_FISH_STICKS",
    FEAST_OF_FISH_STICKS: "FEAST_OF_FISH_STICKS",
    MORSEL_OF_LAMB_CHOP: "MORSEL_OF_LAMB_CHOP",
    LUNCHBOX_OF_LAMB_CHOP: "LUNCHBOX_OF_LAMB_CHOP",
    ENTREE_OF_LAMB_CHOP: "ENTREE_OF_LAMB_CHOP",
    PLATTER_OF_LAMB_CHOP: "PLATTER_OF_LAMB_CHOP",
    FEAST_OF_LAMB_CHOP: "FEAST_OF_LAMB_CHOP",
};

export const PLAYER_DEAD = {
    PLAYER_DEAD: "PLAYER_DEAD",
};

export const MEELE_WEAPONS = {
    Beer: "Beer",
    Cocktail: "Cocktail",
    "Leather Handbag": "Leather Handbag",
    "Black And Gold Cane": "Black And Gold Cane",
    "Black And Silver Cane": "Black And Silver Cane",
    "Brown And Silver Cane": "Brown And Silver Cane",
    "Brown And Gold Cane": "Brown And Gold Cane",
    Knife: "Knife",
    Dagger: "Dagger",
    Baton: "Baton",
    "Frying Pan": "Frying Pan",
    Axe: "Axe",
    Hammer: "Hammer",
    Machete: "Machete",
    Katana: "Katana",
};

export const THROWABLE_WEAPONS = {
    Grenade: "Grenade",
    "Molotov Cocktail": "Molotov Cocktail",
    "P&R Flame Bottles": "P&R Flame Bottles",
}

export const GUN_WEAPONS = {
    Carbine: "Carbine",
    "Hand Gun": "Hand Gun",
    M1911: "M1911",
    "Machine Gun": "Machine Gun",
    Pistol: "Pistol",
    "Remmington 870": "Remmington 870",
    Revolver: "Revolver",
    Rifle: "Rifle",
    "Sawed-off Shotgun": "Sawed-off Shotgun",
    Shotgun: "Shotgun",
    "Smith and Wesson Model": "Smith and Wesson Model",
    "Tommy Gun": "Tommy Gun",
}

export const WAR_AUDIO_MAPPINGS = {
    "Axe": ["Axe", "Baton", "Hammer"],
    "Baton": ["Axe", "Baton"],
    "Black And Gold Cane": ["Cane", "Cane2", "Cane3", "Cane4"],
    "Black And Silver Cane": ["Cane", "Cane2", "Cane3", "Cane4"],
    "Brown And Silver Cane": ["Cane", "Cane2", "Cane3", "Cane4"],
    "Brown And Gold Cane": ["Cane", "Cane2", "Cane3", "Cane4"],
    "Carbine": ["Carbine", "Rifle"],
    "Dagger": ["Dagger"],
    "Frying Pan": ["Frying Pan"],
    "Grenade": ["Grenade"],
    "Grenade-effect": ["Grenade-effect"],
    "Hammer": ["Hammer"],
    "Hand Gun": ["Hand Gun"],
    "Heal": ["Heal"],
    "Katana": ["Katana"],
    "Knife": ["Knife"],
    "M1911": ["M1911"],
    "Machete": ["Machete"],
    "Machine Gun": ["Machine Gun"],
    "Molotov Cocktail": ["Grenade"],
    "Molotov Cocktail-effect": ["Grenade-effect"],
    "Pistol": ["Pistol"],
    "P&R Flame Bottles": ["Grenade"],
    "P&R Flame Bottles-effect": ["Grenade-effect"],
    "Remmington 870": ["Remmington"],
    "Revolver": ["Revolver"],
    "Rifle": ["Rifle"],
    "Sawed-off Shotgun": ["Sawed-off Shotgun"],
    "Shotgun": ["Shotgun"],
    "Smith and Wesson Model": ["Revolver"],
    "Tommy Gun": ["Tommy Gun"],
    "PLAYER_DEAD-effect": ["PLAYER_DEAD"],
    "HEAL": ["HEAL"],
    "SELF_HEAL": ["SELF_HEAL"],
    "BLIND_SIGHT": ["BLIND_SIGHT"],
    "BLOOD_RUSH": ["BLOOD_RUSH"],
    "BOUNCE": ["BOUNCE"],
    "FLAKE": ["FLAKE"],
    "FLASH": ["FLASH"],
    "FRUGO": ["FRUGO"],
    "HARDEN": ["HARDEN"],
    "IMMUNE": ["IMMUNE"],
    "MAGNIFY": ["MAGNIFY"],
    "MUTE": ["MUTE"],
    "REGEN": ["REGEN"],
    "SHIELD": ["SHIELD"],
    "VENOM": ["VENOM"],
    "ZAPPER": ["ZAPPER"],
}


export const EVENTS_WITH_PRELUDE_ANIM = [
    'Cheeky move',
];

export const EFFECTS_WITH_PRELUDE_ANIM = [
    EFFECTS.DIARRHEA,
]
