import { Tooltip } from "react-tooltip";
import ImageLoader from "@misc/ImageLoader";
import { BADGE } from "@constants/images";
const ItemTooltip = ({ details, place = "right", tooltipId }: any) => {
    const loadoutName =
        details?.name?.split(" ").length > 1 ? details?.name?.replace(/\s/g, "_") : details?.name;
    return (
        <>
            <Tooltip
                place={place}
                delayShow={800}
                className={`tooltipItemsLoadout tooltipPlace_${place}`}
                anchorSelect={`#${tooltipId}`}
            >
                <div className="flex flex-col gap-5">
                    <div className="itemsDetails flex gap-6 items-center ">
                        <div className="imageHodler userloadout tooltipLoadout">
                            <ImageLoader
                                style={{ padding: "5px" }}
                                src={details?.image}
                                alt="weapons"
                            />
                        </div>
                        <div className="flex flex-col gap-2">
                            <h2 className="tooltipLoadoutName">{details?.name}</h2>
                            <div className="flex itemsInnerDetails gap-5">
                                <div className="itemTypeName  flex-col flex gap-1 items-start">
                                    <p className="itemType">ItemType</p>
                                    <p className="itemName whitespace-nowrap">{details?.type}</p>
                                </div>
                                {details?.rank && (
                                    <div className="targetBasic gap-1 flex items-center">
                                        <ImageLoader width="15px" src={BADGE} alt="badge" />
                                        <p className="basicName">Rank {details?.rank}</p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    {details?.statBoost && (
                        <div className="statsItems ">
                            {Object.entries(details?.statBoost).map(([stats, value]: any) => {
                                return (
                                    <p className="statsItemsName capitalize ">
                                        {stats} <span className="statsValue"> {value}</span>
                                    </p>
                                );
                            })}
                        </div>
                    )}
                    <hr className="horizontalSeperator" />
                    <p className="loadoutDescription">{details?.description}</p>
                </div>
            </Tooltip>
        </>
    );
};
export default ItemTooltip;
