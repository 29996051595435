import { useEffect, useState } from "react";

function formatNumber(num: number, length: number) {
    let numStr = num.toString();
    while (numStr.length < length) {
        numStr = `0${numStr}`;
    }
    return numStr;
}

// all times are in ms
export default function ResetTimer({ timestamp }: { timestamp: number }) {
    const [remainingTime, setRemainingTime] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    });
    useEffect(() => {
        if (timestamp < Date.now()) return;
        const intHandler = setInterval(() => {
            let remainingTime = timestamp - Date.now();
            const days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
            remainingTime = remainingTime % (1000 * 60 * 60 * 24);
            const hours = Math.floor(remainingTime / (1000 * 60 * 60));
            remainingTime = remainingTime % (1000 * 60 * 60);
            const minutes = Math.floor(remainingTime / (1000 * 60));
            remainingTime = remainingTime % (1000 * 60);
            const seconds = Math.floor(remainingTime / 1000);
            setRemainingTime({
                days,
                hours,
                minutes,
                seconds,
            });
        }, 1000);

        return () => clearInterval(intHandler);
    }, [timestamp]);
    return (
        <span>
            {remainingTime.days > 0 ? (
                `${remainingTime.days} days`
            ) : (
                <>
                    {formatNumber(remainingTime.hours, 2)}:
                    {formatNumber(remainingTime.minutes, 2)}:
                    {formatNumber(remainingTime.seconds, 2)}
                </>
            )}
        </span>
    );
}
