import Image from "@misc/Image";
import { useEffect, useState } from "react";
import { getNFTInformation } from "@utils/getNFTInformation";
import { NFTsDetails } from "@interfaces/nft.interface";

const MVP = ({ nftId }: any) => {
  const [mvpNFTDetails, setMvpNFTDetails] = useState<NFTsDetails | null>(null);
  useEffect(() => {
    async function getNFTInfo() {
      const result = await getNFTInformation(nftId);
      setMvpNFTDetails(result);
    }
    getNFTInfo();
  }, []);
  return (
    mvpNFTDetails && (
      <div className="pgMVPWrapper flex items-center gap-7 animate__animated  animate__zoomIn">
        <hr className="pgMVPSep" />
        <div className="pgMVP flex items-center gap-2 justify-center">
          <div className="pgMVPImage flex items-center justify-center">
            <Image id={nftId} width="36px" height="48px" />
          </div>
          <div className="flex flex-col gap-0.5">
            <h1>
              MVP <span>(Most Valuable Player)</span>
            </h1>
            <p>{mvpNFTDetails.nftName}</p>
          </div>
        </div>
        <hr className="pgMVPSep" />
      </div>
    )
  );
};

export default MVP;
