import Modal from "@components/modal/modal.component";
import NFTCard from "@components/card/nftCard.component";
import Countdown from "react-countdown-simple";
import { EMPTY_HAT } from "@constants/images";
import ImageLoader from "@misc/ImageLoader";
import Typewriter from "typewriter-effect";

const TeamFormation = ({
    isVisible,
    setIsVisible,
    startsGameIn,
    warDetails,
    teamSeparation,
    closeHandler,
}: any) => {
    return (
        <Modal
            isVisible={isVisible}
            className="changeStatsModal overflowModal"
            setIsVisible={setIsVisible}
            width="1000px"
            closeHandler={closeHandler}
            height="480px"
        >
            <div className="teamFormation flex flex-col gap-4">
                <div className="formationHead flex flex-col justify-center items-center ">
                    {warDetails ? (
                        <h2 className="mt-3">WAR STARTS IN</h2>
                    ) : (
                        <div className="headingTextTeamSeparation flex ">
                            <p className="">Loading</p>
                            {/* <Typewriter
                options={{
                  strings: ["..."],
                  autoStart: true,
                  loop: true,
                  cursor: "",
                }}
              /> */}
                        </div>
                    )}
                    {warDetails && (
                        <Countdown
                            targetDate={new Date(
                                new Date(Number(warDetails?.startTime) / 1000).setHours(
                                    new Date(Number(warDetails?.startTime) / 1000).getHours()
                                )
                            ).toISOString()}
                            renderer={startsGameIn}
                        />
                    )}
                </div>
                <div className="teamSeparationWrapper flex-col gap-4">
                    <div className="flex team-formationBox">
                        <div className="teamFormationTeamA">
                            <div className="teamAText">
                                <h2>Team A</h2>
                            </div>
                            <div className="flex p-4 justify-between">
                                {teamSeparation?.teamA?.map((details: any, index: any) => {
                                    return (
                                        <NFTCard
                                            cardType="nftCardImageTeamFormation"
                                            key={`key_${index}`}
                                            height="100px"
                                            width="77px"
                                            imageId={details?.participantId}
                                        />
                                    );
                                })}
                                {Array.from(
                                    { length: 5 - teamSeparation?.teamA?.length },
                                    (_, i) => {
                                        return (
                                            <ImageLoader
                                                key={`key_${i}`}
                                                height="100px"
                                                width="77px"
                                                alt="hat"
                                                src={EMPTY_HAT}
                                            />
                                        );
                                    }
                                )}
                            </div>
                        </div>
                        <div className="teamFormationTeamB">
                            <div className="teamBText">
                                <h2>Team B</h2>
                            </div>

                            <div className="flex p-4 justify-between">
                                {teamSeparation?.teamB?.map((details: any, index: number) => {
                                    return (
                                        <NFTCard
                                            cardType="nftCardImageTeamFormation"
                                            key={`key_${index}`}
                                            height="100px"
                                            width="77px"
                                            imageId={details?.participantId}
                                        />
                                    );
                                })}
                                {Array.from(
                                    { length: 5 - teamSeparation?.teamB?.length },
                                    (_, i) => {
                                        return (
                                            <ImageLoader
                                                alt="hat"
                                                key={`key_${i}`}
                                                height="100px"
                                                width="77px"
                                                src={EMPTY_HAT}
                                            />
                                        );
                                    }
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="formationHead mt-4 text-white text-center flex items-center justify-center text-3xl">
                        <Typewriter
                            options={{
                                strings: [
                                    "MAKE SURE YOU ARE LOADED",
                                    "KILL THEM ALL",
                                    "STEAL THE LOOTS",
                                ],
                                autoStart: true,
                                loop: true,
                                cursor: "",
                            }}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    );
};
export default TeamFormation;
