import ImageLoader from "@misc/ImageLoader";
import { EMPTY_HAT } from "@constants/images";
import Typewriter from "typewriter-effect";

const EmptyLobbyLoadout = () => {
  return (
    <div className="emptyParticipantLoadoutForGame">
      <ImageLoader width="90px" src={EMPTY_HAT} alt="gBhat" />
      <h1 className="emptyStateLobby flex uppercase">
        waiting
        <Typewriter
          options={{
            strings: ["..."],
            autoStart: true,
            loop: true,
            cursor: ".",
          }}
        />
      </h1>
    </div>
  );
};

export default EmptyLobbyLoadout;
