export function sortByStartTime(simulations: any[]) {
  return new Promise((resolve, reject) => {
    try {
      const sorted = simulations.sort((a, b) => {
        const startTimeA = BigInt(a.startTime);
        const startTimeB = BigInt(b.startTime);
        return startTimeA < startTimeB ? -1 : startTimeA > startTimeB ? 1 : 0;
      });
      resolve(sorted);
    } catch (e) {
      console.log(e);
      resolve([]);
    }
  });
}

export function sortByStartTimeForFutureWar(simulations: any[]) {
  return new Promise((resolve, reject) => {
    try {
      const sorted = simulations.sort((a, b) => {
        const startTimeA = BigInt(a.data.startTime);
        const startTimeB = BigInt(b.data.startTime);
        return startTimeA < startTimeB ? -1 : startTimeA > startTimeB ? 1 : 0;
      });
      resolve(sorted);
    } catch (e) {
      console.log(e);
      resolve([]);
    }
  });
}
