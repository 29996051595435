import Modal from "@components/modal/modal.component";
import PrimaryButton from "@components/buttons/primary.button";
import { disconnectWallet } from "@utils/disconnectWallet";
import {
  PLAY_CLOSE_SOUND,
  PLAY_NORMAL_CLICK,
  PLAY_INVENTORY_CLICK,
} from "@constants/audio";
import { useNavigate } from "react-router";
import { useNFTDetailGlobalContext } from "@context/nftInformation";

const Disconnect = ({ dispatch, isVisible, setIsVisible }: any) => {
  const navigate = useNavigate();
  const { setNftData, setCurrentBatchIndex, setRentalIndex } = useNFTDetailGlobalContext();
  return (
    <Modal
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      modalTitle="Disconnect your wallet"
      width="540px"
    >
      <div className="disconnect-wallet">
        <p className="text-center">
          Please confirm to disconnect your wallet.{" "}
        </p>
        <div className="confirm-cancel-btns">
          <PrimaryButton
            onClick={() => {
              PLAY_NORMAL_CLICK();
              setIsVisible(false);
              disconnectWallet(
                dispatch,
                navigate,
                setNftData,
                setCurrentBatchIndex,
                setRentalIndex
              );
            }}
            buttonName="Confirm"
          />
          <PrimaryButton
            onClick={() => {
              PLAY_CLOSE_SOUND();
              setIsVisible(false);
            }}
            buttonName="Cancel"
          />
        </div>
      </div>
    </Modal>
  );
};

export default Disconnect;
