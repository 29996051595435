import StylizedButton from "@components/buttons/stylized.button";
import Modal from "@components/modal/modal.component";
import { useNavigate } from "react-router-dom";

const ModalCrash = ({ isVisible, setIsVisible }: any) => {
  const navigate = useNavigate();
  return (
    <Modal isVisible={true} modalTitle={`War ended unexpectedly!`}>
      <div className="crownLimitWrapper">
        {/* <hr className="horizontal-seperator" /> */}
        <div className="crownLimitText">
          {/* <p className="crownLimitTopText">Unfortunately, .</p> */}
          <p className="crownLimitInfo">
            An unexpected error has led to the war ending prematurely, please
            report the issue via our Discord.
          </p>
        </div>
        <hr className="horizontal-seperator" />
        <div className="crownLimitButton">
          <StylizedButton
            onClick={() => {
              navigate("/war");
            }}
            buttonName="Okay"
          />
        </div>
      </div>
    </Modal>
  );
};

export default ModalCrash;
