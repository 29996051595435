import { IUserEquipment } from "@interfaces/equipment.interface";
import { BADGE } from "@constants/images";
import { useEffect } from "react";
import { RxStack } from "react-icons/rx";
import { GiGrenade } from "react-icons/gi";
import PrimaryButton from "@components/buttons/primary.button";
import EquipmentStylizedCard from "@components/card/equipmentStylizedCard.component";
import { useCardAnimation } from "@hooks/useCardAnimation";
import { ILoadoutButton } from "@interfaces/items.interface";
import { IEquipBtnActive } from "@interfaces/items.interface";
import { IEquipedLoadout } from "@interfaces/items.interface";
import { IItems } from "@interfaces/items.interface";
import { ILoadoutDetails } from "@interfaces/character.interface";
import { WeaponType } from "@misc/warSpecific";

export const LoadoutDetails = ({
    slot,
    loadoutEquipmentDetails,
    buttonName,
    loadoutItemDetails,
    itemIndex,
    getItemNumberCount,
    loadoutAbilityDetails,
    getAbilityNumberCount,
    abilityIndex,
    equipedLoadout,
    handleButton,
    war,
}: ILoadoutDetails) => {
    useCardAnimation("animate", true);
    const allowedWeapons = war.allowedWeapons.length === 0 ? Object.keys(WeaponType).map(element => Number(element)) : war.allowedWeapons.map((element: string) => Number(element));
    console.log("this is war", war);
    return (
        <>
            {slot === "weapon" &&
                loadoutEquipmentDetails &&
                (loadoutEquipmentDetails.count as number) > 0 && (
                    <div className="flex flex-col my-6 px-4 animation" id="rightSection">
                        <div className="flex items-center gap-4 ">
                            <EquipmentStylizedCard
                                equipmentImages={loadoutEquipmentDetails.image}
                                className={`setColor ${!allowedWeapons.includes(
                                    loadoutEquipmentDetails.equipmentId
                                ) && "disable opacity-40"}`}
                            />

                            <div className="flex flex-col items-start justify-center">
                                <h1 className="text-xl font-proxima">
                                    {loadoutEquipmentDetails.name}
                                </h1>
                                <div className="flex items-center">
                                    <GiGrenade className="h-12 mr-2" />
                                    {loadoutEquipmentDetails.type}
                                </div>
                                <div className="flex items-center">
                                    <RxStack className=" mr-2" />
                                    Basic
                                    {/* {loadoutEquipmentDetails.properties} */}
                                </div>
                            </div>
                        </div>
                        <div className="py-4">
                            <div className="flex items-center justify-center">
                                {/* <h5 className="text-sm">
                  {loadoutEquipmentDetails.properties?.masteryRequired > 0
                    ? loadoutEquipmentDetails.properties?.masteryRequired
                    : "No"}{" "}
                  MAS Required{" "}
                </h5> */}
                                {/* <span className="bg-white w-[1px] h-[40px]" /> */}
                                {allowedWeapons.includes(loadoutEquipmentDetails.equipmentId) ? (
                                    <PrimaryButton
                                        height="40px"
                                        buttonName={`${buttonName.equipment}  weapon`}
                                        className=""
                                        onClick={() => {
                                            handleButton();
                                        }}
                                    />
                                ) : (
                                    <p>
                                        <b>Note:</b>This weapon cannot be equiped in this battle.
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className="py-3 border-y-[1px] border-gray-500">
                            <span>{loadoutEquipmentDetails?.description}</span>
                        </div>
                        <div className="grid grid-cols-2 text-left py-4 gap-6">
                            <span>
                                <div className="title text-md text-gray-400">Damage</div>
                                <div className="title text-lg text-white">
                                    {loadoutEquipmentDetails.stats?.damage}{" "}
                                    <span className="text-sm">
                                        ({loadoutEquipmentDetails.stats.numberofHits} hits)
                                    </span>
                                </div>
                            </span>
                            <span>
                                <div className="title text-md text-gray-400">
                                    {loadoutEquipmentDetails?.type === "Melee"
                                        ? "Base Durability"
                                        : "Base Ammo"}
                                </div>
                                <div className="title text-lg text-white">
                                    {loadoutEquipmentDetails.stats?.duraAmmo}{" "}
                                    <span className="text-sm">
                                        {" "}
                                        (uses {loadoutEquipmentDetails.stats.consumptionRate} per
                                        hit)
                                    </span>
                                </div>
                            </span>
                            <span>
                                <div className="title text-md text-gray-400">Hit Chance</div>
                                <div className="title text-lg text-white">
                                    {Number(loadoutEquipmentDetails.stats?.hitChance * 100).toFixed(
                                        0
                                    )}
                                    %
                                </div>
                            </span>
                            <span>
                                <div className="title text-md text-gray-400">
                                    Critical Hit Chance
                                </div>
                                <div className="title text-lg text-white">
                                    {Number(
                                        loadoutEquipmentDetails.stats?.criticalChance * 100
                                    ).toFixed(0)}
                                    %
                                </div>
                            </span>
                        </div>
                    </div>
                )}

            {slot === "item" && loadoutItemDetails && (
                // getItemNumberCount?.[loadoutItemDetails.id] > 0 &&
                <div className="flex flex-col my-6 px-4 ">
                    <div className="flex items-start gap-4 ">
                        <EquipmentStylizedCard
                            equipmentImages={loadoutItemDetails.image}
                            className="setColor"
                        />

                        <div className="flex flex-col items-start justify-center">
                            <h1 className="text-xl font-proxima"> {loadoutItemDetails.name}</h1>
                            <div className="flex items-center justify-between w-full">
                                <div className="flex flex-col ">
                                    <div className="text-primary text-sm">Item Type</div>
                                    <div className="text-white text-sm">
                                        {loadoutItemDetails.type}
                                    </div>
                                    {loadoutItemDetails?.rank && (
                                        <button className=" flex px-3 py-2 bg-[#161d21] gap-2 text-sm mt-2">
                                            <img src={BADGE} alt="" /> Rank{" "}
                                            {loadoutItemDetails.rank}
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex items-center justify-center py-3 border-y-[1px] border-gray-500 my-4">
                        <PrimaryButton
                            height="40px"
                            buttonName={`${buttonName.item[itemIndex]}  item`}
                            width="200px"
                            onClick={() => {
                                handleButton();
                            }}
                        />
                        {loadoutItemDetails.statBoost && (
                            <button className="flex items-center justify-between gap-1 px-2 py-2 bg-[#161d21] text-sm font-bold">
                                <span className="text-[#70878f] capitalize">
                                    {Object.keys(loadoutItemDetails.statBoost)[0]}
                                </span>{" "}
                                <div className="text-green-400">
                                    {Object.values(loadoutItemDetails.statBoost)[0]}
                                </div>
                            </button>
                        )}
                    </div>
                    <div className="grid text-left py-4 gap-6">
                        <div className="title text-base text-white">
                            {loadoutItemDetails.description}
                        </div>
                    </div>
                </div>
            )}
            {slot === "ability" && loadoutAbilityDetails && (
                // getAbilityNumberCount?.[loadoutAbilityDetails.id] > 0 &&
                <div className="flex flex-col my-6 px-4 ">
                    <div className="flex items-center gap-4 ">
                        <EquipmentStylizedCard
                            equipmentImages={loadoutAbilityDetails.image}
                            className="setColor"
                        />

                        <div className="flex flex-col items-start justify-center">
                            <h1 className="text-xl font-proxima">{loadoutAbilityDetails.name}</h1>

                            <div className="warContinueBtnDiv my-2">
                                <button className="contineWarBtn">
                                    {loadoutAbilityDetails?.type} Ability
                                </button>
                            </div>
                            <span className="text-primary">Sneak Move Slots</span>
                            <div className="flex items-center mt-2 gap-2">
                                <span
                                    className={`border ${equipedLoadout.ability.firstAbility
                                        ? "border-[#e3c96e] text-color=> text-[#e3c96e]"
                                        : abilityIndex === "firstAbility"
                                            ? ""
                                            : "border-slate-600  text-slate-600"
                                        } p-0.5 px-1 text-xs`}
                                >
                                    E
                                </span>
                                <span
                                    className={`border ${equipedLoadout.ability.secondAbility
                                        ? "border-[#e3c96e] text-color=> text-[#e3c96e]"
                                        : abilityIndex === "secondAbility"
                                            ? ""
                                            : "border-slate-600  text-slate-600"
                                        } p-0.5 px-1 text-xs`}
                                >
                                    E
                                </span>
                                <span
                                    className={`border ${equipedLoadout.ability.thirdAbility
                                        ? "border-[#e3c96e] text-color=> text-[#e3c96e]"
                                        : abilityIndex === "thirdAbility"
                                            ? ""
                                            : "border-slate-600  text-slate-600"
                                        } p-0.5 px-1 text-xs`}
                                >
                                    E
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="py-2 my-3 border-y-[1px] border-slate-600 flex justify-center">
                        <PrimaryButton
                            height="40px"
                            buttonName={`${buttonName.ability[abilityIndex]}  ability`}
                            className=""
                            onClick={() => handleButton()}
                        />
                    </div>
                    <div className="section">
                        <div className="text-primary-bold">Description</div>
                        <ul className="list-disc text-sm mt-2">
                            {loadoutAbilityDetails.description}
                        </ul>
                    </div>
                </div>
            )}
        </>
    );
};

export default LoadoutDetails;
