import { useEffect, useState } from "react";
// @ts-ignore
import GifInfo from 'gif-info';
import axios, { AxiosRequestConfig } from "axios";

interface GifLoaderProps {
    GifImg: string | undefined;
    repeatCount: number;    // -1 = play infinitely
    delay: number;
    partialCompletePercent?: number;
    onPartialCompletion?: () => void;
    onComplete?: () => void;
    onLoad?: () => void;    // on fetching gif image
    [props: string]: any;
}

const GifLoader = ({ GifImg, repeatCount, delay, partialCompletePercent, onLoad, onPartialCompletion, onComplete, ...props }: GifLoaderProps) => {

    const [showGif, setShowGif] = useState(false);
    const [imgBase64Buf, setImgBase64Buf] = useState("");

    const options: AxiosRequestConfig = {
        url: GifImg,
        method: 'GET',
        responseType: 'arraybuffer'
    };

    useEffect(() => {
        axios.request(options).then(({ data: buffer }) => {
            onLoad && onLoad();
            var info = GifInfo(buffer);
            const bufArray = Buffer.from(buffer);
            bufArray[bufArray.length - 1] = Math.floor(Math.random() * 256);
            setImgBase64Buf(bufArray.toString("base64"));

            setTimeout(() => { setShowGif(true) }, delay);

            if(onPartialCompletion && partialCompletePercent !== 100) {
                setTimeout(() => {
                    onPartialCompletion();
                }, (info.durationChrome * partialCompletePercent! / 100 + delay))
            }

            setTimeout(() => {
                setShowGif(false);
                onComplete && onComplete();
            }, (info.durationChrome * (repeatCount > 0 ? repeatCount : 1) + delay));

        }).catch(err => {
            console.error(err);
            onComplete && onComplete();
        });
    }, []);

    return (
        imgBase64Buf ?
            <img src={`data:image/gif;base64,${imgBase64Buf}`} hidden={!showGif} {...props} /> :
            <></>
    );
};

export default GifLoader;