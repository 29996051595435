const LootcratePlainCard = ({
    image,
    width = "100%",
    height,
    bgColor,
    number,
    className,
    crateWidth,
    crateHeight,
    maxWidth = "100%",
    imageClass,
}: any) => {
    return (
        <div
            style={{ width: crateWidth, height: crateHeight }}
            className={`cratePlainCardWrapper  ${bgColor ?? "lightBlue"}  ${className}`}
        >
            <img
                src={image}
                style={{ width, height, maxWidth }}
                className={`max-h-[200px] p-[15px] object-contain ${imageClass}`}
            />

            {number && (
                <div className="lootCrateNumber drop-shadow-sm">
                    <span className="text-sm font-normal lowercase">x</span>
                    {number}
                </div>
            )}
        </div>
    );
};

export default LootcratePlainCard;
