import { NFTsDetails } from "@interfaces/nft.interface";
import { Dispatch, SetStateAction } from "react";

export const AsyncState = {
    IDLE: "idle",
    LOADING: "loading",
    SUCCESS: "success",
    FAILED: "failed",
    CONFIRM: "confirm",
    CANCELED: "canceled",
};

export interface IRentalModal {
    // selectedChar: NFTsDetails | null;
    selectedChar: any | null;
    isVisible: boolean;
    maxDaysForRent?: number;
    setRentalModal: Dispatch<
        SetStateAction<"rent" | "update" | "remove" | "lend" | "claim" | null>
    >;
    setSelectedChar?: any;
}

export const RentalLoadingTexts = {
    LEND: {
        [AsyncState.LOADING]: {
            title: "Listing GangstaBet for rent...",
            description: "Please wait for your NFT to be listed in the rental market ..",
        },
        [AsyncState.FAILED]: {
            title: "Listing Failed",
            description: " Could not complete the renting process, please try again.",
        },
    },
    REMOVE: {
        [AsyncState.LOADING]: {
            title: "Removing from market",
            description: "Please wait for your NFT to be removed from the market ..",
        },
        [AsyncState.FAILED]: {
            title: "Removal Failed",
            description: " Could not complete the removal process, please try again.",
        },
    },
    RELIST: {
        [AsyncState.LOADING]: {
            title: "Relisting on market",
            description: "Please wait for your NFT to be relist on the market ..",
        },
        [AsyncState.FAILED]: {
            title: "Removal Failed",
            description: " Could not complete the removal process, please try again.",
        },
    },
    RENT: {
        [AsyncState.LOADING]: {
            title: "Readying your rented GangstaBet",
            description: "Please wait, preparing your GangstaBet.",
        },
        [AsyncState.FAILED]: {
            title: "Renting Failed",
            description: " Could not complete the renting process, please try again.",
        },
    },
    UPDATE: {
        [AsyncState.LOADING]: {
            title: "Updating rent rate",
            description: "Please wait for your NFT rent rate to be updated ..",
        },
        [AsyncState.FAILED]: {
            title: "Update rent rate Failed",
            description: " Could not complete the rent rate update process, please try again.",
        },
    },
    CLAIM: {
        [AsyncState.LOADING]: {
            title: "Claiming your fee",
            description: "Just a moment, we’re collecting your fee ..",
        },
        [AsyncState.FAILED]: {
            title: "Claim Fee Failed",
            description: " Could not complete the fee claim process, please try again.",
        },
    },
};
