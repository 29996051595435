import { usersAvailableLootboxBalances } from "@services/icon.service";
import { CROPPEDGRENADE, ITEM, PISTOL, SHIELD } from "@constants/images";
import { getLootBoxDetails } from "@api/api";

interface IGetUserLootBox {
  address: string;
}

export const getUserLootboxDetails = async ({
  walletAddress,
  setUserLootboxDetails,
}: any) => {
  const userLootbox = await usersAvailableLootboxBalances(walletAddress);

  const userAvailableLoot = [];
  for await (let loot of userLootbox) {
    try {
      const id = Number(loot.id);
      const count = Number(loot.count);
      const result = await getLootBoxDetails(id);
      const lootboxChances = [
        CROPPEDGRENADE,
        CROPPEDGRENADE,
        CROPPEDGRENADE,
        CROPPEDGRENADE,
        ITEM,
        ITEM,
        ITEM,
        ITEM,
        PISTOL,
        PISTOL,
        PISTOL,
        PISTOL,
      ];
      const image = result.data.imageUrl;
      userAvailableLoot.push({
        id,
        image,
        count,
        lootboxChances,
        name: result.data.name,
        description: result.data.description,
      });
    } catch (e) {
      console.log(e);
    }
  }
  setUserLootboxDetails(userAvailableLoot);
};
