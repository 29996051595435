import React, { useState } from "react";
import L from "leaflet";
import { Polygon, Polyline, Tooltip, Marker, Popup } from "react-leaflet";
import { MARKER, MAP_WAR_MARKER_PRESENT } from "@constants/images";
import { renderToStaticMarkup } from "react-dom/server";
import WarDetailsSidebar from "@pages/warDetails.sidebar";

interface polygonProps {
  // text: string;
  pathOptions: any;
  eventHandlers: any;
  positions: any;
}

const coordinates = [
  [-138.9881377435, 78.55300952600001],
  [-111.2336305, 160.65123393500002],
  [-107.891437435, 134.06917641500002],
  [-138.6810509185, 162.90812774],
  [-146.15756557, 113.3927483855],
  [-109.0133538835, 135.678466865],
  [-116.0419882315, 94.348156421],
  [-122.66215823050001, 111.75088340900001],
  [-143.1976372945, 182.26003976],
];

const PolygonWithText = (props: any) => {
  // console.log("props", props.war);
  const { war, pathOptions, eventHandlers, positions } = props;
  const [open, setOpen] = useState(false);
  const [warDetail, setWarDetail] = useState<any>();
  const center = L.polygon(positions)
    .getBounds()
    .getCenter();
  // const text_html = L.divIcon({
  //   html: `<p class='district_name_label' style="background:transparent;color:${pathOptions.color};opacity:0.8">${text}</p>`,
  // });

  // var map = L.map("map").setView([51.505, -0.09], 13);

  // var marker = L.marker([51.5, -0.09]).addTo(map);

  // marker.bindPopup("<b>Hello world!</b><br>I am a popup.").openPopup();
  const [changeIcon, setChangeIcon] = useState(false);
  const customMarker = new L.Icon({
    iconUrl: open ? MARKER : MAP_WAR_MARKER_PRESENT,
    iconSize: [40, 80], // set the size of your custom marker
  });

  const handleMarkerClick = (item: any) => {
    setWarDetail(item);
    // const modalContent = renderToStaticMarkup(
    //   <ConnectModal isVisible={open} setIsVisible={setOpen} />
    // );
    // L.popup()
    //   .setLatLng(e.latlng)
    //   .setContent(modalContent);
    setChangeIcon(true);
    setOpen(true);
  };

  // console.log("coordinate", center.lat, center.lng);
  // console.log("position=====>", positions);

  return (
    <>
      {!(new Date(Number(war.endTime) / 1000) < new Date()) && (
        <Polygon
          pathOptions={pathOptions}
          eventHandlers={eventHandlers}
          positions={positions}
        >
          {/* <Tooltip sticky>{text}</Tooltip> */}
          {/* <Marker
        position={{
          lat: center.lat + 3,
          lng: center.lng - 5,
        }}
        icon={text_html}
      /> */}
          <Marker
            key={`marker-`}
            position={{
              lat:
                war.coordinates &&
                typeof war.coordinates === "string" &&
                war.coordinates.split(",")
                  ? Number(war.coordinates.split(",")[0].replace(" ", ""))
                  : 0,
              lng:
                war.coordinates &&
                typeof war.coordinates === "string" &&
                war.coordinates.split(",")
                  ? Number(war.coordinates.split(",")[1].replace(" ", ""))
                  : 0,
            }}
            icon={customMarker}
            eventHandlers={{
              click: () => {
                handleMarkerClick(war);
              },
            }}
          >
            {/* <Popup>
          <div className="custom-popup">
            <h2>Custom Popup</h2>
            <p>Here is some custom content for your popup.</p>
          </div>
        </Popup> */}
          </Marker>
          {open && (
            <WarDetailsSidebar
              warDetail={warDetail}
              open={open}
              setOpen={setOpen}
            />
          )}
        </Polygon>
      )}
    </>
  );
};

export default PolygonWithText;
