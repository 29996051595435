import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getNFTInformation, objectMap } from "@utils/getNFTInformation";
import { useDetailGlobalContext } from "./detailProvider";
import { getActiveRentals, getMaxDaysForRent, getRentalIndex, userClaimable } from "@services/icon.service";
import { NFT_RENT_STATUS } from "@constants/nft";
import { RootState } from "@redux/store";

const nftInformation = createContext<any>(null);

const testBatchData = [{
  gangstaBetDetails: {
    nftId: 289,
    nftImage: "https://d2l8dezwbqkb9.cloudfront.net/ipfs/img/4902.gif",
    nftName: "GangstaBet #289",
    ownedBy: "hx8200d196492cf7cf659db6b016630228a1888565",
    gender: "Male",
    race: "Cyborg",
    classType: "Investigator",
    levelNumber: 1,
    characterType: "Gangster",
    levelType: "Junior Detective",
  },
  defaultWeapons: {
    weaponName: "Knife",
    weaponType: "Default Weapon",
    Ranged: "Melee",
    image: "https://d3o1y7koafmnju.cloudfront.net/default_equipment_images/16.png",
  },
  skills: {
    primary: {
      strength: 2.77,
      accuracy: 2.62,
      mastery: 2.42,
      agility: 2.0700000000000003,
      luck: 2.69,
      total: 12.57,
    },
    secondary: {
      health: 509,
      speed: 15,
      duraAmmo: 0.02,
      dodgeChance: 0,
      hitChance: 0.01,
      criticalChance: 0,
      supportEffectiveness: 0,
      SupportTriggerChance: 0,
      meleeDamage: 0.01,
    },
  },
  rental: {
    borrower: "hx0000000000000000000000000000000000000000",
    claimableAmount: "0",
    deListed: "0",
    expirationTimestamp: "0",
    id: "1",
    listedTimestamp: "1707721893256986",
    nftId: "289",
    owner: "hx77abefe4a33e31c208afee301150fb61ad3f0be2",
    period: "0",
    rentFee: "10000000000000000000",
    rentType: "0",
    startTimestamp: "0",
    status: "1",
  },
  hospitalization: {
    isHospitalized: false,
    outOfHospitalizationTimer: 3476546,
  },
  lockStatus: false,
},
{
  gangstaBetDetails: {
    nftId: 297,
    nftImage: "https://d2l8dezwbqkb9.cloudfront.net/ipfs/img/4944.gif",
    nftName: "GangstaBet #297",
    ownedBy: "hx71c278bcd023e8bb889c91b006666152750e9f10",
    gender: "Female",
    race: "Cyborg",
    classType: "Commander",
    levelNumber: 1,
    characterType: "Gangster",
    levelType: "Mob Boss",
  },
  defaultWeapons: {
    weaponName: "Revolver",
    weaponType: "Default Weapon",
    Ranged: "Range",
    image: "https://d3o1y7koafmnju.cloudfront.net/default_equipment_images/2.png",
  },
  skills: {
    primary: {
      strength: 2.77,
      accuracy: 2.62,
      mastery: 2.42,
      agility: 2.0700000000000003,
      luck: 2.69,
      total: 12.57,
    },
    secondary: {
      health: 509,
      speed: 15,
      duraAmmo: 0.02,
      dodgeChance: 0,
      hitChance: 0.01,
      criticalChance: 0,
      supportEffectiveness: 0,
      SupportTriggerChance: 0,
      meleeDamage: 0.01,
    },
  },
  rental: {
    borrower: "hx0000000000000000000000000000000000000000",
    claimableAmount: "0",
    deListed: "0",
    expirationTimestamp: "0",
    id: "2",
    listedTimestamp: "1707721913164342",
    nftId: "297",
    owner: "hx77abefe4a33e31c208afee301150fb61ad3f0be2",
    period: "0",
    rentFee: "15000000000000000000",
    rentType: "0",
    startTimestamp: "0",
    status: "1",
  },
  hospitalization: {
    isHospitalized: false,
    outOfHospitalizationTimer: 3476546,
  },
  lockStatus: false,
},]

const NFTInformation = ({ children }: any) => {
  const [nftsDetails, setNftData] = useState<any>([]);
  const [rentNftsDetails, setRentNftData] = useState<any>([]);
  const [currentBatchIndex, setCurrentBatchIndex] = useState(0);
  const [currentRentalBatchIndex, setCurrentRentalBatchIndex] = useState(0);
  const [rentalIndex, setRentalIndex] = useState<number | null>(null);
  const [changeDataToggle, setChangeDataToggle] = useState(false);
  const BATCH_LOAD = 5;
  const { isWalletConnected, walletAddress } = useSelector((state: any) => state.wallet);
  const { ownedNft } = useSelector((state: RootState) => state.inventory);
  const [rentalInformation, setRentalInformation] = useState({
    hasClaimableRentFee: false,
    fees: {
      icx: 0,
      crown: 0,
    },
    maxRentDay: 0
  })
  const [gangstabetLoading, setGangstabetLoading] = useState(true);
  const [gangstabetRentalLoading, setGangstabetRentalLoading] = useState(true);

  const [tempNftsDetails, setTempNftDetails] = useState<any>([]);
  const [tempRentNftsDetails, setTempRentNftDetails] = useState<any>([]);

  const getRentalInformation = async (address: string) => {
    const { icx, crown } = objectMap(await userClaimable(address) ?? {}, (v: any, k: any) => Number(v / (10 ** 18)));
    console.log({ icx, crown });
    setRentalInformation({
      hasClaimableRentFee: Boolean(icx + crown),
      fees: { icx, crown },
      maxRentDay: Number(await getMaxDaysForRent())
    })
  }

  const fetchNftData = async (
    batchIds: string[],
    setter: React.Dispatch<React.SetStateAction<any[]>>,
  ) => {
    const promises = batchIds.map(async (id: any) => {
      const response = await getNFTInformation(id);
      return response;
    });

    // const batchData = await Promise.all(promises);
    const batchData = testBatchData;
    setter((prevData: any) => {
      const stringArray = Array.from(
        new Set([
          ...prevData.map((item: any) => JSON.stringify(item)),
          ...batchData.map((item: any) => JSON.stringify(item)),
        ])
      );
      return stringArray.map((item: any) => JSON.parse(item));
    });
  };

  const getRentalIndexValue = async () => {
    setRentalIndex(Number(await getRentalIndex()));
  }

  const updateNftStatus = useCallback(() => {
    if (!walletAddress) return;
    if (tempNftsDetails.length === 0 && tempRentNftsDetails.length === 0) return;

    const today = new Date()
    //TODO: change time in production
    // const tomorrow = today.setDate(today.getDate() + 1)
    const tomorrow = today.setMinutes(today.getMinutes() + 10)

    //UI ma chahin rent expire bhayeko chha ra owner ko rent duration banki chha bhane available dekhaunu parla
    const availableForRentNFTs_owned = tempNftsDetails.filter((nft: any) => (
      //NFT which is available for rent
      (nft.rental.status === NFT_RENT_STATUS.AVAILABLE_FOR_RENT
        //NFT which is prev rented but its rental time is expired
        || (nft.rental.status === NFT_RENT_STATUS.IN_RENT && Date.now() > Math.floor(nft.rental.expirationTimestamp / 1000)))
      //NFT must not exceed the duration - 1 day (since we are giving for atleast 1 day rent thus 23:59 shoudh get minused)
      && Math.floor(nft.rental.expiry / 1000) > tomorrow)
    ).map((item: any) => ({ ...item, rental: { ...item.rental, status: NFT_RENT_STATUS.AVAILABLE_FOR_RENT } }))

    const availableForRentNFTs_rental = tempRentNftsDetails.filter((nft: any) => (
      //NFT which is available for rent
      (nft.rental.status === NFT_RENT_STATUS.AVAILABLE_FOR_RENT
        //NFT which is prev rented but its rental time is expired
        || (nft.rental.status === NFT_RENT_STATUS.IN_RENT && Date.now() > Math.floor(nft.rental.expirationTimestamp / 1000)))
      //NFT must not exceed the duration - 1 day (since we are giving for atleast 1 day rent thus 23:59 shoudh get minused)
      && Math.floor(nft.rental.expiry / 1000) > tomorrow)
    ).map((item: any) => ({ ...item, rental: { ...item.rental, status: NFT_RENT_STATUS.AVAILABLE_FOR_RENT } }))

    const rentedNFTs = tempRentNftsDetails.filter((nft: any) => nft.rental.status === NFT_RENT_STATUS.IN_RENT && nft.rental.rentedBy === walletAddress && Date.now() < Math.floor(nft.rental.expirationTimestamp / 1000)).map((item: any) => ({ ...item, rental: { ...item.rental, status: NFT_RENT_STATUS.RENTED } }))

    // rent duration expire bhayeko chha bhane no available for rent dekhaune
    const notAvailableForRentNFTs_owned = tempNftsDetails.filter((nft: any) => Math.floor(nft.rental.expiry / 1000) < tomorrow || nft.rental.status === NFT_RENT_STATUS.DE_LISTED || nft.rental.status === NFT_RENT_STATUS.COMPLETED).map((item: any) => ({ ...item, rental: { ...item.rental, status: NFT_RENT_STATUS.NOT_AVAILABLE_FOR_RENT } }))
    const notAvailableForRentNFTs_rental = tempRentNftsDetails.filter((nft: any) => Math.floor(nft.rental.expiry / 1000) < tomorrow || nft.rental.status === NFT_RENT_STATUS.DE_LISTED || nft.rental.status === NFT_RENT_STATUS.COMPLETED).map((item: any) => ({ ...item, rental: { ...item.rental, status: NFT_RENT_STATUS.NOT_AVAILABLE_FOR_RENT } }))

    const expyringNFTs_owned = tempNftsDetails.filter((nft: any) => nft.rental.owner === walletAddress && Math.floor(nft.rental.expiry / 1000) < tomorrow && Math.floor(nft.rental.expiry / 1000) > Date.now()).map((item: any) => ({ ...item, rental: { ...item.rental, status: NFT_RENT_STATUS.EXPIRING } }))

    setNftData((prevData: any) => {
      const stringArray = [
        ...prevData,
        ...tempNftsDetails,
        ...availableForRentNFTs_owned,
        ...notAvailableForRentNFTs_owned,
        ...expyringNFTs_owned,
        ...rentedNFTs
      ].reduceRight((acc, obj) => {
        if (!acc[obj.nftId]) {
          acc[obj.nftId] = obj;
        }
        return acc;
      }, {});
      return Object.values(stringArray);
    });

    setRentNftData((prevData: any) => {
      const stringArray = [
        ...prevData,
        ...tempRentNftsDetails,
        ...notAvailableForRentNFTs_rental,
        ...availableForRentNFTs_rental,
      ].reduceRight((acc, obj) => {
        if (!acc[obj.nftId]) {
          acc[obj.nftId] = obj;
        }
        return acc;
      }, {});

      return Object.values(stringArray);
    });

    console.log('nfts status: ', tempNftsDetails, tempRentNftsDetails, rentedNFTs, expyringNFTs_owned, availableForRentNFTs_owned, availableForRentNFTs_rental, notAvailableForRentNFTs_owned, notAvailableForRentNFTs_rental);

  }, [tempNftsDetails, tempRentNftsDetails, walletAddress])

  useEffect(() => {
    if (isWalletConnected) {
      if (ownedNft.length === 0) {
        setTimeout(() => {
          setGangstabetLoading(false);
        }, 4000);
      }
      else if (currentBatchIndex * BATCH_LOAD < ownedNft.length) {
        const startIndex = currentBatchIndex * BATCH_LOAD;
        const endIndex = (currentBatchIndex + 1) * BATCH_LOAD;
        const batchIds = ownedNft.slice(startIndex, endIndex);
        fetchNftData(batchIds, setTempNftDetails);
        setTimeout(() => {
          setGangstabetLoading(false);
        }, 2000);
        setCurrentBatchIndex((prevIndex) => prevIndex + 1);
      }
      else updateNftStatus()
    } else {
      setNftData([]);
      setCurrentBatchIndex(0);
    }
  }, [ownedNft, currentBatchIndex, changeDataToggle, isWalletConnected, updateNftStatus]);

  useEffect(() => {
    if (isWalletConnected && rentalIndex !== null) {
      if (rentalIndex === 0) {
        setTimeout(() => {
          setGangstabetRentalLoading(false);
        }, 4000);
      }
      else if (currentRentalBatchIndex * BATCH_LOAD < rentalIndex) {
        (async () => {
          const nfts = await getActiveRentals(
            (currentRentalBatchIndex * BATCH_LOAD).toString(),
            BATCH_LOAD.toString(),
            "asc"
          );
          const nftIds = nfts.map((nft: any) => nft?.nftId).filter(Boolean);
          fetchNftData(nftIds, setTempRentNftDetails);
          setTimeout(() => {
            setGangstabetRentalLoading(false);
          }, 2000);
          setCurrentRentalBatchIndex((prevIndex) => prevIndex + 1);
        })();
      }
      else updateNftStatus()
    } else {
      // setRentNftData([]);
      // setCurrentRentalBatchIndex(0);
    }
  }, [rentalIndex, currentRentalBatchIndex, isWalletConnected, updateNftStatus]);

  useEffect(() => {
    (async () => {
      if (rentalIndex === null) {
        await getRentalIndexValue()
        setRentNftData([]);
        setCurrentRentalBatchIndex(0);
      }
    })();

  }, [rentalIndex]);

  useEffect(() => {
    if (!walletAddress) return;
    (async () => {
      await getRentalInformation(walletAddress)
    })()
  }, [walletAddress])

  return (
    <nftInformation.Provider
      value={{
        nftsDetails,
        rentNftsDetails,
        setRentNftData,
        gangstabetLoading,
        gangstabetRentalLoading,
        setNftData,
        setCurrentBatchIndex,
        currentBatchIndex,
        currentRentalBatchIndex,
        setChangeDataToggle,
        changeDataToggle,
        rentalIndex,
        setRentalIndex,
        setCurrentRentalBatchIndex,
        rentalInformation,
        setRentalInformation,
        getRentalInformation
      }}
    >
      {children}
    </nftInformation.Provider>
  );
};

const useNFTDetailGlobalContext = () => {
  return useContext(nftInformation);
};

export { NFTInformation, useNFTDetailGlobalContext };
