import RentGangstabet from "@components/gangstabet/rent.gangstabet.component";
import { useNFTInformation } from "@context/nftInfoProvider";
import { RootState } from "@redux/store";
import { getActiveRentalsCount } from "@services/icon.service";
import { TOTAL_NUMBER_PER_PAGE } from "@type/rental.type";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const RentalMarket = () => {
    const [rentalTotalCount, setRentalTotalCount] = useState(0);
    const { isWalletConnected } = useSelector((state: RootState) => state.wallet);
    const { reRenderCollectionToggler } = useNFTInformation();
    useEffect(() => {
        (async () => {
            const marketplaceRentalResponse = await getActiveRentalsCount();
            const marketplaceCount = Math.ceil(marketplaceRentalResponse / TOTAL_NUMBER_PER_PAGE);
            const marketTotalPage = marketplaceCount > 0 ? marketplaceCount - 1 : marketplaceCount;
            setRentalTotalCount(marketTotalPage);
        })()
    }, [isWalletConnected, reRenderCollectionToggler])
    return <RentGangstabet totalPageCount={rentalTotalCount} />
}

export default RentalMarket;