import { RootState } from "@redux/store";
import { ReactNode, createContext, useContext, useEffect, useState } from "react"
import { useSelector } from "react-redux";
import { getAllOwnedNFT } from "@api/api";
import { IResponseGangstaBetWalletInfoWithRental } from "@interfaces/nftDetails.interface";
import { formatGangstaBetInformation } from "@utils/rental/formatGb";
import { objectMap } from "@utils/getNFTInformation";
import { getMaxDaysForRent, getPlatformFee, userClaimable } from "@services/icon.service";


const NFTInformationContext = createContext<any>(null);


const NFTInformationProvider = ({ children }: { children: ReactNode }) => {
    const { isWalletConnected, walletAddress } = useSelector((state: RootState) => state.wallet);
    const { ownedNft } = useSelector((state: RootState) => state.inventory)
    const [walletNFTDetails, setWalletNFTDetails] = useState<any>([]);
    const [gangstaBetLoading, setGangstaBetLoading] = useState(true);
    const totalPageCount = Math.ceil(ownedNft.length / 20);
    const [currentPage, setCurrentPage] = useState(1);
    const [platformFees, setPlatformFees] = useState(0);
    const [reRenderCollectionToggler, setRerenderCollectionToggler] = useState(false)
    const [rentalInformation, setRentalInformation] = useState({
        hasClaimableRentFee: false,
        fees: {
            icx: 0,
            crown: 0,
        },
        maxRentDay: 30
    })

    const getNFTInformation = async (address: string, pageNumber: number) => {
        const allOwnedNftsResponse = await getAllOwnedNFT(address, pageNumber);
        const nftDetailedInfo: IResponseGangstaBetWalletInfoWithRental = allOwnedNftsResponse.data;
        return nftDetailedInfo;
    }

    const getRentalInformation = async (address?: string) => {
        if (!isWalletConnected) return;
        const { icx, crown } = objectMap(await userClaimable(address ?? walletAddress) ?? {}, (v: any, k: any) => Number(v / (10 ** 18)));
        setRentalInformation({
            hasClaimableRentFee: Boolean(icx + crown),
            fees: { icx, crown },
            maxRentDay: Number(await getMaxDaysForRent())
        });
    }

    const handlePlatformFee = async () => {
        const fee = await getPlatformFee();
        setPlatformFees(Number(fee));
    }
    useEffect(() => {
        handlePlatformFee();
    }, [])
    useEffect(() => {
        if (!walletAddress) return;
        (async () => {
            await getRentalInformation(walletAddress);
        })()
    }, [reRenderCollectionToggler, isWalletConnected]);

    useEffect(() => {
        if (isWalletConnected && walletAddress) {
            (async () => {
                setGangstaBetLoading(true);
                try {
                    const nftDetailedInfo = await getNFTInformation(walletAddress, currentPage);
                    const rentalStructuredInfo = formatGangstaBetInformation(nftDetailedInfo, "WALLET_NFT", walletAddress);
                    setWalletNFTDetails(rentalStructuredInfo);
                } catch (error) {
                    setWalletNFTDetails([])
                } finally {
                    setTimeout(() => {
                        setGangstaBetLoading(false)
                    }, 1000)
                }
            })()
        } else {
            setWalletNFTDetails(null);
            setGangstaBetLoading(false);
            setCurrentPage(1);
        }

    }, [isWalletConnected, currentPage, reRenderCollectionToggler])

    return <NFTInformationContext.Provider value={
        { walletNFTDetails, gangstaBetLoading, setCurrentPage, currentPage, totalPageCount, reRenderCollectionToggler, setRerenderCollectionToggler, rentalInformation, setRentalInformation, getRentalInformation, platformFees }
    }>{children} </NFTInformationContext.Provider >

}

const useNFTInformation = () => {
    return useContext(NFTInformationContext)
}

export { NFTInformationProvider, useNFTInformation }