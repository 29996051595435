import VolumeController from "@components/sound/volume.controller"
import { BsFillVolumeUpFill } from "react-icons/bs";
import React, { useRef, useEffect, useState } from 'react';
import { handleDropdownClose } from "@utils/closeDropdown";

export const VolumeHandler = () => {
    const [showController, setShowController] = useState(false);
    const volumeRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        handleDropdownClose(volumeRef, setShowController);
    }, []);

    const handleClick = () => {
        setShowController(prev => !prev);
    }
    return (
        <div ref={volumeRef}  style={{cursor: "pointer", position: "absolute", top: "10px", right: "0"}}>
            <BsFillVolumeUpFill
                onClick={handleClick} 
                className="mr-3"
                size={30}
                color={`${showController ? "yellow" : "white"}`}
            />
            {showController && <VolumeController />}
        </div>
    )
}