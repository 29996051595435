import IntervalSelector from "./IntervalSelector";
import { INFT } from "./interfaces/gangstabet.interface";
import { useSelector } from "react-redux";
import PrimaryButton from "@components/buttons/primary.button";
import { EMPTY_HAT } from "@constants/images";
import GangstabetCollection from "@components/gangstabet/collections.gangstabet.component";
import RentGangstabet from "@components/gangstabet/rent.gangstabet.component";
import { PLAY_NORMAL_CLICK } from "@constants/audio";
import RentedGangstaBet from "@components/gangstabet/rented.gangstabet";
import { useEffect, useState } from "react";
import { getActiveRentalsCount, getTenantRentalHistoryCount } from "@services/icon.service";
import { RootState } from "@redux/store";
import { useNFTInformation } from "@context/nftInfoProvider";
import { TOTAL_NUMBER_PER_PAGE, ZERO_ADDRESS } from "@type/rental.type";
import ConnectDisconnectWallet from "@components/header/ConnectDisconnectWallet";
import { HYPERLINKS } from "@misc/links";

export const DefaultEmptyGangstaBetPage = ({ title, description, linkTo, buttonName = "Buy NFTs" }: { title: string, description: string, linkTo: () => void, buttonName: string }) => {
    return <div className="flex flex-col gap-2 w-full h-full items-center justify-center">
        <img src={EMPTY_HAT} alt="empty hat" />
        <p className="text-white text-3xl big-shoulders uppercase">
            {title}
        </p>
        <p className="text-gray w-1/3 text-center">
            {description}
        </p>
        <PrimaryButton onClick={() => {
            linkTo();
            PLAY_NORMAL_CLICK();
        }} buttonName={buttonName} />
    </div>
};

export const DefaultWalletNotConnected = ({ setuserConnectModal }: { setuserConnectModal: React.Dispatch<React.SetStateAction<boolean>> }) => {
    return <div className="flex flex-col gap-2 w-full h-full items-center justify-center">
        <img src={EMPTY_HAT} alt="empty hat" />
        <p className="text-white text-3xl big-shoulders uppercase">
            Wallet not connected
        </p>
        <p className="text-gray w-1/3 text-center">
            Please connect your wallet to view your gangstaBets.
        </p>
        <PrimaryButton onClick={() => {
            setuserConnectModal(true);
            PLAY_NORMAL_CLICK();
        }} buttonName="Connect Wallet   " />
    </div>
}

export const DefaultEmptyTexts = {
    RENTED_PAGE: {
        title: "No rented GangstaBets in your wallet",
        description: "It looks like your NFT rented GangstaBet inventory is currently empty. Explore our rental marketplace to rent new NFTs.",
        link: "/rental"
    },
    GANGSTABET_PAGE: {
        title: " No gangstaBets in your wallet",
        description: "It looks like your NFT inventory is currently empty. Explore our marketplace to purchase new NFTs.",
        link: HYPERLINKS.CRAFT_MARKETPLACE
    }
}

const Gangstabet = ({ defaultNFT }: { defaultNFT: INFT }) => {
    const [userConnectModal, setuserConnectModal] = useState(false);
    const { isWalletConnected, walletAddress } = useSelector((state: RootState) => state.wallet);
    const { reRenderCollectionToggler } = useNFTInformation();
    const [rentalTotalCount, setRentalTotalCount] = useState({ totalRentedPageCount: 0, totalRentalMarketPageCount: 0 })
    useEffect(() => {
        (async () => {
            if (defaultNFT === "Marketplace") {
                // const marketplaceRentalResponse = await getActiveRentalsCount();
                // const marketplaceCount = Math.ceil(marketplaceRentalResponse / TOTAL_NUMBER_PER_PAGE);
                // const marketTotalPage = marketplaceCount > 0 ? marketplaceCount - 1 : marketplaceCount;
                // setRentalTotalCount((prev) => ({ ...prev, totalRentalMarketPageCount: marketTotalPage }));
            } else if (defaultNFT === "Rent") {
                const rentedResponse = await getTenantRentalHistoryCount(walletAddress ?? ZERO_ADDRESS);
                const rentedCount = Math.ceil(rentedResponse / TOTAL_NUMBER_PER_PAGE);
                const rentedTotalPage = rentedCount > 0 ? rentedCount - 1 : rentedCount;
                setRentalTotalCount((prev) => ({ ...prev, totalRentedPageCount: rentedTotalPage }));
            }
        })()
    }, [isWalletConnected, reRenderCollectionToggler, defaultNFT])

    // <DefaultEmptyGangstaBetPage title={DefaultEmptyTexts.RENTED_PAGE.title} description={DefaultEmptyTexts.RENTED_PAGE.description} linkTo={() => { navigate(DefaultEmptyTexts.RENTED_PAGE.link) }} />}
    // <DefaultEmptyGangstaBetPage title={DefaultEmptyTexts.GANGSTABET_PAGE.title} description={DefaultEmptyTexts.GANGSTABET_PAGE.description} linkTo={() => { window.open(DefaultEmptyTexts.GANGSTABET_PAGE.link, "_blank") }} />

    return (
        <div className="flex justify-center min-w-[1400px]">
            <div className="pt-8 w-fit flex flex-col">
                <IntervalSelector currentNFT={defaultNFT} />
                <div className="gangstabetPageWrapper leaderboard-inner-container minWidthContainer !overflow-hidden container mx-auto">
                    {isWalletConnected && defaultNFT === "Rent" ? <RentedGangstaBet totalPageCount={rentalTotalCount.totalRentedPageCount} /> : !isWalletConnected && defaultNFT === "Rent" ? <DefaultWalletNotConnected
                        setuserConnectModal={setuserConnectModal} /> : isWalletConnected && defaultNFT === "Collection" ? <GangstabetCollection /> : !isWalletConnected && defaultNFT === "Collection" && <DefaultWalletNotConnected setuserConnectModal={setuserConnectModal} />}
                </div>
            </div>
            <ConnectDisconnectWallet userConnectModal={userConnectModal} setuserConnectModal={setuserConnectModal} />
        </div>
    );
};

export { Gangstabet };
