import { useEffect, useState, useRef } from "react";
import { AiOutlineDown } from "react-icons/ai";
import WarItem from "@components/warPage/gangWars/warItem.component";
import { handleDropdownClose } from "@utils/closeDropdown";
import {
    durationOptions,
    entryFeeOptions,
    filterWarsOptions,
    sortByOptions,
} from "@type/dropDownOption.type";
import { useSelector } from "react-redux";
import {
    getActiveGangwars,
    getLobbyLockdownPeriod,
    hasWalletParticipated,
} from "@services/icon.service";
import React from "react";
import { LOADING, LOADING_WAR_LIST } from "@constants/images";
import PrimaryButton from "@components/buttons/primary.button";
import { DISCORD } from "@constants/images";
import { getUpComingWars } from "@api/api";
import UpComingWar from "./upComingWar.component";
import { sortByStartTime, sortByStartTimeForFutureWar } from "@misc/filterBasedUponTime";

interface IFilterOptions {
    name: string;
    value: string;
}

interface IWarListFilter {
    duration: IFilterOptions;
    entryFee: IFilterOptions;
    wars: IFilterOptions;
}
const MAP_WAR_MAPPER_NUMBER = 9803955;
const GangWars = () => {
    const [activeGangwar, setActiveGangwar] = useState([]);
    const [upComingWars, setUpComingWar] = useState<any[]>([]);
    const [showUpComingWar, setShowUpComingWar] = useState(true);

    const gangwars = async () => {
        try {
            const data = await getActiveGangwars();
            try {
                const comingWar = await getUpComingWars();
                console.log("comingWar", comingWar.data.scheduledWars);
                const warList: any = await sortByStartTimeForFutureWar(
                    comingWar.data.scheduledWars
                );
                setUpComingWar(warList);
            } catch (e) {
                setUpComingWar([]);
            }
            const reversedList = data?.activeSimulations ? data?.activeSimulations?.reverse() : [];
            const simList = reversedList.map((wars: any) => ({
                ...wars.simulation,
                rewards: wars.reward,
                allowedLevels: wars.allowedLevels,
                allowedGenders: wars.allowedGenders,
                allowedAccessories: wars.allowedAccessories,
                allowedTypes: wars.allowedTypes,
                allowedWeapons: wars.allowedWeapons,
            }));

            const sortedSimulations = await sortByStartTime(simList);

            setActiveGangwar((prev: any) => {
                if (JSON.stringify(sortedSimulations) !== JSON.stringify(prev)) {
                    return sortedSimulations;
                } else {
                    return prev;
                }
            });
        } catch (error) {
            console.log("Error fetching active gangwars:", error);
        }
    };
    useEffect(() => {
        gangwars();
        const interval = setInterval(gangwars, 4000);
        return () => clearInterval(interval);
    }, []);
    const { isWalletConnected, walletAddress } = useSelector((state: any) => state.wallet);
    const { ownedNft } = useSelector((state: any) => state.inventory);
    const [showDurationDropdown, setShowDurationDropdown] = useState<boolean>(false);
    const [lobbyLockingTime, setLobbyLockingTime] = useState<number>();

    useEffect(() => {
        async function lockingTime() {
            const lockingInMillisecond = await getLobbyLockdownPeriod();
            setLobbyLockingTime(lockingInMillisecond);
        }
        lockingTime();
    }, []);

    const [showEntryFreeFilterDropdown, setShowEntryFreeFilterDropdown] = useState<boolean>(false);
    const [showSortByDropdown, setShowSortByDropdown] = useState<boolean>(false);

    const [warListFiter, setWarListFilter] = useState<IWarListFilter>({
        duration: durationOptions[0],
        entryFee: entryFeeOptions[0],
        wars: filterWarsOptions[0],
    });

    const [sortByOption, setSortByOption] = useState<any>(sortByOptions[0]);

    const durationDropdownRef = useRef<any>(null);
    const entryFeeDropdownRef = useRef<any>(null);
    const sortByDropdownRef = useRef<any>(null);

    useEffect(() => {
        handleDropdownClose(durationDropdownRef, setShowDurationDropdown);
        handleDropdownClose(entryFeeDropdownRef, setShowEntryFreeFilterDropdown);
        handleDropdownClose(sortByDropdownRef, setShowSortByDropdown);
    });

    const [filteredWars, setFilteredWars] = useState<any>([]);
    const [filterLoading, setFilterLoading] = useState(false);
    const [forFirstTime, setForFirstTime] = useState(true);
    const [isWarAvailable, setIsWarAvailable] = useState(true);

    const [upcommingWarLists, setUpcomingWarLists] = useState<any[]>([]);

    async function filterUpComingWars(preventLoading?: boolean) {
        if (!preventLoading) setFilterLoading(true);

        const upComingFilterPaymentType = [];
        for await (let upWars of upComingWars) {
            if (warListFiter.entryFee.value === "all") {
                upComingFilterPaymentType.push(upWars);
            } else if (warListFiter.entryFee.value === "freeToPlay") {
                if (Number(upWars?.data?.fee) === 0) {
                    upComingFilterPaymentType.push(upWars);
                    setIsWarAvailable(true);
                }
            } else if (warListFiter.entryFee.value === "paid") {
                if (Number(upWars?.data?.fee) > 0) {
                    upComingFilterPaymentType.push(upWars);
                }
            }
        }
        const filterWithWar = [];
        for await (let upWarsJoined of upComingFilterPaymentType) {
            if (warListFiter.wars.value === "all") {
                filterWithWar.push(upWarsJoined);
            }
        }
        if (warListFiter.wars.value === "joinedWars") {
            setUpcomingWarLists([]);
        } else {
            setUpcomingWarLists(filterWithWar);
        }
        // setFilterLoading(false);
    }

    async function filters(preventLoading?: boolean, isGangwarList?: boolean) {
        if (forFirstTime) {
            setForFirstTime(true);
            setFilterLoading(true);
        }
        const simulation: any = activeGangwar;
        const filteredWarWithDuration: any = [];

        for await (let item of simulation) {
            if (new Date(Number(item.endTime) / 1000) > new Date()) {
                const status = await hasWalletParticipated(item.id, walletAddress);
                item = { ...item, isJoined: !!Number(status) };
                if (warListFiter.duration.value === "all") {
                    filteredWarWithDuration.push(item);
                    setIsWarAvailable(true);
                } else if (Number(warListFiter.duration.value) === 8) {
                    if (item.period / 60000000 === 8) {
                        setIsWarAvailable(true);
                        filteredWarWithDuration.push(item);
                    } else {
                        setIsWarAvailable(false);
                    }
                } else if (Number(warListFiter.duration.value) === 10) {
                    if (item.period / 60000000 === 10) {
                        setIsWarAvailable(true);
                        filteredWarWithDuration.push(item);
                    } else {
                        setIsWarAvailable(false);
                    }
                } else if (Number(warListFiter.duration.value) === 12) {
                    if (item.period / 60000000 === 12) {
                        filteredWarWithDuration.push(item);
                        setIsWarAvailable(true);
                    } else {
                        setIsWarAvailable(false);
                    }
                } else if (Number(warListFiter.duration.value) === 16) {
                    if (item.period / 60000000 === 16) {
                        filteredWarWithDuration.push(item);
                        setIsWarAvailable(true);
                    } else {
                        setIsWarAvailable(false);
                    }
                }
            }
        }

        const filterWithEntry: any = [];

        for await (let item of filteredWarWithDuration) {
            if (warListFiter.entryFee.value === "all") {
                filterWithEntry.push(item);
                setIsWarAvailable(true);
            } else if (warListFiter.entryFee.value === "freeToPlay") {
                if (Number(item.fee) === 0) {
                    filterWithEntry.push(item);
                    setIsWarAvailable(true);
                } else {
                    setIsWarAvailable(false);
                }
            } else if (warListFiter.entryFee.value === "paid") {
                if (Number(item.fee) > 0) {
                    filterWithEntry.push(item);
                    setIsWarAvailable(true);
                } else {
                    setIsWarAvailable(false);
                }
            }
        }

        const filterWithWar: any = [];

        for await (let item of filterWithEntry) {
            if (warListFiter.wars.value === "all") {
                filterWithWar.push(item);
                setIsWarAvailable(true);
            } else if (warListFiter.wars.value === "joinedWars") {
                if (item.isJoined) {
                    filterWithWar.push(item);
                    setIsWarAvailable(true);
                } else {
                    setIsWarAvailable(false);
                }
            }
        }
        setFilteredWars(filterWithWar);

        setTimeout(() => {
            setFilterLoading(false);
        }, 2000);
    }
    useEffect(() => {
        filters();
        filterUpComingWars();
    }, [warListFiter]);
    useEffect(() => {
        filters(true, true);
    }, [activeGangwar]);

    useEffect(() => {
        filterUpComingWars(true);
    }, [upComingWars]);

    return (
        <div className="gangwars-tab-content">
            {/* {filteredWars.length > 0 && !filterLoading && ( */}
            <div className="filter-section flex justify-between flex-row">
                <div className="flex flex-row dropdown-wrapper">
                    {/* <div className="relative duration-dropdown" ref={durationDropdownRef}>
            <div
              className="dropdown-title-wrapper flex items-center cursor-pointer"
              onClick={() => {
                setShowDurationDropdown(!showDurationDropdown);
              }}
            >
              <div className="filter-title flex flex-row">
                <p className="filter-text">Duration:</p>
                <p className="filter-selected">{warListFiter.duration.name}</p>
              </div>
              <AiOutlineDown />
            </div>
            {showDurationDropdown && (
              <div className="filter-menu">
                {durationOptions.map((option, index) => {
                  return (
                    <p
                      className={
                        warListFiter.duration.name === option.name
                          ? "filter-selected text-yellow"
                          : ""
                      }
                      onClick={() => {
                        setWarListFilter({
                          ...warListFiter,
                          duration: option,
                        });
                        setShowDurationDropdown(!showDurationDropdown);
                      }}
                      key={index}
                    >
                      {option.name}
                    </p>
                  );
                })}
              </div>
            )}
          </div> */}
                    <div className="relative entry-fee-dropdown" ref={entryFeeDropdownRef}>
                        <div
                            className="dropdown-title-wrapper flex items-center cursor-pointer"
                            onClick={() => {
                                setShowEntryFreeFilterDropdown(!showEntryFreeFilterDropdown);
                            }}
                        >
                            <div className="filter-title flex flex-row">
                                <p className="filter-text">Entry fee:</p>
                                <p className="filter-selected">{warListFiter.entryFee.name}</p>
                            </div>
                            <AiOutlineDown />
                        </div>
                        {showEntryFreeFilterDropdown && (
                            <div className="filter-menu">
                                {entryFeeOptions.map((option, index) => {
                                    return (
                                        <p
                                            className={
                                                warListFiter.entryFee.name === option.name
                                                    ? "filter-selected text-yellow"
                                                    : ""
                                            }
                                            onClick={() => {
                                                setWarListFilter({
                                                    ...warListFiter,
                                                    entryFee: option,
                                                });
                                                setShowEntryFreeFilterDropdown(
                                                    !showEntryFreeFilterDropdown
                                                );
                                            }}
                                            key={index}
                                        >
                                            {option.name}
                                        </p>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                    <div className="war-join-filter flex flex-row gap-3">
                        {filterWarsOptions.map((option, index) => {
                            return (
                                <label
                                    htmlFor={option.value}
                                    className="flex gap-2 items-center radio-label"
                                    key={option.value}
                                >
                                    <input
                                        type="radio"
                                        value={option.value}
                                        id={option.value}
                                        className="radio-input"
                                        checked={option.value === warListFiter.wars.value}
                                        onChange={() =>
                                            setWarListFilter({ ...warListFiter, wars: option })
                                        }
                                    />
                                    <span className="custom-radio" />
                                    {option.name}
                                </label>
                            );
                        })}
                    </div>

                    <div className="toggleCommingWar text-sm font-normal  flex items-center gap-2">
                        <label className="switch">
                            <input
                                onChange={() => {
                                    setShowUpComingWar(!showUpComingWar);
                                }}
                                id="check"
                                checked={!showUpComingWar}
                                type="checkbox"
                                className="checkbox"
                            />
                            <div className="slider"></div>
                        </label>
                        <p
                            onClick={() => {
                                setShowUpComingWar(!showUpComingWar);
                            }}
                            className="cursor-pointer joinedWars"
                        >
                            Show only wars you can join
                        </p>
                    </div>

                    {/* <div className="toggleUpcomingWar">
            <input type="checkbox" id="toggle" />
            <label htmlFor="toggle"></label>
          </div> */}
                </div>
                {/* <div className="relative sort-dropdown" ref={sortByDropdownRef}>
          <div
            className="dropdown-title-wrapper flex items-center cursor-pointer"
            onClick={() => setShowSortByDropdown(!showSortByDropdown)}
          >
            <div className="filter-title flex flex-row">
              <p className="filter-text">Sort by</p>
            </div>
            <AiOutlineDown />
          </div>
          {showSortByDropdown && (
            <div className="filter-menu">
              {sortByOptions.map((option, index) => {
                return (
                  <p
                    className={
                      sortByOption.name === option.name
                        ? "filter-selected text-yellow"
                        : ""
                    }
                    key={option.value}
                    onClick={() => {
                      setSortByOption(option);
                      setShowSortByDropdown(!showSortByDropdown);
                    }}
                  >
                    {option.name}
                  </p>
                );
              })}
            </div>
          )}
        </div> */}
            </div>
            {/* )} */}

            <div className="wars-table-wrapper gangwars-table-wrapper">
                <table className="wars-table">
                    <thead className="wars-table-header">
                        {((filteredWars.length > 0 && !filterLoading) ||
                            (upComingWars && upComingWars?.length > 0)) && (
                            <tr className="flex gap-3 justify-between">
                                <td className="war-list-start-time-block">start time</td>
                                <td className="war-list-entry-fee-block text-center">entry fee</td>
                                <td className="war-list-rewards-block text-center">rewards</td>
                                <td className="war-list-duration-block">duration</td>
                                <td className="war-list-objective-block">Title</td>
                                <td className="w-[100px]">Zone type</td>
                                <td className="war-list-zone-type-block">
                                    {/* Eligibility Criteria */}
                                    War Specs
                                </td>
                                <td className="war-list-join-button"></td>
                            </tr>
                        )}
                    </thead>
                    <tbody className="war-items">
                        {filteredWars.length > 0 && !filterLoading ? (
                            filteredWars.map((warInfo: any, index: any) => {
                                return (
                                    !(new Date(Number(warInfo.endTime) / 1000) < new Date()) && (
                                        <WarItem war={warInfo} key={warInfo?.id} />
                                    )
                                );
                            })
                        ) : filterLoading ? (
                            <div className="flex items-center min-h-[300px]  justify-center">
                                <img width={"180px"} src={LOADING} alt="loading-image" />
                            </div>
                        ) : !isWarAvailable && !showUpComingWar ? (
                            <p className="flex flex-col min-h-[300px]  justify-center text-4xl opacity-70 items-center unSelectedTextState uppercase no-war-available">
                                No Gangwar available
                            </p>
                        ) : (
                            (upcommingWarLists?.length <= 0 ||
                                (!showUpComingWar && filteredWars.length <= 0)) && (
                                <div className="flex flex-col min-h-[300px]  justify-center text-4xl opacity-70 items-center  ">
                                    <p className="unSelectedTextState uppercase no-war-available">
                                        No Gangwar scheduled
                                    </p>
                                    {/* <p className="text-sm gotoDiscordText">
                    Stay informed about upcoming schedules by visiting our
                    discord.
                  </p>
                  <PrimaryButton
                    onClick={() => {
                      window.open("https://discord.gg/rkJBeAgQux", "_blank");
                    }}
                    image={DISCORD}
                    buttonName="Visit Discord"
                  /> */}
                                </div>
                            )
                        )}
                        {/* {showUpComingWar && !filterLoading && <hr />} */}
                        {showUpComingWar &&
                            !filterLoading &&
                            upcommingWarLists &&
                            upcommingWarLists.length > 0 &&
                            upcommingWarLists.map((warInfo: any, index: number) => {
                                return (
                                    <UpComingWar
                                        id={MAP_WAR_MAPPER_NUMBER + index}
                                        war={warInfo}
                                        key={index}
                                    />
                                );
                            })}
                        {/* {allWarInfo.map((warInfo: any, index: any) => WarItem(warInfo))} */}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default React.memo(GangWars);
