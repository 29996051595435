import {
  PLAY_CLOSE_SOUND,
  PLAY_NORMAL_CLICK,
  PLAY_INVENTORY_CLICK,
} from "@constants/audio";
import React from "react";
import { MdClose } from "react-icons/md";

const SidebarBody = ({
  setOpen,
  title,
  children,
  customSideBar,
  icon,
}: any) => {
  return (
    <div className={"warDetailsSideBar " + customSideBar}>
      <div className="drawer">
        <div className="drawer_header">
          {icon && icon}
          <div className="title capitalize">
            {title || "GangWar: The First Battle"}
          </div>
          <MdClose
            className="text-white text-4xl cursor-pointer"
            onClick={() => {
              setOpen(false);
              PLAY_CLOSE_SOUND();
            }}
          />
        </div>
        <div className="drawer_body">
          <div className="fightScreen">{children}</div>
        </div>
      </div>
      <div className="drawer_overlay"></div>
    </div>
  );
};

export default SidebarBody;
