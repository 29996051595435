export const statNameMapping: Record<string, string> = {
  health: "Health",
  speed: "Speed",
  duraAmmo: "Dura/Ammo",
  dodgeChance: "Dodge Chance",
  hitChance: "Hit Chance",
  criticalChance: "Critical Chance",
  supportEffectiveness: "Support Effectiveness",
  SupportTriggerChance: "Support Trigger Chance",
  meleeDamage: "Melee Damage",
};

export const titleStatNameMapping: Record<string, string> = {
  health: "Health",
  speed: "Speed",
  duraAmmo: "Scales the Durability/Ammo of equipped weapons ",
  dodgeChance: "Dodge Chance",
  hitChance: "Increases the Hit Chance of equipped weapons",
  criticalChance: "Increases the Critical Hit Chance of equipped weapons",
};
