import { useEffect } from "react";
import NFTCard from "@components/card/nftCard.component";
import { PISTOL, RANGE } from "@constants/images";
import EquipmentPlainCard from "@components/card/equipmentPlainCard.component";
import PrimaryButton from "@components/buttons/primary.button";
import { GiLoveMystery } from "react-icons/gi";
import { CROWN } from "@constants/images";
import { useCardAnimation } from "@hooks/useCardAnimation";
import { statNameMapping, titleStatNameMapping } from "@misc/statNameMap";
import { excludeStatsKeys } from "@misc/removeStatsKeys";
import { HYPERLINKS } from "@misc/links";
import { NFT_RENT_STATUS } from "@constants/nft";
interface ICharacterInfo {
    selectedCharacterInfo: any;
    animationToggle: boolean;
    warStartTime: number;
}

const CharacterInfo = ({ selectedCharacterInfo, warStartTime, animationToggle }: ICharacterInfo) => {
    useCardAnimation("animate", animationToggle);
    return (
        <>
            {Object.keys(selectedCharacterInfo).length > 0 && (
                <div className="animation nftInfo " id="animate">
                    <div className="nftImage">
                        <NFTCard
                            cardType="nftCardImageChooseCharacter"
                            width="230px"
                            height="300px"
                            nftImage={selectedCharacterInfo.gangstaBetDetails.nftImage}
                            // damage={
                            //     selectedCharacterInfo?.cooldownActivated && {
                            //         isDamaged: true,
                            //         cardSize: "isNFTDamaged-lg",
                            //         nameName: "Hospitalized",
                            //         damageRemainingTime: "",
                            //     }
                            // }
                            hospitalized={{
                                cardSize: "NFTOnIllLg",
                                hospitalizedTimer: selectedCharacterInfo.hospitalization.warCooldown,

                            }}

                            rental={
                                {
                                    cardSize: "NFTOnRentLg",
                                    rentalType: selectedCharacterInfo.rental.status,
                                    rentalTimer: selectedCharacterInfo.rental.expirationTimestamp,
                                    rentalName: selectedCharacterInfo.rental.rentalName,
                                }
                            }
                            locked={
                                selectedCharacterInfo.lockStatus && {
                                    cardSize: "NFTOnIllLg",
                                }
                            }
                        />
                    </div>
                    <div className="nftDetails">
                        <div className="nftNameClassType">
                            <h2
                                className="gbName cursor-pointer"
                                onClick={() => {
                                    window.open(
                                        `${HYPERLINKS.GANGSTABET_PROFILE}/${selectedCharacterInfo?.gangstaBetDetails?.nftId}`
                                    );
                                }}
                            >
                                {selectedCharacterInfo?.gangstaBetDetails?.nftName}
                            </h2>
                            <div className="gbClassType">
                                <p className="gbType">{selectedCharacterInfo?.gangstaBetDetails?.classType}</p>
                                <div className="flex items-center gap-1">
                                    <div className="w-3 h-3  bg-red-600 rounded-full"></div>
                                    <p className="gbClass">
                                        {selectedCharacterInfo?.gangstaBetDetails?.characterType}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="gbLevel">
                            <p className="gbLevelName">{selectedCharacterInfo?.gangstaBetDetails?.levelType}</p>
                            <div className="flex items-center gap-1">
                                <div className="w-3 h-3  bg-gray-200 rounded-full"></div>
                                <p className="gbLevelNow text-gray">
                                    Level {selectedCharacterInfo?.gangstaBetDetails?.levelNumber}
                                </p>{" "}
                            </div>
                            <div className="flex item-center gap-1">
                                <div className="w-3 h-3 mt-1 bg-gray-700 rounded-full"></div>
                                <p className="text-gray">Id {selectedCharacterInfo?.gangstaBetDetails?.nftId}</p>
                            </div>
                        </div>
                        {selectedCharacterInfo?.damages && (
                            <div className="recoveryButton flex flex-col gap-1">
                                <hr />
                                <PrimaryButton
                                    icon={<GiLoveMystery color="red" />}
                                    height="40px"
                                    buttonName="rapid recovery"
                                    extraIcon={
                                        <img width="22px" height={"22px"} src={CROWN} alt="crown" />
                                    }
                                    extraText="200"
                                />
                                <hr />
                            </div>
                        )}

                        <div className="nftWeapons">
                            <div className="weapons">
                                <EquipmentPlainCard
                                    height="64px"
                                    width="68px"
                                    equipmentImages={selectedCharacterInfo?.defaultWeapons.image}
                                    className="setGrey"
                                />
                                <div className="weaponsInfo">
                                    <p className="weaponName">
                                        {selectedCharacterInfo?.defaultWeapons.weaponName}
                                    </p>
                                    <p className="isDefault">
                                        {selectedCharacterInfo?.defaultWeapons.weaponType}
                                    </p>
                                </div>
                                <div className="ranged">
                                    <img src={RANGE} alt="ranged" />
                                    <p className="rangedText">
                                        {selectedCharacterInfo?.defaultWeapons.Ranged}
                                    </p>
                                </div>
                            </div>
                        </div>

                        {selectedCharacterInfo.rental.status === NFT_RENT_STATUS.IN_RENT && (Number(selectedCharacterInfo.rental.expirationTimestamp) / 1000) < warStartTime && <div className="rentalWarningInfo flex gap-1 ">
                            <p className="text-white">Note: </p>   <p className="text-sm text-red">Rented GangstaBet expires before war start.</p>
                        </div>}
                        <div className="nftSkills">
                            <p className="nftSkillTitle">GangWar Stats</p>
                            <hr />
                            <div className="nftActualSkills !min-w-[320px]">
                                <div className="skillsLeft " >
                                    {Object.keys(selectedCharacterInfo?.skills.primary).map(
                                        (keys) => {
                                            return (
                                                <div className="skillInfo">
                                                    <p className="skillName capitalize">{keys}</p>
                                                    <p className="skillNumber">
                                                        {selectedCharacterInfo?.skills.primary[
                                                            keys
                                                        ].toFixed(0)}
                                                    </p>
                                                </div>
                                            );
                                        }
                                    )}
                                </div>
                                <div className="skillsRight">
                                    {Object.keys(selectedCharacterInfo?.skills.secondary).map(
                                        (keys: any) => {
                                            if (excludeStatsKeys.includes(keys)) return null;
                                            return (
                                                <div className="skillInfo">
                                                    <p
                                                        className="skillName"
                                                        title={`${titleStatNameMapping[keys]}`}
                                                    >
                                                        {statNameMapping?.[keys]}
                                                    </p>
                                                    <p className="skillNumber">
                                                        {statNameMapping?.[keys]?.split(" ")
                                                            .length > 1
                                                            ? `${keys.toLowerCase() !==
                                                                "dodgechance"
                                                                ? "+"
                                                                : ""
                                                            }${Number(
                                                                selectedCharacterInfo?.skills
                                                                    .secondary[keys] * 100
                                                            ).toFixed(0)}%`
                                                            : selectedCharacterInfo?.skills.secondary[
                                                                keys
                                                            ].toFixed(0)}
                                                    </p>
                                                </div>
                                            );
                                        }
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default CharacterInfo;
