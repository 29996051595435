import { useNFTInformation } from "@context/nftInfoProvider";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

const GangstaBetCollectionPagination = () => {
    const { setCurrentPage, currentPage, totalPageCount } = useNFTInformation()
    if (totalPageCount === 1) return <></>;
    return <div className="gbCollectionPagination mb-3 mt-1 text-white flex gap-4 justify-center items-center">
        <div onClick={() => { if (currentPage > 1) { setCurrentPage((prev: number) => prev - 1) } }} className={`gbCollectionPagination_prev ${currentPage <= 1 ? "disable !cursor-not-allowed" : "cursor-pointer"}`}><FaAngleLeft size={25} color="white" />
        </div>
        <div className="gbCollectionPagination_current">{currentPage}/{totalPageCount}</div>
        <div onClick={() => { if (totalPageCount > currentPage) { setCurrentPage((prev: number) => prev + 1) } }} className={`gbCollectionPagination_next ${totalPageCount <= currentPage ? "disable !cursor-not-allowed" : "cursor-pointer"}`}><FaAngleRight size={25} color="white" />
        </div>
    </div>
}

export default GangstaBetCollectionPagination;