export const searchNFTNameAndId = (allInfo: any, query: any) => {
    const result = allInfo.filter(
        (item: any) =>
            item.gangstaBetDetails.nftName
                .toString()
                .toLowerCase()
                .includes(query) ||
            item.gangstaBetDetails.nftId
                .toString()
                .toLowerCase()
                .includes(query)
    );
    return result;
};
