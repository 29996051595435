import { ReactNode } from "react";
import { useSelector } from "react-redux";
import { IPrimaryButton } from "src/core/interfaces/button.interface";
import Loading from "@components/team/loading";

const PrimaryButton = ({
  buttonName,
  icon,
  extraIcon,
  extraText,
  onClick,
  height = "50px",
  className,
  disabled,
  width = "200px",
  image,
}: IPrimaryButton) => {
  const { isTransactionLoading } = useSelector((state: any) => state.loading);
  return (
    <button
      style={{ height, width }}
      onClick={() => {
        if (onClick) {
          onClick();
          // SOUNDS.NORMAL_CLICK.play();
        }
      }}
      className={`primary-button m-2   ${className} ${isTransactionLoading &&
        "disable"}`}
      disabled={disabled}
    >
      <div
        className={`flex ${icon || extraText ? "gap-2" : ""}   items-center`}
      >
        {isTransactionLoading ? (
          <Loading />
        ) : (
          <div className="flex gap-2">
            {image && <img width={"30px"} src={image} alt="logo" />}{" "}
            {buttonName}
          </div>
        )}

        <div className={`flex items-center gap-1`}>
          {extraText && (
            <>
              ({extraIcon} {extraText})
            </>
          )}
        </div>
      </div>
    </button>
  );
};

export default PrimaryButton;
