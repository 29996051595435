import PrimaryButton from "@components/buttons/primary.button";
import NFTCard from "@components/card/nftCard.component";
import CommonModal from "@components/modal/commonModal.component";
import { CROWN, TEST_NFTS, GB_HAT, WALLET_ICON, EMPTY_HAT } from "@constants/images";
import { useNFTDetailGlobalContext } from "@context/nftInformation";
import { useCardAnimation } from "@hooks/useCardAnimation";
import { NFTsDetails } from "@interfaces/nft.interface";
import { useEffect, useRef, useLayoutEffect, useState, useCallback, useMemo } from "react";
import { AiOutlineDown } from "react-icons/ai";
import { FaSearch } from "react-icons/fa";
import { useSelector } from "react-redux";
import { LOADING } from "@constants/images";
import { BsHeartFill } from "react-icons/bs";
import { MdTimer } from "react-icons/md";
import { PLAY_NORMAL_CLICK } from "@constants/audio";
import { useDetailGlobalContext } from "@context/detailProvider";
import { MessageText } from "@misc/message/messageText";
import { useTransactionValidate } from "@hooks/transactionResult";
import { claimRentFees, getCoolDownFee, outOfHospitalization } from "@services/icon.service";
import Countdown from "react-countdown-simple";
import OutOfHospitalizationWarning from "@components/modal/crownLimitToJoin.modal";
import Typewriter from "typewriter-effect";
import PutForRent from "@components/gangstabet/putForRent.component";
import LendModal from "@components/modal/rental/rental.lend.modal";
import ClaimModal from "@components/modal/rental/rental.claim.modal";
import RentDetail from "./rentDetail.component";
import UpdateModal from "@components/modal/rental/rental.update.modal";
import RemoveModal from "@components/modal/rental/rental.remove.modal";
import { NFTSkillsWRentalHistory, NFTDetail } from "./nfts.component";
import { NFT_RENT_STATUS } from "@constants/nft";
import { useNFTInformation } from "@context/nftInfoProvider";
import GangstaBetCollectionPagination from "./gangstaBetPagination";
import { RootState } from "@redux/store";
import { HYPERLINKS } from "@misc/links";


interface SelectedNFTsDetails extends NFTsDetails {
    fullAmount?: string;
    halfAmount?: string;
}

interface RecoverState {
    state: boolean;
    fullRecover: boolean;
}

const GangstabetCollection = () => {
    const defaultSort = useRef<any>(null);
    const [defaultSortOptions, setDafaultSortOptions] = useState<boolean>(false);
    const [selectedOption, setSelectedOption] = useState<string>("Default sort");
    const { ownedNft } = useSelector((state: any) => state.inventory);
    const [rentalModal, setRentalModal] = useState<
        "rent" | "update" | "remove" | "lend" | "claim" | null
    >(null);


    const sortOptions = [
        // { label: "Default (Alphabetically)", value: "default" },
        // { label: "Recently Used", value: "recent" },
        // { label: "NEW SORTING FEATURE", value: "new" },
        { label: "Level (High to Low)", value: "levelHigh" },
        { label: "Level (Low to High)", value: "levelLow" },
    ];

    // const {
    //     nftsDetails,
    //     gangstabetLoading,
    //     currentBatchIndex,
    //     setNftData,
    //     setCurrentBatchIndex,
    //     setRentalIndex,
    // rentalInformation,
    //     setRentalInformation
    // } = useNFTDetailGlobalContext();



    const { walletNFTDetails, gangstaBetLoading, rentalInformation } = useNFTInformation()
    const {
        reFetchDetails,
        setTransactionLoading,
        userCrown,
    } = useDetailGlobalContext();

    const [animationToggle, setAnimationToggle] = useState<boolean>(false);
    const [recover, setRecover] = useState<RecoverState>({
        state: false,
        fullRecover: false,
    });
    const [notEnoughCrown, setNotEnoughCrown] = useState(false);
    // const [selectedChar, setSelectedChar] = useState<SelectedNFTsDetails | null>(
    //     null
    // );
    const [selectedChar, setSelectedChar] = useState<any>(
        null
    );
    const [timestamp, setTimestamp] = useState<number>(0);

    const { walletAddress, isLedger, ledgerPath, ledgerTransport } = useSelector(
        (state: RootState) => state.wallet
    );

    const { getTxResultWithTimer } = useTransactionValidate({
        onHandle: () => { },
    });

    // useEffect(() => {
    //     setSelectedChar(null);
    // }, [nftsDetails]);

    useEffect(() => {
        reFetchDetails();
    }, []);

    // useLayoutEffect(() => {
    //     setFilteredData(nftsDetails);
    // }, [nftsDetails]);

    // useEffect(() => {
    //     setLoading(true);
    //     setTimeout(() => {
    //         setLoading(false);
    //     }, 6000);
    // }, [currentBatchIndex]);

    const [searchTerm, setSearchTerm] = useState('');
    const [searchResult, setSearchResult] = useState<any>(null);
    const memoizeNFTInformation = useMemo(() => {
        if (!walletNFTDetails) return []
        return walletNFTDetails.map((item: any) => ({
            gangstaBetDetails: {
                ...item.gangstaBetDetails,
                nftId: +item.gangstaBetDetails.nftId
            },
            ...item,
        }))

    }, [walletNFTDetails]);

    useEffect(() => {
        setSearchResult(memoizeNFTInformation)
    }, [memoizeNFTInformation])

    const searchNFTInformation = useCallback((searchKey: string) => {
        const normalizeSearchTerm = searchKey.trim().toLowerCase();
        console.log({ searchResult: memoizeNFTInformation })
        // Check if walletNFTDetails is null
        if (walletNFTDetails === null) {
            return;
        }
        console.log("searchResult----------------->")
        const searchResults = normalizeSearchTerm === '' ? memoizeNFTInformation : memoizeNFTInformation.filter((item: any) => item.gangstaBetDetails.nftId.toString().includes(normalizeSearchTerm) || item.gangstaBetDetails.nftName.toLowerCase().includes(normalizeSearchTerm))
        setSearchResult(searchResults)
    }, [memoizeNFTInformation]);

    const handleNFTInformationSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setSearchTerm(value);
        searchNFTInformation(value);
    }

    // const handleSearchChange = (e: any) => {
    //     setFilteredData(
    //         nftsDetails?.filter((item: any) =>
    //             item?.nftName.toLowerCase().includes(e.target.value.toLowerCase())
    //         )
    //     );
    //     setSearchString(e.target.value);
    // };
    const handleSortChange = (option: any) => {
        setDafaultSortOptions(!defaultSortOptions);
        setSelectedOption(option?.label);

        // if (option.value === "default") {
        //     setFilteredData(
        //         nftsDetails?.sort(function (a: any, b: any) {
        //             return a.nftName.localeCompare(b.nftName, undefined, {
        //                 numeric: true,
        //                 sensitivity: "base",
        //             });
        //         })
        //     );
        // }
        if (option.value === "levelHigh") {
            setSearchResult(
                searchResult.sort(function (a: any, b: any) {
                    return b?.gangstaBetDetails?.levelNumber - a?.gangstaBetDetails?.levelNumber;
                })
            );
        }
        if (option.value === "levelLow") {
            setSearchResult(
                searchResult.sort(function (a: any, b: any) {
                    return a?.gangstaBetDetails?.levelNumber - b?.gangstaBetDetails?.levelNumber;
                })
            );
        }
    };

    useCardAnimation("rightSection", animationToggle);
    const confirmHandle = async () => {
        setRecover({
            state: false,
            fullRecover: false,
        });
        setTransactionLoading((prev) => ({
            ...prev,
            state: "loading",
            status: true,
            message: recover.fullRecover
                ? MessageText.OUT_OF_HOSPITALIZATION_FULL
                : MessageText.OUT_OF_HOSPITALIZATION_HALF,
            title: "Recovering...",
        }));
        const fullRecovery = recover.fullRecover ?? false;
        // const value = await getCoolDownFee(selectedChar?.nftId, "0x1");
        // console.log("value", value);
        const value = recover.fullRecover
            ? selectedChar?.fullAmount
            : selectedChar?.halfAmount;
        const res: any = await outOfHospitalization(
            timestamp,
            walletAddress,
            selectedChar?.gangstaBetDetails.nftId,
            fullRecovery,
            value,
            isLedger,
            ledgerPath,
            ledgerTransport
        );
        setSelectedChar(null);

        if (isLedger) {
            if (res?.name === "TransportStatusError") {
                console.log("Ledger cancelled!!!");
            } else if (res) {
                getTxResultWithTimer(res as string);
            } else {
                console.log("Error on ledger");
            }
        }
    };
    const warStartTime = ({ days, hours, minutes, seconds }: any) => {
        if (days === 0 && hours === 0 && minutes === 0 && seconds === 0) {
            // setNftData([]);
            // setCurrentBatchIndex(0);
            // setRentalIndex(null)
        }
        return (
            <div>
                {hours < 10 ? "0" + hours : hours} hr{" "}
                {minutes < 10 ? "0" + minutes : minutes} min{" "}
                {seconds < 10 ? "0" + seconds : seconds} sec
            </div>
        );
    };

    return (
        <div className="min-w-[1400px]">
            {/* <>
                {searchResult ? searchResult.length > 0 ? searchResult.map((item: any) => {
                    return <div className="text-green">{item.gangstaBetDetails.token_name}</div>
                }) : <div className="text-red">No data found</div> : <div className="text-white">Loading...</div>}
            </> */}
            {/* {nftsDetails.length > 0 && !gangstabetLoading && !isLoading ? ( */}
            {walletNFTDetails && walletNFTDetails.length > 0 && searchResult && !gangstaBetLoading ? (

                <div className="flex justify-start min-w-[1400px]">
                    <div className=" gangstabetNftList border-r border-gray-600">
                        <div className="header">
                            <button
                                onClick={() => {
                                    PLAY_NORMAL_CLICK();
                                    window.open(
                                        HYPERLINKS.CRAFT_MARKETPLACE,
                                        "_blank"
                                    );
                                }}
                                className="button flex-center gap-3 text-white"
                            >
                                <img src={GB_HAT} alt="" /> Buy NFT
                            </button>
                            <span className="search w-2/4">
                                <FaSearch className="text-white" />
                                <input
                                    placeholder="Search GangstaBets by name or number"
                                    className="w-full focus:outline-0"
                                    // TODO: replacing searchString with searchTerm for test
                                    value={searchTerm}
                                    onChange={(e) => {
                                        handleNFTInformationSearch(e);
                                        // handleSearchChange(e)
                                    }}
                                />
                            </span>
                            <div className="relative sort-dropdown" ref={defaultSort}>
                                <div
                                    className="dropdown-title-wrapper flex-center cursor-pointer"
                                    onClick={() => {
                                        PLAY_NORMAL_CLICK();
                                        setDafaultSortOptions(!defaultSortOptions);
                                    }}
                                >
                                    <div className="filter-title flex flex-row">
                                        <p className="filter-text">{selectedOption}</p>
                                    </div>
                                    <AiOutlineDown />
                                </div>
                                {defaultSortOptions && (
                                    <div className="filter-menu">
                                        {sortOptions.map((option, index) => {
                                            return (
                                                <p
                                                    className={
                                                        selectedOption === option.label
                                                            ? "filter-selected filterOptionText text-yellow"
                                                            : "option"
                                                    }
                                                    onClick={() => {
                                                        PLAY_NORMAL_CLICK();
                                                        handleSortChange(option);
                                                    }}
                                                    key={index}
                                                >
                                                    {option.label}
                                                </p>
                                            );
                                        })}
                                    </div>
                                )}
                            </div>
                        </div>
                        {/* {rentalInformation.hasClaimableRentFee && ( */}
                        <div className="w-full flex items-center justify-center gap-4 ">
                            <p className="rental-text-md"><span className="rental-text-md cursor-pointer !text-[#E3C96E] underline underline-offset-2"
                                onClick={() => setRentalModal('claim')}>Collect your accumulated rental fees.</span> </p>

                        </div>
                        {/* )} */}
                        <div className="flex">
                            <div className="body w-full">
                                {searchResult?.length > 0 ? (
                                    <div className="flex flex-col gap-7">
                                        <div className="flex justify-center flex-wrap gap-5 m-5 cardsSection">
                                            {searchResult?.map((item: any) => (
                                                <div
                                                    className="flex flex-col gap-1 items-center justify-start h-content"
                                                    onClick={async () => {
                                                        PLAY_NORMAL_CLICK();
                                                        setAnimationToggle(
                                                            item === selectedChar
                                                                ? animationToggle
                                                                : !animationToggle
                                                        );

                                                        if (item?.hospitalization?.warCooldown && Number(item.hospitalization.warCooldown) / 1000 > Date.now()) {
                                                            const timer = new Date().getTime() * 1000;
                                                            setTimestamp(timer);
                                                            const fullAmount: string = await getCoolDownFee(
                                                                item.gangstaBetDetails.nftId,
                                                                "0x1",
                                                                timer.toString()
                                                            );
                                                            const halfAmount = await getCoolDownFee(
                                                                item.gangstaBetDetails.nftId,
                                                                "0x0",
                                                                timer.toString()
                                                            );
                                                            setSelectedChar({
                                                                ...item,
                                                                fullAmount: fullAmount,
                                                                halfAmount: halfAmount,
                                                            });
                                                        } else {
                                                            setSelectedChar(item);
                                                        }
                                                    }}
                                                >
                                                    <NFTCard
                                                        showTimer={false}
                                                        cardType="nftCardImageCollection"
                                                        nftImage={item.gangstaBetDetails.nftImage ?? TEST_NFTS}
                                                        width="140px"
                                                        height="180px"
                                                        locked={
                                                            item.lockStatus && {
                                                                cardSize: "NFTOnIllMd",
                                                            }
                                                        }
                                                        hospitalized={{
                                                            cardSize: "NFTOnIllMd",
                                                            hospitalizedTimer: item.hospitalization.warCooldown,

                                                        }}

                                                        rental={
                                                            {
                                                                cardSize: "NFTOnRentMd",
                                                                rentalType: item.rental.status,
                                                                rentalTimer: item.rental.expirationTimestamp,
                                                                rentalName: item.rental.rentalName,
                                                            }
                                                        }
                                                    />
                                                    <div className="text-gray text-center truncate w-[140px]">
                                                        {item.gangstaBetDetails.nftName}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        {/* {currentBatchIndex < Math.ceil(ownedNft.length / 5) && (
                                            <p className="text-white self-center text-center batchLoading  flex text-lg">
                                                Loading{" "}
                                                <Typewriter
                                                    options={{
                                                        strings: ["..."],
                                                        autoStart: true,
                                                        loop: true,
                                                        cursor: ".",
                                                    }}
                                                />{" "}
                                            </p>
                                        )} */}
                                        <GangstaBetCollectionPagination />
                                    </div>
                                ) : (
                                    <div className="flex w-full flex-col gap-5  items-center justify-center">
                                        <NFTCard
                                            cardType="nftCardImageCollection"
                                            nftImage={GB_HAT}
                                            height={"180px"}
                                            width={"142px"}
                                            className="inactive-image2"
                                        />
                                        <p className="text-white text-3xl big-shoulders uppercase">
                                            No NFT found
                                        </p>
                                        <p className="text-gray w-2/3 text-center">
                                            It seems that your search didn't return any results.
                                            Please check if you entered the correct NFT name or ID.
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>

                    </div>
                    {selectedChar ? (
                        <div className="overflow-hidden right-section-width">
                            <div className="right-section animation" id="rightSection">
                                <div className="flex-center gap-6 border-b border-b-gray-600">
                                    <div className="flex-center gap-6 p-5">
                                        <NFTCard
                                            cardType="nftCardImageCollection"
                                            nftImage={selectedChar.gangstaBetDetails.nftImage ?? TEST_NFTS}
                                            width={"140px"}
                                            height={"180px"}
                                            isSelected
                                            hospitalized={{
                                                cardSize: "NFTOnIllMd",
                                                hospitalizedTimer: selectedChar.hospitalization.warCooldown,

                                            }}

                                            rental={
                                                {
                                                    cardSize: "NFTOnRentMd",
                                                    rentalType: selectedChar?.rental.status,
                                                    rentalTimer: selectedChar.rental.expirationTimestamp,
                                                    rentalName: selectedChar?.rental.rentalName,

                                                }
                                            }
                                            locked={
                                                selectedChar.lockStatus && {
                                                    cardSize: "NFTOnIllMd",
                                                }
                                            }
                                        />
                                        <NFTDetail selectedChar={selectedChar} />
                                    </div>
                                </div>
                                {Number(selectedChar.hospitalization.warCooldown) / 1000 > Date.now() && (
                                    <div
                                        className={` ${Math.ceil(
                                            Number(selectedChar.fullAmount) / 10 ** 18
                                        ) <= 0 &&
                                            "disable"} border-b flex justify-between outOfHospitalization px-5  border-b-gray-600`}
                                    >
                                        <div className="flex items-center justify-center flex-col gap-3">
                                            <button
                                                onClick={() => {
                                                    if (
                                                        userCrown >=
                                                        Number(selectedChar?.fullAmount) / 10 ** 18
                                                    ) {
                                                        setRecover({
                                                            state: true,
                                                            fullRecover: true,
                                                        });
                                                    } else {
                                                        setNotEnoughCrown(true);
                                                    }
                                                }}
                                                className="fullRecoverBtn flex items-center justify-center"
                                            >
                                                <BsHeartFill color="red" /> Recovery{" "}
                                                <img src={CROWN} alt="icon" className="h-4" />{" "}
                                                {Math.ceil(Number(selectedChar.fullAmount) / 10 ** 18)}
                                            </button>
                                            {/* <button
                            onClick={() => {
                              if (
                                userCrown >=
                                Number(selectedChar.fullAmount) / 10 ** 18
                              ) {
                                setRecover({
                                  state: true,
                                  fullRecover: false,
                                });
                              } else {
                                setNotEnoughCrown(true);
                              }
                            }}
                            className="halfRecoverBtn flex items-center justify-center "
                          >
                            <BsHeartHalf color="red" /> Half Recovery{" "}
                            <img src={CROWN} alt="icon" className="h-4" />{" "}
                            {Math.ceil(
                              Number(selectedChar?.halfAmount) / 10 ** 18
                            )}
                          </button> */}
                                        </div>
                                        <div className="recoveryBtnSeparator"></div>
                                        <div className="recoveeryTimerBox flex gap-2 items-center">
                                            <MdTimer className="bg-grey" color="#70878f" />
                                            <div className="recoveryTimer">
                                                <p className="recoveryTimerText">Auto recover in:</p>
                                                <Countdown
                                                    targetDate={new Date(
                                                        new Date(
                                                            (selectedChar.hospitalization.warCooldown as number) / 1000
                                                        ).setHours(
                                                            new Date(
                                                                (selectedChar.hospitalization.warCooldown as number) / 1000
                                                            ).getHours()
                                                        )
                                                    ).toISOString()}
                                                    disableTypes={true}
                                                    renderer={warStartTime}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {/* {selectedChar?.cooldownActivated && (
                      <div className="border-b border-b-gray-600 ">
                        <div className="py-4 text-center">
                          <PrimaryButton
                            width="300px"
                            buttonName="FULL RECOVEREY"
                            icon={<FaHeart className="text-red" />}
                            extraIcon={
                              <img src={CROWN} alt="icon" className="h-4" />
                            }
                            extraText={selectedChar.fullAmount?.toString()}
                          />
                          <PrimaryButton
                            width="300px"
                            buttonName="HALF RECOVEREY"
                            icon={<FaHeart className="text-red" />}
                            extraIcon={
                              <img src={CROWN} alt="icon" className="h-4" />
                            }
                            extraText={selectedChar.halfAmount?.toString()}
                            onClick={() => setRecover(true)}
                          />
                        </div>
                      </div>
                    )} */}

                                {!selectedChar.lockStatus && <>
                                    {selectedChar.rental?.status === NFT_RENT_STATUS.NOT_AVAILABLE_FOR_RENT ?
                                        <PutForRent setRentalModal={setRentalModal} />
                                        : <RentDetail setRentalModal={setRentalModal} selectedChar={selectedChar} />
                                    }
                                </>}
                                {/* {!Boolean(Number(selectedChar.rental.status)) ?
                                    <PutForRent setRentalModal={setRentalModal} />
                                    : <RentDetail setRentalModal={setRentalModal} selectedChar={selectedChar} />
                                } */}
                                <NFTSkillsWRentalHistory selectedChar={selectedChar} />
                            </div>
                        </div>
                    ) : (
                        <div className="flex-center self-center nftSelectedCard justify-center  h-full px-12">
                            <div className="text-3xl uppercase text-gray-700 text-center big-shoulders my-auto font-bold">
                                Select an NFT to see Details
                            </div>
                        </div>
                    )}
                </div>
                // gangstabetLoading || isLoading
            ) : gangstaBetLoading ? (
                // Loading
                <div className="flex flex-col min-h-[500px] w-[1400px] min-w-[1400px] gap-2 h-full items-center justify-center">
                    <img width={220} src={LOADING} alt="loading circle" />
                </div>
            ) : !gangstaBetLoading && walletNFTDetails && walletNFTDetails.length === 0 && searchResult && (
                // Empty Inventory
                <div className="flex flex-col min-h-[500px] w-[1400px] min-w-[1400px] gap-2  h-full  items-center justify-center">
                    {/* <NFTCard
                        cardType="nftCardImageCollection"
                        nftImage={GB_HAT}
                        height={"150px"}
                        width={"120px"}
                        className="inactive-image2"
                    /> */}
                    <img src={EMPTY_HAT} alt="empty hat" />
                    <p className="text-white text-3xl big-shoulders uppercase">
                        No Gangstabets in your wallet
                    </p>
                    <p className="text-gray w-1/3 text-center">
                        It looks like your NFT inventory is currently empty. Explore our
                        marketplace to purchase new NFTs.
                    </p>
                    <PrimaryButton
                        onClick={() => {
                            window.open(
                                HYPERLINKS.CRAFT_MARKETPLACE,
                                "_blank"
                            );
                            PLAY_NORMAL_CLICK();
                        }}
                        buttonName="Buy NFTS"
                    />
                </div>
            )}

            {/* modals */}
            {recover.state && !notEnoughCrown && (
                <CommonModal
                    isVisible={recover.state}
                    setIsVisible={() => {
                        setRecover({
                            state: false,
                            fullRecover: false,
                        });
                    }}
                    approveAction={"Recover"}
                    confirmHandle={confirmHandle}
                    icon={<img src={CROWN} alt="icon" className="h-5" />}
                    extraText={
                        <span className="text-yellow">
                            {recover.fullRecover
                                ? Math.ceil(Number(selectedChar?.fullAmount) / 10 ** 18)
                                : Math.ceil(Number(selectedChar?.halfAmount) / 10 ** 18)}
                        </span>
                    }
                    textInformation={
                        recover.fullRecover
                            ? "Use CROWN to recover your GangstaBet instantly."
                            : "Use CROWN to reduce your GangstaBet's recovery time to half."
                    }
                />
            )}
            {notEnoughCrown && (
                <OutOfHospitalizationWarning
                    isVisible={notEnoughCrown}
                    setIsVisible={setNotEnoughCrown}
                    fee={
                        recover.fullRecover
                            ? Number(selectedChar?.fullAmount)
                            : Number(selectedChar?.halfAmount)
                    }
                />
            )}
            {/* Rental */}
            {rentalModal === "lend" && (
                <LendModal
                    setSelectedChar={setSelectedChar}
                    selectedChar={selectedChar}
                    isVisible={rentalModal === "lend"}
                    setRentalModal={setRentalModal}
                    maxDaysForRent={0}
                />
            )}
            {rentalModal === "update" && (
                <UpdateModal
                    setSelectedChar={setSelectedChar}
                    selectedChar={selectedChar}
                    isVisible={rentalModal === "update"}
                    setRentalModal={setRentalModal}
                    maxDaysForRent={0}
                />
            )}
            {rentalModal === "remove" && (
                <RemoveModal
                    setSelectedChar={setSelectedChar}
                    selectedChar={selectedChar}
                    isVisible={rentalModal === "remove"}
                    setRentalModal={setRentalModal}
                />
            )}
            {rentalModal === "claim" && (
                <ClaimModal
                    isVisible={rentalModal === "claim"}
                    setRentalModal={setRentalModal}
                />
            )}

        </div>
    );
};

export default GangstabetCollection;
