import { RootState } from "@redux/store";
import { useDispatch, useSelector } from "react-redux";
import { setSignStoreHash } from "@redux/features/walletSlice";
import { signWithLedger } from "@utils/ledger";
import { validateSignature } from "@misc/validateSignature";
import { initializeSignTxObj } from "@services/icon.service";
import IconService from "icon-sdk-js";
import { sha3_256 } from "js-sha3";
import { Subject } from "rxjs";
import { setLoadingState } from "@redux/features/stateSlice";

const MSG_KEY = "message";

export const signatureSubject = new Subject<string>();

export const useSignMessage = (message: string) => {
  const { walletAddress, isLedger, ledgerPath, ledgerTransport } = useSelector(
    (state: RootState) => state.wallet
  );
  const dispatch = useDispatch();

  const getRanHex = (size: number) => {
    return [...Array(size)]
      .map(() => Math.floor(Math.random() * 16).toString(16))
      .join("");
  };

  // const getDynamicHashN = (count: number) => {
  //     return getRanHex(count + (51 - count)) + new Date().getTime();
  // };

  // only handles one signature at a time
  const signClick = async () => {
    if (walletAddress) {
      const txObj = initializeSignTxObj(walletAddress, MSG_KEY, message);
      const txStrRep = IconService.IconUtil.generateHashKey(txObj);
      const txSha3Rep = sha3_256.update(txStrRep).hex();

      localStorage.setItem("dynamicHash", txSha3Rep);
      if (isLedger) {
        const { signature } = await signWithLedger(
          ledgerTransport,
          ledgerPath,
          txStrRep
        );
        
        return signature;
      } else {
        dispatch(setLoadingState(true));
        dispatch(setSignStoreHash(txSha3Rep));

        if (message) {
          window.parent.dispatchEvent(
            new CustomEvent("ICONEX_RELAY_REQUEST", {
              detail: {
                type: "REQUEST_SIGNING",
                payload: {
                  from: walletAddress,
                  hash: txSha3Rep, //64C
                },
              },
            })
          );
          return new Promise((resolve) => {
            signatureSubject.subscribe((signature) => {
              
              resolve(signature);
            });
          });
        }
      }
    } else {
      dispatch(setLoadingState(false));
    }
  };

  return { signClick };
};
