import Modal from "@components/modal/modal.component";
import { useEffect, useState } from "react";
import NFTCard from "@components/card/nftCard.component";
import { AiOutlineInfoCircle } from "react-icons/ai";
import ImageLoader from "@misc/ImageLoader";
import ItemTooltip from "@components/tooltips/item.tooltip";
import { getNFTInformation } from "@utils/getNFTInformation";
import { statNameMapping } from "@misc/statNameMap";
import { excludeStatsKeys } from "@misc/removeStatsKeys";
import GameLoadoutLobby from "./gameLoadoutLobby.component";
import { HYPERLINKS } from "@misc/links";

const LoadoutDetails = ({ isVisible, setIsVisible, participantsDetails }: any) => {
    const [data, setData] = useState<any>({});
    useEffect(() => {
        async function getDetail() {
            const data = await getNFTInformation("2797");
            setData(data);
        }
        getDetail();
    }, []);
    return (
        <Modal
            isVisible={isVisible}
            className="changeStatsModal"
            setIsVisible={setIsVisible}
            overlayWidth="100%"
            overlayHeight="100%"
        >
            <div className="detailsLoadoutModal">
                <div className="otherParticipantLoadoutModal">
                    <NFTCard
                        cardType="nftCardImageChooseCharacter"
                        height="100px"
                        width="77px"
                        nftImage={participantsDetails?.nftDetails?.nftImage}
                        onClick={() =>
                            window.open(
                                `${HYPERLINKS.GANGSTABET_PROFILE}/${participantsDetails?.nftDetails.nftId}`,
                                "_blank"
                            )
                        }
                    />
                    <div className="w-full flex flex-col gap-2">
                        <div className="flex w-full justify-between ">
                            <div className="gbDetails flex flex-col">
                                <h2
                                    onClick={() =>
                                        window.open(
                                            `${HYPERLINKS.GANGSTABET_PROFILE}/${participantsDetails?.nftDetails.nftId}`,
                                            "_blank"
                                        )
                                    }
                                    className="gbName cursor-pointer"
                                >
                                    {" "}
                                    {participantsDetails?.nftDetails?.nftName}
                                </h2>
                                <div className="flex gap-2">
                                    <p className="isGangster">
                                        {" "}
                                        {participantsDetails?.nftDetails?.characterType}
                                    </p>
                                    <p className="gbType">
                                        {" "}
                                        {participantsDetails?.nftDetails?.classType}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <GameLoadoutLobby participantsDetails={participantsDetails} />
                    </div>
                </div>
                <hr className="horizontalSeperator" />
                <div className="skillsStats flex justify-between gap-10">
                    <div className="primarySkillsStats flex flex-col gap-2">
                        {participantsDetails?.nftDetails?.skills &&
                            Object.entries(participantsDetails?.nftDetails?.skills?.primary).map(
                                ([key, value]: any) => {
                                    return (
                                        <div className="flex justify-between" key={key}>
                                            <p className="statsSkillKeys capitalize">{key} </p>
                                            <p className="statsSkillValues ">
                                                {Math.floor(value).toFixed(0)}
                                            </p>
                                        </div>
                                    );
                                }
                            )}
                    </div>
                    <div className="secondarySkillsStats flex flex-col gap-2">
                        {participantsDetails?.nftDetails?.skills &&
                            Object.entries(participantsDetails?.nftDetails?.skills?.secondary).map(
                                ([key, value]: any) => {
                                    if (excludeStatsKeys.includes(key)) return null;
                                    return (
                                        <div
                                            className="flex flex-row justify-between w-full"
                                            key={key}
                                        >
                                            <p className="statsSkillKeys capitalize">
                                                {statNameMapping?.[key]}
                                            </p>
                                            <p className="statsSkillValues self-end">
                                                {statNameMapping?.[key]?.split(" ").length > 1
                                                    ? (value * 100).toFixed(2) + "%"
                                                    : value.toFixed(0)}
                                            </p>
                                        </div>
                                    );
                                }
                            )}
                    </div>
                </div>
            </div>
        </Modal>
    );
};
export default LoadoutDetails;
