export const convertTwoArrayToObject = (
  keyArray: string[],
  valueArray: string[]
) => {
  const result: { [key: string]: number } = {};
  keyArray.forEach((key: string, index: number) => {
    result[key] = Number(valueArray[index]);
  });
  return result;
};
