import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useSpring, animated, easings } from "@react-spring/web";
import { isNil } from 'lodash';
import { sanitize } from 'dompurify';

import { ATTACK_EQUIPMENTS, BATTLE_STATE, BATTLE_TEAMS, ACTION_TYPE, NARRATION_TYPES, IGNORED_ACTION_TYPES, IGNORED_NARRATION_TYPES, SPECIAL_NARRATION_TYPES, IGNORED_EQUIPMENTS, MEELE_WEAPONS, IGNORED_EFFECTS, NO_RETURN_DEFAULT_POS_ACTIONS, NO_RETURN_DEFAULT_POS_NARRATIONS, PLAYER_DEAD, SPECIAL_ACTION_TYPES, SPECIAL_ACTION_GIF_WIDTH, GUN_WEAPONS, ITEMS, EFFECTS, EVENTS_WITH_PRELUDE_ANIM, EFFECTS_WITH_PRELUDE_ANIM } from "@constants/war";
import GifLoader from "@components/gifLoader/GifLoader.component";

import { animations, ANIMATION_VALUES, getRandomEasings } from "./springAnimations";
// import manageFlameGlow from "../hooks/manageFlameGlow";
import { CHANGE_DISPLAY_MAP, IActiveBattleDetails } from "../interfaces/warScreen.interface";
import { getGifImage, getBattleAudio, getBattleEquipment, animateDistraction, sleep } from "../utils.warscreen";
import { useSelector } from "react-redux";

const CUSTOM_TRANSLATE_ACTIONS: Array<string> = [ACTION_TYPE.FRIENDLY_FIRE, ACTION_TYPE.HEAL, ACTION_TYPE.COVER_HEAL];
const CUSTOM_TRANSLATE_NARRATIONS: Array<string> = [NARRATION_TYPES.HEAL];
const CUSTOM_TRANSLATE_EFFECTS: Array<string> = [EFFECTS.DEAD_COVER];
const NFT_CARD_ID_PREFIX = "anim_card_container_0x";

interface IAnimatedNftCardProps {
    id: string,
    isBattlePassive: boolean;
    team?: typeof BATTLE_TEAMS[keyof typeof BATTLE_TEAMS];
    battleState?: typeof BATTLE_STATE[keyof typeof BATTLE_STATE];
    setBattleState?: (battleState: string) => Dispatch<SetStateAction<string>>;
    battleDetails?: IActiveBattleDetails;
    attackItem?: keyof typeof ATTACK_EQUIPMENTS;
    delay?: number;
    shouldComeToDefaultPos?: boolean;
    shouldComeToBattleField?: boolean;
    isOneVOneBattle: boolean;
    children?: React.ReactNode;
    fullScreenEffect: IFullScrrenEffects;
    setFullScreenEffect: (effect: IFullScrrenEffects) => Dispatch<SetStateAction<IFullScrrenEffects>>;
    fullScreenLoadFlag: boolean,
    fullScreenCompleteFlag: boolean,
    isDeadAnimation: boolean,
}

export interface IAttackEffects {
    show: boolean,
    id: string,
    image: string | undefined,
    audio: HTMLAudioElement,
}

export interface IFullScrrenEffects extends IAttackEffects {
    styles: object;
}

export default function AnimatedNftCard({ battleState, // rename to battleState
    setBattleState,
    battleDetails,
    isBattlePassive,
    team,
    children,
    id,
    shouldComeToDefaultPos,
    shouldComeToBattleField,
    isOneVOneBattle,
    fullScreenEffect,
    setFullScreenEffect,
    fullScreenLoadFlag,
    fullScreenCompleteFlag,
    isDeadAnimation
}: IAnimatedNftCardProps
) {
    console.log("$$$$$$$$$$$$$$$$$$$");
    console.log(battleDetails);
    const { effectsVolume } = useSelector((state: any) => state.volume);
    const isMain = (battleDetails?.main.id && battleDetails?.main.id === parseInt(id)) ? true : false;
    const isTwoNftInvolved = battleDetails?.main.id &&
        battleDetails?.target.id &&
        battleDetails?.main.id != battleDetails?.target.id;

    const [shakeEffect, shakeApi] = useSpring(() => ({ rotate: 0, translateZ: 0, loop: false }), [team]);
    const [moveEffect, moveApi] = useSpring(() => (
        { scale: 1, translateX: 0, translateY: 0, translateZ: 0 }), [team]);
    const [shouldHighlightNft, setShouldHighlightNft] = useState(false);

    const [animationStyles, setAnimationStyles] = useState({});
    // const [stopAnimMethod, setStopAnimMethod] = useState<{ fn: () => void }>({ fn: () => void 0 });
    const [shouldAnimate, setShouldAnimate] = useState(true);

    const [deadCoverId, setDeadCoverId] = useState<string | undefined> ();

    const [partialAttackCompletePercent, setPartialAttackCompletePercent] = useState(100);

    const [activeAttackEffect, setActiveAttackEffect] = useState<IAttackEffects>({
        show: false,
        id: "",
        image: undefined,
        audio: new Audio(),
    });
    const [activeHpEffect, setActiveHpEffect] = useState<IAttackEffects>({
        show: false,
        id: "",
        image: undefined,
        audio: new Audio(),
    });
    const [passiveEffect, setPassiveEffect] = useState<IAttackEffects>({
        show: false,
        id: "",
        image: undefined,
        audio: new Audio(),
    });

    const nftCardRef = useRef<HTMLDivElement>(null);
    const battleGroundCenter = useRef<number>(0);
    const firstAnimation = useRef(true);
    
    const playAudio = () => {
        fullScreenEffect.show && fullScreenEffect.audio.src && fullScreenEffect.audio.play();
        activeAttackEffect.show && activeAttackEffect.audio.src && activeAttackEffect.audio.play();
        activeHpEffect.show && activeHpEffect.audio.src && activeHpEffect.audio.play();
    };

    useEffect(() => {
        playAudio();
    }, [fullScreenLoadFlag]);

    useEffect(() => {
        if(battleDetails?.equipment && EFFECTS_WITH_PRELUDE_ANIM.includes(battleDetails.equipment)) {
            handlePreludeAnimComplete();
        } else {
            handleAttackComplete();
        }
    }, [fullScreenCompleteFlag]);

    useEffect(() => {
        setAnimationStyles({ ...moveEffect, ...shakeEffect });
    }, [team]);

    useEffect(() => {
        battleDetails?.narrationType === NARRATION_TYPES.ABILITY_LOG ||
        isBattlePassive || battleDetails?.actionType === ACTION_TYPE.GUN_JAMMED ? 
            setShouldHighlightNft(true) :
            setShouldHighlightNft(false);

        if (battleDetails?.equipment) {
            // backup battle equipment in another key
            if (!battleDetails.actualWeapon) {
                battleDetails!.actualWeapon = battleDetails!.equipment;
                battleDetails!.equipment = getBattleEquipment(battleDetails);
            }

            if (Object.keys(MEELE_WEAPONS).includes(battleDetails.equipment))
                setPartialAttackCompletePercent(75);
            else if (Object.keys(GUN_WEAPONS).includes(battleDetails.equipment))
                setPartialAttackCompletePercent(50);
            else
                setPartialAttackCompletePercent(100);
        } else {
            setPartialAttackCompletePercent(100);
        }

        if (!battleDetails) {
            // shakeApi.set({ rotate: 0, translateZ: 0 });
            shakeApi.stop();
        }
    }, [battleDetails]);

    useEffect(() => {
        if (!nftCardRef?.current) return;
        manageBattleStateUpdates();

        // update center of battle screen
        const battleGroudnElem = document.getElementsByClassName("left-section")[0].getBoundingClientRect();
        battleGroundCenter.current = battleGroudnElem.width / 2;

    }, [nftCardRef, battleState]);

    const getWrapperClassName = () => {
        const attackHurtClass = battleState === BATTLE_STATE.BATTLE_COMPLETE ?
            "" :
            isMain ? "war-attack-flame" : "war-take-hit";
        return ["war-animated-card", attackHurtClass].join(" ")
    }

    const getAttackingGifClass = () => {
        return battleDetails?.involvedIds.length === 1 ?
            "war-attack-center"
            :
            battleDetails?.main.team?.team === BATTLE_TEAMS.BLUE ?
                "war-attack-left"
                :
                "war-attack-right"
    }

    const getTargetGifClass = () => {
        return (battleDetails?.target.team?.team === BATTLE_TEAMS.BLUE && battleDetails?.main.team?.team !== BATTLE_TEAMS.BLUE) ||
            battleDetails?.target.team?.team === BATTLE_TEAMS.ORANGE && battleDetails.main.team?.team == BATTLE_TEAMS.ORANGE ?
            "war-victim-left"
            :
            "war-victim-right";
    }

    const getCustomTranslateX = (): Promise<number> => {
        // wait for active nft group to move slightly towards the battle stage
        return new Promise((resolve) => {
            setTimeout(() => {
                const cardPos = nftCardRef.current!.getBoundingClientRect();
                const warScreenCenter = document.querySelector(".left-section")!.clientWidth / 2;
                let customTranslateX = team === BATTLE_TEAMS.BLUE ?
                    warScreenCenter - cardPos.right - cardPos.width * 4 :
                    warScreenCenter - cardPos.left + cardPos.width * 4;
                if ((
                    (battleDetails?.actionType &&
                        CUSTOM_TRANSLATE_ACTIONS.includes(battleDetails.actionType) ||
                        CUSTOM_TRANSLATE_NARRATIONS.includes(battleDetails!.narrationType!) ||
                        (battleDetails?.equipment && CUSTOM_TRANSLATE_EFFECTS.includes(battleDetails.equipment))
                    ) ||
                    (battleDetails?.actionType === ACTION_TYPE.COLLATERAL_ATTACK &&
                        battleDetails.main.team === battleDetails.target.team)
                ) &&
                    battleDetails!.target.id === parseInt(id)
                ) {
                    customTranslateX = team === BATTLE_TEAMS.ORANGE ?
                        warScreenCenter - cardPos.right - cardPos.width * 4 :
                        warScreenCenter - cardPos.left + cardPos.width * 4;

                } else if (!isTwoNftInvolved) {
                    customTranslateX = warScreenCenter - cardPos.x - cardPos.width / 2;

                } else if ((battleDetails?.equipment &&
                    battleDetails?.narrationType == NARRATION_TYPES.ACTION_LOG &&
                    Object.values(MEELE_WEAPONS).includes(battleDetails.equipment) &&
                    battleDetails?.main.team !== battleDetails.target.team) ||
                    isOneVOneBattle) {
                    customTranslateX = team === BATTLE_TEAMS.BLUE ?
                        warScreenCenter - cardPos.right - cardPos.width * ANIMATION_VALUES.warNftScale :
                        warScreenCenter - cardPos.left + cardPos.width * ANIMATION_VALUES.warNftScale;
                }
                resolve(customTranslateX / ANIMATION_VALUES.warNftScale);
            }, 510);
        })
    }

    const getTranslateYToCenter = () => {
        const cardPos = nftCardRef.current!.getBoundingClientRect();
        const isBelowCenter = window.innerHeight / 2 - (cardPos.top + cardPos.height / 2) < 0;
        const translateY = (isBelowCenter ?
            -cardPos.bottom + window.innerHeight / 2 + cardPos.height / 2
            :
            window.innerHeight / 2 - cardPos.top - cardPos.height / 2
        )
        return (translateY / ANIMATION_VALUES.warNftScale) - 20;
    }

    const getChangeType = () => {
        // IMPORTANT: These return types are based on values defined on warscreen.interface.ts
        if (battleDetails!.narrationType === NARRATION_TYPES.SHIELD_BOOST)
            return "shieldChange";
        
        if(battleDetails!.equipment === EFFECTS.DIARRHEA)
            return "speedChange";

        switch (battleDetails!.actionType) {
            case SPECIAL_ACTION_TYPES.MAS_DUEL:
                if (battleDetails?.actualWeapon && Object.values(MEELE_WEAPONS).includes(battleDetails.actualWeapon))
                    return "durabilityChange";
                else
                    return "ammoChange";
            case SPECIAL_ACTION_TYPES.AGI_DUEL:
                return "speedChange";
            case SPECIAL_ACTION_TYPES.LUK_DUEL:
                if (battleDetails?.target.shieldChange || battleDetails?.main.shieldChange)
                    return "shieldChange";
                else
                    return "hpChange";
            default:
                return "hpChange";
        }
    }

    const shouldIgnoreThisBattle = () => {
        // @ts-ignore
        return (battleDetails?.actionType && Object.values(IGNORED_ACTION_TYPES).includes(battleDetails.actionType)) ||
            // @ts-ignore
            (battleDetails?.narrationType && (Object.values(IGNORED_NARRATION_TYPES).includes(battleDetails.narrationType)
                && battleDetails.equipment !== ITEMS.ANKH_OF_REBIRTH
                && battleDetails.equipment !== ITEMS.ANTIDOTE
                && !battleDetails.equipment?.includes(ITEMS.COLD_WATER)
                && battleDetails.equipment !== EFFECTS.DEAD_COVER
                && battleDetails.equipment !== EFFECTS.DIARRHEA
            )) ||
            // @ts-ignore
            (battleDetails?.equipment && Object.values(IGNORED_EFFECTS).includes(battleDetails.equipment)) ||
            isDeadAnimation
    }

    const showHpChangePopup = (isMain: boolean = false) => {
        let hpChangeText = "", hpChangeClass;
        if ((battleDetails?.actionType && Object.keys(SPECIAL_ACTION_TYPES).includes(battleDetails.actionType)) ||
            battleDetails?.narrationType === NARRATION_TYPES.SHIELD_BOOST || 
            (battleDetails?.firstAnimOfEventGroup && battleDetails.equipment === EFFECTS.DIARRHEA)
        ) {
            // Special hp text popup for this action log
            const changeType = getChangeType();
            const hpChangeVal = (isMain ? battleDetails.main[changeType] : battleDetails.target[changeType]) ?? 0;
            hpChangeClass = hpChangeVal > 0 ? "text-green" :
                hpChangeVal == 0 ? "" : 
                battleDetails.involvedIds.length > 1 ? "text-red" : "text-shadowed-red";
            hpChangeText = hpChangeVal > 0 ? `+${parseInt(hpChangeVal.toFixed())} ${CHANGE_DISPLAY_MAP[changeType]}` :
                hpChangeVal < 0 ? `${parseInt(hpChangeVal.toFixed())} ${CHANGE_DISPLAY_MAP[changeType]}` :
                    "";

            // special case for miss/dodged attack
        } else if (battleDetails?.attackType === "DODGED" || battleDetails?.attackType === "MISS") {
            hpChangeText = battleDetails.attackType;
            hpChangeClass = "text-blue";

        } else if (battleDetails?.narrationType === PLAYER_DEAD.PLAYER_DEAD) {
            hpChangeText = "DEAD☠️";
            hpChangeClass = "text-dead";

        } else {
            const hpChange = isMain ?
                battleDetails!.main.hpChange.toFixed(0) :
                battleDetails!.target.hpChange.toFixed(0);
            hpChangeClass = parseInt(hpChange) > 0 ? "text-green" :
                parseInt(hpChange) == 0 ? "" : "text-red";
            hpChangeText = parseInt(hpChange) > 0 ? `+${hpChange} HP` : parseInt(hpChange) == 0 ? '' : `${hpChange}HP`;
        }
        // add extra emoji for critical hit
        hpChangeText = (hpChangeText && ((battleDetails?.attackType === "CRITICAL_HIT" && !isMain) ||
            Array.isArray(battleDetails?.extraDamage) && battleDetails!.extraDamage.length > 0)) ?
            hpChangeText + "⚡" :
            hpChangeText;
        nftCardRef.current?.setAttribute("data-content", hpChangeText);
        nftCardRef.current?.classList.add("war-hp-popup");
        hpChangeClass && nftCardRef.current?.classList.add(hpChangeClass);
        isBattlePassive && !isOneVOneBattle && nftCardRef.current?.classList.add("passive-hp-text");
    }

    const updateBattleState = (newState: string) => {
        // @ts-ignore
        setBattleState(newState);
    }

    const manageBattleStateUpdates = async () => {
        // todo: state should be updated by target nft wherever possible

        if (battleState === BATTLE_STATE.BATTLE_RESET) {
            console.log('<<<<<<<<<<');
            if (shouldIgnoreThisBattle()) {
                setShouldAnimate(false);
                updateBattleState(BATTLE_STATE.BATTLE_COMPLETE);
                return;
            }
            shakeApi(animations.shake(team === BATTLE_TEAMS.BLUE));
            // special case for suicidal dumbness, collateral_attack, hide/heal and meele weapons
            const customTranslateX = await getCustomTranslateX();

            // @ts-ignore
            if (NO_RETURN_DEFAULT_POS_ACTIONS.includes(battleDetails?.actionType) ||
                // @ts-ignore
                NO_RETURN_DEFAULT_POS_NARRATIONS.includes(battleDetails?.narrationType)) {
                // don't move the nft to center on collateral attack & player dead
                updateBattleState(BATTLE_STATE.ATTACK_IN_PROGRESS);
            } else {
                if (shouldComeToBattleField && ((!isOneVOneBattle && !isBattlePassive) || (isOneVOneBattle && firstAnimation.current))) {
                    moveApi(animations.move(
                        team === BATTLE_TEAMS.BLUE,
                        !isTwoNftInvolved,
                        customTranslateX ?? undefined
                    ))[0].then(() => {
                        moveApi({ translateY: getTranslateYToCenter(), config: { duration: ANIMATION_VALUES.MAX_DURATION / 2 } })[0].then(() => {
                            if(battleDetails?.firstAnimOfEventGroup && 
                                ((battleDetails?.eventName && EVENTS_WITH_PRELUDE_ANIM.includes(battleDetails.eventName)) ||
                                battleDetails?.equipment && EFFECTS_WITH_PRELUDE_ANIM.includes(battleDetails.equipment))) {
                                updateBattleState(BATTLE_STATE.PRELUDE_ANIMATION);
                            } else {
                                updateBattleState(BATTLE_STATE.ATTACK_IN_PROGRESS);
                            }
                        })
                    });
                    if(battleDetails!.involvedIds.length > 1) {
                        firstAnimation.current = false;
                    }
                } else {
                    updateBattleState(BATTLE_STATE.ATTACK_IN_PROGRESS);
                }
            }

        } else if(battleState === BATTLE_STATE.PRELUDE_ANIMATION) {
            // play prelude gif and set state to attack in progress
            if(isMain) {
                if(battleDetails?.eventName && EVENTS_WITH_PRELUDE_ANIM.includes(battleDetails.eventName))
                    setPassiveEffect({
                        show: true,
                        id: Math.random().toString(),
                        image: getGifImage({
                            ...battleDetails, 
                            equipment: battleDetails?.eventName?.toUpperCase().replaceAll(" ", "_")
                        }),
                        audio: new Audio(),
                    })
            } else {
                if(battleDetails?.equipment && EFFECTS_WITH_PRELUDE_ANIM.includes(battleDetails.equipment))
                    setFullScreenEffect({
                        show: true,
                        id: Math.random().toString(),
                        image: getGifImage(battleDetails!),
                        styles: {
                            position: "absolute",
                            transform: "translate(-50%, -10%)",
                            left: "50%",
                            maxHeight: "auto",
                            maxWidth: `${SPECIAL_ACTION_GIF_WIDTH}px`,
                            pointerEvents: "none"
                        },
                        audio: new Audio(),
                    })
            }

        } else if (battleState === BATTLE_STATE.ATTACK_IN_PROGRESS) {
            // show no animation on main for collateral attack & player dead
            // @ts-ignore
            if (isMain && (!NO_RETURN_DEFAULT_POS_ACTIONS.includes(battleDetails?.actionType) &&
                // @ts-ignore
                !NO_RETURN_DEFAULT_POS_NARRATIONS.includes(battleDetails?.narrationType))
            ) {
                shouldHighlightNft && (battleDetails!.involvedIds.length === 1 || battleDetails?.actionType === ACTION_TYPE.GUN_JAMMED) && 
                    nftCardRef.current?.classList.add("battle-main-highlight");
                // backup battle equipment in another key
                if (!battleDetails?.actualWeapon) {
                    battleDetails!.actualWeapon = battleDetails!.equipment;
                    battleDetails!.equipment = getBattleEquipment(battleDetails);
                }

                if(battleDetails!.equipment === EFFECTS.DEAD_COVER) {
                    if(battleDetails?.narrationType === IGNORED_NARRATION_TYPES.EFFECT_TAKEN) {
                        handleDeadCoverStarted();
                    } else {
                        updateBattleState(BATTLE_STATE.ATTACK_COMPLETE);
                    }
                    return;
                }

                let attackGif, attackAudio = new Audio();
                try {
                    attackGif = getGifImage(battleDetails!);
                    attackAudio = getBattleAudio(battleDetails!, false);
                    attackAudio.volume = effectsVolume / 100;
                } catch (e) {
                    console.error(e);
                    alert(`Error for narration: ${battleDetails!.narrationType} equipment: ${battleDetails!.equipment} isMain: ${isMain}`);
                    updateBattleState(BATTLE_STATE.BATTLE_COMPLETE);
                }

                // special case: full screen animation for SPECIAL_NARRATION_TYPES
                // @ts-ignore
                (Object.values(SPECIAL_NARRATION_TYPES).includes(battleDetails.narrationType)) ?
                    setFullScreenEffect({
                        show: true,
                        id: Math.random().toString(),
                        image: attackGif,
                        audio: attackAudio,
                        styles: {
                            maxHeight: "90vh",
                            width: document.getElementsByClassName("left-section")[0].getBoundingClientRect().width,
                            maxWidth: document.getElementsByClassName("left-section")[0].getBoundingClientRect().width,
                            position: "absolute",
                            transform: "translate(-50%, -10%)",
                            left: "50%",
                            pointerEvents: "none",
                        }
                    })
                    :
                    // @ts-ignore
                    (battleDetails?.actionType && Object.values(SPECIAL_ACTION_TYPES).includes(battleDetails.actionType)) ?
                        setFullScreenEffect({
                            show: true,
                            id: Math.random().toString(),
                            image: attackGif,
                            audio: attackAudio,
                            styles: {
                                position: "absolute",
                                transform: "translate(-50%, -10%)",
                                left: "50%",
                                maxHeight: "auto",
                                maxWidth: `${SPECIAL_ACTION_GIF_WIDTH}px`,
                                pointerEvents: "none"
                            }
                        }) :
                        setActiveAttackEffect({
                            show: true,
                            id: Math.random().toString(),
                            image: attackGif,
                            audio: attackAudio,
                        });

                // if there's no target, this is item consumption
                if (battleDetails?.target.id === undefined) {
                    showHpChangePopup(true);
                } else if (battleDetails.actionType === ACTION_TYPE.SELF_HEAL) {
                    // special case for SELF_HEAL
                    showHpChangePopup(true);
                }

            } else {
                // target should update the state if there is no main
                // or if this is collateral attack or is player dead
                shouldHighlightNft && 
                    nftCardRef.current?.classList.add("battle-target-highlight");
                // @ts-ignore
                (battleDetails?.main.id === undefined || NO_RETURN_DEFAULT_POS_ACTIONS.includes(battleDetails?.actionType) ||
                    // @ts-ignore
                    NO_RETURN_DEFAULT_POS_NARRATIONS.includes(battleDetails?.narrationType)) &&
                    (setBattleState && setBattleState(BATTLE_STATE.ATTACK_COMPLETE));
            }

        } else if (battleState === BATTLE_STATE.ATTACK_COMPLETE) {
            if (isMain && !(battleDetails?.actionType === ACTION_TYPE.FRIENDLY_FIRE && battleDetails?.target.id === battleDetails?.main.id)) {
                if(battleDetails?.equipment === SPECIAL_ACTION_TYPES.DISTRACTION_SUCCESS) {
                    await animateDistraction(battleDetails.distractedNFTs);
                    setBattleState && setBattleState(BATTLE_STATE.BATTLE_COMPLETE);
                } else if (battleDetails?.target.id === undefined
                    || battleDetails?.target.id === battleDetails?.main.id) {
                    // target doesn't exist. No need to play hp effect
                    setBattleState && setBattleState(BATTLE_STATE.BATTLE_COMPLETE);
                }
                // @ts-ignore
                if (battleDetails?.actionType && Object.values(SPECIAL_ACTION_TYPES).includes(battleDetails.actionType)) {
                    showHpChangePopup(true);
                }
                return;
            } else if(!isMain && battleDetails?.equipment === EFFECTS.DEAD_COVER) {
                if(battleDetails.narrationType === IGNORED_NARRATION_TYPES.EFFECT_TAKEN) {
                    handleDeadCoverCompleted();
                } else if(battleDetails.narrationType === IGNORED_NARRATION_TYPES.EFFECT_OVER) {
                    setDeadCoverId(undefined);
                }
                updateBattleState(BATTLE_STATE.BATTLE_COMPLETE);
                return;
            } else if(!isMain && battleDetails?.equipment === EFFECTS.DIARRHEA) {
                const TIMEOUT_MS = 2000;
                showHpChangePopup();
                await sleep(TIMEOUT_MS);
                updateBattleState(BATTLE_STATE.BATTLE_COMPLETE);
                return;
            }

            try {
                handleAttackEffectOnTarget();
            } catch (e) {
                console.error(e);
                alert(`Error for narration: ${battleDetails!.narrationType} equipment: ${battleDetails!.equipment} isMain: ${isMain}`);
                updateBattleState(BATTLE_STATE.BATTLE_COMPLETE);
            }

        } else if (battleState === BATTLE_STATE.BATTLE_COMPLETE) {
            nftCardRef.current?.classList.remove("battle-target-highlight");
            nftCardRef.current?.classList.remove("battle-main-highlight");
            if(isOneVOneBattle) {
                firstAnimation.current && moveApi({ scale: 1, translateX: 0, translateY: 0, translateZ: 0 });
            } else if (shouldAnimate && shouldComeToDefaultPos && !isBattlePassive) 
            {
                // move nft to it's default position
                moveApi({ 
                    scale: 1, 
                    translateX: 0, 
                    translateY: 0, 
                    translateZ: 0, 
                    config: {
                        easing: getRandomEasings(), 
                        duration: ANIMATION_VALUES.MAX_DURATION,
                    } 
                });
            }
            setShouldAnimate(true);
        }
    }

    // played after the attack gif is completely played
    const handleAttackEffectOnTarget = () => {
        const warScreenCenter = document.querySelector(".left-section")!.clientWidth / 2;
        // start playing attack effect on target gif
        showHpChangePopup();
        // backup current equipment in another key
        if (typeof battleDetails!.actualWeapon === "undefined") battleDetails!.actualWeapon = battleDetails!.equipment;

        battleDetails!.equipment = getBattleEquipment(battleDetails);

        // @ts-ignore
        const effectShowingFunc = isBattlePassive ? setPassiveEffect : setActiveHpEffect;

        if (typeof battleDetails?.main.id === 'number') {
            // this is two nft battle & should show hit or miss
            if ((battleDetails.attackType == 'DODGED' || battleDetails.attackType == 'MISS') &&
                battleDetails.actionType !== ACTION_TYPE.GUN_JAMMED
            ) {
                // for special action types, dodged & miss type, we don't play gif
                let currentPropsValue: any = null;
                moveApi((_, props) => {
                    if(!currentPropsValue) currentPropsValue = props.get();
                    return {
                        to: [
                            {
                                translateX: nftCardRef.current!.getBoundingClientRect().x > warScreenCenter ? 
                                    currentPropsValue.translateX + 20 : 
                                    currentPropsValue.translateX - 20, 
                                translateY: currentPropsValue.translateY - 20, 
                                percentage: 0.5,
                                config: {
                                    easing: easings.easeOutQuad,
                                    duration: 500,  // todo: 1 - calculate according to attack effect gif duration
                                }
                            },
                            {
                                translateX: currentPropsValue.translateX, 
                                translateY: currentPropsValue.translateY, 
                                percentage: 1,
                                config: {
                                    easing: easings.easeOutElastic,
                                    duration: 1000, // todo: 2 - calculate according to attack effect gif duration
                                }
                            },
                        ],
                    }
                })[0].then(() => {
                    handleHpEffectComplete();
                });
            } else if(battleDetails.actionType && Object.keys(SPECIAL_ACTION_TYPES).includes(battleDetails.actionType)) {
                const TIMEOUT_MS = battleDetails.actionType === ACTION_TYPE.GUN_JAMMED ? 300 : 2000;
                setTimeout(handleHpEffectComplete, TIMEOUT_MS);
            } else {
                try {
                    effectShowingFunc({
                        id: Math.random().toString(),
                        show: battleDetails?.equipment !== EFFECTS.DEAD_COVER ? true : false,
                        image: getGifImage(battleDetails, true),
                        audio: getBattleAudio(battleDetails, true),
                    });
                } catch(e) {
                    console.error(e);
                    alert(`Error for narration: ${battleDetails!.narrationType} equipment: ${battleDetails!.equipment} isMain: ${isMain}`);
                    updateBattleState(BATTLE_STATE.BATTLE_COMPLETE);
                }
            }
        } else {
            try {
                // this is EFFECT_LOG from previous attack like poison, bleeding, etc
                effectShowingFunc({
                    id: Math.random().toString(),
                    show: battleDetails?.equipment !== EFFECTS.DEAD_COVER ? true : false,
                    image: getGifImage(battleDetails!, false),
                    audio: getBattleAudio(battleDetails!, true),
                });
            } catch (e) {
                console.error(e);
                alert(`Error for narration: ${battleDetails!.narrationType} equipment: ${battleDetails!.equipment} isMain: ${isMain}`);
                updateBattleState(BATTLE_STATE.BATTLE_COMPLETE);
            }
        }
    }

    const handlePreludeAnimComplete = () => {
        if(battleDetails?.equipment && EFFECTS_WITH_PRELUDE_ANIM.includes(battleDetails.equipment)) {
            setFullScreenEffect({ id: "", show: false, image: undefined, styles: {}, audio: new Audio(), });
            updateBattleState(BATTLE_STATE.ATTACK_COMPLETE);
            return;
        }
        setPassiveEffect({ id: "", show: false, image: undefined, audio: new Audio(), });
        updateBattleState(BATTLE_STATE.ATTACK_IN_PROGRESS);
    }

    const handleAttackComplete = () => {
        activeAttackEffect.show && setActiveAttackEffect({ id: "", show: false, image: undefined, audio: new Audio(), });
        fullScreenEffect.show && setFullScreenEffect({ id: "", show: false, image: undefined, audio: new Audio(), styles: {} });
        nftCardRef.current?.classList.remove("war-hp-popup");
        // @ts-ignore
        setBattleState && setBattleState((prevState: string) => (prevState === BATTLE_STATE.ATTACK_IN_PROGRESS ? BATTLE_STATE.ATTACK_COMPLETE: prevState));
    }

    const onPartialAttackCompletion = () => {
        // @ts-ignore
        setBattleState && setBattleState((prevState: string) => (prevState === BATTLE_STATE.ATTACK_IN_PROGRESS ? BATTLE_STATE.ATTACK_COMPLETE: prevState));
    }

    const handleHpEffectComplete = () => {
        setActiveHpEffect({ id: "", show: false, image: undefined, audio: new Audio() });
        nftCardRef.current?.classList.remove("war-hp-popup");
        setBattleState && setBattleState(BATTLE_STATE.BATTLE_COMPLETE);
    }

    const handlePassiveEffectComplete = () => {
        if(battleDetails?.firstAnimOfEventGroup && 
            (battleDetails?.eventName && EVENTS_WITH_PRELUDE_ANIM.includes(battleDetails.eventName))) {
            handlePreludeAnimComplete();
        } else {
            updateBattleState(BATTLE_STATE.BATTLE_COMPLETE);
            setPassiveEffect({ id: "", show: false, image: undefined, audio: new Audio(), });
            nftCardRef.current?.classList.remove("war-hp-popup");
        }
    }

    const handleDeadCoverStarted = () => {
        // move main towards target
        const targetPos = document.getElementById(`${NFT_CARD_ID_PREFIX}${battleDetails?.target?.id?.toString(16)}`)?.getBoundingClientRect();
        if(isMain) {
            const currPos = nftCardRef.current?.getBoundingClientRect().x;
            const finalPos = team === BATTLE_TEAMS.BLUE ? (targetPos?.left ?? 0) + (targetPos?.width ?? 0) :
                (targetPos?.left ?? 0) - (targetPos?.width ?? 0);  
            moveApi({ translateX: (finalPos! - currPos!) / ANIMATION_VALUES.warNftScale })[0].then(() => {
                updateBattleState(BATTLE_STATE.ATTACK_COMPLETE);
            });
        }
    }

    const handleDeadCoverCompleted = () => {
        // set dead cover id
        setDeadCoverId(`${NFT_CARD_ID_PREFIX}${battleDetails?.main?.id?.toString()}`);
    }

    return (
        <>
            <animated.div
                ref={nftCardRef}
                id={`anim_card_container_${id}`}
                className={getWrapperClassName()}
                style={animationStyles}
            >
                {activeAttackEffect.show &&
                    <GifLoader
                        className={getAttackingGifClass()}
                        GifImg={`${activeAttackEffect.image}`}
                        delay={0}
                        repeatCount={1}
                        onLoad={playAudio}
                        onComplete={handleAttackComplete}
                        partialCompletePercent={partialAttackCompletePercent}
                        onPartialCompletion={onPartialAttackCompletion}
                    />
                }
                {activeHpEffect.show &&
                    <GifLoader
                        className={getTargetGifClass()}
                        GifImg={`${activeHpEffect.image}`}
                        delay={0}
                        repeatCount={1}
                        onLoad={playAudio}
                        onComplete={handleHpEffectComplete}
                    />
                }
                {passiveEffect.show &&
                    <GifLoader
                        className={getTargetGifClass()}
                        GifImg={`${passiveEffect.image}`}
                        delay={0}
                        repeatCount={1}
                        onLoad={playAudio}
                        onComplete={handlePassiveEffectComplete}
                    />
                }
                {!isNil(deadCoverId) && 
                    <div 
                        className="dead-cover"
                        dangerouslySetInnerHTML={{
                        __html: sanitize(document.getElementById(`${deadCoverId}`)?.innerHTML ?? "")
                        }}
                    >
                    </div>
                }
                {children}
            </animated.div>
        </>
    );
}