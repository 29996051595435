import { useDetailGlobalContext } from "@context/detailProvider";
import { Dispatch, FC, ReactNode, SetStateAction, useEffect } from "react";
import { AsyncState, RentalLoadingTexts } from "./rental.interface";
import RentalLoading from "./loading.rental";
import { useTransactionValidate } from "@hooks/transactionResult";
import RentalFailed from "./failed.rental.";

interface IrentalWrapper {
  asyncState: string;
  setRentalModal: Dispatch<
    SetStateAction<"rent" | "update" | "remove" | "lend" | "claim" | null>
  >;
  setAsyncState: Dispatch<SetStateAction<string>>;
  type: keyof typeof RentalLoadingTexts;
  children: ReactNode;
}

const RentalWrapper: FC<IrentalWrapper> = ({
  asyncState,
  type,
  setRentalModal,
  setAsyncState,
  children,
}) => {
  const {
    transactionLoading,
    setTransactionLoading,
    setShowAsyncStateModal,
  } = useDetailGlobalContext();
  console.log({ transactionLoading })
  useTransactionValidate({
    onHandle: () => { },
  });

  const onUnmount = () => {
    setTransactionLoading((prev) => ({
      ...prev,
      state: "idle",
      status: false,
    }));
    setShowAsyncStateModal(true);
  };

  const onMount = () => {
    setShowAsyncStateModal(false);
    setTransactionLoading((prev) => ({
      ...prev,
      state: "idle",
      status: true,
    }));
  };

  useEffect(() => {
    onMount();
    return onUnmount;
  }, []);

  useEffect(() => {
    setAsyncState(transactionLoading.state);
  }, [setAsyncState, transactionLoading.state]);

  if (asyncState === AsyncState.CANCELED) {
    setRentalModal(null);
    setTransactionLoading((prev) => ({
      ...prev,
      state: "idle",
      status: false,
    }));
    return null;
  }

  if (asyncState === AsyncState.LOADING)
    return (
      <RentalLoading
        title={RentalLoadingTexts[type][AsyncState.LOADING].title}
        description={RentalLoadingTexts[type][AsyncState.LOADING].description}
      />
    );

  if (asyncState === AsyncState.FAILED)
    return (
      <RentalFailed
        setRentalModal={setRentalModal}
        title={RentalLoadingTexts[type][AsyncState.FAILED].title}
        description={RentalLoadingTexts[type][AsyncState.FAILED].description}
      />
    );

  return <>{children}</>;
};

export default RentalWrapper;
