import { AiOutlineDown, AiOutlineClose } from "react-icons/ai";
import NFTCard from "@components/card/nftCard.component";
import { useState, useEffect, useRef, useLayoutEffect } from "react";
import CharacterInfo from "@components/chooseCharacter/CharacterInfo.component";
import { GB_HAT, LOADING } from "@constants/images";
import { FiSearch } from "react-icons/fi";
import { EMPTY_HAT } from "@constants/images";
import SelectionScreen from "@components/screen/selectionScreen.component";
import GameTime from "@components/screen/gameTime.component";
import { handleDropdownClose } from "@utils/closeDropdown";
import { sortNFTList } from "@type/dropDownOption.type";
import { MARKETPLACE_URL } from "@misc/url";
import { searchNFTNameAndId } from "@utils/searchNFT";
import CharacterEquipment from "@components/chooseCharacter/CharacterEquipment.component";
import { useSelector } from "react-redux";
import { useNFTDetailGlobalContext } from "@context/nftInformation";
import { PLAY_NORMAL_CLICK } from "@constants/audio";
import { NFTsDetails } from "@interfaces/nft.interface";
import Typewriter from "typewriter-effect";
import { NFT_RENT_STATUS } from "@constants/nft";
import { useNavigate } from "react-router-dom";
import { GangstaBetType, GangstaBetTypeCount, WeaponTypeCount } from "@misc/warSpecific";
import { AddSpecificGameTag, getKeyByValue } from "@misc/specificTagGames";
import { useNFTInformation } from "@context/nftInfoProvider";
import { useRentalMarketplace } from "@hooks/renatlMarketPlace";
import GangstaBetCollectionPagination from "@components/gangstabet/gangstaBetPagination";
import { usePaginatedRentalMarket } from "@hooks/usePaginatedRentalData";
import { ZERO_ADDRESS } from "@type/rental.type";
import GangstaBetRentalLoading from "@components/gangstabet/gangstaBetRentalLoading";

interface ICharacter {
    isScreenClose: boolean;
    chooseCharacterScreenClose: React.Dispatch<React.SetStateAction<boolean>>;
    gangwarId: number;
    war: any;
    setShowLobbyDetails: any;
}
type CHOOSE_NFT_TAB = "YOUR_NFT" | "RENTED_NFT"
const Character = ({
    isScreenClose,
    chooseCharacterScreenClose,
    gangwarId,
    war,
    setShowLobbyDetails,
}: ICharacter) => {
    console.log("allowedLevels", war);
    const { walletAddress } = useSelector((state: any) => state.wallet);
    const { nftsDetails, currentBatchIndex } = useNFTDetailGlobalContext();
    const { walletNFTDetails, gangstaBetLoading } = useNFTInformation();
    // const { rentalData, rentalLoading } = useRentalMarketplace(0, "TENANT", walletAddress);
    const URL = `${process.env.REACT_APP_BASE_URL}/gangwar/rental/getAddressActiveRentals/${walletAddress ?? ZERO_ADDRESS}`
    const { data, loading, loaderRef } = usePaginatedRentalMarket(URL, 1, "TENANT", walletAddress ?? ZERO_ADDRESS);
    const { ownedNft } = useSelector((state: any) => state.inventory);
    const navigate = useNavigate();
    const [selectedCharacterInfo, setSelectedCharacterInfo] = useState<any>({});
    const [animationToggle, setAnimationToggle] = useState<boolean>(false);
    const [highlightSelected, setHighlightSelected] = useState<boolean>(false);
    const [showFilter, setShowFilter] = useState<boolean>(false);
    const [searchQuery, setSearchQuery] = useState<string>("");
    const [selectedIndex, setSelectedIndex] = useState<string>("-1");
    const [selectedCard, setSelectedCard] = useState<any | null>(null);
    const [selectedChar, setSelectedChar] = useState<boolean>(false);
    const [selectedNFTTab, setSelectedNFTTab] = useState<CHOOSE_NFT_TAB>("YOUR_NFT")

    const sortByDropdownRef = useRef<any>(null);

    const handleSelectCard = (nftId: any) => {
        setSelectedCard(nftId);
    };

    useEffect(() => {
        handleDropdownClose(sortByDropdownRef, setShowFilter);
    });

    const [sortByOption, setSortByOption] = useState<any>(sortNFTList[0]);

    // const { gangstabetLoading } = useNFTDetailGlobalContext();

    const [NFTDetailsData, setNFTDetailsData] = useState([]);

    /**
     * filtered rented nft and removed any owned nfts which is currently on rent.
     */
    useLayoutEffect(() => {
        setNFTDetailsData(
            (selectedNFTTab === "YOUR_NFT" ? walletNFTDetails : data).filter(
                (nft: any) =>
                    nft.rental.status === NFT_RENT_STATUS.NOT_AVAILABLE_FOR_RENT ||
                    nft.rental.status === NFT_RENT_STATUS.DE_LISTED ||
                    // TODO: remove/check it before releasing it to prod
                    // nft.rental.status === NFT_RENT_STATUS.RENTED ||
                    nft.rental.status === NFT_RENT_STATUS.IN_RENT

            )
        );
    }, [walletNFTDetails, data, selectedNFTTab]);

    // useEffect(() => {
    //     setLoading(true);
    //     setTimeout(() => {
    //         setLoading(false);
    //     }, 6000);
    // }, [currentBatchIndex]);

    const renderSelectedCharacter = () => {
        return (
            <CharacterInfo
                warStartTime={Number(war.endTime) / 1000}
                selectedCharacterInfo={selectedCharacterInfo}
                animationToggle={animationToggle}
            />
        );
    };
    // const filteredNFTsDetails = NFTDetailsData.filter((nft: any) => {
    //     const raceId = getKeyByValue(GangstaBetType, [nft.gangstaBetDetails.race])[0];
    //     return (
    //         war.allowedLevels.includes(`0x${Number(nft.gangstaBetDetails.levelNumber).toString(16)}`) &&
    //         war.allowedTypes.includes(`0x${Number(raceId).toString(16)}`)
    //     );
    // });
    const highlightNFTsDetails = ((nftDetails: any) => {
        const raceId = getKeyByValue(GangstaBetType, [nftDetails.gangstaBetDetails.race])[0];
        return (
            war.allowedLevels.includes(`0x${Number(nftDetails.gangstaBetDetails.levelNumber).toString(16)}`) &&
            war.allowedTypes.includes(`0x${Number(raceId).toString(16)}`)
        );
    });
    console.log({ selectedCharacterInfo });
    // const filteredNFTsDetails = nftsDetails.filter(
    //   (nft: NFTsDetails) => nft.levelNumber === nft.levelNumber
    // );
    const arrangeNFTCards = () => {
        if (sortByOption.value === "highToLow") {
            return searchNFTNameAndId(NFTDetailsData, searchQuery);
        } else if (sortByOption.value === "lowToHigh") {
            return searchNFTNameAndId(NFTDetailsData, searchQuery).reverse();
        }
    };
    const setNFTInfo = (item: any, indexWithTabName: string) => {
        if (indexWithTabName !== selectedIndex) {
            setSelectedIndex(`${selectedNFTTab}_${indexWithTabName}`);
            setSelectedCharacterInfo(item);
            setAnimationToggle(!animationToggle);
        }
    };
    return (
        <>
            <SelectionScreen
                isScreenClose={isScreenClose}
                setScreenClose={chooseCharacterScreenClose}
            >
                <div className="selectCharacterLeftPart">
                    <div className="allNFTFilters">
                        <div className="flex gap-2">
                            <div
                                className="buyNFTBtn"
                                onClick={() => {
                                    PLAY_NORMAL_CLICK();
                                    window.open(MARKETPLACE_URL, "_blank");
                                }}
                            >
                                <img src={GB_HAT} alt="gb_hat" /> Buy NFT
                            </div>
                            <div
                                className="buyNFTBtn"
                                onClick={() => {
                                    PLAY_NORMAL_CLICK();
                                    navigate("/rental");
                                }}
                            >
                                Rent NFT
                            </div>
                        </div>
                        <div className="searchNFT">
                            <div className="searchInput">
                                <FiSearch color="white" className="searchIcon" />
                                <input
                                    placeholder="Search NFTs by name or number"
                                    className="searchBox"
                                    type="text"
                                    value={searchQuery}
                                    onChange={(e) => {
                                        setSearchQuery(e.target.value);
                                    }}
                                />
                                {searchQuery && (
                                    <AiOutlineClose
                                        onClick={() => {
                                            setSearchQuery("");
                                        }}
                                        color="white"
                                        className="closeSearchIcon"
                                    />
                                )}
                            </div>
                        </div>

                        <div className="sortNFTby" ref={sortByDropdownRef}>
                            <div
                                className="sortBy"
                                onClick={() => {
                                    PLAY_NORMAL_CLICK();
                                    setShowFilter(!showFilter);
                                }}
                            >
                                <div className="sortByText">
                                    Sort By
                                    {/* {sortByOption.name} */}
                                </div>
                                <AiOutlineDown />
                            </div>
                            {showFilter && (
                                <div className="sortByDrop">
                                    {sortNFTList.map((option, index) => {
                                        return (
                                            <p
                                                key={`${index}_sort`}
                                                className={
                                                    sortByOption.name === option.name
                                                        ? "filter-selected"
                                                        : ""
                                                }
                                                onClick={() => {
                                                    setSortByOption(option);
                                                    setShowFilter(!showFilter);
                                                }}
                                            >
                                                {option.name}
                                            </p>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="flex justify-between">

                        {war.allowedTypes.length !== GangstaBetTypeCount() ? (
                            <div className="flex items-center gap-1 ml-3 mt-1">
                                <AddSpecificGameTag
                                    specificType={war.allowedTypes}
                                    tag="GangstaBetType"
                                    customId={war.id}
                                />
                            </div>
                        ) : <div></div>}

                        <div className="flex gap-2 justify-end mx-2 mt-1">
                            <div onClick={() => { if (selectedNFTTab !== "YOUR_NFT") setSelectedCharacterInfo({}); setSelectedNFTTab("YOUR_NFT"); }} className={`cursor-pointer ${selectedNFTTab === "YOUR_NFT" ? "chooseCharacterSelectedNFTTab" : "chooseCharacterNFTTab"} `}>Your NFTs</div>
                            <div onClick={() => { if (selectedNFTTab !== "RENTED_NFT") setSelectedCharacterInfo({}); setSelectedNFTTab("RENTED_NFT"); }} className={`cursor-pointer ${selectedNFTTab === "RENTED_NFT" ? "chooseCharacterSelectedNFTTab" : "chooseCharacterNFTTab"}`}>Rented NFTs</div>
                        </div>
                    </div>
                    <div className="nftCards">
                        {searchNFTNameAndId(NFTDetailsData, searchQuery).length > 0 &&
                            (!gangstaBetLoading || (selectedNFTTab === "RENTED_NFT" && data && data.length > 0)) ? (
                            <div className="flex flex-col items-center">
                                <div className="flex flex-wrap gap-[10px]">
                                    {arrangeNFTCards().map((item: any, index: number) => {
                                        if (!item?.gangstaBetDetails.nftId) {
                                            return null;
                                        } else {
                                            const highlight = highlightNFTsDetails(item);
                                            console.log({ highlight })
                                            return (
                                                <div
                                                    key={`${index}_nftList`}
                                                    onClick={() => {
                                                        PLAY_NORMAL_CLICK();
                                                        setHighlightSelected(!highlightSelected);
                                                    }}
                                                    className={`${!highlight && "disable opacity-60"} nftAllCards`}
                                                >
                                                    <NFTCard
                                                        cardType="nftCardImageChooseCharacter"
                                                        hospitalized={{
                                                            cardSize: "NFTOnIllSm",
                                                            hospitalizedTimer: item.hospitalization.warCooldown,

                                                        }}

                                                        showCard={false}

                                                        rental={
                                                            {
                                                                cardSize: "NFTOnRentSm",
                                                                rentalType: item.rental.status,
                                                                rentalTimer: item.rental.expirationTimestamp,
                                                                rentalName: item.rental.rentalName,
                                                            }
                                                        }
                                                        locked={
                                                            item.lockStatus && {
                                                                cardSize: "NFTOnIllSm",
                                                            }
                                                        }
                                                        isSelected={item.gangstaBetDetails.nftId === selectedCard}
                                                        onSelect={handleSelectCard}
                                                        onClick={() => {
                                                            setNFTInfo(item, `${selectedNFTTab}_${index}`);
                                                            PLAY_NORMAL_CLICK();
                                                        }}
                                                        width="110px"
                                                        height="140px"
                                                        nftId={item.gangstaBetDetails.nftId}
                                                        nftImage={item.gangstaBetDetails.nftImage}
                                                    />
                                                    <p className="nftName text-center truncate w-[100px]">
                                                        {item.gangstaBetDetails.nftName}
                                                    </p>
                                                </div>
                                            );
                                        }
                                    })}
                                </div>
                                {selectedNFTTab === "YOUR_NFT" && <GangstaBetCollectionPagination />}
                                {loading && selectedNFTTab === "RENTED_NFT" &&
                                    <div className="mt-6">
                                        <GangstaBetRentalLoading />
                                    </div>
                                }
                                <div ref={loaderRef} style={{ height: '10px' }} />
                                {currentBatchIndex < Math.ceil(ownedNft.length / 5) && (
                                    <p className="text-white batchLoading  flex text-lg">
                                        Loading{" "}
                                        <Typewriter
                                            options={{
                                                strings: ["..."],
                                                autoStart: true,
                                                loop: true,
                                                cursor: ".",
                                            }}
                                        />
                                    </p>
                                )}
                            </div>
                        ) : (gangstaBetLoading) || (data.length === 0 && loading) ? (
                            <div className="w-full h-[400px] flex justify-center items-center">
                                {" "}
                                <img width={220} src={LOADING} alt="loading circle" />
                            </div>
                        ) : (
                            <div className="searchNotFound mt-5">
                                <img src={EMPTY_HAT} alt="empty hat" />
                                <p className="notFoundNFT">NO NFT FOUND</p>
                                <p className="notFoundInfo">
                                    We're sorry, but it seems that you do not have any GangstaBet
                                    for the war. You can{" "}
                                    <span
                                        onClick={() => {
                                            window.open(MARKETPLACE_URL, "_blank");
                                        }}
                                        className="cursor-pointer"
                                    >
                                        <b>Buy</b>
                                    </span>{" "}
                                    /{" "}
                                    <span
                                        onClick={() => {
                                            navigate("/rental");
                                        }}
                                        className="cursor-pointer"
                                    >
                                        <b>Rent </b>
                                    </span>
                                    a GangstaBet.
                                </p>
                            </div>
                        )}
                    </div>
                </div>
                <div
                    style={{ position: "relative", overflow: "hidden" }}
                    className="selectCharacterRightPart"
                >
                    <div className="nftCharacterDetails">
                        {Object.keys(selectedCharacterInfo).length > 0
                            ? renderSelectedCharacter()
                            : !(
                                searchNFTNameAndId(NFTDetailsData, searchQuery).length <= 0
                            ) && <p className="nftNotSelected">SELECT AN NFT TO SEE DETAILS</p>}
                    </div>
                    <GameTime
                        war={war}
                        isRentTimeWillOver={selectedCharacterInfo?.rental?.status === NFT_RENT_STATUS.IN_RENT && (Number(selectedCharacterInfo.rental.expirationTimestamp) / 1000) < (Number(war.endTime) / 1000)}
                        handleContinue={() => setSelectedChar(true)}
                        disabled={!selectedCharacterInfo?.gangstaBetDetails?.nftId}
                        isHospitalized={Boolean(selectedCharacterInfo?.hospitalization?.cooldownActivated)}
                        isNFTLocked={selectedCharacterInfo?.lockStatus}
                    />
                </div>
            </SelectionScreen >

            {selectedChar && (
                <CharacterEquipment
                    // userEquipmentDetails={userEquipmentDetails}
                    // userItemsDetails={userItemsDetails}
                    isScreenClose={isScreenClose}
                    chooseEquipedScreenClose={setSelectedChar}
                    selectedCharacterInfo={selectedCharacterInfo}
                    // getItemNumberCount={getItemNumberCount}
                    // setItemNumberCount={setItemNumberCount}
                    // userAbilityDetails={userAbilityDetails}
                    // getAbilityNumberCount={getAbilityNumberCount}
                    // setAbilityNumberCount={setAbilityNumberCount}
                    setShowLobbyDetails={setShowLobbyDetails}
                    gangwarId={gangwarId}
                    warFee={Number(war.fee) / 10 ** 18}
                    walletAddress={walletAddress}
                    war={war}
                    paymentMethod={war.paymentMethod}
                    setScreenClose={chooseCharacterScreenClose}
                />
            )
            }
        </>
    );
};

export default Character;
