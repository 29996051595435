import { AiOutlineInfoCircle } from "react-icons/ai";
import Image from "@misc/Image";

const SpeedList = ({
  teamASppedList,
  teamBSppedList,
  teamBInfo,
  teamAInfo,
}: any) => {
  return (
    <div className={`warSpeedListWrapper `}>
      <div className="spedListHeading">
        <h1 className="px-4">Speed List</h1>
      </div>
      <div className="spdListTable flex flex-col gap-2">
        <div className="teamATableSpdList">
          <div className="teamATextSpd">
            <h1>Team A</h1>
          </div>
          <div>
            <div className="spdListTableHead">
              <p className="spdListTurn">Turn</p>
              <p className="spdListName">Name</p>
              <p className="spdListSPD flex justify-center items-center ">
                <span className="mr-1">SPD</span>
                <AiOutlineInfoCircle className="cursor-pointer" />
              </p>
            </div>
            <div className="flex flex-col gap-0.5">
              {teamASppedList.map((details: any, index: number) => {
                return (
                  <div
                    key={`keys_${index}`}
                    className={`spdListTableData ${details?.isCurrentlyPerforming &&
                      "spdListTableDataSelectedTeamA"}`}
                  >
                    <p className="spdListTurnValue flex flex-col items-center">
                      #{details?.pos}
                      {/* <BiChevronsDown /> */}
                      {/* details?.isCurrentlyPerforming */}
                    </p>
                    <p className="spdListNameValue flex gap-3">
                      <div className="spdListImage">
                        <Image width="28px" height="37.5px" id={details.id} />
                      </div>
                      <div className="spdListGBDetails flex gap-1 flex-col items-start justify-center">
                        <h2>{teamAInfo?.[details.id]?.token_name}</h2>
                        <p>
                          <span>{teamAInfo?.[details.id]?.class_type}</span>
                        </p>
                      </div>
                    </p>
                    <p className="spdListSPDValue ">
                      {details?.speed.toFixed(0)}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="teamBTableSpdList">
          <div className="teamBTextSpd">
            <h1>Team B</h1>
          </div>
          <div>
            <div className="spdListTableHead">
              <p className="spdListTurn">Turn</p>
              <p className="spdListName">Name</p>
              <p className="spdListSPD flex justify-center items-center ">
                <span className="mr-1">SPD</span>
                <AiOutlineInfoCircle className="cursor-pointer" />
              </p>
            </div>
            <div className="flex flex-col gap-0.5">
              {teamBSppedList.map((details: any, index: number) => {
                return (
                  <div
                    key={`keys_${index}`}
                    className={`spdListTableData ${details?.isCurrentlyPerforming &&
                      "spdListTableDataSelectedTeamB"}`}
                  >
                    <p className="spdListTurnValue flex flex-col items-center">
                      #{details?.pos}
                      {/* <BiChevronsDown /> */}
                    </p>
                    <p className="spdListNameValue flex gap-3">
                      <div className="spdListImage">
                        <Image width="28px" height="37.5px" id={details.id} />
                      </div>
                      <div className="spdListGBDetails flex gap-1 flex-col items-start justify-center">
                        <h2>{teamBInfo?.[details.id]?.token_name}</h2>

                        <p>
                          <span>{teamBInfo?.[details.id]?.class_type}</span>
                        </p>
                      </div>
                    </p>
                    <p className="spdListSPDValue ">
                      {details?.speed.toFixed(0)}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SpeedList;
