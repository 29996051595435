import React, { useEffect, useState } from "react";
import {
    ABILITY,
    ABILITY1,
    CROPPEDGRENADE,
    GRENADE,
    ITEM,
    LOADING,
    SHIELD,
} from "@constants/images";
import { RxStack } from "react-icons/rx";
import { GiGrenade } from "react-icons/gi";
import EquipmentStylizedCard from "@components/card/equipmentStylizedCard.component";
import InventoryBody from "@pages/inventory/InventoryBody";
import { useCardAnimation } from "@hooks/useCardAnimation";
import { IGetUserAbility } from "@interfaces/ability.interface";
import { useDetailGlobalContext } from "@context/detailProvider";
import NFTCard from "@components/card/nftCard.component";
import PrimaryButton from "@components/buttons/primary.button";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { Tooltip } from "react-tooltip";
import { HYPERLINKS } from "@misc/links";
import { PLAY_CLOSE_SOUND, PLAY_NORMAL_CLICK, PLAY_INVENTORY_CLICK } from "@constants/audio";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";

const Abilities = () => {
    const [animationToggle, setAnimationToggle] = useState<boolean>(false);
    useCardAnimation("animateThis", animationToggle);

    const {
        userAbilityDetails,
        userAbilityDetailsWithSeperation,
        loadingFetchUserDetails,
    } = useDetailGlobalContext();

    const [ability, setAbility] = useState<any>({
        active: [],
        passive: [],
    });
    useEffect(() => {
        setAbility({
            active: [],
            passive: [],
        });
        userAbilityDetails.map((ability) => {
            if (ability.type === "Active") {
                setAbility((prev: any) => ({
                    ...prev,
                    active: [...prev.active, ability],
                }));
            } else if (ability.type === "Passive") {
                setAbility((prev: any) => ({
                    ...prev,
                    passive: [...prev.passive, ability],
                }));
            }
        });
    }, [userAbilityDetails]);

    const [abilityDetails, setAbilityDetails] = useState<IGetUserAbility | null>(null);

    const [collpase, setCollapse] = useState({
        active: true,
        passive: true,
    });

    return (
        <>
            <InventoryBody>
                {userAbilityDetails.length > 0 && !loadingFetchUserDetails ? (
                    <>
                        <div className="col-span-7 abilitiesOptions">
                            {ability?.passive?.length > 0 && (
                                <div className=" flex flex-col gap-5">
                                    <div className="flex gap-1 items-center">
                                        <div className="flex gap-1 items-center">
                                            <p className="w-[105px]">Passive Moves</p>
                                            {/* className="w-[125px]" */}
                                            <IoIosInformationCircleOutline
                                                className="cursor-pointer mt-1"
                                                color="#485C65"
                                                size={20}
                                                id="passiveAbilities"
                                                data-tooltip-content="Sneak moves which grants an effect which is always on and works automatically without needing to be triggered.
                      "
                                            />
                                            <Tooltip
                                                style={{
                                                    zIndex: 999,
                                                    maxWidth: "300px",
                                                    opacity: 1,
                                                }}
                                                anchorSelect="#passiveAbilities"
                                            />
                                        </div>
                                        <hr className="w-[100%] speInventoryTop " />
                                        {collpase.passive ? (
                                            <RiArrowDropUpLine
                                                size={30}
                                                className="text-gray min-w-[30px] mr-3 cursor-pointer"
                                                onClick={() => {
                                                    setCollapse((prev) => ({
                                                        ...prev,
                                                        passive: !collpase.passive,
                                                    }));
                                                }}
                                            />
                                        ) : (
                                            <RiArrowDropDownLine
                                                size={30}
                                                className="text-gray min-w-[30px] mr-3 cursor-pointer"
                                                onClick={() => {
                                                    setCollapse((prev) => ({
                                                        ...prev,
                                                        passive: !collpase.passive,
                                                    }));
                                                }}
                                            />
                                        )}
                                    </div>
                                    <div className="flex flex-wrap gap-6">
                                        {ability?.passive?.map(
                                            (item: IGetUserAbility, index: number) => {
                                                if (
                                                    index <
                                                    (collpase.passive
                                                        ? ability?.passive?.length
                                                        : 0)
                                                )
                                                    return (
                                                        <>
                                                            <div
                                                                id={`passiveAbility`}
                                                                className={
                                                                    "border inventoryBoxHover max-w-[175px] min-w-[175px] min-h-[201px] flex flex-col justify-center items-center   pt-8 px-4 cursor-pointer relative " +
                                                                    (abilityDetails?.id === item.id
                                                                        ? "activeOption "
                                                                        : "apply-border")
                                                                }
                                                                onClick={() => {
                                                                    PLAY_INVENTORY_CLICK();
                                                                    setAbilityDetails(item);
                                                                    setAnimationToggle(
                                                                        !animationToggle
                                                                    );
                                                                }}
                                                            >
                                                                <div className="image-background-inventory">
                                                                    <img
                                                                        src={item.image}
                                                                        alt=""
                                                                        className="h-[120px] p-6 "
                                                                    />
                                                                    <div className="number ">
                                                                        <span className="text-sm font-normal">
                                                                            x
                                                                        </span>
                                                                        {item.count}
                                                                    </div>
                                                                </div>

                                                                <p className="mt-5 pb-3 font-semibold">
                                                                    {" "}
                                                                    {item.name}
                                                                </p>
                                                            </div>
                                                        </>
                                                    );
                                            }
                                        )}
                                    </div>
                                </div>
                            )}
                            {ability?.active?.length > 0 && (
                                <div className="flex flex-col mt-5 gap-4">
                                    <div className="flex gap-1 items-center">
                                        <div className="flex gap-1 items-center">
                                            <p className="w-[105px]">Active Moves</p>
                                            {/* className="w-[125px]" */}
                                            <IoIosInformationCircleOutline
                                                className="cursor-pointer mt-1"
                                                color="#485C65"
                                                size={20}
                                                data-tooltip-content="Sneak Moves which need to be triggered manually during a GangWar in order to use
                      "
                                                id="activeAbilities"
                                            />
                                            <Tooltip
                                                style={{
                                                    zIndex: 999,
                                                    maxWidth: "300px",
                                                    opacity: 1,
                                                }}
                                                anchorSelect="#activeAbilities"
                                            />
                                        </div>
                                        <hr className="w-[100%] speInventoryTop " />
                                        {collpase.active ? (
                                            <RiArrowDropUpLine
                                                size={30}
                                                className="text-gray min-w-[30px] mr-3 cursor-pointer"
                                                onClick={() => {
                                                    setCollapse((prev) => ({
                                                        ...prev,
                                                        active: !collpase.active,
                                                    }));
                                                }}
                                            />
                                        ) : (
                                            <RiArrowDropDownLine
                                                size={30}
                                                className="text-gray min-w-[30px] mr-3 cursor-pointer"
                                                onClick={() => {
                                                    setCollapse((prev) => ({
                                                        ...prev,
                                                        active: !collpase.active,
                                                    }));
                                                }}
                                            />
                                        )}
                                    </div>
                                    <div className=" flex flex-wrap gap-6">
                                        {ability?.active?.map(
                                            (item: IGetUserAbility, index: number) => {
                                                if (
                                                    index <
                                                    (collpase.active ? ability?.active?.length : 0)
                                                )
                                                    return (
                                                        <div
                                                            className={
                                                                "border inventoryBoxHover max-w-[175px] min-w-[175px] min-h-[201px] flex flex-col justify-center items-center   pt-8 px-4 cursor-pointer relative " +
                                                                (abilityDetails?.id === item.id
                                                                    ? "activeOption "
                                                                    : "apply-border")
                                                            }
                                                            onClick={() => {
                                                                PLAY_INVENTORY_CLICK();
                                                                setAbilityDetails(item);
                                                                setAnimationToggle(
                                                                    !animationToggle
                                                                );
                                                            }}
                                                        >
                                                            <div className="image-background-inventory">
                                                                {" "}
                                                                <img
                                                                    src={item.image}
                                                                    alt=""
                                                                    className="h-[120px] p-6 "
                                                                />
                                                                <div className="number">
                                                                    <span className="text-sm font-normal">
                                                                        x
                                                                    </span>
                                                                    {item.count}
                                                                </div>
                                                            </div>

                                                            <p className="mt-5 pb-3 font-semibold">
                                                                {" "}
                                                                {item.name}
                                                            </p>
                                                        </div>
                                                    );
                                            }
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                        {abilityDetails ? (
                            <div className="col-span-3 flex flex-col justify-between details overflow-hidden">
                                <div className="flex flex-col animation" id="animateThis">
                                    <div className="flex items-center gap-4">
                                        <EquipmentStylizedCard
                                            equipmentImages={abilityDetails.image}
                                            className="setColor"
                                        />

                                        <div className="flex flex-col items-start justify-center">
                                            <h1 className="text-md font-proxima">
                                                {abilityDetails.name}
                                            </h1>

                                            <div className="warContinueBtnDiv my-2">
                                                <button className="abilityTypeBtn">
                                                    {abilityDetails.type} Ability
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="section mt-6 border-y-[1px] border-gray-500">
                                        <ul className="list-disc text-base mt-2">
                                            {abilityDetails.description}
                                        </ul>
                                    </div>
                                </div>
                                <div className="moreInfoText">
                                    <hr className="seperatorInventory" />
                                    <p className=" mt-5">For More details on Sneak Moves</p>
                                    <a href={HYPERLINKS.ABILITIES} target="_blank" rel="noopener">
                                        Click here to read the documentation.
                                    </a>
                                </div>
                            </div>
                        ) : (
                            <div className="col-span-3 flex items-center justify-center details overflow-hidden">
                                <p className="text-4xl text-center unSelectedTextState opacity-20">
                                    SELECT AN SNEAK MOVES TO SEE DETAILS
                                </p>
                            </div>
                        )}
                    </>
                ) : loadingFetchUserDetails ? (
                    <div className="col-span-10 ">
                        <div className="flex flex-col gap-2 w-full h-full items-center justify-center">
                            <img width={220} src={LOADING} alt="loading circle" />
                        </div>
                    </div>
                ) : (
                    <div className="col-span-10">
                        <div className="flex flex-col gap-2 w-full h-full items-center justify-center">
                            <div className="flex flex-col gap-2 w-full h-full items-center justify-center">
                                <NFTCard
                                    cardType="nftCardImageCollection"
                                    nftImage={ABILITY}
                                    height={"180px"}
                                    width={"145px"}
                                    className="inactive-image2"
                                />
                                <p className="text-3xl big-shoulders">NO SNEAK MOVES FOUND</p>
                                <p className="text-gray max-w-[500px] text-center">
                                    It looks like your Sneak Moves Inventory is currently empty. You
                                    can acquire Sneak Moves by opening LootCrates.
                                </p>
                                {/* <PrimaryButton className="disable" buttonName="buy abilities" /> */}
                            </div>
                            <div className="moreInfoTextEmpty w-full overflow-hidden">
                                <hr className="seperatorInventory" />
                                <div className="flex flex-col gap-2">
                                    <p className=" mt-5 text-center">
                                        For More details on sneak moves
                                    </p>
                                    <a
                                        href={HYPERLINKS.ABILITIES}
                                        target="_blank"
                                        className="text-center"
                                    >
                                        Click here to read the documentation.
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </InventoryBody>
        </>
    );
};

export default Abilities;
