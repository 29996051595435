import { useDispatch } from "react-redux";
import {
  setWalletConnection,
  setWalletAddress,
} from "@redux/features/walletSlice";
import { useEffect, useState } from "react";
import { getTransactionResult } from "@services/icon.service";
import { config } from "@config/config";
import { useDetailGlobalContext } from "@context/detailProvider";
import { getEquipmentInformation } from "@utils/getEquipmentInformation";
import { getAbilitiesInformation } from "@utils/getAbilitiesInformation";
import { getItemDetails } from "@api/api";
import { getItemsInformation } from "@utils/getItemsInformation";
import { CROWN, ICX } from "@constants/images";
import { sendSneakMoveAddTx } from "@api/api";

interface IUseTransactionValidate {
  method?: string;
  setLoadoutsReceived?: React.Dispatch<React.SetStateAction<ILoadoutReceived>>;
  navigate?: string;
  onHandle?: any;
}

interface ILoadoutReceived {
  status: boolean;
  equipment: Array<{ id: number; count: number; details: any }>;
  abilities: Array<{ id: number; count: number; details: any }>;
  items: Array<{ id: number; count: number; details: any }>;
  crown: Array<{ id: number; count: number; details: any }>;
  icx: Array<{ id: number; count: number; details: any }>;
}

export const useTransactionValidate = ({
  method,
  setLoadoutsReceived,
  navigate,
  onHandle,
}: IUseTransactionValidate) => {
  const {
    transactionLoading,
    setTransactionLoading,
  } = useDetailGlobalContext();

  const getTx = async (tx: string) => {
    const res: unknown | any = await getTransactionResult(tx);
    if (res.status === "pending") {
      setTransactionLoading((prev) => ({
        ...prev,
        state: "loading",
        status: true,
        // message: "Transaction Success",
        navigate: navigate ?? "",
        closeScreen: onHandle ?? onHandle,
      }));
      getTxResultWithTimer(tx);
    } else if (res?.status === "success") {
      if (method === "burn" && setLoadoutsReceived) {
        setLoadoutsReceived({
          status: false,
          equipment: [],
          abilities: [],
          items: [],
          crown: [],
          icx: [],
        });
        for await (let event of res.eventLogs) {
          if (event.scoreAddress === config.contract.EQUIPMENT_SCORE) {
            const result = await getEquipmentInformation(event.data[0]);
            setLoadoutsReceived((prev) => ({
              ...prev,
              equipment: [
                ...prev.equipment,
                {
                  id: Number(event.data[0]),
                  count: Number(event.data[1]),
                  details: result,
                },
              ],
            }));
          } else if (
            event.scoreAddress === config.contract.LOOTBOX_SCORE &&
            event.indexed[0] == "AbilityMint(Address,int,int)"
          ) {
            const result = await getAbilitiesInformation(event.indexed[2]);
            setLoadoutsReceived((prev) => ({
              ...prev,
              abilities: [
                ...prev.abilities,
                {
                  id: Number(event.indexed[2]),
                  count: Number(event.indexed[3]),
                  details: result,
                },
              ],
            }));
          } else if (event.scoreAddress === config.contract.ITEMS_SCORE) {
            const result = await getItemsInformation(event.data[0]);
            setLoadoutsReceived((prev) => ({
              ...prev,
              items: [
                ...prev.items,
                {
                  id: Number(event.data[0]),
                  count: Number(event.data[1]),
                  details: result,
                },
              ],
            }));
          } else if (event.scoreAddress === config.contract.CROWN_SCORE) {
            setLoadoutsReceived((prev) => ({
              ...prev,
              crown: [
                ...prev.crown,
                {
                  id: 1,
                  count: Number(event.indexed[3]) / 10 ** 18,
                  details: {
                    image: CROWN,
                    name: "Crown",
                    description:
                      "CROWN is more than just the governance token. It will fuel the entire economy, and there will be a lot of blood on the street fighting for it.",
                  },
                },
              ],
            }));
          } else if (
            event.scoreAddress === config.contract.LOOTBOX_SCORE &&
            event.indexed[0] == "ICXTransfer(Address,Address,int)"
          ) {
            setLoadoutsReceived((prev) => ({
              ...prev,
              icx: [
                ...prev.icx,
                {
                  id: 1,
                  count: Number(event.indexed[3]) / 10 ** 18,
                  details: {
                    image: ICX,
                    name: "ICX",
                    description:
                      "ICX is the associated cryptocurrency token or coin.",
                  },
                },
              ],
            }));
          }
        }
        setTransactionLoading((prev) => ({
          ...prev,
          state: "loading",
          status: false,
          message: "",
          navigate: "",
          closeScreen: onHandle ?? onHandle,
        }));
        setLoadoutsReceived((prev) => ({
          ...prev,
          status: true,
        }));
      } else if (method === "addAbilityTokens") {
        const result = await sendSneakMoveAddTx(tx);
        if (result.status === 200) {
          setTransactionLoading((prev) => ({
            ...prev,
            state: "success",
            status: true,
            // message: "Transaction Success",
            navigate: navigate ?? "",
            closeScreen: onHandle ?? onHandle,
          }));
        }
      } else {
        setTransactionLoading((prev) => ({
          ...prev,
          state: "success",
          status: true,
          // message: "Transaction Success",
          navigate: navigate ?? "",
          closeScreen: onHandle ?? onHandle,
        }));
      }
      // setTransactionLoading((prev) => ({
      //   ...prev,
      //   state: "loading",
      //   status: false,
      //   message: "",
      // }));
    } else {
      setTransactionLoading((prev) => ({
        ...prev,
        state: "failed",
        status: true,
        message: "Transaction Failed",
        navigate: "",
        closeScreen: onHandle ?? onHandle,
      }));
    }
  };

  const getTxResultWithTimer = (txHash: string) => {
    setTimeout(() => {
      getTx(txHash);
    }, 6000);
  };

  const eventHandler = async (event: any) => {
    if (event.detail.type === "RESPONSE_JSON-RPC") {
      const hash = { txHash: event.detail.payload.result };
      setTransactionLoading((prev) => ({
        ...prev,
        state: "loading",
        status: true,
      }));
      getTxResultWithTimer(event.detail.payload.result);
    } else if (event.detail.type === "CANCEL_JSON-RPC") {
      setTransactionLoading((prev) => ({
        ...prev,
        state: "canceled",
        status: false,
      }));
    }
  };

  useEffect(() => {
    window.addEventListener("ICONEX_RELAY_RESPONSE", eventHandler, false);
    return () => {
      window.removeEventListener("ICONEX_RELAY_RESPONSE", eventHandler);
    };
  }, []);
  return { getTxResultWithTimer };
};
