export const MessageText = {
  CHANGE_LOADOUT:
    "You will not be able to change your loadout once the lobby is locked",
  JOIN_GANGWAR:
    "You can still change your Weapons and Sneak Moves in the lobby",
  REFILLING_MOVES:
    "Active sneak moves can be refilled as many times as required.",
  OUT_OF_HOSPITALIZATION_FULL:
    "Please wait for a while, your GangstaBet is recovering.",
  OUT_OF_HOSPITALIZATION_HALF:
    "Please wait for a while, your GangstaBet is recovering..",
};

export const ResponseMessage = (text: string) => {
  if (text === MessageText.CHANGE_LOADOUT) {
    return {
      message: "Weapons and Sneakmoves updated",
      buttonName: "Go to lobby",
    };
  } else if (text === MessageText.JOIN_GANGWAR) {
    return {
      message: "GangstaBet equipped.",
      buttonName: "Go to lobby",
    };
  } else if (text === MessageText.JOIN_GANGWAR) {
    return {
      message: "Refilled the sneakmoves",
      buttonName: "okay",
    };
  } else if (text === MessageText.OUT_OF_HOSPITALIZATION_FULL) {
    return {
      message:
        "Your GangstaBet has successfully recovered and is ready for another GangWar.",
      buttonName: "okay",
    };
  } else if (text === MessageText.OUT_OF_HOSPITALIZATION_HALF) {
    return {
      message:
        "Your GangstaBet’s recovery time has been halved, they will be up and running in no time.",
      buttonName: "okay",
    };
  }
};
