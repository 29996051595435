import { createContext, useContext, useEffect, useState } from "react";
import { IUserEquipment } from "@interfaces/equipment.interface";
import { useSelector } from "react-redux";
import { getUserEquipment } from "@utils/getUserEquipment";
import { IAbilityCount } from "@interfaces/ability.interface";
import { IGetUserAbility } from "@interfaces/ability.interface";
import { getUsersAvailableAbilityBalances } from "@utils/getUserAbility";
import { getItems } from "@utils/getUserItem";
import { IItemCount } from "@interfaces/items.interface";
import { IItems } from "@interfaces/items.interface";
import { getUserLootboxDetails } from "@utils/getLootboxDetails";
import { availableCrown, getLockedNft, getICXBalance } from "@services/icon.service";

type TRANSACTION_STATUS = "idle" | "loading" | "failed" | "success" | "canceled";

interface IDetailsContext {
  userCooldownEquipmentDetails: Array<IUserEquipment>;
  userEquipmentDetails: Array<IUserEquipment>;
  setUserEqupimentDetails: React.Dispatch<React.SetStateAction<Array<IUserEquipment>>>;
  userLockedEquipmentDetails: Array<IUserEquipment>;
  userUnreleasedEquipmentDetails: Array<IUserEquipment>;
  getAbilityNumberCount: IAbilityCount;
  setAbilityNumberCount: React.Dispatch<React.SetStateAction<IAbilityCount>>;
  setUserAbilityDetails: React.Dispatch<React.SetStateAction<Array<IGetUserAbility>>>;
  userAbilityDetailsWithSeperation: {
    passive: Array<IGetUserAbility>;
    active: Array<IGetUserAbility>;
  };
  userAbilityDetails: Array<IGetUserAbility>;
  setItemNumberCount: React.Dispatch<React.SetStateAction<IItemCount>>;
  getItemNumberCount: IItemCount;
  userItemsDetails: Array<IItems>;
  setUserItemsDetails: React.Dispatch<React.SetStateAction<Array<IItems>>>;
  userLootboxDetails: any;
  setUserLootboxDetails: any;
  reFetchDetails: () => void;
  transactionLoading: {
    navigate: any;
    state: TRANSACTION_STATUS;
    status: boolean;
    message: string;
    closeScreen?: any;
    title?: any;
  };
  setTransactionLoading: React.Dispatch<
    React.SetStateAction<{
      state: TRANSACTION_STATUS;
      status: boolean;
      message: string;
      navigate: string;
      closeScreen?: any;
      title?: string;
    }>
  >;
  getLockedNFTs: Array<number>;
  userCrown: number;
  userICX: number;
  warIdSelectedFromMap: number;
  setWarIdSelectedFromMap: React.Dispatch<React.SetStateAction<number>>;
  reFetchChangeData: boolean;
  setRefetchData: React.Dispatch<React.SetStateAction<boolean>>;
  showUserLobby: boolean;
  setShowUserLobby: React.Dispatch<React.SetStateAction<boolean>>;
  activeTab: number;
  setActiveTab: React.Dispatch<React.SetStateAction<number>>;
  userItemsDetailsWithSeperation: {
    stats: Array<IItems>;
    nonStats: Array<IItems>;
    unReleased: Array<IItems>;
  };
  loadingFetchUserDetails: boolean;
  isFirebaseSignedIn: boolean;
  setFirebaseSignedIn: React.Dispatch<React.SetStateAction<boolean>>;
  showAsyncStateModal: boolean,
  setShowAsyncStateModal: React.Dispatch<React.SetStateAction<boolean>>;
  getUserCrownAndICXBalance: () => void;
}

const DetailsContext = createContext<IDetailsContext>({
  activeTab: 0,
  setActiveTab: () => { },
  userCooldownEquipmentDetails: [],
  userEquipmentDetails: [],
  userLockedEquipmentDetails: [],
  userUnreleasedEquipmentDetails: [],
  setUserEqupimentDetails: () => { },
  getAbilityNumberCount: { "0": -1 },
  setAbilityNumberCount: () => { },
  userAbilityDetails: [],
  setUserAbilityDetails: () => { },
  getItemNumberCount: { "0": -1 },
  setItemNumberCount: () => { },
  userItemsDetails: [],
  setUserItemsDetails: () => { },
  userLootboxDetails: [],
  setUserLootboxDetails: () => { },
  reFetchDetails: () => { },
  transactionLoading: {
    state: "loading",
    status: false,
    message: "",
    navigate: "",
    closeScreen: () => { },
  },
  setTransactionLoading: () => { },
  getLockedNFTs: [],
  userCrown: 0,
  userICX: 0,
  warIdSelectedFromMap: -1,
  setWarIdSelectedFromMap: () => { },
  reFetchChangeData: false,
  setRefetchData: () => { },
  setShowUserLobby: () => { },
  showUserLobby: false,
  userItemsDetailsWithSeperation: {
    stats: [],
    nonStats: [],
    unReleased: [],
  },
  userAbilityDetailsWithSeperation: {
    passive: [],
    active: [],
  },
  loadingFetchUserDetails: false,
  isFirebaseSignedIn: false,
  setFirebaseSignedIn: () => { },
  showAsyncStateModal: true,
  setShowAsyncStateModal: () => { },
  getUserCrownAndICXBalance: () => { },
});
const DetailsProvider = ({ children }: any) => {
  const [isFirebaseSignedIn, setFirebaseSignedIn] = useState<boolean>(false);
  // equipment
  const [userEquipmentDetails, setUserEqupimentDetails] = useState<Array<IUserEquipment>>([]);
  const [userLockedEquipmentDetails, setUserLockedEquipmentDetails] = useState<
    Array<IUserEquipment>
  >([]);
  // unreleased weapons
  const [userUnreleasedEquipmentDetails, setUserUnreleasedEquipmentDetails] = useState<
    Array<IUserEquipment>
  >([]);

  // cooldown equipments
  const [userCooldownEquipmentDetails, setUserCooldownEquipmentDetails] = useState<
    Array<IUserEquipment>
  >([]);
  // ability
  const [getAbilityNumberCount, setAbilityNumberCount] = useState<IAbilityCount>({});
  const [userAbilityDetails, setUserAbilityDetails] = useState<Array<IGetUserAbility>>([]);

  const [userAbilityDetailsWithSeperation, setUserAbilityDetailsWithSeperation] = useState<{
    passive: Array<IGetUserAbility>;
    active: Array<IGetUserAbility>;
  }>({
    passive: [],
    active: [],
  });

  //items
  const [getItemNumberCount, setItemNumberCount] = useState<IItemCount>({
    "0": -1,
  });

  const [userItemsDetails, setUserItemsDetails] = useState<Array<IItems>>([]);
  const [userItemsDetailsWithSeperation, setUserItemsDetailsWithSeperation] = useState<{
    stats: Array<IItems>;
    nonStats: Array<IItems>;
    unReleased: Array<IItems>;
  }>({
    stats: [],
    nonStats: [],
    unReleased: [],
  });

  const { isWalletConnected, walletAddress } = useSelector((state: any) => state.wallet);

  //loot box
  const [userLootboxDetails, setUserLootboxDetails] = useState([]);

  //get global locked nfts

  const [getLockedNFTs, setLockedNFTs] = useState<Array<number>>([]);

  //get available crown

  const [userCrown, setUserCrown] = useState(0);
  const [userICX, setUserICX] = useState(0);

  const [transactionLoading, setTransactionLoading] = useState<{
    state: TRANSACTION_STATUS;
    status: boolean;
    message: string;
    navigate: string;
    closeScreen?: any;
    title?: string;
  }>({
    state: "idle",
    status: false,
    message: "",
    navigate: "",
    closeScreen: () => { },
    title: "",
  });

  //used to hide/display Async State modal globally
  const [showAsyncStateModal, setShowAsyncStateModal] = useState(true)

  //active tab for the history and warlist
  const [activeTab, setActiveTab] = useState<number>(0);

  //set show lobby
  const [showUserLobby, setShowUserLobby] = useState(false);
  const [reFetchChangeData, setRefetchData] = useState(false);

  const [warIdSelectedFromMap, setWarIdSelectedFromMap] = useState(-1);
  const [loadingFetchUserDetails, setLoadingFetchUserDetails] = useState(false);

  const getUserCrownAndICXBalance = async () => {
    if (isWalletConnected) {
      const [crown, icx] = await Promise.all([availableCrown(walletAddress), getICXBalance(walletAddress)])
      setUserCrown(crown);
      setUserICX(icx);
    }
  }

  const fetchDetails = async () => {
    setLoadingFetchUserDetails(true);
    setLockedNFTs([]);
    if (isWalletConnected) {
      const lockedNft = await getLockedNft();
      setLockedNFTs(lockedNft.map((hexStr: string) => parseInt(hexStr, 16)));

      await Promise.all([
        getUserCrownAndICXBalance(),
        getUserEquipment({
          setUserEqupimentDetails,
          setUserLockedEquipmentDetails,
          setUserUnreleasedEquipmentDetails,
          setUserCooldownEquipmentDetails,
          walletAddress,
        }),
        getUsersAvailableAbilityBalances({
          setAbilityNumberCount,
          setUserAbilityDetails,
          walletAddress,
          setUserAbilityDetailsWithSeperation,
        }),
        getItems({
          walletAddress,
          setItemNumberCount,
          setUserItemsDetails,
          setUserItemsDetailsWithSeperation,
        }),
        getUserLootboxDetails({ walletAddress, setUserLootboxDetails })
      ])

      setWarIdSelectedFromMap(-1);
    } else {
      setUserEqupimentDetails([]);
      setUserLockedEquipmentDetails([]);
      setUserUnreleasedEquipmentDetails([]);
      setUserCooldownEquipmentDetails([]);
      setUserItemsDetails([]);
      setUserLootboxDetails([]);
      setUserAbilityDetails([]);
      setUserItemsDetailsWithSeperation({
        stats: [],
        nonStats: [],
        unReleased: [],
      });
      setUserAbilityDetailsWithSeperation({
        active: [],
        passive: [],
      });
      setUserCrown(0);
      setUserICX(0);
      setWarIdSelectedFromMap(-1);
    }
    setLoadingFetchUserDetails(false);
  };

  const reFetchDetails = () => {
    fetchDetails();
  };

  useEffect(() => {
    fetchDetails();
  }, [isWalletConnected]);

  return (
    <DetailsContext.Provider
      value={{
        userEquipmentDetails,
        setUserEqupimentDetails,
        userLockedEquipmentDetails,
        userUnreleasedEquipmentDetails,
        userCooldownEquipmentDetails,
        getAbilityNumberCount,
        setAbilityNumberCount,
        userAbilityDetails,
        setUserAbilityDetails,
        getItemNumberCount,
        setItemNumberCount,
        userItemsDetails,
        setUserItemsDetails,
        userLootboxDetails,
        setUserLootboxDetails,
        reFetchDetails,
        transactionLoading,
        setTransactionLoading,
        getLockedNFTs,
        userCrown,
        userICX,
        warIdSelectedFromMap,
        setWarIdSelectedFromMap,
        reFetchChangeData,
        setRefetchData,
        setShowUserLobby,
        activeTab,
        setActiveTab,
        showUserLobby,
        userItemsDetailsWithSeperation,
        userAbilityDetailsWithSeperation,
        loadingFetchUserDetails,
        isFirebaseSignedIn,
        setFirebaseSignedIn,
        showAsyncStateModal,
        setShowAsyncStateModal,
        getUserCrownAndICXBalance
      }}
    >
      {children}
    </DetailsContext.Provider>
  );
};

const useDetailGlobalContext = () => {
  return useContext(DetailsContext);
};

export { DetailsProvider, useDetailGlobalContext };
