import Modal from "@components/modal/modal.component";
import { useState } from "react";
import StylizedButton from "@components/buttons/stylized.button";
import { FaUserAlt } from "react-icons/fa";
interface IPlayerLimitReached {
  isVisible: boolean;
  setIsVisible: any;
  maxPlayer: number;
}

const PlayerLimitReachedModal = ({
  isVisible,
  setIsVisible,
  maxPlayer,
}: IPlayerLimitReached) => {
  return (
    <Modal
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      modalTitle="Player limit reached"
      width="600px"
    >
      <div className="playerLimitWrapper">
        <div className="playerNumber">
          <FaUserAlt color="white" />{" "}
          <span className="text-red-700">
            {maxPlayer}/{maxPlayer} players
          </span>
        </div>
        <hr className="horizontal-seperator" />
        <div className="playerReachedText">
          <p className="playerReachedTopText">
            Sorry, you cannot join this war because the player limit has been
            reached.
          </p>
          <p className="playerReachedInfo">
            Please try joining a different war
          </p>
        </div>
        <hr className="horizontal-seperator" />
        <div className="playerReachedButton">
          <StylizedButton
            onClick={() => {
              setIsVisible(false);
            }}
            buttonName="Okay"
          />
        </div>
      </div>
    </Modal>
  );
};

export default PlayerLimitReachedModal;
