import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useWalletConnect } from "@hooks/connectWallet";
import {
  getUsersLootCrateBurntCount,
  usersAvailableLootboxBalances,
} from "@services/icon.service";
import WalletConnected from "@components/modal/wallet/connected.modal";
import { useDetailGlobalContext } from "@context/detailProvider";
import LoadingModal from "@components/modal/loadingModal.modal";
import StarterKit from "@components/modal/starterKit.modal";
import { useTokenInterceptor } from "@hooks/useTokenInterceptor";
import { baseURL } from "@api/api";
import { useNavigate } from "react-router-dom";
import SigningMessage from "@components/modal/wallet/signTransaction.modal";
import { useWarListGlobalContext } from "@context/war";

const Connections = () => {
  const { isWalletConnected, walletAddress, tempWalletAddress } = useSelector(
    (state: any) => state.wallet
  );
  const {
    setMessageSigningModal,
    messageSigningModal,
  } = useWarListGlobalContext();
  const [connectedWalletModal, setConnectedWalletModal] = useState(false);
  // const [messageSigningModal, setMessageSigningModal] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useTokenInterceptor(baseURL, setMessageSigningModal);
  useWalletConnect(setConnectedWalletModal);

  const [openStartKit, setOpenStartKit] = useState(false);

  useEffect(() => {
    async function checkIfUserClaimingForFirstTime() {
      const count = await getUsersLootCrateBurntCount(walletAddress);
      const lootCrates = await usersAvailableLootboxBalances(walletAddress);
      const value = Object.values(count).map((num) => Number(num));
      const maxValue = Math.max(...value);
      if (maxValue <= 0 && lootCrates.length > 0) {
        setOpenStartKit(true);
      }
    }
    if (isWalletConnected) {
      checkIfUserClaimingForFirstTime();
    }
  }, [isWalletConnected]);

  const {
    transactionLoading,
    setTransactionLoading,
    showAsyncStateModal,
  } = useDetailGlobalContext();

  return (
    <>
      {connectedWalletModal && (
        <WalletConnected
          isVisible={connectedWalletModal}
          setIsVisible={setConnectedWalletModal}
          walletAddress={walletAddress}
        />
      )}
      {/* {messageSigningModal && (
        <SigningMessage
          isVisible={messageSigningModal}
          setIsVisible={setMessageSigningModal}
          walletAddress={walletAddress}
        />
      )} */}
      {showAsyncStateModal && (
        <>
          {transactionLoading.state === "loading" &&
            transactionLoading.state && (
              <LoadingModal
                isVisible={transactionLoading.status}
                setIsVisible={() => {}}
                title={transactionLoading.title ?? "loading"}
                message={`${
                  transactionLoading.message !== ""
                    ? transactionLoading.message
                    : "Please wait transaction is being processed..."
                }`}
                state={transactionLoading.state}
              />
            )}
          {transactionLoading.state === "success" && transactionLoading.state && (
            <LoadingModal
              isVisible={transactionLoading.status}
              setIsVisible={() => {
                setTransactionLoading((prev) => ({ ...prev, status: false }));
              }}
              closeScreen={transactionLoading?.closeScreen}
              navigate={transactionLoading.navigate}
              title="Success"
              message={transactionLoading.message}
              state={transactionLoading.state}
            />
          )}
          {transactionLoading.state === "failed" && transactionLoading.state && (
            <LoadingModal
              isVisible={transactionLoading.status}
              setIsVisible={() => {
                setTransactionLoading((prev) => ({ ...prev, status: false }));
              }}
              title="Failed!"
              message="The changes you were trying to make has not been registered"
              state={transactionLoading.state}
            />
          )}
        </>
      )}
      {openStartKit && (
        <StarterKit isVisible={openStartKit} setIsVisible={setOpenStartKit} />
      )}
    </>
  );
};
export default Connections;
