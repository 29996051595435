import { FC, useState } from "react";

import Modal from "@components/modal/modal.component";
import PrimaryButton from "../../buttons/primary.button";
import { CROWN, ICX, ARROW_DOWN_ICON } from "@constants/images";
import { setForRent } from "@services/icon.service";
import { NFT_RENT_TYPE } from "@constants/nft";
import { useDetailGlobalContext } from "@context/detailProvider";
import RentalLoading from "./loading.rental";
import { AsyncState, IRentalModal } from "./rental.interface";
import { useNFTDetailGlobalContext } from "@context/nftInformation";
import RentalWrapper from "./RentalWrapper.component";
import { useNFTInformation } from "@context/nftInfoProvider";
import { useSelector } from "react-redux";
import { RootState } from "@redux/store";
import { useTransactionValidate } from "@hooks/transactionResult";

const LendModal: FC<IRentalModal> = ({
  selectedChar,
  isVisible,
  setRentalModal,
  maxDaysForRent,
  setSelectedChar
}) => {
  const { isLedger } = useSelector((state: RootState) => state.wallet);
  const { getTxResultWithTimer } = useTransactionValidate({ onHandle: () => { } });
  const { reRenderCollectionToggler, setRerenderCollectionToggler, platformFees } = useNFTInformation();
  const [asyncState, setAsyncState] = useState(AsyncState.IDLE);
  const [period, setPeriod] = useState(2);
  const [isOpen, setIsOpen] = useState(false);
  const [rentFee, setRentFee] = useState<null | number>(null);
  const [invalidRate, setInvalidRate] = useState(false);
  const [currency, setCurrency] = useState<keyof typeof NFT_RENT_TYPE>(
    NFT_RENT_TYPE.CROWN_CURRENCY
  );
  const {
    reFetchDetails,
    setRefetchData,
    reFetchChangeData,
  } = useDetailGlobalContext();
  const {
    setNftData,
    setRentalIndex,
    setCurrentBatchIndex,
    setChangeDataToggle, changeDataToggle
  } = useNFTDetailGlobalContext();

  const onAction = async () => {
    if (!rentFee) return;
    try {
      setAsyncState(AsyncState.LOADING);
      const result: any = await setForRent(selectedChar?.gangstaBetDetails?.nftId.toString(), rentFee, currency, period.toString());

      if (isLedger) {
        if (result?.name === "TransportStatusError") {
          console.log("Ledger cancelled!!!");
        } else if (result) {
          getTxResultWithTimer(result as string);
        } else {
          console.log("Error on ledger");
        }
      }
    } catch (e) {
      setAsyncState(AsyncState.IDLE);
    }
  };

  const checkRentValidity = () => {
    if (!rentFee) {
      setInvalidRate(true);
      return;
    }
    setAsyncState(AsyncState.CONFIRM);
  };

  const onContinue = () => {
    setRentalModal(null);
    if (asyncState === AsyncState.SUCCESS) {
      setSelectedChar(null);
      setRerenderCollectionToggler(!reRenderCollectionToggler);
      //refetch all NFT details (rent + inventory)
      setRentalIndex(null);
      setNftData([]);
      setCurrentBatchIndex(0);
      setRefetchData(!reFetchChangeData);
      reFetchDetails();
      setChangeDataToggle(!changeDataToggle);
    }
  };

  const getOption = (day: number) => {
    return (
      <div className="rent-price-dropdown flex gap-1.5">
        {day} days
        {Boolean(rentFee) && (
          <>
            {" "}
            (<img
              src={
                currency === NFT_RENT_TYPE.CROWN_CURRENCY ? CROWN : ICX
              }
              alt="currency"
              className="w-5 h-5"
            />
            {rentFee! * day} Total)
          </>
        )}
      </div>
    )
  }

  const selectOption = (day: number) => {
    setPeriod(day);
    setIsOpen(false);
  };

  return (
    <RentalWrapper
      type="LEND"
      asyncState={asyncState}
      setAsyncState={setAsyncState}
      setRentalModal={setRentalModal}
    >
      <Modal
        isVisible={isVisible}
        onModalClose={onContinue}
        glow={asyncState === AsyncState.SUCCESS ? "success" : ""}
        modalTitle={
          asyncState === AsyncState.CONFIRM
            ? "Confirm rent rate"
            : asyncState === AsyncState.SUCCESS
              ? "GangstaBet Listed"
              : "Enter your rent rate"
        }
        className=" rental-modal-outer-wrapper flex flex-col justify-center items-center"
      >
        <p className="description-text text-center">
          {asyncState === AsyncState.CONFIRM ? (
            <>
              Are you sure you want to list the GangstaBet on <br /> the rental
              market at this rent rate?
              <div className="success-description-text py-4 mt-6 mb-8">
                GangstaBet name: <span>{selectedChar?.gangstaBetDetails?.nftName}</span>
                <br />
                <p className="flex gap-1.5 justify-center items-end">
                  Rent rate:
                  <span className="inline-flex gap-2 items-end">
                    <img
                      src={
                        currency === NFT_RENT_TYPE.CROWN_CURRENCY ? CROWN : ICX
                      }
                      alt="currency"
                      className="w-6 h-6"
                    />
                    {rentFee} per day
                  </span>
                </p>
                {/* Rent Duration: <span>{period} days</span> */}
              </div>
            </>
          ) : asyncState === AsyncState.SUCCESS ? (
            <>
              Your GangstaBet has been listed on the <br /> rental market.
              <div className="success-description-text py-4 mt-6 mb-8">
                GangstaBet name: <span>{selectedChar.gangstaBetDetails?.nftName}</span>
                <br />
                <p className="flex gap-1.5 justify-center items-end">
                  New rent rate:
                  <span className="inline-flex gap-2 items-end">
                    <img
                      src={
                        currency === NFT_RENT_TYPE.CROWN_CURRENCY ? CROWN : ICX
                      }
                      alt="currency"
                      className="w-6 h-6"
                    />
                    {rentFee} per day
                  </span>
                </p>
                {/* Rent Duration: <span>{period} days</span> */}
              </div>
            </>
          ) : (
            <>
              Get your GangstaBet listed on the rent market & <br /> earn some
              extra CROWN.
            </>
          )}
        </p>
        {asyncState === AsyncState.IDLE && (
          <div className="my-6 flex flex-col gap-2">
            <fieldset>
              <div className="flex items-center gap-2">

                <input
                  type="radio"
                  id="crown"
                  name="currency"
                  value={NFT_RENT_TYPE.CROWN_CURRENCY}
                  checked={currency === NFT_RENT_TYPE.CROWN_CURRENCY}
                  onChange={() => setCurrency(NFT_RENT_TYPE.CROWN_CURRENCY)}
                  className="outline-none accent-yellow-400 cursor-pointer" />
                <label htmlFor="crown" className="rental-text cursor-pointer">CROWN</label>

                <input
                  type="radio"
                  id="icx"
                  name="currency"
                  value={NFT_RENT_TYPE.ICX_CURRENCY}
                  checked={currency === NFT_RENT_TYPE.ICX_CURRENCY}
                  onChange={() => setCurrency(NFT_RENT_TYPE.ICX_CURRENCY)}
                  className="outline-none accent-yellow-400 cursor-pointer" />
                <label htmlFor="icx" className="rental-text cursor-pointer">ICX</label>

              </div>
            </fieldset>

            <div className="relative flex gap-3 justify-between items-end px-4 py-2.5 group">
              <div className="absolute input-box top-0 left-0 right-0 bottom-0 w-full h-full group-active:border-[rgba(227,201,110,1)] group-hover:border-[rgba(227,201,110,1)] group-focus:border-[rgba(227,201,110,1)]" />
              <img
                src={currency === NFT_RENT_TYPE.CROWN_CURRENCY ? CROWN : ICX}
                alt="currency"
                className="w-6 h-6 "
              />
              <input
                onChange={(e) => {
                  e.preventDefault();
                  setIsOpen(false)
                  setRentFee(Number(e.target.value));
                }}
                onClick={() => setInvalidRate(false)}
                defaultValue={rentFee ?? ""}
                min={1}
                type="number"
                placeholder="Enter rent rate"
                className={`input-area ${invalidRate
                  ? "placeholder:text-[#F66E6E] !text-[#F66E6E]"
                  : "placeholder:text-[rgba(112,135,143,1)]"
                  } w-[150px]`}
              />
              <p className="input-area-text !text-[rgba(112,135,143,1)]">
                per day
              </p>
            </div>
            <small className="text-sm text-white">Note: {platformFees}% will be deducted as a platform fee.</small>
            {invalidRate && (
              <p className="rental-text !text-[#F66E6E]">
                Please enter a rent rate before you submit.
              </p>
            )}
            {/* <p className="rental-text !text-[#485C65]">
              Enter the duration
            </p>
            <button
              disabled={!rentFee}
              onClick={() => setIsOpen(!isOpen)}
              className=" disabled:opacity-50 disabled:cursor-not-allowed cursor-pointer relative flex gap-3 justify-between items-end outline-none py-2.5 group">
              <div className="absolute input-box top-0 left-0 right-0 bottom-0 w-full h-full group-active:border-[rgba(227,201,110,1)] group-hover:border-[rgba(227,201,110,1)] group-focus:border-[rgba(227,201,110,1)]" />
              <p className="input-area-text ml-4 !text-[rgba(112,135,143,1)]">
                {getOption(period)}
              </p>
              {isOpen && (
                <div className="absolute top-full max-h-32 overflow-y-scroll py-1 w-full flex flex-col items-center bg-black rounded-sm border border-[#485C65] z-50">
                  {Array.from({ length: maxDaysForRent || 0 }, (_, i) => i + 2).map((day) => (
                    <button
                      key={day}
                      onClick={() => selectOption(day)}
                      className="block w-full px-4 py-2 text-sm text-gray-500  hover:text-white"
                    >
                      {getOption(day)}
                    </button>
                  ))}
                </div>
              )}

              <img
                src={ARROW_DOWN_ICON}
                alt="currency"
                className="w-6 h-6"
              />
            </button> */}
          </div>
        )}
        <div className="flex flex-col items-center">
          {asyncState === AsyncState.IDLE && (
            <PrimaryButton
              onClick={checkRentValidity}
              buttonName="Put For Rent"
            />
          )}
          {asyncState === AsyncState.CONFIRM && (
            <PrimaryButton onClick={onAction} buttonName="Yes, Put For Rent" />
          )}
          {asyncState === AsyncState.SUCCESS ? (
            <PrimaryButton onClick={onContinue} buttonName="Okay" />
          ) : (
            <button
              onClick={
                asyncState === AsyncState.CONFIRM
                  ? () => setAsyncState(AsyncState.IDLE)
                  : () => setRentalModal(null)
              }
              className="rent-price-dropdown !text-center !py-[0.5em] outline-none"
            >
              {asyncState === AsyncState.CONFIRM ? "Go Back" : "Not Now"}
            </button>
          )}
        </div>
      </Modal>
    </RentalWrapper>
  );
};

export default LendModal;
