import React, { FC } from 'react';
import ChatMessages from './ChatMessages';
import ChatInput from './ChatInput';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const ChatUI = ({ playersInfo, userStatsDetails }: any) => {
  
  const { id } = useParams()
  
  return (
    <div className='chat-container'>
      <div className='chat-header'>
        <h3 className='big-shoulders text-3xl'>Gangsta Chat</h3>
      </div>
      <div className="chat-area">
        <ChatMessages chatRoomId={id} userStatsDetails={userStatsDetails} playersInfo={playersInfo} />
        <ChatInput chatRoomId={id} userStatsDetails={userStatsDetails} playersInfo={playersInfo} />
      </div>
    </div>
  );
};

export default ChatUI;
