import { useState } from "react";
import PrimaryButton from "@components/buttons/primary.button";
import EquipmentStylizedCard from "@components/card/equipmentStylizedCard.component";
import { RiDeleteBin6Line } from "react-icons/ri";
import PrimaryIconButton from "@components/buttons/primaryButtonwithIconOnly.button";
import "react-tooltip/dist/react-tooltip.css";
import { useCardAnimation } from "@hooks/useCardAnimation";
import { RxStack } from "react-icons/rx";
import { GiGrenade } from "react-icons/gi";
import EquipmentPlainCard from "@components/card/equipmentPlainCard.component";
import { MdClose } from "react-icons/md";
import { Tooltip } from "react-tooltip";
import { emptyStructureForEquipments, emptyStructureForItems } from "@misc/structure";

import { ABILITY, ITEM, WEAPON } from "@constants/images";
import { useDetailGlobalContext } from "@context/detailProvider";
import { BADGE } from "@constants/images";
import ItemTooltip from "@components/tooltips/item.tooltip";
import WeaponTooltip from "@components/tooltips/weapon.tooltip";
import SneakMoves from "@components/tooltips/sneakmove.tooltip";
import { WeaponType } from "@misc/warSpecific";

const AbilityScreen = ({
    animationToggle,
    handleSelectedNewAbility,
    loadoutAbilityDetails,
    abilitiesTypes,
    slot,
    loadoutItemDetails,
    setEquipedLoadout,
    showAbilitiesSlots,
    setShowAbilitiesSlots,
    emptyStructureForAbilities,
    setLoadoutAbilityDetails,
    showAbilities,
    setShowAbilities,
    loadoutEquipmentDetails,
    setEquipmentSlots,
    setLoadoutEquipmentDetails,
    showEquipment,
    setShowEquipment,
    handleSelectedEquipment,
    setShowItemsSlots,
    showItemsSlots,
    showItems,
    setShowItems,
    handleSelectedItems,
    itemTypes,
    setLoadoutItemDetails,
    participantsDetailsItemsId,
    setHasChanged,
    war,
}: any) => {
    console.log({ war });
    useCardAnimation("animateThis", animationToggle);
    const allowedWeapons = war.allowedWeapons.length === 0 ? Object.keys(WeaponType).map(element => Number(element)) : war.allowedWeapons.map((element: string) => Number(element));

    const {
        userEquipmentDetails,
        getAbilityNumberCount,
        setAbilityNumberCount,
        userAbilityDetails,
        getItemNumberCount,
        setItemNumberCount,
        userItemsDetails,
    } = useDetailGlobalContext();

    const removeAbilities = () => {
        setHasChanged(true);
        setAbilityNumberCount((prev: any) => ({
            ...prev,
            [loadoutAbilityDetails.id]: getAbilityNumberCount[loadoutAbilityDetails.id] + 1,
        }));
        setEquipedLoadout((prev: any) => ({
            ...prev,
            ability: { ...prev.ability, [loadoutAbilityDetails.abilityIndex]: null },
        }));
        setShowAbilitiesSlots(() => {
            const result = [...showAbilitiesSlots];
            result[
                abilitiesTypes.indexOf(loadoutAbilityDetails.abilityIndex)
            ] = emptyStructureForAbilities;
            return result;
        });
        setLoadoutAbilityDetails({
            ...emptyStructureForAbilities,
            abilityIndex: loadoutAbilityDetails.abilityIndex,
        });
    };

    const removeItems = () => {
        setHasChanged(true);
        setItemNumberCount((prev: any) => ({
            ...prev,
            [loadoutItemDetails.id]: getItemNumberCount[loadoutItemDetails.id] + 1,
        }));
        setEquipedLoadout((prev: any) => ({
            ...prev,
            items: { ...prev.items, [loadoutItemDetails.itemIndex]: null },
        }));
        setShowItemsSlots(() => {
            const result = [...showItemsSlots];
            result[itemTypes.indexOf(loadoutItemDetails.itemIndex)] = emptyStructureForItems;
            return result;
        });
        setLoadoutItemDetails({
            ...emptyStructureForItems,
            itemIndex: loadoutItemDetails.itemIndex,
        });
    };

    const removeEquipment = () => {
        setEquipedLoadout((prev: any) => ({ ...prev, equipment: null }));
        setEquipmentSlots([emptyStructureForEquipments]);
        setLoadoutEquipmentDetails(emptyStructureForEquipments);
    };

    const [hoveredIndex, setHoveredIndex] = useState(-1);
    const handleItemHover = (index: number) => {
        setHoveredIndex(index);
    };

    return (
        <>
            {loadoutAbilityDetails &&
                slot === "ability" &&
                loadoutAbilityDetails.id > 0 &&
                showAbilities.state === "" && (
                    <div
                        className="flex flex-col  justify-start text-white text-left h-full animation"
                        id="animateThis"
                    >
                        <div className="flex items-center gap-4 ">
                            <EquipmentStylizedCard
                                equipmentImages={loadoutAbilityDetails.image}
                                className="setColor"
                            />

                            <div className="flex flex-col items-start justify-center">
                                <h1 className="text-md font-proxima">
                                    {loadoutAbilityDetails.name}
                                </h1>

                                <div className="warContinueBtnDiv my-2">
                                    <button className="contineWarBtn">
                                        {loadoutAbilityDetails.type}
                                    </button>
                                </div>
                                <span className="text-primary">Sneak Move Slots</span>
                                <div className="flex items-center mt-2 gap-2">
                                    {abilitiesTypes.map((item: any, index: any) => (
                                        <span
                                            className={
                                                loadoutAbilityDetails.abilityIndex ===
                                                    abilitiesTypes[index]
                                                    ? " border-[#e3c96e] text-[#e3c96e] border  p-0.5 px-1 text-xs "
                                                    : "border-slate-600 text-slate-600 border  p-0.5 px-1 text-xs "
                                            }
                                        >
                                            E
                                        </span>
                                    ))}
                                </div>
                            </div>
                        </div>

                        <div className="py-2 my-3 border-y-[1px] border-slate-600 flex justify-center">
                            <PrimaryButton
                                height="40px"
                                buttonName={"switch ability"}
                                className=""
                                onClick={() => {
                                    setShowAbilities({ status: true, state: "switch" });
                                }}
                            />
                            <PrimaryIconButton
                                icon={<RiDeleteBin6Line />}
                                className="text-white"
                                onClick={() => {
                                    removeAbilities();
                                }}
                            />
                        </div>
                        <div className="section">
                            <div className="text-primary-bold">Description</div>
                            <ul className="list-disc text-sm mt-2">
                                <p>{loadoutAbilityDetails.description}</p>
                            </ul>
                        </div>
                    </div>
                )}

            {slot === "weapon" &&
                loadoutEquipmentDetails &&
                !showEquipment.status &&
                loadoutEquipmentDetails.equipmentId > 0 && (
                    <div className="flex flex-col animation" id="animateThis">
                        <div className="flex items-center gap-4 ">
                            <EquipmentStylizedCard
                                equipmentImages={loadoutEquipmentDetails.image}
                                className="setColor"
                            />

                            <div className="flex flex-col items-start justify-center text-white">
                                <h1 className="text-lg font-proxima">
                                    {loadoutEquipmentDetails.name}
                                </h1>
                                <div className="flex items-center text-sm">
                                    <GiGrenade className="h-8 mr-2" />
                                    {loadoutEquipmentDetails.type}
                                </div>
                                <div className="flex items-center text-sm">
                                    <RxStack className=" mr-2" />
                                    {/* {loadoutEquipmentDetails.type}
                                     */}
                                    Basic
                                </div>
                            </div>
                        </div>

                        <div className="py-2 my-3 border-y-[1px] border-slate-600 flex justify-center">
                            <PrimaryButton
                                height="40px"
                                buttonName={"switch Equipment"}
                                className=""
                                onClick={() => {
                                    setShowEquipment({ status: true, state: "switch" });
                                }}
                            />
                            <PrimaryIconButton
                                icon={<RiDeleteBin6Line />}
                                className="text-white"
                                onClick={() => {
                                    removeEquipment();
                                }}
                            />
                        </div>

                        <div className="py-3 border-y-[0.5px] border-slate-500 border-gray text-white mt-4">
                            <span>{loadoutEquipmentDetails?.description}</span>
                        </div>
                        <div className="grid grid-cols-2 text-left py-4 gap-4 gap-x-0">
                            <span>
                                <div className="title text-md text-gray-400">Damage</div>
                                <div className="title text-lg text-white">
                                    {loadoutEquipmentDetails.stats.damage}(
                                    {loadoutEquipmentDetails.stats.numberofHits} hits)
                                </div>
                            </span>
                            <span>
                                <div className="title text-md text-gray-400">
                                    {loadoutEquipmentDetails?.type === "Melee"
                                        ? "Base Durability"
                                        : "Base Ammo"}
                                </div>
                                <div className="title text-lg text-white">
                                    {loadoutEquipmentDetails.stats.duraAmmo}
                                    <span className="text-sm">
                                        {" "}
                                        (uses {loadoutEquipmentDetails.stats.consumptionRate} per
                                        hit)
                                    </span>
                                </div>
                            </span>
                            <span>
                                <div className="title text-md text-gray-400">Hit Chance</div>
                                <div className="title text-lg text-white">
                                    {(loadoutEquipmentDetails.stats.hitChance * 100).toFixed(0)}%
                                </div>
                            </span>
                            <span>
                                <div className="title text-md text-gray-400">
                                    {" "}
                                    Critical Hit Chance
                                </div>
                                <div className="title text-lg text-white">
                                    +
                                    {(loadoutEquipmentDetails.stats.criticalChance * 100).toFixed(
                                        0
                                    )}
                                    %
                                </div>
                            </span>
                        </div>
                    </div>
                )}
            {/* item details */}
            {loadoutItemDetails &&
                slot === "item" &&
                loadoutItemDetails.id > 0 &&
                !showItems.status && (
                    <div className="flex flex-col animation" id="animateThis">
                        <div className="flex items-center gap-4 ">
                            <EquipmentStylizedCard
                                equipmentImages={loadoutItemDetails.image}
                                className="setColor"
                            />

                            <div className="flex flex-col items-start justify-center text-white">
                                <h1 className="text-lg font-proxima">{loadoutItemDetails.name}</h1>
                                <div className="flex items-center text-sm">
                                    {/* <GiGrenade className="h-8 mr-2" /> */}
                                    {loadoutItemDetails.type}
                                </div>
                                {loadoutItemDetails.rank && (
                                    <div className="flex items-center text-sm mt-2">
                                        <img src={BADGE} alt="badge" className=" mr-2" />
                                        {loadoutItemDetails.rank}
                                    </div>
                                )}
                            </div>
                        </div>
                        {loadoutItemDetails.statBoost && (
                            <div className="button capitalize bg-[#141b20] px-12 py-3 dark text-2xl text-dark font-bold my-4 text-center">
                                {Object.keys(loadoutItemDetails.statBoost)[0]}{" "}
                                <span className="text-green-500 ml-3">
                                    {Object.values(loadoutItemDetails.statBoost)[0] as string}
                                </span>
                            </div>
                        )}
                        {!participantsDetailsItemsId.includes(loadoutItemDetails.id) && (
                            <div className="py-2 my-3 border-y-[1px] border-slate-600 flex justify-center">
                                <PrimaryButton
                                    height="40px"
                                    buttonName={"switch Items"}
                                    className=""
                                    onClick={() => {
                                        setShowItems({ status: true, state: "switch" });
                                    }}
                                />
                                <PrimaryIconButton
                                    icon={<RiDeleteBin6Line />}
                                    className="text-white"
                                    onClick={() => {
                                        removeItems();
                                    }}
                                />
                            </div>
                        )}

                        <div className="text-description py-3 border-y-[0.5px] border-slate-700 text-white mt-4 text-base">
                            <span>{loadoutItemDetails.description}</span>
                        </div>
                    </div>
                )}

            {loadoutItemDetails &&
                slot === "item" &&
                loadoutItemDetails?.id < 0 &&
                !showItems.status && (
                    <div className="flex flex-col  justify-center px-4 text-white text-center h-full">
                        <img src={ITEM} alt="ability" className="w-48 mx-auto my-4 abilityImage" />
                        <div className="text text-slate-800 font-bold uppercase text-2xl">
                            ITEM SLOTS EMPTY
                        </div>
                        <div
                            className={`py-2 my-6 border-y-[1px] border-slate-800 flex justify-center ${userItemsDetails.length <=
                                0 && "disable"}`}
                        >
                            <PrimaryButton
                                height="40px"
                                buttonName={"equip Item"}
                                onClick={() => {
                                    setShowItems({ status: true, state: "equip" });
                                }}
                            />
                        </div>
                    </div>
                )}

            {loadoutAbilityDetails &&
                slot === "ability" &&
                !showAbilities.status &&
                loadoutAbilityDetails.id < 0 && (
                    <div className="flex flex-col  justify-center px-4 text-white text-center h-full">
                        <img
                            src={ABILITY}
                            alt="ability"
                            className="w-48 mx-auto my-4 abilityImage"
                        />
                        <div className="text text-slate-800 font-bold uppercase text-2xl">
                            SNEAK MOVE SLOTS EMPTY
                        </div>
                        <div
                            className={`py-2 my-6 border-y-[1px] border-slate-800 flex justify-center ${loadoutAbilityDetails.length <=
                                0 && "disable"}`}
                        >
                            <PrimaryButton
                                height="40px"
                                buttonName={"equip sneak move"}
                                onClick={() => {
                                    setShowAbilities({ status: true, state: "equip" });
                                }}
                            />
                        </div>
                    </div>
                )}

            {loadoutEquipmentDetails &&
                loadoutEquipmentDetails.equipmentId < 0 &&
                !showEquipment.status &&
                slot === "weapon" && (
                    <div className="flex flex-col  justify-center px-4 text-white text-center h-full">
                        <img
                            src={WEAPON}
                            alt="ability"
                            className="w-48 mx-auto my-4 abilityImage"
                        />
                        <div className="text text-slate-800 font-bold uppercase text-2xl">
                            EQUIPMENT SLOTS EMPTY
                        </div>
                        {userEquipmentDetails.length > 0 && (
                            <div
                                className={`py-2 my-6 border-y-[1px] border-slate-800 flex justify-center ${userEquipmentDetails.length <=
                                    0 && "disable"}`}
                            >
                                <PrimaryButton
                                    height="40px"
                                    buttonName={"equip eQUIPMENT"}
                                    onClick={() => {
                                        setShowEquipment({ status: true, state: "equip" });
                                    }}
                                />
                            </div>
                        )}
                    </div>
                )}

            {/* items select */}
            {showItems.status &&
                slot === "item" &&
                (showItems.state === "equip" || showItems.state === "switch") && (
                    <>
                        <div className="flex items-center justify-between w-full text-2xl animation">
                            <div className="text-yellow font-bold uppercase">
                                {userItemsDetails.length > 0 && "select an Item"}
                            </div>
                            <MdClose
                                className="text-white font-bold"
                                onClick={() => {
                                    setShowItems({ status: false, state: "" });
                                }}
                            />
                        </div>
                        <div className="flex items-center flex-wrap gap-3 my-4">
                            {userItemsDetails.length > 0 ? (
                                userItemsDetails.map(
                                    (item: any, index: number) =>
                                        getItemNumberCount[item.id] > 0 && (
                                            <>
                                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                                <a
                                                    id={`tooltip-anchor-${index}`}
                                                    className="text-white cursor-pointer"
                                                    onClick={() => {
                                                        handleSelectedItems(item);
                                                    }}
                                                    onMouseEnter={() => handleItemHover(index)}
                                                    onMouseLeave={() => handleItemHover(-1)}
                                                >
                                                    <EquipmentPlainCard
                                                        height="50px"
                                                        width="56px"
                                                        equipmentImages={item.image}
                                                        equipmentNumber={
                                                            getItemNumberCount[item.id]
                                                        }
                                                    />
                                                </a>
                                                <ItemTooltip
                                                    details={item}
                                                    tooltipId={`tooltip-anchor-${index}`}
                                                    place={"left"}
                                                />
                                                {/* <Tooltip
                        anchorSelect={`#tooltip-anchor-${index}`}
                        place="left"
                        style={{
                          backgroundColor: "#192228",
                          border: "1px solid #485c65",
                          padding: "20px",
                          zIndex: 99999,
                          maxWidth: "370px",
                          opacity: 1,
                        }}
                      >
                        <div className="flex items-center gap-4">
                          <EquipmentStylizedCard
                            equipmentImages={item.image}
                            className="setColor"
                          />

                          <div className="flex flex-col items-start justify-center">
                            <h1 className="text-md font-proxima">
                              {item.name}
                            </h1>

                            <div className="warContinueBtnDiv my-2">
                              <button className="contineWarBtn">
                                {item.type}
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="section mt-4">
                          <div className="text-primary-bold">Description</div>
                          <ul className="list-disc text-sm mt-2">
                            {item.description}
                          </ul>
                        </div>
                      </Tooltip> */}
                                            </>
                                        )
                                )
                            ) : (
                                <div className="flex flex-col  justify-center px-4 text-white text-center h-full">
                                    <img
                                        src={WEAPON}
                                        alt="ability"
                                        className="w-48 mx-auto my-4 abilityImage"
                                    />
                                    <div className="text text-slate-800 font-bold uppercase text-2xl">
                                        EMPTY ITEM
                                    </div>
                                </div>
                            )}
                        </div>
                    </>
                )}

            {showAbilities.status &&
                slot === "ability" &&
                (showAbilities.state === "equip" || showAbilities.state === "switch") && (
                    <>
                        <div className="flex items-center justify-between w-full text-2xl animation">
                            <div className="text-yellow font-bold uppercase">
                                select a sneakmove
                            </div>
                            <MdClose
                                className="text-white font-bold"
                                onClick={() => {
                                    setShowAbilities({ status: false, state: "" });
                                }}
                            />
                        </div>
                        <div className="flex items-center flex-wrap gap-3 my-4">
                            {userAbilityDetails.map(
                                (ability: any, index: number) =>
                                    getAbilityNumberCount[ability.id] > 0 && (
                                        <>
                                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                            <a
                                                id={`tooltip-anchor-${index}`}
                                                className="text-white cursor-pointer"
                                                onClick={() => {
                                                    handleSelectedNewAbility(ability);
                                                }}
                                                onMouseEnter={() => handleItemHover(index)}
                                                onMouseLeave={() => handleItemHover(-1)}
                                            >
                                                <EquipmentPlainCard
                                                    height="50px"
                                                    width="56px"
                                                    equipmentImages={ability.image}
                                                    equipmentNumber={
                                                        getAbilityNumberCount[ability.id]
                                                    }
                                                />
                                            </a>
                                            <SneakMoves
                                                tooltipId={`tooltip-anchor-${index}`}
                                                details={ability}
                                                place="left"
                                            />
                                            {/* <Tooltip
                        anchorSelect={`#tooltip-anchor-${index}`}
                        place="left"
                        style={{
                          backgroundColor: "#192228",
                          border: "1px solid #485c65",
                          padding: "20px",
                          zIndex: 99999,
                          maxWidth: "370px",
                          opacity: 1,
                        }}
                      >
                        <div className="flex items-center gap-4">
                          <EquipmentStylizedCard
                            equipmentImages={ability.image}
                            className="setColor"
                          />

                          <div className="flex flex-col items-start justify-center">
                            <h1 className="text-md font-proxima">
                              {ability.name}
                            </h1>

                            <div className="warContinueBtnDiv my-2">
                              <button className="contineWarBtn">
                                {ability.type} Ability
                              </button>
                            </div>
                            <span className="text-primary">
                              Sneak Move Slots
                            </span>
                            <div className="flex items-center mt-2 gap-2">
                              {abilitiesTypes.map((item: any, index: any) => (
                                <span
                                  className={
                                    loadoutAbilityDetails.abilityIndex ===
                                    abilitiesTypes[index]
                                      ? " border-[#e3c96e] text-[#e3c96e] border  p-0.5 px-1 text-xs "
                                      : "border-slate-600 text-slate-600 border  p-0.5 px-1 text-xs "
                                  }
                                >
                                  E
                                </span>
                              ))}
                            </div>
                          </div>
                        </div>
                        <div className="section mt-4">
                          <div className="text-primary-bold">Description</div>
                          <ul className="list-disc text-sm mt-2">
                            {ability.description}
                          </ul>
                        </div>
                      </Tooltip> */}
                                        </>
                                    )
                            )}
                        </div>
                    </>
                )}
            {showEquipment.status &&
                slot === "weapon" &&
                (showEquipment.state === "equip" || showEquipment.state === "switch") && (
                    <>
                        <div className="flex items-center  justify-between w-full text-2xl animation">
                            <div className="text-yellow font-bold uppercase">
                                select an equipment
                            </div>
                            <MdClose
                                className="text-white font-bold"
                                onClick={() => {
                                    setShowEquipment({ status: false, state: "" });
                                }}
                            />
                        </div>
                        <div className="flex items-center flex-wrap gap-3 my-4">
                            {userEquipmentDetails.map((item: any, index: number) => (
                                <>
                                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                    <a
                                        id={`tooltip-anchor-${index}`}
                                        className={`text-white ${!allowedWeapons.includes(item.equipmentId)
                                                ? "cursor-not-allowed"
                                                : "cursor-pointer"
                                            } `}
                                        onClick={() => {
                                            if (allowedWeapons.includes(item.equipmentId)) {
                                                handleSelectedEquipment(item);
                                            }
                                        }}
                                        onMouseEnter={() => handleItemHover(index)}
                                        onMouseLeave={() => handleItemHover(-1)}
                                    >
                                        <EquipmentPlainCard
                                            height="50px"
                                            width="56px"
                                            className={`${!allowedWeapons.includes(
                                                item.equipmentId
                                            ) && "disable opacity-40 cursor-not-allowed"}`}
                                            equipmentImages={item.image}
                                        />
                                    </a>
                                    <WeaponTooltip
                                        tooltipId={`tooltip-anchor-${index}`}
                                        details={item}
                                        place="left"
                                    />
                                    {/* <Tooltip
                    anchorSelect={`#tooltip-anchor-${index}`}
                    place="left"
                    style={{
                      backgroundColor: "#192228",
                      border: "1px solid #485c65",
                      padding: "20px",
                      zIndex: 99999,
                      maxWidth: "370px",
                    }}
                  >
                    <div className="flex items-center gap-4">
                      <EquipmentStylizedCard
                        equipmentImages={item.image}
                        className="setColor"
                      />

                      <div className="flex flex-col items-start justify-center">
                        <h1 className="text-md font-proxima">{item?.name}</h1>

                        <div className="warContinueBtnDiv my-2">
                          <button className="contineWarBtn">
                            {item?.type}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="section mt-4">
                      <div className="text-primary-bold">Description</div>
                      <ul className="list-disc text-sm mt-2">
                        {item?.description}
                      </ul>
                    </div>
                  </Tooltip> */}
                                </>
                            ))}
                        </div>
                    </>
                )}
        </>
    );
};

export default AbilityScreen;
