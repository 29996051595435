import React, { useEffect, useState } from "react";
import {
    BADGE,
    CRAFT,
    CRATE,
    CRATE2,
    CROPPEDGRENADE,
    ITEM,
    LOADING,
    OPENING_CRATE,
    STARTER_OPENING,
    PREMIUM_OPENING,
    PISTOL,
    SHIELD,
} from "@constants/images";
import InventoryBody from "@pages/inventory/InventoryBody";
import PrimaryButton from "@components/buttons/primary.button";
import EquipmentPlainCard from "@components/card/equipmentPlainCard.component";
import AnimationScreen from "./AnimationScreen";
import { useCardAnimation } from "@hooks/useCardAnimation";
import ColoredCrateCard from "@components/card/coloredCrateCard";
import { useDetailGlobalContext } from "@context/detailProvider";
import LoadingModal from "@components/modal/loadingModal.modal";
import { useTransactionValidate } from "@hooks/transactionResult";
import { burnLootbox } from "@services/icon.service";
import { useSelector } from "react-redux";
import NFTCard from "@components/card/nftCard.component";
import LootClaimAnimation from "./lootClaimAnimation";
import { HYPERLINKS } from "@misc/links";
import { ColorDetailsTypes, colorDetails } from "@type/lootboxColor.type";
import {
    PLAY_CLOSE_SOUND,
    PLAY_NORMAL_CLICK,
    PLAY_INVENTORY_CLICK,
    PLAY_CLAIM_SOUND,
} from "@constants/audio";
import LootcratePlainCard from "@components/card/lootcratePlainCard";

const LootCrates = () => {
    interface ILoadoutReceived {
        status: boolean;
        equipment: Array<{ id: number; count: number; details: any }>;
        abilities: Array<{ id: number; count: number; details: any }>;
        items: Array<{ id: number; count: number; details: any }>;
        crown: Array<{ id: number; count: number; details: any }>;
        icx: Array<{ id: number; count: number; details: any }>;
    }
    const [loadoutsReceived, setLoadoutsReceived] = useState<ILoadoutReceived>({
        status: false,
        equipment: [],
        abilities: [],
        items: [],
        crown: [],
        icx: [],
    });
    const { getTxResultWithTimer } = useTransactionValidate({
        method: "burn",
        setLoadoutsReceived: setLoadoutsReceived,
    });

    const [active, setActive] = useState<boolean>(false);
    const [openCrate, setOpenCrate] = useState<boolean>(false);

    useCardAnimation("animateThis", active);
    const {
        userLootboxDetails,
        setTransactionLoading,
        loadingFetchUserDetails,
    } = useDetailGlobalContext();

    const [lootboxDetails, setLootboxDetails] = useState<any>();

    const { walletAddress, isLedger, ledgerPath, ledgerTransport } = useSelector(
        (state: any) => state.wallet
    );

    const burnLootboxs = async (id: string) => {
        if (walletAddress) {
            setTransactionLoading((prev) => ({
                ...prev,
                state: "loading",
                status: true,
                message: "Please wait while lootcrate is being unlocked",
                title: "Unlocking",
            }));
            const res: any = await burnLootbox(
                walletAddress,
                id,
                isLedger,
                ledgerPath,
                ledgerTransport
            );
            if (isLedger) {
                if (res?.name === "TransportStatusError") {
                    console.log("Ledger cancelled!!!");
                } else if (res) {
                    getTxResultWithTimer(res as string);
                } else {
                    console.log("Error on ledger");
                }
            }
        }
    };

    function sumItems(lootBox: any): Partial<ILoadoutReceived> {
        const result: any = {};

        ["abilities", "items", "equipment", "crown", "icx"].forEach((key) => {
            const idCounts: Record<string, { count: number; details?: any }> = {};

            lootBox[key].forEach((item: any) => {
                const { id, count, details } = item;
                idCounts[id] = {
                    count: (idCounts[id]?.count || 0) + count,
                    details,
                };
            });

            result[key] = Object.entries(idCounts).map(([id, { count, details }]) => ({
                id,
                count,
                details,
            }));
        });

        return result;
    }

    const [imgSrc, setImageSrc] = useState("");
    useEffect(() => {
        const randomInt = Math.floor(Math.random() * 1000000) + 1;
        const id = lootboxDetails?.id;

        const gifUrl = `${id === 2 ? PREMIUM_OPENING : STARTER_OPENING}?v=${randomInt}`;
        setImageSrc(gifUrl);
    }, [openCrate]);

    console.log("loadingFetchUserDetails", userLootboxDetails, loadingFetchUserDetails);

    return (
        <>
            <InventoryBody>
                {userLootboxDetails.length > 0 && !loadingFetchUserDetails ? (
                    <>
                        <div className="col-span-7 lootcrateOptions">
                            <div className="flex gap-8 items-start flex-wrap">
                                {userLootboxDetails.map((item: any) => (
                                    <>
                                        <div className="col-span-1 max-w-[170px] max-h-[201px]">
                                            <div
                                                className={
                                                    " border inventoryBoxHover p-6 cursor-pointer relative h-[201px] " +
                                                    (lootboxDetails?.id === item.id
                                                        ? "activeOption"
                                                        : "apply-border")
                                                }
                                                onClick={() => {
                                                    PLAY_INVENTORY_CLICK();
                                                    setActive(!active);
                                                    setLootboxDetails(item);
                                                }}
                                            >
                                                {/* <ColoredCrateCard
                                                    image={item.image}
                                                    bgColor={
                                                        colorDetails[
                                                            item.id.toString()
                                                        ] as ColorDetailsTypes
                                                    }
                                                    number={item.count}
                                                    className
                                                /> */}
                                                <LootcratePlainCard
                                                    image={item.image}
                                                    number={item.count}
                                                    key={`key-${item.count}`}
                                                    crateWidth="118px"
                                                    crateHeight="113px"
                                                    width="100%"
                                                    height="100%"
                                                    className="cursor-pointer"
                                                    bgColor={
                                                        colorDetails[
                                                        item.id.toString()
                                                        ] as ColorDetailsTypes
                                                    }
                                                />
                                                <div className=" text-center truncate  text-white my-3 ">
                                                    {item.name}
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ))}
                            </div>
                        </div>
                        {lootboxDetails && lootboxDetails.count > 0 ? (
                            <div className="col-span-3 flex flex-col justify-between details overflow-hidden">
                                <div className="flex flex-col animation" id="animateThis">
                                    <div className="mx-auto text-center w-[200px]">
                                        {/* <ColoredCrateCard
                                            image={lootboxDetails.image}
                                            bgColor={
                                                colorDetails[
                                                    lootboxDetails.id.toString()
                                                ] as ColorDetailsTypes
                                            }
                                            number={lootboxDetails.count}
                                            className="h-[145px] w-[200px]"
                                        /> */}
                                        <LootcratePlainCard
                                            image={lootboxDetails.image}
                                            number={lootboxDetails.count}
                                            crateWidth="160px"
                                            crateHeight="160px"
                                            width="100%"
                                            height="100%"
                                            className="cursor-pointer"
                                            bgColor={
                                                colorDetails[
                                                lootboxDetails.id.toString()
                                                ] as ColorDetailsTypes
                                            }
                                        />
                                        <div className="text-xl text-white my-2 font-semibold">
                                            {lootboxDetails.name}
                                        </div>
                                        <PrimaryButton
                                            buttonName="OPEN CRATE"
                                            onClick={() => {
                                                PLAY_INVENTORY_CLICK();
                                                setOpenCrate(!openCrate);
                                                burnLootboxs(lootboxDetails.id.toString());
                                            }}
                                        />{" "}
                                    </div>

                                    <div className="text-left p-4 dark border-y border-gray-600 mt-4">
                                        {lootboxDetails.description}
                                    </div>

                                    {/* <div className="">
                    <div className="text-center dark text-3xl my-4">
                      Drop Chances
                    </div>
                    <div className="grid grid-cols-4 gap-4 mx-5">
                      {lootboxDetails.lootboxChances.map((item: string) => (
                        <EquipmentPlainCard
                          height="60px"
                          width="60px"
                          equipmentImages={item}
                          className={"setGrey inactive-image"}
                        />
                      ))}
                    </div>
                  </div> */}

                                    <div
                                        className="viewInCraft mt-4"
                                        onClick={() => {
                                            PLAY_INVENTORY_CLICK();
                                            window.open(
                                                "https://craft.network/collection/gangstaverse-lootcrates",
                                                "_blank"
                                            );
                                        }}
                                    >
                                        <img src={CRAFT} alt="craft" />
                                        <button className="">View in Craft</button>
                                    </div>
                                </div>
                                <div className="moreInfoText">
                                    <hr className="seperatorInventory" />
                                    <p className=" mt-5">For More details on Loot Crate</p>
                                    <a href={HYPERLINKS.LOOTCTATE} target="_blank" rel="noopener">
                                        Click here to read the documentation.
                                    </a>
                                </div>
                            </div>
                        ) : (
                            !lootboxDetails && (
                                <div className="col-span-3 flex items-center justify-center details overflow-hidden">
                                    <p className="text-4xl text-center unSelectedTextState opacity-20">
                                        SELECT A CRATE TO SEE DETAILS
                                    </p>
                                </div>
                            )
                        )}
                    </>
                ) : loadingFetchUserDetails ? (
                    <div className="col-span-10 ">
                        <div className="flex flex-col gap-2 w-full h-full items-center justify-center">
                            <img width={220} src={LOADING} alt="loading circle" />
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="col-span-10 ">
                            <div className="flex flex-col gap-2 w-full h-full items-center justify-center">
                                <div className="flex flex-col gap-2 w-full h-full items-center justify-center">
                                    <NFTCard
                                        cardType="nftCardImageCollection"
                                        nftImage={CRATE2}
                                        height={"180px"}
                                        width={"145px"}
                                        className="inactive-image2"
                                    />
                                    <p className="text-3xl big-shoulders uppercase">
                                        No loot crates found
                                    </p>
                                    <p className="text-gray max-w-[500px] text-center">
                                        It looks like your LootCrates Inventory is currently empty.
                                        Explore Craft marketplace to purchase new LootCrates.
                                    </p>
                                    <PrimaryButton
                                        onClick={() => {
                                            PLAY_INVENTORY_CLICK();
                                            window.open(
                                                "https://craft.network/collection/gangstaverse-lootcrates",
                                                "_blank"
                                            );
                                        }}
                                        buttonName="Buy Loot Crates"
                                    />
                                </div>
                                <div className="moreInfoTextEmpty w-full overflow-hidden">
                                    <hr className="seperatorInventory" />
                                    <div className="flex flex-col gap-2">
                                        <p className=" mt-5 text-center">
                                            For More details on Loot Crate
                                        </p>
                                        <a
                                            href={HYPERLINKS.LOOTCTATE}
                                            target="_blank"
                                            className="text-center"
                                        >
                                            Click here to read the documentation.
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </InventoryBody>
            {/* {openCrate && (
        <LoadingModal isVisible={openCrate} setIsVisible={setOpenCrate} />
      )} */}
            {loadoutsReceived.status && (
                // <AnimationScreen
                //   loadoutsReceived={sumItems(loadoutsReceived)}
                //   setOpenCrate={() =>
                //     setLoadoutsReceived((prev) => ({ ...prev, status: false }))
                //   }
                //   setLootboxDetails={setLootboxDetails}
                // />
                <LootClaimAnimation
                    loadoutsReceived={sumItems(loadoutsReceived)}
                    setOpenCrate={() => setLoadoutsReceived((prev) => ({ ...prev, status: false }))}
                    setLootboxDetails={setLootboxDetails}
                    imgSrc={imgSrc}
                />
            )}
            {/* {loot && (
        <AnimationScreen
          loadoutsReceived={{
            status: false,
            equipment: [{ id: 1, count: 2 }],
            abilities: [
              { id: 1, count: 1 },
              { id: 2, count: 1 },
              { id: 3, count: 1 },
            ],
            items: [
              { id: 1, count: 1 },
              { id: 2, count: 1 },
              { id: 3, count: 1 },
            ],
          }}
          setOpenCrate={setLoot}
        />
      )} */}
        </>
    );
};

export default LootCrates;
