import { IUserEquipment } from "@interfaces/equipment.interface";
import { ABILITY, ITEM, WEAPON } from "@constants/images";
import NFTCard from "@components/card/nftCard.component";
import { useState, useEffect } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import SelectionScreen from "@components/screen/selectionScreen.component";
import GameTime from "@components/screen/gameTime.component";
import EquipmentPlainCard from "@components/card/equipmentPlainCard.component";
import { PISTOL, RANGE } from "@constants/images";
import SwitchWeapon from "../switchWeapon/SwitchWeapon.modal";
import CommonModal from "@components/modal/commonModal.component";
import { useCardAnimation } from "@hooks/useCardAnimation";
import { IGetUserAbility } from "@interfaces/ability.interface";
import { abilityIndexType } from "@type/button.types";
import LoadoutDetails from "@components/chooseCharacter/LoadoutDetails.component";
import { statNameMapping, titleStatNameMapping } from "@misc/statNameMap";
import PayEntryFee from "@components/modal/payEntryFee.component";
import { excludeStatsKeys } from "@misc/removeStatsKeys";

import {
    IItems,
    IEquipBtnActive,
    ILoadoutButton,
    IEquipedLoadout,
} from "@interfaces/items.interface";
import { itemIndexType, slotType } from "@type/button.types";
import { handleSwitchConfirm } from "@utils/loadout";
import { joinGangwar, joinGangwarViaICX, joinGangwarWithCrown } from "@services/icon.service";
import { ICharacterEquipment } from "@interfaces/character.interface";
import { useTransactionValidate } from "@hooks/transactionResult";
import { useDetailGlobalContext } from "@context/detailProvider";
import { Tooltip } from "react-tooltip";
import { useDispatch, useSelector } from "react-redux";
import { useWarListGlobalContext } from "@context/war";
import { MessageText } from "@misc/message/messageText";
import { HYPERLINKS } from "@misc/links";
import { WeaponType, WeaponTypeCount } from "@misc/warSpecific";
import toasterService from "@components/toast/ToasterService.component";

const CharacterEquipment = ({
    isScreenClose,
    chooseEquipedScreenClose,
    selectedCharacterInfo,
    // userEquipmentDetails,
    // userItemsDetails,
    // getItemNumberCount,
    // setItemNumberCount,
    // userAbilityDetails,
    // getAbilityNumberCount,
    // setAbilityNumberCount,
    gangwarId,
    walletAddress,
    war,
    setScreenClose,
    warFee,
    setShowLobbyDetails,
    paymentMethod,
}: ICharacterEquipment) => {
    const allowedWeapons = war.allowedWeapons.length === 0 ? Object.keys(WeaponType).map(element => Number(element)) : war.allowedWeapons.map((element: string) => Number(element));
    const {
        userEquipmentDetails,
        getAbilityNumberCount,
        setAbilityNumberCount,
        userAbilityDetails,
        getItemNumberCount,
        setItemNumberCount,
        userItemsDetails,
    } = useDetailGlobalContext();
    // const [showLobbyDetails, setShowLobbyDetails] = useState<boolean>(false);
    // console.log("userAbilityDetails", userAbilityDetails);
    const { isLedger, ledgerPath, ledgerTransport } = useSelector((state: any) => state.wallet);
    const dispatch = useDispatch();
    const { setTeamSeparation } = useWarListGlobalContext();
    const closeScreen = () => {
        setTeamSeparation({ teamA: [], teamB: [] });
        setShowLobbyDetails(true);
        setScreenClose(false);
        chooseEquipedScreenClose(false);
    };

    const { getTxResultWithTimer } = useTransactionValidate({
        onHandle: closeScreen,
    });
    const [animationToggle, setAnimationToggle] = useState<boolean>(false);
    const [slot, setSlot] = useState<slotType>("weapon");
    const [active, setActive] = useState<boolean>(false);
    const [confirmLoadout, setConfirmLoadout] = useState<boolean>(false);
    const [itemIndex, setItemIndex] = useState<itemIndexType>("firstItem");
    const [abilityIndex, setAbilityIndex] = useState<abilityIndexType>("firstAbility");

    const { setTransactionLoading } = useDetailGlobalContext();

    const [loadoutEquipmentDetails, setLoadoutEquipmentDetails] = useState<IUserEquipment | null>(
        null
    );

    const [loadoutItemDetails, setLoadoutItemDetails] = useState<IItems | null>(null);

    const [doubleClickLoadout, setDoubleClickLoadout] = useState({
        slot: "",
        id: -1,
    });

    const [loadoutAbilityDetails, setLoadoutAbilityDetails] = useState<IGetUserAbility | null>(
        null
    );
    const [replaceLoadout, setReplaceloadout] = useState<boolean>(false);

    const [isEquipBtnActive, setEquipeBtnActive] = useState<IEquipBtnActive>({
        equipment: { status: false, id: -1 },
        item: {
            firstItem: {
                status: false,
                itemIndex: "firstItem",
                id: -1,
            },
            secondItem: { status: false, itemIndex: "secondItem", id: -1 },
            thirdItem: { status: false, itemIndex: "thirdItem", id: -1 },
        },
        ability: {
            firstAbility: {
                status: false,
                abilityIndex: "firstAbility",
                id: -1,
            },
            secondAbility: { status: false, abilityIndex: "secondAbility", id: -1 },
            thirdAbility: { status: false, abilityIndex: "thirdAbility", id: -1 },
        },
    });
    const [buttonName, setButtonName] = useState<ILoadoutButton>({
        equipment: "equip",
        ability: {
            firstAbility: "equip",
            secondAbility: "equip",
            thirdAbility: "equip",
        },
        item: {
            firstItem: "equip",
            secondItem: "equip",
            thirdItem: "equip",
        },
    });

    const [equipedLoadout, setEquipedLoadout] = useState<IEquipedLoadout>({
        equipment: null,
        items: {
            firstItem: null,
            secondItem: null,
            thirdItem: null,
        },
        ability: {
            firstAbility: null,
            secondAbility: null,
            thirdAbility: null,
        },
    });

    const [userEquipedLoadout, setUserEquipedLoadout] = useState<
        Array<IUserEquipment | IGetUserAbility | IItems>
    >([]);

    const abilityIndexes: Array<abilityIndexType> = [
        "firstAbility",
        "secondAbility",
        "thirdAbility",
    ];
    const itemsIndexes: Array<itemIndexType> = ["firstItem", "secondItem", "thirdItem"];

    useEffect(() => {
        if (slot === "weapon" && loadoutEquipmentDetails) {
            if (
                isEquipBtnActive.equipment.status &&
                isEquipBtnActive.equipment.id === loadoutEquipmentDetails.equipmentId
            ) {
                setButtonName((prev: ILoadoutButton) => ({
                    ...prev,
                    equipment: "unequip",
                }));
            } else if (
                isEquipBtnActive.equipment.status &&
                isEquipBtnActive.equipment.id !== loadoutEquipmentDetails.equipmentId
            ) {
                setButtonName((prev: ILoadoutButton) => ({
                    ...prev,
                    equipment: "switch",
                }));
            } else {
                setButtonName((prev: ILoadoutButton) => ({
                    ...prev,
                    equipment: "equip",
                }));
            }
        }
        if (slot === "item" && loadoutItemDetails) {
            if (
                isEquipBtnActive.item[itemIndex].status &&
                isEquipBtnActive.item[itemIndex].id === loadoutItemDetails.id &&
                isEquipBtnActive.item[itemIndex].itemIndex === itemIndex
            ) {
                setButtonName((prev: ILoadoutButton) => ({
                    ...prev,
                    item: { ...prev.item, [itemIndex]: "unequip" },
                }));
            } else if (
                isEquipBtnActive.item[itemIndex].status &&
                isEquipBtnActive.item[itemIndex].id !== loadoutItemDetails.id &&
                isEquipBtnActive.item[itemIndex].itemIndex === itemIndex
            ) {
                setButtonName((prev: ILoadoutButton) => ({
                    ...prev,
                    item: { ...prev.item, [itemIndex]: "switch" },
                }));
            } else {
                setButtonName((prev: ILoadoutButton) => ({
                    ...prev,
                    item: { ...prev.item, [itemIndex]: "equip" },
                }));
            }
        }
        if (slot === "ability" && loadoutAbilityDetails) {
            if (
                isEquipBtnActive.ability[abilityIndex].status &&
                isEquipBtnActive.ability[abilityIndex].id === loadoutAbilityDetails.id &&
                isEquipBtnActive.ability[abilityIndex].abilityIndex === abilityIndex
            ) {
                setButtonName((prev: ILoadoutButton) => ({
                    ...prev,
                    ability: { ...prev.ability, [abilityIndex]: "unequip" },
                }));
            } else if (
                isEquipBtnActive.ability[abilityIndex].status &&
                isEquipBtnActive.ability[abilityIndex].id !== loadoutAbilityDetails.id &&
                isEquipBtnActive.ability[abilityIndex].abilityIndex === abilityIndex
            ) {
                setButtonName((prev: ILoadoutButton) => ({
                    ...prev,
                    ability: { ...prev.ability, [abilityIndex]: "switch" },
                }));
            } else {
                setButtonName((prev: ILoadoutButton) => ({
                    ...prev,
                    ability: { ...prev.ability, [abilityIndex]: "equip" },
                }));
            }
        }
    }, [
        loadoutEquipmentDetails,
        loadoutItemDetails,
        isEquipBtnActive,
        itemIndex,
        loadoutAbilityDetails,
        abilityIndex,
    ]);

    const [showPayCrownConfirm, setShowPayCrownConfirm] = useState({
        status: false,
        method: "",
    });

    const handleButton = () => {
        const equipment = buttonName?.equipment;
        const item = buttonName?.item[itemIndex];
        const ability = buttonName?.ability[abilityIndex];
        if (slot === "weapon" && loadoutEquipmentDetails) {
            if (equipment === "equip") {
                setEquipedLoadout((prev: IEquipedLoadout) => ({
                    ...prev,
                    equipment: loadoutEquipmentDetails,
                }));
            } else if (equipment === "unequip") {
                setEquipedLoadout((prev: IEquipedLoadout) => ({
                    ...prev,
                    equipment: null,
                }));
            } else if (equipment === "switch") {
                setReplaceloadout(true);
            }
            if (equipment === "equip" || equipment === "unequip") {
                setEquipeBtnActive((prev: IEquipBtnActive) => ({
                    ...prev,
                    equipment: {
                        status: !isEquipBtnActive.equipment.status,
                        id: loadoutEquipmentDetails.equipmentId,
                    },
                }));
            }
        }
        if (slot === "item" && loadoutItemDetails) {
            if (item === "equip") {
                setEquipedLoadout((prev: IEquipedLoadout) => ({
                    ...prev,
                    items: {
                        ...prev.items,
                        [itemIndex]: loadoutItemDetails,
                    },
                }));
                const getIndexOf = itemsIndexes.indexOf(itemIndex);
                if (itemsIndexes.length - 1 > getIndexOf)
                    setItemIndex(itemsIndexes[getIndexOf + 1]);
                const itemCount = getItemNumberCount[loadoutItemDetails.id] - 1;
                setItemNumberCount((prev: any) => ({
                    ...prev,
                    [loadoutItemDetails.id]: itemCount,
                }));
                if (itemCount <= 0) setLoadoutItemDetails(null);

                if (loadoutItemDetails.statBoost) {
                    const skillsStat = document.getElementById(
                        `${Object.keys(loadoutItemDetails.statBoost)[0]}`
                    );
                    if (skillsStat) {
                        const span = document.createElement("span");
                        const statText = document.createTextNode(
                            `${Object.values(loadoutItemDetails.statBoost)[0]}`
                        );
                        span.setAttribute("id", loadoutItemDetails.id.toString());

                        span.appendChild(statText);
                        skillsStat.appendChild(span);
                    }
                }
            } else if (item === "unequip") {
                setItemNumberCount((prev: any) => ({
                    ...prev,
                    [loadoutItemDetails.id]: getItemNumberCount[loadoutItemDetails.id] + 1,
                }));

                const removeStat = document.getElementById(loadoutItemDetails.id.toString());
                if (removeStat) {
                    removeStat.remove();
                }
                setEquipedLoadout((prev: IEquipedLoadout) => ({
                    ...prev,
                    items: {
                        ...prev.items,
                        [itemIndex]: null,
                    },
                }));
            } else if (item === "switch") {
                setReplaceloadout(true);
            }
            if (item === "equip" || item === "unequip") {
                setEquipeBtnActive((prev: IEquipBtnActive) => ({
                    ...prev,
                    item: {
                        ...prev.item,
                        [itemIndex]: {
                            status: !isEquipBtnActive.item[itemIndex].status,
                            id: loadoutItemDetails.id,
                            itemIndex: itemIndex,
                        },
                    },
                }));
            }
        }
        if (slot === "ability" && loadoutAbilityDetails) {
            if (ability === "equip") {
                setEquipedLoadout((prev: any) => ({
                    ...prev,
                    ability: {
                        ...prev.ability,
                        [abilityIndex]: loadoutAbilityDetails,
                    },
                }));
                const getIndexOf = abilityIndexes.indexOf(abilityIndex);
                const sneakMoveCount = getAbilityNumberCount[loadoutAbilityDetails.id] - 1;
                if (abilityIndexes.length - 1 > getIndexOf)
                    setAbilityIndex(abilityIndexes[getIndexOf + 1]);
                setAbilityNumberCount((prev: any) => ({
                    ...prev,
                    [loadoutAbilityDetails.id]: sneakMoveCount,
                }));
                if (sneakMoveCount <= 0) setLoadoutAbilityDetails(null);
            } else if (ability === "unequip") {
                setAbilityNumberCount((prev: any) => ({
                    ...prev,
                    [loadoutAbilityDetails.id]: getAbilityNumberCount[loadoutAbilityDetails.id] + 1,
                }));

                setEquipedLoadout((prev: IEquipedLoadout) => ({
                    ...prev,
                    ability: {
                        ...prev.ability,
                        [abilityIndex]: null,
                    },
                }));
            } else if (ability === "switch") {
                setReplaceloadout(true);
            }
            if (ability === "equip" || ability === "unequip") {
                setEquipeBtnActive((prev: IEquipBtnActive) => ({
                    ...prev,
                    ability: {
                        ...prev.ability,
                        [abilityIndex]: {
                            status: !isEquipBtnActive.ability[abilityIndex].status,
                            id: loadoutAbilityDetails.id,
                            abilityIndex: abilityIndex,
                        },
                    },
                }));
            }
        }
    };

    const switchLoadout = () => {
        handleSwitchConfirm({
            slot: slot,
            setEquipedLoadout,
            loadoutEquipmentDetails: loadoutEquipmentDetails as IUserEquipment,
            setEquipeBtnActive,
            equipedLoadout,
            loadoutItemDetails: loadoutItemDetails as IItems,
            itemIndex,
            setItemNumberCount,
            getItemNumberCount,
            getAbilityNumberCount,
            abilityIndex,
            setAbilityNumberCount,
            loadoutAbilityDetails,
        });
    };

    const continueGame = () => {
        setUserEquipedLoadout([]);
        if (equipedLoadout.equipment) {
            setUserEquipedLoadout((prev) => [...prev, equipedLoadout.equipment as IUserEquipment]);
        }

        itemsIndexes.map((itm: string) => {
            if (equipedLoadout.items[itm]) {
                setUserEquipedLoadout((prev) => [...prev, equipedLoadout.items[itm] as IItems]);
            }
        });
        abilityIndexes.map((itm: string) => {
            if (equipedLoadout.ability[itm]) {
                setUserEquipedLoadout((prev) => [
                    ...prev,
                    equipedLoadout.ability[itm] as IGetUserAbility,
                ]);
            }
        });
    };
    const confirmJoinGame = async () => {
        setConfirmLoadout(false);
        const processItemGangwar: any = {};
        const processAbilityGangwar: any = {};
        itemsIndexes.map((item) => {
            if (equipedLoadout.items[item]) {
                if ((equipedLoadout.items[item] as IItems).id in processItemGangwar) {
                    processItemGangwar[(equipedLoadout.items[item] as IItems).id] =
                        processItemGangwar[(equipedLoadout.items[item] as IItems).id] + 1;
                } else {
                    processItemGangwar[(equipedLoadout.items[item] as IItems).id] = 1;
                }
            }
        });
        abilityIndexes.map((item) => {
            if (equipedLoadout.ability[item]) {
                if ((equipedLoadout.ability[item] as IGetUserAbility).id in processAbilityGangwar) {
                    processAbilityGangwar[(equipedLoadout.ability[item] as IGetUserAbility).id] =
                        processAbilityGangwar[
                        (equipedLoadout.ability[item] as IGetUserAbility).id
                        ] + 1;
                } else {
                    processAbilityGangwar[(equipedLoadout.ability[item] as IGetUserAbility).id] = 1;
                }
            }
        });
        const address = walletAddress;
        const simulationId = gangwarId.toString();
        const nftId = selectedCharacterInfo.gangstaBetDetails.nftId.toString();
        const equipmentId = equipedLoadout.equipment
            ? [equipedLoadout.equipment.equipmentId.toString()]
            : [];
        const equipmentValue = equipedLoadout.equipment ? ["1"] : [];
        const itemIds = Object.keys(processItemGangwar).map((item) => item.toString());
        const itemValues = Object.values(processItemGangwar).map((item: any) => item.toString());
        const abilityIds = Object.keys(processAbilityGangwar).map((item: any) => item.toString());
        const abilityValues = Object.values(processAbilityGangwar).map((item: any) =>
            item.toString()
        );

        setTransactionLoading((prev) => ({
            ...prev,
            state: "loading",
            status: true,
            message: MessageText.JOIN_GANGWAR,
            title: "Saving your Loadout",
        }));
        if (warFee > 0 && paymentMethod === "CROWN") {
            const res: any = await joinGangwarWithCrown(
                address,
                simulationId,
                nftId,
                equipmentId,
                equipmentValue,
                itemIds,
                itemValues,
                abilityIds,
                abilityValues,
                warFee,
                isLedger,
                ledgerPath,
                ledgerTransport
            );
            if (isLedger) {
                if (res?.name === "TransportStatusError") {
                    console.log("Ledger cancelled!!!");
                } else if (res) {
                    getTxResultWithTimer(res as string);
                } else {
                    console.log("Error on ledger");
                }
            }
        } else if (warFee === 0) {
            const res: any = await joinGangwar(
                address,
                simulationId,
                nftId,
                equipmentId,
                equipmentValue,
                itemIds,
                itemValues,
                abilityIds,
                abilityValues,
                isLedger,
                ledgerPath,
                ledgerTransport
            );
            if (isLedger) {
                if (res?.name === "TransportStatusError") {
                    console.log("Ledger cancelled!!!");
                } else if (res) {
                    getTxResultWithTimer(res as string);
                } else {
                    console.log("Error on ledger");
                }
            }
        } else if (warFee > 0 && paymentMethod === "ICX") {
            const res: any = await joinGangwarViaICX(
                address,
                simulationId,
                nftId,
                equipmentId,
                equipmentValue,
                itemIds,
                itemValues,
                abilityIds,
                abilityValues,
                warFee,
                isLedger,
                ledgerPath,
                ledgerTransport
            );
            if (isLedger) {
                if (res?.name === "TransportStatusError") {
                    console.log("Ledger cancelled!!!");
                } else if (res) {
                    getTxResultWithTimer(res as string);
                } else {
                    console.log("Error on ledger");
                }
            }
        }
    };

    const joinGameGangwar = async () => {
        if (warFee === 0) {
            confirmJoinGame();
        } else if (warFee > 0) {
            setConfirmLoadout(false);
            setShowPayCrownConfirm({ status: true, method: paymentMethod });
        }
    };

    const [participantsDetails, setParticipatedDetails] = useState<any>({
        abilities: [],
        equipments: [],
        items: [],
    });
    const setParticipatedLoadouts = (key: string, amount: string, availableInfo: any) => {
        for (let i = 0; i < Number(amount); i++) {
            setParticipatedDetails((prev: any) => ({
                ...prev,
                [key]: [...prev[key], availableInfo],
            }));
        }
    };

    // const loadoutDetails = async () => {
    //   const detailsHead = ["abilities", "equipments", "items"];
    //   setParticipatedDetails({
    //     abilities: [],
    //     equipments: [],
    //     items: [],
    //   });
    //   const details = await getUserParticipantDetail(
    //     Number(war.id).toString(),
    //     Number(nftId).toString()
    //   );
    //   for await (let key of detailsHead) {
    //     for await (let item of details[key]) {
    //       if (key === detailsHead[0]) {
    //         const availableInfo = await getAbilitiesInformation(item.id);
    //         setParticipatedLoadouts(key, item.amount, availableInfo);
    //       } else if (key === detailsHead[1]) {
    //         const availableInfo = await getEquipmentInformation(item.id);

    //         setParticipatedLoadouts(key, item.amount, availableInfo);
    //       } else if (key === detailsHead[2]) {
    //         const availableInfo = await getItemsInformation(item.id);

    //         setParticipatedLoadouts(key, item.amount, availableInfo);
    //       }
    //     }
    //   }
    // };
    useCardAnimation("rightSection", animationToggle);
    return (
        <>
            <SelectionScreen
                isScreenClose={isScreenClose}
                setScreenClose={chooseEquipedScreenClose}
                titleText="Equip your Gangster"
            >
                <div className="equipItemLeftPart">
                    <div className="nftCharacterDetails">
                        {Object.keys(selectedCharacterInfo).length > 0 ? (
                            <>
                                <div className="flex flex-col">
                                    <div className=" nftInfo ">
                                        <div className="nftImage">
                                            <NFTCard
                                                cardType="nftCardImageChooseCharacter"
                                                width="160px"
                                                height="215px"
                                                nftImage={selectedCharacterInfo.gangstaBetDetails.nftImage}
                                                hospitalized={{
                                                    cardSize: "NFTOnIllLg",
                                                    hospitalizedTimer: selectedCharacterInfo.hospitalization.warCooldown,

                                                }}

                                                rental={
                                                    {
                                                        cardSize: "NFTOnRentLg",
                                                        rentalType: selectedCharacterInfo.rental.status,
                                                        rentalTimer: selectedCharacterInfo.rental.expirationTimestamp,
                                                        rentalName: selectedCharacterInfo.rental.rentalName,
                                                    }
                                                }
                                                locked={
                                                    selectedCharacterInfo.lockStatus && {
                                                        cardSize: "NFTOnIllLg",
                                                    }
                                                }

                                            />
                                        </div>
                                        <div className="nftDetails p-2">
                                            <div className="nftNameClassType">
                                                <h2
                                                    className="gbName cursor-pointer"
                                                    onClick={() => {
                                                        window.open(
                                                            `${HYPERLINKS.GANGSTABET_PROFILE}/${selectedCharacterInfo?.gangstaBetDetails.nftId}`
                                                        );
                                                    }}
                                                >
                                                    {selectedCharacterInfo?.gangstaBetDetails.nftName}
                                                </h2>
                                                <div className="gbClassType">
                                                    <p className="gbType">
                                                        {selectedCharacterInfo?.gangstaBetDetails.classType}
                                                    </p>
                                                    <div className="flex items-center gap-1">
                                                        <div className="w-3 h-3  bg-red-600 rounded-full"></div>
                                                        <p className="gbClass">
                                                            {selectedCharacterInfo?.gangstaBetDetails.characterType}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="gbLevel">
                                                <p className="gbLevelName">
                                                    {selectedCharacterInfo?.gangstaBetDetails.levelType}
                                                </p>
                                                <p className="gbLevelNow text-gray flex gap-2">
                                                    <div className="w-3 h-3 mt-1 bg-gray-200 rounded-full"></div>
                                                    Level {selectedCharacterInfo?.gangstaBetDetails.levelNumber}
                                                </p>
                                                <div className="flex item-center gap-1">
                                                    <div className="w-3 h-3 mt-1 bg-gray-700 rounded-full"></div>
                                                    <p className="text-gray">
                                                        Id {selectedCharacterInfo?.gangstaBetDetails.nftId}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="nftWeapons">
                                                <div className="weapons">
                                                    <EquipmentPlainCard
                                                        height="54px"
                                                        width="54px"
                                                        equipmentImages={
                                                            selectedCharacterInfo?.defaultWeapons.image
                                                        }
                                                        className="setColor"
                                                    />
                                                    <div className="weaponsInfo w-content">
                                                        <p className="weaponName whitespace-nowrap">
                                                            {
                                                                selectedCharacterInfo?.defaultWeapons
                                                                    .weaponName
                                                            }
                                                        </p>

                                                        <p className="isDefault whitespace-nowrap">
                                                            {
                                                                selectedCharacterInfo?.defaultWeapons
                                                                    .weaponType
                                                            }
                                                        </p>
                                                    </div>
                                                    <div className="ranged">
                                                        <img src={RANGE} alt="ranged" />
                                                        <p className="rangedText">
                                                            {selectedCharacterInfo?.defaultWeapons.Ranged}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="nftSkills">
                                        <p className="nftSkillTitle">GangWar Stats</p>

                                        <div className="nftActualSkills cursor-pointer">
                                            <div className="skillsLeft">
                                                {Object.keys(
                                                    selectedCharacterInfo?.skills.primary
                                                ).map((keys, index) => {
                                                    return (
                                                        <div
                                                            key={`${index}_skill`}
                                                            className="skillInfo"
                                                        >
                                                            <p className="skillName capitalize">
                                                                {keys}
                                                            </p>
                                                            <p className="skillNumber">
                                                                {selectedCharacterInfo?.skills.primary[
                                                                    keys
                                                                ].toFixed(0)}
                                                                <span
                                                                    id={keys}
                                                                    className="upgradeStats"
                                                                ></span>
                                                            </p>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                            <div className="skillsRight">
                                                {Object.keys(
                                                    selectedCharacterInfo?.skills?.secondary
                                                ).map((keys: any, index) => {
                                                    if (excludeStatsKeys.includes(keys))
                                                        return null;
                                                    return (
                                                        <div
                                                            key={`${index}_SecSkill`}
                                                            className="skillInfo"
                                                        >
                                                            <p
                                                                className="skillName"
                                                                title={`${titleStatNameMapping[keys]}`}
                                                            >
                                                                {statNameMapping?.[keys]}
                                                            </p>
                                                            <p className="skillNumber">
                                                                {statNameMapping?.[keys]?.split(" ")
                                                                    .length > 1
                                                                    ? `${keys.toLowerCase() !==
                                                                        "dodgechance"
                                                                        ? "+"
                                                                        : ""
                                                                    }${Number(
                                                                        selectedCharacterInfo
                                                                            ?.skills.secondary[
                                                                        keys
                                                                        ] * 100
                                                                    ).toFixed(0)}%`
                                                                    : selectedCharacterInfo?.skills.secondary[
                                                                        keys
                                                                    ].toFixed(0)}
                                                            </p>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <p className="nftNotSelected">SELECT AN NFT TO SEE DETAILS</p>
                        )}
                    </div>
                </div>
                <Tooltip
                    style={{ zIndex: 999, maxWidth: "300px", opacity: 1 }}
                    anchorSelect="#weapon"
                >
                    <p className="text-sm text-center">
                        Choose a primary weapon to equip your GangstaBet for the upcoming war.{" "}
                        <br />
                        <i className="text-slate-500">
                            Click on the slot to view all the equipment you possess. You can select
                            only one weapon.
                        </i>
                    </p>
                </Tooltip>
                <Tooltip
                    style={{ zIndex: 999, opacity: 1, maxWidth: "300px" }}
                    anchorSelect="#items"
                >
                    <p className="text-sm text-center">
                        Choose the Items you wish to start the GangWar with. These will be
                        automatically used. <br />
                        <i className="text-slate-500">
                            Click on one of the slots to view all the items you possess. You can
                            select a total of 3 items.
                        </i>
                    </p>
                </Tooltip>
                <Tooltip
                    style={{ zIndex: 999, opacity: 1, maxWidth: "300px" }}
                    anchorSelect="#sneekMoves"
                >
                    <p className="text-sm text-center">
                        Choose the Sneak Moves you want to start the GangWar with. <br />
                        <i className="text-slate-500">
                            Click on one of the slots to view all the Sneak Moves. You can only use
                            3 Sneak Moves in a single GangWar. Passive sneak moves will be used
                            automatically
                        </i>
                    </p>
                </Tooltip>

                <div className="equipItemMiddlePart">
                    <h1>LOADOUT</h1>
                    <div className="flex flex-col py-6 px-6 text-center">
                        <div className="weaponSlot flex flex-col items-center w-full">
                            <div className="flex items-center gap-2 mb-2">
                                <div className="text-primary">Weapon Slot</div>
                                <AiOutlineInfoCircle
                                    className="cursor-pointer information"
                                    id="weapon"
                                    color="white"
                                />
                            </div>

                            <EquipmentPlainCard
                                height="60px"
                                width="68px"
                                equipmentImages={
                                    equipedLoadout?.equipment &&
                                        Object.keys(equipedLoadout.equipment).length > 0
                                        ? equipedLoadout.equipment.image
                                        : WEAPON
                                }
                                className={`${slot === "weapon" || equipedLoadout?.equipment
                                    ? "setColor"
                                    : "setGray inactive-image"
                                    }`}
                                handleClick={() => setSlot("weapon")}
                            />
                        </div>
                        <div className="ItemSlot flex flex-col items-center w-full my-6">
                            <div className="flex items-center gap-2 mb-2">
                                <div className="text-primary">Item Slot</div>
                                <AiOutlineInfoCircle
                                    id="items"
                                    className="cursor-pointer information"
                                    color="white"
                                />
                            </div>
                            <div className="flex justify-center items-center gap-4 flex-wrap">
                                {itemsIndexes.map((item: string, index: number) => (
                                    <EquipmentPlainCard
                                        height="60px"
                                        width="64px"
                                        equipmentImages={
                                            equipedLoadout.items?.[item] &&
                                                Object.keys(equipedLoadout.items?.[item] as IItems)
                                                    .length > 0
                                                ? equipedLoadout.items?.[item]?.image
                                                : ITEM
                                        }
                                        handleClick={() => {
                                            setSlot("item");
                                            setItemIndex(item as itemIndexType);

                                            if (equipedLoadout.items?.[item]) {
                                                setLoadoutItemDetails(equipedLoadout.items?.[item]);
                                                setAnimationToggle(!animationToggle);
                                            } else {
                                                setLoadoutItemDetails(null);
                                            }
                                        }}
                                        className={
                                            (slot === "item" && itemIndex === item) ||
                                                equipedLoadout.items?.[item]?.image
                                                ? "setColor"
                                                : "setGrey inactive-image"
                                        }
                                    />
                                ))}
                            </div>
                        </div>
                        <div className="ItemSlot flex flex-col items-center w-full">
                            <div className="flex items-center gap-2 mb-2">
                                <div className="text-primary">Sneak Move Slot</div>
                                <AiOutlineInfoCircle
                                    id="sneekMoves"
                                    className="cursor-pointer information"
                                    color="white"
                                />
                            </div>
                            <div className="flex justify-center items-center gap-4 flex-wrap">
                                {abilityIndexes.map((ability: string, index: number) => (
                                    <EquipmentPlainCard
                                        height="60px"
                                        width="64px"
                                        equipmentImages={
                                            equipedLoadout.ability?.[ability] &&
                                                Object.keys(
                                                    equipedLoadout.ability?.[ability] as IGetUserAbility
                                                ).length > 0
                                                ? equipedLoadout.ability?.[ability]?.image
                                                : ABILITY
                                        }
                                        className={
                                            (slot === "ability" && abilityIndex === ability) ||
                                                equipedLoadout.ability?.[ability]?.image
                                                ? "setColor"
                                                : "setGrey inactive-image"
                                        }
                                        handleClick={() => {
                                            setSlot("ability");
                                            setAbilityIndex(ability as abilityIndexType);
                                            if (equipedLoadout.ability?.[ability]) {
                                                setLoadoutAbilityDetails(
                                                    equipedLoadout.ability?.[ability]
                                                );
                                                setAnimationToggle(!animationToggle);
                                            } else {
                                                setLoadoutAbilityDetails(null);
                                            }
                                        }}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    style={{ position: "relative", overflow: "hidden" }}
                    className="equipItemRightPart "
                >
                    <div className="flex h-full border-b border-b-[#e3c96e]">
                        <div className="items w-5/12 left-part overflow-y-auto max-h-[510px]">
                            <div className=" grid grid-cols-3 gap-2 gap-y-5 my-6 items-center ml-2">
                                {slot === "weapon" && (
                                    <>
                                        {userEquipmentDetails.length > 0 ? (
                                            userEquipmentDetails.map((item: any, index: number) => (
                                                <EquipmentPlainCard
                                                    height="50px"
                                                    width="56px"
                                                    equipmentImages={item.image}
                                                    className={`${slot === "weapon" &&
                                                        (loadoutEquipmentDetails as IUserEquipment)
                                                            ?.equipmentId === item.equipmentId
                                                        ? " setColor "
                                                        : " setGrey "
                                                        } ${!allowedWeapons.includes(
                                                            item.equipmentId
                                                        ) && "disable opacity-40"}`}
                                                    active={
                                                        slot === "weapon" &&
                                                        equipedLoadout?.equipment?.equipmentId ===
                                                        item.equipmentId
                                                    }
                                                    handleClick={() => {
                                                        if (
                                                            (loadoutEquipmentDetails as IUserEquipment)
                                                                ?.equipmentId !== item.equipmentId
                                                        ) {
                                                            setActive(true);
                                                            setLoadoutEquipmentDetails(item);
                                                            setAnimationToggle(!animationToggle);
                                                        }
                                                    }}
                                                    doubleClick={() => {
                                                        if (
                                                            allowedWeapons.includes(
                                                                item.equipmentId
                                                            )
                                                        ) {
                                                            handleButton();
                                                        }
                                                    }}
                                                    key={`${index}_equipment`}
                                                />
                                            ))
                                        ) : (
                                            <div className="emptyloadout flex items-center w-[222px] justify-center min-h-[400px]">
                                                <p className="text-center">
                                                    No equipment available
                                                </p>
                                            </div>
                                        )}
                                    </>
                                )}
                                {slot === "item" && (
                                    <>
                                        {userItemsDetails.length > 0 ? (
                                            userItemsDetails.map((item: any, index: number) => {
                                                return (
                                                    getItemNumberCount[item.id] > 0 && (
                                                        <EquipmentPlainCard
                                                            height="50px"
                                                            width="56px"
                                                            equipmentImages={item?.image}
                                                            className={
                                                                slot === "item" &&
                                                                    (loadoutItemDetails as IItems)
                                                                        ?.id === item.id
                                                                    ? "setColor "
                                                                    : "setGrey "
                                                            }
                                                            doubleClick={() => {
                                                                handleButton();
                                                            }}
                                                            id={`itemId_${item.id}`}
                                                            equipmentNumber={
                                                                getItemNumberCount[item.id]
                                                            }
                                                            handleClick={() => {
                                                                if (
                                                                    (loadoutItemDetails as IItems)
                                                                        ?.id !== item.id
                                                                ) {
                                                                    setLoadoutItemDetails(item);
                                                                    setAnimationToggle(
                                                                        !animationToggle
                                                                    );
                                                                }
                                                            }}
                                                            key={`${index}_${item.name}`}
                                                        />
                                                    )
                                                );
                                            })
                                        ) : (
                                            <div className="emptyloadout flex items-center w-[222px] justify-center min-h-[400px]">
                                                <p className="text-center">No Items available</p>
                                            </div>
                                        )}
                                    </>
                                )}
                                {slot === "ability" && (
                                    <>
                                        {userAbilityDetails && userAbilityDetails.length > 0 ? (
                                            userAbilityDetails.map(
                                                (ability: IGetUserAbility, index: number) => {
                                                    return (
                                                        getAbilityNumberCount[ability.id] > 0 && (
                                                            <EquipmentPlainCard
                                                                width="56px"
                                                                height="50px"
                                                                equipmentImages={ability?.image}
                                                                doubleClick={() => {
                                                                    handleButton();
                                                                }}
                                                                className={
                                                                    slot === "ability" &&
                                                                        (loadoutAbilityDetails as IGetUserAbility)
                                                                            ?.id === ability.id
                                                                        ? "setColor"
                                                                        : "setGrey"
                                                                }
                                                                equipmentNumber={
                                                                    getAbilityNumberCount[
                                                                    ability.id
                                                                    ]
                                                                }
                                                                handleClick={() => {
                                                                    if (
                                                                        (loadoutAbilityDetails as IGetUserAbility)
                                                                            ?.id !== ability.id
                                                                    ) {
                                                                        setLoadoutAbilityDetails(
                                                                            ability
                                                                        );
                                                                        setAnimationToggle(
                                                                            !animationToggle
                                                                        );
                                                                    }
                                                                }}
                                                                key={`ability__${index}`}
                                                            />
                                                        )
                                                    );
                                                }
                                            )
                                        ) : (
                                            <div className="emptyloadout flex items-center w-[222px] justify-center min-h-[400px]">
                                                <p className="text-center">
                                                    No sneakmoves available
                                                </p>
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="item-details w-7/12 item-details text-white overflow-hidden">
                            <div className="animation max-h-[510px] overflow-auto" id="rightSection">
                                <LoadoutDetails
                                    war={war}
                                    slot={slot}
                                    handleButton={handleButton}
                                    loadoutEquipmentDetails={loadoutEquipmentDetails}
                                    setEquipedLoadout={setEquipedLoadout}
                                    setReplaceloadout={setReplaceloadout}
                                    isEquipBtnActive={isEquipBtnActive}
                                    buttonName={buttonName}
                                    setButtonName={setButtonName}
                                    setEquipeBtnActive={setEquipeBtnActive}
                                    handleConfirm={switchLoadout}
                                    loadoutItemDetails={loadoutItemDetails}
                                    itemIndex={itemIndex}
                                    equipedLoadout={equipedLoadout}
                                    setItemNumberCount={setItemNumberCount}
                                    getItemNumberCount={getItemNumberCount}
                                    loadoutAbilityDetails={loadoutAbilityDetails}
                                    getAbilityNumberCount={getAbilityNumberCount}
                                    setAbilityNumberCount={setAbilityNumberCount}
                                    abilityIndex={abilityIndex}
                                    doubleClickLoadout={doubleClickLoadout}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="py-4 px-4">
                        <GameTime
                            war={war}
                            handleContinue={() => {
                                if (
                                    !equipedLoadout.equipment &&
                                    allowedWeapons.length !== WeaponTypeCount()
                                ) {
                                    toasterService.set({
                                        title: "Warning",
                                        message: `You cannot join weapon specific game without taking a weapon.`,
                                        type: "error",
                                        toastSize: "toastSmall",
                                    });
                                } else {
                                    continueGame();
                                    setConfirmLoadout(true);
                                }
                            }}
                        />
                    </div>
                </div>
            </SelectionScreen>

            {confirmLoadout && (
                <CommonModal
                    isVisible={confirmLoadout}
                    setIsVisible={setConfirmLoadout}
                    modalTitle={"Confirm your loadout"}
                    confirmHandle={joinGameGangwar}
                    modalContent={
                        <>
                            <div className="rapidRecoverNFTText">
                                {/* <p className="rapidRecoverNFTTopText text-2xl">
                  {userEquipedLoadout.length === 7
                    ? "You have some empty slots in your loadout."
                    : userEquipedLoadout.length > 0
                    ? "You have some empty slots in your loadout."
                    : "All your loadout slots are empty."}
                </p>
                <hr className="horizontal-seperator" /> */}
                                <p className="rapidRecoverNFTInfo">
                                    Are you sure you want to join this war?
                                </p>
                                <p className="rapidRecoverNFTInfo">
                                    You wont be able to withdraw once you join a war. You will only
                                    be allowed to change sneakmoves and equipment after you lock
                                    this loadout.
                                </p>
                            </div>
                            {userEquipedLoadout.length > 0 && (
                                <>
                                    <hr className="horizontal-seperator" />
                                    <div className="flex items-center gap-4 py-8">
                                        {userEquipedLoadout.map((item) => {
                                            return (
                                                item.image && (
                                                    <EquipmentPlainCard
                                                        height="60px"
                                                        width="64px"
                                                        equipmentImages={item.image}
                                                        className="setGrey"
                                                    />
                                                )
                                            );
                                        })}
                                    </div>
                                </>
                            )}
                        </>
                    }
                    approveAction={"YES, JOIN WAR"}
                    cancelAction={"NOT NOW"}
                />
            )}

            {replaceLoadout && (
                <SwitchWeapon
                    isVisible={replaceLoadout}
                    setIsVisible={() => {
                        setReplaceloadout(false);
                    }}
                    previousEquiped={
                        slot === "weapon"
                            ? equipedLoadout.equipment
                            : slot === "item"
                                ? equipedLoadout.items[itemIndex]
                                : slot === "ability" && equipedLoadout.ability[abilityIndex]
                    }
                    modalTitle={slot === "item" ? "Switch Items?" : slot === "ability" ? "Switch Sneakmove?" : "Switch Weapon?"}
                    switchEquiped={
                        slot === "weapon"
                            ? loadoutEquipmentDetails
                            : slot === "item"
                                ? loadoutItemDetails
                                : slot === "ability" && loadoutAbilityDetails
                    }
                    handleConfirm={switchLoadout}
                />
            )}

            {showPayCrownConfirm.status && (
                <PayEntryFee
                    isVisible={showPayCrownConfirm.status}
                    setIsVisible={() => {
                        setShowPayCrownConfirm((prev) => ({
                            ...prev,
                            status: false,
                            method: "",
                        }));
                    }}
                    handleConfirm={confirmJoinGame}
                    warFee={warFee}
                    showPayCrownConfirm={showPayCrownConfirm}
                />
            )}

            {/* {showLobbyDetails && (
        <LobbyScreen
          participatedNFTsDetails={selectedCharacterInfo}
          participantsDetails={equipedLoadout}
          isScreenClose={showLobbyDetails}
          setScreenClose={setShowLobbyDetails}
          simulationId={war.id}
          war={war}
          walletAddress={walletAddress}
        />
      )} */}
        </>
    );
};

export default CharacterEquipment;
