import NFTCard from "@components/card/nftCard.component";
import Loading from "@components/loading/loading";
import toasterService from "@components/toast/ToasterService.component";
import { ABILITY, ITEM, NEXT, WEAPON } from "@constants/images";
import { onSnapshot } from "@firebase/firestore";
import { EFFECTS as EFFECTS_IMG } from "@constants/images";
import ItemTooltip from "@components/tooltips/item.tooltip";
import SneakMoves from "@components/tooltips/sneakmove.tooltip";
import WeaponTooltip from "@components/tooltips/weapon.tooltip";
import { PLAY_SNEAK_MOVE_HOVER, PLAY_NORMAL_CLICK } from "@constants/audio";
import PostGames from "@pages/postGame/postGame";
import { getUserSneakmove, triggerSneakmove, verifyToken, verifyTokenValidity } from "@api/api";
import LoadingMove from "@components/team/loading";
import ModalCrash from "@components/modal/crash.modal";
import {
  getDB,
  getStats,
  getTeamA,
  getTeamB,
  updateSpectatorStats,
} from "@services/firebase.service";
import {
  getLockedNFTInSimulation,
  getSimulationDetail,
  getUserParticipantDetail,
  hasWalletParticipated,
} from "@services/icon.service";
import axios from "axios";
import { useEffect, useState, useRef } from "react";
import { BsEyeFill } from "react-icons/bs";
import { IoArrowBackOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { Tooltip } from "react-tooltip";
import RightSection from "./RightSection";
import { getDefaultEquipment } from "@api/api";
import { useStats } from "@hooks/useStats";
import { getItemsInformation } from "@utils/getItemsInformation";
import { getAbilitiesInformation } from "@utils/getAbilitiesInformation";
import { getEquipmentInformation } from "@utils/getEquipmentInformation";
import ImageLoader from "@misc/ImageLoader";
import {
  BATTLE_STATE,
  NARRATION_TYPES,
  BATTLE_ROLES,
  BATTLE_TEAMS,
  ACTION_TYPE,
  CHANGE_TYPE,
  MEELE_WEAPONS,
  ITEMS,
  PASSIVE_NARRATION_TYPES,
  PASSIVE_ACTION_TYPES,
  IGNORED_NARRATION_TYPES,
  IGNORED_EFFECTS,
  PRELUDE_NARRATION_TYPES,
  COLD_WATER_EFFECTS,
  EFFECTS,
} from "@constants/war";
import { ANIMATION_VALUES } from "./components/springAnimations";
import AnimatedNftCard, { IFullScrrenEffects } from "./components/AnimatedNftCard";
import {
  IBattleRawEvent,
  IBattleRawEventGroup,
  IBattleEvent,
  IActiveBattleDetails,
  IAnimatedWarHealth,
  IIndividualNftHealth,
  IChangeAmount,
  IMainOrTargetRaw,
  IMainOrTarget,
} from "./interfaces/warScreen.interface";
import InactiveTabModal from "./components/InactiveTabModal";
import { useNFTDetailGlobalContext } from "@context/nftInformation";
import { getNFTInformation } from "@utils/getNFTInformation";
import { useDetailGlobalContext } from "@context/detailProvider";
import {
  getBattleEquipment,
  getGifImage,
  preFetchImages,
  getInfiniteEffectGif,
  sleep,
} from "./utils.warscreen";
import GifLoader from "@components/gifLoader/GifLoader.component";
import PrimaryButton from "@components/buttons/primary.button";
import { useWarListGlobalContext } from "@context/war";
import { VolumeHandler } from "./components/VolumeHandler";
import AnimatedEventName from "./components/AnimatedEventName";
import { useSignMessage } from "@hooks/useSignMessage";
import { useNFTInformation } from "@context/nftInfoProvider";

const BlueProgressButton = ({ width }: any) => {
  return (
    <div className="mt-1 w-[60px] bg-gray-600 h-2 border border-[#ddc469] ">
      <div
        className="health-transition h-1.5 bg-[#00E1D4]"
        style={{ width: `${width}%` }}
      ></div>
    </div>
  );
};

const OrangeProgressButton = ({ width }: any) => {
  return (
    <div className="mt-1 w-[60px] bg-gray-600 h-2 border border-[#ddc469] ">
      <div
        className="health-transition h-1.5 bg-[#FF8D24]"
        style={{ width: `${width}%` }}
      ></div>
    </div>
  );
};

const warStartsIn = ({ seconds, minutes }: any) => (
  <div>{seconds < 10 ? "0" + seconds : seconds}</div>
);

const paddingTheArray = (array: any, padWith: any, withSize: number) => {
  const result = [...array];
  while (result.length < withSize) {
    result.push({ ...padWith });
  }
  return result;
};

const NFTCardWithOrangeProgress = (
  {
    info,
    healthInfo,
    withIcon,
    nftExtraInfo,
    dead,
    isDeadAnimation,
    activeBattleState,
    setActiveBattleState,
    activeBattleDetails,
    isActiveGroup,
    isBattlePassive,
    shouldComeToDefaultPos,
    shouldComeToBattleField,
    isOneVOneBattle,
    effects,
    fullScreenEffect,
    setFullScreenEffect,
    fullScreenLoadFlag,
    fullScreenCompleteFlag,
  }: any,
  ...props: any
) => {
  console.log("information", info);
  // todo: move battleState & setActiveBattleState into context api
  return (
    <div className={["war-group", isActiveGroup ? "active-war-group" : ""].join(" ")}>
      <AnimatedNftCard
        team={BATTLE_TEAMS.ORANGE}
        battleState={activeBattleState}
        setBattleState={setActiveBattleState}
        battleDetails={activeBattleDetails}
        id={info.token_id}
        isBattlePassive={isBattlePassive}
        shouldComeToDefaultPos={shouldComeToDefaultPos}
        shouldComeToBattleField={shouldComeToBattleField}
        isOneVOneBattle={isOneVOneBattle}
        fullScreenEffect={fullScreenEffect}
        setFullScreenEffect={setFullScreenEffect}
        fullScreenLoadFlag={fullScreenLoadFlag}
        fullScreenCompleteFlag={fullScreenCompleteFlag}
        isDeadAnimation={isDeadAnimation}
      >
        {!dead &&
          effects?.map((effect: any) => (
            <img
              style={{
                position: "absolute",
                height: "100%",
                zIndex: "12",
                pointerEvents: "none",
              }}
              src={getInfiniteEffectGif(effect.effectType)}
            />
          ))}
        <NFTCard
          borderType="nftBorderHistoryAndBattle"
          cardType="nftCardImageHistoryAndBattle"
          height={"75px"}
          width={"60px"}
          id={`id_${info.token_id}`}
          imageId={info.token_id}
          dead={dead}
          flip={true}
        />
        {/* {nftExtraInfo?.effects?.length > 0 && (
          <div className="flex gap-2 py-1">
            {nftExtraInfo.effects.map((effect: string, index: number) => {
              return (
                <>
                  <ImageLoader
                    alt="effect"
                    width="25px"
                    src={EFFECTS?.[effect.toUpperCase()]}
                  />
                </>
              );
            })}
          </div>
        )} */}
        <div
          style={{
            transform:
              document.getElementById(`id_${info.token_id}`) &&
                document.getElementById(`id_${info.token_id}`)!.getBoundingClientRect()
                  .width /
                document.getElementById(`id_${info.token_id}`)!.offsetWidth >
                2
                ? "scale(0.4)"
                : "",
          }}
        >
          <Tooltip
            anchorSelect={`#id_${info.token_id}`}
            place="left"
            style={{
              backgroundColor: "#192228",
              border: "1px solid #485c65",
              padding: "20px",
              width: "220px",
              zIndex: 99999,
              maxWidth: 450,
              opacity: 1,
            }}
            delayShow={800}
          >
            <>
              <div className="flex items-start gap-3">
                {/* <NFTCard
                  imageId={info.token_id}
                  height={"75px"}
                  width={"60px"}
                  isSelected
                  dead={dead}
                /> */}
                <div className="flex flex-col">
                  <div className="text-orange font-semibold text-lg">
                    {info.token_name}
                  </div>
                  <div className="text-gray font-semibold">{info.class_type}</div>
                  <div className="text-white">
                    <b>{info.statistics.current_level.name}</b>
                  </div>
                  {/* <div className="border-b-2 border-gray-600 pb-2">
                    <ProgressButton
                      width={`${healthInfo &&
                        Object.keys(healthInfo).length > 0 &&
                        calculateHealth(
                          healthInfo?.currentHealth,
                          healthInfo?.initialHealth
                        )}%`}
                    />
                  </div> */}

                  {/* <div className="flex items-center gap-4 pt-3">
                              <EquipmentPlainCard
                                equipmentImages={PISTOL}
                                height="60px"
                                width="60px"
                              />
                              <EquipmentPlainCard
                                equipmentImages={PISTOL}
                                height="60px"
                                width="60px"
                                className="setColor"
                              />
                            </div> */}
                </div>
              </div>
            </>
          </Tooltip>
        </div>
        <OrangeProgressButton
          width={
            healthInfo &&
            Object.keys(healthInfo).length > 0 &&
            calculateHealth(healthInfo?.currentHealth, healthInfo?.initialHealth)
          }
        />
        {/* <p className="text-xs text-gray text-left font-semibold">{info.name}</p> */}
        {withIcon && <img src={NEXT} alt="" className="absolute -top-[10px] right-[0px]" />}
      </AnimatedNftCard>
    </div>
  );
};

const calculateHealth = (presentHealth: number, previousHealth: number) => {
  presentHealth = presentHealth < 0 ? 0 : presentHealth;
  const healthProportion = (presentHealth / previousHealth) * 100;
  return healthProportion <= 100 ? healthProportion : 100;
};

const NFTCardWithBlueProgress = ({
  info,
  healthInfo,
  withIcon,
  nftExtraInfo,
  dead,
  isDeadAnimation,
  activeBattleState,
  setActiveBattleState,
  activeBattleDetails,
  isActiveGroup,
  isBattlePassive,
  shouldComeToDefaultPos,
  shouldComeToBattleField,
  isOneVOneBattle,
  effects,
  fullScreenEffect,
  setFullScreenEffect,
  fullScreenLoadFlag,
  fullScreenCompleteFlag,
}: any) => {
  return (
    <div className={["war-group", isActiveGroup ? "active-war-group" : ""].join(" ")}>
      <AnimatedNftCard
        team={BATTLE_TEAMS.BLUE}
        battleState={activeBattleState}
        setBattleState={setActiveBattleState}
        battleDetails={activeBattleDetails}
        id={info.token_id}
        isBattlePassive={isBattlePassive}
        shouldComeToDefaultPos={shouldComeToDefaultPos}
        shouldComeToBattleField={shouldComeToBattleField}
        isOneVOneBattle={isOneVOneBattle}
        fullScreenEffect={fullScreenEffect}
        setFullScreenEffect={setFullScreenEffect}
        fullScreenLoadFlag={fullScreenLoadFlag}
        fullScreenCompleteFlag={fullScreenCompleteFlag}
        isDeadAnimation={isDeadAnimation}
      >
        {!dead &&
          effects?.map((effect: any) => (
            <img
              className={`${effect.effectType === "CONFUSION" ? "" : "flip"}`}
              style={{
                position: "absolute",
                height: "100%",
                zIndex: "12",
                pointerEvents: "none",
              }}
              src={getInfiniteEffectGif(effect.effectType)}
            />
          ))}
        <NFTCard
          borderType="nftBorderHistoryAndBattle"
          cardType="nftCardImageHistoryAndBattle"
          imageId={info.token_id}
          height={"75px"}
          width={"60px"}
          id={`id_${info.token_id}`}
          dead={dead}
        />
        {/* {nftExtraInfo?.effects?.length > 0 && (
          <div className="flex gap-2 py-1">
            {nftExtraInfo.effects.map((effect: string, index: number) => {
              return (
                <>
                 
                  <ImageLoader
                    alt="effect"
                    width="25px"
                    src={EFFECTS?.[effect.toUpperCase()]}
                  />
                </>
              );
            })}
          </div>
        )} */}
        <div
          style={{
            transform:
              document.getElementById(`id_${info.token_id}`) &&
                document.getElementById(`id_${info.token_id}`)!.getBoundingClientRect()
                  .width /
                document.getElementById(`id_${info.token_id}`)!.offsetWidth >
                2
                ? "scale(0.4)"
                : "",
          }}
        >
          <Tooltip
            anchorSelect={`#id_${info.token_id}`}
            place="right"
            style={{
              backgroundColor: "#192228",
              border: "1px solid #485c65",
              padding: "20px",
              zIndex: 99999,
              width: "220px",
              opacity: 1,
            }}
            delayShow={800}
          >
            <div className="flex items-start gap-3">
              {/* <NFTCard
                imageId={info.token_id}
                height={"75px"}
                width={"60px"}
                isSelected
                dead={dead}
              /> */}
              <div className="flex flex-col">
                <div className="text-blue font-semibold text-lg">
                  {info.token_name}
                </div>
                <div className="text-gray font-semibold">{info.class_type}</div>
                <div className="text-white">
                  <b>{info.statistics.current_level.name}</b>
                </div>
                {/* <div className="border-b-2 border-gray-600 pb-2">
                  <ProgressButton
                    width={`${calculateHealth(
                      healthInfo?.currentHealth,
                      healthInfo?.initialHealth
                    )}%`}
                  />
                </div> */}
              </div>
            </div>
          </Tooltip>
        </div>
        <BlueProgressButton
          width={calculateHealth(healthInfo?.currentHealth, healthInfo?.initialHealth)}
        />
        {/* <p className="text-xs text-gray text-left font-semibold">{info.name}</p> */}
        {withIcon && (
          <img src={NEXT} alt="" className="absolute -top-[10px] -left-[10px]" />
        )}
      </AnimatedNftCard>
    </div>
  );
};

const NewWarScreen = () => {
  const [isGameOver, setGameOver] = useState({ status: false, winner: "" });
  const gameOverRef = useRef<{ status: boolean; winner: string }>(isGameOver);
  const [allAnimationsDone, setAllAnimationsDone] = useState(false);
  gameOverRef.current = isGameOver;
  const { id } = useParams();
  const isReplayBattle = window.location.href.includes("rewatch");
  const { walletAddress, isWalletConnected } = useSelector((state: any) => state.wallet);
  const { isFirebaseSignedIn, reFetchDetails } = useDetailGlobalContext();
  const stats = useStats(id);
  const [gameState, setGameState] = useState({
    round: 0,
    turn: 0,
  });
  const handleOnPageLoad = async () => {
    const stats = await getStats(id);
    let newCount = 1;
    let maxCount = 1;
    if (stats) {
      newCount = stats.currentCount + 1;
      maxCount = stats.maxCount > newCount ? stats.maxCount : newCount;
    } else {
      // window.alert("No stats found!");
    }
    updateSpectatorStats(id, newCount, maxCount);
  };

  const handleBeforeUnload = async () => {
    window.confirm("Window is unloaded! This is not working in edge, chrome and safari.");
  };

  const [activeBattleState, setActiveBattleState] = useState<
    typeof BATTLE_STATE[keyof typeof BATTLE_STATE]
  >(BATTLE_STATE.NONE);
  const [isBattlePassive, setIsBattlePassive] = useState<boolean>(false);
  const [battleAnimLogs, setBattleAnimLogs] = useState<Array<IBattleEvent>>([]);
  const [animatedLogs, setAnimatedLogs] = useState<Array<IBattleEvent>>([]);
  // ref needed due to invocation from async setTimeout
  const battleAnimLogsRef = useRef(battleAnimLogs);
  battleAnimLogsRef.current = battleAnimLogs;
  const animStartIndexRef = useRef<number | undefined>(undefined);
  const [activeBattleDetails, setActiveBattleDetails] = useState<IActiveBattleDetails | null>(
    null
  );
  const [isNewEventGroup, setIsNewEventGroup] = useState(true);
  const activeBattleRef = useRef<IActiveBattleDetails | null>(null);

  const [fullScreenEffect, setFullScreenEffect] = useState<IFullScrrenEffects>({
    show: false,
    id: "",
    image: undefined,
    audio: new Audio(),
    styles: {},
  });
  const [fullScreenCompleteFlag, setFullScreenCompleteFlag] = useState(false);
  const [fullScreenLoadFlag, setFullScreenLoadFlag] = useState(false);
  const { setMessageSigningModal, messageSigningModal } = useWarListGlobalContext();

  const [isTokenInvalid, setTokenInvalid] = useState(false);
  async function checkTokenValidity() {
    const token = localStorage.getItem("token");
    if (token) {
      const isValid = await verifyTokenValidity({ token });
      if (!isValid.data.publicKey) {
        setTokenInvalid(true);
      } else {
        setTokenInvalid(false);
      }
    } else {
      setTokenInvalid(true);
    }
  }

  useEffect(() => {
    reFetchDetails();
  }, []);

  const [activeNftGroup, setActiveNftGroup] = useState<Array<Number>>([]);
  const firstHealthFetch = useRef<boolean>(true);
  const prevEventGroupLength = useRef<Number>(0);
  const individualTeamsPending = useRef<boolean>(false);
  const isOneVOneBattle = useRef<boolean>(false);

  const isNextAttackCollateral =
    activeBattleDetails &&
    battleAnimLogs[activeBattleDetails.battleIndex + 1]?.actionType ===
    ACTION_TYPE.COLLATERAL_ATTACK;

  const isNextNarrationDead =
    activeBattleDetails &&
    battleAnimLogs[activeBattleDetails.battleIndex + 1]?.narrationType ===
    NARRATION_TYPES.PLAYER_DEAD;

  const getMainTargetObj = (
    rawObj: IMainOrTargetRaw | -1,
    changedAmount: Array<IChangeAmount> | -1,
    narrationType: string,
    isMain: boolean,
    weapon?: string
  ): IMainOrTarget => {
    const defaultObj = {
      id: undefined,
      team: undefined,
      hpChange: 0,
      curHealth: undefined,
      initialHealth: undefined,
    };

    if (
      (isMain && narrationType === NARRATION_TYPES.EFFECT_LOG) ||
      (!isMain &&
        (narrationType === NARRATION_TYPES.ITEM_TAKEN ||
          narrationType === NARRATION_TYPES.SELF_HEAL))
    ) {
      return defaultObj;
    }

    let nftStatChangesObj: any = {};
    if (typeof rawObj === "object" && Array.isArray(changedAmount)) {
      changedAmount.map((statChange) => {
        if (statChange.character === rawObj.id) {
          nftStatChangesObj[statChange.changeType] = statChange.amount;
        }
      });
    }

    // @ts-ignore
    return typeof rawObj === "object"
      ? {
        ...rawObj,
        hpChange: nftStatChangesObj?.[CHANGE_TYPE.HEALTH] ?? 0,
        speedChange: nftStatChangesObj?.[CHANGE_TYPE.SPEED] ?? 0,
        durabilityChange:
          weapon && Object.values(MEELE_WEAPONS).includes(weapon)
            ? nftStatChangesObj?.[CHANGE_TYPE.DURA_AMMO] ?? 0
            : 0,
        ammoChange:
          weapon && !Object.values(MEELE_WEAPONS).includes(weapon)
            ? nftStatChangesObj?.[CHANGE_TYPE.DURA_AMMO] ?? 0
            : 0,
        shieldChange: nftStatChangesObj?.[CHANGE_TYPE.SHIELD] ?? 0,
        coldWaterChange: Object.getOwnPropertyDescriptor(
          nftStatChangesObj,
          CHANGE_TYPE.BURN
        )
          ? COLD_WATER_EFFECTS.BURN
          : Object.getOwnPropertyDescriptor(nftStatChangesObj, CHANGE_TYPE.STUN)
            ? COLD_WATER_EFFECTS.STUN
            : undefined,
      }
      : defaultObj;
  };

  const showInactiveModal = useRef(false);
  const showJumpToLatestEvent = useRef(false);
  const shouldJumpToLatestEvent = useRef<boolean>(false);
  const prevJumpTimeoutRef = useRef<any>(null);
  const updateBlurredState = () => {
    const dontShowFlag = sessionStorage.getItem("noShowInactiveModal");
    if (document.visibilityState === "hidden") {
      if (!dontShowFlag) {
        showInactiveModal.current = true;
      }
    } else {
      if (
        !isReplayBattle &&
        activeBattleRef.current &&
        stateRef.current &&
        activeBattleRef.current.eventGroupId < stateRef.current.eventLogs.length - 1
      ) {
        showJumpToLatestEvent.current = true;
        // remove button after 14sec on inactivity
        clearTimeout(prevJumpTimeoutRef.current);
        prevJumpTimeoutRef.current = setTimeout(() => {
          showJumpToLatestEvent.current = false;
        }, 7000);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("visibilitychange", updateBlurredState);
    if (!isReplayBattle) {
      handleOnPageLoad();
      window.addEventListener("load", handleOnPageLoad);
      window.addEventListener("unload", handleBeforeUnload);

      return () => {
        window.removeEventListener("load", handleOnPageLoad);
        window.removeEventListener("unload", handleBeforeUnload);
        window.removeEventListener("visibilitychange", updateBlurredState);
      };
    } else {
      return () => {
        window.removeEventListener("visibilitychange", updateBlurredState);
      };
    }
  }, [isFirebaseSignedIn]);

  const [playersInfo, setPlayersInfo] = useState<any>({
    teamA: [],
    teamB: [],
  });

  const [state, setstate] = useState<any>({
    isGameOver: false,
    isLobbyLocked: true,
    totalEventCount: 0,
    currentEvent: null,
    spotlight: "Team A",
    eventLogs: [
      {
        eventName: "GAME STARTING SOON",
        logCollection: [],
      },
    ],
    currentEventId: 0,
    currentEventName: "GAME STARTING SOON",
  });
  const stateRef = useRef<any>(null);

  const { signClick } = useSignMessage("randomNumber");
  const handleSigning = async () => {
    PLAY_NORMAL_CLICK();
    const signature = await signClick();
    const payload = {
      address: walletAddress,
      signature: signature,
      payload: localStorage.getItem("dynamicHash") as string,
    };

    const res = await verifyToken(payload);
    if (res.data.jwt) {
      localStorage.setItem("token", res.data.jwt);
    }
    // setIsVisible(false);
    // dispatch(setLoadingState(false));
  };

  useEffect(() => {
    // console.log('_____________________________________');
    // console.log(state.eventLogs);
    const newBattleAnimations: Array<IBattleEvent> = [];
    let newGroupStartIndex =
      battleAnimLogs.length > 0
        ? battleAnimLogs[battleAnimLogs.length - 1].eventGroupId + 1
        : 0;

    const newLogGroups = state.eventLogs.slice(newGroupStartIndex);
    const canIgnorePrelude = isReplayBattle
      ? battleAnimLogs.length === 0 &&
      PRELUDE_NARRATION_TYPES.includes(
        state.eventLogs[0]?.logCollection[0]?.narrationType
      ) &&
      PRELUDE_NARRATION_TYPES.includes(
        state.eventLogs[0]?.logCollection[state.eventLogs[0].logCollection.length - 1]
          ?.narrationType
      )
      : activeBattleDetails?.eventGroupId === 0 &&
      PRELUDE_NARRATION_TYPES.includes(activeBattleDetails.narrationType!) &&
      PRELUDE_NARRATION_TYPES.includes(
        state.eventLogs[0]?.logCollection[state.eventLogs[0]?.logCollection.length - 1]
          ?.narrationType
      );

    // prefetch gif images
    const gifImageSources: Array<string> = [];
    newLogGroups.forEach((battleEventGroup: IBattleRawEventGroup, eventGroupIndex: number) => {
      eventGroupIndex += newGroupStartIndex;
      const isAnimated = animStartIndexRef.current
        ? eventGroupIndex < animStartIndexRef.current
          ? true
          : false
        : false;
      battleEventGroup.logCollection?.forEach(
        (battleRawEvent: IBattleRawEvent, index: number) => {
          // if(battleRawEvent && eventGroupIndex == 12) { // todo
          if (battleRawEvent) {
            // main if consuming or attacking
            // target if effect like poison or getting attacked
            const newBattleEvent = {
              eventGroupId: eventGroupIndex,
              narration: battleRawEvent.narration,
              // gameState: battleRawEvent?.gameState,
              narrationType: battleRawEvent.narrationType, // categorize gif images (consume/effect/attack)
              main: getMainTargetObj(
                battleRawEvent.main,
                battleRawEvent.changedAmount,
                battleRawEvent.narrationType,
                true,
                battleRawEvent.mainWep?.name
              ),
              target: getMainTargetObj(
                battleRawEvent.target,
                battleRawEvent.changedAmount,
                battleRawEvent.narrationType,
                false,
                battleRawEvent.mainWep?.name
              ),
              distractedNFTs: battleRawEvent.distractedNFTs,
              equipment:
                battleRawEvent.mainWep?.name ??
                battleRawEvent.itemType ??
                battleRawEvent.effectType ??
                battleRawEvent.abilityType, // main animation
              eventName: battleEventGroup.eventName, // event group name
              actionType: battleRawEvent.actionType, // 1v1
              attackType:
                typeof battleRawEvent.attackType == "string"
                  ? battleRawEvent.attackType
                  : undefined, // 1v1 MISS/HIT/DODGED
              isAnimated: !isReplayBattle
                ? isAnimated
                : canIgnorePrelude && eventGroupIndex === 0,
              // isAnimated: false,
              // populate only once for rightSection to detect boundaries
              mains: index === 0 ? battleEventGroup.mains : undefined,
              targets: index === 0 ? battleEventGroup.targets : undefined,
              contextLog: index === 0 ? battleEventGroup.contextLog : undefined,
              extraDamage: battleRawEvent.extraDamage,
              firstAnimOfEventGroup: index === 0,
            };

            newBattleAnimations.push(newBattleEvent);
            // update equipment only for prefetching
            newBattleEvent.equipment = getBattleEquipment(newBattleEvent);
            gifImageSources.push(
              getGifImage(newBattleEvent),
              getGifImage(newBattleEvent, true)
            );
          }
        }
      );
    });
    preFetchImages(gifImageSources);
    // setBattleAnimLogs(newBattleAnimations.slice(18)); // todo
    const oldBattleAnimations =
      canIgnorePrelude || shouldJumpToLatestEvent.current
        ? battleAnimLogs.map((battleEvent) => {
          battleEvent.isAnimated = true;
          return battleEvent;
        })
        : battleAnimLogs;

    setBattleAnimLogs([...oldBattleAnimations, ...newBattleAnimations]);
    shouldJumpToLatestEvent.current = false;
    console.log("////////////////////");
    console.log([...oldBattleAnimations, ...newBattleAnimations]);
  }, [state, isFirebaseSignedIn]);

  const [participatedInTeam, setParticipatedInTeam] = useState({
    team: "",
    nftId: -1,
  });

  const [playerDetails, setPlayerDetails] = useState<any>(null);
  const [userStatsDetails, setUserStatsDetails] = useState<any>(null);

  const [isWalletParticipated, setWalletParticipated] = useState<boolean>(false);

  const [loading, setLoading] = useState(true);
  const [totalHealth, setTotalHealth] = useState({
    teamA: {
      initial: 0,
      present: 0,
    },
    teamB: {
      initial: 0,
      present: 0,
    },
  });

  const [nftIds, setNftIds] = useState<any[]>([]);
  const [currentAnimateNFTIds, setCurrentAnimationNFTIds] = useState<any>({
    teamA: [],
    teamB: [],
  });

  const [participantsDetails, setParticipatedDetails] = useState<any>({
    abilities: [],
    equipments: [],
    items: [],
  });

  const [detailInfoParticipants, setDetailInfoParticipants] = useState<any>({
    abilities: {},
    equipments: {},
    items: {},
  });

  const getParticipatedDetails = async (warId: number, nftId: number) => {
    setParticipatedDetails({
      abilities: [],
      equipments: [],
      items: [],
    });
    setDetailInfoParticipants({
      abilities: {},
      equipments: {},
      items: {},
    });
    const details = await getUserParticipantDetail(
      Number(warId).toString(),
      Number(nftId).toString()
    );

    details.items.forEach(async (element: any) => {
      const res = await getItemsInformation(element.id);
      setDetailInfoParticipants((prev: any) => ({
        ...prev,
        items: { ...prev.items, [Number(element.id)]: res },
      }));
    });
    details.abilities.forEach(async (element: any) => {
      const res = await getAbilitiesInformation(element.id);
      setDetailInfoParticipants((prev: any) => ({
        ...prev,
        abilities: { ...prev.abilities, [Number(element.id)]: res },
      }));
    });
    details.equipments.forEach(async (element: any) => {
      const res = await getEquipmentInformation(element.id);
      setDetailInfoParticipants((prev: any) => ({
        ...prev,
        equipments: { ...prev.equipments, [Number(element.id)]: res },
      }));
    });

    setParticipatedDetails({
      abilities: details.abilities,
      equipments: details.equipments,
      items: paddingTheArray(
        increaseArrayByCountItems(details.items),
        {
          amount: "0x1",
          id: -1,
          owner: "",
        },
        3
      ),
    });
  };

  const [nftIdsTeam, setNftIdsTeam] = useState<any>({
    teamA: [],
    teamB: [],
  });

  const [defaultWeapon, setDefaultWeapon] = useState("");
  const [getLootboxId, setLootboxId] = useState({
    lootBoxId: 0,
    hddLootBoxId: 0,
    hhLootBoxId: 0,
    mvpLootBoxId: 0,
  });

  const getDefaultEquipments = async (nftId: string) => {
    const result = await getDefaultEquipment(nftId);
    setDefaultWeapon(result.data?.defaultEquipment?.imageUrl);
  };
  const [isAllIdFetched, setAllIdFetched] = useState(false);

  useEffect(() => {
    async function getSimDetails() {
      const warDetails = await getSimulationDetail(id?.toString());
      setLootboxId({
        lootBoxId: Number(warDetails.reward.lootBoxId),
        hddLootBoxId: Number(warDetails.reward.hddLootBoxId),
        hhLootBoxId: Number(warDetails.reward.hhLootBoxId),
        mvpLootBoxId: Number(warDetails.reward.mvpLootBoxId),
      });
    }
    getSimDetails();
  }, [isFirebaseSignedIn]);

  async function fetchDetails() {
    const [checkStatus, querySnapshotTeamA, querySnapshotTeamB] = await Promise.all([
      hasWalletParticipated(id, walletAddress),
      getTeamA(id),
      getTeamB(id),
    ]);
    setWalletParticipated(!!Number(checkStatus));
    const teamATempInfo: any[] = [];
    const teamBTempInfo: any[] = [];

    for await (const doc of querySnapshotTeamA.docs) {
      const currentDocData = doc.data();
      // console.log(
      //   "teamATempInfo",
      //   `${process.env.REACT_APP_GANGSTABET_URL}/0x${doc.data().id}`
      // );
      const nft_info = await axios.get(
        `${process.env.REACT_APP_GANGSTABET_URL}/0x${Number(currentDocData.id).toString(
          16
        )}`
      );

      setNftIds((prev: any) => [...prev, doc.data().id]);

      if (!!Number(checkStatus)) {
        const info = await getNFTInformation(currentDocData.id);
        const nftId = await getLockedNFTInSimulation(walletAddress, id);

        if (currentDocData.id.toString() === Number(nftId).toString()) {
          getDefaultEquipments(nftId);
          getParticipatedDetails(Number(id), Number(nftId));
          setParticipatedInTeam({ team: "teamA", nftId: Number(nftId) });
          setUserStatsDetails(info);
          setPlayerDetails({
            // ...doc.data(),
            ...nft_info.data,
            team: "Team A",
          });
        }
      }
      teamATempInfo.push({
        // ...doc.data(),
        ...nft_info.data,
        team: "Team A",
      });
    }

    for await (const doc of querySnapshotTeamB.docs) {
      const nft_info = await axios.get(
        `${process.env.REACT_APP_GANGSTABET_URL}/0x${Number(doc.data().id).toString(16)}`
      );

      setNftIds((prev: any) => [...prev, doc.data().id]);
      if (!!Number(checkStatus)) {
        const info = await getNFTInformation(doc.data().id);
        const nftId = await getLockedNFTInSimulation(walletAddress, id);
        if (doc.data().id.toString() === Number(nftId).toString()) {
          getParticipatedDetails(Number(id), Number(nftId));
          getDefaultEquipments(nftId);
          setParticipatedInTeam({ team: "teamB", nftId: Number(nftId) });
          setUserStatsDetails(info);
          setPlayerDetails({
            // ...doc.data(),
            ...nft_info.data,
            team: "Team B",
          });
        }
      }

      teamBTempInfo.push({
        // ...doc.data(),
        ...nft_info.data,
        team: "Team B",
      });
    }

    // console.log("teamATempInfo", {
    //   teamA: teamATempInfo,
    //   teamB: teamBTempInfo,
    // });

    setPlayersInfo({
      teamA: teamATempInfo,
      teamB: teamBTempInfo,
    });

    return teamATempInfo.length > 0 && teamBTempInfo.length > 0;
  }

  const updateDeadNfts = () => {
    if (activeBattleDetails?.narrationType === NARRATION_TYPES.PLAYER_DEAD) {
      if (activeBattleDetails.target.id) {
        deadNftsRef.current = [...deadNftsRef.current, activeBattleDetails.target.id];
      }
    }

    if (
      activeBattleDetails?.narrationType === NARRATION_TYPES.ITEM_TAKEN &&
      activeBattleDetails?.equipment === ITEMS.ANKH_OF_REBIRTH
    ) {
      deadNftsRef.current = deadNftsRef.current.filter(
        (id) => id !== activeBattleDetails.main.id
      );
    }
    if (
      activeBattleDetails?.narrationType === NARRATION_TYPES.ACTION_LOG &&
      activeBattleDetails?.actionType === ACTION_TYPE.REVIVE
    ) {
      deadNftsRef.current = deadNftsRef.current.filter(
        (id) => id !== activeBattleDetails.main.id && id !== activeBattleDetails.target.id
      );
    }
  };

  const updateCurrNftEffects = () => {
    if (
      (activeBattleDetails?.narrationType === IGNORED_NARRATION_TYPES.EFFECT_TAKEN ||
        activeBattleDetails?.narrationType === IGNORED_NARRATION_TYPES.EFFECT_REFRESHED) &&
      activeBattleDetails.equipment &&
      !Object.keys(IGNORED_EFFECTS).includes(activeBattleDetails.equipment) &&
      activeBattleDetails.equipment !== EFFECTS.DEAD_COVER
    ) {
      setCurrNftEffects((prev) => [
        ...prev,
        {
          id: activeBattleDetails.target.id!,
          effectType: activeBattleDetails.equipment!,
        },
      ]);
    }
    if (activeBattleDetails?.narrationType === IGNORED_NARRATION_TYPES.EFFECT_OVER) {
      setCurrNftEffects((prev) =>
        prev.filter(
          (effect) =>
            !(
              effect.id === activeBattleDetails.target.id &&
              effect.effectType === activeBattleDetails.equipment
            )
        )
      );
    }
    if (activeBattleDetails?.narrationType === NARRATION_TYPES.PLAYER_DEAD) {
      setCurrNftEffects((prev) =>
        prev.filter((effect) => !(effect.id === activeBattleDetails.target.id))
      );
    }
  };

  const updateWarHealths = () => {
    const isBlueMain = activeBattleDetails?.main.team?.team === BATTLE_TEAMS.BLUE;
    const isBlueTarget = activeBattleDetails?.target.team?.team === BATTLE_TEAMS.BLUE;

    const newBlueTeamHealth = isBlueMain
      ? activeBattleDetails.main.team
      : isBlueTarget
        ? activeBattleDetails.target.team
        : null;

    const isOrangeMain = activeBattleDetails?.main.team?.team === BATTLE_TEAMS.ORANGE;
    const isOrangeTarget = activeBattleDetails?.target.team?.team === BATTLE_TEAMS.ORANGE;

    const newOrangeTeamHealth = isOrangeMain
      ? activeBattleDetails.main.team
      : isOrangeTarget
        ? activeBattleDetails.target.team
        : null;

    const mainHealthChange = {
      initialHealth: activeBattleDetails?.main.totalHealth,
      currentHealth: activeBattleDetails?.main.curHealth,
    };

    const targetHealthChange = {
      initialHealth: activeBattleDetails?.target.totalHealth,
      currentHealth: activeBattleDetails?.target.curHealth,
    };

    const userRole =
      activeBattleDetails?.main.id === userNFTInfo.nftId
        ? BATTLE_ROLES.MAIN
        : BATTLE_ROLES.TARGET;
    if (activeBattleDetails?.involvedIds.includes(userNFTInfo.nftId)) {
      setUserNFTHealth({
        initial: activeBattleDetails?.[userRole]?.totalHealth ?? 0,
        current: activeBattleDetails?.[userRole]?.curHealth ?? 0,
      });
    }

    setBlueTeamHealth((prev) => ({
      currentTeamHealth: newBlueTeamHealth?.curHealth ?? prev.currentTeamHealth,
      initialTeamHealth: newBlueTeamHealth?.totalHealth ?? prev.initialTeamHealth,
      nftHealth: prev.nftHealth.map((nft) =>
        activeBattleDetails?.involvedIds.includes(nft.id)
          ? {
            id: nft.id,
            currentHealth:
              nft.id === activeBattleDetails.main.id
                ? mainHealthChange!.currentHealth!
                : targetHealthChange!.currentHealth!,
            initialHealth:
              nft.id === activeBattleDetails.main.id
                ? mainHealthChange!.initialHealth!
                : targetHealthChange!.initialHealth!,
          }
          : nft
      ),
    }));

    setOrangeTeamHealth((prev) => ({
      currentTeamHealth: newOrangeTeamHealth?.curHealth ?? prev.currentTeamHealth,
      initialTeamHealth: newOrangeTeamHealth?.totalHealth ?? prev.initialTeamHealth,
      nftHealth: prev.nftHealth.map((nft) =>
        activeBattleDetails?.involvedIds.includes(nft.id)
          ? {
            id: nft.id,
            currentHealth:
              nft.id === activeBattleDetails.main.id
                ? mainHealthChange!.currentHealth!
                : targetHealthChange!.currentHealth!,
            initialHealth:
              nft.id === activeBattleDetails.main.id
                ? mainHealthChange!.initialHealth!
                : targetHealthChange!.initialHealth!,
          }
          : nft
      ),
    }));
  };

  const updateActiveBattle = () => {
    // update previous battle (battleAnimLogs) as animated
    console.log("UPDATING ACTIVE BATTLE");
    const battleAnimLogs = battleAnimLogsRef.current;
    const prevBattleDetails = activeBattleDetails;
    const nextBattleIndex = battleAnimLogs.findIndex((battleEvent) => !battleEvent.isAnimated);

    if (nextBattleIndex < 0) {
      // Either game is over OR we're waiting for new event from backend
      console.log(gameOverRef.current);
      if (gameOverRef.current.status && !individualTeamsPending.current) {
        console.log(
          "____________________________________GAME OVER______________________________"
        );
        setAllAnimationsDone(true);
      } else {
        setTimeout(updateActiveBattle, 1000);
      }
      return;
    }
    const nextBattleRaw = battleAnimLogs[nextBattleIndex];

    const activeGp: Array<Number> = activeNftGroup;
    // this is beginning of next event group
    if (nextBattleRaw.mains || nextBattleRaw.targets) {
      activeGp.length = 0;
      nextBattleRaw.mains?.ids && activeGp.push(...nextBattleRaw.mains.ids);
      nextBattleRaw.targets?.ids && activeGp.push(...nextBattleRaw.targets.ids);
    }

    const nextBattle: IActiveBattleDetails = {
      battleIndex: nextBattleIndex,
      involvedIds: Array.from(
        new Set(
          [nextBattleRaw.main.id, nextBattleRaw.target.id].filter(
            (val) => typeof val == "number"
          )
        )
      ),
      [BATTLE_ROLES.MAIN]: nextBattleRaw.main,
      [BATTLE_ROLES.TARGET]: nextBattleRaw.target,
      [BATTLE_ROLES.DISTRACT_NFTS]: nextBattleRaw.distractedNFTs,
      narrationType: nextBattleRaw.narrationType,
      actionType: nextBattleRaw.actionType,
      equipment: nextBattleRaw.equipment,
      attackType: nextBattleRaw.attackType,
      extraDamage: nextBattleRaw.extraDamage,
      eventGroupId: nextBattleRaw.eventGroupId,
      eventName: nextBattleRaw.eventName,
      firstAnimOfEventGroup: nextBattleRaw.firstAnimOfEventGroup,
    };

    setActiveNftGroup(Array.from(new Set(activeGp)));
    setActiveBattleState(BATTLE_STATE.BATTLE_RESET);
    setActiveBattleDetails(nextBattle);
    setIsNewEventGroup(nextBattle.eventGroupId != activeBattleDetails!.eventGroupId);
    activeBattleRef.current = nextBattle;
    isDeadAnimation.current =
      ((typeof nextBattle.main.id == "number" &&
        deadNftsRef.current.includes(nextBattle.main.id)) ||
        (typeof nextBattle.target.id == "number" &&
          deadNftsRef.current.includes(nextBattle.target.id))) &&
      nextBattle.equipment !== ITEMS.ANKH_OF_REBIRTH &&
      nextBattle.actionType !== ACTION_TYPE.REVIVE &&
      nextBattle.equipment !== EFFECTS.DEAD_COVER;

    // @ts-ignore
    const isPassiveBattle =
      Object.values(PASSIVE_NARRATION_TYPES).includes(
        nextBattle.narrationType as PASSIVE_NARRATION_TYPES
      ) ||
      // @ts-ignore
      (nextBattle?.actionType &&
        Object.values(PASSIVE_ACTION_TYPES).includes(
          nextBattle.actionType as PASSIVE_ACTION_TYPES
        ));
    setIsBattlePassive(isPassiveBattle ? true : false);
    setShouldComeToBattleField(prevBattleDetails?.actionType !== ACTION_TYPE.GUN_JAMMED);
  };

  useEffect(() => {
    console.log("BATTLE STATE  = ", activeBattleState);
    if (
      activeBattleState === BATTLE_STATE.ATTACK_IN_PROGRESS ||
      activeBattleState === BATTLE_STATE.BATTLE_COMPLETE
    ) {
      setAnimatedLogs(
        battleAnimLogs.filter(
          (b, index) =>
            battleAnimLogs[index - 1]?.isAnimated || b.isAnimated || index == 0
        )
      );
    }

    // if current battle is in complete state, wait for buffer time & start another battle.
    if (activeBattleState === BATTLE_STATE.BATTLE_COMPLETE) {
      const lastBattleIndex = battleAnimLogs.findIndex((battle) => !battle.isAnimated);
      const updatedBattleAnimLogs: Array<IBattleEvent> = JSON.parse(
        JSON.stringify(battleAnimLogs)
      );
      updatedBattleAnimLogs[lastBattleIndex].isAnimated = true;
      setBattleAnimLogs(JSON.parse(JSON.stringify(updatedBattleAnimLogs)));

      // update deadNfts
      updateDeadNfts();

      // update nft effects
      updateCurrNftEffects();

      // update healths
      updateWarHealths();
      // start another battle
      setTimeout(() => {
        updateActiveBattle();
      }, ANIMATION_VALUES.MAX_DURATION * 2);
    }
  }, [activeBattleState, isFirebaseSignedIn]);

  useEffect(() => {
    if (playersInfo.teamA.length <= 0 && playersInfo.teamB.length <= 0) {
      console.log("fetch details is ran");
      fetchDetails().then((flag) => {
        if (flag && !battleStarted.current) {
          setTimeout(updateActiveBattle, 1000);
          battleStarted.current = true;
        }
      });
    }
  }, [isAllIdFetched, isFirebaseSignedIn]);

  const [blueTeamHealth, setBlueTeamHealth] = useState<IAnimatedWarHealth>({
    currentTeamHealth: 0,
    initialTeamHealth: 0,
    nftHealth: [],
  });
  const [userNFTHealth, setUserNFTHealth] = useState({
    initial: 0,
    current: 0,
  });
  const [userNFTInfo, setUserNFTInfo] = useState<any>({ nftId: 0, team: "" });

  const [orangeTeamHealth, setOrangeTeamHealth] = useState<IAnimatedWarHealth>({
    currentTeamHealth: 0,
    initialTeamHealth: 0,
    nftHealth: [],
  });
  const deadNftsRef = useRef<Array<number>>([]);
  const isDeadAnimation = useRef<boolean | undefined>(false); // animation for dead NFTs should be ignored, except ANKH_OF_REBIRTH & REVIVE
  const [currNftEffects, setCurrNftEffects] = useState<Array<{ id: number; effectType: string }>>(
    []
  );
  const battleStarted = useRef<boolean>(false);

  const [shouldComeToBattleField, setShouldComeToBattleField] = useState(true);

  const [allNftHealth, setAllnftsHealth] = useState<any>(null);

  // const [allNFTHealth, setALLNFTsHealth] = useState<{
  //   teamA: { [key: number]: { initial: number; present: number } };
  //   teamB: { [key: number]: { initial: number; present: number } };
  // }>({
  //   teamA: {
  //     0: {
  //       initial: 0,
  //       present: 0,
  //     },
  //   },
  //   teamB: {
  //     0: {
  //       initial: 0,
  //       present: 0,
  //     },
  //   },
  // });

  const [continuousNFTsHealth, setContinuousNFTHealth] = useState<any>({});

  const [logCount, setLogCount] = useState(0);
  const [speedList, setSpeedList] = useState({
    teamA: [],
    teamB: [],
  });

  useEffect(() => {
    setLoading(true);

    const unsubscribe = onSnapshot(getDB(id), async (snapshot: any) => {
      setNftIds([]);
      setNftIdsTeam({
        teamA: [],
        teamB: [],
      });
      const nftTotalHealth = {
        teamA: {
          initial: 0,
          present: 0,
        },
        teamB: {
          initial: 0,
          present: 0,
        },
      };

      // const nftHealth: {
      //   teamA: { [key: number]: { initial: number; present: number } };
      //   teamB: { [key: number]: { initial: number; present: number } };
      // } = {
      //   teamA: {},
      //   teamB: {},
      // };

      const idsInTeam: any = {
        teamA: [],
        teamB: [],
      };
      const nftSpeedList: any = {
        teamA: [],
        teamB: [],
      };
      const snap = snapshot.data();
      console.log("*******************");
      console.log(snap);
      let userNFT = 0;
      const teamAIndividualHealths: Array<IIndividualNftHealth> = [];
      const teamBIndividualHealths: Array<IIndividualNftHealth> = [];
      const getIndividualTeams = async () => {
        if (firstHealthFetch.current) {
          await sleep(1000);
        }
        const querySnapshotTeamA = await getTeamA(id);
        const querySnapshotTeamB = await getTeamB(id);
        for await (const doc of querySnapshotTeamA.docs) {
          const currentDocData = doc.data();

          nftSpeedList.teamA.push({
            id: Number(currentDocData.id),
            pos: currentDocData.pos,
            speed: currentDocData?.secondaryStats?.currentStats?.speed,
            name: currentDocData.name,
            isCurrentlyPerforming: currentDocData.isCurrentlyPerforming,
          });
          if (walletAddress && userNFT === 0) {
            const nftId = await getLockedNFTInSimulation(walletAddress, id);
            if (nftId) {
              if (currentDocData.id.toString() === Number(nftId).toString()) {
                userNFT = Number(nftId);
                setUserNFTInfo({
                  nftId: Number(nftId),
                  team: BATTLE_TEAMS.BLUE,
                });
              }
            }
          }
          idsInTeam.teamA.push(currentDocData.id);

          nftTotalHealth["teamA"] = {
            present:
              nftTotalHealth.teamA.present +
              currentDocData.secondaryStats.currentStats.health,
            initial:
              nftTotalHealth.teamA.initial +
              currentDocData.secondaryStats.initialStats.health,
          };
          setAllnftsHealth((prev: any) => ({ ...prev, ...currentDocData }));
          // nftHealth["teamA"][currentDocData.id]["initial"] =
          //   currentDocData.secondaryStats.initialStats.health;
          // nftHealth["teamA"][currentDocData.id]["initial"] =
          //   currentDocData.secondaryStats.initialStats.health;
          // console.log("currentDocData", currentDocData, currentDocData.id);
          setContinuousNFTHealth((prev: any) => ({
            ...prev,
            [currentDocData.id]: currentDocData,
          }));

          teamAIndividualHealths.push({
            id: currentDocData.id,
            // todo: set currentHealth to the one coming from firestore
            currentHealth: currentDocData.secondaryStats.currentStats.health,
            initialHealth: currentDocData.secondaryStats.initialStats.health,
          });

          if (firstHealthFetch.current || shouldJumpToLatestEvent.current) {
            if (Number(currentDocData.id) === userNFT) {
              setUserNFTHealth({
                initial: currentDocData.secondaryStats.initialStats.health,
                current: currentDocData.secondaryStats.currentStats.health,
              });
            }
            if (!currentDocData.isAlive && !isReplayBattle) {
              deadNftsRef.current = Array.from(
                new Set([...deadNftsRef.current, currentDocData.id])
              );
            }
          }

          // reset healths if the game is a past war replay
          if (isReplayBattle) {
            const currIndex = teamAIndividualHealths.length - 1;
            teamAIndividualHealths[currIndex].currentHealth =
              teamAIndividualHealths[currIndex].initialHealth;
            nftTotalHealth["teamA"].present = nftTotalHealth["teamA"].initial;
          }
        }

        for await (const doc of querySnapshotTeamB.docs) {
          const currentDocData = doc.data();
          nftSpeedList.teamB.push({
            id: Number(currentDocData.id),
            pos: currentDocData.pos,
            speed: currentDocData?.secondaryStats?.currentStats?.speed,
            name: currentDocData.name,
            isCurrentlyPerforming: currentDocData.isCurrentlyPerforming,
          });
          idsInTeam.teamB.push(currentDocData.id);
          nftTotalHealth["teamB"] = {
            present:
              nftTotalHealth.teamB.present +
              currentDocData.secondaryStats.currentStats.health,
            initial:
              nftTotalHealth.teamB.initial +
              currentDocData.secondaryStats.initialStats.health,
          };
          setContinuousNFTHealth((prev: any) => ({
            ...prev,
            [currentDocData.id]: currentDocData,
          }));
          if (walletAddress && userNFT === 0) {
            const nftId = await getLockedNFTInSimulation(walletAddress, id);
            if (nftId) {
              if (doc.data().id.toString() === Number(nftId).toString()) {
                setUserNFTInfo({
                  nftId: Number(nftId),
                  team: BATTLE_TEAMS.ORANGE,
                });
                userNFT = Number(nftId);
              }
            }
          }
          teamBIndividualHealths.push({
            id: currentDocData.id,
            currentHealth: currentDocData.secondaryStats.currentStats.health,
            initialHealth: currentDocData.secondaryStats.initialStats.health,
          });

          if (firstHealthFetch.current || shouldJumpToLatestEvent.current) {
            if (Number(currentDocData.id) === userNFT) {
              setUserNFTHealth({
                initial: currentDocData.secondaryStats.initialStats.health,
                current: currentDocData.secondaryStats.currentStats.health,
              });
            }
            if (!currentDocData.isAlive && !isReplayBattle) {
              deadNftsRef.current = Array.from(
                new Set([...deadNftsRef.current, currentDocData.id])
              );
            }
          }

          // reset healths if the game is a past war replay
          if (isReplayBattle) {
            const currIndex = teamBIndividualHealths.length - 1;
            teamBIndividualHealths[currIndex].currentHealth =
              teamBIndividualHealths[currIndex].initialHealth;
            nftTotalHealth["teamB"].present = nftTotalHealth["teamB"].initial;
          }
        }

        setNftIdsTeam(idsInTeam);
        setSpeedList(nftSpeedList);
        if (
          (firstHealthFetch.current || shouldJumpToLatestEvent.current) &&
          teamAIndividualHealths.length > 0 &&
          teamBIndividualHealths.length > 0
        ) {
          setBlueTeamHealth({
            currentTeamHealth: nftTotalHealth["teamA"].present,
            initialTeamHealth: nftTotalHealth["teamA"].initial,
            nftHealth: teamAIndividualHealths,
          });

          setOrangeTeamHealth({
            currentTeamHealth: nftTotalHealth["teamB"].present,
            initialTeamHealth: nftTotalHealth["teamB"].initial,
            nftHealth: teamBIndividualHealths,
          });
          // isOneVOneBattle.current = (teamAIndividualHealths.length === 1 && teamBIndividualHealths.length === 1);
          firstHealthFetch.current = false;
        }
        // console.log("isAllIdFetched", isAllIdFetched, nftTotalHealth);
        setAllIdFetched((prev) => {
          return prev === true ? false : true;
        });
        setLoading(false);
      };

      individualTeamsPending.current = true;
      getIndividualTeams().then(() => {
        if (snap?.eventLogs.length > 0) {
          const sn_data = snapshot.data();
          // sn_data.eventLogs = sn_data.eventLogs.slice(4,);
          setstate({
            ...sn_data,
          });
          stateRef.current = sn_data;
          // sn_data.eventLogs.forEach((d:any, id:any) => {
          //   d.logCollection.forEach((l:any) => {
          //     if(l.actionType == ACTION_TYPE.COLLATERAL_ATTACK) {
          //       alert(id);
          //     }
          //   })
          // })
          console.log("--------------------------------------");
          console.log("LENGTH: ", prevEventGroupLength.current);
          console.log(sn_data);
          individualTeamsPending.current = false;
        }
      });
      setTotalHealth(nftTotalHealth);
      setGameOver({ status: snap.isGameOver, winner: snap.winner });
      setLogCount(snap.eventLogs.length);

      // start animating battle from the index received at the beginning
      if (animStartIndexRef.current === undefined) {
        animStartIndexRef.current = snap.eventLogs.length;
      }
    });

    return () => {
      unsubscribe();
    };
  }, [isWalletConnected, isFirebaseSignedIn]);

  const [countDownTimer, setCountDownTimer] = useState<any>();

  useEffect(() => {
    const time = new Date(
      new Date(new Date().getTime() + 15000).setHours(
        new Date(new Date().getTime() + 15000).getHours()
      )
    ).toISOString();
    setCountDownTimer(time);
    return () => { };
  }, [logCount, isFirebaseSignedIn]);

  const [disableAbilities, setDisableAbilities] = useState(false);
  // console.log(playersInfo);
  useEffect(() => {
    let timeoutId: any;
    if (disableAbilities) {
      timeoutId = setTimeout(() => {
        setDisableAbilities(false);
      }, 15000);
    }
    return () => clearTimeout(timeoutId);
  }, [disableAbilities, isFirebaseSignedIn]);

  const handleAbilitiesClick = (abilityId: number) => {
    if (!disableAbilities) {
      setDisableAbilities(true);
      abilityTriggred(abilityId);
    }
  };

  const jumpToLatestBattleEvent = (e: any) => {
    e.preventDefault();
    // should jump to latest event

    if (activeBattleDetails) {
      shouldJumpToLatestEvent.current = true;
      showJumpToLatestEvent.current = false;
    }
  };

  const navigate = useNavigate();

  const returnItems = (item: any) => {
    const component = Array.from({ length: Number(item.amount) }, (_, i) => {
      const name = detailInfoParticipants?.items?.[Number(item.id)]?.name;
      const nameItem = name?.split(" ").length > 1 ? name?.replace(/\s/g, "_") : name;
      const tooltipId = `${nameItem}_${i}_warPage`;
      return (
        <>
          <div
            key={`equip_${i}`}
            id={`${tooltipId}`}
            className={`loadoutBackgroundBorder cursor-pointer warScreenItems ${Number(item.id) > 0 && userNFTHealth?.current > 0
              ? "setColor"
              : "disable"
              }`}
          >
            <ImageLoader
              className="p-1"
              src={
                Number(item.id) > 0
                  ? `${process.env.REACT_APP_IMAGE_BASE_URL
                  }/items_images/${Number(item.id)}.png`
                  : ITEM
              }
              alt="items"
            />
          </div>

          {Number(item.id) > 0 && (
            <ItemTooltip
              key={`itemtooltip_${Math.random() * 100000}`}
              details={detailInfoParticipants?.items?.[Number(item.id)]}
              tooltipId={tooltipId}
            />
          )}
        </>
      );
    });
    return component;
  };

  const [userRemainingAbilities, setUserRemainingAbilities] = useState<any>([]);
  const [isAbilitiesInPending, setAbilitiesInPending] = useState(false);

  const abilityTriggred = async (abilityId: number) => {
    const team =
      participatedInTeam.team === "teamA"
        ? "Team A"
        : participatedInTeam.team === "teamB" && "Team B";

    console.log("ABILITY TRIGGER", {
      simulationId: id?.toString(),
      triggeredBy: walletAddress,
      abilityId: abilityId,
      nftId: participatedInTeam.nftId.toString(),
      team: team,
    });
    const name = detailInfoParticipants?.abilities?.[Number(abilityId)]?.name;
    // triggerSneakmove
    const payload = {
      simulationId: id?.toString(),
      userAddress: walletAddress,
      abilityId: abilityId,
      nftId: participatedInTeam.nftId.toString(),
      team: team,
    };

    triggerSneakmove(payload)
      .then((response) => {
        if (response.status === 200) {
          toasterService.set({
            title: "SNEAKMOVES WILL BE TRIGGERED SOON!",
            message: `You have chosen to use the ${name ?? ""} Sneak Move.
            `,
            type: "success",
            toastSize: "toastLarge",
          });
        }
      })
      .catch(() => {
        toasterService.set({
          title: "SNEAKMOVES WARNING",
          message: `Max count reached!`,
          type: "error",
          toastSize: "toastLarge",
        });
      });
  };

  interface IIncreaseArrayByCountAbilities {
    abilityId: number;
    count: number;
    type: string;
  }

  function increaseArrayByCountAbilities(
    abilities: IIncreaseArrayByCountAbilities[]
  ): IIncreaseArrayByCountAbilities[] {
    const sortedArray = abilities.sort((a, b) => b.count - a.count);
    const newArray: IIncreaseArrayByCountAbilities[] = [];
    sortedArray.forEach((obj) => {
      for (let i = 0; i < obj.count; i++) {
        newArray.push({
          abilityId: obj.abilityId,
          count: 1,
          type: obj.type,
        });
      }
    });
    return newArray;
  }

  interface IIncreaseArrayByCountItems {
    id: number;
    amount: number | string;
    owner: string;
  }

  function increaseArrayByCountItems(
    items: IIncreaseArrayByCountItems[]
  ): IIncreaseArrayByCountItems[] {
    const sortedArray = items.sort((a, b) => Number(b.amount) - Number(a.amount));
    const newArray: IIncreaseArrayByCountItems[] = [];
    sortedArray.forEach((obj) => {
      for (let i = 0; i < Number(obj.amount); i++) {
        newArray.push({
          amount: "0x1",
          id: obj.id,
          owner: obj.owner,
        });
      }
    });
    return newArray;
  }

  const fetchUserSneakmoves = () => {
    const payload = {
      userAddress: walletAddress as string,
      simulationId: id?.toString() as string,
    };
    getUserSneakmove(payload).then((res) => {
      if (res.status === 200) {
        const abilityList = res.data.data.abilityList;
        const usableAbility = abilityList.filter(
          (move: any) =>
            move.type === "PASSIVE" ||
            move.status === "USABLE" ||
            move.status === "PENDING"
        );
        const userAbilities =
          usableAbility.length > 3 ? usableAbility.slice(0, 3) : usableAbility;
        setUserRemainingAbilities(userAbilities);
        setAbilitiesInPending(
          userAbilities.some((ability: any) => ability.status === "PENDING")
        );
      }
    });
  };

  useEffect(() => {
    if (isWalletConnected) {
      const interval = setInterval(fetchUserSneakmoves, 3000);
      return () => clearInterval(interval);
    }
  }, [disableAbilities]);
  useEffect(() => {
    checkTokenValidity();
  }, [userRemainingAbilities]);
  const { setNftData, setCurrentBatchIndex, setRentalIndex } = useNFTDetailGlobalContext();
  const { reRenderCollectionToggler, setRerenderCollectionToggler } = useNFTInformation();

  console.log(
    "condition satisfied",
    // isGameOver &&
    //   continuousNFTsHealth &&
    //   Object.keys(continuousNFTsHealth).length > 0 &&
    //   getLootboxId.lootBoxId > 0 &&
    //   allAnimationsDone &&
    //   // participatedInTeam.nftId > 0 &&
    //   nftIdsTeam.teamA.length > 0 &&
    //   nftIdsTeam.teamB.length > 0 &&
    //   playersInfo.teamA.length > 0 &&
    //   playersInfo.teamB.length > 0,
    "isGameOver=>",
    isGameOver.status,
    "length=>",
    Object.keys(continuousNFTsHealth).length,
    "lootbox id",
    getLootboxId.lootBoxId,
    "allAnimationsDone",
    allAnimationsDone,
    // participatedInTeam.nftId > 0 &&
    "nftIdsTeam.teamA",
    nftIdsTeam.teamA.length,
    "nftIdsTeam.teamB",
    nftIdsTeam.teamB.length,
    "playersInfo.teamA",
    playersInfo.teamA.length,
    "playersInfo.teamA",
    playersInfo.teamB.length
  );

  return (
    <>
      {state.crashStatus && <ModalCrash />}
      {state.crashStatus ? (
        <ModalCrash />
      ) : loading || state.eventLogs.length < 1 || !battleStarted.current ? (
        <Loading warId={id} />
      ) : isGameOver.status &&
        continuousNFTsHealth &&
        Object.keys(continuousNFTsHealth).length > 0 &&
        // getLootboxId.lootBoxId > 0 && //uncomment this is production
        allAnimationsDone &&
        // participatedInTeam.nftId > 0 &&
        nftIdsTeam.teamA.length > 0 &&
        nftIdsTeam.teamB.length > 0 &&
        !state.crashStatus &&
        playersInfo.teamA.length > 0 &&
        playersInfo.teamB.length > 0 ? (
        <PostGames
          nftHealthInfo={continuousNFTsHealth}
          getLootboxId={getLootboxId}
          participatedInTeam={participatedInTeam}
          isWalletParticipated={isWalletParticipated}
          extraInfo={state}
          nftIdsTeam={nftIdsTeam}
        />
      ) : (
        // : allAnimationsDone ? ( // todo
        //   <PostGame
        //     participatedInTeam={participatedInTeam}
        //     isWalletParticipated={isWalletParticipated}
        //     playersInfo={playersInfo}
        //     isGameOver={isGameOver}
        //     totalHealth={totalHealth}
        //     getLootboxId={getLootboxId}
        //     continuousNFTsHealth={continuousNFTsHealth}
        //   />
        // )
        <div className="war-screen minWidthContainer flex">
          <InactiveTabModal
            isVisible={showInactiveModal.current}
            setIsVisible={(val: boolean) => {
              showInactiveModal.current = val;
            }}
          />
          <div className="left-section">
            {showJumpToLatestEvent.current && (
              <div
                id="latestEventText"
                className="jumpToLatestEvent cursor-pointer"
                data-tooltip-content="You are lagging behind the real-time battle"
                onClick={jumpToLatestBattleEvent}
              >
                <span className="flex-center">Jump to latest event</span>
                <Tooltip
                  style={{ zIndex: 1999, maxWidth: "300px", opacity: 1 }}
                  anchorSelect="#latestEventText"
                  place="right"
                ></Tooltip>
              </div>
            )}
            <div className="top-section">
              <IoArrowBackOutline
                onClick={() => {
                  setRerenderCollectionToggler(!reRenderCollectionToggler);
                  setNftData([]);
                  setCurrentBatchIndex(0);
                  setRentalIndex(null);
                  navigate(-1);
                }}
                className="absolute opacity-50 hover:opacity-100 z-50 cursor-pointer top-1 left-5 text-4xl text-white"
              />

              <div
                className="topLeftSection z-10"
                style={
                  activeBattleDetails?.main.team?.team === BATTLE_TEAMS.BLUE
                    ? { zIndex: 100 }
                    : {}
                }
              >
                <div className="flex flex-col gap-6">
                  {playersInfo.teamA.length > 0}
                  {playersInfo.teamA.length > 0 &&
                    playersInfo.teamA.map((props: any, index: number) => {
                      const isActive = activeBattleDetails?.involvedIds.includes(
                        parseInt(playersInfo?.teamA[index]?.token_id)
                      );
                      return (
                        <div
                          id={playersInfo.teamA[index]?.token_id}
                          key={playersInfo.teamA[index]?.token_id}
                          // @ts-ignore
                          style={{ ...props }}
                          className={
                            "animate__animated  animate__slideInLeft " +
                            (activeBattleDetails?.involvedIds.includes(
                              parseInt(props.token_id)
                            )
                              ? activeBattleDetails?.main.id ===
                                parseInt(props.token_id)
                                ? "z-10"
                                : "z-9"
                              : "")
                          }
                        // onClick={() => {
                        //   animate1v1([456], [417]);
                        // }}
                        >
                          <NFTCardWithBlueProgress
                            info={playersInfo.teamA[index]}
                            healthInfo={blueTeamHealth.nftHealth.find(
                              (h) =>
                                h.id ===
                                parseInt(props?.token_id, 16)
                            )}
                            nftExtraInfo={
                              continuousNFTsHealth?.[
                              Number(
                                playersInfo.teamA[index]
                                  ?.token_id
                              )
                              ]
                            }
                            isActiveGroup={activeNftGroup.includes(
                              parseInt(props.token_id)
                            )}
                            activeBattleState={
                              isActive ? activeBattleState : undefined
                            }
                            setActiveBattleState={
                              isActive
                                ? setActiveBattleState
                                : undefined
                            }
                            activeBattleDetails={
                              isActive
                                ? activeBattleDetails
                                : undefined
                            }
                            dead={deadNftsRef.current.includes(
                              parseInt(props?.token_id, 16)
                            )}
                            isBattlePassive={isBattlePassive}
                            shouldComeToDefaultPos={
                              !(
                                isNextAttackCollateral &&
                                activeBattleDetails.main.id ==
                                parseInt(props.token_id)
                              ) &&
                              !isNextNarrationDead &&
                              activeBattleDetails?.actionType !==
                              ACTION_TYPE.GUN_JAMMED
                            }
                            shouldComeToBattleField={
                              shouldComeToBattleField
                            }
                            isOneVOneBattle={isOneVOneBattle.current}
                            effects={currNftEffects.filter(
                              (effect) =>
                                effect.id ===
                                parseInt(props?.token_id, 16)
                            )}
                            setFullScreenEffect={setFullScreenEffect}
                            fullScreenEffect={fullScreenEffect}
                            fullScreenLoadFlag={fullScreenLoadFlag}
                            fullScreenCompleteFlag={
                              fullScreenCompleteFlag
                            }
                            isDeadAnimation={isDeadAnimation.current}
                          />
                        </div>
                      );
                    })}
                </div>
              </div>

              <div className="topRightSection z-10">
                <div className="flex flex-col gap-6">
                  {playersInfo.teamB.length > 0}
                  {playersInfo.teamB.length > 0 &&
                    playersInfo.teamB.map((props: any, index: number) => {
                      const isActive = activeBattleDetails?.involvedIds.includes(
                        parseInt(playersInfo?.teamB[index]?.token_id)
                      );
                      return (
                        <div
                          id={playersInfo.teamB[index]?.token_id}
                          key={playersInfo.teamB[index]?.token_id}
                          // @ts-ignore
                          style={{ ...props }}
                          // onClick={() => {
                          //   animate1v1([456], [417]);
                          // }}
                          className={
                            "animate__animated  animate__slideInRight " +
                            (activeBattleDetails?.involvedIds.includes(
                              parseInt(props.token_id)
                            )
                              ? activeBattleDetails?.main.id ===
                                parseInt(props.token_id)
                                ? "z-10"
                                : "z-9"
                              : "")
                          }
                        >
                          <NFTCardWithOrangeProgress
                            info={playersInfo.teamB[index]}
                            healthInfo={orangeTeamHealth?.nftHealth.find(
                              (h) =>
                                h.id ===
                                parseInt(props?.token_id, 16)
                            )}
                            nftExtraInfo={
                              continuousNFTsHealth?.[
                              Number(
                                playersInfo.teamB[index]
                                  ?.token_id
                              )
                              ]
                            }
                            isActiveGroup={activeNftGroup.includes(
                              parseInt(props.token_id)
                            )}
                            activeBattleState={
                              isActive ? activeBattleState : undefined
                            }
                            setActiveBattleState={
                              isActive
                                ? setActiveBattleState
                                : undefined
                            }
                            activeBattleDetails={
                              isActive
                                ? activeBattleDetails
                                : undefined
                            }
                            dead={deadNftsRef.current.includes(
                              parseInt(props?.token_id, 16)
                            )}
                            isBattlePassive={isBattlePassive}
                            shouldComeToDefaultPos={
                              !(
                                isNextAttackCollateral &&
                                activeBattleDetails.main.id ==
                                parseInt(props.token_id)
                              ) &&
                              !isNextNarrationDead &&
                              activeBattleDetails?.actionType !==
                              ACTION_TYPE.GUN_JAMMED
                            }
                            shouldComeToBattleField={
                              shouldComeToBattleField
                            }
                            isOneVOneBattle={isOneVOneBattle.current}
                            effects={currNftEffects.filter(
                              (effect) =>
                                effect.id ===
                                parseInt(props?.token_id, 16)
                            )}
                            setFullScreenEffect={setFullScreenEffect}
                            fullScreenEffect={fullScreenEffect}
                            fullScreenLoadFlag={fullScreenLoadFlag}
                            fullScreenCompleteFlag={
                              fullScreenCompleteFlag
                            }
                            isDeadAnimation={isDeadAnimation.current}
                          />
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className="topMiddleSection h-full">
                <div className="w-full h-full flex items-center justify-center gap-48">
                  {fullScreenEffect.show && (
                    <GifLoader
                      className={"war-attack-special"}
                      style={fullScreenEffect.styles}
                      GifImg={`${fullScreenEffect.image}`}
                      delay={0}
                      repeatCount={1}
                      onLoad={() => setFullScreenLoadFlag((prev) => !prev)}
                      onComplete={() =>
                        setFullScreenCompleteFlag((prev) => !prev)
                      }
                    />
                  )}
                  {activeBattleDetails && (
                    <AnimatedEventName
                      text={
                        battleAnimLogs[activeBattleDetails.battleIndex]
                          .eventName
                      }
                      show={
                        isNewEventGroup &&
                        battleAnimLogs[
                          activeBattleDetails.battleIndex
                        ].eventName
                          .toUpperCase()
                          .split(" ")
                          .join("_") !== activeBattleDetails.actionType
                      }
                    />
                  )}
                </div>
              </div>
              <div className="topTopSection">
                <VolumeHandler />
                <div className="flex flex-col">
                  <div className="blueProgress mt-1 -mr-8">
                    <div
                      style={{
                        width: `${blueTeamHealth
                          ? calculateHealth(
                            blueTeamHealth?.currentTeamHealth,
                            blueTeamHealth?.initialTeamHealth
                          )
                          : 100
                          }%`,
                      }}
                    ></div>
                  </div>
                  <div className="text-xl text-white text-right font-semibold">
                    Team A
                  </div>
                </div>
                <div className="cycleWrapper ">
                  <div className="cycleSection mx-auto">
                    <div className="flex flex-col py-2">
                      {/* <p className="text-md font-bold warPageTimer">
                        <p className="text-sm text-white">Next Event In</p>
                        <Countdown
                          targetDate={countDownTimer}
                          disableTypes={true}
                          renderer={warStartsIn}
                        />
                      </p> */}
                      <div className="flex items-center justify-center gap-0.5 text-base text-white font-bold">
                        <p className="text-base text-white font-bold">
                          Round {state.currentRound}
                        </p>
                      </div>
                      <div className="flex items-center justify-center gap-0.5 text-base text-gray text-center w-full">
                        <p className="text-gray text-center font-bold">
                          Turn {state?.currentTurn}
                        </p>
                        <div className="watchSeparator"></div>
                        <div className="flex justify-center items-center gap-0.5 text-gray text-center">
                          <BsEyeFill className="text-sm" />
                          <span className="font-bold">
                            {stats?.maxCount}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col items-start">
                  <div className="orangeProgress mt-1 -ml-10">
                    <div
                      style={{
                        width: `${orangeTeamHealth
                          ? calculateHealth(
                            orangeTeamHealth?.currentTeamHealth,
                            orangeTeamHealth?.initialTeamHealth
                          )
                          : 100
                          }%`,
                      }}
                    ></div>
                  </div>{" "}
                  <div className="text-xl text-left font-semibold text-white">
                    Team B
                  </div>
                </div>
              </div>
              <div className="lowestLayer"></div>
              <div className="topBottomSection">
                {playerDetails && !isReplayBattle && (
                  // <div className="overflow-hidden ">
                  <div className="playerInfoWarScreen flex gap-24 justify-center items-end animate__animated animate__fadeInUpBig">
                    <div className="playerInfoImageAndItems w-[400px] justify-end items-center flex gap-2">
                      <div className="warScreenPlayerItems flex flex-col gap-1">
                        {participantsDetails.items.length > 0 && (
                          <>
                            {participantsDetails.items.map(
                              (item: any, index: number) => {
                                return returnItems(item);
                              }
                            )}
                          </>
                        )}
                      </div>
                      <div className="playerInfoNFTDetails flex  gap-2">
                        <NFTCard
                          borderType="nftBorderHistoryAndBattle"
                          cardType="nftCardImageHistoryAndBattle"
                          imageId={playerDetails.token_id}
                          width="70px"
                          height="90px"
                          dead={deadNftsRef.current.includes(
                            parseInt(playerDetails.token_id)
                          )}
                        />
                        <div className="flex flex-col gap-2">
                          <p className="playerNFTName">
                            {playerDetails.token_name}
                          </p>
                          <p className="playerNFTHealth">
                            <div className="w-[163px] h-[20px] bg-gray-200 dark:bg-gray-700">
                              <div
                                className="bg-green-600 animateBar h-[20px] whitespace-nowrap  text-blue-100 text-center p-1 leading-none"
                                style={{
                                  width:
                                    (userNFTHealth?.current /
                                      userNFTHealth?.initial) *
                                    100 +
                                    "%",
                                }}
                              >
                                {userNFTHealth?.current.toFixed(0)}/
                                {userNFTHealth?.initial.toFixed(0)}
                              </div>
                            </div>
                          </p>
                          {continuousNFTsHealth?.[
                            Number(playerDetails.token_id)
                          ]?.effects?.length > 0 ? (
                            <div className="flex items-center gap-1">
                              {
                                <>
                                  {continuousNFTsHealth?.[
                                    Number(
                                      playerDetails.token_id
                                    )
                                  ].effects.map(
                                    (
                                      effect: string,
                                      index: number
                                    ) => {
                                      return (
                                        <>
                                          <div
                                            id={`${index}_${effect}`}
                                            className="effectWarScreen cursor-pointer"
                                            data-tooltip-content={effect?.toUpperCase()}
                                          >
                                            <ImageLoader
                                              className="p-1"
                                              alt="effect"
                                              width="25px"
                                              src={
                                                EFFECTS_IMG?.[
                                                effect.toUpperCase()
                                                ]
                                              }
                                            />
                                          </div>
                                          <Tooltip
                                            style={{
                                              zIndex: 999,
                                              maxWidth:
                                                "300px",
                                              opacity: 1,
                                            }}
                                            anchorSelect={`#${index}_${effect}`}
                                          />
                                        </>
                                      );
                                    }
                                  )}
                                </>
                              }
                            </div>
                          ) : (
                            <div className="flex items-center mt-2 gap-2">
                              <p className="noBuffDebuff">
                                No Buffs / Debuffs
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      className={`playerDefaultPrimaryWeapons w-[150px] flex items-end gap-2 ${userNFTHealth?.current <=
                        0 && "disable"}`}
                    >
                      {participantsDetails.equipments.length > 0 &&
                        Object.keys(
                          continuousNFTsHealth?.[
                          Number(playerDetails?.token_id)
                          ]
                        ).length > 0 &&
                        continuousNFTsHealth?.[Number(playerDetails?.token_id)]
                          .equipment[0].currentDuraAmmo > 0 ? (
                        <div className="flex flex-col gap-1">
                          <p className="duraammoWarPage whitespace-nowrap">
                            <span className="duraammoPresent">
                              {Number(
                                continuousNFTsHealth?.[
                                  Number(playerDetails?.token_id)
                                ].equipment[0].currentDuraAmmo
                              ).toFixed(0)}
                            </span>
                            <span className="duraammoInitial">
                              {" "}
                              /{" "}
                              {Number(
                                continuousNFTsHealth?.[
                                  Number(playerDetails?.token_id)
                                ].equipment[0].totalDuraAmmo
                              ).toFixed(0)}
                            </span>
                          </p>
                          <div className="warPrimaryWeapons cursor-pointer loadoutBackgroundBorder flex justify-center items-center ">
                            {participantsDetails.equipments.map(
                              (equipment: any, index: number) => {
                                const name =
                                  detailInfoParticipants
                                    .equipments?.[
                                    Number(equipment.id)
                                  ]?.name;
                                const equipmentName =
                                  name?.split(" ").length > 1
                                    ? name?.replace(/\s/g, "_")
                                    : name;
                                const tooltipId = `${equipmentName}_${index}_warPage`;
                                return (
                                  <div id={`${tooltipId}`}>
                                    <ImageLoader
                                      key={`equipment_${index}`}
                                      src={`${process.env
                                        .REACT_APP_IMAGE_BASE_URL
                                        }/primary_equipment_images/${Number(
                                          equipment.id
                                        )}.png`}
                                      alt="primary weapons"
                                      className={`setColor blurImage p-2 ${detailInfoParticipants
                                        .equipments?.[
                                        Number(
                                          equipment.id
                                        )
                                      ]?.name
                                        }`}
                                    />
                                    <WeaponTooltip
                                      tooltipId={tooltipId}
                                      details={
                                        detailInfoParticipants
                                          ?.equipments?.[
                                        Number(
                                          equipment.id
                                        )
                                        ]
                                      }
                                    />
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>
                      ) : (
                        <div className="loadoutBackgroundBorder warPrimaryWeaponsNotTaken flex justify-center items-center ">
                          <ImageLoader
                            src={WEAPON}
                            alt="primary weapons "
                            className={` p-2 blurImage`}
                          />
                        </div>
                      )}

                      <div
                        className={`warDefaultWeapons  flex justify-center items-center loadoutBackgroundBorder ${userNFTHealth?.current <=
                          0 && "disable"}`}
                        style={{
                          width:
                            participantsDetails.equipments.length > 0
                              ? "48px"
                              : "64px",
                          height:
                            participantsDetails.equipments.length > 0
                              ? "48px"
                              : "64px",
                        }}
                      >
                        <ImageLoader
                          className="p-2"
                          src={defaultWeapon}
                          alt="default image"
                        />
                      </div>
                    </div>
                    <div className="playerInfoSneakMoves w-[200px] flex gap-2">
                      {isTokenInvalid &&
                        Object.keys(
                          continuousNFTsHealth?.[
                          Number(playerDetails?.token_id)
                          ]
                        )?.length > 0 &&
                        userNFTHealth?.current > 0 ? (
                        <div
                          id="signingMessage"
                          data-tooltip-content="You need to authorize yourself before triggering active sneak moves."
                          className="highlightBg "
                        >
                          <PrimaryButton
                            onClick={() => {
                              handleSigning();
                            }}
                            className="p-0 animate__animated  animate__infinite	infinite animate__pulse"
                            buttonName="Click to Sign"
                          />

                          <Tooltip
                            style={{
                              zIndex: 999,
                              opacity: 1,
                            }}
                            anchorSelect="#signingMessage"
                          />
                        </div>
                      ) : (
                        <>
                          {Object.keys(
                            continuousNFTsHealth?.[
                            Number(playerDetails?.token_id)
                            ]
                          )?.length > 0 && (
                              <>
                                {userRemainingAbilities.map(
                                  (abilities: any, index: number) => {
                                    const inCooldown =
                                      continuousNFTsHealth?.[
                                        Number(
                                          playerDetails?.token_id
                                        )
                                      ]?.abilityDetails
                                        ?.inCooldown;
                                    const isReady =
                                      continuousNFTsHealth?.[
                                        Number(
                                          playerDetails?.token_id
                                        )
                                      ]?.abilityDetails?.isReady;
                                    const name =
                                      detailInfoParticipants
                                        ?.abilities?.[
                                        Number(
                                          abilities.abilityId
                                        )
                                      ]?.name;
                                    const sneakMoveName =
                                      name?.split(" ").length > 1
                                        ? name?.replace(
                                          /\s/g,
                                          "_"
                                        )
                                        : name;
                                    const tooltipId = `${sneakMoveName}_${index}_warPage`;
                                    return (
                                      <div
                                        className={`animate__animated animate__fadeIn${disableAbilities ||
                                          Number(
                                            abilities.abilityId
                                          ) <= 0 ||
                                          userNFTHealth?.current <=
                                          0 ||
                                          inCooldown ||
                                          abilities.status ===
                                          "PENDING" ||
                                          !isReady ||
                                          isAbilitiesInPending
                                          ? "disable"
                                          : "setColor cursor-pointer"
                                          }
                                    ${abilities.status === "PENDING" && "makeInActive"}
                                    
                                    `}
                                        key={`userLoadout_${index}`}
                                      >
                                        <div
                                          id={`${tooltipId}`}
                                          className={`sneakMoveDetails relative ${disableAbilities &&
                                            "disable"}  ${abilities?.type ===
                                              "ACTIVE"
                                              ? "sneakMoveBgBorder"
                                              : abilities?.type ===
                                                "PASSIVE"
                                                ? "loadoutBackgroundActiveBorderSneakMove"
                                                : "loadoutBackgroundBorder"
                                            }`}
                                          onClick={() => {
                                            if (
                                              abilities?.type?.toLowerCase() ===
                                              "active" &&
                                              abilities.status ===
                                              "USABLE"
                                            ) {
                                              console.log(
                                                "clicked abilities",
                                                abilities
                                              );
                                              handleAbilitiesClick(
                                                Number(
                                                  abilities.abilityId
                                                )
                                              );
                                              PLAY_SNEAK_MOVE_HOVER();
                                            }
                                          }}
                                        >
                                          {abilities.type ===
                                            "PASSIVE" && (
                                              <div className="flex items-center justify-center passiveSneakMoveOverlay  uppercase">
                                                <span className=" text-center">
                                                  used
                                                </span>
                                              </div>
                                            )}

                                          {abilities.status ===
                                            "PENDING" &&
                                            userNFTHealth?.current >
                                            0 && (
                                              <>
                                                <div
                                                  id="willbetriggered"
                                                  data-tooltip-content="Will be released soon."
                                                  className="cursor-pointer animate__animated  animate__fadeInUp sneakMoveOverlayStatus flex items-center justify-center warPageSkillsStateBorder  uppercase"
                                                >
                                                  <span className="ml-2">
                                                    <LoadingMove />
                                                  </span>
                                                </div>
                                                <Tooltip
                                                  style={{
                                                    zIndex: 999,
                                                    maxWidth:
                                                      "300px",
                                                    opacity: 1,
                                                  }}
                                                  anchorSelect="#willbetriggered"
                                                />
                                              </>
                                            )}
                                          <ImageLoader
                                            className={`p-3 ${abilities?.type ===
                                              "active"
                                              ? "cursor-pointer"
                                              : ""
                                              }  ${disableAbilities
                                                ? "disable"
                                                : "setColor"
                                              }`}
                                            src={`${process.env
                                              .REACT_APP_IMAGE_BASE_URL
                                              }/sneak_moves_images/${Number(
                                                abilities.abilityId
                                              )}.png`}
                                            alt="sneak moves"
                                          />
                                        </div>

                                        {Number(
                                          abilities.abilityId
                                        ) > 0 && (
                                            <SneakMoves
                                              key={`sneakmoves_${Math.random() *
                                                100000}`}
                                              details={
                                                detailInfoParticipants
                                                  ?.abilities?.[
                                                Number(
                                                  abilities.abilityId
                                                )
                                                ]
                                              }
                                              tooltipId={
                                                tooltipId
                                              }
                                              place="left"
                                            />
                                          )}
                                      </div>
                                    );
                                  }
                                )}
                                {Array.from(
                                  {
                                    length:
                                      3 -
                                      userRemainingAbilities.length,
                                  },
                                  (_, index) => {
                                    return (
                                      <div className={`disable`}>
                                        <div
                                          key={`userLoadout_${index}`}
                                          className={`sneakMoveDetails relative  loadoutBackgroundBorder`}
                                        >
                                          <div className="relative">
                                            <div className="sneakMoveOverlay  uppercase">
                                              <span className="text-red">
                                                empty
                                              </span>
                                            </div>
                                            <ImageLoader
                                              className={`p-3 disable`}
                                              src={
                                                ABILITY
                                              }
                                              alt="sneak moves"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                              </>
                            )}
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="right-section z-20">
            <RightSection
              speedList={speedList}
              userStatsDetails={userStatsDetails}
              logs={animatedLogs}
              currentTurn={state?.currentTurn}
              playersInfo={playersInfo}
              setGameState={setGameState}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default NewWarScreen;
