import { IoTriangleSharp } from "react-icons/io5";

type IndicatorVariant = "up" | "down";

const Indicator = ({ variant }: { variant: IndicatorVariant }) => {
    return (
        <div className="performance-indicator">
            <IoTriangleSharp className={variant == "up" ? "triangle-up" : "triangle-down"} />
        </div>
    );
}

export default Indicator;