import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setMasterVolume,
  setMusicVolume,
  setEffectVolume,
} from "@redux/features/volumeSlice";
import { RootState } from "@redux/store";
interface SliderProps {
  min: number;
  max: number;
  step: number;
  value: number;
  onChange: (value: number) => void;
}

const VolumeController = () => {
  const dispatch = useDispatch();
  const { masterVolume, musicVolume, effectsVolume } = useSelector(
    (state: RootState) => state.volume
  );
  const { isWalletConnected } = useSelector((state: RootState) => state.wallet)
  const handleChangeEffect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(event.target.value);
    dispatch(setEffectVolume(newValue));
    document.documentElement.style.setProperty(
      "--slider-progress-effect",
      `${newValue}%`
    );
  };
  const handleChangeMusic = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(event.target.value);
    dispatch(setMusicVolume(newValue));
    document.documentElement.style.setProperty(
      "--slider-progress-music",
      `${newValue}%`
    );
  };
  const handleChangeMaster = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(event.target.value);
    dispatch(setMasterVolume(newValue));
    document.documentElement.style.setProperty(
      "--slider-progress-master",
      `${newValue}%`
    );
  };
  useEffect(() => {
    document.documentElement.style.setProperty(
      "--slider-progress-music",
      `${musicVolume}%`
    );
    document.documentElement.style.setProperty(
      "--slider-progress-master",
      `${masterVolume}%`
    );
    document.documentElement.style.setProperty(
      "--slider-progress-effect",
      `${effectsVolume}%`
    );
  }, []);
  const MIN = 0;
  const MAX = 100;
  const STEP = 1;
  return (
    <div style={{ left: isWalletConnected ? "250px" : "45px" }} className={`volumeControllerWrapper animate__animated  animate__fadeIn`}>
      <div className="volumeController">
        <div className="flex flex-col gap-3">
          {/* <div className="">
            <div className="volumeText flex justify-between">
              <p>Master volume</p>
              <p>{masterVolume}</p>
            </div>
            <div className="volumeIncDec masterController">
              <input
                type="range"
                className="cursor-pointer volumeSlider"
                min={MIN}
                max={MAX}
                step={STEP}
                value={masterVolume}
                onChange={handleChangeMaster}
              />
            </div>
          </div> */}
          <div className="">
            <div className="volumeText flex justify-between">
              <p>Music volume</p>
              <p>{musicVolume}</p>
            </div>
            <div className="volumeIncDec musicController">
              <input
                type="range"
                className="cursor-pointer volumeSlider"
                min={MIN}
                max={MAX}
                step={STEP}
                value={musicVolume}
                onChange={handleChangeMusic}
              />
            </div>
          </div>
          <div className="">
            <div className="volumeText flex justify-between">
              <p>Effects volume</p>
              <p>{effectsVolume}</p>
            </div>
            <div className="volumeIncDec effectController">
              <input
                type="range"
                className="cursor-pointer volumeSlider"
                min={MIN}
                max={MAX}
                step={STEP}
                value={effectsVolume}
                onChange={handleChangeEffect}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default VolumeController;
