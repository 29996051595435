import { getEquipmentsDetails } from "@api/api";
import { IGetUserAbility } from "@interfaces/ability.interface";
import { getItemDetails } from "@api/api";
import { IItems } from "@interfaces/items.interface";

export async function getItemsInformation(
  itemId: string
): Promise<Partial<IItems>> {
  const result = await getItemDetails(itemId);
  const {
    id,
    imageUrl,
    name,
    type,
    statBoost,
    description,
    rank,
  } = result.data;
  const availableInfo = {
    name,
    type,
    id,
    rank,
    statBoost,
    description,
    image: imageUrl,
  };
  return availableInfo;
}
