import { TbMedal } from "react-icons/tb";
import Image from "@misc/Image";
import Loading from "./loading";
import { DEADICON } from "@constants/images";
import { HYPERLINKS } from "@misc/links";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { Tooltip } from "react-tooltip";

const TeamTable = ({
  team,
  playerInfo,
  highestDamageDealer,
  highestHealer,
}: any) => {
  const className =
    team === "teamA" ? "teamAPGValueWrapper" : "teamBPGValueWrapper";
  const loadingColor = team === "teamA" ? "blue" : "orange";
  return (
    <div className="postGameTable">
      <div className="pgHeading flex items-center uppercase ">
        <p className="pgName flex items-center uppercase">nft details</p>
        <p className="pgHealth uppercase flex items-center justify-center">
          health
        </p>
        <p className="pgKills uppercase flex items-center justify-center">
          kills
        </p>
        <p className="pgDamageDealth uppercase flex items-center justify-center">
          dmg dealt
        </p>
        <p className="pgHealed uppercase flex items-center justify-center">
          hp healed
        </p>
      </div>
      {playerInfo?.length > 0 ? (
        <div className={className}>
          {playerInfo &&
            playerInfo.map((playerDetails: any, index: number) => {
              const nftId = playerDetails.token_id
                ? playerDetails.token_id
                : playerDetails.id;
              return (
                <div className="animate__animated  animate__fadeIn pgValues flex items-center">
                  <div className="pgNameValue flex items-center gap-2">
                    <div
                      onClick={() => {
                        window.open(
                          `${HYPERLINKS.GANGSTABET_PROFILE}/${nftId}`,
                          "_blank"
                        );
                      }}
                      className="relative cursor-pointer"
                    >
                      {playerDetails?.secondaryStats?.currentStats?.health ===
                        0 && (
                        <img
                          width="36px"
                          height="48px"
                          className={`nftCardDeadImage deadIconGame h-full absolute p-1`}
                          src={DEADICON}
                          alt="nft dead image"
                        />
                      )}
                      <Image id={nftId} width="36px" height="48px" />
                    </div>
                    <p
                      className="cursor-pointer"
                      onClick={() => {
                        window.open(
                          `${HYPERLINKS.GANGSTABET_PROFILE}/${nftId}`,
                          "_blank"
                        );
                      }}
                    >
                      {playerDetails.name}
                    </p>
                  </div>
                  <div className="pgHealthValue flex flex-col gap-1 justify-end ">
                    <p className="self-center">
                      {playerDetails?.secondaryStats?.currentStats?.health}/
                      {playerDetails?.secondaryStats?.initialStats?.health}
                    </p>
                    <div className="w-full bg-black-200 h-1.5 dark:bg-gray-700">
                      <div
                        className="bg-green-600 h-1.5  dark:bg-blue-500"
                        style={{
                          width: `${(playerDetails?.secondaryStats?.currentStats
                            ?.health /
                            playerDetails?.secondaryStats?.initialStats
                              ?.health) *
                            100}%`,
                        }}
                      ></div>
                    </div>
                  </div>
                  <p className="pgKillsValue flex items-center justify-center">
                    {playerDetails?.performanceStats?.kills}
                  </p>
                  <p
                    className={`pgDamageDealthValue  ${Number(
                      highestDamageDealer
                    ) === Number(nftId) &&
                      "pgHighestDamageDealthValue"} flex items-center justify-center`}
                  >
                    {Number(highestDamageDealer) === Number(nftId) && (
                      <TbMedal color="#E3C96E" />
                    )}

                    {playerDetails?.performanceStats?.damageDealt.toFixed(0)}
                  </p>
                  <p
                    className={`pgHealedValue ${Number(highestHealer) ===
                      Number(nftId) &&
                      "pgHighestHealedValue"} flex items-center justify-center`}
                  >
                    {Number(highestHealer) === Number(nftId) && (
                      <TbMedal color="#6EE381" />
                    )}
                    {(playerDetails?.performanceStats?.heal).toFixed(0)}
                    <AiOutlineInfoCircle
                      className="text-gray ml-3 cursor-pointer"
                      size={14}
                      id={`_heal_${Number(nftId)}`}
                    />
                    <Tooltip
                      place="left"
                      anchorSelect={`#_heal_${Number(nftId)}`}
                      style={{
                        backgroundColor: "#192228",
                        zIndex: 9999999999,
                        maxWidth: 450,
                        opacity: 1,
                      }}
                    >
                      <div className="flex flex-col">
                        <p className="text-xs">
                          Team Heal:{" "}
                          {(playerDetails?.performanceStats?.heal).toFixed(0)}
                        </p>
                        <p className="text-xs">
                          Self Heal:{" "}
                          {(playerDetails?.performanceStats?.selfHeal).toFixed(
                            0
                          )}
                        </p>
                      </div>
                    </Tooltip>
                  </p>
                </div>
              );
            })}
        </div>
      ) : (
        <div className="w-full h-[200px] flex items-center justify-center">
          <Loading widthClass="w-14" heightClass="h-14" color={loadingColor} />
        </div>
      )}
    </div>
  );
};

export default TeamTable;
