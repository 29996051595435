import { ABILITY } from "@constants/images";
import Image from "@misc/Image";
import { useEffect, useRef, useState } from "react";
import { FaArrowUp } from "react-icons/fa";
import { IBattleEvent } from "./interfaces/warScreen.interface";
import { TABS } from "@constants/images";
import ImageLoader from "@misc/ImageLoader";
import NFTCard from "@components/card/nftCard.component";
import { AiOutlineInfoCircle, AiFillLock } from "react-icons/ai";
import { excludeStatsKeys } from "@misc/removeStatsKeys";
import { statNameMapping } from "@misc/statNameMap";
import { LOADING } from "@constants/images";
import { BiChevronsDown } from "react-icons/bi";
import { useDetailGlobalContext } from "@context/detailProvider";
import EquipmentPlainCard from "@components/card/equipmentPlainCard.component";
import SneakMoves from "@components/tooltips/sneakmove.tooltip";
import SneakMoveDetails from "./sneakMoves";
import SpeedList from "./tabs/speedList";
import WarTabs from "./tabs/tab";
import Gangstabet from "./tabs/gangstabet";
import Logs from "./tabs/logs";
import ChatUI from "./components/WarChats/ChatUI";

interface IRightSectionProps {
  logs: Array<IBattleEvent>;
  userStatsDetails: any;
  currentTurn: number | null;
  speedList: any;
  playersInfo: any;
  setGameState: any;
}

const RightSection = ({
  logs,
  userStatsDetails,
  currentTurn,
  speedList,
  playersInfo,
  setGameState,
}: IRightSectionProps) => {
  // const [visible, setVisible] = useState<boolean>(false);
  // const [scrollTop, setScrollTop] = useState<number>(0);
  const [formattedLogs, setFormattedLogs] = useState<Array<any>>([]);

  console.log(playersInfo, ";players");

  const tabsRef = useRef();

  const {
    userAbilityDetails,
    getAbilityNumberCount,
  } = useDetailGlobalContext();

  // const handleScroll = (event: any) => {
  //   setScrollTop(event.currentTarget.scrollTop);
  // };

  // useEffect(() => {
  //   if (scrollTop > 10) {
  //     setVisible(true);
  //   } else {
  //     setVisible(false);
  //   }
  // }, [scrollTop]);
  const scrollableRef = useRef<any>(null);
  // useEffect(() => {
  //   if (containerRef.current) {
  //     containerRef.current.scrollTop = containerRef.current.scrollHeight;
  //   }
  // });

  // useEffect(() => {
  //   const container = containerRef.current;
  //   if (container) {
  //     const handleScroll = () => {
  //       const atBottom =
  //         container.scrollTop + container.clientHeight ===
  //         container.scrollHeight;
  //       if (atBottom) {
  //         console.log("Container at bottom");
  //       } else {
  //         console.log("Container not at bottom");
  //       }
  //       if (atBottom) {
  //         container.scrollTop = container.scrollHeight;
  //       }
  //     };
  //     container.addEventListener("scroll", handleScroll);
  //     return () => {
  //       container.removeEventListener("scroll", handleScroll);
  //     };
  //   }
  // }, []);

  const [inLogType, setInLog] = useState(0);

  useEffect(() => {
    // convert 1d warLogs to 2d warlogs array
    const refinedLogs = [];
    let index = 0;
    let counter = 0;

    while (counter < logs.length) {
      const sameEvGroupLogs = logs.filter((l) => l.eventGroupId === index);

      const fLog = {
        mains: logs[counter].mains,
        targets: logs[counter].targets,
        contextLog: logs[counter].contextLog,
        logCollection: sameEvGroupLogs,
        eventName: logs[counter].eventName,
        narrationType: logs[counter].narrationType,
      };
      // setGameState((prev: any) => ({
      //   turn: logs[counter].gameState,
      //   round: 0,
      // }));
      refinedLogs.push(fLog);
      counter += sameEvGroupLogs.length > 0 ? sameEvGroupLogs.length : 1;
      index += 1;
    }

    setFormattedLogs(refinedLogs);
  }, [logs]);

  const prevIsUserAtBottom = useRef<boolean>(false);
  const [showPresentLogBtn, setPresentLogBtn] = useState(true);

  useEffect(() => {
    const {
      scrollTop,
      scrollHeight,
      clientHeight,
      offsetHeight,
    } = scrollableRef.current;

    if (scrollTop + clientHeight + offsetHeight > scrollHeight) {
      prevIsUserAtBottom.current = true;
      setPresentLogBtn(true);
    } else {
      prevIsUserAtBottom.current = false;
      setPresentLogBtn(false);
    }

    if (prevIsUserAtBottom.current) {
      scrollableRef.current.scrollTop = scrollableRef.current.scrollHeight;
      // console.log(scrollableRef.current.scrollHeight);
    }
  }, [formattedLogs]);

  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const showButtonOnScroll = (event: any) => {
    const { scrollTop } = event.target;
    if (scrollTop > prevScrollPos) {
      // scrolling down
    } else {
      // scrolling up
      setPresentLogBtn(false);
    }
    setPrevScrollPos(scrollTop);
  };
  console.log("logs", logs);
  return (
    <div className="container mx-auto">
      <div
        className="body relative"
        onScroll={showButtonOnScroll}
        ref={scrollableRef}
      >
        {inLogType === 0 && (
          <div
            className={`logWrappers ${
              inLogType === 0
                ? "animate__animated  animate__backInLeft"
                : "animate__animated animate__backOutLeft"
            }`}
          >
            {formattedLogs?.length > 0 ? (
              <Logs formattedLogs={formattedLogs} />
            ) : (
              <div className="emptyLogState">
                {/* <ImageLoader width="100px" src={LOADING} alt="gun" /> */}
                <p>
                  snooping in the <br /> battle field
                </p>
              </div>
            )}
          </div>
        )}

        <div
          className={`${
            inLogType === 1
              ? "animate__animated  animate__backInLeft"
              : "animate__animated animate__backOutLeft"
          }`}
        >
          {inLogType === 1 && userStatsDetails && (
            <Gangstabet userStatsDetails={userStatsDetails} />
          )}
        </div>

        <div
          className={`${
            inLogType === 3
              ? "animate__animated  animate__backInLeft"
              : "animate__animated animate__backOutLeft"
          }`}
        >
          {inLogType === 3 && (
            <SpeedList
              teamASppedList={speedList.teamA.sort(
                (a: any, b: any) => a.pos - b.pos
              )}
              teamBSppedList={speedList.teamB.sort(
                (a: any, b: any) => a.pos - b.pos
              )}
              teamAInfo={
                playersInfo.teamA
                  ? playersInfo.teamA.reduce((acc: any, obj: any) => {
                      acc[Number(obj.token_id)] = obj;
                      return acc;
                    }, {})
                  : {}
              }
              teamBInfo={
                playersInfo.teamB
                  ? playersInfo.teamB.reduce((acc: any, obj: any) => {
                      acc[Number(obj.token_id)] = obj;
                      return acc;
                    }, {})
                  : {}
              }
            />
          )}
        </div>

        <div
          className={`${
            inLogType === 4
              ? "animate__animated  animate__backInLeft"
              : "animate__animated animate__backOutLeft"
          }`}
        >
          {inLogType === 4 && userStatsDetails && (
            <SneakMoveDetails userStatsDetails={userStatsDetails} />
          )}
        </div>

        <div
          className={`${
            inLogType === 5
              ? "animate__animated  animate__backInUp"
              : "animate__animated animate__backOutDown"
          }`}
        >
          {inLogType === 5 && (
            <ChatUI
              playersInfo={playersInfo}
              userStatsDetails={userStatsDetails}
            />
          )}
        </div>

        <WarTabs
          setInLog={setInLog}
          inLogType={inLogType}
          userStatsDetails={userStatsDetails}
        />
        <span
          style={{ display: !showPresentLogBtn ? "inline" : "none" }}
          className="backtoTop"
          onClick={() => {
            setPresentLogBtn(true);
            scrollableRef.current.scrollTop =
              scrollableRef.current.scrollHeight;
          }}
        >
          {inLogType === 0 && (
            <span className="flex-center gap-3 px-4 py-1 cursor-pointer">
              New event <FaArrowUp style={{ rotate: "180deg" }} />
            </span>
          )}
        </span>
        {/* </div> */}
        {inLogType === 0 && (
          <>
            <div className="overlay-log-top"></div>
            <div className="overlay-log-bottom"></div>
          </>
        )}
      </div>
    </div>
  );
};

export default RightSection;
