import { IInterval, IStats } from "@pages/leaderboard/interfaces/leaderboard.interface";
import axios from "axios";
import axiosRetry from "axios-retry";

export let baseURL = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        "Content-Type": "application/json",
    },
});
let baseURLWithoutSigning = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        "Content-Type": "application/json",
    },
});
let imageBaseURL = axios.create({
    baseURL: process.env.REACT_APP_IMAGE_BASE_URL,
});
let gangstabetURL = axios.create({
    baseURL: process.env.REACT_APP_GANGSTABET_URL,
});

// Configure retry options
axiosRetry(baseURL, {
    retries: 3, // Maximum number of retry attempts
    retryDelay: (retryCount) => retryCount * 1000, // Delay between retries (in milliseconds)
    retryCondition: (error: any) => {
        // Retry only for specific error codes or conditions
        return Promise.resolve(
            axiosRetry.isNetworkError(error) || (error.response && error.response.status >= 500)
        );
    },
});

// baseURL.interceptors.request.use(async (config: any) => {
//   const token = localStorage.getItem("token");
//   if (token) {
//     try {
//       const response = await axios.post(
//         process.env.REACT_APP_BASE_URL + "/auth/verifyToken",
//         { token }
//       );
//       console.log("response from", response);
//       if (response.data.publicKey) {
//         config.headers = config.headers || {};
//         config.headers.Authorization = `Bearer ${token}`;
//         return config;
//       }
//     } catch (error) {
//       console.log("Error validating token:", error);
//     }
//   }
//   console.log("response --->");
//   // Regenerate the token
// });

export async function verifyToken(payload: any) {
    return axios.post(`${process.env.REACT_APP_BASE_URL}/auth/verifySignature/`, payload);
}

export async function verifyTokenValidity(token: any) {
    return axios.post(`${process.env.REACT_APP_BASE_URL}/auth/verifyToken/`, token);
}

export async function getEquipmentsDetails(equipmentId: string | number) {
    return baseURLWithoutSigning.get(
        `/gangwar/primary_equipment/${Number(equipmentId)}` //.json before push
    );
}

export async function getItemDetails(itemId: string | number) {
    return imageBaseURL.get(`/item/${Number(itemId)}`);
}

export async function getNFTDetails(nftId: string) {
    return gangstabetURL.get(`/0x${Number(nftId).toString(16)}`);
}

export async function getDefaultEquipment(nftId: string) {
    return baseURLWithoutSigning.get(`/gangwar/get_default_equipment/${Number(nftId)}`);
}

export async function getAbilityInformation(id: number) {
    return imageBaseURL.get(`/sm/${Number(id)}`);
}

export async function getLootBoxDetails(id: number) {
    return imageBaseURL.get(`/lc/${Number(id)}`);
}

export async function getSkillsStats(id: number) {
    return baseURLWithoutSigning.post(`/gangwar/get_stats/`, {
        nftId: id,
    });
}
export async function sendSneakMoveAddTx(tx: string) {
    return baseURLWithoutSigning.post(
        `/gangwar/sneakmove/add 
  `,
        {
            txHash: tx,
        }
    );
}

export async function triggerSneakmove(payload: any) {
    return baseURL.post(`/gangwar/sneakmove/trigger`, payload);
}

export async function getUserSneakmove(payload: { userAddress: string; simulationId: string }) {
    return baseURLWithoutSigning.post(
        `/gangwar/sneakmove/get
  `,
        payload
    );
}

export async function getLeaderboardData(
    stats: IStats,
    interval: string,
    timestamp: number,
    count: number
) {
    const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/gangwar/leaderboard/${stats}/${interval}/${timestamp}/${count}`
    );
    return data;
}
export async function getUpComingWars() {
    return baseURLWithoutSigning.get(`/gangwar/get_scheduled_wars`);
}
// export async function getUpComingWars() {
//   return axios.get(
//     `https://api.gangstaverse.co/api/gangwar/get_scheduled_wars`
//   );
// }

// api for the rental and gangstaBet collections

export async function getAllOwnedNFT(address: string, pageNumber: number) {
    return baseURLWithoutSigning.get(`/gangwar/rental/getAllNfts/${address}/${pageNumber}`);
}

export async function getAllRentedNFT(address: string, pageNumber: number) {
    return baseURLWithoutSigning.get(
        `gangwar/rental/getAddressActiveRentals/${address}/${pageNumber}`
    );
}
