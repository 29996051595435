import NORMAL_CLICK from "@assets/audio/clickSound.mp3";
import CLOSE_SOUND from "@assets/audio/closeSound.mp3";
import INVENTORY_CLICK from "@assets/audio/inventoryClick.mp3";
import CLAIM_SOUND from "@assets/audio/claimSound.mp3";
import SNEAK_MOVE_HOVER from "@assets/audio/ability_hover.mp3";

export const GENERIC_BG_MUSIC = "BG_MUSIC/generic_bgm.mp3";
export const BATTLE_BG_MUSIC = "BG_MUSIC/warscreen_bgm.mp3";
export const VICTORY_SOUND = "WIN_LOSE/victory.mp3";
export const LOSE_SOUND = "WIN_LOSE/lose.mp3";
export const DRAW_SOUND = "WIN_LOSE/draw.mp3";

const SOUNDS = {
  CLOSE_SOUND: new Audio(CLOSE_SOUND),
  NORMAL_CLICK: new Audio(NORMAL_CLICK),
  INVENTORY_CLICK: new Audio(INVENTORY_CLICK),
  CLAIM_SOUND: new Audio(CLAIM_SOUND),
  SNEAK_MOVE_HOVER: new Audio(SNEAK_MOVE_HOVER),
};

const PLAY_CLOSE_SOUND = () => SOUNDS.CLOSE_SOUND.play();
const PLAY_NORMAL_CLICK = () => {
  SOUNDS.NORMAL_CLICK.volume = 0.15;
  SOUNDS.NORMAL_CLICK.play();
};
const PLAY_INVENTORY_CLICK = () => SOUNDS.INVENTORY_CLICK.play();

const PLAY_CLAIM_SOUND = () => {
  SOUNDS.CLAIM_SOUND.play();
};

const PLAY_SNEAK_MOVE_HOVER = () => {
  SOUNDS.SNEAK_MOVE_HOVER.volume = 0.5;
  SOUNDS.SNEAK_MOVE_HOVER.play();
};

export {
  PLAY_CLOSE_SOUND,
  PLAY_NORMAL_CLICK,
  PLAY_INVENTORY_CLICK,
  PLAY_CLAIM_SOUND,
  PLAY_SNEAK_MOVE_HOVER,
};
