import NFTCard from "@components/card/nftCard.component";
import { AiOutlineInfoCircle } from "react-icons/ai";
import LoadoutDetails from "./loadoutDetails.modal";
import { useState } from "react";
import GameLoadoutLobby from "./gameLoadoutLobby.component";
import { HYPERLINKS } from "@misc/links";

const ParticipantLoddyLoadout = ({ participantsDetails }: any) => {
    const [isVisible, setIsVisible] = useState(false);
    return (
        <div className="otherParticipantLoadoutForGame">
            <NFTCard
                borderType="nftBorderHistoryAndBattle"
                cardType="nftCardImageHistoryAndBattle"
                height="90px"
                width="70px"
                nftImage={participantsDetails?.nftDetails?.nftImage}
                onClick={() =>
                    window.open(
                        `${HYPERLINKS.GANGSTABET_PROFILE}/${participantsDetails?.nftDetails.nftId}`,
                        "_blank"
                    )
                }
            />
            <div className="w-full flex flex-col gap-2">
                <div className="flex w-full justify-between ">
                    <div className="gbDetails flex flex-col">
                        <h2
                            onClick={() =>
                                window.open(
                                    `${HYPERLINKS.GANGSTABET_PROFILE}/${participantsDetails?.nftDetails.nftId}`,
                                    "_blank"
                                )
                            }
                            className="gbName cursor-pointer"
                        >
                            {" "}
                            {participantsDetails?.nftDetails?.nftName}
                        </h2>
                        <div className="flex gap-2">
                            <p className="isGangster">
                                {participantsDetails?.nftDetails?.characterType}
                            </p>
                            <p className="gbType">{participantsDetails?.nftDetails?.classType}</p>
                            <p className="gbType">
                                Level {participantsDetails?.nftDetails?.levelNumber}
                            </p>
                        </div>
                    </div>
                    <AiOutlineInfoCircle
                        className="cursor-pointer"
                        size={20}
                        color="white"
                        onClick={() => {
                            setIsVisible(true);
                        }}
                    />
                </div>
                <GameLoadoutLobby participantsDetails={participantsDetails} />
            </div>
            <LoadoutDetails
                isVisible={isVisible}
                participantsDetails={participantsDetails}
                setIsVisible={setIsVisible}
            />
        </div>
    );
};
export default ParticipantLoddyLoadout;
