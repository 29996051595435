import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";


interface ILoadingState {
isTransactionLoading:boolean
}

const initialState: ILoadingState = {
    isTransactionLoading:false
};

export const loadingSlice = createSlice({
  name: "loading",
  initialState,
  reducers: {
    
    setLoadingState: (
        state: ILoadingState,
        action: PayloadAction<boolean>
      ) => {
        state.isTransactionLoading = action.payload;
      }
  
  },
});

export const {
    setLoadingState
} = loadingSlice.actions;

export default loadingSlice.reducer;
