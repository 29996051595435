/* eslint-disable import/no-extraneous-dependencies */
import { configureStore } from "@reduxjs/toolkit";
import walletSlice from "@redux/features/walletSlice";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import counterSlice from "@redux/features/counterSlice";
import lobbySlice from "@redux/features/lobbySlice";
import inventorySlice from "@redux/features/inventorySlice";
import volumeSlice from "@redux/features/volumeSlice";
import loadingSlice from "@redux/features/stateSlice";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  blacklist: ["lobby", "inventory", "counter", "loading"],
  // whitelist: ["lobbySlice"],
};

const rootReducer = combineReducers({
  counter: counterSlice,
  wallet: walletSlice,
  lobby: lobbySlice,
  inventory: inventorySlice,
  volume: volumeSlice,
  loading: loadingSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
