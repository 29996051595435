import { IUserEquipment } from "@interfaces/equipment.interface";
import { getUsersEquipments } from "@services/icon.service";
import { getEquipmentsDetails } from "@api/api";
import { getEquipmentInformation } from "./getEquipmentInformation";

interface IGetEquipment {
    setUserEqupimentDetails: React.Dispatch<React.SetStateAction<Array<IUserEquipment>>>;
    setUserLockedEquipmentDetails: React.Dispatch<React.SetStateAction<Array<IUserEquipment>>>;
    setUserCooldownEquipmentDetails: React.Dispatch<React.SetStateAction<Array<IUserEquipment>>>;
    setUserUnreleasedEquipmentDetails: React.Dispatch<React.SetStateAction<Array<IUserEquipment>>>;
    walletAddress: string | null;
}
export async function getUserEquipment({
    setUserEqupimentDetails,
    setUserLockedEquipmentDetails,
    setUserCooldownEquipmentDetails,
    setUserUnreleasedEquipmentDetails,
    walletAddress,
}: IGetEquipment) {
    const userAvailableEquipment: Array<IUserEquipment> = [];
    const userLockedEquipment: Array<IUserEquipment> = [];
    const userCooldownEquipment: Array<IUserEquipment> = [];
    const userUnreleasedEquipment: Array<IUserEquipment> = [];
    // const unreleasedEquipmentIds = [15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25];
    const unreleasedEquipmentIds: Array<number> = [17];
    const equipments = await getUsersEquipments(walletAddress);
    for await (let equip of equipments.availableEquipments) {
        const info = await getEquipmentInformation(equip.id);
        info["count"] = equip.count;
        if (unreleasedEquipmentIds.includes(Number(equip.id))) {
            const userEquipment = { ...info }; // Create a new object for each iteration
            // userEquipment["uniqueKey"] = `locked_${i}_${Number(equip.id)}`;
            userEquipment["equipmentId"] = (userEquipment.equipmentId as number) + 10011;
            userEquipment["uniqueKey"] = `released_${Number(equip.id)}`;
            userEquipment["isUnreleased"] = true;
            userUnreleasedEquipment.push(userEquipment as IUserEquipment);
        } else {
            userAvailableEquipment.push(info as IUserEquipment);
        }
    }
    for await (let equip of equipments.lockedEquipments) {
        const info = await getEquipmentInformation(equip.id);
        info["count"] = equip.count;

        // for (let i = 0; i < Number(equip.count); i++) {
        const userEquipment = { ...info }; // Create a new object for each iteration
        // userEquipment["uniqueKey"] = `locked_${i}_${Number(equip.id)}`;
        userEquipment["equipmentId"] = (userEquipment.equipmentId as number) + 100;
        userEquipment["uniqueKey"] = `locked_${Number(equip.id)}`;
        userEquipment["isLocked"] = true;
        userLockedEquipment.push(userEquipment as IUserEquipment);
        // }
    }
    for await (let cooldown of equipments.coolDownEquipments) {
        const info = await getEquipmentInformation(cooldown.id);
        const userEquipment = { ...info }; // Create a new object for each iteration
        const randomNum = Math.floor(Math.random() * 100);
        userEquipment["equipmentId"] = (userEquipment.equipmentId as number) + 100;
        userEquipment["uniqueKey"] = `cooldown_${randomNum}_${Number(cooldown.id)}`;
        userEquipment["isInCooldown"] = true;
        userEquipment["cooldownTime"] = Number(cooldown.expiry);
        userCooldownEquipment.push(userEquipment as IUserEquipment);
        // }
    }
    setUserEqupimentDetails(userAvailableEquipment);
    setUserLockedEquipmentDetails(userLockedEquipment);
    setUserCooldownEquipmentDetails(userCooldownEquipment);
    setUserUnreleasedEquipmentDetails(userUnreleasedEquipment);
}
