import { getLobbyLockdownPeriod } from "@services/icon.service";
import Countdown from "react-countdown-simple";
import { AiOutlineRight } from "react-icons/ai";
import { useState, useEffect } from "react";
import Typewriter from "typewriter-effect";
import toasterService from "@components/toast/ToasterService.component";

const GameTime = ({
  handleContinue,
  disabled,
  war,
  isHospitalized = false,
  isNFTLocked = false,
  isRentTimeWillOver = false
}: any) => {
  const [lobbyLockPeriod, setLobbyLockPeriod] = useState("0");
  async function lobbyLockDownPeriod() {
    const result = await getLobbyLockdownPeriod();
    setLobbyLockPeriod(result);
  }

  useEffect(() => {
    lobbyLockDownPeriod();
  }, []);
  const [isLobbyLocked, setLobbyLocked] = useState(false);
  const startsGameIn = ({ days, hours, minutes, seconds }: any) => {
    if (days === 0 && hours === 0 && minutes === 0 && seconds === 0) {
      if (lobbyLockPeriod && war) {
        const lockTime =
          Number(war?.startTime) / 1000 - Number(lobbyLockPeriod) / 1000;
        if (new Date() > new Date(lockTime)) {
          if (!isLobbyLocked) {
            setLobbyLocked(true);
            {
              toasterService.set({
                title: "Warning",
                message: `Please do not proceed ahead, the time for joining war is over!`,
                type: "error",
                toastSize: "toastLarge",
              });
            }
          }
        }
      }
      return <span />;
    } else {
      return (
        <>
          <span className="text-dark mr-2 uppercase normal-case">
            War Starts In{" "}
          </span>
          <span>
            {days > 0 && `${days} :`}
            {hours < 10 ? "0" + hours : hours}:
            {minutes < 10 ? "0" + minutes : minutes}:
            {seconds < 10 ? "0" + seconds : seconds}
          </span>
        </>
      );
    }
  };
  return (
    <div className="startWar">
      <div className="startWarTimer flex gap-2">
        {war && (
          <span>
            {Number(lobbyLockPeriod) > 0 ? (
              <Countdown
                targetDate={new Date(
                  new Date(
                    Number(war?.startTime) / 1000 -
                    Number(lobbyLockPeriod) / 1000
                  ).setHours(
                    new Date(
                      Number(war?.startTime) / 1000 -
                      Number(lobbyLockPeriod) / 1000
                    ).getHours()
                  )
                ).toISOString()}
                // disableTypes={true}
                renderer={startsGameIn}
              />
            ) : (
              <p className=" flex">
                {" "}
                <Typewriter
                  options={{
                    strings: ["........"],
                    autoStart: true,
                    loop: true,
                    cursor: ".",
                  }}
                />
              </p>
            )}
          </span>
        )}
      </div>
      <div className="warContinueBtnDiv">
        <button
          className={`contineWarBtn ${(isHospitalized || isNFTLocked || isRentTimeWillOver) &&
            "disable opacity-50"}`}
          onClick={handleContinue}
          disabled={disabled ? disabled : false}
        >
          Continue <AiOutlineRight size={30} />
        </button>
      </div>
    </div>
  );
};

export default GameTime;
