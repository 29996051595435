import { AiOutlineClose } from "react-icons/ai";
import { ICloseButtons } from "src/core/interfaces/button.interface";

const CloseButton = ({ buttonType, onClick }: ICloseButtons) => {
  return (
    <button onClick={onClick} className={`close-button ${buttonType} p-1`}>
      <AiOutlineClose
        size={20}
        color={buttonType === "closePrimary" ? "black" : "white"}
        style={{ transform: "rotate(45deg)" }}
      />
    </button>
  );
};

export default CloseButton;
