import Typewriter from "typewriter-effect";

export const GangstaBetRentalLoading = () => {
    return <div className='text-white flex justify-center items-center rentalLoading flex gap-1 justify-center items-center'><p>Loading</p>
        <Typewriter
            options={{
                strings: ["..."],
                autoStart: true,
                loop: true,
                cursor: ".",
            }}
        />
    </div>
}

export default GangstaBetRentalLoading;