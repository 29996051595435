import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { BATTLE_BG_MUSIC, GENERIC_BG_MUSIC } from "@constants/audio";
import { SOUND_S3_URL } from "@constants/war";

export function BgSound() {
    const FADE_INTERVAL = 300;
    const FADE_STEP = 10;
  
    const location = useLocation();
    const audioRef = useRef<HTMLAudioElement>(null);
    const { musicVolume } = useSelector((state: any) => state.volume);
  
    const [bgMusic, setBgMusic] = useState("");
    const bgMusicRef = useRef("");
    const prevBgMusicRef = useRef("");
  
    useEffect(() => {
      let prevVol = 0;
      const handle = setInterval(() => {
        prevVol = prevVol + FADE_STEP;
        if(audioRef.current){
          if(prevVol >= musicVolume - FADE_STEP) {
            audioRef.current.volume = musicVolume / 100;
            audioRef.current.play();
            clearInterval(handle);
          } else {
            audioRef.current.volume = prevVol / 100;
          }
        }
      }, FADE_INTERVAL)
      prevBgMusicRef.current = bgMusic;
    }, [bgMusic]);
  
    const updateBgMusic = (src: string) => {
      if(src == bgMusic) return;

      let prevVol = audioRef.current && audioRef.current.currentTime > 0 ? musicVolume : 0;
  
      const handle = setInterval(() => {
        const newVol = prevVol - FADE_STEP;
        prevVol = newVol > 0 ? newVol : 0;
        if(audioRef.current) {
          audioRef.current.volume = prevVol / 100;
        }
        if(prevVol <= FADE_STEP) {
          clearInterval(handle);
          if(bgMusicRef.current == src && audioRef.current) {
            audioRef.current.volume = musicVolume / 100;
          }
          setBgMusic(src);
          bgMusicRef.current = src;
        }
      }, FADE_INTERVAL);
    }
  
    const setupBgAudio = () => {
      updateBgMusic(
        location.pathname.includes("/battle")
          ? SOUND_S3_URL + BATTLE_BG_MUSIC
          : SOUND_S3_URL + GENERIC_BG_MUSIC
      );
      audioRef.current && audioRef.current.play();
      document.removeEventListener("click", setupBgAudio);
    };
  
    useEffect(() => {
      document.addEventListener("click", setupBgAudio);
    }, []);
  
    useEffect(() => {
      if (location.pathname.includes("/battle")) {
        updateBgMusic(SOUND_S3_URL + BATTLE_BG_MUSIC);
      } else {
        updateBgMusic(SOUND_S3_URL + GENERIC_BG_MUSIC);
      }
    }, [location.pathname]);
  
    useEffect(() => {
      if (audioRef.current) audioRef.current.volume = musicVolume / 100;
    }, [musicVolume]);
  
    return (
      <audio src={bgMusic} ref={audioRef} autoPlay typeof="audio/mpeg" loop />
    );
  }
  