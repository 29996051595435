import StylizedButton from "@components/buttons/stylized.button";
import EquipmentPlainCard from "@components/card/equipmentPlainCard.component";
import Modal from "@components/modal/modal.component";
import { CROPPEDGRENADE } from "@constants/images";
import { HiArrowLongRight } from "react-icons/hi2";
interface IWarCloseForEntry {
  isVisible: boolean;
  setIsVisible: any;
  modalTitle?: string;
  modalContent?: any;
  buttonName?: string;
  handleConfirm?: any;
  previousEquiped?: any;
  switchEquiped?: any;
}
const SwitchWeapon = ({
  isVisible,
  setIsVisible,
  handleConfirm,
  modalTitle,
  modalContent,
  buttonName,
  previousEquiped,
  switchEquiped,
}: IWarCloseForEntry) => {
  const isLoadoutItemSame = previousEquiped?.id
    ? Number(previousEquiped?.id) === Number(switchEquiped?.id)
    : Number(previousEquiped?.equipmentId) ===
      Number(switchEquiped?.equipmentId);

  return (
    <Modal
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      width=""
      modalTitle={modalTitle || "Switch Weapons?"}
    >
      <div className="rapidRecoverNFTWrapper ">
        {isLoadoutItemSame ? (
          <>
            {/* <hr className="horizontal-seperator" /> */}
            <p className="px-20 text-white  text-center">
              Same loadout cannot be switched !
            </p>
            <hr className="horizontal-seperator" />
          </>
        ) : (
          <> {modalContent}</>
        )}

        <div className="rapidRecoverNFTText">
          <div className="flex items-center relative justify-center w-full my-12">
            <EquipmentPlainCard
              height="95px"
              width="105px"
              equipmentImages={previousEquiped?.image}
              className="setColor"
            />
            <HiArrowLongRight
              className="text-6xl text-[#e3c96e] z-50"
              // style={{ position: "absolute", left: 113 }}
            />
            <EquipmentPlainCard
              height="95px"
              width="105px"
              equipmentImages={switchEquiped?.image}
              className="setGrey"
            />
          </div>
        </div>
        <div className="rapidRecoverNFTButton">
          <StylizedButton
            onClick={() => {
              isLoadoutItemSame ? handleConfirm() : handleConfirm();
              setIsVisible(!isVisible);
            }}
            buttonName={isLoadoutItemSame ? "Okay" : "YES, SWITCH"}
          />
          {!isLoadoutItemSame && (
            <p
              className="notNowBtn"
              onClick={() => {
                setIsVisible(!isVisible);
              }}
            >
              Not now
            </p>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default SwitchWeapon;
