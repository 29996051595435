import { useRef, useState, useEffect, CSSProperties } from "react";

import StylizedButton from "@components/buttons/stylized.button";
import Modal from "@components/modal/modal.component";

interface InactiveTabModal {
  isVisible: boolean;
  setIsVisible: any;
}

interface IDontShowCheckbox {
  onClick: (e: any) => void
}

const checkboxStyles: CSSProperties = {
  pointerEvents: "none",
  accentColor: "#9d3039",
  height: "16px",
  width: "16px",
}

const DontShowCheckBox = ({ onClick }: IDontShowCheckbox) => {
  const checkboxRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    checkboxRef.current?.click();
    onClick(checkboxRef.current);
  }

  // checkbox should not be checked on initial load
  useEffect(() => {
    if (checkboxRef.current?.checked) checkboxRef.current.click();
  }, [])

  return (
    <p onClick={handleClick} className="text-white mt-4 flex flex-row items-center cursor-pointer">
      <input type="checkbox" style={checkboxStyles} ref={checkboxRef} className="mr-2" />
      <label className="cursor-pointer">Don't show again</label>
    </p>
  );
}

const InactiveTabModal = ({ isVisible, setIsVisible }: InactiveTabModal) => {

  const [dontShowAgain, setDontShowAgain] = useState(false);

  useEffect(() => {
    setDontShowAgain(false);
  }, [isVisible]);

  const onOkayBtnClick = () => {
    if (dontShowAgain) {
      sessionStorage.setItem("noShowInactiveModal", "true");
    } else {
      sessionStorage.removeItem("noShowInactiveModal");
    }
    setIsVisible(false);
  }

  const onDontShowAgainClick = (checkBoxElem: HTMLInputElement) => {
    setDontShowAgain(checkBoxElem.checked);
  }

  return (
    <Modal
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      modalTitle="Warning"
    >
      <div className="warCloseEntryWrapper">
        <hr className="horizontal-seperator" />
        <div className="warCloseEntryText">
          <p className="warCloseEntryTopText">
            It is not advised to minimize or switch browser tabs during a war.
            This may introduce unwanted effects and delays in the animations.
          </p>
        </div>
        <hr className="horizontal-seperator" />
        <div className="warCloseEntryButton">
          <StylizedButton
            onClick={onOkayBtnClick}
            buttonName="Okay"
          />
        </div>
        <DontShowCheckBox onClick={onDontShowAgainClick} />
      </div>
    </Modal>
  );
};

export default InactiveTabModal;
