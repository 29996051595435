const RenderTimeCounter = ({ days, hours, minutes, seconds }: any) => {
    if (days === 0 && hours === 0 && minutes === 0 && seconds === 0) {
        return <span></span>;
    }
    return (
        <div>
            {days > 0 && `${days}d: `}
            {hours > 0 ? hours < 10 ? `0${hours}h: ` : `${hours}h: ` : ``} {minutes > 0 ? minutes < 10 ? `0${minutes}m: ` : `${minutes}m: ` : ``}
            {seconds < 10 ? `${seconds}s` : `${seconds}s`}
        </div>
    );
};

export default RenderTimeCounter;
