import ConnectModal from "@components/modal/wallet/connectWith.modal";
import { useDispatch } from "react-redux";
import DisconnectWallet from "@components/modal/wallet/disconnectWarning.modal";

interface IWalletConnectDisconnect {
    userConnectModal: boolean;
    setuserConnectModal: React.Dispatch<React.SetStateAction<boolean>>; disconnectWalletModal?: boolean;
    setDisconnectWalletModal?: React.Dispatch<React.SetStateAction<boolean>>
}

const ConnectDisconnectWallet = ({ userConnectModal, setuserConnectModal, disconnectWalletModal, setDisconnectWalletModal }: IWalletConnectDisconnect) => {
    const dispatch = useDispatch();
    return <>
        {userConnectModal && (
            <ConnectModal
                isVisible={userConnectModal}
                setIsVisible={setuserConnectModal}
            />
        )}
        {disconnectWalletModal && (
            <DisconnectWallet
                dispatch={dispatch}
                isVisible={disconnectWalletModal}
                setIsVisible={setDisconnectWalletModal}
            />
        )}
    </>
};

export default ConnectDisconnectWallet;