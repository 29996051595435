import { getAbilityInformation, getEquipmentsDetails } from "@api/api";
import { IGetUserAbility } from "@interfaces/ability.interface";
import { IUserEquipment } from "@interfaces/equipment.interface";

export async function getAbilitiesInformation(
  id: string
): Promise<Partial<IGetUserAbility>> {
  try {
    const abilityInfo = await getAbilityInformation(Number(id));
    const result = abilityInfo.data;
    const availableInfo = {
      image: result.imageUrl,
      id: Number(result.abilityId),
      type: result.type,
      name: result.name,
      description: result.description,
    };
    return availableInfo;
  } catch (e) {
    return {};
  }
}
