import axios from "axios";
import {
    IActiveBattleDetails,
    IMainOrTarget,
} from "./interfaces/warScreen.interface";
import {
    ATTACK_EQUIPMENTS,
    ITEMS,
    NARRATION_TYPES,
    EFFECTS,
    ABILITY,
    PLAYER_DEAD,
    WAR_AUDIO_MAPPINGS,
    TARGET_GIF_SUFFIX,
    INFINITE_GIF_SUFFIX,
    IMAGES_S3_URL,
    SOUND_S3_URL,
    ACTION_TYPE,
    SPECIAL_ACTION_TYPES,
} from "@constants/war";

interface IBattleDetails {
    narrationType?: string;
    actionType?: string;
    equipment?: string;
    main?: IMainOrTarget;
    target?: IMainOrTarget;
}

// These are guaranteed to be available even if battleDetails.equipment is not available
const DEFAULT_EQUIPMENT = {
    [NARRATION_TYPES.ACTION_LOG]: ATTACK_EQUIPMENTS.REVOLVER,
    [NARRATION_TYPES.EFFECT_LOG]: EFFECTS.POISON,
    [NARRATION_TYPES.ITEM_TAKEN]: ITEMS.ANTIDOTE,
    [NARRATION_TYPES.ABILITY_LOG]: ABILITY.IMMUNE,
    [NARRATION_TYPES.PLAYER_DEAD]: PLAYER_DEAD.PLAYER_DEAD,
    [NARRATION_TYPES.HEAL]: "Heal",
    [NARRATION_TYPES.SELF_HEAL]: "Heal",
    [NARRATION_TYPES.SHIELD_BOOST]: NARRATION_TYPES.SHIELD_BOOST,
};

export async function sleep(ms: number) {
    return new Promise((resolve: any) => {
        setTimeout(() => {
            resolve(true);
        }, ms);
    });
}

export const getBattleEquipment = (
    battleDetails: IBattleDetails | undefined
) => {
    // special case for self_heal & heal
    if (
        battleDetails?.actionType == ACTION_TYPE.SELF_HEAL ||
        battleDetails?.actionType == ACTION_TYPE.HEAL ||
        battleDetails?.actionType == ACTION_TYPE.COVER_HEAL ||
        battleDetails?.narrationType === NARRATION_TYPES.HEAL
    ) {
        return "Heal";
    }
    // Special case for COLD_WATER
    if (
        battleDetails?.narrationType === NARRATION_TYPES.ITEM_TAKEN &&
        battleDetails.equipment === ITEMS.COLD_WATER
    ) {
        return `${battleDetails.equipment}_${battleDetails?.main?.coldWaterChange}`;
    }
    if (
        battleDetails?.actionType &&
        Object.keys(SPECIAL_ACTION_TYPES).includes(battleDetails.actionType)
    ) {
        return battleDetails.actionType;
    }
    return (
        battleDetails?.equipment ?? battleDetails?.narrationType ?? "RND_VAL"
    );
};

export const getGifImage = (
    battleDetails: IBattleDetails,
    isAttackTarget: boolean = false
) => {
    return (
        IMAGES_S3_URL +
        (isAttackTarget
            ? `${battleDetails.narrationType}/${battleDetails.equipment}${TARGET_GIF_SUFFIX}.gif`
            : `${battleDetails.narrationType}/${battleDetails.equipment}.gif`)
    );
};

export function getInfiniteEffectGif(effectType: string): string {
    return IMAGES_S3_URL + `EFFECT_LOG/${effectType}${INFINITE_GIF_SUFFIX}.gif`;
}

export function preFetchImages(imgSrcArr: Array<string>) {
    const uniqueSources = new Set(imgSrcArr);
    uniqueSources.forEach((imgSrc) => {
        axios.get(imgSrc, { responseType: "arraybuffer" });
    });
}

export const getBattleAudio = (
    battleDetails: IActiveBattleDetails,
    isTarget: boolean
): HTMLAudioElement => {
    try {
        let mp3File;
        const filenameArr: Array<string> = isTarget
            ? // @ts-ignore
              WAR_AUDIO_MAPPINGS[battleDetails.equipment + TARGET_GIF_SUFFIX]
            : // @ts-ignore
              WAR_AUDIO_MAPPINGS[battleDetails.equipment];
        const filename = filenameArr.at(
            Math.floor(Math.random() * filenameArr.length)
        );
        mp3File =
            SOUND_S3_URL + `${battleDetails.narrationType}/${filename}.mp3`;

        return new Audio(mp3File);
    } catch (e) {
        console.error(e);
        return new Audio("");
    }
};

export const animateDistraction = async (nftIds?: Array<number>) => {
    const selectorPrefix = "anim_card_container_";
    const classesToAdd = ["spin", "target-highlight-child"];
    return new Promise(async (resolve) => {
        nftIds?.forEach((id) => {
            const hexId = id.toString(16);
            
            document
                .getElementById(`${selectorPrefix}0x${hexId}`)
                ?.classList.add(...classesToAdd);
        });
        await sleep(1000);
        nftIds?.forEach((id) => {
            const hexId = id.toString(16);
            document
                .getElementById(`${selectorPrefix}0x${hexId}`)
                ?.classList.remove(...classesToAdd);
        });
        resolve(true);
    });
};
