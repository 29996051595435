import React, { useState, useEffect } from "react";
import { MdClose } from "react-icons/md";
import { CRATE2, FIST, HEALING, PISTOL, TEST_NFTS } from "@constants/images";
import PrimaryButton from "@components/buttons/primary.button";
import { RiSwordFill, RiSwordLine } from "react-icons/ri";
import ColoredCrateCard from "@components/card/coloredCrateCard";
import SidebarBody from "./sidebarBody";
import { FiChevronLeft } from "react-icons/fi";
import EquipmentPlainCard from "@components/card/equipmentPlainCard.component";
import Countdown from "react-countdown-simple";
import { CROWN } from "@constants/images";

import {
  getLobbyLockdownPeriod,
  getSimulationDetail,
  hasWalletParticipated,
} from "@services/icon.service";
import { useSelector } from "react-redux";
import { getLootBoxDetails } from "@api/api";
import { useNavigate } from "react-router-dom";
import { useDetailGlobalContext } from "@context/detailProvider";
const tsConvertOptions: Intl.DateTimeFormatOptions = {
  day: "2-digit",
  month: "short",
  hour: "2-digit",
  minute: "2-digit",
  hour12: true,
};

const WarDetailsSidebar = ({ open, warDetail, setOpen, title }: any) => {
  const [openDropChances, setOpenDropChances] = useState<boolean>(false);
  const [gameStatus, setGameStatus] = useState(
    new Date() >= new Date(warDetail.startTime / 1000) &&
      new Date() <= new Date(warDetail.endTime / 1000)
  );

  const navigate = useNavigate();

  const [remainingTime, setRemainingTime] = useState({
    hoursRemaining: 0,
    minutesRemaining: 0,
    secondsRemaining: -1,
  });
  const checkGameStatus = async () => {
    setGameStatus(
      new Date() >= new Date(warDetail.startTime / 1000) &&
        new Date() <= new Date(warDetail.endTime / 1000)
    );
    const participantsNumber = await getSimulationDetail(warDetail.id);
    setNumberOfParticipants(participantsNumber.participants.length);
    const remainingMilliseconds =
      new Date(warDetail.startTime / 1000).getTime() - new Date().getTime();
    let remainingTime = remainingMilliseconds;
    const hoursRemaining = Math.floor(remainingTime / (1000 * 60 * 60));
    remainingTime -= hoursRemaining * (1000 * 60 * 60);
    const minutesRemaining = Math.floor(remainingTime / (1000 * 60));
    remainingTime -= minutesRemaining * (1000 * 60);
    const secondsRemaining = Math.floor(remainingTime / 1000);
    remainingTime -= secondsRemaining * 1000;
    setRemainingTime({
      hoursRemaining: hoursRemaining,
      minutesRemaining: minutesRemaining,
      secondsRemaining: secondsRemaining,
    });
  };

  const [lootboxDetails, setLootboxDetails] = useState<any>();

  useEffect(() => {
    async function fetchLootDetails() {
      try {
        const result = await getLootBoxDetails(
          Number(warDetail.rewards.lootBoxId)
        );
        setLootboxDetails(result.data);
      } catch (e) {
        console.log(e);
      }
    }
    fetchLootDetails();
  }, []);

  useEffect(() => {
    const interval = setInterval(checkGameStatus, 1000);

    return () => clearInterval(interval);
  }, []);
  const [numberOfParticipants, setNumberOfParticipants] = useState<number>();
  const [isWalletParticipated, setWalletParticipated] = useState<boolean>();
  const { isWalletConnected, walletAddress } = useSelector(
    (state: any) => state.wallet
  );
  useEffect(() => {
    async function participants() {
      if (isWalletConnected) {
        const checkStatus = await hasWalletParticipated(
          warDetail.id,
          walletAddress
        );
        setWalletParticipated(!!Number(checkStatus));
      } else {
        setWalletParticipated(false);
      }
    }
    participants();
  }, [isWalletConnected]);
  const [showWarPage, setShowWarPage] = useState(false);
  const { setWarIdSelectedFromMap } = useDetailGlobalContext();
  const [lobbyLockPeriod, setLobbyLockPeriod] = useState("0");
  async function lobbyLockDownPeriod() {
    const result = await getLobbyLockdownPeriod();
    setLobbyLockPeriod(result);
  }
  const WAR_MAX_PLAYERS = Number(warDetail?.maxPlayer);

  useEffect(() => {
    lobbyLockDownPeriod();
  }, []);
  return (
    <>
      <SidebarBody setOpen={setOpen} title={warDetail?.title}>
        <div className="flex items-start justify-start w-full">
          <div className="w-1/2 border-r border-gray-800  pr-4">
            <p className="text-gray">START TIME </p>
            <p className="text-white font-semibold text-base">
              {" "}
              {new Date(
                Number(warDetail?.startTime) / 1000 -
                  Number(lobbyLockPeriod) / 1000
              ).toLocaleString("en-US", tsConvertOptions)}
            </p>
            <div className="text-gray flex ">
              <p>{gameStatus ? "Elapsed in" : "Starts in"}</p>
              <b>
                <p className="modal-countdown">
                  <Countdown
                    targetDate={
                      gameStatus
                        ? new Date(
                            new Date(Number(warDetail.endTime) / 1000).setHours(
                              new Date(
                                Number(warDetail.endTime) / 1000
                              ).getHours()
                            )
                          ).toISOString()
                        : new Date(
                            new Date(
                              Number(warDetail?.startTime) / 1000 -
                                Number(lobbyLockPeriod) / 1000
                            ).setHours(
                              new Date(
                                Number(warDetail?.startTime) / 1000 -
                                  Number(lobbyLockPeriod) / 1000
                              ).getHours()
                            )
                          ).toISOString()
                    }
                    disableTypes={true}
                  />
                </p>
              </b>
            </div>
          </div>
          <div className="px-4 w-1/2 ">
            <p className="text-gray">STATUS </p>
            <p className="text-white font-semibold text-base">
              {" "}
              {numberOfParticipants}/{WAR_MAX_PLAYERS} Players
            </p>
          </div>
        </div>
        <div className="mt-2 mb-4 w-full flex justify-center ">
          <PrimaryButton
            buttonName="WAR INFORMATION"
            icon={<RiSwordLine className="text-4xl text-white" />}
            className="w-full text-3xl "
            // disabled
            width="300px"
            onClick={() => {
              setWarIdSelectedFromMap(Number(warDetail.id));
              navigate("/war");
            }}
          />
        </div>
        <div className="flex items-start justify-start w-full border-y border-gray-800  py-4">
          <div className="w-1/2  border-r border-gray-800  pr-4">
            <p className="text-gray">DESCRIPTION </p>
            {/* <p className="text-white font-semibold text-base">
              {" "}
              Street Brawling
            </p> */}
            <p className="text-gray">{warDetail.description}</p>
          </div>
          <div className="w-1/2  px-4">
            <p className="text-gray">MAX DURATION </p>
            <p className="text-white font-semibold text-base">
              {" "}
              {Number(warDetail.period) / 60_000_000} MINS
            </p>
          </div>
        </div>
        <div className="flex items-start justify-start w-full border-y border-gray-800 py-4">
          <div className="w-1/2  border-r border-gray-800  pr-4">
            <p className="text-gray">PAYMENT TYPE </p>

            <p className="text-green">
              {/* {isWalletParticipated ? (
                <span className="text-yellow">joined</span>
              ) : gameStatus ? (
                "-"
              ) : warDetail.entryFee > 0 ? (
                <>
                  {warDetail.entryFee}
                  <img src={CROWN} alt="crown" className="crown-logo" />
                </>
              ) : (
                )} */}
              <span className="text-green">{warDetail?.paymentMethod}</span>
            </p>
          </div>
          <div className="w-1/2 px-4">
            <p className="text-gray">ZONE TYPE </p>
            <p className="text-white font-semibold text-base">
              {" "}
              {warDetail?.zone}
            </p>
          </div>
        </div>
        <div className="flex w-full gap-4">
          {/* <ColoredCrateCard
            image={CRATE2}
            bgColor="purple"
            className="custom-height"
          /> */}

          <img
            className="p-2 w-[105px] mt-[1px]"
            src={lootboxDetails?.imageUrl}
            alt="lootbox"
          />

          <div>
            <div className="text-white text-base mt-10 font-bold">
              {lootboxDetails?.name}
            </div>
            {/* <p
              className="underline text-white cursor-pointer"
              onClick={() => {
                // setOpen(false);
                setOpenDropChances(true);
              }}
            >
              View Drop Chances
            </p> */}
          </div>
        </div>
      </SidebarBody>

      {openDropChances && (
        <SidebarBody
          title="Drop Chances"
          icon={
            <FiChevronLeft
              onClick={() => {
                setOpen(true);
                setOpenDropChances(!openDropChances);
              }}
              className="text-white text-4xl cursor-pointer"
            />
          }
          setOpen={setOpenDropChances}
        >
          <div className="flex w-full mt-4 border-b border-gray-800 pb-4 sm:gap-2 gap-0">
            <div className="w-1/3">
              <ColoredCrateCard
                image={CRATE2}
                bgColor="purple"
                className="custom-height"
              />
            </div>
            <div className="w-2/3">
              <div className="text-white text-xl font-bold">Epic Crate</div>
              <p className="text-gray text-sm cursor-pointer">
                The Epic Loot Crate is packed with all sorts of Epic goodies.
                Expect to find awesome weapons, cool items, and sweet abilities
                to wage .
              </p>
            </div>
          </div>
          <div className="grid grid-cols-4 gap-8 mx-5 mt-4">
            {[0, 1, 2, 4, 5, 6, 7, 8].map((item: any) => (
              <>
                <a id="tooltip-anchor" className="text-white cursor-pointer">
                  <EquipmentPlainCard
                    height="60px"
                    width="64px"
                    equipmentImages={PISTOL}
                    className={"setGrey inactive-image"}
                  />
                </a>
              </>
            ))}
          </div>
        </SidebarBody>
      )}
    </>
  );
};

export default WarDetailsSidebar;
