import { easings } from "@react-spring/web";

export const ANIMATION_VALUES = {
    translateX: 60,
    translateZ: 20,
    groupTranslateX: 100,
    warNftScale: 2.5,
    shakeScaleLow: 0.99,
    shakeScaleHigh: 1.01,
    centerScale: 2.5,
    centerTranslateX: 155,
    centerTranslateY: 100,
    rotateRight: -1,
    rotateLeft: 1,
    MAX_DURATION: 800,
};


// todo:: THIS IS TOO DIRTY; MANANGE IT TIDILY
export const animations = {
    shake: (isTeamBlue?: boolean) => ({
        from: {
            // opacity: 0,
            rotate: isTeamBlue ? ANIMATION_VALUES.rotateLeft : ANIMATION_VALUES.rotateRight,
            translateZ: Math.random() * 10,
            percentage: 0,
        },
        to: [
            {
                rotate: isTeamBlue ? ANIMATION_VALUES.rotateRight : ANIMATION_VALUES.rotateLeft,
                translateZ: ANIMATION_VALUES.translateZ,
                percentage: 50,
            },
            {
                rotate: isTeamBlue ? ANIMATION_VALUES.rotateLeft : ANIMATION_VALUES.rotateRight,
                translateZ: 0,
                percentage: 100,
            },
        ],
        config: { duration: 500 },
        loop: true, // todo
    }),
    move: (leftSideNft: boolean, toCenter?: boolean, customTranslateX?: number) => {
        return {
            from: {
                scale: 1,
                translateX: 0,
                translateY: 0,
                translateZ: 0,
            },
            to: {
                scale: toCenter ? ANIMATION_VALUES.centerScale : ANIMATION_VALUES.warNftScale,
                translateX: customTranslateX,
            },
            config: { duration: ANIMATION_VALUES.MAX_DURATION,
                easing: getRandomEasings(),
            },
        }
    },
};

export function getRandomEasings() {
    return [easings.easeOutCirc, easings.easeInExpo, easings.easeInSine].at(Math.floor(Math.random() * 3));
}