import Modal from "@components/modal/modal.component";
import PrimaryButton from "@components/buttons/primary.button";
import { PLAY_NORMAL_CLICK } from "@constants/audio";
import { useSignMessage } from "@hooks/useSignMessage";

const Connected = ({ isVisible, setIsVisible, walletAddress }: any) => {
  return (
    <Modal
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      modalTitle="Congratulations!"
    >
      <div className="connect-success">
        <p className="text-center wallet-connected-success">
          Wallet is successfully connected
        </p>
        <p className="text-center wallet-connected-address">{walletAddress}</p>
        <PrimaryButton
          onClick={() => {
            PLAY_NORMAL_CLICK();
            setIsVisible(false);
          }}
          buttonName="Continue"
        />
      </div>
    </Modal>
  );
};

export default Connected;
