import React from 'react';
import { getSpectatorStats } from '../services/firebase.service';

function useStats(simId) {
    const [stats, setStats] = React.useState({ "currentCount": 0, "maxCount": 0 });

    React.useEffect(() => {
        const getStats = async () => {
            await getSpectatorStats(simId, setStats);
        }

        getStats();
    }, [simId]);
    return stats;
}

export { useStats };
