import { createContext, useState, useContext } from "react";
import { useSelector } from "react-redux";

const warListInformation = createContext<any>(null);
const WarLists = ({ children }: any) => {
  const [teamSeparation, setTeamSeparation] = useState({
    teamA: [],
    teamB: [],
  });
  const [messageSigningModal, setMessageSigningModal] = useState(false);

  return (
    <warListInformation.Provider
      value={{
        setTeamSeparation,
        teamSeparation,
        setMessageSigningModal,
        messageSigningModal,
      }}
    >
      {children}
    </warListInformation.Provider>
  );
};

const useWarListGlobalContext = () => {
  return useContext(warListInformation);
};

export { WarLists, useWarListGlobalContext };
