import React, { useEffect, useState, useCallback } from "react";
import NotEnoughCrownModal from "@components/modal/notEnoughCrown.modal";
import Character from "@components/chooseCharacter/Character.component";
import toasterService from "@components/toast/ToasterService.component";
import { getLootBoxDetails } from "@api/api";
import GameLobby from "@components/lobbyScreen/gameLobby.component";
import {
  getSimulationDetail,
  hasWalletParticipated,
} from "@services/icon.service";
import { useNavigate } from "react-router";
import { useDetailGlobalContext } from "@context/detailProvider";
import GangwarList from "./gangwarList.component";
import { useNFTDetailGlobalContext } from "@context/nftInformation";
import { useDispatch, useSelector } from "react-redux";
import { useWarListGlobalContext } from "@context/war";
import PlayerLimitReachedModal from "@components/modal/playerLimitReached.modal";
import { useNFTInformation } from "@context/nftInfoProvider";

const WarItem = ({ war }: any) => {
  const {
    warIdSelectedFromMap,
    setWarIdSelectedFromMap,
    reFetchChangeData,
    userCrown,
    userICX,
  } = useDetailGlobalContext();
  const { isWalletConnected, walletAddress } = useSelector(
    (state: any) => state.wallet
  );
  const { availableICX, availableCrown } = useSelector(
    (state: any) => state.inventory
  );
  const { setNftData, setCurrentBatchIndex, setRentalIndex } = useNFTDetailGlobalContext();
  useEffect(() => {
    setTimeout(() => {
      setWarIdSelectedFromMap(-1);
    }, 10000);
  }, [warIdSelectedFromMap]);
  const navigate = useNavigate();

  const [isWalletParticipated, setWalletParticipated] = useState<boolean>();
  useEffect(() => {
    async function participants() {
      if (isWalletConnected) {
        const checkStatus = await hasWalletParticipated(war.id, walletAddress);
        setWalletParticipated(!!Number(checkStatus));
      } else {
        setWalletParticipated(false);
      }
    }
    participants();
  }, [isWalletConnected, reFetchChangeData, war?.id]);
  const [
    showPlayerLimitReachedModal,
    setShowPlayerLimitReachedModal,
  ] = useState<boolean>(false);
  // const [showWarNotOpenModal, setShowWarNotOpenModal] = useState<boolean>(
  //   false
  // );
  // const [showWarCloseEntryModal, setShowWarCloseEntryModal] = useState<boolean>(
  //   false
  // );
  // const [showNotEnoughCrownModal, setShowNotEnoughCrownModal] = useState<
  //   boolean
  // >(false);

  const [lootboxDetails, setLootboxDetails] = useState<any>();

  useEffect(() => {
    async function fetchLootDetails() {
      try {
        const result = await getLootBoxDetails(Number(war.rewards.lootBoxId));
        setLootboxDetails(result.data);
      } catch (e) {
        console.log(e);
      }
    }
    fetchLootDetails();
  }, []);

  const [showOwnedNfts, setShowOwnedNfts] = useState<boolean>(false);
  const [gangwarId, setGangwarId] = useState<number>(0);
  const [showLobbyDetails, setShowLobbyDetails] = useState<boolean>(false);

  const [participantsDetails, setParticipatedDetails] = useState<any>({
    abilities: [],
    equipments: [],
    items: [],
  });

  const [showNotEnoughCrown, setShowNotEnoughCrown] = useState({
    status: false,
    fee: 0,
    type: "",
  });

  const dispatch = useDispatch();

  const { setTeamSeparation } = useWarListGlobalContext();
  const { reRenderCollectionToggler, setRerenderCollectionToggler } = useNFTInformation();

  const maxPlayer = Number(war?.maxPlayer);
  const viewLobbyHandler = () => {
    setTeamSeparation({ teamA: [], teamB: [] });
    setShowLobbyDetails(true);
  };

  const warActionBtnHandler =
    //  useCallback(async
    async () => {
      setRentalIndex(null);
      setNftData([]);
      setRerenderCollectionToggler(!reRenderCollectionToggler);
      setCurrentBatchIndex(0);
      // const result = await getSimulationDetail(war.id);
      const participantsNumber = await getSimulationDetail(war.id);
      const gameStatus = (participantsNumber.teamA.length > 0 &&
        participantsNumber.teamB.length > 0) as boolean;
      if (isWalletConnected) {
        const checkStatus = await hasWalletParticipated(war.id, walletAddress);
        if (!gameStatus && !!!Number(checkStatus) && !showOwnedNfts) {
          if (maxPlayer === participantsNumber.participants.length) {
            setShowPlayerLimitReachedModal(true);
          } else {
            if (war?.paymentMethod === "CROWN") {
              if (userCrown >= Number(war.fee) / 10 ** 18) {
                setShowOwnedNfts(true);
                setGangwarId(Number(war.id));
              } else {
                setShowNotEnoughCrown({
                  status: true,
                  fee: Number(war.fee) / 10 ** 18,
                  type: "CROWN",
                });
              }
            } else if (war?.paymentMethod === "FREE") {
              setShowOwnedNfts(true);
              setGangwarId(Number(war.id));
            } else if (war?.paymentMethod === "ICX") {
              if (availableICX >= Number(war.fee) / 10 ** 18) {
                setShowOwnedNfts(true);
                setGangwarId(Number(war.id));
              } else {
                setShowNotEnoughCrown({
                  status: true,
                  fee: Number(war.fee) / 10 ** 18,
                  type: "ICX",
                });
              }
            }
          }
        } else if (!gameStatus && !!Number(checkStatus) && !showLobbyDetails) {
          setTeamSeparation({ teamA: [], teamB: [] });
          setShowLobbyDetails(true);
        } else if (gameStatus && !!Number(checkStatus)) {
          const warId = Number(war.id);
          navigate(`/battle/${warId}`);
        } else if (gameStatus && !!!Number(checkStatus)) {
          const warId = Number(war.id);
          navigate(`/battle/${warId}`);
        }
      } else {
        if (gameStatus && !isWalletParticipated) {
          const warId = Number(war.id);
          navigate(`/battle/${warId}`);
        } else {
          toasterService.set({
            title: "Please connect your Wallet",
            message: `Please make sure to connect wallet before joining war`,
            type: "warn",
            toastSize: "toastSmall",
          });
        }
      }
    };
  // , [war.id, isWalletConnected]);

  return (
    <>
      <GangwarList
        war={war}
        lootboxDetails={lootboxDetails}
        viewLobbyHandler={viewLobbyHandler}
        warActionBtnHandler={warActionBtnHandler}
      />
      {showPlayerLimitReachedModal && (
        <PlayerLimitReachedModal
          isVisible={showPlayerLimitReachedModal}
          setIsVisible={setShowPlayerLimitReachedModal}
          maxPlayer={maxPlayer}
        />
      )}
      {/* <WarNotOpenModal
        isVisible={showWarNotOpenModal}
        setIsVisible={setShowWarNotOpenModal}
      />
      <WarCloseEntryModal
        isVisible={showWarCloseEntryModal}
        setIsVisible={setShowWarCloseEntryModal}
      />
      <NotEnoughCrownModal
        isVisible={showNotEnoughCrownModal}
        setIsVisible={setShowNotEnoughCrownModal}
      /> */}

      {showOwnedNfts && (
        <Character
          isScreenClose={showOwnedNfts}
          chooseCharacterScreenClose={setShowOwnedNfts}
          gangwarId={gangwarId}
          war={war}
          setShowLobbyDetails={setShowLobbyDetails}
        />
      )}
      {showLobbyDetails && (
        <GameLobby
          isScreenClose={showLobbyDetails}
          setScreenClose={setShowLobbyDetails}
          warId={war?.id}
          walletAddress={walletAddress}
        />
      )}
      {showNotEnoughCrown && (
        <NotEnoughCrownModal
          isVisible={showNotEnoughCrown.status}
          setIsVisible={() => {
            setShowNotEnoughCrown((prev) => ({
              ...prev,
              status: false,
            }));
          }}
          info={showNotEnoughCrown}
        />
      )}
    </>
  );
};

export default WarItem;
