import axios from "axios";
import { config } from "@config/config";
import { objectToHexWithPrefix } from "@utils/objectWithHex";
import AppIcx from "@ledgerhq/hw-app-icx";
import { store } from "@redux/store";
const IconService = require("icon-sdk-js").default;

const {
    IconBuilder,
    HttpProvider,
    IconAmount,
    IconConverter,
    IconValidator,
    IconUtil,
} = IconService;

const { CallBuilder } = IconBuilder;

const httpProvider = new HttpProvider(config.icon.WALLET_URL);
const iconService = new IconService(httpProvider);
const callBuilder = new CallBuilder();

export const handleLogin = () => {
    window.dispatchEvent(
        new CustomEvent("ICONEX_RELAY_REQUEST", {
            detail: {
                type: "REQUEST_ADDRESS",
            },
        })
    );
};

const signTransaction = async (transaction, walletPath, transport) => {
    const rawTransaction = IconConverter.toRawTransaction(transaction);
    const hashKey = IconUtil.generateHashKey(rawTransaction);
    const icx = new AppIcx(transport);
    const { signedRawTxBase64 } = await icx.signTransaction(walletPath, hashKey);

    rawTransaction.signature = signedRawTxBase64;

    return {
        getProperties: () => rawTransaction,
        getSignature: () => signedRawTxBase64,
    };
};

const postIconTransaction = async (txObj) => {
    try {
        const reqObj = {
            jsonrpc: "2.0",
            method: "icx_sendTransaction",
            params: txObj,
            id: 909099,
        };
        const { data } = await axios.post(config.icon.WALLET_URL, reqObj);
        return data.result;
    } catch (e) {
        console.error(e);
        throw new Error("Error sending transaction to blockchain");
    }
};

const getStepLimit = async (from, method, score, param, value) => {
    try {
        const info = {
            id: 1234,
            jsonrpc: "2.0",
            method: "debug_estimateStep",
            params: {
                from,
                data: {
                    method,
                    params: param || null,
                },
                dataType: "call",
                nid: `0x${parseFloat(config.icon.NETWORK_ID).toString(16)}`,
                nonce: "0x1",
                timestamp: `0x${(new Date().getTime() * 1000).toString(16)}`,
                to: score,
                value: value ? `0x${(value * 10 ** 18).toString(16)}` : null,
                version: "0x3",
            },
        };
        const stepLimit = await axios.post(config.icon.STEP_LIMIT_API, info);
        return `0x${(Number(stepLimit.data.result) + 1_000_000).toString(16)}`;
    } catch (error) {
        throw error.response.data.error.message;
    }
};

export const checkICONaddressValidity = async (address) => {
    return new Promise(async (resolve, reject) => {
        try {
            const validity = IconValidator.isEoaAddress(address);
            resolve(validity);
        } catch (e) {
            reject(false);
        }
    });
};

export const getTransactionResult = async (tx) => {
    try {
        const transactionResult = await iconService.getTransactionResult(tx).execute();
        return {
            status: transactionResult.status === 1 ? "success" : "error",
            msg: transactionResult.status === 1 ? "Success" : transactionResult.failure.message,
            tx: tx,
            eventLogs: transactionResult.status === 1 ? transactionResult.eventLogs : "no_logs",
        };
    } catch (e) {
        if (
            e.toString().includes("debug") ||
            e.toString().includes("NotFound") ||
            e.includes("Pending") ||
            e.includes("Executing") ||
            e === "[RPC ERROR] Invalid params txHash"
        ) {
            return {
                status: "pending",
                msg: "Pending Transaction",
                tx: tx,
            };
        }

        return {
            status: "error",
            msg: "Invalid Transaction",
            tx: tx,
        };
    }
};

export const availableCrown = (address) => {
    return new Promise(async (resolve, reject) => {
        try {
            const call = callBuilder
                .to(config.contract.CROWN_SCORE)
                .method("balanceOf")
                .params({ _owner: address })
                .build();
            const availableCrown = await iconService.call(call).execute();
            const parsedCrown = Number(availableCrown) / 10 ** 18;
            //  / 10 ** 18;
            resolve(parsedCrown);
        } catch (e) {
            console.log("got crown", e);
            resolve(0);
        }
    });
};

export const getLatestBlock = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            let block = await iconService.getLastBlock().execute();
            resolve(block);
        } catch (e) {
            reject({ msg: "Invalid" });
        }
    });
};

export const getBlock = (blockNumber) => {
    return new Promise(async (resolve, reject) => {
        try {
            let block = await iconService.getBlockByHeight(blockNumber).execute();
            resolve(block);
        } catch (e) {
            reject({ msg: "Invalid" });
        }
    });
};

export const getActiveGangwars = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const call = callBuilder
                .to(config.contract.GANGWAR_CONTRACT)
                .method("getActiveSimulation")
                .params({})
                .build();
            const arr = await iconService.call(call).execute();
            resolve(arr);
        } catch (e) {
            resolve([]);
        }
    });
};

export const getSimulationDetail = (simId) => {
    return new Promise(async (resolve, reject) => {
        try {
            const call = callBuilder
                .to(config.contract.GANGWAR_CONTRACT)
                .method("getSimulationDetail")
                .params({ simulationId: simId })
                .build();
            const dict = await iconService.call(call).execute();
            resolve(dict);
        } catch (e) {
            resolve([]);
        }
    });
};

export const getNftStatus = (nftId) => {
    return new Promise(async (resolve, reject) => {
        try {
            const call = callBuilder
                .to(config.contract.GANGWAR_CONTRACT)
                .method("getNftStatus")
                .params({ nftId: nftId })
                .build();
            const dict = await iconService.call(call).execute();

            resolve(dict);
        } catch (e) {
            console.log("Error", e);
            resolve([]);
        }
    });
};

export const joinGangwar = async (
    address,
    simulationId,
    nftId,
    equipmentId,
    equipmentVals,
    itemIds,
    itemVals,
    abilityIds,
    abilityVals,
    isLedger,
    ledgerPath,
    ledgerTransport
) => {
    try {
        const callTransactionBuilder = new IconBuilder.CallTransactionBuilder();
        const txObj = callTransactionBuilder
            .from(address)
            .to(config.contract.GANGWAR_CONTRACT)
            .stepLimit(IconConverter.toBigNumber("100000000"))
            .nid(IconConverter.toBigNumber(config.icon.NETWORK_ID))
            .nonce(IconConverter.toBigNumber("1"))
            .version(IconConverter.toBigNumber("3"))
            .timestamp(new Date().getTime() * 1000)
            .method("participateFree")
            .params({
                data: {
                    simulationId,
                    nftId,
                    equipmentIds: equipmentId,
                    equipmentValues: equipmentVals,
                    itemIds: itemIds,
                    itemValues: itemVals,
                    abilityIds: abilityIds,
                    abilityValues: abilityVals,
                },
            })
            .build();

        if (isLedger) {
            const signedTransaction = await signTransaction(txObj, ledgerPath, ledgerTransport);
            const res = await iconService.sendTransaction(signedTransaction).execute();
            return res;
        } else {
            let scoreData = {};
            scoreData.value = JSON.stringify({
                jsonrpc: "2.0",
                method: "icx_sendTransaction",
                params: IconConverter.toRawTransaction(txObj),
                id: 50889,
            });

            const parsed = JSON.parse(scoreData.value);

            window.dispatchEvent(
                new CustomEvent("ICONEX_RELAY_REQUEST", {
                    detail: {
                        type: "REQUEST_JSON-RPC",
                        payload: parsed,
                    },
                })
            );
        }
    } catch (e) {
        return -1;
    }
};

export const joinGangwarViaICX = async (
    address,
    simulationId,
    nftId,
    equipmentId,
    equipmentValue,
    itemIds,
    itemValues,
    abilityIds,
    abilityValues,
    warFee,
    isLedger,
    ledgerPath,
    ledgerTransport
) => {
    try {
        const callTransactionBuilder = new IconBuilder.CallTransactionBuilder();
        const txObj = callTransactionBuilder
            .from(address)
            .to(config.contract.GANGWAR_CONTRACT)
            .stepLimit(IconConverter.toBigNumber("100000000"))
            .value(IconAmount.of(warFee, IconAmount.Unit.ICX).toLoop())
            .nid(IconConverter.toBigNumber(config.icon.NETWORK_ID))
            .nonce(IconConverter.toBigNumber("1"))
            .version(IconConverter.toBigNumber("3"))
            .timestamp(new Date().getTime() * 1000)
            .method("participateViaIcx")
            .params({
                data: {
                    simulationId,
                    nftId,
                    equipmentIds: equipmentId,
                    equipmentValues: equipmentValue,
                    itemIds: itemIds,
                    itemValues: itemValues,
                    abilityIds: abilityIds,
                    abilityValues: abilityValues,
                },
            })
            .build();

        if (isLedger) {
            const signedTransaction = await signTransaction(txObj, ledgerPath, ledgerTransport);
            const res = await iconService.sendTransaction(signedTransaction).execute();
            return res;
        } else {
            let scoreData = {};
            scoreData.value = JSON.stringify({
                jsonrpc: "2.0",
                method: "icx_sendTransaction",
                params: IconConverter.toRawTransaction(txObj),
                id: 50889,
            });

            const parsed = JSON.parse(scoreData.value);

            window.dispatchEvent(
                new CustomEvent("ICONEX_RELAY_REQUEST", {
                    detail: {
                        type: "REQUEST_JSON-RPC",
                        payload: parsed,
                    },
                })
            );
        }
    } catch (e) {
        console.log("there is error", e);
        return -1;
    }
};

export const joinGangwarWithCrown = async (
    address,
    simulationId,
    nftId,
    equipmentId,
    equipmentVals,
    itemIds,
    itemVals,
    abilityIds,
    abilityVals,
    warPrice,
    isLedger,
    ledgerPath,
    ledgerTransport
) => {
    try {
        const data = {
            destinationContract: config.contract.GANGWAR_CONTRACT,
            simulationId,
            nftId,
            equipmentIds: equipmentId,
            equipmentValues: equipmentVals,
            itemIds: itemIds,
            itemValues: itemVals,
            abilityIds: abilityIds,
            abilityValues: abilityVals,
            deposit: false,
            action: "PARTICIPATE_VIA_CROWN",
        };
        const fixedWarPrice = (Number(warPrice) * 10 ** 18).toLocaleString("fullwide", {
            useGrouping: false,
        });
        const txObj = {
            version: "0x3",
            from: address,
            to: config.contract.CROWN_SCORE,
            stepLimit: IconConverter.toHex(100000000),
            timestamp: IconConverter.toHex(Date.now() * 1000),
            nid: "0x" + config.icon.NETWORK_ID,
            nonce: "0x122",
            dataType: "call",
            data: {
                method: "transfer",
                params: {
                    _to: config.contract.TOKEN_DISTRIBUTION, //token distribution
                    _value: fixedWarPrice,
                    _data: objectToHexWithPrefix(data),
                },
            },
        };

        if (isLedger) {
            const signedTransaction = await signTransaction(txObj, ledgerPath, ledgerTransport);
            txObj["signature"] = signedTransaction.getSignature();
            const res = await postIconTransaction(txObj);
            return res;
        } else {
            let scoreData = {};
            scoreData.value = JSON.stringify({
                jsonrpc: "2.0",
                method: "icx_sendTransaction",
                params: txObj,
                id: 50889,
            });

            const parsed = JSON.parse(scoreData.value);

            window.dispatchEvent(
                new CustomEvent("ICONEX_RELAY_REQUEST", {
                    detail: {
                        type: "REQUEST_JSON-RPC",
                        payload: parsed,
                    },
                })
            );
        }
    } catch (e) {
        console.log("issue in ledger", e);
        return -1;
    }
};

export const hasWalletParticipated = async (simId, address) => {
    return new Promise(async (resolve, reject) => {
        try {
            const call = callBuilder
                .to(config.contract.GANGWAR_CONTRACT)
                .method("hasWalletParticipated")
                .params({
                    simulationId: simId,
                    wallet: address,
                })
                .build();
            const status = await iconService.call(call).execute();
            resolve(status);
        } catch (e) {
            console.log(e);
            resolve(0);
        }
    });
};

export const getLobbyLockdownPeriod = async () => {
    try {
        const call = callBuilder
            .to(config.contract.GANGWAR_CONTRACT)
            .method("getLobbyLockdownPeriod")
            .params({})
            .build();
        const lockingTime = await iconService.call(call).execute();
        return lockingTime;
    } catch (e) {
        console.log(e);
        return 0;
    }
};

//production
const httpProviderProduction = new HttpProvider("https://ctz.solidwallet.io/api/v3");
const iconServiceProduction = new IconService(httpProviderProduction);
const callBuilderProduction = new CallBuilder();
export const getCharacterInfo = (nftId) => {
    return new Promise(async (resolve, reject) => {
        try {
            const call = callBuilder
                .to(
                    // "cx384018e03aa8b739472c7a0645b70df97550e2c2"
                    config.contract.GANGSTA_SCORE
                )
                .method("getCharacterInfo")
                .params({
                    nftId: `0x${parseInt(nftId).toString(16)}`,
                })
                .build();
            const nft = await iconService.call(call).execute();

            resolve(nft);
        } catch (e) {
            if (typeof e === "string") {
                if (e.includes("Generating NFT")) {
                    resolve({
                        msg: "Generating NFT",
                        meta_id: e
                            .split(".")[1]
                            .replace("(", "")
                            .replace(")", ""),
                    });
                }
            }
            resolve({
                msg: "Invalid",
            });
        }
    });
};

export const getOwnedNft = async (address, page) => {
    try {
        const call = callBuilder
            .to(
                // "cx384018e03aa8b739472c7a0645b70df97550e2c2"
                config.contract.GANGSTA_SCORE
            )
            .method(
                "getAllNftOwned"
                // "get_all_nft_owned"
            )
            .params({ address: address, page: page ?? "0x1" })
            .build();
        const nfts = await iconService.call(call).execute();

        return nfts;
    } catch (error) {
        console.log("NFTs", error);
        return [];
    }
};

export const getLockedNFTInSimulation = async (address, simulationId) => {
    try {
        const call = callBuilder
            .to(config.contract.GANGWAR_CONTRACT)
            .method("getOwnedNft")
            .params({ simulationId: simulationId, wallet: address })
            .build();
        const result = await iconService.call(call).execute();
        return result;
    } catch (e) {
        console.log(e);
        return -1;
    }
};

export const getUsersEquipments = (address) => {
    return new Promise(async (resolve, reject) => {
        try {
            const call = callBuilder
                .to(config.contract.EQUIPMENT_SCORE)
                .method("getUsersEquipments")
                .params({ user: address })
                .build();
            const arr = await iconService.call(call).execute();
            resolve(arr);
        } catch (e) {
            resolve([]);
        }
    });
};

export const usersAvailableLootboxBalances = (address) => {
    return new Promise(async (resolve, reject) => {
        try {
            const call = callBuilder
                .to(config.contract.LOOTBOX_SCORE)
                .method("getUsersAvailableLootboxBalances")
                .params({ user: address })
                .build();
            const arr = await iconService.call(call).execute();
            resolve(arr);
        } catch (e) {
            console.log(e);
            resolve([]);
        }
    });
};

export const usersItemsBalance = (address) => {
    return new Promise(async (resolve, reject) => {
        try {
            const call = callBuilder
                .to(config.contract.ITEMS_SCORE)
                .method("getUsersItemsBalance")
                .params({ user: address })
                .build();
            const arr = await iconService.call(call).execute();
            resolve(arr);
        } catch (e) {
            resolve([]);
        }
    });
};

export const getSimulationCount = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const call = callBuilder
                .to(config.contract.GANGWAR_CONTRACT)
                .method("getSimulationCount")
                .params({})
                .build();
            const arr = await iconService.call(call).execute();
            resolve(arr);
        } catch (e) {
            console.log("Details", e);
            resolve([]);
        }
    });
};

export const getSimulationHistory = (offset) => {
    return new Promise(async (resolve, reject) => {
        try {
            const count = await getSimulationCount();
            const call = callBuilder
                .to(config.contract.GANGWAR_CONTRACT)
                .method("getSimulationHistory")
                .params({ offset: offset.toString(), limit: "10", order: "desc" })
                .build();
            const arr = await iconService.call(call).execute();
            resolve(arr);
        } catch (e) {
            console.log("historyDetails", e);
            resolve([]);
        }
    });
};

export const burnLootbox = async (address, lootboxId, isLedger, ledgerPath, ledgerTransport) => {
    try {
        const callTransactionBuilder = new IconBuilder.CallTransactionBuilder();
        const txObj = callTransactionBuilder
            .from(address)
            .to(config.contract.LOOTBOX_SCORE)
            .stepLimit(IconConverter.toBigNumber("10000000"))
            .nid(IconConverter.toBigNumber(config.icon.NETWORK_ID))
            .nonce(IconConverter.toBigNumber("1"))
            .version(IconConverter.toBigNumber("3"))
            .timestamp(new Date().getTime() * 1000)
            .method("burn")
            .params({
                lootBoxId: lootboxId,
            })
            .build();
        if (isLedger) {
            const signedTransaction = await signTransaction(txObj, ledgerPath, ledgerTransport);
            const res = await iconService.sendTransaction(signedTransaction).execute();
            return res;
        } else {
            let scoreData = {};
            scoreData.value = JSON.stringify({
                jsonrpc: "2.0",
                method: "icx_sendTransaction",
                params: IconConverter.toRawTransaction(txObj),
                id: 50889,
            });

            const parsed = JSON.parse(scoreData.value);

            window.dispatchEvent(
                new CustomEvent("ICONEX_RELAY_REQUEST", {
                    detail: {
                        type: "REQUEST_JSON-RPC",
                        payload: parsed,
                    },
                })
            );
        }
    } catch (e) {
        return -1;
    }
};

export const usersAvailableAbilityBalances = (address) => {
    return new Promise(async (resolve, reject) => {
        try {
            const call = callBuilder
                .to(config.contract.LOOTBOX_SCORE)
                .method("getUsersAvailableAbilityBalances")
                .params({ user: address })
                .build();
            const arr = await iconService.call(call).execute();
            resolve(arr);
        } catch (e) {
            resolve([]);
        }
    });
};

export const getUserParticipantDetail = async (simulationId, nftId) => {
    try {
        const call = callBuilder
            .to(config.contract.GANGWAR_CONTRACT)
            .method("getParticipantDetail")
            .params({ simulationId, nftId })
            .build();
        const arr = await iconService.call(call).execute();
        return arr;
    } catch (e) {
        console.log(e);
        return [];
    }
};

export const getLockedNft = async () => {
    try {
        const call = callBuilder
            .to(config.contract.GANGWAR_CONTRACT)
            .method("getLockedNft")
            .params({})
            .build();
        const arr = await iconService.call(call).execute();
        return arr;
    } catch (e) {
        console.log(e);
        return [];
    }
};

export const updateLoadouts = async (payload) => {
    try {
        console.log(payload);
        const callTransactionBuilder = new IconBuilder.CallTransactionBuilder();
        const txObj = callTransactionBuilder
            .from(payload.address)
            .to(config.contract.GANGWAR_CONTRACT)
            .stepLimit(IconConverter.toBigNumber("100000000"))
            .nid(IconConverter.toBigNumber(config.icon.NETWORK_ID))
            .nonce(IconConverter.toBigNumber("1"))
            .version(IconConverter.toBigNumber("3"))
            .timestamp(new Date().getTime() * 1000)
            .method("updateToken")
            .params({ data: payload.params })
            .build();
        if (payload.ledger.isLedger) {
            const signedTransaction = await signTransaction(
                txObj,
                payload.ledger.ledgerPath,
                payload.ledger.ledgerTransport
            );
            const res = await iconService.sendTransaction(signedTransaction).execute();
            return res;
        } else {
            let scoreData = {};
            scoreData.value = JSON.stringify({
                jsonrpc: "2.0",
                method: "icx_sendTransaction",
                params: IconConverter.toRawTransaction(txObj),
                id: 50889,
            });

            const parsed = JSON.parse(scoreData.value);

            window.dispatchEvent(
                new CustomEvent("ICONEX_RELAY_REQUEST", {
                    detail: {
                        type: "REQUEST_JSON-RPC",
                        payload: parsed,
                    },
                })
            );
        }
    } catch (e) {
        console.log(e);
        return -1;
    }
};

export const getUsersLootCrateBurntCount = async (address) => {
    try {
        const call = callBuilder
            .to(config.contract.LOOTBOX_SCORE)
            .method("getUsersLootCrateBurntCount")
            .params({ address: address })
            .build();
        const arr = await iconService.call(call).execute();
        return arr;
    } catch (e) {
        console.log(e);
        return [];
    }
};

export const addAbilityTokens = async (payload) => {
    try {
        console.log(payload);
        const callTransactionBuilder = new IconBuilder.CallTransactionBuilder();
        const txObj = callTransactionBuilder
            .from(payload.address)
            .to(config.contract.GANGWAR_CONTRACT)
            .stepLimit(IconConverter.toBigNumber("100000000"))
            .nid(IconConverter.toBigNumber(config.icon.NETWORK_ID))
            .nonce(IconConverter.toBigNumber("1"))
            .version(IconConverter.toBigNumber("3"))
            .timestamp(new Date().getTime() * 1000)
            .method("addAbilityTokens")
            .params(payload.params)
            .build();
        // const const txObj = {
        //   "version": "0x3",
        //   "from": address,
        //   "to": config.contract.CROWN_SCORE,
        //   "stepLimit": IconConverter.toHex(5000000),
        //   "timestamp": IconConverter.toHex(Date.now() * 1000),
        //   "nid": "0x" + config.icon.NETWORK_ID,
        //   "nonce": "0x122",
        //   "dataType": "call",
        //   "data": {
        //     "method": "transfer",
        //     "params": {
        //       _to: config.contract.TOKEN_DISTRIBUTION, //token distribution
        //       _value: fixedWarPrice,
        //       _data: objectToHexWithPrefix(data),
        //     }
        //   }
        // }
        if (payload.ledger.isLedger) {
            const signedTransaction = await signTransaction(
                txObj,
                payload.ledger.ledgerPath,
                payload.ledger.ledgerTransport
            );
            const res = await iconService.sendTransaction(signedTransaction).execute();
            return res;
        } else {
            let scoreData = {};
            scoreData.value = JSON.stringify({
                jsonrpc: "2.0",
                method: "icx_sendTransaction",
                params: IconConverter.toRawTransaction(txObj),
                id: 50889,
            });

            const parsed = JSON.parse(scoreData.value);

            window.dispatchEvent(
                new CustomEvent("ICONEX_RELAY_REQUEST", {
                    detail: {
                        type: "REQUEST_JSON-RPC",
                        payload: parsed,
                    },
                })
            );
        }
    } catch (e) {
        console.log(e);
        return -1;
    }
};

export const getICXBalance = async (address) => {
    return new Promise(async (resolve, reject) => {
        try {
            let balance = await iconService.getBalance(address).execute();
            const icxBalance = IconConverter.toBigNumber(balance)
                .div(IconConverter.toBigNumber("1e18"))
                .toString();

            resolve(icxBalance);
        } catch (e) {
            reject({ msg: "Invalid" });
        }
    });
};

export const initializeSignTxObj = (iconAddress, msgKey, msgVal) => {
    return new IconService.IconBuilder.CallTransactionBuilder()
        .from(iconAddress)
        .to(iconAddress)
        .nid(IconService.IconConverter.toHex(1))
        .nonce(IconService.IconConverter.toHex(1))
        .version(IconService.IconConverter.toHex(3))
        .stepLimit(IconService.IconConverter.toHex(0))
        .timestamp(IconService.IconConverter.toHex(0))
        .method("transfer")
        .params({ [msgKey]: msgVal })
        .build();
};

export const outOfHospitalization = async (
    timestamp,
    address,
    nftId,
    fullRecovery,
    value,
    isLedger,
    ledgerPath,
    ledgerTransport
) => {
    // const fixedWarPrice = (Number(value) * 10 ** 18).toLocaleString("fullwide", {
    //   useGrouping: false,
    // });
    try {
        const data = {
            destinationContract: config.contract.GANGWAR_CONTRACT,
            nftId: nftId.toString(),
            timestamp: timestamp.toString(),
            deposit: false,
            action: "NFT_COOLDOWN_REDUCTION",
            fullRecovery: fullRecovery,
        };
        console.log("dataIssue", data);
        const fixedWarPrice = value.toLocaleString("fullwide", {
            useGrouping: false,
        });
        const txObj = {
            version: "0x3",
            from: address,
            to: config.contract.CROWN_SCORE,
            stepLimit: IconConverter.toHex(100000000),
            timestamp: IconConverter.toHex(Date.now() * 1000),
            nid: "0x" + config.icon.NETWORK_ID,
            nonce: "0x122",
            dataType: "call",
            data: {
                method: "transfer",
                params: {
                    _to: config.contract.TOKEN_DISTRIBUTION, //token distribution
                    _value: fixedWarPrice,
                    _data: objectToHexWithPrefix(data),
                },
            },
        };

        if (isLedger) {
            const signedTransaction = await signTransaction(txObj, ledgerPath, ledgerTransport);
            txObj["signature"] = signedTransaction.getSignature();
            const res = await postIconTransaction(txObj);
            return res;
        } else {
            let scoreData = {};
            scoreData.value = JSON.stringify({
                jsonrpc: "2.0",
                method: "icx_sendTransaction",
                params: txObj,
                id: 50889,
            });

            const parsed = JSON.parse(scoreData.value);

            window.dispatchEvent(
                new CustomEvent("ICONEX_RELAY_REQUEST", {
                    detail: {
                        type: "REQUEST_JSON-RPC",
                        payload: parsed,
                    },
                })
            );
        }
    } catch (e) {
        console.log("issue in ledger", e);
        return -1;
    }
};

export const getCoolDownFee = async (nftId, isFull, timestamp) => {
    try {
        const call = callBuilder
            .to(config.contract.GANGWAR_CONTRACT)
            .method("getCoolDownFee")
            .params({
                nftId: nftId.toString(),
                full: isFull,
                timestamp,
            })
            .build();
        // console.log("testing", call);
        const res = await iconService.call(call).execute();
        // console.log(
        //     "testing returned value===>",
        //     res.toString(),
        //     IconConverter.toBigNumber(res).toString(),
        //     Number(res).toLocaleString("fullwide", {
        //         useGrouping: false,
        //     })
        // );
        return Promise.resolve(IconConverter.toBigNumber(res).toString());
    } catch (e) {
        console.log("testing", e);
        return "-1";
    }
};

/**
 * RENTAL SCORE FUNCTIONS
 */

export const getRentNftStatus = async (nftId) => {
    try {
        const call = callBuilder
            .to(config.contract.RENTAL_SCORE)
            .method("getRentStatus")
            .params({ nftId })
            .build();
        const status = await iconService.call(call).execute();
        return status;
    } catch (error) {
        console.log("Status: ", error);
        return "NOT_AVAILABLE_FOR_RENT";
    }
};

export const getRentInfo = async (nftId) => {
    try {
        const call = callBuilder
            .to(config.contract.RENTAL_SCORE)
            .method("getNftRentInfo")
            .params({ nftId })
            .build();
        const status = await iconService.call(call).execute();
        return status;
    } catch (error) {
        console.log("Status: ", error);
        return "NOT_AVAILABLE_FOR_RENT";
    }
};
export const getSetRentDetail = async (nftId) => {
    try {
        const call = callBuilder
            .to(config.contract.RENTAL_SCORE)
            .method("getSetRentDetail")
            .params({ nftId })
            .build();
        const status = await iconService.call(call).execute();
        return status;
    } catch (error) {
        console.log("timestamp: ", error);
        return;
    }
};
export const getMaxDaysForRent = async () => {
    try {
        const call = callBuilder
            .to(config.contract.RENTAL_SCORE)
            .method("getMaxForRent")
            .params({})
            .build();
        const status = await iconService.call(call).execute();
        return status;
    } catch (error) {
        console.log("days: ", error);
        return 0;
    }
};
export const getRentedNFT = async (address) => {
    try {
        const call = callBuilder
            .to(config.contract.RENTAL_SCORE)
            .method("getRentedNft")
            .params({ address })
            .build();
        const status = await iconService.call(call).execute();
        return status;
    } catch (error) {
        console.log("Status: ", error);
        return "NOT_AVAILABLE_FOR_RENT";
    }
};

export const getRentedBy = async (nftId) => {
    try {
        const call = callBuilder
            .to(config.contract.RENTAL_SCORE)
            .method("rentedBy")
            .params({ nftId })
            .build();
        const status = await iconService.call(call).execute();
        return status;
    } catch (error) {
        console.log("Status: ", error);
        return "NOT_AVAILABLE_FOR_RENT";
    }
};

export const getRentType = async (nftId) => {
    try {
        const call = callBuilder
            .to(config.contract.RENTAL_SCORE)
            .method("getRentType")
            .params({ nftId })
            .build();
        const rentType = await iconService.call(call).execute();
        return rentType;
    } catch (error) {
        console.log("Status: ", error);
        return "INVALID";
    }
};
export const userClaimable = async (address) => {
    try {
        const call = callBuilder
            .to(config.contract.RENTAL_SCORE)
            .method("usersClaimableAmount")
            .params({ address })
            .build();
        const claimable = await iconService.call(call).execute();
        return claimable;
    } catch (error) {
        console.log("Status: ", error);
        return false;
    }
};

// export const getCollectedAmount = async (nftId) => {
//   try {
//     const call = callBuilder
//       .to(config.contract.RENTAL_SCORE)
//       .method("collectedFee")
//       .params({ nftId })
//       .build();
//     const collectedFee = await iconService.call(call).execute();
//     return collectedFee;
//   } catch (error) {
//     console.log("Status: ", error);
//     return 0;
//   }
// };

export const getRentalIndex = async () => {
    try {
        const call = callBuilder
            .to(config.contract.RENTAL_SCORE)
            .method("getRentalIndex")
            .params({})
            .build();
        const rentalIndex = await iconService.call(call).execute();
        return rentalIndex;
    } catch (error) {
        console.log("Status: ", error);
        return "INVALID";
    }
};

export const getRenterRentalHistoryCount = async (address) => {
    try {
        const call = callBuilder
            .to(config.contract.RENTAL_SCORE)
            .method("getRenterRentalHistoryCount")
            .params({ user: address })
            .build();
        const rentalHistoryCount = await iconService.call(call).execute();
        return Number(rentalHistoryCount);
    } catch (error) {
        console.log("Status: ", error);
        return 0;
    }
};

export const getActiveRentalsCount = async () => {
    try {
        const call = callBuilder
            .to(config.contract.RENTAL_SCORE)
            .method("getActiveRentalsCount")
            .params({})
            .build();
        const rentalHistoryCount = await iconService.call(call).execute();
        return Number(rentalHistoryCount);
    } catch (error) {
        console.log("Status: ", error);
        return 0;
    }
};
export const getTenantRentalHistoryCount = async (address) => {
    try {
        const call = callBuilder
            .to(config.contract.RENTAL_SCORE)
            .method("getTenantRentalHistoryCount")
            .params({ user: address })
            .build();
        const rentalHistoryCount = await iconService.call(call).execute();
        return Number(rentalHistoryCount);
    } catch (error) {
        console.log("Status: ", error);
        return 0;
    }
};

export const getActiveRentals = async (offset = "0", limit = "20", order = "desc") => {
    try {
        const call = callBuilder
            .to(config.contract.RENTAL_SCORE)
            .method("getActiveRentals")
            .params({ offset, limit, order })
            .build();
        const activeRents = await iconService.call(call).execute();
        return activeRents;
    } catch (error) {
        console.log("Status: ", error);
        return [];
    }
};

export const getPlatformFee = async () => {
    try {
        const call = callBuilder
            .to(config.contract.RENTAL_SCORE)
            .method("getPlatformFee")
            .params({})
            .build();
        const platformFee = await iconService.call(call).execute();
        return platformFee;
    } catch (error) {
        console.log("Status: ", error);
        return 0;
    }
};

export const getUserClaimables = async (
    walletAddress,
    offset = "0",
    limit = "10",
    order = "asc"
) => {
    try {
        const call = callBuilder
            .to(config.contract.RENTAL_SCORE)
            .method("getUsersClaimables")
            .params({ user: walletAddress, offset, limit, order })
            .build();
        const claimableRents = await iconService.call(call).execute();
        return claimableRents;
    } catch (error) {
        console.log("Status: ", error);
        return { totalRecords: 0, rents: [] };
    }
};

export const getClaimedHistory = async () => {
    try {
        const { walletAddress } = store.getState().wallet;
        const call = callBuilder
            .to(config.contract.RENTAL_SCORE)
            .method("getClaimedHistory")
            .params({ walletAddress })
            .build();
        const claimedRents = await iconService.call(call).execute();
        return claimedRents;
    } catch (error) {
        console.log("Status: ", error);
        return [];
    }
};

export const getRentalHistory = async (nftId) => {
    try {
        const historyCountcall = callBuilder
            .to(config.contract.RENTAL_SCORE)
            .method("getNftRentalHistoryCount")
            .params({ nftId: `0x${nftId.toString(16)}` })
            .build();
        const historyDataCount = +(await iconService.call(historyCountcall).execute());

        const call = callBuilder
            .to(config.contract.RENTAL_SCORE)
            .method("getNftRentalHistory")
            // TODO: limit: (historyDataCount * 20).toString() offset: "0"
            .params({
                nftId: nftId.toString(),
                offset: historyDataCount > 10 ? `0x${(historyDataCount - 10).toString(16)}` : `0x0`,
                limit: `0x${(10).toString(16)}`,
                order: "dsc",
            })
            .build();
        const rentHistory = await iconService.call(call).execute();
        console.log({ rentalHistory: rentHistory });
        return rentHistory;
    } catch (error) {
        console.log("Status: ", error);
        return [];
    }
};

export const setForRent = async (nftId, rentFeePerDay, type, period) => {
    try {
        const { walletAddress, isLedger, ledgerPath, ledgerTransport } = store.getState().wallet;
        const callTransactionBuilder = new IconBuilder.CallTransactionBuilder();
        const rentFee = (rentFeePerDay * 10 ** 18).toLocaleString("fullwide", {
            useGrouping: false,
        });
        const txObj = callTransactionBuilder
            .from(walletAddress)
            .to(config.contract.RENTAL_SCORE)
            .stepLimit(IconConverter.toBigNumber("10000000"))
            .nid(IconConverter.toBigNumber(config.icon.NETWORK_ID))
            .nonce(IconConverter.toBigNumber("1"))
            .version(IconConverter.toBigNumber("3"))
            .timestamp(new Date().getTime() * 1000)
            .method("setForRent")
            // .params({ nftId, rentFee, type, rentForDays: period })
            .params({ nftId, rentFee, type })
            .build();
        if (isLedger) {
            const signedTransaction = await signTransaction(txObj, ledgerPath, ledgerTransport);
            const res = await iconService.sendTransaction(signedTransaction).execute();
            return res;
        } else {
            let scoreData = {};
            scoreData.value = JSON.stringify({
                jsonrpc: "2.0",
                method: "icx_sendTransaction",
                params: IconConverter.toRawTransaction(txObj),
                id: 50889,
            });

            const parsed = JSON.parse(scoreData.value);

            window.dispatchEvent(
                new CustomEvent("ICONEX_RELAY_REQUEST", {
                    detail: {
                        type: "REQUEST_JSON-RPC",
                        payload: parsed,
                    },
                })
            );
        }
    } catch (error) {
        console.log("Status: ", error);
        throw error;
    }
};

export const updateRateForRent = async (nftId, rentFeePerDay) => {
    try {
        const rentFee = (rentFeePerDay * 10 ** 18).toLocaleString("fullwide", {
            useGrouping: false,
        });
        const { walletAddress, isLedger, ledgerPath, ledgerTransport } = store.getState().wallet;
        const callTransactionBuilder = new IconBuilder.CallTransactionBuilder();

        const txObj = callTransactionBuilder
            .from(walletAddress)
            .to(config.contract.RENTAL_SCORE)
            .stepLimit(IconConverter.toBigNumber("10000000"))
            .nid(IconConverter.toBigNumber(config.icon.NETWORK_ID))
            .nonce(IconConverter.toBigNumber("1"))
            .version(IconConverter.toBigNumber("3"))
            .timestamp(new Date().getTime() * 1000)
            .method("changeFeePerDay")
            .params({ nftId, rentFee })
            .build();
        if (isLedger) {
            const signedTransaction = await signTransaction(txObj, ledgerPath, ledgerTransport);
            const res = await iconService.sendTransaction(signedTransaction).execute();
            return res;
        } else {
            let scoreData = {};
            scoreData.value = JSON.stringify({
                jsonrpc: "2.0",
                method: "icx_sendTransaction",
                params: IconConverter.toRawTransaction(txObj),
                id: 50889,
            });

            const parsed = JSON.parse(scoreData.value);

            window.dispatchEvent(
                new CustomEvent("ICONEX_RELAY_REQUEST", {
                    detail: {
                        type: "REQUEST_JSON-RPC",
                        payload: parsed,
                    },
                })
            );
        }
    } catch (error) {
        console.log("Status: ", error);
        throw error;
    }
};

export const RentViaICX = async (nftId, rentFee, periodInDay) => {
    try {
        const { walletAddress, isLedger, ledgerPath, ledgerTransport } = store.getState().wallet;
        console.log({ isLedger, ledgerPath, ledgerTransport });
        const callTransactionBuilder = new IconBuilder.CallTransactionBuilder();
        const txObj = callTransactionBuilder
            .from(walletAddress)
            .to(config.contract.RENTAL_SCORE)
            .stepLimit(IconConverter.toBigNumber("100000000"))
            .value(IconAmount.of(rentFee * Number(periodInDay), IconAmount.Unit.ICX).toLoop())
            .nid(IconConverter.toBigNumber(config.icon.NETWORK_ID))
            .nonce(IconConverter.toBigNumber("1"))
            .version(IconConverter.toBigNumber("3"))
            .timestamp(new Date().getTime() * 1000)
            .method("rentIcx")
            .params({ nftId, periodInDay })
            .build();
        if (isLedger) {
            const signedTransaction = await signTransaction(txObj, ledgerPath, ledgerTransport);
            const res = await iconService.sendTransaction(signedTransaction).execute();
            return res;
        } else {
            let scoreData = {};
            scoreData.value = JSON.stringify({
                jsonrpc: "2.0",
                method: "icx_sendTransaction",
                params: IconConverter.toRawTransaction(txObj),
                id: 50889,
            });

            const parsed = JSON.parse(scoreData.value);

            window.dispatchEvent(
                new CustomEvent("ICONEX_RELAY_REQUEST", {
                    detail: {
                        type: "REQUEST_JSON-RPC",
                        payload: parsed,
                    },
                })
            );
        }
    } catch (error) {
        console.log("Status: ", error);
        throw error;
    }
};

export const RentViaCROWN = async (nftId, rentFee, periodInDay) => {
    try {
        const data = {
            destinationContract: config.contract.RENTAL_SCORE,
            nftId,
            periodInDay,
            // action: "RENT_VIA_CROWN",
        };
        const fixedRentFee = (rentFee * 10 ** 18 * Number(periodInDay)).toLocaleString("fullwide", {
            useGrouping: false,
        });

        const { walletAddress, isLedger, ledgerPath, ledgerTransport } = store.getState().wallet;
        const txObj = {
            version: "0x3",
            from: walletAddress,
            to: config.contract.CROWN_SCORE,
            stepLimit: IconConverter.toHex(100000000),
            timestamp: IconConverter.toHex(Date.now() * 1000),
            nid: "0x" + config.icon.NETWORK_ID,
            nonce: "0x122",
            dataType: "call",
            data: {
                method: "transfer",
                params: {
                    _to: config.contract.RENTAL_SCORE, //token distribution
                    _value: fixedRentFee,
                    _data: objectToHexWithPrefix(data),
                },
            },
        };
        if (isLedger) {
            const signedTransaction = await signTransaction(txObj, ledgerPath, ledgerTransport);
            txObj["signature"] = signedTransaction.getSignature();
            const res = await postIconTransaction(txObj);
            return res;
        } else {
            let scoreData = {};
            scoreData.value = JSON.stringify({
                jsonrpc: "2.0",
                method: "icx_sendTransaction",
                params: txObj,
                id: 50889,
            });

            const parsed = JSON.parse(scoreData.value);

            window.dispatchEvent(
                new CustomEvent("ICONEX_RELAY_REQUEST", {
                    detail: {
                        type: "REQUEST_JSON-RPC",
                        payload: parsed,
                    },
                })
            );
        }
    } catch (error) {
        console.log(error);
        throw error;
    }
};

export const deListRent = async (nftId) => {
    try {
        const { walletAddress, isLedger, ledgerPath, ledgerTransport } = store.getState().wallet;

        const callTransactionBuilder = new IconBuilder.CallTransactionBuilder();
        const txObj = callTransactionBuilder
            .from(walletAddress)
            .to(config.contract.RENTAL_SCORE)
            .stepLimit(IconConverter.toBigNumber("10000000"))
            .nid(IconConverter.toBigNumber(config.icon.NETWORK_ID))
            .nonce(IconConverter.toBigNumber("1"))
            .version(IconConverter.toBigNumber("3"))
            .timestamp(new Date().getTime() * 1000)
            .method("deList")
            .params({ nftId })
            .build();
        if (isLedger) {
            const signedTransaction = await signTransaction(txObj, ledgerPath, ledgerTransport);
            const res = await iconService.sendTransaction(signedTransaction).execute();
            return res;
        } else {
            let scoreData = {};
            scoreData.value = JSON.stringify({
                jsonrpc: "2.0",
                method: "icx_sendTransaction",
                params: IconConverter.toRawTransaction(txObj),
                id: 50889,
            });

            const parsed = JSON.parse(scoreData.value);

            window.dispatchEvent(
                new CustomEvent("ICONEX_RELAY_REQUEST", {
                    detail: {
                        type: "REQUEST_JSON-RPC",
                        payload: parsed,
                    },
                })
            );
        }
    } catch (error) {
        console.log("Status: ", error);
        throw error;
    }
};

export const reverseDeList = async (nftId) => {
    try {
        const { walletAddress, isLedger, ledgerPath, ledgerTransport } = store.getState().wallet;

        const callTransactionBuilder = new IconBuilder.CallTransactionBuilder();
        const txObj = callTransactionBuilder
            .from(walletAddress)
            .to(config.contract.RENTAL_SCORE)
            .stepLimit(IconConverter.toBigNumber("10000000"))
            .nid(IconConverter.toBigNumber(config.icon.NETWORK_ID))
            .nonce(IconConverter.toBigNumber("1"))
            .version(IconConverter.toBigNumber("3"))
            .timestamp(new Date().getTime() * 1000)
            .method("reverseDeList")
            .params({ nftId })
            .build();
        if (isLedger) {
            const signedTransaction = await signTransaction(txObj, ledgerPath, ledgerTransport);
            const res = await iconService.sendTransaction(signedTransaction).execute();
            return res;
        } else {
            let scoreData = {};
            scoreData.value = JSON.stringify({
                jsonrpc: "2.0",
                method: "icx_sendTransaction",
                params: IconConverter.toRawTransaction(txObj),
                id: 50889,
            });

            const parsed = JSON.parse(scoreData.value);

            window.dispatchEvent(
                new CustomEvent("ICONEX_RELAY_REQUEST", {
                    detail: {
                        type: "REQUEST_JSON-RPC",
                        payload: parsed,
                    },
                })
            );
        }
    } catch (error) {
        console.log("Status: ", error);
        throw error;
    }
};

export const claimRentFees = async () => {
    try {
        const { walletAddress, isLedger, ledgerPath, ledgerTransport } = store.getState().wallet;

        const callTransactionBuilder = new IconBuilder.CallTransactionBuilder();
        const txObj = callTransactionBuilder
            .from(walletAddress)
            .to(config.contract.RENTAL_SCORE)
            .stepLimit(IconConverter.toBigNumber("10000000"))
            .nid(IconConverter.toBigNumber(config.icon.NETWORK_ID))
            .nonce(IconConverter.toBigNumber("1"))
            .version(IconConverter.toBigNumber("3"))
            .timestamp(new Date().getTime() * 1000)
            .method("claimAllRent")
            .params({})
            .build();
        if (isLedger) {
            const signedTransaction = await signTransaction(txObj, ledgerPath, ledgerTransport);
            const res = await iconService.sendTransaction(signedTransaction).execute();
            return res;
        } else {
            let scoreData = {};
            scoreData.value = JSON.stringify({
                jsonrpc: "2.0",
                method: "icx_sendTransaction",
                params: IconConverter.toRawTransaction(txObj),
                id: 50889,
            });

            const parsed = JSON.parse(scoreData.value);

            window.dispatchEvent(
                new CustomEvent("ICONEX_RELAY_REQUEST", {
                    detail: {
                        type: "REQUEST_JSON-RPC",
                        payload: parsed,
                    },
                })
            );
        }
    } catch (error) {
        console.log("Status: ", error);
        throw error;
    }
};
export const syncClaimableRent = async () => {
    try {
        const { walletAddress, isLedger, ledgerPath, ledgerTransport } = store.getState().wallet;

        const callTransactionBuilder = new IconBuilder.CallTransactionBuilder();
        const txObj = callTransactionBuilder
            .from(walletAddress)
            .to(config.contract.RENTAL_SCORE)
            .stepLimit(IconConverter.toBigNumber("10000000"))
            .nid(IconConverter.toBigNumber(config.icon.NETWORK_ID))
            .nonce(IconConverter.toBigNumber("1"))
            .version(IconConverter.toBigNumber("3"))
            .timestamp(new Date().getTime() * 1000)
            .method("syncUsersClaimableAmount")
            .params({ user: walletAddress })
            .build();
        if (isLedger) {
            const signedTransaction = await signTransaction(txObj, ledgerPath, ledgerTransport);
            const res = await iconService.sendTransaction(signedTransaction).execute();
            return res;
        } else {
            let scoreData = {};
            scoreData.value = JSON.stringify({
                jsonrpc: "2.0",
                method: "icx_sendTransaction",
                params: IconConverter.toRawTransaction(txObj),
                id: 50889,
            });

            const parsed = JSON.parse(scoreData.value);

            window.dispatchEvent(
                new CustomEvent("ICONEX_RELAY_REQUEST", {
                    detail: {
                        type: "REQUEST_JSON-RPC",
                        payload: parsed,
                    },
                })
            );
        }
    } catch (error) {
        console.log("Status: ", error);
        throw error;
    }
};
