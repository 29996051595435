import { getLootBoxDetails } from "@api/api";
import PrimaryButton from "@components/buttons/primary.button";
import ImageLoader from "@misc/ImageLoader";
import { useEffect, useState } from "react";
import { CROWN, YELLOW_ICONEX } from "../../core/constants/images";
const GameReward = ({
  storeRewardData,
  continuePostGames,
  setShowRewardPage,
}: any) => {
  console.log("storeRewardData", storeRewardData);
  const REACT_APP_IMAGE_BASE_URL = process.env.REACT_APP_IMAGE_BASE_URL;
  const [showSpinner, setShowSpinner] = useState(false);
  let rewardBackground = "rewardBgHH";
  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowSpinner(true);
    }, 0);
    return () => clearTimeout(timeout);
  }, []);

  const [lootboxDetails, setLootboxDetails] = useState<any>({});

  useEffect(() => {
    async function fetchLootDetails() {
      try {
        try {
          if (
            storeRewardData.name !== "ICX" ||
            storeRewardData.name !== "CROWN"
          ) {
            const result = await getLootBoxDetails(Number(storeRewardData.id));
            setLootboxDetails(result?.data);
          }
        } catch (e) {
          setLootboxDetails({});
        }
      } catch (e) {
        console.log(e);
      }
    }
    fetchLootDetails();
  }, [storeRewardData]);

  const rewardInfo = () => {
    if (storeRewardData?.name === "highestDamageDealer") {
      rewardBackground = "rewardBgDD";
      return {
        name: "ANNIHILATOR",
        title: "Congratulations! You dealt the most damage.",
        desc: "You won an annihilator crate!",
      };
    } else if (storeRewardData?.name === "mvp") {
      return {
        name: "MOST VALUABLE PLAYER (MVP)",
        title: "Congratulations! You made the most contribution.",
        desc: "You won an MVP crate!",
      };
    } else if (storeRewardData?.name === "highestHealer") {
      return {
        name: "SAVIOR OF SOULS (SOS)",
        title: "Congratulations! You were the highest healer.",
        desc: "You won an SOS crate!",
      };
    } else if (storeRewardData?.name === "winner") {
      return {
        name: "WINNER",
        title: "Congratulations! Your team won the war.",
        desc: `You won an ${lootboxDetails?.name ?? ""}!`,
      };
    } else if (storeRewardData?.name === "ICX") {
      rewardBackground = "rewardBgPay";
      return {
        name: "VICTORY",
        title: "Congratulations! for the",
        desc: `You have earned a bounty of ICX.`,
      };
    } else if (storeRewardData?.name === "CROWN") {
      rewardBackground = "rewardBgPay";
      return {
        name: "VICTORY",
        title: "Congratulations! for the",
        desc: `You have earned a bounty of CROWN.`,
      };
    }
  };

  const getReward = rewardInfo();

  return (
    <div className="gameRewardWrapperOverlay flex items-center justify-center">
      <div className="gameRewardWrapper flex items-center justify-center gap-4 flex-col">
        <div className="rewardText animate__animated animate__fadeInDown">
          <p className="topText">{getReward?.title}</p>
          <h1 className="text-center">{getReward?.name}</h1>
          <p className="bottomText">{getReward?.desc}</p>
        </div>
        <div className="flex items-center justify-center">
          <div
            className={`${showSpinner &&
              "rewardSpinner"} animate__animated animate__zoomIn animate__delay-3s`}
          ></div>

          <div
            className={` ${rewardBackground}  flex gap-20 items-center justify-center animate__animated animate-duration-3s  animate__zoomIn animate__delay-1s`}
          >
            {storeRewardData?.name === "ICX" ||
            storeRewardData?.name === "CROWN" ? (
              <div className="relative flex gap-2 flex-col">
                <ImageLoader
                  className="animate__animated  animate__zoomIn animate__delay-1s"
                  width="130px"
                  height="130px"
                  src={storeRewardData?.name === "ICX" ? YELLOW_ICONEX : CROWN}
                  alt="reward"
                />
                <div className="backgroundHide"></div>
                <hr className="border-yellow-300" />
                <div className="flex flex-col items-center justify-center">
                  <p className="unSelectedTextState text-white text-3xl">
                    {storeRewardData?.id} {storeRewardData?.name}
                  </p>
                  <p className="uppercase text-white">reward</p>
                </div>
              </div>
            ) : (
              <ImageLoader
                className="animate__animated  animate__zoomIn animate__delay-1s"
                width="170px"
                height="190px"
                src={lootboxDetails?.imageUrl}
                alt="reward"
              />
            )}
          </div>
        </div>
        <PrimaryButton
          className="animate__animated animate__fadeInUp"
          width="214px"
          buttonName="continue"
          onClick={() => {
            setShowRewardPage(false);
            continuePostGames();
          }}
        />
      </div>
    </div>
  );
};

export default GameReward;
