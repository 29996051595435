import { FC, useEffect, useState } from "react";
import Modal from "@components/modal/modal.component";
import PrimaryButton from "../../buttons/primary.button";
import { CROWN, ICX } from "@constants/images";
import { useDetailGlobalContext } from "@context/detailProvider";
import { AsyncState, IRentalModal } from "./rental.interface";
import { useNFTDetailGlobalContext } from "@context/nftInformation";
import { NFT_RENT_TYPE } from "@constants/nft";
import { RentViaCROWN, RentViaICX } from "@services/icon.service";
import RentalWrapper from "./RentalWrapper.component";
import { useNFTInformation } from "@context/nftInfoProvider";
import { useNavigate } from "react-router-dom";
import { HYPERLINKS } from "@misc/links";
import { useTransactionValidate } from "@hooks/transactionResult";
import { useSelector } from "react-redux";
import { RootState } from "@redux/store";

interface IRentModal extends IRentalModal {
  period: number;
  fetchRentalMarketplaceInfo: () => void;
}
const RentModal: FC<IRentModal> = ({
  period,
  selectedChar,
  isVisible,
  setRentalModal,
  fetchRentalMarketplaceInfo,
  setSelectedChar
}) => {
  const { getTxResultWithTimer } = useTransactionValidate({ onHandle: () => { } });
  const [asyncState, setAsyncState] = useState(AsyncState.IDLE);
  const { userCrown, userICX, getUserCrownAndICXBalance } = useDetailGlobalContext();
  const navigate = useNavigate();
  const { isLedger } = useSelector((state: RootState) => state.wallet)
  const {
    reFetchDetails,
    setRefetchData,
    reFetchChangeData,
  } = useDetailGlobalContext();
  const {
    setNftData,
    setRentalIndex,
    setCurrentBatchIndex,
  } = useNFTDetailGlobalContext();
  const { setRerenderCollectionToggler, reRenderCollectionToggler } = useNFTInformation();

  const differenceInAmount =
    selectedChar?.rental.rentFee! * period -
    (selectedChar?.rental.rentType === NFT_RENT_TYPE.CROWN_CURRENCY
      ? userCrown
      : userICX);

  const onAction = async () => {
    try {
      setAsyncState(AsyncState.LOADING);
      if (selectedChar?.rental.rentType === NFT_RENT_TYPE.CROWN_CURRENCY) {
        const result = await RentViaCROWN(
          selectedChar.gangstaBetDetails.nftId.toString(),
          selectedChar.rental.listedRentFee,
          period.toString()
        );
        if (isLedger) {
          if (result?.name === "TransportStatusError") {
            console.log("Ledger cancelled!!!");
          } else if (result) {
            getTxResultWithTimer(result as string);
          } else {
            console.log("Error on ledger");
          }
        }
      }
      else if (selectedChar?.rental.rentType === NFT_RENT_TYPE.ICX_CURRENCY) {
        const result: any = await RentViaICX(
          selectedChar.gangstaBetDetails.nftId.toString(),
          selectedChar.rental.listedRentFee,
          period.toString()
        );
        if (isLedger) {
          console.log({ result })
          if (result?.name === "TransportStatusError") {
            console.log("Ledger cancelled!!!");
          } else if (result) {
            getTxResultWithTimer(result as string);
          } else {
            console.log("Error on ledger");
          }
        }
      }
    } catch (error) {
      setAsyncState(AsyncState.IDLE);
    }
  };

  useEffect(() => {
    (async () => {
      getUserCrownAndICXBalance()
    })();
  }, []);

  const onContinue = () => {
    setRentalModal(null);
    if (asyncState === AsyncState.SUCCESS) {
      //refetch all NFT details (rent + inventory)
      setSelectedChar(null);
      fetchRentalMarketplaceInfo();
      setRerenderCollectionToggler(!reRenderCollectionToggler);
      setRentalIndex(null);
      setNftData([]);
      setCurrentBatchIndex(0);
      setRefetchData(!reFetchChangeData);
      reFetchDetails();
      getUserCrownAndICXBalance();
    }
  };

  if (differenceInAmount > 0) {
    return (
      <Modal
        isVisible={isVisible}
        setIsVisible={onContinue}
        glow="fail"
        modalTitle={
          selectedChar?.rental.rentType === NFT_RENT_TYPE.CROWN_CURRENCY
            ? "Not enough CROWN"
            : "Not enough ICX"
        }
        className=" rental-modal-outer-wrapper flex flex-col justify-center items-center"
      >
        <p className="description-text flex flex-col items-center !text-white text-center">
          <p className="flex gap-1.5 justify-center items-end">
            Rent Time: <span>{period > 0 ? <> {period} {period === 1 ? 'day' : 'days'}</> : <>-</>}
            </span>
          </p>
          <br />
          <p className="flex gap-1.5 justify-center items-end">
            Total Rent cost:
            <span className="inline-flex gap-2 items-end">
              <img
                src={
                  selectedChar?.rental.rentType === NFT_RENT_TYPE.CROWN_CURRENCY
                    ? CROWN
                    : ICX
                }
                alt="currency"
                className="w-6 h-6"
              />
              {selectedChar?.rental.listedRentFee}
            </span>
          </p>
          <hr className="horizontal-seperator" />
          <p className="inline-flex gap-1.5">
            You need{" "}
            <span>
              {" "}
              {differenceInAmount} more $
              {selectedChar?.rental.rentType === NFT_RENT_TYPE.CROWN_CURRENCY
                ? "CROWN"
                : "ICX"}
            </span>{" "}
            to rent this GangstaBet.
          </p>
          <hr className="horizontal-seperator" />
        </p>
        <PrimaryButton onClick={onContinue} buttonName="Okay" />
      </Modal>
    );
  }

  return (
    <RentalWrapper
      type="RENT"
      asyncState={asyncState}
      setAsyncState={setAsyncState}
      setRentalModal={setRentalModal}
    >
      <Modal
        isVisible={isVisible}
        onModalClose={onContinue}
        glow={asyncState === AsyncState.SUCCESS ? "success" : ""}
        modalTitle={
          asyncState === AsyncState.SUCCESS
            ? "GangstaBet Rented"
            : "Confirm rent transaction"
        }
        // closeHandler={() => { navigate("/gangstabet/rent") }}
        // closeHandler={() => { fetchRentalMarketplaceInfo() }}

        className=" rental-modal-outer-wrapper flex flex-col justify-center items-center"
      >
        <p className="description-text text-center">
          {asyncState === AsyncState.SUCCESS ? (
            <>
              You have successfully rented {selectedChar?.gangstaBetDetails?.nftName}
              <br />
              <div className="success-description-text py-4 mt-6 mb-8">
                GangstaBet Name: <span>{selectedChar?.gangstaBetDetails?.nftName}</span>
                <br />
                Rent Time: <span>{period > 0 ? <> {period} {period === 1 ? 'day' : 'days'}</> : <>-</>}</span>
                <br />
                <p className="flex gap-1.5 justify-center items-end">
                  Total Rent cost:
                  <span className="inline-flex gap-2 items-end">
                    <img
                      src={
                        selectedChar?.rental.rentType ===
                          NFT_RENT_TYPE.CROWN_CURRENCY
                          ? CROWN
                          : ICX
                      }
                      alt="currency"
                      className="w-6 h-6"
                    />
                    {period * selectedChar?.rental.listedRentFee!}
                  </span>
                </p>
              </div>
            </>
          ) : (
            <>
              Are you sure you want to rent this GangstaBet? <br /> You cannot
              undo this action once you press the <br /> “Yes, Rent NFT” button.
              <div className="success-description-text py-4 mt-6 mb-8">
                GangstaBet Name: <span>{selectedChar?.gangstaBetDetails?.nftName}</span>
                <br />
                Rent Time: <span>{period > 0 ? <> {period} {period === 1 ? 'day' : 'days'}</> : <>-</>}</span>
                <br />
                <p className="flex gap-1.5 justify-center items-end">
                  Total Rent cost:
                  <span className="inline-flex gap-2 items-end">
                    <img
                      src={
                        selectedChar?.rental.rentType ===
                          NFT_RENT_TYPE.CROWN_CURRENCY
                          ? CROWN
                          : ICX
                      }
                      alt="currency"
                      className="w-6 h-6"
                    />
                    {period * selectedChar?.rental.listedRentFee!}{" "}
                    ({selectedChar?.rental.listedRentFee} per day)
                  </span>
                </p>
              </div>
            </>
          )}
        </p>
        <div className="flex flex-col items-center">
          {asyncState === AsyncState.IDLE && (
            <PrimaryButton onClick={onAction} buttonName="Yes, Rent NFT" />
          )}
          {asyncState === AsyncState.SUCCESS ? (
            <>
              <PrimaryButton
                width="250px"
                onClick={() =>
                  window.open(
                    HYPERLINKS.CRAFT_MARKETPLACE,
                    "_blank"
                  )
                }
                buttonName="Explore Marketplace"
              />
              <PrimaryButton
                onClick={() => navigate("/profile/rent")}
                buttonName="Check NFT"
              />
            </>
          ) : (
            <button onClick={() => setRentalModal(null)} className="rent-price-dropdown !text-center !py-[0.5em] outline-none">
              Not Now
            </button>
          )}
        </div>
      </Modal>
    </RentalWrapper>
  );
};

export default RentModal;
