import React from 'react';
import { usePaginatedRentalMarket } from '@hooks/usePaginatedRentalData';
import NFTCard from '@components/card/nftCard.component';
import { TEST_NFTS } from '@constants/images';
import { NFT_RENT_STATUS } from '@constants/nft';
import { ZERO_ADDRESS } from '@type/rental.type';

const Test: React.FC = () => {
    const url = "https://game-server-base.06vc7g8c0t7h6.us-west-2.cs.amazonlightsail.com/api/gangwar/history"
    // const { data, loading, loaderRef } = usePaginatedRentalMarket(url, 10, "MARKETPLACE", walletAddress ?? ZERO_ADDRESS);
    // console.log({ data })
    const gbData = [{
        "gangstaBetDetails": {
            "nftId": 297,
            "nftImage": "https://d2l8dezwbqkb9.cloudfront.net/ipfs/img/4944.gif",
            "nftName": "GangstaBet #297",
            "ownedBy": "hx71c278bcd023e8bb889c91b006666152750e9f10",
            "gender": "Female",
            "race": "Cyborg",
            "classType": "Commander",
            "levelNumber": 1,
            "characterType": "Gangster",
            "levelType": "Mob Boss"
        },
        "defaultWeapons": {
            "weaponName": "Revolver",
            "weaponType": "Default Weapon",
            "Ranged": "Range",
            "image": "https://d3o1y7koafmnju.cloudfront.net/default_equipment_images/2.png"
        },
        "skills": {
            "primary": {
                "strength": 2.77,
                "accuracy": 2.62,
                "mastery": 2.42,
                "agility": 2.0700000000000003,
                "luck": 2.69,
                "total": 12.57
            },
            "secondary": {
                "health": 509,
                "speed": 15,
                "duraAmmo": 0.02,
                "dodgeChance": 0,
                "hitChance": 0.01,
                "criticalChance": 0,
                "supportEffectiveness": 0,
                "SupportTriggerChance": 0,
                "meleeDamage": 0.01
            }
        },
        "rental": {
            "borrower": "hx0000000000000000000000000000000000000000",
            "claimableAmount": "0",
            "deListed": "0",
            "expirationTimestamp": "0",
            "id": "2",
            "listedTimestamp": "1707721913164342",
            "nftId": "297",
            "owner": "hx77abefe4a33e31c208afee301150fb61ad3f0be2",
            "period": "0",
            "rentFee": 15,
            "rentType": "ICX_CURRENCY",
            "startTimestamp": "0",
            "status": "AVAILABLE_FOR_RENT"
        },
        "hospitalization": {
            "isHospitalized": false,
            "outOfHospitalizationTimer": 3476546
        },
        "lockStatus": false
    }]
    return (
        <div className='m-8'>
            {/* Display data */}
            {/* {data.map((item: any, index: number) => (
                <div className='text-white' key={index}>{index} === {item.title}</div>
            ))}

            {loading && <div className='text-white'>Loading...</div>}
            <div ref={loaderRef} style={{ height: '10px' }} /> */}

            {/* {gbData.map((item: any) => {
                return <NFTCard
                    cardType="nftCardImageChooseCharacter"
                    nftImage={item.gangstaBetDetails.nftImage ?? TEST_NFTS}
                    // width="140px"
                    // height="180px"
                    width="230px"
                    height="300px"
                    hospitalized={{
                        cardSize: "NFTOnIllSm",
                        // hospitalizedTimer: 1707925830000000,
                        hospitalizedTimer: -1,

                    }}

                    rental={
                        {
                            cardSize: "NFTOnRentSm",
                            rentalType: item?.rental.status,
                            rentalTimer: 1707925830000000,
                            // rentalTimer: -1,
                            rentalName: "For Rent",
                        }
                    }

                    damage={
                        item?.cooldownActivated
                            ? {
                                isDamaged: true,
                                cardSize: "isNFTDamaged-sm",
                                nameName: "Hospitalized",
                                damageRemainingTime: "",
                            }
                            : item?.rental.status ===
                                NFT_RENT_STATUS.AVAILABLE_FOR_RENT
                                ? {
                                    isDamaged: true,
                                    isRental: true,
                                    cardSize: "isNFTonRent-lg",
                                    nameName: "FOR RENT",
                                    damageRemainingTime: "",
                                }
                                : item?.rental.status === NFT_RENT_STATUS.RENTED
                                    ? {
                                        isDamaged: false,
                                        isRental: true,
                                        cardSize: "isNFTRented-sm",
                                        nameName: "RENTED",
                                        damageRemainingTime: "",
                                    }

                                    : item?.rental.status ===
                                        NFT_RENT_STATUS.EXPIRING
                                        ? {
                                            isDamaged: true,
                                            isRental: true,
                                            cardSize: "isNFTDamaged-sm",
                                            nameName: "EXPIRING",
                                            damageRemainingTime: "",
                                        }
                                        : item?.rental.status ===
                                            NFT_RENT_STATUS.IN_RENT
                                            ? {
                                                isDamaged: true,
                                                isRental: true,
                                                cardSize: "isNFTinRent-sm",
                                                nameName: "ON RENT",
                                                damageRemainingTime: "",
                                            }
                                            : {}
                    }
                    cooldownTimer={
                        item?.cooldownActivated
                            ? item?.cooldownTimer
                            : item.rental.expirationTimestamp! / 1000 >
                                Date.now() ? item.rental.expirationTimestamp
                                : item.rental.status === NFT_RENT_STATUS.EXPIRING
                                    ? item.rental.expiry : undefined
                    }
                    locked={
                        item?.isNFTLocked && {
                            isLocked: true,
                            cardSize: "isNFTDamaged-sm",
                        }
                    }
                // hospitalisedTime={"03:24:47"}
                />
            })} */}



        </div>
    );
};

export default Test;
