import CloseButton from "@components/buttons/colse.button";
import {
  PLAY_CLOSE_SOUND,
  PLAY_NORMAL_CLICK,
  PLAY_INVENTORY_CLICK,
} from "@constants/audio";
import { setLoadingState } from "@redux/features/stateSlice";
import { useDispatch } from "react-redux";
import { IModal } from "src/core/interfaces/modal.interface";

const Modal = ({
  modalTitle,
  children,
  isVisible,
  setIsVisible,
  width = "500px",
  className,
  height,
  closeHandler,
  overlayWidth,
  overlayHeight,
  glow,
  loading,
  onModalClose = () => null,
}: IModal) => {
  const dispatch = useDispatch();
  if (!isVisible) return null;
  return (
    <div
      className={`modalOverlay ${className?.includes(" ") &&
        className.split(" ")[1]}`}
      style={{ width: overlayWidth, height: overlayHeight }}
    >
      <div
        className={`childOverlay relative ${className?.includes(" ") &&
          className.split(" ")[1]}`}
      >
        <div style={{ width: width, height }} className="modalWrapper">
          <div className={`glowEllipse ${glow}`}></div>
          {!loading && (
            <div
              onClick={() => {
                if (closeHandler) {
                  closeHandler();
                }
                onModalClose()
                setIsVisible(false);
                PLAY_CLOSE_SOUND();
                // dispatch(setLoadingState(false));
              }}
              className="modalCloseButton"
            >
              <CloseButton buttonType="closeOutline" />
            </div>
          )}
          <hr className="modalCutterTopLeft" />
          <hr className="modalCutterBottomLeft" />
          <hr className="modalCutterBottomRight" />

          <div className="modalComponent">
            {modalTitle && (
              <div className="modalTitle">
                <span className={`titleText ${glow ? glow : ""} text-center`}>
                  {modalTitle}
                </span>
              </div>
            )}

            <div className={`${className ? className : "modalBody"}`}>
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
