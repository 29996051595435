import { getSimulationCount } from "@services/icon.service";
import { createContext, useState, useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDetailGlobalContext } from "./detailProvider";

const historyInformation = createContext<any>(null);
const History = ({ children }: any) => {
  const { activeTab } = useDetailGlobalContext();

  const [totalSimulationCount, setTotalSimulationCount] = useState<number>(0);
  useEffect(() => {
    async function getHistory() {
      const count = await getSimulationCount();
      setTotalSimulationCount(count);
    }
    getHistory();
  }, [activeTab]);

  return (
    <historyInformation.Provider
      value={{
        totalSimulationCount,
      }}
    >
      {children}
    </historyInformation.Provider>
  );
};

const useHistoryGlobalContext = () => {
  return useContext(historyInformation);
};

export { History, useHistoryGlobalContext };
