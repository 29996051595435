import { useEffect } from "react";
export const useCardAnimation = (id: string, animationToggle: boolean) => {
  useEffect(() => {
    if (animationToggle === true) {
      document.getElementById(id)?.classList.remove("bounceFromLeft");
      document.getElementById(id)?.classList.add("bounceFromRight");
    } else {
      document.getElementById(id)?.classList.add("bounceFromLeft");
      document.getElementById(id)?.classList.remove("bounceFromRight");
    }
  }, [animationToggle]);
};
