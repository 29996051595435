export const colorDetails: { [key: string]: string } = {
    0: "",
    1: "grey",
    2: "lightYellow",
    3: "orange",
    4: "lightOrange",
    5: "skyBlue",
    6: "lightGreen",
    7: "lightRed",
    8: "lightYellow",
    45: "lightBlue",
    46: "lightBlue",
    47: "lightBlue",
    50: "darkOrange",
    51: "darkOrange",
    52: "darkOrange",
    35: "goldenLight",
    36: "goldenLight",
    37: "goldenLight",
    39: "goldenLight",
    40: "goldenLight",
    41: "goldenLight",
    42: "goldenLight",
    43: "goldenLight",
    38: "goldenLight",
    // 20: "goldenLight",
};
export type ColorDetailsTypes =
    | "0"
    | "1"
    | "2"
    | "3"
    | "4"
    | "5"
    | "6"
    | "7"
    | "8"
    | "35"
    | "36"
    | "37"
    | "38"
    | "39"
    | "40"
    | "41"
    | "42"
    | "43"
    | "44"
    | "45"
    | "46"
    | "47"
    | "48"
    | "49"
    | "50"
    | "51"
    | "52"
    | "53";
