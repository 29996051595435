import { Tooltip } from "react-tooltip";
import ImageLoader from "@misc/ImageLoader";
import { RANGE } from "@constants/images";
import { RxStack } from "react-icons/rx";

const WeaponTooltip = ({ tooltipId, details, place = "right" }: any) => {
  return (
    <>
      <Tooltip
        place={place}
        delayShow={800}
        className={`tooltipWeaponsLoadout tooltipPlace_${place}`}
        anchorSelect={`#${tooltipId}`}
      >
        <div className="flex flex-col gap-5">
          <div className="weaponsDetails flex gap-6 items-center ">
            <div className="imageHodler userloadout tooltipLoadout">
              <ImageLoader
                style={{ padding: "5px" }}
                src={details?.image}
                alt="weapons"
              />
            </div>
            <div className="flex flex-col gap-2">
              <h2 className="tooltipLoadoutName">{details?.name}</h2>
              <div className="flex weaponsInnerDetails gap-2">
                <div className="targetRange flex gap-1 items-center">
                  <ImageLoader width="18px" src={RANGE} alt="ranged" />
                  <p className="rangeName">{details?.type}</p>
                </div>
                <div className="targetBasic gap-1 flex items-center">
                  <RxStack size={18} className="" />
                  <p className="basicName">Basic</p>
                </div>
              </div>
            </div>
          </div>
          <hr className="horizontalSeperator" />
          <p className="loadoutDescription">{details?.description}</p>
          <hr className="horizontalSeperator" />
          <div className="weaponsStats flex gap-10">
            <div className="flex flex-col gap-2">
              <div>
                <p className="statsKeys">Damage</p>
                <p className="statsValue">
                  + {details?.stats?.damage}
                  <span className="text-sm">
                    ({details?.stats?.numberofHits} hits)
                  </span>
                </p>
              </div>
              <div>
                <p className="statsKeys">Hit Chance</p>
                <p className="statsValue">
                  {Number(details?.stats?.hitChance * 100).toFixed(0)}%
                </p>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <div>
                <p className="statsKeys">
                  Durability{" "}
                  {details?.type === "Melee" ? "Base Durability" : "Base Ammo"}
                </p>
                <p className="statsValue">
                  {details?.stats?.duraAmmo}
                  <span className="text-sm">
                    {" "}
                    (uses {details?.stats?.consumptionRate} per hit)
                  </span>
                </p>
              </div>
              <div>
                <p className="statsKeys"> Critical Hit Chance</p>
                <p className="statsValue">
                  {Number(details?.stats?.criticalChance * 100).toFixed(0)}%
                </p>
              </div>
            </div>
          </div>
        </div>
      </Tooltip>
    </>
  );
};
export default WeaponTooltip;
