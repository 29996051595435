import Header from "./Header";
import TeamTable from "@components/team/teamTable.component";
import TimeAndRound from "@components/team/timeAndRound.component";
import { getDB, getTeamA, getTeamB } from "@services/firebase.service";
import {
  getLockedNFTInSimulation,
  hasWalletParticipated,
} from "@services/icon.service";
import axios from "axios";
import { onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import MVP from "@components/team/mvp.component";
import PrimaryButton from "@components/buttons/primary.button";

const Overview = () => {
  const convertTeamToSame = (teamName: string) => {
    return teamName === "Team A" ? "teamA" : "teamB";
  };

  const getOpponentTeam = (team: string) => {
    return team === "teamA" ? "teamB" : "teamA";
  };

  const [open, setOpen] = useState<boolean>(false);
  const { id } = useParams();
  const [state, setstate] = useState<any>({
    isGameOver: false,
    isLobbyLocked: true,
    totalEventCount: 0,
    currentEvent: null,
    spotlight: "Team A",
    eventLogs: [
      {
        eventName: "GAME STARTING SOON",
        logCollection: [],
      },
    ],
    currentEventId: 0,
    currentEventName: "GAME STARTING SOON",
  });

  const [playersInfo, setPlayersInfo] = useState<any>({
    teamANftInfo: [],
    teamBNftInfo: [],
  });

  const [isWalletParticipated, setWalletParticipated] = useState<boolean>();
  const [participatedInTeam, setParticipatedInTeam] = useState<string>("");

  const { walletAddress } = useSelector((state: any) => state.wallet);

  const [loading, setLoading] = useState(false);

  const [totalHealth, setTotalHealth] = useState({
    teamA: {
      initial: 0,
      present: 0,
    },
    teamB: {
      initial: 0,
      present: 0,
    },
  });

  useEffect(() => {
    setLoading(true);
    const unsubscribe = onSnapshot(getDB(id), async (snapshot: any) => {
      const checkStatus = await hasWalletParticipated(id, walletAddress);
      setWalletParticipated(!!Number(checkStatus));

      const getIndividualTeams = async () => {
        const teamATempInfo: any[] = [];
        const teamBTempInfo: any[] = [];

        const querySnapshotTeamA = await getTeamA(id);
        const querySnapshotTeamB = await getTeamB(id);
        const nftTotalHealth = {
          teamA: {
            initial: 0,
            present: 0,
          },
          teamB: {
            initial: 0,
            present: 0,
          },
        };

        for await (const doc of querySnapshotTeamA.docs) {
          const currentDocData = doc.data();

          const nft_info = await axios.get(
            `${process.env.REACT_APP_GANGSTABET_URL}/0x${Number(
              doc.data().id
            ).toString(16)}`
          );
          nftTotalHealth["teamA"] = {
            present:
              nftTotalHealth.teamA.present +
              currentDocData.secondaryStats.currentStats.health,
            initial:
              nftTotalHealth.teamA.initial +
              currentDocData.secondaryStats.initialStats.health,
          };
          if (!!Number(checkStatus)) {
            const nftId = await getLockedNFTInSimulation(walletAddress, id);
            if (doc.data().id.toString() === Number(nftId).toString()) {
              setParticipatedInTeam("Team A");
            }
          }
          teamATempInfo.push({
            ...doc.data(),
            ...nft_info.data,
            team: "Team A",
          });
        }
        for await (const doc of querySnapshotTeamB.docs) {
          const currentDocData = doc.data();
          const nft_info = await axios.get(
            `${process.env.REACT_APP_GANGSTABET_URL}/0x${Number(
              doc.data().id
            ).toString(16)}`
          );
          nftTotalHealth["teamB"] = {
            present:
              nftTotalHealth.teamB.present +
              currentDocData.secondaryStats.currentStats.health,
            initial:
              nftTotalHealth.teamB.initial +
              currentDocData.secondaryStats.initialStats.health,
          };

          if (!!Number(checkStatus)) {
            const nftId = await getLockedNFTInSimulation(walletAddress, id);
            if (doc.data().id.toString() === Number(nftId).toString()) {
              setParticipatedInTeam("Team B");
            }
          }

          teamBTempInfo.push({
            ...doc.data(),
            ...nft_info.data,
            team: "Team B",
          });
        }
        setTotalHealth(nftTotalHealth);

        setPlayersInfo({
          teamANftInfo: teamATempInfo,
          teamBNftInfo: teamBTempInfo,
        });
      };

      getIndividualTeams();

      if (snapshot.data()?.eventLogs.length > 0) {
        const sn_data = snapshot.data();
        setstate({
          ...sn_data,
        });
      }

      setLoading(false);
    });

    return () => {
      unsubscribe();
    };
  }, []);
  return (
    <div className="warOverviewWrapperMain">
      <Header logs={state.eventLogs} />
      <div className="warOverviewWrapper container mx-auto">
        <div className="postGameHeading animate__animated animate__fadeInDownBig">
          <h1>{state?.winner}</h1>
        </div>
        <div className="flex gap-2">
          <div className="teamAGBTableWeapper">
            <TeamTable
              highestDamageDealer={state?.highestDamageDealer}
              highestHealer={state?.highestHealer}
              playerInfo={playersInfo.teamANftInfo}
              team="teamA"
            />
          </div>
          <div className="teamBGBTableWeapper">
            <TeamTable
              highestDamageDealer={state?.highestDamageDealer}
              highestHealer={state?.highestHealer}
              playerInfo={playersInfo.teamBNftInfo}
              team="teamB"
            />
          </div>
        </div>
        {state?.mvp && state?.mvp !== "0" && (
          <MVP nftId={state?.mvp as string} />
        )}
        <div className="flex items-center flex-col gap-2">
          <TimeAndRound
            duration={state?.duration}
            rounds={state?.currentRound}
          />
          <PrimaryButton
            onClick={() => {
              const url = `/battle/rewatch/${id}`;
              window.location.href = url;
              // window.open();
            }}
            width="250px"
            className="mb-8"
            buttonName="Replay"
          />
        </div>
      </div>
    </div>
  );
};

export default Overview;
