import { BATTLE_ROLES, CHANGE_TYPE, COLD_WATER_EFFECTS } from "@constants/war";

export interface IChangeAmount {
  changeType: CHANGE_TYPE;
  amount: number;
  character: number;
}

export interface ITeam {
  curHealth: number;
  totalHealth: number;
  team: string;
}

export interface IMainOrTargetRaw {
  id: number;
  curHealth: number;
  totalHealth: number;
  team: ITeam;
}

export interface IMainOrTarget {
  id?: number;
  team?: ITeam;
  hpChange: number;
  curHealth?: number;
  totalHealth?: number;
  durabilityChange?: number;
  ammoChange?: number;
  speedChange?: number;
  shieldChange?: number;
  coldWaterChange?: COLD_WATER_EFFECTS;
}

export enum CHANGE_DISPLAY_MAP {
  ammoChange = "AMMO",
  speedChange = "SPEED",
  shieldChange = "SHIELD",
  durabilityChange = "DURA",
  hpChange = "HP",
}

export type attackType = "HIT" | "MISS" | "DODGED" | "CRITICAL_HIT";

export type IMainsOrTargets = {
  ids: Array<number>;
  team: "team A" | "team B";
};

export interface IBattleRawEvent {
  narrationType: string; // ITEM_TAKEN | ACTION_LOG | EFFECT_LOG | ABILITY_LOG
  narration: string; // what happened on this battle event
  changedAmount: Array<IChangeAmount> | -1; // change on each character due to this event
  main: IMainOrTargetRaw | -1;
  target: IMainOrTargetRaw | -1;
  distractedNFTs?: Array<number>;
  mainWep?: { type: string; name: string }; // 1v1 weapon. name could be PISTOL, etc.
  actionType?: string; // 1v1 sub attack type on actual attack
  attackType?: attackType | -1; // 1v1 attack type
  eventName?: string; // 1v1 attack's group event name
  itemType?: string; // consumption item name:  ANTIDOTE |
  effectType?: string; // poison, etc
  abilityType?: string; // ability type for ABILITY_LOG
  extraDamage?: Array<any>;
  gameState?: Record<string, string | Record<string, string | number>>;
}

export interface IBattleRawEventGroup {
  eventName: string;
  logCollection: Array<IBattleRawEvent>;
  // follwing 3 fields for formatting logs on sidebar
  mains?: IMainsOrTargets;
  targets?: IMainsOrTargets;
  contextLog?: string;
}

export interface IBattleEvent {
  eventGroupId: number; // identify which group this event belongs to
  narration: string;
  narrationType: string; // todo: what are the possibilities
  eventName: string; // todo: what are other possibilities
  main: IMainOrTarget;
  distractedNFTs?: Array<number>;
  target: IMainOrTarget;
  actionType: string | undefined;
  attackType: attackType | undefined; // 1v1 MISS/HIT/DODGED
  equipment: string | undefined; // battle weapons / consumption items / poison
  isAnimated: boolean;
  mains?: IMainsOrTargets;
  targets?: IMainsOrTargets;
  contextLog?: string;
  extraDamage?: Array<any>;
  firstAnimOfEventGroup?: boolean;
}

export interface IActiveBattleDetails {
  battleIndex: number;
  eventGroupId: number;
  involvedIds: Array<number | undefined | null>;
  [BATTLE_ROLES.MAIN]: IMainOrTarget;
  [BATTLE_ROLES.TARGET]: IMainOrTarget;
  [BATTLE_ROLES.DISTRACT_NFTS]?: Array<number>;
  narrationType?: string; // ITEM_TAKEN | EFFECT_LOG | ACTION_LOG
  actionType?: string;
  equipment?: string; // GUN, POISON, CAKE, GRENADE, ...
  attackType?: attackType;
  actualWeapon?: string;
  extraDamage?: Array<any>;
  eventName?: string;
  firstAnimOfEventGroup?: boolean;
}

export interface IAnimatedWarHealth {
  currentTeamHealth: number;
  initialTeamHealth: number;
  nftHealth: Array<IIndividualNftHealth>;
}

export type IIndividualNftHealth = {
  id: number;
  currentHealth: number;
  initialHealth: number;
};
