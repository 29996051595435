import React, { useRef, useEffect } from 'react';
import ChatMessage from './ChatMessage';
import { useMessages } from '@pages/warScreen/hooks/useMessage';

const ChatMessages = ({ chatRoomId, userStatsDetails, playersInfo } : any) => {
    const messages = useMessages(`gangwars#${chatRoomId}`);
    const messageEndRef = useRef<any>(null);
    useEffect(() => {
        messageEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);

  return (
        <div className='chat-messageContainer'>
            {messages && messages.map((x: any) => (
                <ChatMessage key={x?.id} isOwnMessage={true} message={x} userStatsDetails={userStatsDetails} playersInfo={playersInfo} />
            ))}
            <div ref={messageEndRef}></div>
        </div>
  );
};

export default ChatMessages;
