import { useNavigate } from "react-router-dom";
import { useLeaderboardContext } from "../context/LeaderboardContext";
import LEADERBOARD_ROUTES from "../routes.leaderboard";

export default function IntervalSelector() {
    const { intervalFilter } = useLeaderboardContext();
    const navigate = useNavigate();

    return (
        <div className="interval-selector">
            <span
                className={intervalFilter === "weekly" ? "selected-interval" : ""}
                onClick={() => navigate(LEADERBOARD_ROUTES.BASE + LEADERBOARD_ROUTES.WEEKLY)}
            >
                Weekly
            </span>
            <span
                className={intervalFilter === "monthly" ? "selected-interval" : ""}
                onClick={() => navigate(LEADERBOARD_ROUTES.BASE + LEADERBOARD_ROUTES.MONTHLY)}
            >
                Monthly
            </span>{" "}
            <span
                className={intervalFilter === "seasonal" ? "selected-interval" : ""}
                onClick={() => navigate(LEADERBOARD_ROUTES.BASE + LEADERBOARD_ROUTES.SEASONAL)}
            >
                Seasonal
            </span>
            <span
                className={intervalFilter === "all" ? "selected-interval" : ""}
                onClick={() => navigate(LEADERBOARD_ROUTES.BASE + LEADERBOARD_ROUTES.ALL)}
            >
                All
            </span>
        </div>
    );
}
