import { CROWN } from "@constants/images";
import NFTCard from "@components/card/nftCard.component";
import { useState, useEffect } from "react";
import SelectionScreen from "@components/screen/selectionScreen.component";
import EquipmentPlainCard from "@components/card/equipmentPlainCard.component";
import { GiLoveMystery } from "react-icons/gi";
import PrimaryButton from "@components/buttons/primary.button";
import { AiOutlineRight } from "react-icons/ai";
import { RANGE, ABILITY, ITEM, WEAPON } from "@constants/images";
import CommonModal from "@components/modal/commonModal.component";
import { useCardAnimation } from "@hooks/useCardAnimation";
import { FaPlus, FaUser } from "react-icons/fa";
import "react-tooltip/dist/react-tooltip.css";
import SwitchWeapon from "@components/switchWeapon/SwitchWeapon.modal";
import AbilityScreen from "./details.component";
import { IUserEquipment } from "@interfaces/equipment.interface";
import { IItems } from "@interfaces/items.interface";
import { IGetUserAbility } from "@interfaces/ability.interface";
import { abilityIndexType, itemIndexType, slotType } from "@type/button.types";
import { IEquipedLoadout } from "@interfaces/items.interface";
import {
    emptyStructureForAbilities,
    emptyStructureForEquipments,
    emptyStructureForItems,
} from "@misc/structure";
import { lockUnlockLoadouts } from "@utils/lockUnlockLoadouts";
import { convertTwoArrayToObject } from "@utils/arrayToObject";
import {
    getLobbyLockdownPeriod,
    getSimulationDetail,
    updateLoadouts,
} from "@services/icon.service";
import { useTransactionValidate } from "@hooks/transactionResult";
import Countdown from "react-countdown-simple";
import React from "react";
import { statNameMapping, titleStatNameMapping } from "@misc/statNameMap";
import { excludeStatsKeys } from "@misc/removeStatsKeys";
import { useNavigate } from "react-router-dom";
import { PLAY_NORMAL_CLICK } from "@constants/audio";
import { useDetailGlobalContext } from "@context/detailProvider";
import toasterService from "@components/toast/ToasterService.component";
import { useSelector } from "react-redux";
import { MessageText } from "@misc/message/messageText";
import { HYPERLINKS } from "@misc/links";
import { WeaponType, WeaponTypeCount } from "@misc/warSpecific";

const LobbyScreen = ({
    participatedNFTsDetails,
    participantsDetails,
    isScreenClose,
    setScreenClose,
    simulationId,
    walletAddress,
    war,
}: any) => {
    const allowedWeapons = war.allowedWeapons.length === 0 ? Object.keys(WeaponType).map(element => Number(element)) : war.allowedWeapons.map((element: string) => Number(element));
    const {
        userEquipmentDetails,
        getAbilityNumberCount,
        setAbilityNumberCount,
        userAbilityDetails,
        getItemNumberCount,
        setItemNumberCount,
        userItemsDetails,
    } = useDetailGlobalContext();
    const [lobbyLockPeriod, setLobbyLockPeriod] = useState("0");
    async function lobbyLockDownPeriod() {
        const result = await getLobbyLockdownPeriod();
        setLobbyLockPeriod(result);
    }
    const WAR_MAX_PLAYERS = Number(war?.maxPlayer);

    useEffect(() => {
        lobbyLockDownPeriod();
    }, []);

    const [participantsDetailsItemsId, setParticipatedDetailsItemsId] = useState([]);
    useEffect(() => {
        const values: any = [];
        participantsDetails?.items.map((item: any) => {
            values.push(item.id);
        });
        setParticipatedDetailsItemsId(values);
    }, []);
    const closeScreen = () => {
        setScreenClose(false);
    };
    const { getTxResultWithTimer } = useTransactionValidate({
        onHandle: closeScreen,
    });
    const [hasChanged, setHasChanged] = useState(false);

    const [numberOfParticipants, setNumberOfParticipants] = useState<number>();
    useEffect(() => {
        async function getParticipant() {
            const participantsNumber = await getSimulationDetail(war.id);
            setNumberOfParticipants(participantsNumber.participants.length);
        }
        getParticipant();
    }, []);
    const paddingTheArrayItem = (array: any, padWith: any, withSize: number) => {
        const result = [...array];
        while (result.length < withSize) {
            result.push({ ...padWith });
        }
        return result;
    };
    const [animationToggle, setAnimationToggle] = useState<boolean>(false);
    const [replace, setReplace] = useState<boolean>(false);

    useCardAnimation("animateThis", animationToggle);
    const { setTransactionLoading } = useDetailGlobalContext();
    const [loadoutEquipmentDetails, setLoadoutEquipmentDetails] = useState<IUserEquipment | null>(
        null
    );

    interface IloadoutItemsDetails extends IItems {
        itemIndex: itemIndexType;
    }

    interface ILoadoutAbilitiesDetails extends IGetUserAbility {
        abilityIndex: abilityIndexType;
    }

    const [loadoutItemDetails, setLoadoutItemDetails] = useState<IloadoutItemsDetails | null>(null);

    const [
        loadoutAbilityDetails,
        setLoadoutAbilityDetails,
    ] = useState<ILoadoutAbilitiesDetails | null>(null);

    const itemTypes: Array<itemIndexType> = ["firstItem", "secondItem", "thirdItem"];

    const abilitiesTypes: Array<abilityIndexType> = [
        "firstAbility",
        "secondAbility",
        "thirdAbility",
    ];

    const { isLedger, ledgerPath, ledgerTransport } = useSelector((state: any) => state.wallet);

    const [slot, setSlot] = useState<slotType>("weapon");

    const [equipedLoadout, setEquipedLoadout] = useState<Partial<IEquipedLoadout>>({
        equipment:
            participantsDetails.equipments.length > 0 ? participantsDetails.equipments[0] : null,
        ability: {
            firstAbility:
                participantsDetails.abilities.length > 0 ? participantsDetails.abilities[0] : null,
            secondAbility:
                participantsDetails.abilities.length > 1 ? participantsDetails.abilities[1] : null,
            thirdAbility:
                participantsDetails.abilities.length > 2 ? participantsDetails.abilities[2] : null,
        },
        items: {
            firstItems: participantsDetails.items.length > 0 ? participantsDetails.items[0] : null,
            secondItems: participantsDetails.items.length > 1 ? participantsDetails.items[1] : null,
            thirdItems: participantsDetails.items.length > 2 ? participantsDetails.items[2] : null,
        },
    });

    const [showAbilitiesSlots, setShowAbilitiesSlots] = useState(
        paddingTheArrayItem(participantsDetails.abilities, emptyStructureForAbilities, 3)
    );

    const [showItemsSlots, setShowItemsSlots] = useState(
        paddingTheArrayItem(participantsDetails.items, emptyStructureForItems, 3)
    );

    const [showEquipmentSlot, setEquipmentSlots] = useState<any>([]);
    const [confirmLoadout, setConfirmLoadout] = useState<boolean>(false);

    useEffect(() => {
        setEquipmentSlots(
            paddingTheArrayItem(participantsDetails.equipments, emptyStructureForEquipments, 1)
        );
        setEquipedLoadout({
            equipment:
                participantsDetails.equipments.length > 0
                    ? participantsDetails.equipments[0]
                    : null,
            ability: {
                firstAbility:
                    participantsDetails.abilities.length > 0
                        ? participantsDetails.abilities[0]
                        : null,
                secondAbility:
                    participantsDetails.abilities.length > 1
                        ? participantsDetails.abilities[1]
                        : null,
                thirdAbility:
                    participantsDetails.abilities.length > 2
                        ? participantsDetails.abilities[2]
                        : null,
            },
            items: {
                firstItems:
                    participantsDetails.items.length > 0 ? participantsDetails.items[0] : null,
                secondItems:
                    participantsDetails.items.length > 1 ? participantsDetails.items[1] : null,
                thirdItems:
                    participantsDetails.items.length > 2 ? participantsDetails.items[2] : null,
            },
        });
    }, []);

    const [showAbilities, setShowAbilities] = useState({
        status: false,
        state: "",
    });

    const [showEquipment, setShowEquipment] = useState({
        status: false,
        state: "",
    });

    const [showItems, setShowItems] = useState({
        status: false,
        state: "",
    });

    const [switchLoadout, setSwitchLoadout] = useState<boolean>(false);
    const [switchLoadoutsAbilities, setSwitchLoadoutAbilities] = useState<IGetUserAbility | null>(
        null
    );
    const [switchLoadoutsEquipment, setSwitchLoadoutEquipment] = useState<IUserEquipment | null>(
        null
    );

    const [switchLoadoutsItems, setSwitchLoadoutsItems] = useState<IItems | null>(null);

    const confirmSwitchLoadout = () => {
        if (slot === "ability" && switchLoadoutsAbilities) {
            setAbilityNumberCount((prev: any) => ({
                ...prev,
                [(switchLoadoutsAbilities as IGetUserAbility).id]:
                    getAbilityNumberCount[(switchLoadoutsAbilities as IGetUserAbility).id] - 1,
                [(loadoutAbilityDetails as IGetUserAbility).id]:
                    getAbilityNumberCount[(loadoutAbilityDetails as IGetUserAbility).id] + 1,
            }));
            setShowAbilities({ status: false, state: "" });
            setEquipedLoadout((prev: any) => ({
                ...prev,
                ability: {
                    ...prev.ability,
                    [(loadoutAbilityDetails as ILoadoutAbilitiesDetails)
                        .abilityIndex]: switchLoadoutsAbilities,
                },
            }));
            setShowAbilitiesSlots(() => {
                const result = [...showAbilitiesSlots];
                result[
                    abilitiesTypes.indexOf(
                        (loadoutAbilityDetails as ILoadoutAbilitiesDetails).abilityIndex
                    )
                ] = switchLoadoutsAbilities;
                return result;
            });
            setLoadoutAbilityDetails({
                ...switchLoadoutsAbilities,
                abilityIndex: (loadoutAbilityDetails as ILoadoutAbilitiesDetails).abilityIndex,
            });
        } else if (slot === "weapon" && switchLoadoutsEquipment) {
            setShowEquipment({ status: false, state: "" });
            setEquipedLoadout((prev) => ({
                ...prev,
                equipment: switchLoadoutsEquipment,
            }));
            setEquipmentSlots([switchLoadoutsEquipment]);
            setLoadoutEquipmentDetails(switchLoadoutsEquipment);
        } else if (slot === "item" && switchLoadoutsItems) {
            setItemNumberCount((prev: any) => ({
                ...prev,
                [(switchLoadoutsItems as IItems).id]:
                    getItemNumberCount[(switchLoadoutsItems as IItems).id] - 1,
                [(loadoutItemDetails as IItems).id]:
                    getItemNumberCount[(loadoutItemDetails as IItems).id] + 1,
            }));
            setShowItems({ status: false, state: "" });
            setEquipedLoadout((prev: any) => ({
                ...prev,
                items: {
                    ...prev.items,
                    [(loadoutItemDetails as IloadoutItemsDetails).itemIndex]: switchLoadoutsItems,
                },
            }));
            setShowItemsSlots(() => {
                const result = [...showItemsSlots];
                result[
                    itemTypes.indexOf((loadoutItemDetails as IloadoutItemsDetails).itemIndex)
                ] = switchLoadoutsItems;
                return result;
            });
            setLoadoutItemDetails({
                ...switchLoadoutsItems,
                itemIndex: (loadoutItemDetails as IloadoutItemsDetails).itemIndex,
            });
        }
    };

    const handleSelectedEquipment = (item: any) => {
        setHasChanged(true);
        if (showEquipment.state === "equip") {
            setShowEquipment({ status: false, state: "" });
            setEquipmentSlots([item]);
            setEquipedLoadout((prev) => ({ ...prev, equipment: item }));
            setLoadoutEquipmentDetails(item);
        } else if (showEquipment.state === "switch") {
            setSwitchLoadoutEquipment(item);
            setSwitchLoadout(true);
        }
    };

    const handleSelectedItems = (item: any) => {
        setHasChanged(true);
        if (showItems.state === "equip") {
            setItemNumberCount((prev: any) => ({
                ...prev,
                [item.id]: getItemNumberCount[item.id] - 1,
            }));
            setShowItems({ status: false, state: "" });
            setShowItemsSlots(() => {
                const result = [...showItemsSlots];
                result[
                    itemTypes.indexOf((loadoutItemDetails as IloadoutItemsDetails).itemIndex)
                ] = item;
                return result;
            });

            setEquipedLoadout((prev: any) => ({
                ...prev,
                items: {
                    ...prev.items,
                    [(loadoutItemDetails as IloadoutItemsDetails).itemIndex]: item,
                },
            }));

            setLoadoutItemDetails({
                ...item,
                itemIndex: (loadoutItemDetails as IloadoutItemsDetails).itemIndex,
            });
        } else if (showItems.state === "switch") {
            setSwitchLoadoutsItems(item);
            setSwitchLoadout(true);
        }
    };

    const handleSelectedNewAbility = (item: any) => {
        setHasChanged(true);
        if (showAbilities.state === "equip") {
            setShowAbilities({ status: false, state: "" });
            setShowAbilitiesSlots(() => {
                const result = [...showAbilitiesSlots];
                result[
                    abilitiesTypes.indexOf(
                        (loadoutAbilityDetails as ILoadoutAbilitiesDetails).abilityIndex
                    )
                ] = item;
                return result;
            });

            setEquipedLoadout((prev: any) => ({
                ...prev,
                ability: {
                    ...prev.ability,
                    [(loadoutAbilityDetails as ILoadoutAbilitiesDetails).abilityIndex]: item,
                },
            }));

            setLoadoutAbilityDetails({
                ...item,
                abilityIndex: (loadoutAbilityDetails as ILoadoutAbilitiesDetails).abilityIndex,
            });
            setAbilityNumberCount((prev: any) => ({
                ...prev,
                [(item as ILoadoutAbilitiesDetails).id]:
                    getAbilityNumberCount[(item as ILoadoutAbilitiesDetails).id] - 1,
            }));
        } else if (showAbilities.state === "switch") {
            setSwitchLoadoutAbilities(item);
            setSwitchLoadout(true);
        }
    };

    const [userEquipedLoadout, setUserEquipedLoadout] = useState<Array<String>>([]);
    const handleChangeLoadoutsConfirm = async () => {
        setTransactionLoading((prev) => ({
            ...prev,
            state: "loading",
            status: true,
            title: "Updating your Loadout",
            message: MessageText.CHANGE_LOADOUT,
        }));
        setUserEquipedLoadout([]);
        const presentAbilityGangwar: any = {};
        const previousAbilityGangwar: any = {};

        const presentItemsGangwar: any = {};
        const previousItemsGangwar: any = {};

        //present abilities
        abilitiesTypes.map((item) => {
            if (equipedLoadout.ability?.[item]) {
                if (
                    Number((equipedLoadout.ability[item] as IGetUserAbility).id) in
                    presentAbilityGangwar
                ) {
                    presentAbilityGangwar[
                        Number((equipedLoadout.ability[item] as IGetUserAbility).id)
                    ] =
                        presentAbilityGangwar[
                        Number((equipedLoadout.ability[item] as IGetUserAbility).id)
                        ] + 1;
                } else {
                    presentAbilityGangwar[
                        Number((equipedLoadout.ability[item] as IGetUserAbility).id)
                    ] = 1;
                }
            }
        });

        //present items
        itemTypes.map((item) => {
            if (equipedLoadout.items?.[item]) {
                if (Number((equipedLoadout.items[item] as IItems).id) in presentItemsGangwar) {
                    presentItemsGangwar[Number((equipedLoadout.items[item] as IItems).id)] =
                        presentItemsGangwar[Number((equipedLoadout.items[item] as IItems).id)] + 1;
                } else {
                    presentItemsGangwar[Number((equipedLoadout.items[item] as IItems).id)] = 1;
                }
            }
        });

        //previous abilities
        participantsDetails.abilities.map((item: any) => {
            if (Number(item.id) in previousAbilityGangwar) {
                previousAbilityGangwar[Number(item.id)] =
                    previousAbilityGangwar[Number(item.id)] + 1;
            } else {
                previousAbilityGangwar[Number(item.id)] = 1;
            }
        });

        //previous items
        participantsDetails.items.map((item: any) => {
            if (Number(item.id) in previousItemsGangwar) {
                previousItemsGangwar[Number(item.id)] = previousItemsGangwar[Number(item.id)] + 1;
            } else {
                previousItemsGangwar[Number(item.id)] = 1;
            }
        });

        //present values abilities
        const abilitypresentIds = Object.keys(presentAbilityGangwar).map((item: any) =>
            item.toString()
        );
        const abilityPresentValues = Object.values(presentAbilityGangwar).map((item: any) =>
            item.toString()
        );

        //items
        const itemsPresentIds = Object.keys(presentItemsGangwar).map((item: any) =>
            item.toString()
        );
        const itemsPresentValues = Object.values(presentItemsGangwar).map((item: any) =>
            item.toString()
        );

        //equipment
        const equipmentPresentId = equipedLoadout.equipment
            ? [equipedLoadout.equipment.equipmentId.toString()]
            : [];
        const equipmentPresentValue = equipedLoadout.equipment ? ["1"] : [];

        //previous values
        //abilities
        const abilityPreviousIds = Object.keys(previousAbilityGangwar).map((item: any) =>
            item.toString()
        );
        const abilityPreviousValues = Object.values(previousAbilityGangwar).map((item: any) =>
            item.toString()
        );
        //items
        const itemsPreviousIds = Object.keys(previousItemsGangwar).map((item: any) =>
            item.toString()
        );
        const itemsPreviousValues = Object.values(previousItemsGangwar).map((item: any) =>
            item.toString()
        );
        //equipment
        const equipmentPreviousId =
            participantsDetails.equipments.length > 0
                ? [participantsDetails.equipments[0].equipmentId.toString()]
                : [];
        const equipmentPreviousValue = participantsDetails.equipments.length > 0 ? ["1"] : [];

        const prevAbilities = convertTwoArrayToObject(abilityPreviousIds, abilityPreviousValues);
        const presAbilities = convertTwoArrayToObject(abilitypresentIds, abilityPresentValues);

        const prevItems = convertTwoArrayToObject(itemsPreviousIds, itemsPreviousValues);
        const presItems = convertTwoArrayToObject(itemsPresentIds, itemsPresentValues);

        const prevEquipment = convertTwoArrayToObject(equipmentPreviousId, equipmentPreviousValue);
        const presEquipment = convertTwoArrayToObject(equipmentPresentId, equipmentPresentValue);
        const abilitiesLockUnlock = lockUnlockLoadouts(prevAbilities, presAbilities);
        const equipmentLockUnlock = lockUnlockLoadouts(prevEquipment, presEquipment);

        const ItemsLockUnlock = lockUnlockLoadouts(prevItems, presItems);

        const unLockEquipmentId = equipmentLockUnlock[0];
        const unLockEquipmentValue = equipmentLockUnlock[1];
        const lockEquipmentId = equipmentLockUnlock[2];
        const lockEquipmentValues = equipmentLockUnlock[3];

        const unLockAbilitiesId = abilitiesLockUnlock[0];
        const unLockAbilitiesValue = abilitiesLockUnlock[1];
        const lockAbilitiesId = abilitiesLockUnlock[2];
        const lockAbilitiesValues = abilitiesLockUnlock[3];

        const unLockItemsId = ItemsLockUnlock[0];
        const unLockItemsValue = ItemsLockUnlock[1];
        const lockItemsId = ItemsLockUnlock[2];
        const lockItemsValues = ItemsLockUnlock[3];

        const newEquipmentIds =
            equipmentPreviousId.toString() === equipmentPresentId.toString() &&
                equipmentPreviousValue.toString() === equipmentPresentValue.toString()
                ? []
                : Object.keys(presEquipment);

        const newAbilityIds =
            abilitypresentIds.toString() === abilityPreviousIds.toString() &&
                abilityPresentValues.toString() === abilityPreviousValues.toString()
                ? []
                : Object.keys(presAbilities);

        const parameter = {
            simulationId: simulationId,
            nftId: participatedNFTsDetails.nftId.toString(),
            newEquipmentIds: newEquipmentIds,
            newEquipmentValues:
                equipmentPreviousId.toString() === equipmentPresentId.toString() &&
                    equipmentPreviousValue.toString() === equipmentPresentValue.toString()
                    ? []
                    : Object.values(presEquipment).map(String),
            toUnlockEquipmentIds: unLockEquipmentId,
            allEquipmentRemoved:
                newEquipmentIds.length === 0 && unLockEquipmentId.length > 0 ? "0x1" : "0x0",
            toUnlockEquipmentValues: unLockEquipmentValue,
            toLockEquipmentIds: lockEquipmentId,
            toLockEquipmentValues: lockEquipmentValues,
            newAbilityIds: newAbilityIds,
            newAbilityValues:
                abilitypresentIds.toString() === abilityPreviousIds.toString() &&
                    abilityPresentValues.toString() === abilityPreviousValues.toString()
                    ? []
                    : Object.values(presAbilities).map(String),
            toUnlockAbilityIds: unLockAbilitiesId,
            toUnlockAbilityValues: unLockAbilitiesValue,
            toLockAbilityIds: lockAbilitiesId,
            toLockAbilityValues: lockAbilitiesValues,
            allAbilityRemoved:
                newAbilityIds.length === 0 && unLockAbilitiesId.length > 0 ? "0x1" : "0x0",
            newItemIds: itemsPresentIds,
            newItemValues: itemsPresentValues,
        };

        const res: any = await updateLoadouts({
            address: walletAddress,
            params: parameter,
            ledger: {
                isLedger,
                ledgerPath,
                ledgerTransport,
            },
        });
        if (isLedger) {
            if (res?.name === "TransportStatusError") {
                console.log("Ledger cancelled!!!");
            } else if (res) {
                getTxResultWithTimer(res as string);
            } else {
                console.log("Error on ledger");
            }
        }
    };
    const navigate = useNavigate();
    const [isLobbyLocked, setLobbyLocked] = useState(false);
    const startsGameIn = ({ hours, minutes, seconds }: any) => {
        const lockTime = Number(war.startTime) / 1000 - Number(lobbyLockPeriod) / 1000;
        if (new Date() > new Date(lockTime)) {
            if (!isLobbyLocked) {
                setLobbyLocked(true);
                toasterService.set({
                    title: "Lobby Locked",
                    message: `Lobby is locked. Loadout cannot be changed now.`,
                    type: "warn",
                    toastSize: "toastSmall",
                });
            }
        }
        if (hours === 0 && minutes === 0 && seconds === 0) {
            navigate(`/battle/${Number(war.id)}`);
            return <span />;
        } else {
            return (
                <span>
                    {hours < 10 ? "0" + hours : hours}:{minutes < 10 ? "0" + minutes : minutes}:
                    {seconds < 10 ? "0" + seconds : seconds}
                </span>
            );
        }
    };

    return (
        <>
            <SelectionScreen
                isScreenClose={isScreenClose}
                setScreenClose={setScreenClose}
                titleText={
                    <>
                        <span className="text-dark normal-case	">
                            {/* War Starts In  */}
                            Loadout
                        </span>
                        {/* <Countdown
              targetDate={new Date(
                new Date(Number(war.startTime) / 1000).setHours(
                  new Date(Number(war.startTime) / 1000).getHours()
                )
              ).toISOString()}
              renderer={startsGameIn}
            /> */}
                    </>
                }
                closeContent={
                    <div className="flex items-center justify-between text-4xl text-dark">
                        <FaUser />
                        <div className="font-bold ml-3 ">
                            <span className="text-yellow normal-case">
                                {" "}
                                {numberOfParticipants}/{WAR_MAX_PLAYERS}{" "}
                            </span>{" "}
                            Registered
                        </div>
                    </div>
                }
            >
                <div className="flex w-[100%]">
                    <div className="w-1/4 section1 overflow-hidden">
                        <div className="animation nftInfo " id="animate">
                            <div className="nftImage flex justify-center">
                                <NFTCard
                                    cardType="nftCardImageChooseCharacter"
                                    width="220px"
                                    // height="300px"
                                    nftImage={participatedNFTsDetails?.nftImage}
                                // highlightSelectedBorder
                                />
                            </div>

                            <div className="nftDetails">
                                <div className="nftNameClassType">
                                    <h2
                                        onClick={() => {
                                            window.open(
                                                `${HYPERLINKS.GANGSTABET_PROFILE}/${participatedNFTsDetails?.nftId}`
                                            );
                                        }}
                                        className="gbName cursor-pointer"
                                    >
                                        {participatedNFTsDetails?.nftName}
                                    </h2>
                                    <div className="gbClassType">
                                        <p className="gbType">
                                            {participatedNFTsDetails?.classType}
                                        </p>
                                        <div className="circle"></div>
                                        <p className="gbClass">
                                            {participatedNFTsDetails?.characterType}
                                        </p>
                                    </div>
                                </div>
                                <div className="gbLevel">
                                    <p className="gbLevelName">
                                        {participatedNFTsDetails?.levelType}
                                    </p>
                                    <div className="flex gap-1 ">
                                        <div className="w-3 h-3 mt-1 bg-gray-200 rounded-full"></div>

                                        <p className="gbLevelNow">
                                            Level {participatedNFTsDetails?.levelNumber}
                                        </p>
                                    </div>
                                    <div className="flex item-center gap-1">
                                        <div className="w-3 h-3 mt-1 bg-gray-700 rounded-full"></div>
                                        <p className="text-gray">
                                            Id {participatedNFTsDetails?.nftId}
                                        </p>
                                    </div>
                                </div>
                                {participatedNFTsDetails?.damages && (
                                    <div className="recoveryButton flex flex-col gap-1">
                                        <hr />
                                        <PrimaryButton
                                            icon={<GiLoveMystery color="red" />}
                                            height="40px"
                                            buttonName="rapid recovery"
                                            extraIcon={
                                                <img
                                                    width="22px"
                                                    height={"22px"}
                                                    src={CROWN}
                                                    alt="crown"
                                                />
                                            }
                                            extraText="200"
                                        />
                                        <hr />
                                    </div>
                                )}

                                <div className="nftWeapons">
                                    <div className="weapons">
                                        <EquipmentPlainCard
                                            height="64px"
                                            width="68px"
                                            equipmentImages={participatedNFTsDetails?.weapons.image}
                                            className="setColor"
                                        />
                                        <div className="weaponsInfo w-content">
                                            <p className="weaponName">
                                                {participatedNFTsDetails?.weapons?.weaponName}
                                            </p>

                                            <p className="isDefault">
                                                {participatedNFTsDetails?.weapons?.weaponType}
                                            </p>
                                        </div>
                                        <div className="ranged">
                                            <img src={RANGE} alt="ranged" />
                                            <p className="rangedText">
                                                {participatedNFTsDetails?.weapons?.Ranged}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-1/4 section2">
                        <div className="nftSkills">
                            <p className="nftSkillTitle">GangWar Stats</p>

                            <div className="nftActualSkills cursor-pointer">
                                <div className="skillsLeft">
                                    {participatedNFTsDetails?.nftName &&
                                        Object.keys(participatedNFTsDetails?.skills?.primary)?.map(
                                            (keys) => {
                                                return (
                                                    <div className="skillInfo">
                                                        <p className="skillName capitalize">
                                                            {keys}
                                                        </p>
                                                        <p className="skillNumber">
                                                            {participatedNFTsDetails?.skills.primary[
                                                                keys
                                                            ].toFixed(0)}{" "}
                                                            <span className="text-green-400">
                                                                {/* {"+" + participatedNFTsDetails?.skills[keys]} */}
                                                            </span>
                                                        </p>
                                                    </div>
                                                );
                                            }
                                        )}
                                </div>
                            </div>
                            <div className="nftActualSkills cursor-pointer">
                                <div className="skillsLeft">
                                    {participatedNFTsDetails?.nftName &&
                                        Object.keys(
                                            participatedNFTsDetails?.skills?.secondary
                                        )?.map((keys) => {
                                            if (excludeStatsKeys.includes(keys)) return null;

                                            return (
                                                <div className="skillInfo">
                                                    <p
                                                        className="skillName"
                                                        title={`${titleStatNameMapping[keys]}`}
                                                    >
                                                        {statNameMapping?.[keys]}
                                                    </p>
                                                    <p className="skillNumber">
                                                        {statNameMapping?.[keys]?.split(" ")
                                                            .length > 1
                                                            ? `${keys.toLowerCase() !==
                                                                "dodgechance"
                                                                ? "+"
                                                                : ""
                                                            }${Number(
                                                                participatedNFTsDetails?.skills
                                                                    .secondary[keys] * 100
                                                            ).toFixed(0)}%`
                                                            : participatedNFTsDetails?.skills.secondary[
                                                                keys
                                                            ].toFixed(0)}
                                                    </p>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-1/4 section3">
                        <div className="weaponSlot">
                            <EquipmentPlainCard
                                equipmentImages={WEAPON}
                                minHeight="30px"
                                minWidth="34px"
                                height="30px"
                                width="34px"
                            />
                            {showEquipmentSlot.map((equipment: any, index: number) => (
                                <div
                                    className={
                                        "w-full flex items-center gap-3 cursor-pointer " +
                                        (loadoutEquipmentDetails?.equipmentId ===
                                            equipment.equipmentId
                                            ? "activeClass"
                                            : "")
                                    }
                                    onClick={() => {
                                        setLoadoutEquipmentDetails(equipment);
                                        setSlot("weapon");
                                        setAnimationToggle(!animationToggle);
                                    }}
                                >
                                    <EquipmentPlainCard
                                        height="50px"
                                        width="56px"
                                        equipmentImages={equipment?.image}
                                        className={
                                            loadoutEquipmentDetails?.equipmentId ===
                                                equipment.equipmentId || equipment?.equipmentId > 0
                                                ? " setColor ml-[-5px]"
                                                : " inactive-image ml-[-5px]"
                                        }
                                    />
                                    <div className="flex items-center justify-between w-full h-full">
                                        <div className="flex flex-col w-full">
                                            <div className="text-primary">{equipment?.type}</div>
                                            <div className="text-white font-semibold">
                                                {equipment?.name}
                                            </div>
                                        </div>
                                        {equipment?.equipmentId <= 0 && (
                                            <FaPlus className="text-white font-semibold" />
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className="itemSlot">
                            <EquipmentPlainCard
                                equipmentImages={ITEM}
                                minHeight="30px"
                                minWidth="34px"
                                height="30px"
                                width="34px"
                            />
                            <div className="w-full">
                                {showItemsSlots.map((item: any, index: number) => (
                                    <div
                                        className={
                                            "mb-4 cursor-pointer w-full h-[56px] " +
                                            (loadoutItemDetails?.itemIndex === itemTypes[index]
                                                ? "activeClass"
                                                : "")
                                        }
                                        id={itemTypes[index]}
                                        key={index}
                                        onClick={(e) => {
                                            setShowItems({ status: false, state: "" });
                                            setAnimationToggle(!animationToggle);
                                            setLoadoutItemDetails({
                                                ...item,
                                                itemIndex: itemTypes[index],
                                            });
                                            setSlot("item");
                                        }}
                                    >
                                        <span className={"flex items-center "}>
                                            <EquipmentPlainCard
                                                height="50px"
                                                width="56px"
                                                equipmentImages={item.image}
                                                className={
                                                    loadoutItemDetails?.itemIndex ===
                                                        itemTypes[index] || item?.id > 0
                                                        ? " setColor ml-[-5px]"
                                                        : " inactive-image ml-[-5px]"
                                                }
                                            />
                                            <div className="flex items-center justify-between w-full h-full">
                                                <div className={"flex flex-col pl-2 "}>
                                                    <div className="text-primary">{item?.name}</div>
                                                    <div className="text-white font-semibold">
                                                        {item?.type}
                                                    </div>
                                                </div>
                                                {item?.id <= 0 && (
                                                    <FaPlus className="text-white font-semibold" />
                                                )}
                                            </div>
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="abilitySlot">
                            <EquipmentPlainCard
                                equipmentImages={ABILITY}
                                minHeight="30px"
                                minWidth="34px"
                                height="30px"
                                width="34px"
                            />
                            {/* item slot starts */}
                            <div className="w-full">
                                {showAbilitiesSlots.map((item: any, index: number) => (
                                    <div
                                        className={
                                            "flex mb-4 items-center gap-3 cursor-pointer " +
                                            "h-[55px] " +
                                            (loadoutAbilityDetails?.abilityIndex ===
                                                abilitiesTypes[index]
                                                ? "activeClass"
                                                : "")
                                        }
                                        key={index}
                                        onClick={() => {
                                            setShowAbilities({ status: false, state: "" });

                                            setLoadoutAbilityDetails({
                                                ...item,
                                                abilityIndex: abilitiesTypes[index],
                                            });
                                            setAnimationToggle(!animationToggle);
                                            setSlot("ability");
                                        }}
                                    >
                                        <EquipmentPlainCard
                                            height="50px"
                                            width="56px"
                                            equipmentImages={item?.image}
                                            className={
                                                loadoutAbilityDetails?.abilityIndex ===
                                                    abilitiesTypes[index] || item?.id > 0
                                                    ? "setColor ml-[-5px]"
                                                    : "inactive-image ml-[-5px]"
                                            }
                                        />
                                        <div className="flex items-center justify-between w-full h-full">
                                            <div className="flex flex-col">
                                                <div className="text-primary ">{item?.name}</div>
                                                <div className="text-white font-semibold">
                                                    {item?.type}
                                                </div>
                                            </div>
                                            {item?.id <= 0 && (
                                                <FaPlus className="text-white font-semibold" />
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {/* item slot ends */}
                        </div>
                    </div>
                    <div className="w-1/4 section4 flex flex-col justify-between">
                        <div className=" overflow-hidden ">
                            <AbilityScreen
                                replace={replace}
                                setHasChanged={setHasChanged}
                                setReplace={setReplace}
                                animationToggle={animationToggle}
                                handleSelectedNewAbility={handleSelectedNewAbility}
                                loadoutAbilityDetails={loadoutAbilityDetails}
                                abilitiesTypes={abilitiesTypes}
                                slot={slot}
                                loadoutItemDetails={loadoutItemDetails}
                                equipedLoadout={equipedLoadout}
                                setEquipedLoadout={setEquipedLoadout}
                                participantsDetails={participantsDetails}
                                showAbilitiesSlots={showAbilitiesSlots}
                                setShowAbilitiesSlots={setShowAbilitiesSlots}
                                showItemsSlots={showItemsSlots}
                                setShowItemsSlots={setShowItemsSlots}
                                showItems={showItems}
                                setShowItems={setShowItems}
                                emptyStructureForAbilities={emptyStructureForAbilities}
                                setLoadoutAbilityDetails={setLoadoutAbilityDetails}
                                showAbilities={showAbilities}
                                setShowAbilities={setShowAbilities}
                                setSwitchLoadout={setSwitchLoadout}
                                loadoutEquipmentDetails={loadoutEquipmentDetails}
                                setEquipmentSlots={setEquipmentSlots}
                                setLoadoutEquipmentDetails={setLoadoutEquipmentDetails}
                                showEquipment={showEquipment}
                                setShowEquipment={setShowEquipment}
                                handleSelectedEquipment={handleSelectedEquipment}
                                handleSelectedItems={handleSelectedItems}
                                itemTypes={itemTypes}
                                setLoadoutItemDetails={setLoadoutItemDetails}
                                participantsDetailsItemsId={participantsDetailsItemsId}
                                war={war}
                            />
                        </div>
                        <div className="warContinueBtnDiv self-center">
                            {!isLobbyLocked && hasChanged ? (
                                <button
                                    className="contineWarBtn continueLobbyBtn  "
                                    onClick={() => {
                                        if (
                                            !equipedLoadout.equipment &&
                                            allowedWeapons.length !== WeaponTypeCount()
                                        ) {
                                            toasterService.set({
                                                title: "Warning",
                                                message: `You cannot join weapon specific game without taking a weapon.`,
                                                type: "error",
                                                toastSize: "toastSmall",
                                            });
                                        } else {
                                            handleChangeLoadoutsConfirm();
                                        }
                                        // setConfirmLoadout(true);
                                    }}
                                // disabled={disabled ? disabled : false}
                                >
                                    Continue <AiOutlineRight size={30} />
                                </button>
                            ) : (
                                <button
                                    className="contineWarBtn continueLobbyBtn  "
                                    onClick={() => {
                                        // handleChangeLoadoutsConfirm();
                                        // setConfirmLoadout(true);
                                        setScreenClose();
                                        PLAY_NORMAL_CLICK();
                                    }}
                                // disabled={disabled ? disabled : false}
                                >
                                    Go Back <AiOutlineRight size={30} />
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </SelectionScreen>
            {switchLoadout && (
                <SwitchWeapon
                    isVisible={switchLoadout}
                    setIsVisible={setSwitchLoadout}
                    modalTitle="Replace Ability"
                    buttonName="Yes Replace"
                    handleConfirm={() => {
                        confirmSwitchLoadout();
                        setSwitchLoadout(false);
                    }}
                    previousEquiped={
                        slot === "ability"
                            ? equipedLoadout.ability?.[
                            (loadoutAbilityDetails as ILoadoutAbilitiesDetails).abilityIndex
                            ]
                            : slot === "weapon"
                                ? equipedLoadout.equipment
                                : slot === "item" &&
                                equipedLoadout.items?.[
                                (loadoutItemDetails as IloadoutItemsDetails).itemIndex
                                ]
                    }
                    switchEquiped={
                        slot === "ability"
                            ? switchLoadoutsAbilities
                            : slot === "weapon"
                                ? switchLoadoutsEquipment
                                : slot === "item" && switchLoadoutsItems
                    }
                    modalContent={
                        <>
                            {/* <hr className="horizontal-seperator" /> */}
                            <p className="px-20 text-white  text-center">
                                Are you sure want to switch?
                            </p>
                            <hr className="horizontal-seperator" />
                        </>
                    }
                />
            )}
            {confirmLoadout && (
                <CommonModal
                    isVisible={confirmLoadout}
                    setIsVisible={setConfirmLoadout}
                    modalTitle={"Confirm your loadout"}
                    confirmHandle={() => {
                        handleChangeLoadoutsConfirm();
                    }}
                    modalContent={
                        <>
                            <div className="rapidRecoverNFTText">
                                {/* {userEquipedLoadout.length === 0 && (
                  <>
                    {" "}
                    <p className="rapidRecoverNFTTopText text-2xl">
                      Your loadout slots are empty.
                    </p>
                    <hr className="horizontal-seperator" />
                  </>
                )} */}

                                <p className="rapidRecoverNFTInfo">
                                    Are you sure you want to change loadouts?
                                </p>
                                <p className="rapidRecoverNFTInfo">
                                    You wont be able to withdraw items once you join a war. You will
                                    only be allowed to change sneakmoves and equipment after you
                                    lock this loadout.
                                </p>
                            </div>
                            <hr className="horizontal-seperator" />
                            {/* <div className="flex items-center gap-4 py-8">
                {userEquipedLoadout.length > 0 &&
                  userEquipedLoadout.map((item) => {
                    return (
                      <EquipmentPlainCard
                        height="60px"
                        width="64px"
                        equipmentImages={item}
                        className="setGrey"
                      />
                    );
                  })}
              </div> */}
                        </>
                    }
                    approveAction={"YES, JOIN WAR"}
                    cancelAction={"NOT NOW"}
                />
            )}
        </>
    );
};

export default React.memo(LobbyScreen);
