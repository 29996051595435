import StylizedButton from "@components/buttons/stylized.button";
import Modal from "@components/modal/modal.component";
import { CROWN } from "@constants/images";
import ImageLoader from "@misc/ImageLoader";
interface IWarCloseForEntry {
  isVisible: boolean;
  setIsVisible: any;
  modalTitle?: string;
  modalContent?: any;
  approveAction?: any;
  cancelAction?: string;
  icon?: any;
  confirmHandle?: any;
  extraText?: any;
  textInformation?: string;
  width?: string;
}
const CommonModal = ({
  isVisible,
  setIsVisible,
  modalTitle,
  modalContent,
  approveAction,
  cancelAction,
  icon,
  confirmHandle,
  extraText,
  textInformation,
  width,
}: IWarCloseForEntry) => {
  return (
    <Modal
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      width={width}
      modalTitle={modalTitle || "Rapidly recover your NFT"}
    >
      <div className="rapidRecoverNFTWrapper">
        {modalContent || (
          <>
            <div className="flex gap-1">
              <p className="text-gray">
                {" "}
                Recovery Fees:{" "}
                <span className="font-bold text-white">{extraText}</span>
              </p>{" "}
              <ImageLoader
                className="mt-[-6px]"
                width="20px"
                src={CROWN}
                alt="crown"
              />{" "}
            </div>
            <hr className="horizontal-seperator" />
            <div className="rapidRecoverNFTText">
              {/* <p className="rapidRecoverNFTTopText text-lg">
                Restore your NFT to full health with crown.
              </p> */}
              <p className="rapidRecoverNFTInfo">{textInformation}</p>
            </div>
            <hr className="horizontal-seperator" />
          </>
        )}
        <div className="rapidRecoverNFTButton">
          <div onClick={confirmHandle} className="stylized-wrapper my-3">
            <div className="box-1"></div>
            <button className="stylized-button  mx-7">
              <div className="px-10 stylized-text flex gap-3 items-center">
                {approveAction || "Okay"}
                {/* {icon} */}
                {/* {extraText}{" "} */}
              </div>
            </button>
            <div className="box-2"></div>
          </div>
          <p
            onClick={() => {
              setIsVisible(false);
            }}
            className="notNowBtn"
          >
            {cancelAction || "Not now"}
          </p>
        </div>
      </div>
    </Modal>
  );
};

export default CommonModal;
