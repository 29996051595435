import {
    createContext,
    useContext,
    useState,
    useMemo,
    PropsWithChildren,
} from "react";
import _ from "lodash";
import { IInterval, IStats } from "../interfaces/leaderboard.interface";

interface LeaderboardContextType {
    intervalFilter: IInterval;
    statsFilter: IStats;
    showLastWinners: boolean;
    showRewardsModal: boolean;
    rewardsModalData: { [key: string]: any };
    setShowLastWinners: (x: boolean) => any;
    setShowRewardsModal: (x: boolean) => any;
    setRewardsModalData: (x: { [key: string]: any }) => any;
    setSelectedInterval: (x: IInterval) => any;
    setSelectedStats: (x: IStats) => any;
}

type ILeaderboardProvider = PropsWithChildren<{}>;

export const LeaderboardContext = createContext<LeaderboardContextType | null>(
    null
);

export const LeaderboardProvider = ({ children }: ILeaderboardProvider) => {
    const [selectedInterval, setSelectedInterval] = useState<IInterval>(
        "weekly"
    );
    const [selectedStats, setSelectedStats] = useState<IStats>("wins");
    const [showLastWinners, setShowLastWinners] = useState(false);
    const [showRewardsModal, setShowRewardsModal] = useState(false);
    const [rewardsModalData, setRewardsModalData] = useState<{
        [key: string]: any;
    }>({});

    const contextValues = useMemo(
        () => ({
            intervalFilter: selectedInterval,
            statsFilter: selectedStats,
            showLastWinners,
            showRewardsModal,
            rewardsModalData,
            setSelectedInterval,
            setSelectedStats,
            setShowLastWinners,
            setShowRewardsModal,
            setRewardsModalData,
        }),
        [
            selectedInterval,
            selectedStats,
            showLastWinners,
            showRewardsModal,
            rewardsModalData,
        ]
    );

    return (
        <LeaderboardContext.Provider value={contextValues}>
            {children}
        </LeaderboardContext.Provider>
    );
};

export function useLeaderboardContext() {
    const leaderboardContext = useContext(LeaderboardContext);

    if (_.isNil(leaderboardContext)) {
        throw new Error(
            "useLeaderboard context must be used inside LeaderboardContext.Provider"
        );
    }
    return leaderboardContext;
}
