import { FaUser } from "react-icons/fa";
import { useEffect, useState, useCallback } from "react";
import { getSimulationDetail } from "@services/icon.service";
import { useDispatch } from "react-redux";
import { setTeamFormation } from "@redux/features/lobbySlice";
import PrimaryButton from "@components/buttons/primary.button";
import Typewriter from "typewriter-effect";

const NumberOfParticipants = ({
  warId,
  setTeamFormationModal,
  teamSeparation,
  isLobbyLocked,
}: any) => {
  const [numberOfParticipants, setNumberOfParticipants] = useState<number>();
  const [totalParticipants, setTotalParticipants] = useState<number>(0);
  const dispatch = useDispatch();
  const getNumberOfParticipants = useCallback(async () => {
    const participantsNumber = await getSimulationDetail(warId);
    setNumberOfParticipants(participantsNumber.participants.length);
    setTotalParticipants(Number(participantsNumber?.simulation?.maxPlayer));
  }, [warId]);

  useEffect(() => {
    const interval = setInterval(getNumberOfParticipants, 5000);

    return () => clearInterval(interval);
  }, [getNumberOfParticipants]);
  return (
    <div className="flex gap-1 items-center cursor-pointer justify-center text-4xl text-dark">
      <FaUser />

      <p className="font-bold ml-3  text-yellow normal-case">
        {numberOfParticipants ?? "-"} /{" "}
        {totalParticipants > 0 ? totalParticipants : "-"}
      </p>
      <div className="font-bold">
        {isLobbyLocked && teamSeparation.teamA.length <= 0 ? (
          <div className="flex ml-2 min-w-[210px] animate__animated  animate__fadeIn">
            <p className="font-bold"> Forming Team </p>
            <Typewriter
              options={{
                strings: ["..."],
                autoStart: true,
                loop: true,
                cursor: ".",
              }}
            />
          </div>
        ) : teamSeparation.teamA.length > 0 ? (
          <PrimaryButton
            className="animate__animated  animate__fadeIn"
            height="40px"
            buttonName="View teams"
            onClick={() => {
              teamSeparation.teamA.length > 0 &&
                setTeamFormationModal &&
                setTeamFormationModal(true);
            }}
          />
        ) : (
          !isLobbyLocked &&
          teamSeparation.teamA.length <= 0 && (
            <p className="ml-1 animate__animated  animate__fadeIn">
              {" "}
              Registered
            </p>
          )
        )}
      </div>
    </div>
  );
};

export default NumberOfParticipants;
