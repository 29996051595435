import TrophyImg from "@assets/images/leaderboard/trophy.svg";
import NFTCard from "@components/card/nftCard.component";
import { Link } from "react-router-dom";

export default function EmptyLeaderboard() {
    return (
        <div className="empty-leaderboard">
            <div className="trophy-img-container">
                <NFTCard width="140px" height="180px" nftImage={TrophyImg} />
            </div>
            <h4>The Leaderboard is Empty</h4>
            <p>
                Be the first to climb the leaderboard! Start playing matches
                today and show everyone your skills.
            </p>

            <button>
                <Link to="/war">Start Playing</Link>
            </button>
        </div>
    );
}
