import PrimaryButton from "@components/buttons/primary.button";
import MVP from "@components/team/mvp.component";
import TeamTable from "@components/team/teamTable.component";
import TimeAndRound from "@components/team/timeAndRound.component";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import GameReward from "./gameReward";
import { useDetailGlobalContext } from "@context/detailProvider";
import { useNFTDetailGlobalContext } from "@context/nftInformation";
import { useSelector } from "react-redux";
import { SOUND_S3_URL } from "@constants/war";
import { LOSE_SOUND, VICTORY_SOUND, DRAW_SOUND } from "@constants/audio";
import { getSimulationDetail } from "@services/icon.service";
import { useNFTInformation } from "@context/nftInfoProvider";

const VICTORY_SOUND_SRC = SOUND_S3_URL + VICTORY_SOUND;
const LOSE_SOUND_SRC = SOUND_S3_URL + LOSE_SOUND;
const DRAW_SOUND_SRC = SOUND_S3_URL + DRAW_SOUND;

const PostGame = ({
  nftHealthInfo,
  getLootboxId,
  participatedInTeam,
  isWalletParticipated,
  extraInfo,
  nftIdsTeam,
}: any) => {
  const { id } = useParams();

  const convertTeamToSame = (teamName: string) => {
    return teamName === "Team A"
      ? "teamA"
      : teamName === "Team B"
        ? "teamB"
        : teamName.toLowerCase() === "tie"
          ? "Tie"
          : "";
  };
  const navigate = useNavigate();
  const [playerInTeam, setPlayerInTeam] = useState({
    teamA: [],
    teamB: [],
  });
  const [soundSrc, setSoundSrc] = useState("");
  const soundRef = useRef<HTMLAudioElement>(null);
  type paymentMode = "ICX" | "CROWN" | "FREE";
  const [payment, setPayment] = useState({
    type: "FREE",
    amount: 0,
  });

  useEffect(() => {
    async function getPaymentMethod() {
      const details = await getSimulationDetail(id);
      setPayment({
        type: details?.simulation?.paymentMethod,
        amount: Number(details?.simulation?.fee) / 10 ** 18,
      });
    }
    getPaymentMethod();
  }, []);

  useEffect(() => {
    const teamA = nftIdsTeam?.teamA?.map((id: number) => nftHealthInfo?.[id]);
    const teamB = nftIdsTeam?.teamB?.map((id: number) => nftHealthInfo?.[id]);
    setPlayerInTeam({ teamA, teamB });

    if (isWalletParticipated) {
      if (participatedInTeam?.team === convertTeamToSame(extraInfo?.winner)) {
        setSoundSrc(VICTORY_SOUND_SRC);
      } else if (
        convertTeamToSame(extraInfo?.winner)
          .toString()
          .toLowerCase() === "tie"
      ) {
        setSoundSrc(DRAW_SOUND_SRC);
      } else {
        setSoundSrc(LOSE_SOUND_SRC);
      }
    } else {
      setSoundSrc(DRAW_SOUND_SRC);
    }

    return () => document.removeEventListener("click", playSound);
  }, []);
  const [showRewardPage, setShowRewardPage] = useState(false);
  const [currentPageShow, setCurrentPageShow] = useState(0);
  const [storeRewardData, setRewardData] = useState<any>(null);
  const { reFetchDetails } = useDetailGlobalContext();
  const { setNftData, setCurrentBatchIndex, setRentalIndex } = useNFTDetailGlobalContext();
  const { musicVolume } = useSelector((state: any) => state.volume);
  const { reRenderCollectionToggler, setRerenderCollectionToggler } = useNFTInformation();

  const resetData = () => {
    setNftData([]);
    setRerenderCollectionToggler(!reRenderCollectionToggler);
    setCurrentBatchIndex(0);
    setRentalIndex(null);
    reFetchDetails();
  };

  const playSound = () => {
    if (soundRef.current) {
      soundRef.current.volume = musicVolume / 100;
      soundRef.current
        .play()
        .then(() => {
          document.removeEventListener("click", playSound);
        })
        .catch((err: any) => {
          console.error(err);
          document.addEventListener("click", playSound);
        });
    }
  };

  useEffect(() => {
    if (soundSrc) {
      playSound();
    }
  }, [soundSrc]);

  const continuePostGames = () => {
    if (isWalletParticipated) {
      const nftId = Number(participatedInTeam.nftId);
      const rewards: any = {};

      if (Number(extraInfo?.highestDamageDealer) === nftId) {
        rewards["highestDamageDealer"] = getLootboxId?.hddLootBoxId;
      }
      if (Number(extraInfo?.highestHealer) === nftId) {
        rewards["highestHealer"] = getLootboxId?.hhLootBoxId;
      }
      if (Number(extraInfo?.mvp) === nftId) {
        rewards["mvp"] = getLootboxId?.mvpLootBoxId;
      }
      if (participatedInTeam?.team === convertTeamToSame(extraInfo?.winner)) {
        rewards["winner"] = getLootboxId?.lootBoxId;
        if (payment.type === "ICX") {
          rewards[payment.type] = payment.amount * (70 / 100) * 2;
        } else if (payment.type === "CROWN") {
          rewards[payment.type] = payment.amount * (60 / 100) * 2;
        }
      }
      console.log("DETAILS:-->", rewards);
      if (Object.keys(rewards).length <= 0) {
        navigate("/war");
      } else {
        const keys = Object.keys(rewards);
        const values = Object.values(rewards);
        if (keys.length > currentPageShow) {
          setShowRewardPage(true);
          setRewardData({
            name: keys[currentPageShow],
            id: values[currentPageShow],
          });
          setCurrentPageShow((prev: number) => prev + 1);
        } else {
          resetData();
          navigate("/inventory/loot-crates");
        }
      }
    } else {
      resetData();
      navigate("/war");
    }
  };
  return (
    <div className="pGWrapper">
      <div className="postGameWrapper container mx-auto">
        <audio src={soundSrc} ref={soundRef} />
        {!showRewardPage && (
          <>
            <div className="postGameHeading">
              {participatedInTeam?.team}
              {isWalletParticipated ? (
                participatedInTeam?.team ===
                  convertTeamToSame(extraInfo?.winner) ? (
                  <h1>VICTORY</h1>
                ) : convertTeamToSame(extraInfo?.winner)
                  .toString()
                  .toLowerCase() === "tie" ? (
                  <h1>TIE</h1>
                ) : (
                  participatedInTeam?.team !==
                  convertTeamToSame(extraInfo?.winner) && <h1>DEFEAT</h1>
                )
              ) : (
                <h1 className="uppercase">{extraInfo?.winner}</h1>
              )}
            </div>
            <div className="flex gap-2">
              <div className="teamAGBTableWeapper">
                <h3>Team A</h3>
                <TeamTable
                  playerInfo={playerInTeam?.teamA}
                  highestDamageDealer={extraInfo?.highestDamageDealer}
                  highestHealer={extraInfo?.highestHealer}
                  team="teamA"
                />
              </div>
              <div className="teamBGBTableWeapper">
                <h3>Team B</h3>
                <TeamTable
                  highestDamageDealer={extraInfo?.highestDamageDealer}
                  highestHealer={extraInfo?.highestHealer}
                  playerInfo={playerInTeam?.teamB}
                  team="teamB"
                />
              </div>
            </div>
            {extraInfo?.mvp && extraInfo?.mvp !== "0" && (
              <MVP nftId={extraInfo?.mvp as string} />
            )}
            <TimeAndRound
              duration={extraInfo?.duration}
              rounds={extraInfo?.currentRound}
            />
            <div className="flex gap-2">
              <PrimaryButton
                onClick={() => {
                  const url = `/battle/rewatch/${id}`;
                  window.location.href = url;
                  // window.open();
                }}
                width="250px"
                buttonName="Replay"
              />
              <PrimaryButton
                onClick={() => {
                  continuePostGames();
                }}
                width="250px"
                buttonName="Continue"
              />
            </div>
          </>
        )}

        {showRewardPage && (
          <GameReward
            key={currentPageShow}
            storeRewardData={storeRewardData}
            setShowRewardPage={setShowRewardPage}
            continuePostGames={continuePostGames}
          />
        )}
      </div>
    </div>
  );
};

export default PostGame;
