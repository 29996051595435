import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { NFTsDetails } from "@interfaces/nft.interface";


interface IVolumeState {
masterVolume:number;
musicVolume:number;
effectsVolume:number
}

const initialState: IVolumeState = {
    masterVolume:50,
    musicVolume:50,
    effectsVolume:50
};

export const volumeSlice = createSlice({
  name: "volume",
  initialState,
  reducers: {
    setMasterVolume: (
      state: IVolumeState,
      action: PayloadAction<number>
    ) => {
      state.masterVolume = action.payload;
    },
    setMusicVolume: (
        state: IVolumeState,
        action: PayloadAction<number>
      ) => {
        state.musicVolume = action.payload;
      },setEffectVolume: (
        state: IVolumeState,
        action: PayloadAction<number>
      ) => {
        state.effectsVolume = action.payload;
      },
  
  },
});

export const {
    setMasterVolume,setMusicVolume,setEffectVolume
} = volumeSlice.actions;

export default volumeSlice.reducer;
