import { MdClose } from "react-icons/md";
import Image from "@misc/Image";
import ImageLoader from "@misc/ImageLoader";
import { ABILITY } from "@constants/images";

const SideDrawer = ({ open, setOpen, logs }: any) => {
  const validateDangerousHTML = (tags: any, className?: string) => {
    const hasScriptOrLinkTags = /<(script|link)\b/i.test(tags);
    if (!hasScriptOrLinkTags) {
      return (
        <p
          className={`text-sm my-3 px-4 ${className}`}
          dangerouslySetInnerHTML={{
            __html: tags,
          }}
        />
      );
    }
  };

  return (
    <div
      className={`sideDrawerWrapper ${
        open.state
          ? "animate__animated  animate__slideInRight"
          : "animate__animated  animate__backOutRight"
      }`}
    >
      <div className="drawer_overlay"></div>
      <div className="drawer">
        <div className={`relative `}>
          <div className="drawer_header">
            <div className="title">Event log</div>
            <MdClose
              className="text-white text-4xl cursor-pointer"
              onClick={() => setOpen({ status: "clicked", state: false })}
            />
          </div>

          <div className="overlay-log-top"></div>
          <div className="overlay-log-bottom"></div>

          <div className="drawer_body">
            {logs.map((log: any, index: number) => (
              <div className="fightScreen">
                <p className="text-xl my-3">{log?.eventName}</p>
                {validateDangerousHTML(log?.contextLog)}
                <div className="flex mt-3  gap-2">
                  <div className="flex gap-1 flex-wrap items-center justify-center">
                    {" "}
                    {log?.mains && Object.keys(log?.mains)?.length > 0 && (
                      <div className="image-container flex gap-1">
                        {log?.mains?.ids.map((id: number) => {
                          return (
                            <Image id={id.toString()} className="blue-image" />
                          );
                        })}
                      </div>
                    )}
                  </div>
                  {log?.targets?.ids &&
                    Object.keys(log?.targets?.ids)?.length > 0 && (
                      <p className="self-center versus-text">VS</p>
                    )}

                  <div className="flex gap-1 flex-wrap items-center justify-center">
                    {log?.targets && Object.keys(log?.targets)?.length > 0 && (
                      <div className="image-container flex gap-1">
                        {log?.targets && (
                          <div className="image-container flex gap-1">
                            {log?.targets?.ids.map((id: number) => {
                              return (
                                <Image
                                  id={id.toString()}
                                  className="blue-image"
                                />
                              );
                            })}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                {log.logCollection.map((logCollection: any, i: number) => (
                  <>
                    <div className="flex-center gap-4 my-1"></div>
                    {logCollection?.narrationType === "ABILITY_LOG" && (
                      <div className="px-4 mb-3 flex justify-center items-center sneakMoveActionType">
                        <div className="flex   gap-2">
                          <div className="flex gap-1 flex-wrap items-center justify-center">
                            {" "}
                            {log?.mains && Object.keys(log?.mains)?.length > 0 && (
                              <div className="image-container flex gap-1">
                                {log?.mains?.ids.map((id: number) => {
                                  return (
                                    <Image
                                      id={id.toString()}
                                      className="blue-image"
                                    />
                                  );
                                })}
                              </div>
                            )}
                          </div>

                          <p className="self-center versus-text">
                            <ImageLoader
                              width="50px"
                              alt="ability"
                              src={ABILITY}
                            />
                          </p>

                          <div className="flex gap-1 flex-wrap items-center justify-center">
                            {log?.targets &&
                              Object.keys(log?.targets)?.length > 0 && (
                                <div className="image-container flex gap-1">
                                  {log?.targets && (
                                    <div className="image-container flex gap-1">
                                      {log?.targets?.ids.map((id: number) => {
                                        return (
                                          <Image
                                            id={id.toString()}
                                            className="blue-image"
                                          />
                                        );
                                      })}
                                    </div>
                                  )}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    )}
                    {logCollection?.extraDamage?.some(
                      (entry: any) => entry.factor === "CRITICAL_HIT"
                    ) && (
                      <div className="px-4 mb-3 flex justify-center items-center criticalHitType">
                        <div className="flex   gap-2">
                          {validateDangerousHTML(logCollection?.narration)}
                        </div>
                      </div>
                    )}
                    {logCollection?.narrationType === "EFFECT_TAKEN" ||
                      (logCollection?.narrationType === "EFFECT_LOG" && (
                        <div className="px-4 mb-3 flex justify-start items-center effectTakenType">
                          <div className="flex">
                            <div className="flex  gap-1 items-center justify-center">
                              {logCollection?.main?.id && (
                                <div className="image-container min-w-[58px] flex">
                                  <Image
                                    id={logCollection?.main?.id}
                                    className="blue-image"
                                  />
                                </div>
                              )}
                              {logCollection?.target?.id && (
                                <div className="image-container min-w-[58px] flex">
                                  <Image
                                    id={logCollection?.target?.id}
                                    className="blue-image"
                                  />
                                </div>
                              )}
                            </div>

                            {/* <p className="self-center versus-text">
                          <ImageLoader
                            width="50px"
                            alt="ability"
                            src={ABILITY}
                          />
                        </p> */}

                            <div className="flex gap-1 flex-wrap items-center justify-center">
                              {validateDangerousHTML(
                                logCollection?.narration,
                                "text-left"
                              )}
                            </div>
                          </div>
                        </div>
                      ))}

                    {logCollection?.narration &&
                      logCollection?.narrationType !== "EFFECT_TAKEN" &&
                      logCollection?.narrationType !== "EFFECT_LOG" &&
                      !logCollection?.extraDamage?.some(
                        (entry: any) => entry.factor === "CRITICAL_HIT"
                      ) &&
                      validateDangerousHTML(logCollection?.narration)}
                  </>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideDrawer;
