import { RENTAL_API_CALL } from "@type/rental.type";
import { CONTRACT_RENT_STATUS_TO_NFT_STATUS, NFT_RENT_STATUS, NFT_RENT_TYPE } from "@constants/nft";
import { IResponseGangstaBetInfoWithRental } from "@interfaces/nftDetails.interface";
import { RentalEmptyStructure } from "@misc/rental";
type NftRentStatus = keyof typeof CONTRACT_RENT_STATUS_TO_NFT_STATUS;

export const formatGangstaBetInformation = (
    nftDetailedInfo: IResponseGangstaBetInfoWithRental,
    apiFrom: RENTAL_API_CALL = "WALLET_NFT",
    walletAddress?: string
) => {
    const rentalStructuredInfo = nftDetailedInfo.nftList
        .map((information) => {
            const { gangwarsInfo, nftInfo, rentalInfo } = information;
            const {
                defaultWeapon,
                hospitalizationState,
                lockedStatus,
                primaryStats,
                secondaryStats,
            } = gangwarsInfo;
            const { imageUrl: defaultWeaponURL, type: Ranged, name: weaponName } = defaultWeapon;
            const {
                gender,
                id: nftId,
                image: nftImage,
                level: { type: levelType, number: levelNumber },
                name: nftName,
                owner: ownedBy,
                race,
                type: characterType,
                class: classType,
            } = nftInfo;
            const gangstaBetDetails = {
                nftId,
                nftImage,
                nftName,
                ownedBy,
                gender,
                race,
                classType,
                levelNumber,
                characterType,
                levelType,
            };
            const { cooldownActivated, currentSimulationId, warCooldown } = hospitalizationState;
            const defaultWeapons = {
                weaponType: "Default Weapon",
                weaponName,
                Ranged,
                image: defaultWeaponURL,
            };
            const skills = { primary: primaryStats, secondary: secondaryStats };
            const rentalAbsInfo = rentalInfo ? { ...rentalInfo } : { ...RentalEmptyStructure };
            const checkIfRenterIsOwner = walletAddress
                ? walletAddress.toLowerCase() === rentalAbsInfo.owner?.toLowerCase()
                : false;
            const checkIfBorrower = walletAddress
                ? walletAddress.toLowerCase() === rentalAbsInfo.borrower?.toLowerCase()
                : false;
            const statusName =
                CONTRACT_RENT_STATUS_TO_NFT_STATUS[Number(rentalAbsInfo.status) as NftRentStatus];

            const checkTimeStatus = Number(rentalAbsInfo.expirationTimestamp) / 1000 > Date.now();
            const deListStatus = Boolean(Number(rentalAbsInfo.deListed));
            const isRented = checkIfRenterIsOwner && checkTimeStatus && !deListStatus;
            const expiration = checkIfRenterIsOwner && checkTimeStatus && deListStatus;
            const putForRent =
                statusName === "NOT_AVAILABLE_FOR_RENT" ||
                (checkIfRenterIsOwner && !checkTimeStatus && deListStatus);
            const forRent = checkIfRenterIsOwner && !checkTimeStatus && !deListStatus;
            const inRent = checkIfBorrower && checkTimeStatus;
            const isFromGangstaBetCollection = apiFrom === "WALLET_NFT";
            const marketAllListAvailableCheck =
                apiFrom === "MARKETPLACE" && !checkTimeStatus && !deListStatus;
            const rentalName = (): string => {
                if (isFromGangstaBetCollection && isRented) {
                    return "Rented Out";
                } else if (isFromGangstaBetCollection && expiration) {
                    return "Expiration";
                } else if (isFromGangstaBetCollection && forRent) {
                    return "Out For rent";
                } else if (inRent) {
                    return "Rented";
                } else {
                    return "";
                }
            };
            // condition for check: nft kept rent by the connected address are not shown in the rental marketplace
            if (apiFrom === "MARKETPLACE") {
                if (checkIfRenterIsOwner) {
                    return null;
                } else if (marketAllListAvailableCheck) {
                    return {
                        lockStatus: lockedStatus,
                        hospitalization: {
                            cooldownActivated: +cooldownActivated,
                            currentSimulationId: +currentSimulationId,
                            warCooldown: +warCooldown,
                        },
                        gangstaBetDetails,
                        defaultWeapons,
                        skills,
                        rental: {
                            ...rentalAbsInfo,
                            rentFee: Number(rentalAbsInfo.rentFee) / 10 ** 18,
                            listedRentFee: Number(rentalAbsInfo.listedRentFee) / 10 ** 18,
                            deListed: Boolean(Number(rentalAbsInfo.deListed)),
                            claimableAmount:
                                Number(rentalAbsInfo.claimableAmount) > 0
                                    ? Number(rentalAbsInfo.claimableAmount) / 10 ** 18
                                    : Number(rentalAbsInfo.claimableAmount),
                            rentType:
                                Number(rentalAbsInfo.rentType) === 0
                                    ? NFT_RENT_TYPE.ICX_CURRENCY
                                    : NFT_RENT_TYPE.CROWN_CURRENCY,
                            status: isFromGangstaBetCollection
                                ? isRented
                                    ? NFT_RENT_STATUS.RENTED
                                    : expiration
                                    ? NFT_RENT_STATUS.EXPIRING
                                    : putForRent
                                    ? NFT_RENT_STATUS.NOT_AVAILABLE_FOR_RENT
                                    : forRent && NFT_RENT_STATUS.AVAILABLE_FOR_RENT
                                : apiFrom === "MARKETPLACE" &&
                                  NFT_RENT_STATUS.NOT_AVAILABLE_FOR_RENT,
                            rentalName: rentalName(),
                        },
                    };
                } else {
                    return null;
                }
            } else if (apiFrom === "TENANT") {
                if (inRent) {
                    return {
                        lockStatus: lockedStatus,
                        hospitalization: {
                            cooldownActivated: +cooldownActivated,
                            currentSimulationId: +currentSimulationId,
                            warCooldown: +warCooldown,
                        },
                        gangstaBetDetails,
                        defaultWeapons,
                        skills,
                        rental: {
                            ...rentalAbsInfo,
                            rentFee: Number(rentalAbsInfo.rentFee) / 10 ** 18,
                            listedRentFee: Number(rentalAbsInfo.listedRentFee) / 10 ** 18,
                            deListed: Boolean(Number(rentalAbsInfo.deListed)),
                            rentType:
                                Number(rentalAbsInfo.rentType) === 0
                                    ? NFT_RENT_TYPE.ICX_CURRENCY
                                    : NFT_RENT_TYPE.CROWN_CURRENCY,
                            claimableAmount:
                                Number(rentalAbsInfo.claimableAmount) > 0
                                    ? Number(rentalAbsInfo.claimableAmount) / 10 ** 18
                                    : Number(rentalAbsInfo.claimableAmount),
                            status: isFromGangstaBetCollection
                                ? isRented
                                    ? NFT_RENT_STATUS.RENTED
                                    : expiration
                                    ? NFT_RENT_STATUS.EXPIRING
                                    : putForRent
                                    ? NFT_RENT_STATUS.NOT_AVAILABLE_FOR_RENT
                                    : forRent && NFT_RENT_STATUS.AVAILABLE_FOR_RENT
                                : apiFrom === "TENANT"
                                ? inRent && NFT_RENT_STATUS.IN_RENT
                                : apiFrom === "MARKETPLACE" &&
                                  NFT_RENT_STATUS.NOT_AVAILABLE_FOR_RENT,
                            rentalName: rentalName(),
                        },
                    };
                } else {
                    return null;
                }
            } else {
                return {
                    lockStatus: lockedStatus,
                    hospitalization: {
                        cooldownActivated: +cooldownActivated,
                        currentSimulationId: +currentSimulationId,
                        warCooldown: +warCooldown,
                    },
                    gangstaBetDetails,
                    defaultWeapons,
                    skills,
                    rental: {
                        ...rentalAbsInfo,
                        rentFee: Number(rentalAbsInfo.rentFee) / 10 ** 18,
                        listedRentFee: Number(rentalAbsInfo.listedRentFee) / 10 ** 18,
                        deListed: Boolean(Number(rentalAbsInfo.deListed)),
                        rentType:
                            Number(rentalAbsInfo.rentType) === 0
                                ? NFT_RENT_TYPE.ICX_CURRENCY
                                : NFT_RENT_TYPE.CROWN_CURRENCY,
                        claimableAmount:
                            Number(rentalAbsInfo.claimableAmount) > 0
                                ? Number(rentalAbsInfo.claimableAmount) / 10 ** 18
                                : Number(rentalAbsInfo.claimableAmount),
                        status: isFromGangstaBetCollection
                            ? isRented
                                ? NFT_RENT_STATUS.RENTED
                                : expiration
                                ? NFT_RENT_STATUS.EXPIRING
                                : putForRent
                                ? NFT_RENT_STATUS.NOT_AVAILABLE_FOR_RENT
                                : forRent && NFT_RENT_STATUS.AVAILABLE_FOR_RENT
                            : apiFrom === "TENANT"
                            ? inRent && NFT_RENT_STATUS.IN_RENT
                            : apiFrom === "MARKETPLACE" && NFT_RENT_STATUS.NOT_AVAILABLE_FOR_RENT,
                        rentalName: rentalName(),
                    },
                };
            }
        })
        .filter((info) => info !== null);
    return rentalStructuredInfo;
};
