import PrimaryButton from "@components/buttons/primary.button";
import NFTCard from "@components/card/nftCard.component";
import ImageLoader from "@misc/ImageLoader";
import { excludeStatsKeys } from "@misc/removeStatsKeys";
import { statNameMapping } from "@misc/statNameMap";
import ItemTooltip from "@components/tooltips/item.tooltip";
import WeaponTooltip from "@components/tooltips/weapon.tooltip";
import SneakMoves from "@components/tooltips/sneakmove.tooltip";
import { ABILITY, ITEM, WEAPON } from "@constants/images";
import { HYPERLINKS } from "@misc/links";

const ParticipatedLoadoutGame = ({ participatedDetails, setViewLoadout, isLobbyLocked }: any) => {
    const detailsHead = ["abilities", "equipments", "items"];
    return (
        <div className="participatedLoadoutForGame flex flex-col gap-6">
            <div className="participantedLoadout">
                <NFTCard
                    borderType="nftBorderHistoryAndBattle"
                    cardType="nftCardImageHistoryAndBattle"
                    height="90px"
                    width="70px"
                    nftImage={participatedDetails?.nftDetails?.nftImage}
                    onClick={() =>
                        window.open(
                            `${HYPERLINKS.GANGSTABET_PROFILE}/${participatedDetails?.nftDetails.nftId}`,
                            "_blank"
                        )
                    }
                />
                <div className="w-full flex flex-col gap-2">
                    <div className="flex w-full justify-between ">
                        <div className="gbDetails flex flex-col">
                            <h2
                                onClick={() =>
                                    window.open(
                                        `${HYPERLINKS.GANGSTABET_PROFILE}/${participatedDetails?.nftDetails.nftId}`,
                                        "_blank"
                                    )
                                }
                                className="gbName cursor-pointer"
                            >
                                {participatedDetails?.nftDetails?.nftName}
                            </h2>
                            <div className="flex gap-2">
                                <p className="isGangster">
                                    {" "}
                                    {participatedDetails?.nftDetails?.characterType}
                                </p>
                                <p className="gbType">
                                    {participatedDetails?.nftDetails?.classType}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="flex gap-3">
                        <div className="flex  gap-1">
                            {participatedDetails?.equipments?.map(
                                (equipments: any, index: number) => {
                                    const equipmentName =
                                        equipments?.name?.split(" ").length > 1
                                            ? equipments?.name?.replace(/\s/g, "_")
                                            : equipments?.name;
                                    const tooltipId = `${equipmentName}_${index}_${participatedDetails?.nftDetails?.nftId}`;
                                    return (
                                        <>
                                            <div
                                                id={`${tooltipId}`}
                                                className="takenLoadouts userloadout cursor-pointer"
                                            >
                                                <ImageLoader
                                                    style={{ padding: "3px" }}
                                                    src={equipments?.image}
                                                    alt="loadout-img"
                                                />
                                            </div>
                                            <WeaponTooltip
                                                tooltipId={tooltipId}
                                                place="bottom"
                                                details={equipments}
                                            />
                                        </>
                                    );
                                }
                            )}
                            {Array.from(
                                { length: 1 - participatedDetails?.equipments?.length },
                                (_, i) => {
                                    return (
                                        <div className="takenLoadouts userloadout r">
                                            <ImageLoader
                                                style={{ padding: "3px" }}
                                                src={WEAPON}
                                                alt="loadout-img"
                                            />
                                        </div>
                                    );
                                }
                            )}
                        </div>
                        <div className="flex gap-1">
                            {participatedDetails?.items?.map((items: any, index: number) => {
                                const itemName =
                                    items?.name?.split(" ").length > 1
                                        ? items?.name?.replace(/\s/g, "_")
                                        : items?.name;
                                const tooltipId = `${itemName}_${participatedDetails?.nftDetails?.nftId}`;

                                return (
                                    <>
                                        <div
                                            id={`${tooltipId}`}
                                            className="takenLoadouts userloadout cursor-pointer"
                                        >
                                            <ImageLoader
                                                style={{ padding: "3px" }}
                                                src={items?.image}
                                                alt="loadout-img"
                                            />
                                        </div>

                                        <ItemTooltip
                                            key={`itemtooltip_${Math.random() * 100000}`}
                                            place="bottom"
                                            details={items}
                                            tooltipId={tooltipId}
                                        />
                                    </>
                                );
                            })}
                            {Array.from(
                                { length: 3 - participatedDetails?.items?.length },
                                (_, i) => {
                                    return (
                                        <div className="takenLoadouts userloadout r">
                                            <ImageLoader
                                                style={{ padding: "3px" }}
                                                src={ITEM}
                                                alt="loadout-img"
                                            />
                                        </div>
                                    );
                                }
                            )}
                        </div>
                        <div className="flex gap-1">
                            {participatedDetails?.abilities?.map(
                                (abilities: any, index: number) => {
                                    const abilitiesName =
                                        abilities?.name?.split(" ").length > 1
                                            ? abilities?.name?.replace(/\s/g, "_")
                                            : abilities?.name;
                                    const tooltipId = `${abilitiesName}_${participatedDetails?.nftDetails?.nftId}`;
                                    return (
                                        <>
                                            <div
                                                id={`${tooltipId}`}
                                                className="takenLoadouts userloadout cursor-pointer"
                                            >
                                                <ImageLoader
                                                    style={{ padding: "3px" }}
                                                    src={abilities?.image}
                                                    alt="loadout-img"
                                                />
                                            </div>
                                            <SneakMoves
                                                key={`sneakmoves_${Math.random() * 100000}`}
                                                place="bottom"
                                                tooltipId={tooltipId}
                                                details={abilities}
                                            />
                                        </>
                                    );
                                }
                            )}
                            {Array.from(
                                { length: 3 - participatedDetails?.abilities?.length },
                                (_, i) => {
                                    return (
                                        <div className="takenLoadouts userloadout r">
                                            <ImageLoader
                                                style={{ padding: "3px" }}
                                                src={ABILITY}
                                                alt="loadout-img"
                                            />
                                        </div>
                                    );
                                }
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <hr className="horizontalSeperator" />
            <div className="skillsStats flex justify-between gap-10">
                <div className="primarySkillsStats flex flex-col gap-2">
                    {participatedDetails?.nftDetails?.skills &&
                        Object.entries(participatedDetails?.nftDetails?.skills?.primary).map(
                            ([key, value]: any) => {
                                return (
                                    <div className="flex justify-between" key={key}>
                                        <p className="statsSkillKeys capitalize">{key} </p>
                                        <p className="statsSkillValues ">
                                            {Math.floor(value).toFixed(0)}
                                        </p>
                                    </div>
                                );
                            }
                        )}
                </div>
                <div className="secondarySkillsStats flex flex-col gap-2">
                    {participatedDetails?.nftDetails?.skills &&
                        Object.entries(participatedDetails?.nftDetails?.skills?.secondary).map(
                            ([key, value]: any) => {
                                if (excludeStatsKeys.includes(key)) return null;
                                return (
                                    <div className="flex flex-row justify-between w-full" key={key}>
                                        <p className="statsSkillKeys capitalize">
                                            {statNameMapping?.[key]}
                                        </p>
                                        <p className="statsSkillValues self-end">
                                            {statNameMapping?.[key]?.split(" ").length > 1
                                                ? (value * 100).toFixed(2) + "%"
                                                : value.toFixed(0)}
                                        </p>
                                    </div>
                                );
                            }
                        )}
                </div>
            </div>
            <div className="flex justify-center">
                <PrimaryButton
                    onClick={() => {
                        if (!isLobbyLocked) {
                            setViewLoadout(true);
                        }
                    }}
                    width="270px"
                    // icon={<AiFillLock color={"white"} />}
                    className={`${isLobbyLocked && "disable"}`}
                    buttonName={`${isLobbyLocked ? "Lobby Locked" : "Edit Loadout"}`}
                />
            </div>
        </div>
    );
};

export default ParticipatedLoadoutGame;
