import { FC, useState } from "react";

import Modal from "@components/modal/modal.component";
import PrimaryButton from "../../buttons/primary.button";
import { ARROW_DOWN_ICON, CROWN, ICX } from "@constants/images";
import { updateRateForRent } from "@services/icon.service";
import { useDetailGlobalContext } from "@context/detailProvider";
import { AsyncState, IRentalModal } from "./rental.interface";
import { useNFTDetailGlobalContext } from "@context/nftInformation";
import { NFT_RENT_STATUS, NFT_RENT_TYPE, msPerDay } from "@constants/nft";
import RentalWrapper from "./RentalWrapper.component";
import { useNFTInformation } from "@context/nftInfoProvider";
import { useSelector } from "react-redux";
import { RootState } from "@redux/store";
import { useTransactionValidate } from "@hooks/transactionResult";

//TODO: Make changes for day change aswell

const RemoveModal: FC<IRentalModal> = ({
  selectedChar,
  isVisible,
  setRentalModal,
  maxDaysForRent,
  setSelectedChar
}) => {
  const { isLedger } = useSelector((state: RootState) => state.wallet);
  const { getTxResultWithTimer } = useTransactionValidate({ onHandle: () => { } });
  const [asyncState, setAsyncState] = useState(AsyncState.IDLE);
  const [newRentDuration, setNewRentDuration] = useState<number>(2);
  const [isOpen, setIsOpen] = useState(false);
  const [rentFee, setRentFee] = useState<null | number>(null);
  const [invalidRate, setInvalidRate] = useState(false);
  const {
    reFetchDetails,
    setRefetchData,
    reFetchChangeData,
  } = useDetailGlobalContext();
  const {
    setNftData,
    setRentalIndex,
    setCurrentBatchIndex,
    setChangeDataToggle, changeDataToggle

  } = useNFTDetailGlobalContext();

  const { reRenderCollectionToggler, setRerenderCollectionToggler, platformFees } = useNFTInformation()

  const nftId = selectedChar?.gangstaBetDetails.nftId!;
  const currency = selectedChar.rental.rentType;
  const onAction = async () => {
    if (!newRentDuration) return;
    setAsyncState(AsyncState.LOADING);

    const result: any = await updateRateForRent(nftId.toString(), rentFee);
    if (isLedger) {
      if (result?.name === "TransportStatusError") {
        console.log("Ledger cancelled!!!");
      } else if (result) {
        getTxResultWithTimer(result as string);
      } else {
        console.log("Error on ledger");
      }
    }
  };

  const onContinue = () => {
    setRentalModal(null);
    if (asyncState === AsyncState.SUCCESS) {
      setSelectedChar(null);
      setRerenderCollectionToggler(!reRenderCollectionToggler);
      //refetch all NFT details (rent + inventory)
      setRentalIndex(null);
      setNftData([]);
      setCurrentBatchIndex(0);
      setRefetchData(!reFetchChangeData);
      reFetchDetails();
      setChangeDataToggle(!changeDataToggle);

    }
  };
  const getOption = (day: number) => {
    return (
      <div className="rent-price-dropdown flex gap-1.5">
        {day} days ({" "}
        <img
          src={
            selectedChar?.rental.rentType === NFT_RENT_TYPE.CROWN_CURRENCY ? CROWN : ICX
          }
          alt="currency"
          className="w-5 h-5"
        />
        {selectedChar?.rental.rentFee! * day} Total)
      </div>
    )
  }

  const selectOption = (day: number) => {
    setNewRentDuration(day);
    setIsOpen(false);
  };

  return (
    <RentalWrapper
      type="UPDATE"
      asyncState={asyncState}
      setAsyncState={setAsyncState}
      setRentalModal={setRentalModal}
    >
      <Modal
        isVisible={isVisible}
        onModalClose={onContinue}
        glow={asyncState === AsyncState.SUCCESS ? "success" : ""}
        modalTitle={
          asyncState === AsyncState.CONFIRM
            ? "Confirm new rent rate"
            : asyncState === AsyncState.SUCCESS
              ? "Rent rate updated"
              : "Update rent rate"
        }
        className=" rental-modal-outer-wrapper flex flex-col justify-center items-center"
      >
        <p className="description-text text-center">
          {asyncState === AsyncState.CONFIRM ? (
            <>
              Are you sure you want to change the rent rate <br /> rate of this
              GangstaBet?
              <div className="success-description-text py-4 mt-6 mb-8">
                GangstaBet name: <span>{selectedChar?.gangstaBetDetails.nftName}</span>
                <br />
                {/* <p className="flex gap-1.5 justify-center items-end"> */}
                New rent rate:{" "}
                <span className="inline-flex gap-2 items-end">

                  {rentFee} per day
                </span>
                {/* </p> */}
                <br />
                {/* Old Duration: <span> {Math.floor((selectedChar?.rental.expiry! / 1000 - Date.now()) / msPerDay)} Days</span> */}
              </div>
            </>
          ) : asyncState === AsyncState.SUCCESS ? (
            <>
              Rent rate for this GangstaBet has been <br /> updated
              successfully.
              <div className="success-description-text py-4 mt-6 mb-8">
                GangstaBet name: <span>{selectedChar?.gangstaBetDetails.nftName}</span>
                <br />
                New rent rate:  <span> {rentFee} per day</span>
              </div>
            </>
          ) : (
            <>
              Change the rate at which time, the other players can <br /> rent your
              GangstaBet.
              <div className="success-description-text py-4 mt-6 mb-8">
                GangstaBet name: <span>{selectedChar?.gangstaBetDetails.nftName}</span>
                <br />
                <p className="flex gap-1.5 justify-center items-end">
                  Current rate:
                  <span className="inline-flex gap-2 items-end">
                    <img
                      src={
                        selectedChar?.rental.rentType ===
                          NFT_RENT_TYPE.CROWN_CURRENCY
                          ? CROWN
                          : ICX
                      }
                      alt="currency"
                      className="w-6 h-6"
                    />
                    {selectedChar.rental.status === NFT_RENT_STATUS.AVAILABLE_FOR_RENT ? selectedChar?.rental.listedRentFee : selectedChar?.rental.rentFee} per day
                  </span>

                </p>
                {/* Current Duration: <span> {Math.floor((selectedChar?.rental.expiry! / 1000 - Date.now()) / msPerDay)} Days</span> */}
              </div>
            </>
          )}
        </p>
        {asyncState === AsyncState.IDLE && (
          // <div className="my-6 ">
          //   <div className="relative flex gap-3 justify-between items-end px-4 py-2.5 group">
          //     <div className="absolute input-box top-0 left-0 right-0 bottom-0 w-full h-full group-active:border-[rgba(227,201,110,1)] group-hover:border-[rgba(227,201,110,1)] group-focus:border-[rgba(227,201,110,1)]" />
          //     <img
          //       src={
          //         selectedChar?.rental.type === NFT_RENT_TYPE.CROWN_CURRENCY
          //           ? CROWN
          //           : ICX
          //       }
          //       alt="currency"
          //       className="w-6 h-6"
          //     />
          //     <input
          //       onChange={(e) => {
          //         e.preventDefault();
          //         setNewRentDuration(Number(e.target.value));
          //       }}
          //       onClick={() => setInvalidRate(false)}
          //       defaultValue={newRentDuration ?? ""}
          //       min={1}
          //       type="number"
          //       placeholder="Enter rent rate"
          //       className={`input-area ${
          //         invalidRate
          //           ? "placeholder:text-[#F66E6E] !text-[#F66E6E]"
          //           : "placeholder:text-[rgba(112,135,143,1)]"
          //       } w-[150px]`}
          //     />
          //     <p className="input-area-text !text-[rgba(112,135,143,1)]">
          //       per day
          //     </p>
          //   </div>
          //   {invalidRate && (
          //     <p className="rental-text !text-[#F66E6E]">
          //       Please enter a rent rate before you submit.
          //     </p>
          //   )}
          // </div>
          // <button
          //   // disabled={!rentFee}
          //   onClick={() => setIsOpen(!isOpen)}
          //   className="w-1/2 cursor-pointer relative flex gap-3 justify-between items-end outline-none py-2.5 group">
          //   <div className="absolute input-box top-0 left-0 right-0 bottom-0 w-full h-full group-active:border-[rgba(227,201,110,1)] group-hover:border-[rgba(227,201,110,1)] group-focus:border-[rgba(227,201,110,1)]" />
          //   <p className="input-area-text ml-4 !text-[rgba(112,135,143,1)]">
          //     {getOption(newRentDuration)}
          //   </p>
          //   {isOpen && (
          //     <div className="absolute top-full max-h-32 overflow-y-scroll py-1 w-full flex flex-col items-center bg-black rounded-sm border border-[#485C65] z-50">
          //       {Array.from({ length: maxDaysForRent || 0 }, (_, i) => i + 2).map((day) => (
          //         <button
          //           key={day}
          //           onClick={() => selectOption(day)}
          //           className="block w-full px-4 py-2 text-sm text-gray-500  hover:text-white"
          //         >
          //           {getOption(day)}
          //         </button>
          //       ))}
          //     </div>
          //   )}

          //   <img
          //     src={ARROW_DOWN_ICON}
          //     alt="currency"
          //     className="w-6 h-6"
          //   />
          // </button>
          <>
            <div className="relative flex gap-3 justify-between items-end px-4 py-2.5 group">
              <div className="absolute input-box top-0 left-0 right-0 bottom-0 w-full h-full group-active:border-[rgba(227,201,110,1)] group-hover:border-[rgba(227,201,110,1)] group-focus:border-[rgba(227,201,110,1)]" />
              <img
                src={currency === NFT_RENT_TYPE.CROWN_CURRENCY ? CROWN : ICX}
                alt="currency"
                className="w-6 h-6 "
              />
              <input
                onChange={(e) => {
                  e.preventDefault();
                  setIsOpen(false)
                  setRentFee(Number(e.target.value));
                }}
                onClick={() => setInvalidRate(false)}
                defaultValue={rentFee ?? ""}
                min={1}
                type="number"
                placeholder="Enter rent rate"
                className={`input-area ${invalidRate
                  ? "placeholder:text-[#F66E6E] !text-[#F66E6E]"
                  : "placeholder:text-[rgba(112,135,143,1)]"
                  } w-[150px]`}
              />
              <p className="input-area-text !text-[rgba(112,135,143,1)]">
                per day
              </p>
            </div>
            <small className="text-sm text-white">Note: {platformFees}% will be deducted as a platform fee.</small>
            {invalidRate && (
              <p className="rental-text !text-[#F66E6E]">
                Please enter a rent rate before you submit.
              </p>
            )}
          </>
        )}
        <div className="flex flex-col items-center">
          {asyncState === AsyncState.IDLE && (
            <PrimaryButton
              disabled={!newRentDuration}
              onClick={() => {
                if (!rentFee) {
                  setInvalidRate(true);
                  return;
                } else setAsyncState(AsyncState.CONFIRM)
              }}
              buttonName="Update Rent Rate"
              className="!w-full"
            />
          )}
          {asyncState === AsyncState.CONFIRM && (
            <PrimaryButton onClick={onAction} buttonName="Yes, Update Rent Rate"
              className="!w-full"
            />
          )}
          {asyncState === AsyncState.SUCCESS ? (
            <PrimaryButton onClick={onContinue} buttonName="Okay" />
          ) : (
            <button
              onClick={
                asyncState === AsyncState.CONFIRM
                  ? () => setAsyncState(AsyncState.IDLE)
                  : () => setRentalModal(null)
              }
              className="rent-price-dropdown !text-center !py-[0.5em] outline-none"
            >
              {asyncState === AsyncState.CONFIRM ? "Go Back" : "Not Now"}
            </button>
          )}
        </div>
      </Modal>
    </RentalWrapper>
  );
};

export default RemoveModal;
